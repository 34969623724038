<template>
    <div class="step-2-container">
        <addProviderHeader :cancel="close"/>
        <div class="step-2-body">
            <div class="step-2-row">
                <StandardInput title="CNPJ" 
                        :action="()=>{}"
                        :type="'text'"
                        :value="store.cnpj"
                        class="step-2-input"
                        mask="## ### ###/####-##"/>
                <Button class="step-2-mobile-button" text="voltar" iconName="arrow_back" :action="back" />
            </div>
            <p class="step-2-green">Esse fornecedor já está cadastrado.</p>
            <p class="step-2-gray"><b class="step-2-orange">Nome Fantasia:</b> {{ store.nome_fantasia || store.name }}</p>
            <p class="step-2-gray"><b class="step-2-orange">Razão Social:</b> {{ store.razao_social || store.social_reazon}}</p>
            
            <p class="step-2-gray desktop">
                <!-- <b class="step-2-orange">Telefone:</b> {{ store.ddd_telefone_1 || store.phone || '--' }} -->
                <b class="step-2-orange">Cidade: </b> {{ store.municipio || store.head_office.city.name }} 
                <b style="margin-left: 4vw;" class="step-2-orange">Estado:  </b> {{ store.uf || store.head_office.state.name }}
            </p>

            <!-- <p class="step-2-gray mobile">
                <b class="step-2-orange">Telefone:</b> {{ store.ddd_telefone_1 || store.phone || '--' }}
            </p> -->
            <p class="step-2-gray mobile">
                <b class="step-2-orange">Cidade: </b> {{ store.municipio || store.head_office.city.name }} 
                <b style="margin-left: 4vw;" class="step-2-orange">Estado:  </b> {{ store.uf || store.head_office.state.name }}
            </p>
            <p class="step-2-gray"></p>
            <div class="step-2-line" />
            <div style="display: flex; align-items: center; margin-bottom: 2vh;">
                <h1 class="step-2-find">Encontre seu vendedor</h1>
                    <!-- <span v-if="internalUser" @click="internalUser = !internalUser" class="material-icons-outlined step-2-internal-icon">check_box</span>
                    <span v-else @click="internalUser = !internalUser" class="material-icons-outlined  step-2-internal-icon">check_box_outline_blank</span>
                    <span class="step-2-internal-text">Vendedor interno</span> -->
                
                
            </div>
            
            <p v-if="notFound" class="step-2-not-found">Vendedor não encontrado - Favor informar nome e empresa.</p>
            <div class="step-2-input-down">
                <template>
                    <input type="text" v-model="invitation.receiver_phone" v-mask="'(##) #.####-####'" placeholder="Digite o telefone do vendedor" class="step-2-phone-input">
                    <input  :disabled="!firstSearch" type="text" v-model="invitation.receiver_name"  placeholder="Nome do Vendedor" class="step-2-phone-input">    
                </template>
                
                <div class="requested-products__actions-wrapper " v-on:click="findSeller">
                        {{ getButtonText() }}
                </div>
                <div class="requested-products__actions-wrapper " @click="saveInternalUser">
                    Vendedor interno
                </div>
            </div>
        </div>
        
        <cc-loader-full v-if="isLoading"/>
        <Alert v-if="showAlert" :text="alertText" :close="close"/>
    </div>
</template>

<script>
import Button from '@/components/ui/buttons/Standard-Button.vue'
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import InvitationService from '@/services/v3/invitation.service'
import QuotationService from "@/services/QuotationService";
import ProviderService from '@/services/v3/provider.service'
import addProviderHeader from './addProviderHeader.vue';
import SellerService from "@/services/v2/sellers.service";
import Alert from './Alert.vue';

export default {
    name: 'AddProviderStep2',
    props:['close', 'store', 'back'],
    components:{
        addProviderHeader,
        StandardInput,
        Button,
        Alert
    },
    data(){
        return{
            invitation: {
                receiver_company_name: this.store.nome_fantasia || this.store.name,
                receiver_name: '',
                receiver_phone: '',
                invited_user_id: null,
                lock: false
            },
            internalUser: false,
            firstSearch: 0,
            sellerName: '',
            svc: new InvitationService(),
            req_svc: new QuotationService(),
            pro_svc: new ProviderService(),
            sellerSvc: new SellerService(),
            isLoading: false,
            notFound: false,
            showAlert: false,
            alertText: 'Convite enviado com sucesso!'
        }
    },
    methods:{
        findSeller(){
            this.isLoading = true
            if (this.internalUser){
                this.saveInternalUser()
                return
            }
            if (this.firstSearch == 1){
                this.save()
            } else {
                this.search_by_phone(resp => {
                    this.isLoading = false
                    this.firstSearch = 1
                    if (resp) this.save()
                })
                
            }
            
        },
        getButtonText () {
            if(this.internalUser || this.firstSearch == 2) return 'Convidar'
            if(this.firstSearch == 1) return 'Incluir'
            return "Localizar"
        },
        saveInternalUser(){
            this.$swal.fire({
                title: "Deseja adicionar um vendedor interno?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, adicionar vendedor!"
            }).then(resp => {
                if (resp.isConfirmed) {
                    let payload = {
                        name: this.store.name.split(' ')[0],
                        email: 'email@email.com',
                        phone: '99',
                        login: Math.floor(Math.random() * 100) + this.store.name.split(' ')[0] +  this.store.cnpj,
                        password: '9268315794',
                        profile: {name: "Vendedor Padrão", id: 753},
                        provider: this.store
                    }
                    this.isLoading = true
                    return this.svc.createInternalSeller(payload).then(resp => {
                        console.log(resp.data);
                        this.svc.activateSeller(resp.data.id, localStorage.getItem('cli_id')).then(() => {
                            this.alertText = 'Vendedor adicionado com sucesso!'
                            this.$swal.fire("Realizado!",this.alertText,"success").then(()=>{this.close()});
                            this.isLoading = false
                        }) 
                    }).catch(() => {
                        this.isLoading = false
                    })
                }
            })
        },
        save() {
            this.isLoading = true
            
            this.invitation.cli_id = localStorage.getItem('cli_id')
            this.svc.send(this.invitation).then(resp => {
                if(this.invitation.invited_user_id){
                    this.alertText = 'Vendedor adicionado com sucesso!'
                    this.req_svc.enableSeller(this.invitation.invited_user_id).then(()=> {
                        // this.showAlert = true
                        this.$swal.fire(
                            "Realizado!",
                            this.alertText,
                            "success"
                        ).then(()=>{
                            this.close()
                        });
                    } )
                } else {
                    this.$swal.fire(
                            "Realizado!",
                            this.alertText,
                            "success"
                        ).then(()=>{
                            this.close()
                        });
                }
                
            }).catch((err) => {
                if(err.response && err.response.data.errors) {
                    console.log(err);
                }
                this.isLoading = false
            })
            
        },
        search_by_phone(callback = null) {
            return this.pro_svc.find_by_phone(this.invitation.receiver_phone)
            .then(response => response.data).then((data) => {
                this.invitation.receiver_name = data.usu_nome
                this.invitation.receiver_company_name = data.provider.for_nome
                this.invitation.invited_user_id = data.usu_id
                this.$set(this.invitation, 'lock', true)
                if(callback)callback(data)
            }).catch(() => {
                this.notFound = true
                if(callback)callback(null)
            })
        },
    }
    
}
</script>

<style scoped lang="scss">
.step-2-container{
    height: 65vh;
    width: 70vw;
    background-color: white;
    border-radius: 10px;
}
.step-2-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.step-2-body{
    height: calc(100% - 10vh);
    padding: 0 3%;
}
.step-2-input{
    width: 60%;
}
.step-2-green{
    color: #30AA4C;
    font-size: 1.3em;
    margin-top: 2vh;
}
.step-2-orange{
    color: var(--primary-color);
    margin-right: 1vw;
    font-size: 1em;
    text-transform: capitalize;
}
.step-2-gray{
    color: #605F5F;
    font-size: 1.3em;
    text-transform: uppercase;
    margin-top: 2vh;
}
.step-2-line{
    border-bottom: 3px solid #ABABAB;
    height: 3vh;
}
.step-2-find{
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.8em;
    margin-top: 1vh;
}
.step-2-input-down{
    display: flex;
    gap: 3vw;
}
.step-2-phone-input{
    border-radius: 8px;
    border: 2px solid #ABABAB;
    padding: 0.5vh 1vw;
    width: 25%;
    color: #605F5F;
}
.step-2-phone-input:focus{
    outline: none;
    border: 2px solid var(--primary-color);
}
.step-2-button{
    border-radius: 6px;
    background: #FFC599;
    border: none;
    padding: 0 2vw;
    color: var(--primary-color);
    font-size: 1.5em;
    font-weight: bold;
}
.requested-products__actions-wrapper {
  width: 15%;
  padding-top: 0.5vh;
  text-align: center;
  margin: 0px 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  font-weight: 400;
  color: var(--primary-color);
  cursor: pointer;
}
.step-2-internal-icon{
    color: var(--primary-color);
    font-size: 2em;
    margin-left: 4vw;
    cursor: pointer;
}
.step-2-internal-text{
    color: #605F5F;
    font-size: 1.3em;
    margin-left: 1vw;
}
.step-2-not-found{
    color: #E53535;
    font-size: 1.2em;
}
.mobile{display: none;}
@media only screen and (max-width: 850px){
    .mobile{display: block;}
    .step-2-container{
        width: 100vw;
    }
    .step-2-mobile-button{
        width: 33%;
    }
    .step-2-input-down{
        flex-wrap: wrap;
    }
    .step-2-phone-input{
        width: 30%;
    }
    .step-2-button{
        width: 50%;
    }
    .desktop{display: none;}
}
</style>