<template>
    <div class="divergent-table-row">

        <div :class="{ 'have-divergent-product': product.divergencias.length > 0 }"
            class="divergent-page-table-name-width">{{ product.produtoDescricao }}
            <div class="divergent-extra-line">
                <p class="divergent-brand-line" v-if="product.pro_observacao">{{ product.pro_observacao }}</p>
                Codigo: {{ product.pro_ean }}
            </div>
        </div>
        <div class="divergent-page-table-alert-width"
            :class="{ 'have-divergent-product': product.divergencias.length > 0 }">
            <span v-if="product.divergencias.length > 0" @click="openModal(product)"
                class="material-icons-outlined divergent-alert-icon">warning</span>
        </div>
        <div class="divergent-page-table-req-width">{{ product.quantidade }}</div>
        <div class="divergent-page-table-quantity-width">{{ product.emb_quantidade }}</div>
        <div class="divergent-page-table-orders-width">
            <td class="unity">
                <div class="unity-wrapper">
                    <input v-model="product.new_amount" min="0" value="1" type="number" disabled />
                    <button class="save">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                </div>
            </td>
        </div>
        <div class="divergent-page-table-unity-width">{{ product.pro_unidade_compra ? product.pro_unidade_compra :
            product.embalagem.split(" ")[0] }}</div>
        <div class="divergent-page-table-digi-width">
            <div style="display: flex; align-items: center;">
                <div>{{ product.menorPreco | currency }}</div>
            </div>
        </div>
        <div class="divergent-page-table-total-width">
            {{ product.menorPreco * product.quantidade_sugestao * (product.flag_generico == 1 || (product.pro_principal
            ==
            null && product.cli_tipoPedido == 1) ? 1 : parseFloat(product.embalagem.split(" ")[1])) | currency }}
        </div>
        <div class="divergent-page-table-action-width">
                <DivergentButton :haveDivergent="product.divergencias.length > 0" :action="() => openModal(product)" />
            </div>

    </div>
</template>

<script>
import DivergentButton from './DivergentButton.vue';

export default {
    name: 'DivergentTableLineDesktop',
    props: ['product', 'openModal'],
    components: {
        DivergentButton,
    },
}
</script>

<style scoped lang="scss">
@import "./typings.component";

.have-divergent-product {
    color: lightcoral;
}

.divergent-page-table-name-width {
    flex: 1;
}

.divergent-extra-line {
    display: flex;
    gap: 2vw;
}

.divergent-brand-line {
    margin: 0;
    color: var(--primary-color);
}

.divergent-table-row {
    display: flex;
    align-items: center;
    padding: 2vh 0;
    color: #606060;
}

.divergent-page-table-alert-width {
    width: 7%;
}

.divergent-alert-icon {
    font-size: 2em;
    margin-left: 2vw;
    cursor: pointer;
}

.divergent-page-table-req-width {
    width: 8%;
}

.divergent-page-table-quantity-width {
    width: 8%;
}

.divergent-page-table-orders-width {
    width: 10%;
}

.unity {
    box-sizing: unset;

    .fa-edit {
        margin-left: 10px;
        color: var(--primary-color);
        cursor: pointer;
    }

    .unity-wrapper {
        display: inline-block;
        width: 86px;

        img {
            height: 18px;
        }

        input[type=number] {
            border-radius: 10px 0 0 10px;
            border-width: 0;
            width: 50px;
            border: 4px solid #0606064d;
            display: inline-block;

            &:focus {
                border: 3px solid var(--primary-color);
                outline: none;
                box-shadow: 0px 0px 0px 3px var(--primary-color) inset;
            }

            &.higher {
                background-color: #13c7213d;
            }

            &.lower {
                background-color: #13c7213d;
            }
        }

        button {
            .fa-arrow-up {
                color: #0033ef9e;
            }

            .fa-arrow-down {
                color: #fb7272;
            }

            &.save {
                float: right;
                color: green;
                height: 32px;
                padding: 4px;
                position: relative;
                left: -12px;
                border-left: none;
                width: 1.5rem;
                border-radius: 0px 10px 10px 0px !important;

                i {
                    border-color: #0606064d;
                    left: 0.4rem;
                    position: absolute;
                    font-size: 0.9rem;
                    margin: 0;
                }
            }
        }
    }
}

.divergent-page-table-unity-width {
    width: 10%;
}

.divergent-page-table-digi-width {
    width: 10%;
    display: flex;
    align-items: center;
}
.divergent-page-table-total-width {
    width: 10%;
}
.divergent-page-table-action-width {
    width: 13%;
}
@media only screen and (max-width: 850px){
    .divergent-table-row{display: none;}
}
</style>