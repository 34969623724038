<template>
    <div class="repurchase">
        <div class="repurchase-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="repurchase-route-select-text">
                Home
            </router-link>
            <span class="material-icons repurchase-route-select-icon">play_arrow</span>
            <div class="repurchase-route-select-text">
                Meus produtos
            </div>
        </div>
        <div class="repurchase-title">Monitor de Divergência</div>
        <div class="repurchase-inputs-line">
            <StandardInput :type="'text'" title="Produto" class="repurchase-store-width"
                :action="(val) => productFilter = val" />
            <StandardInput v-if="!hideStore" :type="'select'" class="repurchase-store-width" title="Empresa"
                :list="lojas" :action="(val) => storeFilter = val" />
            <StandardButton :iconName="'save'" :action="generate" :text="'Gerar ressuprimento'" />
            <StandardButton :iconName="'delete'" :action="deleteAll" :text="'Excluir produto'" />
        </div>
        <div class="repurchase-table-header">
            <div class="table-header-text check-width" @click="checkAll = !checkAll">
                <span v-if="checkAll" class="material-icons-outlined repurchase-check-icon">check_box</span>
                <span v-else class="material-icons-outlined repurchase-check-icon">check_box_outline_blank</span>
            </div>
            <div class="table-header-text product-width">Produto / Pedido</div>
            <div class="table-header-text date-width"> Data Requisição</div>
            <div class="table-header-text user-width">Usuário / Empresa</div>

            <div class="table-header-text request-width">Quantidade / Embalagem </div>
        </div>
        <div v-for="(item, idx) in showDivergentList" :key="item.id">
            <RepurchaseLineDesktop :updateItem="updateItem" :getSpecialBackground="getSpecialBackground" :item="item"
                :getProductPack="getProductPack" :idx="idx" />
            <RepurchaseLineMobile :updateItem="updateItem" :getSpecialBackground="getSpecialBackground" :item="item"
                :getProductPack="getProductPack" :idx="idx" />
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import divergentService from '@/services/v3/divergentService.js'
import RepurchaseLineDesktop from './repurchaseLineDesktop.vue'
import RepurchaseLineMobile from './repurchaseLineMobile.vue'

export default {
    name: 'repurchasePage',
    data() {
        return {
            service: new divergentService(),
            lojas: [
                { text: 'Todas', value: undefined },
            ],
            storeFilter: undefined,
            productFilter: undefined,
            divergentList: [],
            checkAll: false,
            isLoading: true,
            hideStore: false
        }
    },
    components: {
        StandardInput,
        StandardButton,
        RepurchaseLineDesktop,
        RepurchaseLineMobile
    },
    computed: {
        showDivergentList() {
            let resp = this.divergentList
            if (this.storeFilter) resp = resp.filter(val => val.pedido.store.loj_descricao == this.storeFilter)
            if (this.productFilter) resp = resp.filter(val => val.produto.pro_descricao.toLowerCase().includes(this.productFilter.toLowerCase()))
            return resp
        }
    },
    methods: {
        generate() {
            this.$swal.fire({
                title: "Deseja gerar o Ressuprimento dos produtos Selecionados??",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, gerar o Ressuprimento!",
                cancelButtonText: 'Cancelar'
            }).then(resp => {
                if(!resp.isConfirmed) return
                this.isLoading = true
                let generateList = this.divergentList.filter(value => value.check).map(value => value.id)
                this.service.generateNewRebuy(generateList).then(() => {
                    this.load()
                })
            })

        },
        deleteAll() {
            let excludeList = this.divergentList.filter(value => value.check)
            this.isLoading = true
            excludeList.forEach(element => {
                let payload = {
                    "id": element.id,
                    status: null,
                    // "id_cotacao": this.cotId,
                    // "id_pedido": this.id_pedido,
                    // "id_produto": this.product.idProduto,
                    // "id_opcao_divergencia": element.id_opcao_divergencia,
                    // "observacao": element.descricao == 'Produto cobrado mais caro' ? element.observacao.toFixed(2) : element.observacao
                }
                this.service.updateDivergent(payload).then(() => {
                    this.load()
                })
            });

        },
        getProductPack(item) {
            if (item.produto && item.produto.produto_principal) return item.produto.produto_principal.pro_unidade_compra
            else if (item.produto) return item.produto.pro_embalagem
            return '--'
        },
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
        updateItem(item) {
            console.log('asdasd');
            this.$set(item, 'check', !item.check)
        },
        load() {
            this.service.listDivergent().then(resp => {
                this.divergentList = resp.data

                // get stores list
                let stores = []
                if (JSON.parse(localStorage.getItem('store_id'))) {
                    let resp = this.divergentList.find(element => element.pedido.store.loj_id == JSON.parse(localStorage.getItem('store_id')))
                    this.lojas = [{ text: resp.pedido.store.loj_descricao, value: resp.pedido.store.loj_descricao }]
                    this.storeFilter = resp.pedido.store.loj_descricao
                    this.hideStore = true
                } else {
                    for (let index = 0; index < this.divergentList.length; index++) if (!stores.includes(this.divergentList[index].pedido.store.loj_descricao)) stores.push(this.divergentList[index].pedido.store.loj_descricao)
                    stores.forEach(element => this.lojas.push({ text: element, value: element }));
                }


                this.isLoading = false
            })
        }
    },
    watch: {
        checkAll(value) {
            if (value) this.divergentList = this.divergentList.map(element => { return { ...element, check: true } })
            else this.divergentList.forEach(element => element.check = false)
        }
    },
    created() {
        this.load()
    }
}
</script>

<style scoped lang="scss">
.repurchase-route-select {
    display: flex;
    align-items: center;
}

.repurchase-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.repurchase-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.repurchase {
    font-size: 1vw;
    padding-bottom: 10vh;
}

.repurchase-title {
    font-weight: 700;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}

.repurchase-table-header {
    margin-top: 6vh;
    margin-bottom: 1.5vh;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.table-header-text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.19em;
    line-height: 17px;
    color: #605F5F;
}

.date-width {
    flex: 1;
}

.request-width {
    width: 18%;
}

.product-width {
    width: 40%;
}

.user-width {
    width: 20%;
}



.suplier-width {
    width: 10%;
}



.repurchase-store-width {
    width: 18vw;
}



.page-table-line-special {
    background: #F7F7F7;
}

.repurchase-inputs-line {
    display: flex;
    gap: 4vw;
    align-items: flex-end;
}

.check-width {
    width: 5%;
    text-align: center;
}

.repurchase-check-icon {
    font-size: 1.5em;
    cursor: pointer;
    color: var(--primary-color);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (max-width: 850px) {
    .repurchase {
        font-size: 2.5vw;
    }

    .repurchase-inputs-line {
        flex-wrap: wrap;
    }

    .repurchase-store-width {
        width: 55vw;
    }

    .repurchase-table-header {
        display: none;
    }
}
</style>