<template>
    <div class="divergent-table-row">

        <div style="display: flex; justify-content: space-between; margin-bottom: 2vh;">
            <div :class="{ 'have-divergent-product': product.divergencias.length > 0 }"
                class="divergent-page-table-name-width">{{ product.produtoDescricao }}
                <div class="divergent-extra-line">
                    <p class="divergent-brand-line" v-if="product.pro_observacao">{{ product.pro_observacao }}</p>
                    Codigo: {{ product.pro_ean }}
                </div>
            </div>
            <span :class="{ 'have-divergent-product': product.divergencias.length > 0 }"
                v-if="product.divergencias.length > 0" @click="openModal(product)"
                class="material-icons-outlined divergent-alert-icon">warning</span>

        </div>

        <div class="divergent-mobile-desc-row">
            <div class="divergent-mobile-collum-2">
                <p class="divergent-mobile-title">Req.</p>
                <p class="divergent-mobile-text">{{ product.quantidade }}</p>
            </div>
            <div class="divergent-mobile-collum-2">
                <p class="divergent-mobile-title">Qtd Emb.</p>
                <p class="divergent-mobile-text">{{ product.emb_quantidade }}</p>
            </div>
            <div class="divergent-mobile-collum-2">
                <p class="divergent-mobile-title">Pedido</p>
                <p class="divergent-mobile-text">{{ product.new_amount }}</p>
            </div>
        </div>

        <div class="divergent-mobile-desc-row">
            <div class="divergent-mobile-collum">
                <p class="divergent-mobile-title">Und Compra</p>
                <p class="divergent-mobile-text">{{ product.pro_unidade_compra ? product.pro_unidade_compra :
                product.embalagem.split(" ")[0] }}</p>
            </div>
            <div class="divergent-mobile-collum">
                <p class="divergent-mobile-title">Digitação</p>
                <p class="divergent-mobile-text">{{ product.menorPreco | currency }}</p>
            </div>
            <div class="divergent-mobile-collum">
                <p class="divergent-mobile-title">Total</p>
                <p class="divergent-mobile-text">{{ product.menorPreco * product.quantidade_sugestao * (product.flag_generico == 1 || (product.pro_principal
                ==
                null && product.cli_tipoPedido == 1) ? 1 : parseFloat(product.embalagem.split(" ")[1])) | currency }}</p>
            </div>
        </div>

        
        <DivergentButton style="margin: 0 auto;" :haveDivergent="product.divergencias.length > 0" :action="() => openModal(product)" />
        

    </div>
</template>

<script>
import DivergentButton from './DivergentButton.vue';

export default {
    name: 'DivergentTableLineDesktop',
    props: ['product', 'openModal'],
    components: {
        DivergentButton,
    },
}
</script>

<style scoped lang="scss">
@import "./typings.component";

.have-divergent-product {
    color: lightcoral;
}

.divergent-page-table-name-width {
    flex: 1;
    font-size: 1.8em;
}

.divergent-extra-line {
    display: flex;
    gap: 2vw;
}

.divergent-brand-line {
    margin: 0;
    color: var(--primary-color);
}

.divergent-table-row {
    display: none;
    padding: 2vh 0;
    color: #606060;
}

.divergent-page-table-alert-width {
    width: 7%;
}

.divergent-alert-icon {
    font-size: 3em;
    margin-right: 2vw;
    cursor: pointer;
}

.divergent-page-table-req-width {
    width: 8%;
}

.divergent-page-table-quantity-width {
    width: 8%;
}

.divergent-page-table-orders-width {
    width: 10%;
}

.unity {
    box-sizing: unset;

    .fa-edit {
        margin-left: 10px;
        color: var(--primary-color);
        cursor: pointer;
    }

    .unity-wrapper {
        display: inline-block;
        width: 86px;

        img {
            height: 18px;
        }

        input[type=number] {
            border-radius: 10px 0 0 10px;
            border-width: 0;
            width: 50px;
            border: 4px solid #0606064d;
            display: inline-block;

            &:focus {
                border: 3px solid var(--primary-color);
                outline: none;
                box-shadow: 0px 0px 0px 3px var(--primary-color) inset;
            }

            &.higher {
                background-color: #13c7213d;
            }

            &.lower {
                background-color: #13c7213d;
            }
        }

        button {
            .fa-arrow-up {
                color: #0033ef9e;
            }

            .fa-arrow-down {
                color: #fb7272;
            }

            &.save {
                float: right;
                color: green;
                height: 32px;
                padding: 4px;
                position: relative;
                left: -12px;
                border-left: none;
                width: 1.5rem;
                border-radius: 0px 10px 10px 0px !important;

                i {
                    border-color: #0606064d;
                    left: 0.4rem;
                    position: absolute;
                    font-size: 0.9rem;
                    margin: 0;
                }
            }
        }
    }
}

.divergent-page-table-unity-width {
    width: 10%;
}

.divergent-page-table-digi-width {
    width: 10%;
    display: flex;
    align-items: center;
}

.divergent-page-table-total-width {
    width: 10%;
}

.divergent-page-table-action-width {
    width: 13%;
}

.divergent-mobile-desc-row {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.5em;
}

.divergent-mobile-collum {
    width: 30%;
}

.divergent-mobile-title {
    
    font-weight: bold;
    font-size: 1.2em;
}

.divergent-mobile-text {
    
}
.divergent-mobile-collum-2{
    display: flex;
    align-items: center;
    width: 30%;
    gap: 15%;
    margin-bottom: 2vh;
    p{margin-bottom: 0;}
}

@media only screen and (max-width: 850px) {
    .divergent-table-row {
        display: block;
    }
}
</style>