<template>

	<div>

		<a id="download-pdf" style="display:none;"></a>

		<a id="download-excel" style="display:none;"></a>

		<no-data-available v-if="!is_loading && pre_order_collection && pre_order_collection.stores.length == 0" :text="type == 'NOT_ORDERED' ? 'Sem Pedidos Pendentes' : 'Sem Pedidos Confirmados'" />

        <div class="cc-loader" :class="{ hidden : !loading_orders }" v-if="pre_order_collection">
            <img src="/images/loader.svg"/>
            <span>Enviando pedidos {{ orders_completed }}/{{ pre_order_collection.stores.length }}</span>
        </div>

        <!-- <div v-if="pre_order_collection">
            <div class="responsive-table shadow-sm mt-3 p-2"
                v-for="store in pre_order_collection.stores"
                v-bind:key="store.id">

                <table id="winners-pre-order-summary" class="table winners-pre-order-summary m-0">

                    <thead>
                        <tr>
                            <th class="company">
                                <div>
                                    <span class="expand-controller" @click="toggle(store)" v-if="type == 'ORDERED'">
                                        <i v-if="!store.expanded" class="fas fa-angle-right" ></i>
                                        <i v-if="store.expanded" class="fas fa-angle-down"></i>
                                    </span>
                                    <span class="empresa-titulo">Empresa: {{store.name}}</span>
                                    <span class="empresa-titulo">CNPJ: {{ store.cnpj || "" | VMask('##.###.###/####-##')}}</span>
                                </div>
                            </th>
                            <th style="min-width: 13rem;max-width: 13rem;">
                                <div class="flex-center">
                                    <label class="total empresa-titulo " style="padding-top:20px !important"><span style="color: white !important;">Total: {{ pre_order_collection.total_for_store(store) | currency }}</span></label>
                                </div>
                            </th>
                            <th v-if="type == 'ORDERED'">
                                <cc-button
                                    :classes="{ 'hidden' : store.is_loading_pdf }"
                                    :content="'Exportar PDF'"
                                    :icon="'fa fa-file-pdf'"
                                    @click="generate_pdf(store)">
                                </cc-button>
                                <button class="generate-pdf" :class="{ 'hidden' : !store.is_loading_pdf}">
                                    <span v-bind:key="'generate-pdf-loader'">
                                        <img src="/images/loader.svg">
                                        Carregando
                                    </span>
                                </button>
                            </th>
                            <th v-if="can('REQUEST_ORDER', 'CREATE') && type == 'NOT_ORDERED'">
                                <button class="order" v-on:click="send_orders(store)">
                                    <i class="fa fa-shopping-cart"></i>
								    <span>Realizar Pedido</span>
                                </button>
                            </th>
                        </tr>
                    </thead>

                </table>

                <table id="winner-pre-order-details"
                    class="table winner-pre-order-details mt-2"
                    :class="{ 'hidden' : !store.expanded }"
                    v-if="(store.expanded && type == 'ORDERED') || type == 'NOT_ORDERED'">
                    <thead class="gray">
                        <tr>
                            <th class="pl-4">Fornecedor</th>
                            <th>Data Entrega</th>
                            <th>Cond. de Pgto</th>
                            <th>Valor</th>
                            <th>Fat. Min.</th>
                            <th>Tipo de Entrega</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="seller in store.sellers" v-bind:key="`${seller.id}${store.id}`" >
                            <td>
                                <span class="cart">
                                    <router-link :to="subrequest_link(store, seller)">
                                        <i class="fa fa-shopping-cart" ></i>
                                    </router-link>
                                </span>
                                <span class="cart vf" v-if="has_vf_integration">
                                    <i class="fas fa-sync-alt"
                                        aria-hidden="true"
                                        title="Enviar VF"
                                        v-show="
                                            has_vf_integration &&
                                            (!seller.vf_integration_status ||
                                            (seller.vf_integration_status && seller.status != 'INTEGRATED'))
                                        "
                                        @click="sync_order(seller)"></i>
                                    <i class="fas fa-check-square"
                                        aria-hidden="true"
                                        :title="'Sincronizado #' + seller.external_reference"
                                        v-show="
                                            has_vf_integration &&
                                            seller.vf_integration_status &&
                                            seller.vf_integration_status == 'INTEGRATED'
                                        "></i>
                                </span>
                                <span class="provider-name">{{seller.provider.name}}</span>
                            </td>
                            <td>
                                {{ seller.delivery ? seller.delivery.date : '' | formatDateOnly }}
                            </td>
                            <td>
                                {{ seller.delivery ? seller.delivery.form : '' }}
                            </td>
                            <td>
                                <span :class="{ lower: seller.delivery && pre_order_collection.total_for_seller_on_store(seller, store) < seller.delivery.minimum_billing}">
                                    {{ pre_order_collection.total_for_seller_on_store(seller, store) | currency }}
                                </span>
                            </td>
                            <td>
                                {{ seller.delivery ? parseFloat(seller.delivery.minimum_billing) : '' | currency }}
                            </td>
                            <td>
                                {{ seller.provider.delivery_type }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
        <div v-if="pre_order_collection">
            <div v-if="type == 'ORDERED'">
                <div class="preorder-seller-container" v-for="(store, idx) in pre_order_collection.stores" v-bind:key="store.id">
                    <div >
                        <div >
                            <div class="preorder-seller-title">
                                <div class="order-seller-title-text">
                                    <div>
                                        <span v-if="store.isExpand" v-on:click="() => {store.isExpand = false; $forceUpdate()}" class="material-icons-outlined preorder-seller-expanded-icon">expand_more</span>
                                        <span v-else v-on:click="() => {store.isExpand = true; $forceUpdate()}" class="material-icons-outlined preorder-seller-retracted-icon">expand_more</span>
                                    </div>
                                    <div>
                                        <div>Empresa: {{store.name}}</div>
                                        <div>CNPJ: {{ store.cnpj || "" | VMask('##.###.###/####-##')}}</div>
                                    </div>
                                </div>
                                <div class="preorder-seller-title-buttons">
                                    <div class="preorder-seller-title-button-total">Total: {{ pre_order_collection.total_for_store(store) | currency }}</div>
                                    <div class="preorder-seller-title-button-excel" @click="generate_pdf(store)">
                                        <div v-show="store.is_loading_pdf">
                                            <img style="height: 3.3vh" src="/images/loader.svg" alt="loading">
                                        </div>
                                        <div style="display: flex; align-items: center;" v-show="!store.is_loading_pdf">
                                            <span class="material-icons-outlined preorder-seller-title-button-pdf-icon">picture_as_pdf</span>
                                            Exportar PDF
                                        </div>
                                    </div>
                                    <div class="preorder-seller-title-button-excel" @click="export_store_report(store)">
                                        <div v-show="store.is_loading_excel">
                                            <img style="height: 3.3vh" src="/images/loader.svg" alt="loading">
                                        </div>
                                        <div style="display: flex; align-items: center;" v-show="!store.is_loading_excel">
                                            <img class="preorder-seller-title-button-excel-icon" src="@/assets/excel.svg" alt="excel">
                                            Excel
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="store.isExpand">
                                <div class="preorder-seller-table-header">
                                    <div class="preorder-seller-table-cart"></div>
                                    <div class="preorder-seller-table-suplier">Fornecedor</div>
                                    <div class="preorder-seller-table-date">Data Entrega</div>
                                    <div class="preorder-seller-table-payment">Cond. de Pgto.</div>
                                    <div class="preorder-seller-table-value">Valor</div>
                                    <div class="preorder-seller-table-min">Fat. Min.</div>
                                    <div class="preorder-seller-table-delivery">Tipo de Entrega</div>
                                </div>
                                <div :class="getSpecialBackground (idx)" class="preorder-seller-table-row" v-for="seller in store.sellers" v-bind:key="`${seller.id}${store.id}`">
                                    <router-link class="preorder-seller-table-cart" :to="subrequest_link(store, seller)">
                                        <span class="material-icons-outlined preorder-seller-table-cart-icon">shopping_cart</span>
                                    </router-link>
                                    <div class="preorder-seller-table-suplier">{{seller.provider.name}}</div>
                                    <div class="preorder-seller-table-date">{{ seller.delivery ? seller.delivery.date : '' | formatDateOnly }}</div>
                                    <div class="preorder-seller-table-payment">{{ seller.delivery ? seller.delivery.form : '' }}</div>
                                    <div class="preorder-seller-table-value">
                                        <div :class="{ lower: seller.delivery && pre_order_collection.total_for_seller_on_store(seller, store) < seller.delivery.minimum_billing}">{{ pre_order_collection.total_for_seller_on_store(seller, store) | currency }}</div>
                                    </div>
                                    <div class="preorder-seller-table-min">{{ seller.delivery ? parseFloat(seller.delivery.minimum_billing) : '' | currency }}</div>
                                    <div class="preorder-seller-table-delivery">{{ seller.provider.delivery_type }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="preorder-seller-container" v-for="store in pre_order_collection.stores"
                    v-bind:key="store.id">
                    <div class="preorder-seller-title">
                        <div class="preorder-seller-title-text">
                            <div>Empresa: {{store.name}}</div>
                            <div>CNPJ: {{ store.cnpj || "" | VMask('##.###.###/####-##')}}</div>
                        </div>
                        <div class="preorder-seller-title-buttons">
                            <div class="preorder-seller-title-button-total">Total: {{ pre_order_collection.total_for_store(store) | currency }}</div>
                            <div class="preorder-seller-title-button-order" v-on:click="send_orders(store)" v-if="can('REQUEST_ORDER', 'CREATE')">
                            <span class="material-icons preorder-seller-title-button-order-icon">shopping_cart</span>
                                Realizar Pedido
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="preorder-seller-table-header">
                                <div class="preorder-seller-table-cart"></div>
                                <div class="preorder-seller-table-suplier">Fornecedor</div>
                                <div class="preorder-seller-table-date">Data Entrega</div>
                                <div class="preorder-seller-table-payment">Cond. de Pgto.</div>
                                <div class="preorder-seller-table-value">Valor</div>
                                <div class="preorder-seller-table-min">Fat. Min.</div>
                                <div class="preorder-seller-table-delivery">Tipo de Entrega</div>
                            </div>
                            <div :class="getSpecialBackground (idx)" class="preorder-seller-table-row" v-for="(seller, idx) in store.sellers" v-bind:key="`${seller.id}${store.id}`">
                                <router-link class="preorder-seller-table-cart" :to="subrequest_link(store, seller)">
                                    <span class="material-icons-outlined preorder-seller-table-cart-icon">shopping_cart</span>
                                </router-link>
                                <div class="preorder-seller-table-suplier">{{seller.provider.name}}</div>
                                <div class="preorder-seller-table-date">{{ seller.delivery ? seller.delivery.date : '' | formatDateOnly }}</div>
                                <div class="preorder-seller-table-payment">{{ seller.delivery ? seller.delivery.form : '' }}</div>
                                <div class="preorder-seller-table-value">
                                    <div :class="{ lower: seller.delivery && pre_order_collection.total_for_seller_on_store(seller, store) < seller.delivery.minimum_billing}">{{ pre_order_collection.total_for_seller_on_store(seller, store) | currency }}</div>
                                </div>
                                <div class="preorder-seller-table-min">{{ seller.delivery ? parseFloat(seller.delivery.minimum_billing) : '' | currency }}</div>
                                <div class="preorder-seller-table-delivery">{{ seller.provider.delivery_type }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import QuotationService from "@/services/QuotationService";
import RequestService from "@/services/v3/request.service";
import PreOrderService from "@/services/v3/requests/pre-order.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component"
import NoDataAvailable from "@/components/utils/no-data.utils"
import { perm_mixin } from '@/mixins/permission.mixin'
import VarejoFacilService from '@/services/VarejoFacilService';
import StorageService from '@/services/local-storage.service';
import PreOrderCollection from '@/models/requests/pre-order.collection'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { mapState } from 'vuex'
import V3OrderService from "@/services/v3/requests/order.service"
import V2OrderService from "@/services/v2/order.service";

export default {
    mixins: [ perm_mixin, loaderMixin ],
    components: {
        ccButton: Button,
        'no-data-available': NoDataAvailable
    },
    props: [
        'type',
        'request',
        'is_loading'
    ],
    data() {
        return {
            svc: new RequestService(),
            productService: new QuotationService(),
            vf_svc: new VarejoFacilService(),
            storage_svc : new StorageService(),
            v3_order_svc: new V3OrderService(),
            v2_order_svc: new V2OrderService(),
            po_svc: new PreOrderService(),
            collapsed: [],
            is_loading_pdf: false,
            loading_orders: false,
            has_vf_integration: false,
            orders_completed: 0,
            current_seller: null,
            pre_order_collection: null
        };
    },
    watch: {
        request: function() {
            this.load()
        }
    },
    computed: {
        ...mapState('user', [ 'stores' ])
    },
    methods: {
        getSpecialBackground (idx) {if (idx % 2 != 0) return 'page-table-line-special'},
        subrequest_link(store, seller) {
            return {
                name: 'subrequest',
                params: {
                    id: this.$route.params.id,
                    seller_id: seller.id,
                    store_id: store.id
                },
                query: {
                    tab: this.type == 'ORDERED' ? 'ORDERS' : 'PRE-ORDERS'
                }
            }
        },
        ordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 1);
        },
        unordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 0);
        },
        toggle(seller) {
            this.$set(seller, 'expanded', !seller.expanded);
        },
        send_orders(store) {
            this.$swal.fire({
                title: 'Confirma realização do pedido?',
                text: "Seu pedido será enviado ao fornecedor!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, realizar pedido!'
            }).then(async (result) => {
                if (result.value) {

                    for(let i =0; i<store.sellers.length;i++) {
                        await this.v3_order_svc.send_by_store_seller(this.request.id, store.id, store.sellers[i].id)
                        .then((response) => response.data).then((response) => {
                            this.v3_order_svc.sync_legacy(response.id)
                            .then((response) => response.data).then((legacy) => {
                                this.v2_order_svc.notify_legacy(legacy.id_pedido).then(() => {
                                    this.snack('Pedido enviado com sucesso!')
                                    this.reload()
                                })
                            })
                        })
                    }
                    this.$swal.fire(
                        'Recarregando informações!',
                        'Pedidos realizados com sucesso.',
                        'success'
                    );
                    this.loading_orders = false;
                    this.$emit('reload')
                }
            });
        },
        sync_order(item) {
            this.present_loader("Enviando pedido...")
            this.vf_svc.export(item.id_pedido).then(response => response.data).then(data => {
                this.dismiss_loader()
                const notification = {
                    type: 'success',
                    message: "Pedido enviado com sucesso!"
                }
                this.$store.dispatch('notification/add', notification)
                this.reload();
            }).catch(e => {
                this.dismiss_loader()
                if(e.response.data.message) {
                    const notification = {
                        type: 'error',
                        message: e.response.data.message
                    }
                    this.$store.dispatch('notification/add', notification)
                }
            })
        },
        export_store_report(seller) {
            this.$set(seller, 'is_loading_excel', true);
            this.svc.export_store_report(this.$route.params.id, seller.id, { v4: true })
            .then(response => response.data)
            .then(data => {
                var file = new Blob([data], {type: 'application/vnd.ms-excel'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-excel");
                el.download = "Conferencia-" + seller.name + "-" + this.$route.params.id + ".xls";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.$set(seller, 'is_loading_excel', false);
            })
            .catch(error => {
                this.$set(seller, 'is_loading_excel', false);
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        generate_pdf(store) {
            this.$set(store, 'is_loading_pdf', true);
            this.v3_order_svc
            .download_pdf_by_store(this.request.id, store.id)
            .then(response => response.data)
            .then(data => {
                var file = new Blob([data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-pdf");
                el.download = "Conferencia-" + store.trading_name + "-" + this.$route.params.id + ".pdf";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.$set(store, 'is_loading_pdf', false);
            })
            .catch(error => {
                this.$set(seller, 'is_loading_pdf', false);
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        print(){
            self.print();
        },
        emit_total() {
            this.$emit('total', this.pre_order_collection.total)
        },
        reload() {
            this.$emit('reload')
        },
        load() {
            this.po_svc.load(this.request.id, { status: this.type})
            .then(response => response.data)
            .then(response => {
                this.pre_order_collection = new PreOrderCollection(response, this.stores, this.type)
                this.emit_total()
            })
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>

@import "./_pre-orders.component";

.empresa-titulo {
	height: 55px;
  font-family: Rubik !important;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: black !important;
}
.span-button{
	font-family: Rubik !important;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #a3a6b4 !important;
}
label{
  color:#4D4F5C !important
}
.preorder-seller-container{
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 1vh 1vw;
    font-size: 1vw;
    margin-bottom: 3vh;
}
.preorder-seller-title{
    display: flex;
    justify-content: space-between;
}
.preorder-seller-title-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
    text-transform: uppercase;
}
.order-seller-title-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
    text-transform: uppercase;
    display: flex;
}
.preorder-seller-title-buttons{
    display: flex;
}
.preorder-seller-title-button-total{
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    margin-right: 2vw;
}
.preorder-seller-title-button-order{
    background: #30AA4C;
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    font-weight: 600;
    font-size: 1.3em;
    color: #FFFFFF;
    cursor: pointer;
}
.preorder-seller-title-button-order-icon{
    color: #ffffff;
    margin-right: 1vw;
    font-size: 1.5em;
}
.preorder-seller-table-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin: 2vh 0;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}
.preorder-seller-table-cart{width: 5%}
.preorder-seller-table-suplier{flex: 1}
.preorder-seller-table-date{width: 15%}
.preorder-seller-table-payment{width: 12%}
.preorder-seller-table-value{width: 12%}
.preorder-seller-table-min{width: 12%}
.preorder-seller-table-delivery{width: 12%}
.preorder-seller-table-row{
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    margin-bottom: 1vh;
    padding: 1vh 0;
}
.preorder-seller-table-cart-icon{
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.5em;
    margin-left: 1vw;
}
.page-table-line-special{  background: #F7F7F7; }
.preorder-seller-retracted-icon{
    color: #FE6700;
    transform: rotate(-90deg);
    font-size: 2.5em;
    cursor: pointer;
}
.preorder-seller-expanded-icon{
    color: #FE6700;
    font-size: 2.5em;
    cursor: pointer;
}
.preorder-seller-title-button-excel{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    padding: 2vh 1vw;
    font-weight: 600;
    font-size: 1.3em;
    cursor: pointer;
    margin-right: 2vw;
    min-width: 14vw;
}
.preorder-seller-title-button-excel-icon{
    margin-right: 1vw;
}
.preorder-seller-title-button-pdf-icon{
    color: #EF443B;
    margin-right: 1vw;
}
@media only screen and (max-width: 1000px){.preorder-seller-container{font-size: 1.5vw;}}
@media only screen and (max-width: 680px){.preorder-seller-container{font-size: 2vw;}}
@media only screen and (max-width: 540px){
    .preorder-seller-container{font-size: 2.5vw;}
    .preorder-seller-title-button-total{flex: 1; padding: 1vh 1vw; justify-content: center;}
    .preorder-seller-title-button-order{flex: 1; padding: 1vh 1vw; justify-content: center;}
}
@media only screen and (max-width: 410px){.preorder-seller-container{font-size: 3vw;}}
</style>
