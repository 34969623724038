import http from "./http";
import Service from "../Service";

/**
 * @type ProfileService
 */
export default class ProfileService extends Service {

	my() {
		return http.get(`profiles/my`, {
			headers: this.getHeaders()
		});
	}

}
