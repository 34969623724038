<template>
    <div>
        <cc-loader-full v-if="is_loading_provider" />
        <div v-else class="makeorder-page">
            <div class="page-route-select">
                <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                    Home
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                    Monitor de Cotação
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <router-link :to="'/cliente/monitores/vencedores/' + $route.params.id" class="page-route-select-text">
                    Comparativo de Preço
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <div class="page-route-select-text">
                    Não Digitados
                </div>
            </div>
            <div class="makeorder-page-title-container">
                <div class="makeorder-page-title">Gestor de compras</div>
                <div>
                    <span class="material-icons-outlined makeorder-page-title-icons"
                        @click="navigate_provider(-1)">skip_previous</span>
                    <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate_provider(1)"
                        style="margin-left: 1vw;">skip_next</span>
                </div>
            </div>
            <div class="makeorder-page-info-container">
                <div class="makeorder-page-info-texts">
                    <div class="makeorder-page-info-buyer-colum">
                        <div class="makeorder-page-info-buyer-title-text">Comprador</div>
                        <div class="makeorder-page-info-first-line makeorder-page-info-subtitle">Comprador:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.loj_nomeFantasia.toUpperCase() }}</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.loj_endereco }}, {{
                            current_subrequest.loj_bairro }} - {{ current_subrequest.cid_nome }}/{{
        current_subrequest.est_sigla }}</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Fone:</div>
                            <div class="makeorder-page-info-text" v-if="current_subrequest.loj_telefone">{{
                                current_subrequest.loj_telefone | VMask('(##) #####-####') }}</div>
                            <div class="makeorder-page-info-text" v-else>--</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">CNPJ:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.loj_cnpj | cnpj }}</div>
                        </div>
                    </div>
                    <div class="makeorder-page-info-suplier-colum">
                        <div class="makeorder-page-info-buyer-title-text">Fornecedor</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-suplier-raiting-text">{{ current_subrequest.for_nome }}</div>
                            <span class="material-icons-outlined makeorder-page-info-suplier-raiting-icon">grade</span>
                            <div>{{ current_subrequest.rate.toFixed(1) }}</div>
                        </div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.usu_nome }}</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">E-mail:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.usu_email }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Fone:</div>
                            <img class="makeorder-page-info-whats-icon" src="@/assets/WhatsApp_black.svg"
                                alt="mandar mensagem">
                            <a class="makeorder-page-info-text" v-if="current_subrequest.usu_telefone"
                                :href="'https://api.whatsapp.com/send?phone=' + formatNumber(current_subrequest.usu_telefone)"
                                target="_blank">{{ current_subrequest.usu_telefone | VMask('(##) #####-####') }}</a>
                            <div class="makeorder-page-info-text" v-else>--</div>
                        </div>
                    </div>
                    <div class="makeorder-page-info-details-colum">
                        <div class="makeorder-page-info-buyer-title-text">Detalhe do Pedido</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Entrega:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.data_entrega | formatDateOnly }}
                            </div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Tipo de Entrega:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.tpc_nome }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Faturamento:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.faturamento_minimo | currency }}
                            </div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Cond. de Pgto:</div>
                            <div class="makeorder-page-info-text">{{ current_subrequest.forma }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Status:</div>
                            <div v-if="!current_subrequest.id_pedido" class="makeorder-page-info-status-pending">pendente
                            </div>
                            <div v-else class="makeorder-page-info-status-created">gerado</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="makeorder-page-buttons-row">
                <div class="makeorder-page-buttons-total">
                    <span class="material-icons-outlined makeorder-page-buttons-total-icon">attach_money</span>
                    Valor Total: {{ this.current_subrequest.total | currency }}
                </div>
                <div class="makeorder-page-buttons-balance">
                    Saldo: {{ (current_subrequest.faturamento_minimo > this.current_subrequest.total ?
                        this.current_subrequest.total - current_subrequest.faturamento_minimo : 0) | currency }}
                </div>
                <div v-if="productTotal != 0" :class="{ 'report-total-minus': productTotal < 0, 'report-total-plus': productTotal >= 0 }"
                    class="reports-page__title-button-total action-buttons-width">
                    <div style="display: flex; align-items: center;">
                        <!-- <img class="preorder-seller-title-button-excel-icon" src="../../../assets/database-icon.png" alt="excel"> -->
                        {{ productTotal < 0 ? 'Economia' : 'Aumento' }}: {{ productTotal | currency }} </div>
                    </div>
                    <CheckBox style="margin-left: 2vw;" text="Apenas com aumento de preço" :value="onlyPositive" :action="() =>onlyPositive = !onlyPositive"/>
                    <div class="makeorder-page-buttons-blank"></div>
                    <StandardButton class="makeorder-page-button-right" :action="sendSuplier" :iconName="'store'" :text="'Por Empresa'"/>
                    <StandardButton :action="sendBack" :iconName="'arrow_back'" :text="'Voltar'" />
                </div>
                <priceManagerMatrix :subrequest="current_subrequest" :open_negotiation_modal="open_negotiation_modal" :products="products" :getDiff="getDiff" />
            </div>

            <NegotiationModal
                @close="()=> {current_product = undefined;this.load_matrix()}"
                :typing="{
                    idVendedor: $route.params.sellerid, idFornecedor: this.$route.params.providerid,
                    valor: current_product.valor, nomeVendedor: current_subrequest.nomeVendedor,
                    pro_id: current_product.pro_id
                }"
                :product="{
                    produtoDescricao: current_product.pro_descricao, pro_observacao: current_product.pro_observacao,
                    observacao: current_product.observacao, ultimaCompra: current_product.preco_ultima_compra
                }"
                v-if="current_product"
                :quotation_id="$route.params.id" />
        </div>
</template>

<script>
import { perm_mixin } from '@/mixins/permission.mixin'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import RequestsService from "@/services/v2/requests.service";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import priceManagerMatrix from './priceManagerMatrix.vue';
import CheckBox from "@/components/ui/inputs/StandardCheckBox.vue";

export default {
    name: 'PriceManager',
    mixins: [perm_mixin],
    components: {
        StandardButton,
        priceManagerMatrix,
        NegotiationModal,
        CheckBox
    },
    data() {
        return {
            service: new RequestsService(),
            subrequests: undefined,
            all_subrequests: undefined,
            current_subrequest: undefined,
            is_loading_provider: true,
            onlyPositive: false,
            productTotal: 0,
            cliTipoPedido: localStorage.getItem('cli_tipoPedido'),
            current_product: undefined,
            products: [],
            allProducts: [],
        }
    },
    created() {
        // sds = 45
        this.load_subrequests()
        this.load_matrix()
        
    },
    watch:{
        onlyPositive(){
            this.filterProducts()
        }
    },
    methods: {
        formatNumber(number){
                if (!number) return null
                number = number.replace('+','').replace('(','').replace(')','').replace('-','')
                if (number[0] + '' + number[1] != '55' ) number = '55' + number
                return number
            },
        sendSuplier() {this.$router.push({ name: 'request-matrix-overview', params: { id: this.$route.params.id, sellerid: this.$route.params.sellerid, providerid: this.$route.params.providerid }})},
        load_matrix() {
            return this.service.request_products_matrix(this.$route.params.id, { seller_id: this.$route.params.sellerid }).then(response => response.data)
                .then(data => {
                    data.forEach(p => {
                        let prod_stores = p.stores
                        p.stores.forEach(pp => pp.initial_quantity = pp.quantidade)
                        p.stores = []
                        // this.stores.forEach(s => {
                        //     let curr = this.filter_product(prod_stores, s)
                        //     prod_stores.forEach(ps => ps.initial_quantity = ps.quantidade)
                        //     curr.disabled = s.disabled
                        //     p.stores.push(curr)
                        // })
                    });
                    this.allProducts = data;
                    let resp = 0
                    this.allProducts.forEach(element => resp += this.getVar(element))
                    this.products = this.allProducts
                    this.filterProducts()
                    this.productTotal = resp
                    // this.products.forEach(p => this.total(p))
                    // this.stores.forEach(s => this.total_store(s))
                    // this.load_has_orders()
                    // this.products.forEach(p => this.load_has_stock(p))
                    // this.loading = false
                })
        },
        filterProducts () {
            if(this.onlyPositive) this.products = this.products.filter(product => this.getDiff(product) > 0 )
            else this.products = this.allProducts
        },
        getDiff(produto) {
            if (!produto.preco_ultima_compra_generico && !produto.preco_ultima_compra) return 0
            return produto.valor - produto.preco_ultima_compra
        },
        getVar(produto){
            if(!produto.pro_principal && this.cliTipoPedido == 0) return (this.getDiff(produto) * produto.pro_embalagem.split(' ')[1]) * produto.quantidade_sugestao
            return this.getDiff(produto) * produto.quantidade
        },
        open_negotiation_modal(product) {
            this.current_product = product;
        },
        load_subrequests() {
            return this.service.subrequests_with_sellers_for(this.$route.params.id)
                .then(response => response.data).then(data => {
                    this.subrequests = this.group_subrequests(data);
                    this.all_subrequests = data
                    let next = this.subrequests.find(s => s.for_id == this.$route.params.providerid && s.vendedor_id == this.$route.params.sellerid)
                    if (next) {
                        this.current_subrequest = next
                        this.sort_subrequests()
                    } else {
                        this.subrequests.push(this.current_subrequest)
                        this.sort_subrequests()
                        this.current_subrequest.total = 0
                    }
                })
        },
        navigate_provider(direction) {
            var s = null;
            for (var i = 0; i < this.subrequests.length; i++) {
                s = this.subrequests[i];
                if (s.vendedor_id == this.$route.params.sellerid && s.for_id == this.$route.params.providerid) {
                    if (direction == 1) {
                        if (this.subrequests.length > i + 1) {
                            s = this.subrequests[i + 1];
                            break;
                        } else if (this.subrequests.length == i + 1) {
                            s = this.subrequests[0];
                            break;
                        }
                    } else if (direction == -1) {
                        if (i == 0) {
                            s = this.subrequests[this.subrequests.length - 1];
                        } else {
                            s = this.subrequests[i - 1];
                        }
                        break;
                    }
                }
            }
            this.$router.replace({
                name: "requestPriceManager",
                params: {
                    id: this.$route.params.id,
                    sellerid: s.vendedor_id,
                    providerid: s.for_id
                }
            });
            this.trigger = !this.trigger
            this.update();
        },
        update() {
            this.is_loading_provider = true;
            this.load_matrix()
            this.load_subrequests().then(() => {
                this.$forceUpdate();
            })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        sendBack() { this.$router.go(-1) },
        group_subrequests(data) {
            let result = []
            data.forEach(s => {
                var found = result.find(ss => ss.vendedor_id == s.vendedor_id)
                if (found) {
                    found.total += s.total
                    return
                } else {
                    found = s
                }
                result.push(found)
            })
            return result
        },
        sort_subrequests() {
            let result = []
            this.is_loading_provider = false
            result = result.concat(this.subrequests.sort((a, b) => { return a.for_nome.localeCompare(b.for_nome) }))
            this.subrequests = result;

        },
    }
}
</script>

<style scoped lang="scss">
.prods {
    #print-matrix {
        display: none;
    }
}

.makeorder-page {
    padding-right: 3.5vw;
    font-size: 1vw;
    padding-bottom: 15vh;
}

.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.makeorder-page-title {
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
}

.makeorder-page-title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.makeorder-page-title-icons {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0.5vh 1vw;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--primary-color);
        color: white;
    }
}

.makeorder-page-info-container {
    display: flex;
    margin-top: 5vh;
    padding-left: 5vw;
}

.makeorder-page-info-buyer-colum {
    flex: 1;
}

.makeorder-page-info-suplier-colum {
    flex: 1;
}

.reports-page__title-button-total {
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    height: 7.5vh;
}

.report-total-plus {
    background-color: lightcoral;
    color: white;
}

.report-total-minus {
    background-color: lightgreen;
    color: white;
}

.action-buttons-width {
    margin-left: 2vw;
    text-wrap: nowrap;
}

.makeorder-page-info-texts {
    display: flex;
    flex: 1;
}

.makeorder-page-info-details-colum {
    flex: 1;
}

.makeorder-page-info-buyer-title-text {
    font-weight: 700;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #606060;
}
.makeorder-page-button-right{margin-right: 1.5vw;}

.makeorder-page-info-first-line {
    margin-top: 2vh;
}

.makeorder-page-info-subtitle {
    font-weight: 500;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #707070;
    margin-right: 0.5vw;
}

.makeorder-page-info-text {
    font-weight: 300;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #A1A1A1;
}

.makeorder-page-info-suplier-raiting {
    margin-top: 1.5vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.makeorder-page-info-suplier-raiting-text {
    background: rgba(42, 205, 114, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #30AA4C;
    margin-right: 2vw;
}

.makeorder-page-info-suplier-raiting-icon {
    color: #FFCA2B;
    font-size: 2em;
}

.makeorder-page-info-whats-icon {
    filter: brightness(0) saturate(100%) invert(53%) sepia(11%) saturate(2678%) hue-rotate(81deg) brightness(100%) contrast(89%);
    height: 3.5vh;
    margin-right: 1.5vw;
}

.makeorder-page-info-status-created {
    font-weight: 500;
    font-size: 1.1em;
    color: #2ACD72;
}

.makeorder-page-info-status-pending {
    font-weight: 500;
    font-size: 1.1em;
    color: #f39c12;
}


.makeorder-page-buttons-row {
    display: flex;
    margin-top: 3.5vh;
}

.makeorder-page-buttons-total {
    display: flex;
    padding: 1vh 1vw;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #202020;
    margin-right: 2vw;
}

.makeorder-page-buttons-total-icon {
    font-size: 1.75em;
    color: var(--primary-color);
}

.makeorder-page-buttons-blank {
    flex: 1;
}

.makeorder-page-buttons-balance {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
    padding: 1vh 1vw;
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 850px) {
    .makeorder-page{font-size: 1.5vw;}
    .makeorder-page-button-cancelorder{
        margin-top: 2vh;
        height: 7vh;
        justify-content: center;
        margin-left: 0;
    }
    .makeorder-page-buttons-balance{
        width: 45%;
    }
    .makeorder-show-more-button{
        margin-top: 3vh;
    }
    .extra-margin{
        margin-bottom: 5vh;
    }
    .mobile{display: flex;}
    .desktop{display: none;}
    .makeorder-page{font-size: 2vw;}
    .makeorder-page-buttons-row{flex-direction: column;}
    .makeorder-page-button-right{
        margin-left: 0;
        margin-right: 6%;
        margin-top: 2vh;
        width: 30%;
    }
    .makeorder-page-buttons-total{
        margin-right: 10%;
        margin-bottom: 2vh;
        justify-content: center;
        height: 7.5vh;
        width: 45%;
    }
    .makeorder-page-buttons-balance{margin-bottom: 2vh; justify-content: center; height: 7.5vh}
    .makeorder-page-button-order{
        margin-left: 0;
        margin-top: 2vh;
        justify-content: center;
        height: 7.5vh;
        font-size: 1em;
    }
    .makeorder-page-buttons-section{margin: 2vh 0 !important; justify-content: center;}
}
@media only screen and (max-width: 450px) {
    .makeorder-page{font-size: 3vw;}
    .makeorder-page-info-texts{flex-direction: column;}
    .page-route-select{flex-wrap: wrap;}
    .makeorder-page-info-container{
        font-size: 3.5vw;
    }
}
</style>