<style lang="scss" scoped>
  @import "./monitors.page";
</style>
<template>
  <div>
    <div class="monitors">
      <div class="body">
        <div >
          <div class="">
            <div class="">
              <div class="">
                <PriceMonitor v-if="tab === 'price'"/>
                <cc-requests-tab v-if="tab === 'cotacoes'"/>
                <cc-resupplies-tab v-if="tab === 'ressuprimento'"/>
                <cc-mobile-requests v-if="tab === 'mobile'" />
                <cc-orders v-if="tab === 'pedidos'" :request_id="request_id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/cliente/base-components/Header";
import * as CONSTANTS from "@/constants/constants";
import OrdersTab from "./tabs/orders/_orders.tab";
import RequestsTab from './tabs/_requests.tab'
import PriceMonitor from './tabs/price.tab.vue'
import MobileRequestsTab from './tabs/_mobile.tab'
import ResuppliesTab from './tabs/_resupply.tab'
import VarejoFacilService from '@/services/VarejoFacilService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'

export default {
    mixins: [ loaderMixin ],
    props: {
        request_id: {
            type: Number
        },
        tab: {
            type: String,
            required: true,
            default: 'cotacoes'
        }
    },
    components: {
        ccHeader: Header,
        ccOrders: OrdersTab,
        ccRequestsTab: RequestsTab,
        ccResuppliesTab: ResuppliesTab,
        'cc-mobile-requests' : MobileRequestsTab,
        PriceMonitor,
    },
    data() {
        return {
            currentQuotation: null,
            CONSTANTS: CONSTANTS,
            vf_svc: new VarejoFacilService(),
            action_options: [
                { value: "", name: "Ações" },
                { value: "excluir", name: "Excluir" }
            ],
            breadcrumbs: [
                { name: "Painel de Operações", route: 'monitors-page' }
            ],
            list: [],
            searchInput: "",
            showPagination: false,
            page: 1,
            items_by_page_value: 10,
        }
    },
    methods: {
        check_vf_alert() {
            if(this.vf_svc.execute_alert()) {
                this.confirm_action({
                    message: "Atenção! Seus produtos Varejo Facil estão desatualizados a mais de 7 dias.",
                    confirm_text: "Atualizar agora!",
                    cancel_text: 'Deixar para depois!',
                    callback: () => {
                        this.present_loader('Iniciando agendamento de importação de produtos Varejo Facil...')
                        this.vf_svc.import_products().then(() => {
                            this.present_info('Agendamento realizado com sucesso!')
                            this.$store.dispatch('user/reload_user')
                        }).catch(e => {
                            this.dismiss_loader()
                            this.present_info('Ocorreu um erro durante a importação. É possível que a equipe responsável tenha sido notificada.')
                        })
                    }
                })
            }
        }
    },
    mounted() {
        this.$store.dispatch('set_statuses');
        this.check_vf_alert()
    }
};
</script>
