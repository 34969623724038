<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="selected-modal-title-container ">
                <span class="selected-modal-title">
                    Consulta Requisição
                </span>
                <span @click="closeDialog" class="material-icons selected-modal-closebtn-icon">
                    cancel
                </span>
            </div>
            <h2 class="modal-sub-title">{{ title }}</h2>
            <!-- desktop -->
            <selectProductTable :list="list"/>

            <!-- Mobile -->
            <selectProductTableMobile :list="list"/>
        </div>
    </div>
</template>

<script>
import selectProductTable from './selectProductTable.vue';
import selectProductTableMobile from './selectProductTableMobile.vue';

export default {
    name: 'SelectedProductModal',
    props:['list', 'closeDialog', 'title'],
    components:{
        selectProductTable,
        selectProductTableMobile
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 70vw;
    border-radius: 12px;
}
.selected-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.selected-modal-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}
.selected-modal-closebtn-icon {
  color: #FFFFFF;
  font-size: 3em;
  cursor: pointer;
}
.modal-sub-title{
    margin: 20px;
    color: #605F5F;
    font-size: 1.7em;
    font-weight: 600;
}
@media only screen and (max-width: 850px){
    .modal-content-container{
        width: 110vw;
    }
}
</style>