<template>
 <div class="modal-mask">
   <div class="modal-content-container">
	 <div class="manage-categories__main-container">
	   <div class="manage-categories__header-container">
          <span class="manage-categories__header-title">
            Gerenciar Categorias de Produtos
          </span>
          <span @click="closeDialog" class="material-icons manage-categories__closebtn-icon">
            cancel
          </span>
        </div>
		<div class="manage-categories__title-wrapper">
		  <span class="manage-categories__title">Minhas Categorias</span>
		  <StandardButton class="standard-btn desktop" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'"/>
		</div>
		<ValidationObserver v-slot="{ handleSubmit }">
		  <div class="standard-inputs__container">
			<ValidationProvider rules="required" v-slot="v">
		      <div class="standard-inputs__wrapper">
		          <div class="manage-categories__input-wrapper">
                    <span class="manage-categories__input-title">Código Interno</span>
		            <input :placeholder="'Digite o Código Interno'" v-model="new_category.cat_codInterno" class="manage-categories__input">
		          </div>
		      </div>
			<span style="margin: 2em;" class="error">{{ v.errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider rules="required" v-slot="v">
		      <div class="standard-inputs__wrapper">
		        <div class="manage-categories__input-wrapper">
                  <span class="manage-categories__input-title">Nova Categoria</span>
			        <div class="manage-categories__new-category__wrapper">
				      <input :placeholder="'Adicione uma Nova Categoria'" v-model="new_category.cat_descricao" @search="handleSubmit(create)" class="manage-categories__new-category-input">
			          <span @click="handleSubmit(create)" class="material-icons-outlined manage-categories__input-icon plus-icon">add</span>
			        </div>
		        </div>
		      </div>
			<span style="margin: 2em;" class="error">{{ v.errors[0] }}</span>
			</ValidationProvider>
		  </div>
		</ValidationObserver>
		<cc-loader v-if="loading" style="margin-top: 10vh;"/>
		<div v-else class="overflow-container">
          <div class="modal-table-container">
            <div class="modal-table-header">
              <div class="modal-table-colum">ID</div>
              <div class="modal-table-colum">Código Interno</div>
              <div class="modal-table-colum">Nome</div>
              <div class="modal-table-colum text-center">Ação</div>
            </div>
            <div v-for="(cat, idx) in categories"
				 :key="cat.id"
				 :class="{ selected: cat.selected, 'page-table-line-special': idx % 2 != 0 }">
              <div class="modal-table-row">
                <div class="modal-table-colum">
					<div class="manage-categories-mobile-header">ID</div>
					<b>#{{ cat.cat_id }}</b>
				</div>
                <div class="modal-table-colum">
					<div class="manage-categories-mobile-header">Código Interno</div>
					{{ cat.cat_codInterno }}
				</div>
                <div v-if="!cat.edit" class="modal-table-colum">
					<div class="manage-categories-mobile-header">Nome</div>
					<input v-model="cat.cat_descricao" :readonly="!cat.edit" class="manage-categories__chosen-category-input">
				</div>
                <div v-else class="modal-table-colum">
					<div class="manage-categories-mobile-header">Nome</div>
					<input v-model="cat.cat_descricao" :readonly="!cat.edit" class="manage-categories__chosen-category-input input-selected">
				</div>
                <div class="modal-table-colum ">
				  <div class="manage-categories-mobile-header">Ação</div>
				  <div class="text-center">
					<span v-if="!cat.edit && !cat.loading" @click="select(cat)" class="material-icons-outlined edit-btn">edit</span>
					<span v-if="cat.edit && !cat.loading" @click="update(cat)" class="material-icons-outlined save-btn">save</span>
					<span v-show="!cat.loading" @click.stop="remove(cat)" class="material-icons-outlined delete-btn">delete</span>
					<cc-loader style="left: -15vw;" v-show="cat.loading" :show_txt="false" class="loader float-right" />
				  </div>

				</div>
              </div>
			  <div class="mobile-table-row">
				<div style="display: flex; justify-content: space-between;">
					<div class="mobile-table-colum">
						<div class="manage-categories-mobile-header">Nome</div>
						<input v-if="!cat.edit" v-model="cat.cat_descricao" :readonly="!cat.edit" class="manage-categories__chosen-category-input">
						<input v-else v-model="cat.cat_descricao" :readonly="!cat.edit" class="manage-categories__chosen-category-input input-selected">
					</div>
					<div class="mobile-table-colum">
						<div class="manage-categories-mobile-header">ID</div>
						<p style="margin-top: 2.5vw;">#{{ cat.cat_id }}</p>
						
					</div>
					<div class="mobile-table-colum">
						<div class="manage-categories-mobile-header">Código Interno</div>
						<p style="margin-top: 2.5vw;">{{ cat.cat_codInterno }}</p>
					</div>
				</div>
				
				<div style="display: flex; justify-content: space-around;">
					
					
					<span v-if="!cat.edit && !cat.loading" @click="select(cat)" class="material-icons-outlined edit-btn">edit</span>
					<span v-if="cat.edit && !cat.loading" @click="update(cat)" class="material-icons-outlined save-btn">save</span>
					<span v-show="!cat.loading" @click.stop="remove(cat)" class="material-icons-outlined delete-btn">delete</span>
					<cc-loader style="left: -15vw;" v-show="cat.loading" :show_txt="false" class="loader float-right" />
				</div>
			  </div>
            </div>
          </div>
		  <tr v-show="total_items > items_by_page_value" class="seemore-button-containear" >
            <td class="text-center" colspan="6">
              <Paginacao classes="orange justify-end"
                         :items_by_page_value="items_by_page_value"
                         :total="total_items"
                         :page="page"
                         v-on:change="update_page($event)">
              </Paginacao>
            </td>
          </tr>
        </div>
	 </div>
   </div>
 </div>
	<!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents  p-4">
				<div class="col-12">
					<label for="" class="label-control">Minhas Categorias</label>
					<div class="col-12 mb-3">
						<ValidationObserver v-slot="{ handleSubmit }">
							<div class="row">
								<div class="col-2 px-2">
									<ValidationProvider rules="required" v-slot="v">
										<cc-input :placeholder="'Cod. Interno'" :theme="'theme-green'" class="mt-1" v-model="new_category.cat_codInterno" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col p-0">
									<ValidationProvider rules="required" v-slot="v">
										<cc-search :placeholder="'Nova Categoria'" :icon="'fa success fa-plus'" :theme="'theme-green'" class="mt-1" v-model="new_category.cat_descricao" @search="handleSubmit(create)" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
						</ValidationObserver>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">#ID</th>
									<th class="text-left px-3">Cod. Interno</th>
									<th class="text-left px-0">Nome</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="cat in categories" :key="cat.id" :class="{ selected: cat.selected }">
									<td class="text-left px-3"><i v-show="cat.selected" class="fas fa-hand-point-right mr-2 theme-blue"></i><b>#{{ cat.cat_id }}</b></td>
									<td class="text-left pl-4">
										{{ cat.cat_codInterno }}
									</td>
									<td class="text-left px-0">
										<cc-input v-model="cat.cat_descricao" :readonly="!cat.edit" />
									</td>
									<td class="text-center">
										<i v-if="!cat.edit" class="fa fa-pencil remove-btn" @click="select(cat)" />
										<i v-else class="fa fa-floppy-o remove-btn theme-green" @click="update(cat)" />
										<i v-show="!cat.loading" class="fa fa-trash remove-btn" @click.stop="remove(cat)" />
										<cc-loader v-show="cat.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal> -->
</template>

<script>

import CategoryService from "@/services/v1/category.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import Paginacao from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
    mixins: [ loaderMixin ],
	components: {
		ValidationProvider,
		ValidationObserver,
		StandardButton,
		Paginacao,
	},
	props: [ "closeDialog" ],
  	data() {
		return {
			page: 1,
            items_by_page_value: 30,
            total_items: 1000,
			categories: [],
			category: {},
			new_category: {},
			page: 1,
			filters: {
				name: '',
				total_items : 0,
				per_page: 0
			},
			loading: false,
			service: new CategoryService(),
			modal: {
				title: `gerenciar categorias de produtos`,
				subtitle: `Gerencie categorias de seus produtos para melhor organização de seu catálogo`,
				icon_title: 'fas fa-tags',
				cancel_text: 'Voltar',
				style: {
					width: "70%"
				}
			},
		};
	},
  	methods: {
		update_page(page) {
		  this.page = page;
		  load_categories()
		},
		select(cat) {
			this.categories.forEach(c => c.edit = false)
			cat.edit = true
			this.$forceUpdate()
		},
		update(cat) {
			this.loading = true
			this.service.update(cat).then((response) => response.data)
			.then((data) => this.load_categories())
			.then(this.loading = false)
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
				if(error.response.data && error.response.data) {
					this.present_error(error.response.data.mensagem)
				}
			});
		},
		create() {
			this.loading = true
			this.service.create(this.new_category)
			.then((response) => response.data)
			.then((data) =>  this.load_categories())
			.then(this.loading = false)
			.then(() => this.new_category = {})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
				if(error.response.data && error.response.data) {
					this.present_error(error.response.data.mensagem)
				}
			});
		},
		remove(cat) {

			this.confirm_action({
				message: "Confirma remoção da categoria?",
				subtitle: `Categoria #${cat.cat_descricao} será removida!`,
				callback: () => {
					this.$set(cat, 'loading', true)
					this.service.delete(cat.cat_id)
					.then(() => this.load_categories()).then(() => this.$set(cat, 'loading', false))
				}
			})
		},
		close() {
			this.$emit("close");
		},
		load_categories() {
			this.loading = true
			return this.service.all()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
				this.total_items = data.categorias;
				this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		}
	},
	mounted() {
		this.load_categories()
	}
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.manage-categories__main-container {
  width: 70vw;
  height: 110vh;
}
.manage-categories__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.manage-categories__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}
.manage-categories__closebtn-icon {
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
}
.manage-categories__title-wrapper {
  margin: 0em 0em 0em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.manage-categories__title {
  color: #707070;
  font-weight: 400;
  font-size: 2.2em;
}
.standard-inputs__container {
  display: flex;
  align-items: center;
}
.standard-inputs__wrapper {
  display: flex;
  align-items: center;
  margin: 0em 1.2em;
}
.manage-categories__input-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}
.manage-categories__input-title {
  color: #898989;
  font-weight: 400;
  font-size: 1.8em;
  letter-spacing: 0.15px;
  margin-bottom: 0.3em;
}
.manage-categories-mobile-header{display: none;}
.manage-categories__new-category__wrapper {
  display: flex;
  align-items: center;
}
.manage-categories__input {
  width: 30vw;
  height: 6vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.1em;
  color: #A1A1A1;
}
.manage-categories__chosen-category-input {
  width: 15vw;
  height: 5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.1em;
  color: #A1A1A1;
}
.manage-categories__new-category-input {
  width: 30vw;
  height: 6vh;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 1.1em;
  border-top: 2px solid #c2c2c3;
  border-left: 2px solid #c2c2c3;
  border-bottom: 2px solid #c2c2c3;
  border-right: none;
  border-radius: 5.23px 0px 0px 5.23px;
  color: #A1A1A1;
}
.manage-categories__input-icon {
  position: relative;
  width: 4vw;
  height: 6vh;
  left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 0px 5px 5px 0px;
  border-top: 1px solid #c2c2c3;
  border-bottom: 1px solid #c2c2c3;
  border-right: 1px solid #c2c2c3;
  cursor: pointer;
}
.standard-btn {
  width: 10vw;
  margin: 2em;
}
.overflow-container{
  overflow-x: auto;
  max-height: 55vh;
}
.modal-table-container{
  min-height: 40vh;
  margin: 2em;
}
.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-colum{
  flex: 1;
  padding-left: 0.5vw;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  margin-bottom: 1vh;
  align-items: center;
}
.edit-btn {
  margin-top: 0.2em;
  cursor: pointer;
  font-size: 1.8em;
}
.save-btn {
  color: var(--primary-color);
  margin-top: 0.2em;
  cursor: pointer;
  font-size: 1.8em;
}
.delete-btn {
  color: #CD2A2A;
  margin-left: 0.3em;
  cursor: pointer;
  font-size: 1.8em;
}
.input-selected {
  width: 15vw;
  height: 5vh;
  padding: 0px 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.1em;
  color: var(--primary-color);
}
.seemore-button-containear{
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
  max-width: 50vw;
}
.page-table-line-special{
  background: #F7F7F7;
}
.mobile-table-row{
	display: none;
}


@media only screen and (max-width: 800px) {
	.modal-mask{font-size: 2vw;}
  .standard-btn {
	margin: 1em auto;
	width: 65vw;
	margin-right: 2vw;
  }
  .manage-categories__header-title{
	font-size: 2em;
  }
  .manage-categories__title-wrapper {
	margin: 0.5 auto;
  }
  .manage-categories__input {
	margin: 1em auto;
	width: 47vw;
  }
  .manage-categories__new-category-input {
	margin: 1em 0em;
	width: 40vw;
  }
  .manage-categories__input-icon {
	position: relative;
    height: 6vh;
    left: -15px;
    width: 6vw;
  }
  .overflow-container {
    max-height: 70vh;
  }
  .delete-btn {
	margin-left: 0em;
  }
  .modal-table-header{display: none}
  .manage-categories__main-container{
	width: 110vw;
  }
  .modal-table-row{
	display: none;
  }
  .modal-table-colum {
	flex: unset;
	width: 100%;
	display: flex;
	justify-content: space-between
  }
  .manage-categories-mobile-header{
	display: flex;
	font-size: 1.2em;
	font-weight: 700;
  }
  .mobile-table-colum{
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  .manage-categories__chosen-category-input{width: 30vw}
  .desktop{
	display: none;
  }
  .mobile-table-row{
	  display: block;
	  padding-top: 1.5vw;
	  padding-bottom: 3vw;
  }
}
@media only screen and (max-width: 500px) {
	.modal-mask{font-size: 3vw;}
	.manage-categories__chosen-category-input{width: 45vw}
}

	@import "./manage-categories.modal";
</style>
