<template>
    <div class="cc-loader">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <span v-show="show_txt">{{ text || 'Carregando' }}</span>
    </div>
</template>

<script>
export default {
    props: {
        show_txt:  {
            type: Boolean,
            default: true
        },
        text: [ String ]
    }
}
</script>

<style lang="sass">
    @import 'loader.component';
</style>