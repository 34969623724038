<template>
    <div class="direct-order-container">
        <div class="direct-order-table-header">
            <div class="expand-width"></div>
            <div class="name-width">Produto</div>
            <div class="pack-width">Unidade de compra</div>
            <div class="cod-width">Código</div>
            <div class="last-buy-width">Qtd. Requisitada</div>
        </div>
        <div v-for="(product, idx) in products" :key="idx">
            <div class="direct-order-table-line"
                :class="{
                    'direct-order-special-background': idx % 2 == 0,
                    'direct-order-table-line-expand': product.isExpand,
                    'direct-order-subtable-changed': brandChange(product)
                    }"
                 >
                <div class="expand-width">
                    <div v-if="product.pro_generico" class="direct-order-expand-container">
                        <span v-if="product.isExpand" v-on:click="openBrands(product)"
                            class="material-icons-outlined direct-order-expand-icon">expand_more</span>
                        <span v-else v-on:click="openBrands(product)" class="material-icons-outlined direct-order-expand-icon">expand_less</span>
                    </div>
                    <div v-else class="direct-order-expand-container"></div>
                    
                    <span class="material-icons-outlined direct-order-add-icon" v-on:click="() => manage_brands( product)">add</span>
                </div>
                <div class="name-width">{{ product.pro_descricao }}</div>
                <div class="pack-width">{{ product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}</div>
                <div class="cod-width">{{ product.pro_ean }}</div>
                <div class="last-buy-width">{{ product.requested_amount }}</div>
            </div>
            <div v-if="product.isExpand">
                <div class="direct-order-subtable-header">
                    <div class="expand-width"></div>
                    <div class="name-width">Marca</div>
                    <div class="cod-width">Preço</div>
                    <!-- <div class="last-buy-width">Última compra</div> -->
                </div>
                <div class="direct-order-table-line" v-for="(brand, idx2) in product.brands" :key="idx2"
                    :class="{ 'direct-order-special-background': idx2 % 2 == 0, 'direct-order-subtable-changed': brand.isChanged }">
                    <div class="expand-width">
                        <!-- <span v-if="brand.isChanged" style="color: var(--primary-color);" class="material-icons-outlined direct-order-add-icon" v-on:click="() => manage_brands(product, brand)">edit</span>
                        <span v-else class="material-icons-outlined direct-order-add-icon" v-on:click="() => manage_brands(brand, product)">add</span> -->
                    </div>
                    <div class="name-width">{{ brand.pro_observacao }}</div>
                    <div class="cod-width">
                        {{ brand.price ? currency.format(brand.price) : '--' }}
                    </div>
                    <!-- <div class="last-buy-width">R$ --</div> -->
                </div>
            </div>
        </div>

        <QuantityModal v-if="showQuantityModal"
            :save="save"
            :brand="selectedBrand"
            :product="showQuantityModal"
            :temps="temps"
            :close="onCloseModal"/>

        <cc-loader-full v-if="isLoading"/>

        <!-- <div class="footer" :key="'footer'">
            XXXXXXX{{ total_items }}
            <cc-pagination :key="'pag'"
                            classes="orange"
                            :items_by_page_value="items_by_page_value"
                            :total="total_items"
                            :page="page"
                            :page_limit="11"
                            v-on:change="updatePage">
            </cc-pagination>
        </div> -->
    </div>
</template>

<script>
import QuantityModal from './QuantityModal.vue';
import ProductService from "@/services/ProductService";
import TemporaryRequestService from "@/services/v3/requests/temporary.service";
import Pagination from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
    mixins: [loaderMixin],
    components: {
        ccPagination: Pagination,
    },
    name: 'DirectOrderTab',
    props: ['products', 'items_by_page_value', 'total_items', 'updatePage', "page"],
    data() {
        return {
            currency: new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }),
            showQuantityModal: false,
            selectedBrand: {},
            temps: [],
            product_service : new ProductService(),
            tmp_svc: new TemporaryRequestService(),
            isLoading: false,
            counter: 0,
        }
    },
    created () {
        this.isLoading = true
        this.load_temp_products()
        this.load_counter()
    },
    components:{
        QuantityModal
    },
    watch: {
        'products': function(newVal) {
            this.isLoading = true
            this.load_temp_products()
            this.load_counter()
        }
    },
    methods:{
        count_requested(prod) {
            let result = 0
            if(prod && prod.brands) {
                prod.brands.forEach(p => {
                    this.temps.forEach(t => {
                        if(p.pro_id == t.product_id) {
                            result += t.quantity
                        }
                    })
                })
            }
            prod.requested_amount = result
        },
        load_temp_products() {
            return this.tmp_svc.load('REQUEST_WITH_PRE_ORDERS')
                .then(response => response.data)
                .then(data => {
                    this.temps = data
                    this.proces
                    this.products.forEach(p => this.update_temps(p))
                    this.isLoading = false
                })
        },
        load_counter() {
            return this.tmp_svc.count({ type: 'REQUEST_WITH_PRE_ORDERS' })
                .then(response => response.data)
                .then(data => {
                    this.counter = data.total
                    this.$emit('onupdate', this.counter)
                })
        },
        update_temps(product) {
            product.brands?.forEach(b => {
                let current = this.temps.find(tmp => tmp.product_id == b.pro_id) || {}
                b.price = current.unit_price
            })
            this.count_requested(product)
            this.$forceUpdate()
        },
        async onCloseModal() {
            this.showQuantityModal = false
        },
        brandChange(product) {
            let resp = false
            if (product.brands) product.brands.forEach(element => {if (element.isChanged) resp = true});
            return resp
        },
        manage_brands(product) {
            
            this.showQuantityModal = product;
        },
        save(temps) {
            this.is_loading = true
            this.present_loader("Salvando dados...")
            var promises = temps.filter(tmp => tmp.quantity != undefined).map(temp => {
                return this.tmp_svc.send(temp).then(response => response.data)
                    .then(data => {
                        this.is_loading = false
                        this.load_counter()
                        this.load_temp_products()
                        this.showQuantityModal = false
                    }).catch(() => {
                        this.is_loading = false
                    })
            })
            Promise.all(promises).then(() => {
                this.snack("Dados salvos com sucesso!")
                this.dismiss_loader()
            })
        },
        openBrands(product) {
            if (product.brands) {
                product.isExpand = !product.isExpand;
                this.$forceUpdate()
            } else {
              this.isLoading = true
                this.product_service.get_product_brands(product.pro_id).then(resp => {
                    product.brands = resp.data.produtos
                    product.isExpand = !product.isExpand;
                    this.isLoading = false
                    this.update_temps(product)
                })
            }
        }
    }
}
</script>

<style scoped>
.direct-order-table-header {
    display: flex;
    padding: 1.5vh 0;
    border: 1px solid lightgray;
    font-size: 1.3em;
    color: #605F5F;
}

.direct-order-subtable-header {
    display: flex;
    padding: 1.5vh 0;
    font-size: 1.1em;
    color: #605F5F;
    margin-top: 1vh;
    border-bottom: 2px solid lightgray;
}

.direct-order-container {
    margin-top: 2vh;
    margin-right: 2em;
}

.expand-width {
    width: 7%;
    display: flex;
}

.name-width {
    flex: 1;
}

.pack-width {
    width: 15%;
}

.cod-width {
    width: 15%;
}

.last-buy-width {
    width: 15%;
}

.direct-order-table-line {
    padding: 1.5vh 0;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    color: #605F5F;
    font-size: 1.1em;
}


.direct-order-expand-icon {
    font-size: 2em;
    color: var(--primary-color);
    box-sizing: border-box;
    cursor: pointer;
}
.direct-order-expand-container{
    width: 50%;
    display: flex;
    justify-content: center;
}

.direct-order-special-background {
    background: #FBFBFB;
}
.direct-order-table-line-expand{
    background: #E5E5E5;
}

.direct-order-add-icon{
    font-size: 1.5em;
    cursor: pointer;
}

.direct-order-quantity-button {
    outline: 1px solid var(--primary-color);
    text-align: center;
    padding: 0.5vh 0;
    width: 50%;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.direct-order-money-input {
    background: rgba(247, 247, 247, 0.5);
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0.5vh 1vw;
    width: 70%;
}
.direct-order-money-input:focus{
    outline: none;
}

.direct-order-subtable-changed {
    background: rgba(255, 113, 16, 0.15);
}
</style>
