import http from "../http";
import Service from "../../Service";

/**
 * @type OperationService
 */
export default class OperationService extends Service {

	load_products(params) {
		return http.get(`commons/operations/products`, {
            headers: this.getHeaders(),
            params
        });
	}

}
