<template>
    <div class="makeorder-page-table-unity-width">
        <div class="makeorder-page-table-mobile-header">Und Compra</div>
        <div style="display: flex; gap: 1vw; align-items: center;">
            <i class="far fa-edit" style="cursor: pointer; color: var(--primary-color)" @click="edit_wrap(product)"
                v-if="can('PRODUCT', 'UPDATE') && !subrequest.id_pedido" title="Editar quantidade"/>
            {{ product.pro_unidade_compra ? product.pro_unidade_compra : product.embalagem.split(" ")[0] }}
            
        </div>

    </div>
</template>

<script>
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    name: 'UntBuyLine',
    mixins: [ perm_mixin ],
    props: ['product', 'edit_wrap', 'subrequest']
}
</script>

<style scoped>
.makeorder-page-table-unity-width {
    width: 12%;
}

.makeorder-page-table-mobile-header {
    display: none;
}

@media screen and (max-width: 850px) {
    .makeorder-page-table-unity-width {
        width: fit-content !important;
        display: flex;
        align-items: baseline;
        gap: 2vw;
    }

    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>