import axios from 'axios';
import * as CONSTANTS from "@/constants/constants";
import { store } from '@/store/store';

let http = axios.create({
  baseURL: `${CONSTANTS.API_V3_URL}`,
  timeout: 20000
});

http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
    }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if ( error.request.status === CONSTANTS.REQUEST_CODE_TOO_MANY_REQUESTS) {
        const notification = {
            type: 'error',
            message: 'Você fez muitas requisições em um curto espaço de tempo, tente novamente em alguns segundos.'
        }
        store.dispatch('notification/add', notification)
    } else if ( error.request.status === CONSTANTS.REQUEST_CODE_UNAUTHORIZED) {
        const notification = {
            type: 'error',
            message: 'Sua sessão expirou.'
        }
        store.dispatch('notification/add', notification)
        // window.location.href = CONSTANTS.ROUTE_LOGIN;
        window.location.assign(CONSTANTS.ROUTE_LOGIN)
    }
    return Promise.reject(error);
});

export default http;
