<template>
    <div class="makeorder-page-table-quantity-width">
        <div class="makeorder-page-table-mobile-header">Qtd Emb.</div>
        {{ (product.flag_generico == 1 || product.pro_principal) ? product.quantidadeDigitacao : product.embalagem.split(" ")[1] }}
    </div>
</template>
<script>
export default {
    name: 'QtdEmbLine',
    props:['product']
}
</script>
<style scoped>
.makeorder-page-table-quantity-width{width: 7%;}
.makeorder-page-table-mobile-header{display: none;}
@media screen and (max-width: 850px) {
    .makeorder-page-table-quantity-width{
        gap: 2vw;
        display: flex; 
        align-items: center;
        width: fit-content !important;
    }

    .makeorder-page-table-mobile-header{
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}
</style>