import http from "./http";
import Service from "../Service";

export default class foodService extends Service {
    list (params, token) {
        return http.get('clients/management/products?not_load_derivatives=1', {
            cancelToken: token,
            headers: this.getHeaders(),
            params
        })
    }
    getBrandsInfo(productId){
		return http.get(`/admin/${productId}/group`, {
            headers: this.getHeaders()
		})
	}
    getBrands(productId){
        return http.get(`/clients/management/products/${productId}/getMarcasProduto`, {
            headers: this.getHeaders()
		})
    }
	getSupliers (pro_padrao_id, page, city, state) {
        let url = `/admin/management/products/${pro_padrao_id}/providers?page=${page}`
        if (city.cid_id) url += `&cid_id=${city.cid_id}`
        if (state.est_id) url += `&est_id=${state.est_id}`
		return http.get(url, {headers: this.getHeaders()})
	}
	getPrice (payload) {
        let url = `/admin/management/products/${payload.pro_padrao_id}/group?`
        if (payload.city) url = url + 'city=' + payload.city + '&'
        if (payload.fu) url = url + 'fu=' + payload.fu
		return http.get( url , {headers: this.getHeaders()})
	}
}