
<template>
    <form action="#" v-on="$listeners" v-bind="$attrs">
        <slot name="fields" />
    </form>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    form {
        margin: 10px;
        padding: 20px;
    }
</style>
