<template>
    <div id="print-request-report" class="container-fluid p-3 matrix-wrapper">
        <table class="table shadow" v-for="provider in providers" :key="provider.provider_id">
            <thead>
                <tr>
                    <th  class="provider-name">
                        <p><span>{{ provider.name }}</span> - {{ provider.seller_name }} - <span>{{ provider.seller_phone }}</span></p>

                    </th>
                    <th v-for="store in stores" :key="store.id" class="text-center store-quantity">
                        <span class="description store-quantity">
                            <i class="fas fa-store-alt" style="color: var(--primary-color);font-size: 1rem;"></i>
                            {{ store.name }}
                        </span>
                    </th>
                </tr>
                <tr>
                    <th class="text-center pro-col" >
                        Produto(
                            <span style="color: #20CE88;font-size: 1rem;">Total: {{ total_provider(provider) | currency }}</span>
                        )
                    </th>
                    <th class="text-center" v-for="store in stores" :key="'tot-' + store.id">
                        <span class="description store-total"
                            :class="{ cursor: total_store(provider, store.id) > 0, higher: total_store(provider, store.id) > 0 && total_store(provider, store.id) < provider.minimum_billing }">
                                {{ total_store(provider, store.id).toFixed(2) | currency }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(product, idy) in provider.products">
                    <tr :key="'name-' + product.id + '-prov-' + provider.provider_id">
                        <td  class="text-left border-bottom-0 pl-0">
                            <div class="col">
                                <p class="mb-0 pro-name" style="position:relative">
                                    {{ product.name }}
                                    <span class="brand">{{ product.brand }}</span>
                                    <i v-if="product.observations" class="fas fa-info-circle" :title="product.observations.toUpperCase()"></i>
                                </p>
                            </div>
                        </td>
                        <td v-for="(store, idx) in stores" :key="store.id" rowspan="2" >
                            <div class="column-quantity">
                                <input type="text" class="rounded form-control text-center m-auto"
                                     :data-idx="idy"
                                    :readonly="true"
                                    :data-idy="idx"
                                    :value="filter_product(product, store.id).requested_quantity"
                                    :ref="'y-' + idy + '-' + idx">
                            </div>

                        </td>
                    </tr>
                    <tr :key="product.pro_id">
                        <td class="text-center border-top-0 pt-0" >
                            <div class="col d-flex p-0">
                                <p class="mb-0 info">
                                    <span class="description">
                                        Vlr:
                                    </span>
                                    <span class="d-block text-center">
                                        <span :class="{ lower: product.offer_price < product.last_purchase, higher: product.offer_price > product.last_purchase, 'total-val': true }">
                                            {{ product.offer_price | currency }}
                                        </span>
                                    </span>
                                </p>
                                <p class="mb-0 info" title="ultimo preço">
                                    <span class="description">Ult Cmp:</span>
                                    <span class="d-block text-center">{{ product.last_purchase | currency }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Qtd:</span>
                                    <span class="d-block text-center">{{ quantity_sum(product.stores) }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Emb:</span>
                                    <span class="d-block text-center">
                                        {{ product.purchase_unity ? product.purchase_unity : `${product.packing.split(" ")[0]} ${product.packing.split(" ")[1]}` }}
                                    </span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Total:</span>
                                    <span class="d-block text-center total-val">{{ total(product.stores) | currency }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin ],
    props: [ "data" ],
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            products: null,
            providers: [],
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false
        }
    },
    methods: {
        filter_product(product, store_id) {
            let result = product.stores.find(s => s.store_id == store_id)
            return result || {}
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.requested_quantity || 0) }, 0)
        },
        total_provider(provider) {
            let result = 0;
            provider.products.forEach(p => {
                result += this.total(p.stores)
            })
            return result
        },
        total_store(provider, store_id) {
            let result = 0
            provider.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.store_id == store_id) {
                        let value = pp.is_generic && pp.main_product_id == null && this.user.request_type != 1 ? parseInt(pp.packing.split(' ')[1])*pp.offer_price : pp.offer_price
                        result += value*pp.requested_quantity
                    }
                })
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => {
                return sum + (
                    (store.requested_quantity || 0)*
                    (store.is_generic == 0 && store.main_product_id == null && this.user.request_type != 1 ?
                        parseInt(store.packing.split(' ')[1])*store.offer_price : store.offer_price)
                    )
            }, 0)
        },
        process_available_providers() {
            this.products.forEach(pp => {
                let prov = this.providers.find(prov => prov.provider_id == pp.provider_id && pp.seller_id == prov.seller_id)
                if(prov) {
                    let pro = prov.products.find(pr => pr.id == pp.id)
                    if(pro) {
                        pro.stores.push(pp)
                    } else {
                        prov.products.push({ ...pp, stores: [ pp ] })
                    }
                } else {
                    this.providers.push({
                        provider_id: pp.provider_id,
                        seller_id: pp.seller_id,
                        seller_name: pp.seller_name,
                        seller_phone: pp.seller_phone,
                        name: pp.provider_name, products: [ { ...pp, stores: [ pp ] } ]
                    })
                }
            })
        },
        process_available_stores() {
            this.stores.forEach(store => {
                this.products.forEach(p => {
                    p.stores = []
                    if(store.id == p.store_id) {
                        if(!store.products) {
                            store.products = []
                        }
                        if(store.id == p.store_id) {
                            store.products.push(p)
                        }
                    }
                })
            })
        },
        sort_products() {
            this.providers.forEach(p => {
                p.products.sort((a, b) => {
                    if (a.name > b.name) return 1
                    if (b.name > a.name) return -1
                    return 0
                })
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
				if (a.position > b.position) return 1
				if (b.position > a.position) return -1
				return 0
			})
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
        this.products = this.data
        this.sort_stores()
        this.process_available_stores()
        this.process_available_providers()
        this.sort_products()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .total-provider {
        color: $ternary-color;
    }

    .table {
        margin: 50px 0;
        &:first-child {
            margin-top: 0 !important;
        }
    }
    .provider-name {
        max-width: 10vw;
        text-align: center;
        font-size: 0.8rem;
        p{margin-bottom: 0;}
        span {
            font-weight: bolder;
            color: var(--primary-color);
        }
    }

    .pro-name {
        font-weight: bold;
        font-size: 0.8rem;
        .brand {
            color: var(--primary-color);
        }
    }
    .info {
        width: 20%;
        margin-left: 10px;
    }

    th.store-quantity {
        line-break: anywhere;
        white-space: revert;
        cursor: pointer;
        &:hover {
            background-color: var(--primary-color);
            span, i {
                color: white !important;
            }
        }
    }
    .store-quantity {
        width: 7vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    input.store-quantity {
        width: 100%;
    }

    input.store-quantity-reduce {
        width: 60%;
    }

    .total {
        border-right: 1px dashed #ece9e9;
    }

    .total-val {
        white-space: nowrap;
    }

    span.description {
        // width: fit-content;
        font-size: 0.8rem;
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: 4px;
        margin-left: 2px;
        display: inline-block;
        text-align: center;
        color: $gray2;
    }

    tr:hover {
        td {
            background-color: white;
        }
    }

    .quantity-loader {
        position: absolute;
        width: 25%;
        top: -47px;
        left: 83%;
        height: fit-content !important;
    }

    .lower {
        background-color: $higher-green;
        border-radius: 6px;
        color: white;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .higher {
        color: white !important;
        border-radius: 6px;
        background-color: $lower-red;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .fa-store-alt {
        margin-right: 4px;
    }

    .fa-info-circle {
        color: $secondary-color;
        cursor: pointer;
    }

    .cursor {
        cursor: pointer;
    }

    .description.store-total {
        text-align: center;
        color: $ternary-color;
    }

    .obs {
        color: $secondary-color;
    }

    i.fa-handshake-o {
        width: 10px;
        padding-left: 4px;
        color: $orange;
        cursor: pointer;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .matrix-wrapper {
        overflow-x: scroll;
    }

    .pro-col {
        min-width: 600px;
    }

    .column-quantity{
        display: flex;
        justify-content: flex-start;
    }
    .column-stock{
        width: 45px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }
    @media print {
        .shadow{
            box-shadow: none !important;
            border-bottom: 3px solid var(--primary-color);
            border-left: 1px solid lightgray;
            border-right: 1px solid lightgray;
            margin-bottom: 10vh;
        }
        .provider-name{
            p{background-color: lightgoldenrodyellow !important;}
        }
    }

</style>
