<template>
    <div class="makeorder-page-table-totalprice-width">
        <div class="makeorder-page-table-mobile-header">Total</div>
        {{ getDiff(product)  }}
    </div>
</template>

<script>
export default {
    name: 'varLine',
    props: ['product'],
    methods:{
        getDiff(produto) {
            if (localStorage.getItem('cli_utilizaPrecoBase') != 1) return '--'
            if (!produto.ultimaCompra) return this.$options.filters.currency(0)
            return this.$options.filters.currency(produto.menorPreco - produto.ultimaCompra)
        },
    }
}
</script>

<style scoped>
.makeorder-page-table-totalprice-width{width: 7%;}


.makeorder-page-table-mobile-header {
    display: none;
}



@media screen and (max-width: 850px) {
    .makeorder-page-table-totalprice-width{
        width: fit-content !important; 
        display: flex; 
        gap: 2vw;
        align-items: baseline;
    }


    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>