<template>
    <div v-if="account" >
        <div v-if="countdow" class="contract-alert-container">
            <div class="contract-alert-progress-bar">
                <div :style="getProgress()" class="contract-alert-progress"></div>
            </div>
            <div class="contract-alert-text">Em <strong>{{daysLeft}} dias</strong> sua assinatura vai expirar</div>
            <router-link to="/configuracoes/plan" class="contract-alert-button">Ative seu Plano</router-link>
        </div>
        <div v-else class="contract-alert-container contract-alert-cancel-warning">
            Ative seu plano agora e cancele quando quiser sem pagar nada!
        </div>
    </div>
</template>

<script>
import StorageService from '@/services/TokenService';

export default {
    name: 'ContractAlert',
    data () {
        return {
            storage_svc: new StorageService(),
            account: undefined,
            daysLeft: 0,
            countdow: true
        }
    },
    created () {
        this.check()
    },
    watch:{
        $route (to, from){
            if (to.name == 'user-settings') this.countdow = false
            else this.countdow = true
            this.check()
        }
    },
    methods: {
        check () {
            if (this.storage_svc.get('cli_vindi') == 1 && JSON.parse(this.storage_svc.get('contracts')).length == 0) {
                this.account = JSON.parse(this.storage_svc.get('vindiAccount'))
                if(!this.account) return
                const dateNow = new Date()
                const contractDate = new Date(this.account.updated_at)
                contractDate.setDate(contractDate.getDate() + 15)
                const diff = contractDate - dateNow
                this.daysLeft = Math.round(diff/(1000*60*60*24))
                if( this.$route.name != "login" && this.$route.name != "user-settings" && this.daysLeft < 1 ) {
                    this.$router.push('/configuracoes/plan');
                }
            }
        },
        getProgress(){
            const resp = (100 * this.daysLeft) / 15
            if (0 < resp && resp < 100) return 'width: ' + (100 - resp) + '%'
        }
    }
}
</script>

<style>
.contract-alert-container{
    background-color: rgba(255, 113, 16, 0.3);
    top: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    padding: 1vh 0;
    font-size: 1vw;
}
.contract-alert-progress-bar{
    width: 20vw;
    background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    height: 3vh;
}
.contract-alert-progress{
    background-color: var(--primary-color);
    border-radius: 10px;
    height: 100%;
}
.contract-alert-text{
    font-weight: 400;
    font-size: 1.65em;
    color: #303030;
}
.contract-alert-button{
    font-weight: 500;
    font-size: 1.3em;
    color: #FFFFFF;
    background-color: var(--primary-color);
    border-radius: 8px;
    padding: 0.5vh 2vw;
}
.contract-alert-button:hover{
    color: var(--primary-color);
    background-color: white;
    outline: 1px solid var(--primary-color);
}
.contract-alert-cancel-warning{
    font-weight: 600;
    font-size: 1.5em;
    color: var(--primary-color);
}
@media only screen and (max-width: 770px){
    .contract-alert-container{ font-size: 1.5vw;}
}
@media only screen and (max-width: 500px){
    .contract-alert-container{ font-size: 2.5vw;}
}

</style>
