<style lang="scss" scoped>
	@import "@/sass/commons/_variables";
    @import '@/sass/commons/_tables';
    .info {
        // border: 3px solid #e0e0e0 !important;
        border-radius: 7px;
        padding: 1rem 5rem;
    }
    .provider-header {
        border-radius: 10px;
        border: 1px solid #e8e9ec;
        padding: 10px;
        height: 56px;
        background: #f5f6fa;
        .info-block {
            align-items: flex-start;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0rem;
            justify-content: center;
            a, div, span {
                color: #00000096 !important;
                display: flex;
                align-items: center;
            }
            i {
                font-size: 1.9rem;
                position: relative;
                color: $primary-color;
                cursor: pointer;
            }
            &.provider-total div {
                color: red !important;
                &.valid-amount {
                    color: $ternary-color !important;
                }
            }
        }
        .name {
            padding-left: 10px;
            font-family: Rubik !important;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.15;
            letter-spacing: normal;
            text-align: left;
            color: black !important;
        }
    }
    .resume-item-list {
        margin: auto;
        // border: 1px solid honeydew;
        border-radius: 10px;
        padding: 0px 30px;
        &
        .row {
            margin: 15px 0;
            margin-bottom: 10px;
            .prod-name {
                text-transform: uppercase;
                height: 70px;
                i {
                    color: $primary-color;
                }
            }
            .resume-item {
                font-size: 1.2rem;
                color: $secondary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                    margin-right: 10px;
                }
                .fa-coins {
                    color: orange;
                }
                .fa-boxes {
                    color: $ternary-color;
                }
            }
        }
    }
    .separator {
        border-bottom: 1px solid $gray1;
        padding-bottom: 15px;
    }

    .resume-title {
        color: $primary-color;
        font-size: 1.2rem;
        margin-bottom: 10px;
        font-weight: lighter;
    }
    .text-box {
        color: #C9CCD3;
        word-wrap: wrap;
    }
    .resume-header {
        color: gray;
        .info-block {
            color: #4D4F5C;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 250;
            .tag {
                text-transform: uppercase;
                color: #333538ad;
            }
            span {
                padding: 3px 0;
                width: fit-content;
                &.provider {
                    padding: 0.2rem 0.8rem;
                    background-color: #008000a6;
                    color: white;
                    border-radius: 9.6px;
                    font-weight: normal;
                }
            }
        }
    }
    table {
        th:first-child {
            width: 40%;
        }
        th:nth-child(3) {
            width: 10%;
        }
        th:nth-child(6) {
            width: 10%;
        }
        .fa.fa-trash {
            color: $brand-danger;
            font-size: 1.2rem;
            cursor: pointer;
        }
        .control {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                width: 40px;
            }
            button {
                height: 30px;
                width: 10px !important;
                color: white;
                &:focus {
                    outline: none;
                }
                &:first-child {
                    margin-right: 7px;
                    background-color: $lower-red;
                }
                &:last-child {
                    background-color: $ternary-color;
                    margin-left: 7px;
                }
            }
        }
    }
    small.obs {
        background-color: yellow;
        padding: 2px 6px;
        font-size: 13px;
        opacity: 0.6;
        font-weight: 800;
        margin-left: 20px;
    }
    .VueCarousel-slide img {
        border-radius: 15px;
    }
    label.total{
        width: 100%;
        border: 1px solid #e8e9ec;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        border-radius: 5px;
        background-color: #6081E8;
        height: 56px;
        padding: 14px 24px;
        text-transform: uppercase;
        margin-bottom: 0;
        color: white;
        font-family: sans-serif;
    }
</style>
<template>
	<div class="row">
        <div class="col col-12 text-center resume-title">Resumo do Pedido</div>
        <div class="col col-12 info shadow">
            <div class="row resume-header">
                <div class="col col-6 text-left" v-if="cart.store != null">
                    <div class="row">
                        <div class="col col-7 info-block">
                            <span class="tag">Comprador</span>
                            <span>
                                <cc-select-v2
                                    :tracker="'id'"
                                    :text="'name'"
                                    :placeholder="'-'"
                                    :options="stores"
                                    @select="(val) => select_store(val)"
                                    v-model="cart.store" />
                            </span>
                            <span>
                                Fone: <cc-whatsapp :phone="cart.store.phone" />
                            </span>
                            <span>{{ cart.store.street }}</span>
                            <span>{{ cart.store.district }}</span>
                            <span>{{ cart.store.city.name }}/{{ cart.store.state.fu }}</span>
                            <span>{{ cart.store.name }}</span>
                            <span>{{ cart.store.cnpj | cnpj }}</span>
                        </div>
                        <div class="col col-5 text-left text-box info-block">
                            <span class="tag">Observação do pedido</span>
                            <textarea
                                class="form-input"
                                cols="20"
                                rows="6"
                                v-model="obs" />
                        </div>
                    </div>
                </div>
                <div class="col col-6 text-left" v-else>
                    <p class="text-center">Informe uma loja para continuar</p>
                    <cc-select-v2
                        :tracker="'id'"
                        :text="'name'"
                        :placeholder="'-'"
                        :options="stores"
                        @select="(val) => select_store(val)"
                        v-model="store" />
                </div>
                <div class="col col-6">
                    <carousel
                        :autoplay="true"
                        :perPage="1"
                        :loop="true"
                        :autoplayTimeout="8000"
                        :navigationEnabled="false"
                        :navigationClickTargetSize="3"
                        :paginationEnabled="false" style="width:99%;border-radius: 15px;">
                        <slide v-for="(banner, i) in banners" :key="i">
                            <img :src="banner" style="height:300px;width: 100% !important;"  />
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
        <div class="col col-12 resume-item-list">
            <div class="row p-2 m-0" v-for="prov in cart.providers" :key="prov.id">
                <div class="col p-0">
                    <div class="row">
                        <div class="col col-10 p-0">
                            <div class="row provider-header">

                                <div class="col col-4 text-left info-block">
                                    <div>
                                        <span data-v-ca44c69a="" class="expand-controller">
                                            <i data-v-ca44c69a="" class="fas fa-angle-right"></i>
                                        </span>
                                        <span data-v-ca44c69a="" class="name text-upper">
                                            {{ prov.name }}<br>
                                            {{ prov.cnpj | cnpj }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col col-2 text-center info-block">
                                    <div>
                                        <cc-whatsapp :phone="prov.phone" />
                                    </div>
                                </div>
                                <div class="col col-4 text-center info-block">
                                    <div>{{ prov.email ? prov.email : '-' }}</div>
                                </div>
                                <div class="col col-2 text-center info-block provider-total">
                                    <div :class="{ 'valid-amount': total_for(prov) >= prov.minimum_billing }"
                                        :title="total_for(prov) < prov.minimum_billing ? 'Faturamento mínimo do não alcançado' : 'Faturamento mínimio atingido'">
                                        Fat. Min: {{ prov.minimum_billing | currency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-2 pr-0">
                            <div class="row">
                                <div class="col p-0 text-center info-block">
                                    <label class="total">Total: {{ total_for(prov) | currency }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row resume-header">
                        <div class="col p-0">
                            <div class="responsive-table">
                                <table class="table">
                                    <thead class="gray">
                                        <tr>
                                            <th class="text-left pl-4">
                                                <i class="fas fa-boxes"></i>
                                                Produto
                                            </th>
                                            <th class="text-center">
                                                Embalagem
                                            </th>
                                            <th class="text-center">
                                                Quantidade
                                            </th>
                                            <th class="text-center">
                                                Valor Unitario
                                            </th>
                                            <th class="text-center">
                                                Valor Embalagem
                                            </th>
                                            <th class="text-center">
                                                Subtotal
                                            </th>
                                            <th class="text-center">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="i in prov.items" :key="i.item.id">
                                            <td class="prod-name pl-4">
                                                <i class="fa fa-tags"></i>
                                                {{ i.item.product.name }}<br>
                                                <small v-if="i.item.obs" class="obs">{{ i.item.obs }}</small>
                                            </td>
                                            <td class="text-center">
                                                {{ i.wrap }}/{{ i.wrap_quantity }}
                                            </td>
                                            <td class="text-center">
                                                <div class="control">
                                                    <cc-button
                                                        :nolink="true"
                                                        content="-"
                                                        @click="remove_item(i.item)" />
                                                    <span>{{ i.quantity }}</span>
                                                    <cc-button
                                                        :nolink="true"
                                                        content="+"
                                                        @click="add_item(i.item)" />
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                {{ i.item.unit_price | currency }}
                                            </td>
                                            <td class="text-center prod-quantity">
                                                {{ i.item.wrap_price | currency }}
                                            </td>
                                            <td class="text-center prod-subtotal">
                                                {{ i.total | currency }}
                                            </td>
                                            <td class="text-center">
                                                <i class="fa fa-trash" @click="update_quantity({ item: i.item, quantity: 0 })"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Pagination from "@/components/cliente/base-components/Pagination";
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import StoreService from "@/services/v3/stores/store.service"

export default {
    mixins: [ perm_mixin ],
    props: [ "id" ],
	components: {
        ccPagination : Pagination
   },
  	data() {
		return {
            store_svc: new StoreService(),
            obs: null,
            stores: [],
            store: {},
            banners: [
                "/images/campaing-cart-img1.png",
                "/images/campaign-cart-img2.png"
            ]
        }
	},
    watch: {
        obs: function(val) {
            this.set_obs(val)
        }
    },
    computed: {
        ...mapState([ 'cart' ]),
        ...mapGetters('cart', [ 'total', 'total_amount', 'total_for' ])
    },
  	methods: {
        ...mapActions('cart', [ 'set_obs', 'set_store', 'add_item', 'remove_item', 'update_quantity' ]),
        load_stores() {
            this.store_svc.load().then(response => response.data).then(data => {
                this.stores = data
            })
        },
        select_store(store) {
            if(store) {
                this.set_store(store)
            }
        }
	},
    mounted() {
        this.load_stores()
    }
};
</script>
