<template>
  <div class="resume-order-page">
    <div class="page-title">Resumo do Pedido</div>
    <div class="page-title-buttons not-print">
      <div class="cart-button-containear mr-3 ">
        <img src="@/assets/cart.svg" alt="carrinho" class="cart-button-img">
        <div class="cart-button" @click="$router.push('/ofertas/')">Continuar Comprando</div>
      </div>
      <div class="cart-button-containear ">
        <img src="@/assets/scanner.svg" alt="press" class="cart-button-img">
        <div class="scanner-button" v-on:click="printOrder()">Imprimir</div>
      </div>
    </div>
    <div class="select-store-title">
      Empresa
      <img class="select-store-title-icon" v-if="selectedStore == ''" src="@/assets/warning-red.svg" alt="aviso">
    </div>

    <div class="select-store-containear">
      <div class="select-store-input-containear">
        <select class="select-store-input" v-model="selectedStore">
          <option disabled value="">Selecione uma Empresa  para finalizar</option>
          <option v-for="store in stores" :key="store.id" :value="store">{{store.name}}</option>
        </select>
      </div>
      <div class="order-total-containear">
        <div class="order-total">
          Total  {{getTotalAmount()}}
        </div>
        <div class="end-order" v-on:click="sendOrder()" :class="checkEndOrder()">
          FINALIZAR COMPRA
        </div>
      </div>
    </div>

    <div class="client-info-containear" v-if="selectedStore != ''">
      <div class="client-info-containear-title">
        <div class="client-info-dot"></div>
        <div class="client-info-containear-title-text">Empresa selecionada</div>
      </div>
      <div class="client-info-containear-content">
        <div class="store-info-colum">
          <div class="store-info-colum-title">
            CNPJ:
          </div>
          <div class="store-info-colum-text">
            {{selectedStore.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}}
          </div>
          <div class="store-info-colum-title">
            Razão  Social:
          </div>
          <div class="store-info-colum-text">
            {{selectedStore.social_reazon}}
          </div>
          <div class="store-info-colum-title">
            Nome fantasia:
          </div>
          <div class="store-info-colum-text">
            {{selectedStore.trading_name}}
          </div>
        </div>
        <div class="store-address-colum">
          <div class="store-info-colum-title">
            Endereço:
          </div>
          <div class="store-info-colum-text">
            <div>{{selectedStore.street}}</div>
            <div>{{selectedStore.district}}</div>
            <div>{{selectedStore.city.name}} / {{selectedStore.state.fu}}</div>
            <div v-if="selectedStore.complement != 'SEM COMPLEMENTO'">{{selectedStore.complement}}</div>
            <div>{{selectedStore.name}}</div>
          </div>
        </div>
        <div class="obs-containear">
          <div class="store-info-colum-title">
            Observação:
          </div>
          <div class="obs-textarea-containear">
            <textarea class="obs-textarea" v-model="obs" placeholder="Digite aqui ser houver alguma observação" cols="50" rows="8"></textarea>
          </div>
        </div>
      </div>

    </div>
    <div class="order-title">
      Pedido
    </div>
    <div v-for="provider in cart.providers" :key="provider.id">
      <div class="provider-containear">
        <div class="provider-containear-title">
          <div class="provider-containear-title-row">
            <img src="@/assets/colapse.svg" @click="showBody(provider)" alt="minimizar" v-if="provider.showBody" class="expand-icon">
            <img src="@/assets/expand.svg" @click="showBody(provider)" alt="expandir" v-else class="expand-icon">
            <div class="provider-containear-name" v-on:click="openProviderInfo(provider)" title="Visualizar detalhes">
              {{provider.name}}
            </div>
            <div class="provider-containear-cnpj mobile-hide">{{provider.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}}</div>
            <div class="provider-containear-email mobile-hide">{{provider.email}}</div>
            <div class="provider-containear-min mobile-hide">Fat. Min:{{provider.minimum_billing.toLocaleString('pt-BR', {
                                              currency: 'BRL',
                                              style: 'currency',
                                              minimumFractionDigits: 2
                                          })}}</div>
            <div class="provider-containear-total">TOTAL {{total_for(provider).toLocaleString('pt-BR', {
                                              currency: 'BRL',
                                              style: 'currency',
                                              minimumFractionDigits: 2
                                          })}}</div>


          </div>
          <div class="provider-containear-title-obs">OBS: {{provider.campaign.obs}}</div>
        </div>
        <div v-if="!provider.showBody" class="provider-body">
          <div class="provider-body-title">
            <div class="width-30 provider-body-colum">Produto</div>
            <div class="width-20 provider-body-colum mobile-hide">Embalagem</div>
            <div class="provider-body-colum mobile-hide">Quantidade</div>
            <div class="provider-body-colum value-colum">Valor</div>
          </div>
          <div v-for="item in provider.items" :key="item.item.id" class="provider-body-item-containear">
            <div class="provider-body-colum width-30 provider-body-item-name">
              <img :src="item.item.product.cover_url" :alt="item.item.product.name" class="provider-body-item-img">
              <div>
                <div class="provider-body-item-name-text">{{item.item.product.name}}</div>
                <div v-if="item.item.obs" class="provider-body-item-desc-text">{{item.item.obs}}</div>
              </div>

            </div>
            <div class="provider-body-colum width-20 mobile-hide">
              <div class="provider-body-item-package">
                <img src="@/assets/package.svg" alt="pacote" class="package-icon">
                P/T {{item.item.wrap_quantity}} {{item.item.wrap_price.toLocaleString('pt-BR', {
                                            currency: 'BRL',
                                            style: 'currency',
                                            minimumFractionDigits: 2
                                        })}}
              </div>
              <div class="provider-body-item-unity-price">
                Valor unitario  {{item.item.unit_price.toLocaleString('pt-BR', {
                                            currency: 'BRL',
                                            style: 'currency',
                                            minimumFractionDigits: 2
                                        })}}
              </div>
            </div>
            <div class="provider-body-colum mobile-hide provider-body-quantity-containear">
              <div class="provider-body-quantity-subtrack" v-on:click="() => {remove_item(item.item), item.item.isChanged = true}">-</div>
              <!-- <div class="provider-body-quantity-value">{{cart_item_for(item.item).quantity}}</div> -->
              <input class="provider-body-quantity-value" :style="getChangeInput(item.item.isChanged)" type="text" :value="cart_item_for(item.item).quantity" v-on:change="event => {setNewValue(event, item.item)}">
              <div class="provider-body-quantity-add" @click="() => {add_item(item.item), item.item.isChanged = true}">+</div>
            </div>
            <div class="provider-body-colum value-colum">
              {{item.total.toLocaleString('pt-BR', {
                                            currency: 'BRL',
                                            style: 'currency',
                                            minimumFractionDigits: 2
                                        })}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <ProviderDetailsModal v-if="showProviderDetailsModal"
                              :closeDialog="() => this.showProviderDetailsModal = false"
                              :providerInfo="providerInfo"/>
  </div>
</template>

<script>
import StoreService from "@/services/v3/stores/store.service"
import OrderService from "@/services/v3/campaigns/order.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { mapState, mapActions, mapGetters } from 'vuex'
import ProviderDetailsModal from '@/modals/providerDetailsModal.vue'


export default {
  name: 'ResumeOrderView',
  mixins: [ loaderMixin ],
  components:{ProviderDetailsModal},
  data(){
    return {
      store_svc: new StoreService(),
      order_service: new OrderService(),
      selectedStore: '',
      stores: [],
      obs: undefined,
      showProviderDetailsModal:undefined,
      providerInfo: undefined,
      isLoading: false
    }
  },
  watch:{
    selectedStore(store){
      this.set_store(store)
    },
    obs(text){
      this.set_obs(text)
    }
  },
  methods: {
    getChangeInput(value){
        if (value) return 'background-color: lightgreen; color: rgb(66 66 66)'
    },
    setNewValue(event, item){
        this.update_quantity({item: item, quantity:event.target.value})
        item.isChanged = true
        this.$forceUpdate()
    },
    ...mapActions('cart', [ 'remove_item', 'set_store', 'set_obs', 'add_item', 'reset', 'update_quantity']),
    openProviderInfo(provider){
      this.showProviderDetailsModal = true
      this.providerInfo = provider
    },
    load_stores() {
        this.store_svc.load().then(response => response.data).then(data => {
            this.stores = data
        })
    },
    checkEndOrder(){
      if (this.selectedStore == '') return 'end-order-disabled'
      if (this.cart.providers.length < 1) return 'end-order-disabled'
      return ''
    },
    showBody(provider){
      provider.showBody = !provider.showBody
      this.$forceUpdate()
    },
    getTotalAmount(){
      let total = 0
      for (let i = 0; i < this.cart.providers.length; i++){
        total += this.total_for(this.cart.providers[i])
      }
      return total.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2
                })
    },
    sendOrder() {
      if (this.selectedStore == '') return false
      if (this.cart.providers.length < 1) return false
      let cont = true
      this.cart.providers.forEach(p => {
        if (this.total_for(p) < p.minimum_billing) {
          this.snack('Atenção: preço minimo não atendido para loja ' + p.name, 'error');
          cont = false
        }
      })
      if (!cont || this.isLoading) return false
      this.isLoading = true
      this.cart.providers.forEach(p => {
          this.order_service.send_order(this.cart_request_for(p)).then(response => response.data).then(data => {
            this.confirmOrder('Pedido enviado com sucesso!')
            this.reset()
            this.selectedStore = ''
            this.snack('Pedido enviado com sucesso - ' + p.name, 'success')
            this.isLoading = false
          }).catch(err => {
              ErrorHandlerService.handle(err, this.$store);
              let msg = "Ops! Ocorreu um erro ao enviar o pedido!"
              this.snack(p.name + '-' + msg, 'success')
              this.isLoading = false
          })
      })
    },
    printOrder(){
      print()
    },
  },
  computed: {
      ...mapState([ 'cart' ]),
      ...mapGetters('cart', [ 'total', 'total_amount', 'total_for', 'cart_item_for', 'cart_request_for' ])
  },
  mounted() {
    this.load_stores()
  }
}
</script>

<style scoped>
  .resume-order-page{padding-bottom: 3vh; padding-right: 4vw;}
.page-title{
  font-weight: 600;
  font-size: 40px;
  color: #605F5F;
}
.page-title-buttons{
  display: flex;
  margin-top: 20px;
  color:var(--primary-color);
  align-items: center;
  height: 60px;
}
.cart-button-containear{
  display: flex;
  align-items: center;
  border: 0.5px solid #E5E5E5;
  border-radius: 8px;
  padding: 10px 19px;
}
.cart-button-containear:hover{
  background: var(--primary-color);
  color: white;
}
.cart-button-containear:active{
  padding: 14px 25px;
  margin-bottom: 0;
}
.cart-button-containear:hover > .cart-button-img{
  filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(0%) hue-rotate(172deg) brightness(101%) contrast(101%);
}
.cart-button{
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
}
.scanner-button{
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
}
.select-store-containear{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-right: 50px;
  min-height: 50px;
}
.select-store-title{
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.select-store-title-icon{
  height: 24px;
  margin-left: 10px;
}
.select-store-input-containear{
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  padding: 6px;
  width: 45%;
  display: flex;
  align-items: center;
  color: #6e6e6e;
}
.client-info-containear-title{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.client-info-containear-title-text{
  font-weight: 600;
  font-size: 25px;
  color: #605F5F;
}
.select-store-input{
  border: none;
  color: #ABABAB;
  width: 100%;
  cursor: pointer;
}
.select-store-input:focus{
  outline: none;
}
.width-30{
  width: 40% !important;
}
.width-20{
  width: 20% !important;
}
.order-total{
  border: 1px solid #E5E5E5;
  color: #6e6e6e;
  padding: 4px 10px;
  margin-left: 10%;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}
.end-order{
  background: #2ACD72;
  border-radius: 0 5px 5px 0;
  padding: 4px 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.end-order-disabled{
  background: #E5E5E5;
  cursor: default;
}
.client-info-containear{
  border: 1px solid #F2F2F2;
  padding: 5px;
  margin-top: 10px;
  border-radius: 5px;
}
.client-info-containear-content{
  display: flex;
}
.client-info-dot{
  height: 25px;
  width: 25px;
  border: 8px solid green;
  border-radius: 50%;
  margin: 5px;
}
.store-info-colum{
  margin-left: 10px;
  width: 30%;
}
.store-info-colum-title{
  color: #605F5F;
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 5px;
}
.store-info-colum-text{
  color: #605F5F;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
}
.store-address-colum{
  width: 30%;
}
.obs-containear{
  width: 50%;
}
.obs-textarea{
  border: none;
  resize: none;
  border-radius: 5px;
  color: #ABABAB;
  padding: 5px;
  font-weight: 300;
  font-size: 16px;
}
.obs-textarea-containear{
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.obs-textarea:focus{
  outline: none;
}
.provider-containear{
  padding: 10px;
}
.provider-containear-title{
  background: #FFF2F2;
  color: #605F5F;
  font-weight: 700;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 5px;
}
.provider-containear-title-row{
  display: flex;
}
.provider-containear-title-obs{
  color: var(--primary-color);
  font-size: 15px;
  margin-left: 20px;
  background-color: white;
  width: fit-content;
  padding: 0 10px;
  border-radius: 8px;
}
.provider-containear-name{
  width: 20%;
  cursor: pointer;
  text-decoration: underline;
}
.provider-containear-cnpj{
  width: 20%;
}
.provider-containear-email{
  width: 30%;
}
.provider-containear-min{
  width: 15%;
}
.provider-containear-total{
  width: 20%;
}
.provider-body-title{
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #F2F2F2;;
}
.provider-body-colum{
  width: 25%;
  color: #605F5F;
  font-weight: 500;
  font-size: 18px;
}
.value-colum{
  width: 15% !important;
}
.order-title{
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 40px;
  margin-left: 10px;
}
.provider-body{
  background-color: white;
  margin-top: 15px;
  border: 1px solid #F2F2F2;
  padding-top: 10px;
}
.provider-body-item-containear{
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #F2F2F2;
  margin-top: 15px;
}
.provider-body-item-name{
  display: flex;
  align-items: center;
}
.provider-body-item-img{
  max-width: 13%;
  height: 50px;
  border-radius: 50%;
  margin-left: 5px;
}
.provider-body-item-name-text{
  margin-left: 20px;
  color: #605F5F;
  font-weight: 300;
  font-size: 19px;
}
.provider-body-item-desc-text{
  margin-left: 10px;
  color: lightsalmon;
  font-weight: 300;
  font-size: 15px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 37vw;
}
.provider-body-item-package{
  display: flex;
}
.package-icon{
  width: 22px;
}
.provider-body-item-unity-price{

}
.provider-body-quantity-containear{
  display: flex;
}
.provider-body-quantity-subtrack{
    border: 1px solid #F2F2F2;
    border-radius: 5px 0 0 5px;
    text-align: center;
    width: 15%;
    font-size: 20px;
    cursor: pointer;
}
.provider-body-quantity-value{
  border: none;
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    width: 20%;
    text-align: center;
    font-size: 20px;

}
.provider-body-quantity-value:focus{
      border: 1px solid var(--primary-color);
      outline: none;
  }
.provider-body-quantity-add{
    border: 1px solid #F2F2F2;
    border-radius: 0 5px 5px 0;
    text-align: center;
    width: 15%;
    background: var(--primary-color);
    font-size: 20px;
    color: white;
    cursor: pointer;
}
.expand-icon{
  width: 20px;
  margin-left: 1vw;
  margin-right: 2vw;
  cursor: pointer;
}
.order-total-containear{
  font-size: 20px;
  display: -webkit-box;
}
@media only screen and (max-width: 1150px){
    .provider-containear-title{
      font-size: 15px;
    }
}
@media only screen and (max-width: 900px){
    .provider-containear-title{
      font-size: 13px;
    }
}
@media only screen and (max-width: 900px){
    .provider-containear-title{
      font-size: 9px;
    }
    .provider-body-item-name-text{
      font-size: 13px;
    }
    .provider-body-colum{
      font-size: 14px;
    }
}
@media only screen and (max-width: 650px){
  .value-colum{
    width: 40% !important;
  }
  .width-30{
    width: 100% !important;
  }
  .end-order{
    font-size: 15px;
    padding: 4px 10px;
  }
  .mobile-hide{
    display: none;
  }
  .select-store-containear{
    flex-direction: column;
  }
  .order-total{
    margin-left: 0;
  }
  .order-total-containear{
    margin-top: 15px;
    display: flex;
  }
  .cart-button{
    margin-right: 5px;
  }
  .select-store-input-containear{
    width: 100%;
  }
  .client-info-containear{
    flex-direction: column;
  }
  .store-info-colum{
    width: 100%;
  }
  .obs-textarea{
    width: 250px;
  }
  .provider-containear-name{
    width: 100%;
  }
  .provider-containear-total{
    width: 100%;
  }
  .provider-containear-title{
    font-size: 15px;
  }
  .provider-body-colum{
    width: 100%;
    font-size: 20px;
  }
  .provider-body-item-name-text{
    font-size: 18px;
  }
  .client-info-containear-content{
    flex-direction: column;
  }
  .obs-containear{
    width: 90%;
  }
}
@media print {
  .page-body{margin-top: 0;}

}
</style>
