<template>
    <div class="step-1-container">
        <addProviderHeader :cancel="cancel" />
        <div class="step-1-body">
            <StandardInput title="CNPJ" :action="value => Cnpj = value" :type="'text'" :auto="true"
                mask="## ### ###/####-##" />
            <p class="step-1-error-message" v-if="notValid">Desculpe, CNPJ invalido.</p>
            <p class="step-1-error-message" v-if="notFound">Esse fornecedor não foi localizado.</p>
            <p v-if="notFound" class="step-1-error-message-2">Deseja continuar com o cadastro?</p>
            <div class="step-1-buttons-container">
                <Button class="step-1-mobile-button" :text="notFound ? 'Continuar cadastro' : 'Confirmar'" :action="confirm" :filled="true" />
                <Button class="step-1-mobile-button" text="Voltar" :action="() => { cancel() }" />
            </div>
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import Button from '@/components/ui/buttons/Standard-Button.vue'
import ProfileService from '../../../services/v3/providers/profile.service';
import addProviderHeader from './addProviderHeader.vue';

export default {
    name: 'AddProviderStep1',
    props: ['cancel', 'next'],
    components: {
        StandardInput,
        Button,
        addProviderHeader
    },
    data() {
        return {
            Cnpj: '',
            svc: new ProfileService(),
            notFound: false,
            isLoading: false,
            notValid: false
        }
    },
    methods: {
        confirm() {
            if (!this.validarCNPJ(this.Cnpj)) {
                this.notValid = true
                return
            } else this.notValid = false
            if (!this.notFound) {
                this.isLoading = true
                this.svc.load({name:this.Cnpj}).then(resp => {
                    if (resp.data.data.length > 0){
                        this.next(resp.data.data[0])
                        this.isLoading = false
                    } else{
                        this.isLoading = false
                        this.notFound = true
                    }
                })
            } else {
                console.log('aqui');
                console.log(this.Cnpj);
                this.next(this.Cnpj.replace(' ','').replace(' ','').replace('/','').replace('-',''))
            }
        },
        validarCNPJ(cnpj) {

            cnpj = cnpj.replace(/[^\d]+/g, '');

            if (cnpj == '') return false;

            if (cnpj.length != 14)
                return false;

            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999")
                return false;

            // Valida DVs
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;

            return true;

        }
    },
    created () {
        
    }
}
</script>

<style scoped>
.step-1-container {
    height: 60vh;
    width: 60vw;
    background-color: white;
    border-radius: 10px;
}

.step-1-body {
    height: calc(100% - 8vh);
    padding: 5%;
}

.step-1-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 3vw;
    margin-top: 10%;
}

.step-1-error-message {
    color: #E53535;
    font-size: 1.3em;
    font-weight: 400;
    margin-top: 1vh;
}

.step-1-error-message-2 {
    color: #E53535;
    font-size: 1.3em;
    font-weight: bold;
}

@media only screen and (max-width: 850px) {
    .step-1-container {
        width: 100vw;
    }
    .step-1-mobile-button{
        width: 50%;
    }
}
</style>