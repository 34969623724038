<style lang="scss">
@import '@/sass/commons/_variables';

.pagination {
    .desc {
        color: #878787;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    ul {
        list-style: none;
        display: flex;
        li {
            cursor: pointer;
            border: 1px solid #E8E9EC;
            border-radius: 8px;
            color: rgba(77,79,92,0.5);
            padding: 10px 17px;
            margin: 0 4px;
            &.active {
                background-color: #14ABBD;
                border: 1px solid #14ABBD;
                color: white;
            }
            &.prev {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    content: '';
                    border-top: 1px solid #808495;
                    border-left: 1px solid #808495;
                    transform: rotate(-45deg);
                    width: 10px;
                    height: 10px;
                }
            }
            &.next {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    content: '';
                    border-top: 1px solid #808495;
                    border-left: 1px solid #808495;
                    transform: rotate( 135deg );
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    &.orange {
        ul {
            li {
                &.active {
                    background-color: $primary-color !important;
                    border: 1px solid $primary-color;
                }
            }
        }
    }
}
</style>
<template>
    <div class="pagination" v-bind:class="classes" v-if="total_pages > 1">
        <ul>
            <li v-on:click="goToPage(1)" class="prev"></li>
            <li v-on:click="goToPage(i)"
                v-for="i in window"
                v-bind:key="i"
                v-bind:class="{ active: i == page }">
                    {{ i }}
            </li>
            <li v-on:click="goToPage(total_pages)" class="next"></li>
        </ul>
        <div class="desc">
            Exibindo: {{ !(actual_page >= 2) ? 1 : (page-1)*per_page }}..{{ page*per_page }} de {{ total }} registros
        </div>
    </div>
</template>
<script>
export default {
    props: {
		'page_window_size' : {},
        'classes' : {
            default: ''
        },
        'per_page' : {
            default: '10'
		},
		'page_limit': { default : 20 },
        'total' : {
            default: 0
        }
    },
    data() {
        return {
            total_pages: 0,
			actual_page: 1,
			show_pages: 20,
			begin: 0,
			end: 20,
            begin_excedent: 0,
            end_excedent: 0,
            window: [],
            page: 1
        };
    },
    methods: {
        goToPage( new_page ) {
            this.page = new_page
            this.init()
        },
        init() {
            this.show_pages = this.page_limit;
			this.total_pages = Math.ceil(this.total/this.per_page);
            this.set_begin()
            this.set_end()
            this.load_window()
        },
        load_window() {
            this.window = []
            for(let i = this.begin; i <= this.end; i++) {
                this.window.push(i)
            }
        },
        set_begin() {
            let width = this.page_limit/2
            let begin = this.page - width
            this.end_excedent = begin < 1 ? (begin * -1) : 0
            this.begin = begin < 1 ? 1 : begin
        },
        set_end() {
            let width = this.page_limit/2
            let end = this.page + width
            this.begin_excedent = end > this.total_pages ? (end - this.total_pages) : 0
            this.end = end > this.total_pages ? this.total_pages : end
        }
	},
    watch: {
		total: function() {
			this.init()
		},
        per_page : function() {
            this.init()
        },
        page: function(page) {
            this.actual_page = page
        },
        actual_page: function(new_page) {
            this.$emit('change', new_page);
        }
    },
    mounted() {
        this.init()
    }
}
</script>

