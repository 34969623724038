
import axios from "axios";
export default class InfoPriceService{
  initProductList = ['7891080400605',
                    '7891107101621',
                    '7891152500868',
                    '7896116900029',
                    '7896213006396',
                    '7898366930023',
                    '7896259410133',
                    '7898080640222',
                    '7898080640413',
                    '7896094906624',
                    '7891000241356',
                    '7896013101024',
                    '7896098900208',]
  constructor () {
    this.token = undefined
  }
  reloadProducts (searchProduct) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/filtros/produto'
    const config = {
      headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'}
    };
    const data = {"query":searchProduct,"field":"produto","login":"integracao.clubdacotacao@infoprice.co","cliente":1531,"dataInicio":null,"dataFim":null,"rede":null,"tipoLoja":null,"uf":null,"cidade":null,"categoria":null,"secao":null,"gtin":null,"tipoPreco":null,"tipoProduto":null,"fabricante":null,"latitude":null,"longitude":null,"raio":null,"limitProducts":null,"identificadores":null,"addNotFound":true}
    return axios.post(url, data, config).then(response => {
      return response.data
    })
  }
  loadStates (query, lat, lng, radius) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/filtros'
    const config = {
      headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'}
    };
    const data = {"query":query, "field":"uf", "login":"integracao.clubdacotacao@infoprice.co", "cliente":1531, "dataInicio":null, "dataFim":null, "rede":null, "tipoLoja":null, "uf":null, "cidade":null, "categoria":null, "secao":null, "gtin":null, "tipoPreco":null, "tipoProduto":null, "fabricante":null, "latitude": lat, "longitude": lng, "raio":radius, "limitProducts":null, "identificadores":null, "addNotFound":true}
    return axios.post(url, data, config).then(response => {
      return response.data
    })
  }
  loadProducts () {
    let url = 'https://api.infopriceti.com.br/portal-acesso-web/oauth/token?grant_type=password&username=integracao.clubdacotacao@infoprice.co&password=a31fafb19e91a6c913fcaadc81f198a334e03eb5f61ff1bd9d61c12e29118391cb63bc90c5f6f091237f6df2de795259982c0570acb27bfa5a34e2bbe9641a7e'
    let config = {
      headers: { Authorization: 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0'},
    };
    let data = {}
    return axios.post(url, data, config).then(response => {
      this.token = response.data.access_token
      url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/filtros/produto'
      config = {
        headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'}
      };
      data = {"query":"","field":"produto","login":"integracao.clubdacotacao@infoprice.co","cliente":1531,"dataInicio":null,"dataFim":null,"rede":null,"tipoLoja":null,"uf":null,"cidade":null,"categoria":null,"secao":null,"gtin":null,"tipoPreco":null,"tipoProduto":null,"fabricante":null,"latitude":null,"longitude":null,"raio":null,"limitProducts":null,"identificadores":null,"addNotFound":true}
      return axios.post(url, data, config).then(response => {
        let resp = {
          filterList: response.data
        }
        data = {"query":"","field":"produto","login":"integracao.clubdacotacao@infoprice.co","cliente":1531,"dataInicio":null,"dataFim":null,"rede":null,"tipoLoja":null,"uf":null,"cidade":null,"categoria":null,"secao":null,"gtin": this.initProductList,"tipoPreco":null,"tipoProduto":null,"fabricante":null,"latitude":null,"longitude":null,"raio":null,"limitProducts":null,"identificadores":null,"addNotFound":true}
        return axios.post(url, data, config).then(response => {
          resp.productsList = response.data
          return resp
        })
      })
    })
  }
  getToken () {
    let url = 'https://api.infopriceti.com.br/portal-acesso-web/oauth/token?grant_type=password&username=integracao.clubdacotacao@infoprice.co&password=a31fafb19e91a6c913fcaadc81f198a334e03eb5f61ff1bd9d61c12e29118391cb63bc90c5f6f091237f6df2de795259982c0570acb27bfa5a34e2bbe9641a7e'
    let config = {
      headers: { Authorization: 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0'},
    };
    let data = {}
    return axios.post(url, data, config).then(response => {
      this.token = response.data.access_token
    })
  }
  async getProduct(payload){
    if(!this.token){
      await this.getToken()
    }
    
    var dateBeguin = new Date();
    dateBeguin.setDate(dateBeguin.getDate()- 15)

    return this.requestProducts({
      gtins: [payload.value],
      dateEnd: new Date().toISOString().split('T')[0],
      dateBeguin: dateBeguin.toISOString().split('T')[0],
      states: payload.states
    }).then(response => {
      let url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/filtros/produto'
      const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
      let data = {"query":"","field":"produto","login":"integracao.clubdacotacao@infoprice.co","cliente":1531,"dataInicio":null,"dataFim":null,"rede":null,"tipoLoja":null,"uf":null,"cidade":null,"categoria":null,"secao":null,"gtin": [payload.value],"tipoPreco":null,"tipoProduto":null,"fabricante":null,"latitude":null,"longitude":null,"raio":null,"limitProducts":null,"identificadores":null,"addNotFound":true}
      return axios.post(url, data, config).then(response2 => {
        const resp = {
          info1: response[0],
          info2: response2.data[0]
        }
        return resp
      })
    })
  }
  requestProducts (payload) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/agregado?size=50&page=0'
    const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
    const data = {"filter":{"data_inicio": payload.dateBeguin,"data_fim": payload.dateEnd,"rede":null,"tipo_loja":null,"loja_cliente":null,"cnpj":null,"uf": payload.states,"cep":null,"latitude":null,"longitude":null,"raio":null,"cidade": payload.cities,"categoria":null,"secao":null,"gtin": payload.gtins,"sku":null,"tipo_preco":null,"tipo_produto":null,"fabricante":null},"group":["GTIN"],"order":[{"by":"MEDIANA","ascending":true}]}
    return axios.post(url, data, config).then(response => {
      return response.data.content
    })
  }
  requestSellers (payload) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/agregado?size=30&page=' + payload.page
    const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
    const data = {"filter":{"data_inicio": payload.dateBeguin,"data_fim": payload.dateEnd,"rede":null,"tipo_loja":null,"loja_cliente":null,"cnpj":null,"uf": payload.states,"cep":null,"latitude": payload.lat,"longitude": payload.lng,"raio": payload.radius,"cidade": payload.cities,"categoria":null,"secao":null,"gtin": payload.gtin,"sku":null,"tipo_preco":null,"tipo_produto":null,"fabricante":null},"group":["REDE"],"order":[{"by":payload.order,"ascending":true}]}
    return axios.post(url, data, config).then(response => {
      return response.data
    })
  }
  requestAddressOptions (payload) {
    let url = 'https://api.v2.infoprice.co/cliente/v1/location/autocomplete?query=' + payload.query
    if (payload.sessionToken) url += '&session_token=' + payload.sessionToken
    const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
    return axios.get(url, config).then(response => {
      return response.data
    })
  }
  requestLatLong (payload) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/location/place?place_id=' + payload.placeId + '&session_token=' + payload.sessionToken
    const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
    return axios.get(url, config).then(response => {
      return response.data
    })
  }
  requestCities (payload) {
    const url = 'https://api.v2.infoprice.co/cliente/v1/painel_geral/filtros'
    const config = {headers: { Authorization: 'Bearer ' + this.token, 'content-type': 'application/json; charset=UTF-8'},}
    const data = {"query": payload.search,"field":"cidade","login":"","cliente":"","dataInicio":"2022-06-26","dataFim":"2022-07-26","rede":null,"tipoLoja":null,"uf":payload.states,"cidade":null,"categoria":null,"secao":null,"gtin":null,"tipoPreco":null,"tipoProduto":null,"fabricante":null,"origemPreco":null,"latitude":null,"longitude":null,"raio":null,"limitProducts":null,"identificadores":null,"addNotFound":true}
    return axios.post(url, data, config).then(response => {
      return response.data
    })
  }
}