<template>
    <b-card
        @click="show_providers()"
        :img-src="data.cover_url"
        :img-alt="data.name"
        img-to
        style="max-width: none;"
        tag="article"
        class="mb-2 shadow mx-4"
    >
        <b-card-body class="text-left p-0">
            <b-card-title class="text-uppercase">
                <i class="fa fa-tags"></i>
                {{ data.product.name }}
            </b-card-title>
            <b-card-text class="mb-1">
                Preço médio:
                <b class="text-uppercase">{{ data.medium_offered_price | currency }}</b>
            </b-card-text>
            <b-card-text class="mb-1">
                Volume de Vendas:
                <b>{{ data.volume }} / {{ data.total_purchased | currency }}</b>
            </b-card-text>
        </b-card-body>
        <template #footer>
            <cc-button
                :icon="'fa fa-shopping-bag'"
                :content="'Ver Ofertas'"
                :classes="'success fill'" />
        </template>
    </b-card>
</template>
<script>
import OperationService from '@/services/v3/commons/operation.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { mapGetters } from 'vuex'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ loaderMixin, perm_mixin ],
    props: {
        data: {
            required: true
        }
    },
    data(){
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters({ setting: 'user/setting' })
    }
}
</script>

<style lang="scss" scoped>
    @import './index.page';
</style>
