<template>
    <div class="makeorder-page-table-req-width">
        <div class="makeorder-page-table-mobile-header">Req.</div>
        {{ quantity }}
    </div>
</template>
<script>
export default {
    name: 'QuantityLine',
    props:['quantity']
}
</script>
<style scoped>
.makeorder-page-table-req-width{width: 7%;}
.makeorder-page-table-mobile-header{display: none;}

@media screen and (max-width: 850px) {
    .makeorder-page-table-req-width{
        display: flex; 
        gap: 2vw;
        align-items: center;
        width: fit-content !important;
    }
    .makeorder-page-table-mobile-header{
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
    }
}
</style>