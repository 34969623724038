import http from './Http';
import Service from './Service';

/**
 * @type ProductService
 */
export default class ProductService extends Service {

    getAllWrapTypes() {
        return http.get('listarprodutosadministradorfiltro', {headers: this.getHeaders(), timeout: 1200000 });
    }

    get_wraps() {
        return http.get('getembalagens', {headers: this.getHeaders(), timeout: 1200000 });
    }

    get_product_brands(product_id) {
        return http.get('getmarcasproduto', {
            headers: this.getHeaders(),
            timeout: 1200000,
            params: {
				produto: product_id
            }
        });
    }

    pin_brand(data) {
        return http.post("setmarcapreferencial", data, {
            headers: this.getHeaders()
        });
    }

    get_product_suggestion(){
        return http.get("getsugestaocliente", {
            headers: this.getHeaders()
        });
    }

    save_brand(data) {
        return http.post("v2/products/brand", data, {
            headers: this.getHeaders()
        });
    }

    delete(data) {
        return http.delete("v2/products/",{
            headers: this.getHeaders(),
            params: data
        });
    }

}
