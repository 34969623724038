<template>
  <div class="w-100">
    <label for="" v-if="label">{{ label }}</label>
    <multiselect
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      deselect-label="remover"
      :track-by="tracker"
      :label="text"
      @input="onselect"
      :placeholder="placeholder"
      selected-label="Selecionado"
      :options="options"
      open-direction="below"
      :searchable="searchable"
      :allow-empty="empty"
      :show-pointer="true"
      :close-on-select="closeonselect"
      :show-labels="false"
      :multiple="multiple" />
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { uiMixin } from '@/mixins/base.mixing'
  export default {
    mixins: [ uiMixin ],
    props: {
        closeonselect: {
          type: Boolean,
          default: true
        },
        empty: {
          type: Boolean,
          default: true
        },
        searchable: {
          type: Boolean,
          default: false
        },
        options: {
            type: Array,
            required: true
        },
        value: {
            required: true
        },
        tracker: {
          type: String,
          default: 'key'
        },
        text: {
          type: String,
          default: 'value'
        },
        placeholder: {
          type: String,
          default: ''
        },
        multiple: {
          type: Boolean,
          default: false
        },
        label: String
    },
    methods: {
      onselect(selection) {
        this.$emit('onselect', selection)
      }
    },
    components: { Multiselect }
  }
</script>

<style lang="scss" scoped>
  @import "@/sass/commons/_variables";

	/deep/ {
    .multiselect {
      font-family:"Source Sans Pro", sans-serif !important;
      height: 2.5rem;
    }
    .multiselect__single, .multiselect__placeholder {
      color: $input-font-color !important;
    }
    .multiselect__option {
      text-transform: uppercase !important;
      &.multiselect__option--highlight {
        background-color: var(--primary-color) !important;
      }
    }
    .multiselect__tags {
        padding: 5px 40px 0 16px;
        border: 1px solid $border-color;
        font-size: 17px;
        overflow-y: hidden;
    }
  }

</style>
