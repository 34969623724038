<style lang="scss" scoped>
@import "@/sass/commons/_variables";
@import '@/sass/commons/_tables';

.load-more {
	font-family: "Source Sans Pro", sans-serif;
	border: 1px solid;
	color: $primary-color;
	font-weight: 600;
	border-radius: 2px;
	padding: 1rem;
	margin: 1rem;
	&:hover {
		cursor: pointer;
		color: white;
		border: 1px solid $primary-color;
		background-color: $primary-color;
	}
}

.actions {
    font-size: 1.2rem;
}

.page {
  padding: 15px 40px 70px 60px;
  font-size: 1vw;
}
.page-table-mobile-header{display: none;}
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title{
  margin-top: 45px;
  font-weight: 700;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
}
.page-table-line-special{
  background: #F7F7F7;
}
.orders-history__back-btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
.page-table-container{
  margin: 2em 0em;
}
.page-table-header{
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 0.4em 0em;
}
.page-table-header-text{
  padding: 0.2em 0.3em;
  /* padding-left: 10px; */
  font-weight: 600;
  font-size: 1.19em;
  color: #505050;
}
.page-table-row{
  display: flex;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
  align-items: center;
  padding: 0.5em 0em;
}
.page-table-desc-column{
  padding: 0.2em 0.5em;
}
.request-width {
  width: 11%;
}

.company-width {
  width: 15%;
}
.provider-width {
  width: 13%;
}
.observation-width {
  flex: 1;
}
.product-width {
  width: 9%;
}
.total-width {
  width: 8%;
}
.status-width {
  width: 16%;
}
.actions-width {
  width: 10%;
}
.visibility-icon {
  color: #187C3B;
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
  overflow-x: auto;
}

@media only screen and (max-width: 800px) {
  .page{font-size: 2vw;}
  .page-table-header{display: none;}
  .page-table-row{
    flex-direction: column;
    gap: 1vh;
    margin-bottom: 3vh;
    font-size: 1.3em;
  }
  .page-table-desc-column{
    width: 100%;
    flex: unset;
    display: flex;
    justify-content: space-between;
  }
  .page-table-mobile-header{
    display: flex;
    font-weight: 700;
    font-size: 1.1em;
  }

}

@media only screen and (max-width: 500px) {
  .page {
	  padding: 20px;
    font-size: 3vw;
  }
}

</style>

<template>
  <div class="page">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/ofertas" class="page-route-select-text">
        Ofertas
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Ver historico de pedidos
      </div>
    </div>
    <div class="page-title">Histórico de Pedidos</div>
    <div class="orders-history__back-btn-wrapper">
      <StandardButton class="button-text" :action="() => { $router.push('/ofertas') }" :iconName="'arrow_back'" :text="'Voltar'"/>
    </div>
    <cc-loader v-show="loading && page == 1" />
    <no-data-available v-if="orders.length == 0 && !loading" :text="'Sua lista de pedidos está vazia, acesse a pagina de ofertas e aproveite as promoções disponíveis dos fornecedores'" />
    <div v-if="orders.length > 0 || (loading && page > 1)" class="page-table-container">
      <div class="page-table-content">
        <div class="page-table-header">
          <div class="page-table-header-text request-width">N Solicitação / <br> Data </div>
          <div class="page-table-header-text company-width">Empresa</div>
          <div class="page-table-header-text provider-width">Vendedor / <br> Fornecedor</div>
          <div class="page-table-header-text observation-width">Condição de pagamento / <br> Tipo entrega</div>
          <div class="page-table-header-text product-width">Produtos</div>
          <div class="page-table-header-text total-width">Total</div>
          <div class="page-table-header-text status-width text-center">Status</div>
          <div class="page-table-header-text actions-width text-center">Ações</div>
        </div>
        <div v-for="(order, idx) in orders"
             :key="order.pedido_id"
             :class="{'page-table-line-special': idx % 2 != 0 }">
          <div class="page-table-row-containear">
            <div class="page-table-row">
              <div class="page-table-desc-column request-width">
                <div class="page-table-mobile-header">N Solicitação</div>
                <b>#{{ order.pedido_id }}</b><br>
                {{ order.data_criacao | formatDate }}
              </div>
              <div class="page-table-desc-column company-width">
                <div class="page-table-mobile-header">Empresa</div>
                {{ order.loja ? order.loja.loj_descricao : '' }}
              </div>
              <div class="page-table-desc-column provider-width">
                <div class="page-table-mobile-header">Forcenedor</div>
                -- <br>
                {{ order.fornecedor ? order.fornecedor.for_nome : "" }}
              </div>
              <!-- <div class="page-table-desc-column observation-width">{{ order.obs ? order.obs : "" }}</div> -->
              <div class="page-table-desc-column observation-width">
                {{ order.campaign ? order.campaign.condicao_pgto : '--' }} <br>
                --
              </div>
              <div class="page-table-desc-column product-width">
                <div class="page-table-mobile-header">Produtos</div>
                <span class="list-products">{{ order.itens ? order.itens.length : 0 }}</span>
              </div>
              <div class="page-table-desc-column total-width">
                <div class="page-table-mobile-header">Total</div>
                {{ getTotal(order) | currency }}
              </div>
              <div class="page-table-desc-column status-width text-center">
                <div class="page-table-mobile-header">Status</div>
                <cc-status :status="translateStatus(order.status)"></cc-status>
              </div>
              <div class="page-table-desc-column actions-width text-center" title="Visualizar itens">
                <div class="page-table-mobile-header">Ações</div>
                <router-link :to="{ name: 'cliente-monitores-pedido', params: { id: order.pedido_id } }">
                  <span class="material-icons-outlined visibility-icon">
                    visibility
                  </span>
                </router-link>
                <span v-if="order.obs" :title="order.obs" class="material-icons-outlined">info</span>
              </div>
            </div>
          </div>
        </div>
        <tr class="seemore-button-containear" >
          <td class="text-center" colspan="6">
            <Paginacao classes="orange justify-end"
                       :items_by_page_value="30"
                       :total="totalItems.length"
                       :page_limit = '30'
                       :page="1"
                       v-on:change="updatePage($event)">
            </Paginacao>
          </td>
        </tr>
      </div>
    </div>
  </div>
    <!-- <div class="body">

        <div class="container-fluid">
            <div class="row pt-5">
                <div class="col col-11">
                    <h4 class="header-title"><i class="fa fa-shopping-bag" aria-hidden="true"></i>Pedidos</h4>
                    <p class="header-subtitle">Acompanhe suas ultimas compras em ofertas</p>
                </div>
                <div class="col col-1 py-4 right">
                    <cc-button
                        :classes="'fixed fill'"
                        :content="'Voltar'"
                        icon="fa fa-arrow-left"
                        link="/ofertas" />
                </div>
            </div>
            <div class="row">
                <div class="col">

                    <cc-loader v-show="loading && page == 1" />

                    <no-data-available v-if="orders.length == 0 && !loading" :text="'Sua lista de pedidos está vazia, acesse a pagina de ofertas e aproveite as promoções disponíveis dos fornecedores'" />

                    <table class="table" v-if="orders.length > 0 || (loading && page > 1)">
                        <thead>
                            <tr>
                                <th class="text-center">N Solicitação</th>
                                <th class="text-center">Data</th>
                                <th>Empresa</th>
                                <th>Fornecedor</th>
                                <th>Observação</th>
                                <th class="text-center">Produtos</th>
                                <th>Total</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in orders" v-bind:key="order.pedido_id">
                                <td class="text-center"><b>#{{ order.pedido_id }}</b></td>
                                <td class="text-center">{{ order.data_criacao | formatDate }}</td>
                                <td>{{ order.loja ? order.loja.loj_descricao : '' }}</td>
                                <td>{{ order.fornecedor ? order.fornecedor.for_nome : "" }}</td>
                                <td>{{ order.obs ? order.obs : "" }}</td>
                                <td class="text-center">
                                    <span class="list-products">{{ order.itens ? order.itens.length : 0 }}</span>
                                </td>
                                <td>{{ getTotal(order) | currency }}</td>
                                <td class="text-center">
                                    <cc-status :status="translateStatus(order.status)"></cc-status>
                                </td>
                                <td class="show-quotation text-center">
                                    <div class="actions">
                                        <router-link :to="{ name: 'cliente-monitores-pedido', params: { id: order.pedido_id } }">
                                            <i class="fa fa-eye" />
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                            <tr :class="{ hidden : !(loading && page > 1) }">
                                <td colspan="8">
                                    <cc-loader />
                                </td>
                            </tr>
                            <tr v-if="showPagination" class="text-center">
                                <td colspan="8" style="height:6rem">
                                    <a v-on:click="paginate" class="load-more shadow">MOSTRAR MAIS</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>
import OrderService from "@/services/OrderService";
import * as CONSTANTS from "@/constants/constants";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import Paginacao from "@/components/cliente/base-components/Pagination";

	export default {
    components: {
      StandardButton,
      Paginacao,
    },
		data() {
			return {
        page: 1,
        tab: 'DIRECT_PURCHASE',
				showPagination: false,
				orders: [],
				CONSTANTS: CONSTANTS,
        totalItems: undefined,
        items_by_page_value: 50,
        total_items: 1000,
				service: new OrderService(),
				loading: true,
                breadcrumbs : [
                        {
                            link: 'campaigns-index',
                            name: 'Ofertas'
                        },
                        {
                            name: 'Pedidos'
                        }
                    ]
                }
		},
		methods: {
			getTotal(order) {
				return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
			},
			translateStatus(status) {
				switch(status) {
					case "AGUARDANDO_CONFIRMACAO":
						return "AGUARDANDO CONFIRMACAO";
					case "CONFIRMADO":
						return "CONFIRMADO";
					case "CANCELADO":
						return "CANCELADO";
					default:
						return "Não identificado!"
				}
			},
			paginate() {
				this.page = this.page + 1;
				this.getDirectSaleOrders();
			},
			getDirectSaleOrders() {
				this.loading = true;
				this.service.getDirectSaleOrders({ page: this.page, page_size: this.items_by_page_value })
				.then(response => response.data)
				.then(data => {
					this.loading = false;
					this.orders = this.orders.concat(data.data);
          this.totalItems = this.orders.concat(data.data);
					this.showPagination = data.next_page_url != null;
				}).catch(error => {
					this.loading = false;
					ErrorHandlerService.handle(error, this.$store);
				});;
			},
      updatePage (event) {
        this.page = event - 1
        this.getDirectSaleOrders()
      },
		},
		watch: {
			items_by_page_value: function() {
				this.orders = [];
				this.getDirectSaleOrders();
			}
		},
		mounted() {
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
			this.getDirectSaleOrders();
		}
	};
</script>
