<template>
    <div class="makeorder-page-table-totalprice-width">
        <div class="makeorder-page-table-mobile-header">Total</div>
        {{ product.menorPreco*product.quantidade_sugestao*( product.flag_generico == 1 || (product.pro_principal == null && product.cli_tipoPedido == 1) ? 1 : parseFloat(product.embalagem.split(" ")[1])) | currency }}
    </div>
</template>

<script>
export default {
    name: 'TotalLine',
    props: ['product']
}
</script>

<style scoped>
.makeorder-page-table-totalprice-width{width: 7%;}


.makeorder-page-table-mobile-header {
    display: none;
}



@media screen and (max-width: 850px) {
    .makeorder-page-table-totalprice-width{
        width: fit-content !important; 
        display: flex; 
        gap: 2vw;
        align-items: baseline;
    }


    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>