import * as CONSTANTS from "../constants/constants";
import Router from "../routes";

export default class ErrorHandlerService {

    static handle(error, store) {

        console.error(error)

        if(store && error.message == 'Network Error') {
            const notification = {
                type: 'error',
                message: 'Você não está conectado a internet.'
            }
            store.dispatch('notification/add', notification)
        }

        if(!error.request) {
            return;
        }

        if ( error.request.status === CONSTANTS.REQUEST_CODE_UNAUTHORIZED) {
            // window.location.href = CONSTANTS.ROUTE_LOGIN;
            window.location.assign(CONSTANTS.ROUTE_LOGIN)
        }
    }

}
