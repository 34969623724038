<template>
    <div class="public-offer-page">
        <Header/>
        <div class="public-offer-page-body">
            <div class="filter-title">
                Produtos em Oferta no Club da Cotação
            </div>
            <div class="filter-subtitle">
                Confira as ofertas que só o Club tem
            </div>
            <div class="filter-head">
                <div class="filter-text">
                    Filtrar por
                </div>
            </div>
            <div class="filter-tags">
                <div class="product-tag" :class="isActiveTag('product')" v-on:click="pageFilter='product'">
                    Produto
                </div>
                <div class="suplier-tag" :class="isActiveTag('suplier')" v-on:click="pageFilter='suplier'">
                    Fornecedor
                </div>
            </div>
            <div class="filter-inputs-containear">
                <div class="search-input-containear" v-if="pageFilter=='product'">
                    <input v-model="searchTextProduct" type="text" class="search-input" placeholder="Digite aqui o nome do produto">
                    <img src="@/assets/search.png" alt="busca">
                </div>
                <div class="search-input-containear" v-else>
                    <input v-model="searchTextSuplier" type="text" class="search-input" placeholder="Digite aqui o nome do fornecedor">
                    <img src="@/assets/search.png" alt="busca">
                </div>



                <div class="category-input-size">
                    <div class="select-input-text">
                        Categorias
                    </div>
                    <div class="select-input-containear">
                        <select v-model="selectedcategory" class="select-input">
                            <option value="">Selecione uma categoria</option>
                            <option v-for="category in categories" :key="category.id" :value="category">{{category.name}}</option>
                        </select>
                    </div>
                </div>

                <a class="preorder-seller-title-button-order" href="https://api.whatsapp.com/send?phone=558197414321" target="_blank">
                    <span class="material-icons preorder-seller-title-button-order-icon">shopping_cart</span>
                    Quero Comprar!
                </a>

            </div>
            <div class="catalog-containear" v-if="pageFilter=='product'">
                <div v-for="category in categories" :key="category.id">

                    <div class="category-containear" v-if="checkCategory(category)">
                        <div class="category-title">
                            {{category.name}}
                        </div>
                        <div class="category-title-bar"></div>
                        <div class="products-containear">
                            <div v-for="item in checkItems(category.items)" :key="item.id">
                                <div class="product-containear">
                                    <div class="product-align">
                                        <div class="image-alignt">
                                            <img class="product-image" :src="item.product.cover_url" :alt="item.product.name">
                                        </div>
                                        <div class="product-info">
                                            <div class="product-name" :title="item.product.name">
                                                {{formatName(item.product.name)}}
                                            </div>
                                            <div class="item-suplier-containear">
                                                <img src="@/assets/suplier-icon.svg" alt="Fornecedor">
                                                <div class="suplier-text">Fornecedor</div>
                                                <div class="suplier-name" @click="showProviderDetailsModal = true; openProviderDetails(item.campaign.provider)">
                                                  {{item.campaign.provider.name}}
                                                </div>
                                            </div>
                                            <div v-if="item.wrap_quantity > 1" class="product-price">
                                                Vlr. Emb: {{item.wrap_price.toLocaleString('pt-BR', {
                                                    currency: 'BRL',
                                                    style: 'currency',
                                                    minimumFractionDigits: 2
                                                })}}
                                            </div>
                                            <div v-else class="product-price">Valor unitário</div>
                                            <div class="product-price-unity" >
                                                Vlr. Unit: {{item.unit_price.toLocaleString('pt-BR', {
                                                    currency: 'BRL',
                                                    style: 'currency',
                                                    minimumFractionDigits: 2
                                                })}}
                                            </div>
                                            <div class="product-package-containear">
                                                <img src="@/assets/package.svg" alt="pacote">
                                                <div class="product-package-text">
                                                    Pacote com {{item.wrap_quantity}} unidade(s)
                                                </div>
                                            </div>
                                            <div class="product-obs-containear" v-if="item.obs">
                                                <div class="product-obs-content" >
                                                    <div class="product-obs-text">
                                                        {{formatObs(item.obs)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="supliers-containear" v-else>
                <div class="supliers-title">
                    Fornecedor
                </div>
                <div class="products-containear">
                    <div v-for="item in checkSupliers(supliers)" :key="item.id" class="suplier-containear">
                        <div class="suplier-align">
                            <img class="suplier-image" :src="item.provider.cover_url" :alt="item.provider.name">
                            <div class="suplier-body-margin">
                                <div class="suplier-info-name">
                                    {{item.provider.name}}
                                </div>
                                <div class="suplier-text-title">
                                    CNPJ
                                </div>
                                <div class="suplier-text">
                                    {{item.provider.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}}
                                </div>
                                <div class="suplier-text-title">
                                    Campanha
                                </div>
                                <div class="suplier-text">
                                    {{item.name}}
                                </div>
                            </div>

                        </div>
                        <div class="suplier-button" v-on:click="setSearchInput(item.provider.id)">
                            Ver Produto
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProviderDetailsModal v-if="showProviderDetailsModal"
                              :closeDialog="closeProviderDetailsModal"
                              :providerInfo="providerInfo"/>
    </div>
</template>

<script>
import CampaignService from '@/services/v3/campaigns/campaign.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import ProviderDetailsModal from '@/modals/providerDetailsModal.vue'
import Header from '@/components/cliente/base-components/PublicHeader.vue'
    export default {
        name: 'PublicOffer',
        components: { Header, ProviderDetailsModal },
        data() {
            return {
                pageFilter: 'product',
                categories: [],
                svc: new CampaignService(),
                supliers: [],
                searchSuplierNameId: undefined,
                searchSuplierState: undefined,
                searchTextSuplier: '',
                searchTextProduct: '',
                selectedcategory: '',
                providerInfo: undefined,
                showProviderDetailsModal: false,
            }
        },
        async created() {
            if (this.$route.params.filter && this.$route.params.filter.toLowerCase() in {'ac':true, 'al':true, 'ap':true, 'am':true, 'ba':true, 'ce':true, 'es':true, 'go':true, 'ma':true, 'mt':true, 'ms':true, 'mg':true, 'pa':true, 'pb':true, 'pr':true, 'pe':true, 'pi':true, 'rj':true, 'rn':true, 'rs':true, 'ro':true, 'rr':true, 'sc':true, 'sp':true, 'se':true, 'to':true, 'df':true}) this.searchSuplierState = this.$route.params.filter.toLowerCase()
            else this.searchSuplierNameId = this.$route.params.filter
            this.load_items()
        },
        methods:{
            checkSupliers(supliers){
                let resp = []
                for (let index = 0; index < supliers.length; index++) {
                    if (this.searchSuplierNameId){
                        if(supliers[index].provider.id == this.searchSuplierNameId) resp.push(supliers[index])
                    } else if (this.searchSuplierState) {
                        if (supliers[index].provider.head_office.state.fu.toLowerCase() == this.searchSuplierState) resp.push(supliers[index])
                    } else {
                        if (supliers[index].name.toLowerCase().includes(this.searchTextSuplier.toLowerCase())) resp.push(supliers[index])
                    }
                }
                return resp
            },
            closeProviderDetailsModal: function() {
                this.showProviderDetailsModal = false;
            },
            openProviderDetails(payload) {
                this.providerInfo = payload;
            },
            isActiveTag(text){
                if (text == this.pageFilter) return 'active-tag'
            },
            load_items() {
                this.svc.load_public_products().then(response => response.data).then(data => {
                    this.process_categories(this.shuffle(data))
                    this.processSupliers(data)
                    // this.loading = false
                }).catch(err => {
                    ErrorHandlerService.handle(err, this.$store);
                })
            },
            processSupliers(items){
                for (let x = 0; x < items.length; x++){
                    let add = true
                    for (let y = 0; y < this.supliers.length; y++){
                        if (items[x].campaign.id == this.supliers[y].id) add = false
                    }
                    if (add) this.supliers.push(items[x].campaign)
                }
            },
            shuffle(array) {
                let currentIndex = array.length,  randomIndex;
                // While there remain elements to shuffle.
                while (currentIndex != 0) {
                    // Pick a remaining element.
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
                    // And swap it with the current element.
                    [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
                }
                return array;
            },
            process_categories(items) {
                let cat = null
                this.categories = []
                items.filter(i => i.product.category).forEach(i => {
                    cat = this.categories.find(c => c.id == i.product.category.id)
                    cat ? cat.items.push({ ...i, visible: true }) : this.categories.push({ ...i.product.category, visible: true, items: [ { ...i, visible: true } ] })
                })
            },
            checkCategory(category){
            if (!category.visible) return false
            if (this.selectedcategory != '' && category != this.selectedcategory) return false
            if (this.searchSuplierNameId && this.searchTextProduct != ''){
                let show = false
                category.items.forEach(item => {
                    if (item.campaign.provider.id == this.searchSuplierNameId &&  item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) show = true
                })
                return show
            }
            else if (this.searchSuplierState && this.searchTextProduct != ''){
                let show = false
                category.items.forEach(item => {
                    if (item.campaign.provider.head_office.state.fu.toLowerCase() == this.searchSuplierState &&  item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) show = true
                })
                return show
            }
            else if (this.searchSuplierNameId) {
                let show = false
                category.items.forEach(item => {
                    if (item.campaign.provider.id == this.searchSuplierNameId ) show = true
                })
                return show
            }
            else if (this.searchSuplierState) {
                let show = false
                category.items.forEach(item => {
                    if (item.campaign.provider.head_office.state.fu.toLowerCase() == this.searchSuplierState ) show = true
                })
                return show
            }
            else if (this.searchTextProduct != ''){
                let show = false
                category.items.forEach(item => {
                    if (item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) show = true
                })
                return show
            }
            return true
        },
            setSearchInput(id){
                this.searchSuplierNameId = id
                this.pageFilter = 'product'
            },
            checkItems(items){
                let result = []
                if (this.searchTextProduct != ''){
                    items.forEach(item => {
                        if (item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase()) && item.is_enabled) result.push(item)
                    })
                } else result = items

                if (this.searchSuplierNameId) {
                    let resp = []
                    result.forEach(item => {
                        if (item.campaign.provider.id == this.searchSuplierNameId) resp.push(item)
                    })
                    result = resp
                }

                if (this.searchSuplierState) {
                    let resp = []
                    result.forEach(item => {
                        if (item.campaign.provider.head_office.state.fu.toLowerCase() == this.searchSuplierState) resp.push(item)
                    })
                    result = resp
                }

                let resp2 = []
                result.forEach(item => {
                    if (item.is_enabled) resp2.push(item)
                })
                result = resp2
                return result
            },
            formatName(name){
                if (name.length > 55) name = name.slice(0,54) + '...'
                name = name.toLowerCase()
                name = name.substring(0, 1).toUpperCase() + name.substring(1);
                return name
            },
            formatObs(obs){
                if (obs.length > 25) return 'Observação: ' + obs.slice(0,24) + '...'
                return 'Observação: ' + obs
            },
        }
    }
</script>

<style>
    .public-offer-page{
        margin-left: 0;
        font-size: 1vw;
        margin-left: -9vw;
    }
    .public-offer-page-body{
        margin-left: 3vw;
    }
    .filter-title{
        font-weight: 700;
        font-size: 2.5em;
        color: #3A3A3C;
        margin-top: 4vh
    }
    .filter-subtitle{
        font-style: normal;
        font-weight: 400;
        font-size: 1.5em;
        color: #6B7588;
        margin-top: 1vh
    }
    .filter-head{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }
    .filter-text{
        color: #6B7588;
        font-size: 18px;
    }
    .filter-tags{
        display: flex;
        margin-top: 8px;
    }
    .active-tag{
        background-color: var(--primary-color) !important;
        color: white !important;
        cursor: default !important;
    }
    .product-tag{
        background: #FAFAFC;
        border-radius: 8px;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .suplier-tag{
        background: #FAFAFC;
        border-radius: 8px;
        margin-left: 25px;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .catalog-containear{
        margin-top: 60px;
        background-color: #8989890d;
        border-radius: 10px;
        margin-right: 35px;
        padding-top: 2vh;
    }
    .category-title{
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
        color: var(--primary-color);
        background-color: white;
        border-radius: 10px;
        padding: 1vh 2vw;
        width: fit-content;
    }
    .category-title-bar{
        background: var(--primary-color);
        border-radius: 10px;
        width: 119px;
        height: 13px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    select{
        background: white;
    }
    .products-containear{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 100px;
    }
    .product-containear{
        width: 90%;
        margin-bottom: 40px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 6px;
    }
    .product-image{
        border-radius: 10px;
        max-width: 100%;
        height: 170px;
    }
    .image-alignt{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .product-name{
        font-weight: 700;
        font-size: 18px;
        margin-top: 5px;
        height: 50px;
        color: #605F5F;
    }
    .item-suplier-containear{
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .product-info{
        margin: 10px
    }
    .suplier-text{
        flex: 1;
        color: #6B7588;
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
    }
    .suplier-containear{
        margin-left: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 8px;
        width: 75%;
    }
    .suplier-align{
        min-height: 300px;
    }
    .suplier-name{
        color: #6B7588;
        font-weight: 400;
        font-size: 14px;
        border-bottom: 1px solid black;
        cursor: pointer;
    }
    .suplier-name:hover {
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
    }
    .product-price{
        color: var(--primary-color);
        font-weight: 700;
        font-size: 20px;
        margin-top: 10px;
        padding-left: 0.8vw;
    }
    .product-price-unity{
        background-color: var(--primary-color);
        border-radius: 10px;
        color: white;
        font-weight: 700;
        font-size: 20px;
        padding: 0 1vw;
        width: fit-content;
    }
    .product-package-containear{
        display: flex;
    }
    .product-package-text{
        color: #6B7588;
        font-weight: 300;
        font-size: 14px;
        margin-left: 5px;
    }
    .product-buttons-containear{
        display: flex;
        margin-top: 13px;
        padding: 0 10px 10px 10px;
        height: 50px;
    }
    .product-button-subtrack{
        border: 1px solid #F2F2F2;
        border-radius: 5px 0 0 5px;
        width: 30%;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-button-value{
        border-top: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;
        flex: 1;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-button-add{
        border: 1px solid #F2F2F2;
        border-radius: 0 5px 5px 0;
        width: 30%;
        background: var(--primary-color);
        font-size: 20px;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-obs-containear{
        margin-top: 15px;
        height: 40px;
    }
    .product-obs-content{
        display: flex;
        align-items: center;
    }
    .product-obs-text{
        font-weight: 400;
        font-size: 15px;
        color: #EF443B;
    }
    .supliers-containear{
        margin-top: 60px;
        margin-left: 30px;
    }
    .supliers-title{
        font-weight: 500;
        font-size: 32px;
        color: #898989;
        margin-bottom: 15px;
    }
    .suplier-image{
        border-radius: 5px 5px 0 0;
        height: 170px;
        width: 100%;
    }
    .suplier-body-margin{
        margin-left: 10px;
    }
    .suplier-info-name{
        color: #3A3A3C;
        font-weight: 600;
        font-size: 20px;
        margin-top: 15px;
    }
    .suplier-text-title{
        color: #8F90A6;
        font-weight: 500;
        font-size: 14px;
        margin-top: 8px;
    }
    .suplier-text{
        color: #8F90A6;
        font-weight: 300;
        font-size: 14px;
        margin-top: 5px;
    }
    .suplier-button{
        background-color: var(--primary-color);
        text-align: center;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        color: white;
        margin: 15px 10px 10px 10px;
    }
    .product-align{
        min-height: 350px;
        padding-bottom: 1.5vh;
    }
    .category-containear{
        margin-left: 25px;
    }
    .carousel-banner-img{
        height:40vh;
        width: 100% ;
    }
    .filter-inputs-containear{
        display: flex;
        margin-top: 20px;
        align-items: flex-end;
        gap: 5vw;
    }
    .search-input-containear{
        border: 1px solid #E5E5E5;
        display: flex;
        padding: 2px 4px;
        border-radius: 7px;
        width: 30%;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .search-input{
        border: none;
        width: 100%;
    }
    .search-input:focus{
        outline: none;
    }
    .category-input-size{
        width: 30%;
    }
    .select-input-text{
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.15px;
        color: #898989;
    }
    .select-input-containear{
        border: 1px solid #E5E5E5;
        display: flex;
        padding: 2px 4px;
        border-radius: 7px;
        height: 50px;
    }
    .select-input{
        border: none;
        width: 100%;
        cursor: pointer;
        color: grey;
        padding: 0 5px;
    }
    .select-input:focus{
        outline: none;
    }
    .preorder-seller-title-button-order{
        background: #30AA4C;
        border: 1px solid #E5E5E5;
        border-radius: 9.5px;
        display: flex;
        align-items: center;
        padding: 1.5vh 2vw;
        font-weight: 600;
        font-size: 1.5em;
        color: #FFFFFF;
        cursor: pointer;
    }
    .preorder-seller-title-button-order:hover{
        outline: 1px solid #30AA4C;
        color: #30AA4C;
        background-color: white;
    }
    .preorder-seller-title-button-order:hover > .preorder-seller-title-button-order-icon{
        color: #30AA4C;
    }
    .preorder-seller-title-button-order-icon{
        color: #ffffff;
        margin-right: 1vw;
        font-size: 1.5em;
    }
    @media only screen and (max-width: 1600px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
    @media only screen and (max-width: 1400px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    @media only screen and (max-width: 1100px){
        .product-containear{
            width: 85%;
        }
        .public-offer-page{font-size: 1.5vw;}
    }
    @media only screen and (max-width: 880px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr;
        }
        .product-obs-containear{
            height: 50px;
        }
        .public-offer-page{font-size: 2vw;}
        .carousel-banner-img{height: 15vh;}
        .filter-body{
            margin-right: 0;
        }
        .filter-containear{padding: 0 5vw;}
        .product-obs-containear{
            height: 50px;
        }
        .products-containear{
            grid-template-columns: 1fr 1fr;
        }
        .public-offer-page {
            margin-left: -3vw;
        }
        .product-price{
            font-size: 15px;
        }
        .product-price-unity{
            font-size: 15px;
        }

    }
    @media only screen and (max-width: 500px){
        .public-offer-page-body{
            margin-right: 3vw;
        }
        .catalog-containear{margin-right: 0;}
        .public-offer-page{font-size: 3vw;}
        .filter-inputs-containear{
            flex-direction: column;
            align-items: baseline;
        }
        .search-input-containear{
            width: 100%;
            margin-bottom: 10px;
        }
        .state-input-size{
            width: 100%;
            margin-bottom: 10px;
        }
        .category-input-size{
            width: 100%;
        }
        .filter-end-line{
            margin-top: 85px;
        }
        .product-name{
            height: unset;
        }
        .page-margin{ margin-left: 0;}
        .product-containear{ width: 95%;}
        .category-containear{ margin-left: 15px;}
        .item-suplier-containear{flex-wrap: wrap;}
    }
</style>
