<template>
    <div class="winners-page-containear" v-on:click="closeOptionsSelect()">
        <cc-loader-full v-if="is_loading" />
        <div class="winners-page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="winners-page-route-select-text">
                Home
            </router-link>
            <span class="material-icons winners-page-route-select-icon">play_arrow</span>
            <div class="winners-page-route-select-text">
                Comparativo de Preços
            </div>
        </div>
        <div class="winners-page-title"> Comparativo de Preços</div>
        <MobileNote class="winners-page-mobile-info"/>
        <div class="winners-page-buttons-container">
            <div class="winners-page-buttons-1">
                <div :class="isActiveTab('PRODUCTS')" class="button-containear winners-page-buttons-1-mobile "  v-on:click="setProducts">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">inventory_2</span>
                    <div class="button-text">Produtos</div>
                </div>
                <div v-if="total_pre_orders > 0" :class="isActiveTab('PRE-ORDERS')" class="button-containear winners-page-buttons-1-mobile winners-page-button-1"  v-on:click="setPreOrders">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">reorder</span>
                    <div class="button-text">{{can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pré-pedidos: ${to_currency(total_pre_orders)}` : 'Pré-pedidos'}}</div>
                </div>
                <div :class="isActiveTab('ORDERS')" class="button-containear winners-page-buttons-1-mobile winners-page-button-1"  v-on:click="setOrders">
                    <span class="material-icons-outlined winners-page-buttons-1-icon">local_shipping</span>
                    <div class="button-text">{{can('REQUEST', 'READ_ALL_SUBREQUESTS') ? `Pedidos: ${to_currency(total_orders)}` : 'Pedidos'}}</div>
                </div>
            </div>
            <div class="winners-page-buttons-2">
                <StandardButton class="mobile-button" :action="goBack" :iconName="'arrow_back'" :text="'Voltar'"/>
                <StandardButton class="mobile-button" :action="reset" iconName="autorenew" text="Reiniciar"/>
            </div>
        </div>
        <div style="overflow-x: auto;">
            <div v-if="request" style="min-width: 900px;">
                <Products
                    v-show="is_open('PRODUCTS') && can('REQUEST', 'READ_ALL_SUBREQUESTS') && request"
                    :requests_products="request.requests_products"
                    :sellers="sellers"
                    :request="request"
                    :filters="filters"
                    ref="products"
                    @reload="load_request"
                />
                <PreOrders
                    v-show="is_open('PRE-ORDERS')"
                    :type="'NOT_ORDERED'"
                    :request="request"
                    @reload="load_request"
                    @total="(total) => total_pre_orders = total"
                />
                <PreOrders
                    v-show="is_open('ORDERS')"
                    :type="'ORDERED'"
                    :request="request"
                    @reload="load_request"
                    @total="(total) => total_orders = total"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Header from "@/components/cliente/base-components/Header";
import Products from "./components/products/_productsV2";
import PreOrders from "./components/pre-orders/_pre-orders.componentV2";
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import OfferService from "@/services/v3/requests/offer.service"
import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import RequestsService from "@/services/v2/requests.service"
import V3RequestsService from "@/services/v3/request.service"
import { mapState, mapGetters } from 'vuex'
import { perm_mixin } from '@/mixins/permission.mixin'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import MobileNote from '@/components/notes/mobile-promotion.noteV2';
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import { pag_mixin } from '@/mixins/pagination.mixin'

export default {
    mixins: [ loaderMixin, perm_mixin, pag_mixin ],
    props: { id: [ Number, String ] },
    components: {
    ccHeader: Header,
    ccPagination: Pagination,
    PreOrders,
    MobileNote,
    Products,
    StandardButton,
    StandardInputAuto
},
    data() {
        return {
            report_data: null,
            report_data_with_last_offer: null,
            is_loading: true,
            is_loading_sellers: true,
            others_offers: null,
            VIEW: true,
            CONSTANTS: CONSTANTS,
            freezed: false,
            request: null,
            last_offers: [],
            sellers: [],
            offer_svc: new OfferService(),
            svc: new RequestsService(),
            v3_svc: new V3RequestsService(),
            service_v1: new QuotationService(),
            description: "",
            print: false,
            active_menu: "PRODUCTS",
            showOptionSelect: false,
            filters: {
                provider: null,
                category: null,
                store: null,
                has_observation: false,
                others_only: false,
                variation_price: false,
                name: ''
            },
            total_pre_orders: 0,
            total_orders: 0,
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações"
                },
                {
                    name: "Cotação " + this.$route.params.id
                }
            ]
        }
    },
    watch: {
        filters: {
            handler: function(val) {
                this.$store.dispatch('filters/update_request_filters', { ...val, id: this.$route.params.id })
            },
            deep: true
        },
        'VIEW' : function(new_val) {
            this.$store.dispatch('set_view', new_val)
        }
    },
    computed: {
        has_observation(){
            return this.filters.has_observation ? "O" : "P"
        },
        ...mapState([ 'user' ]),
        ...mapGetters('filters', [ 'request_filters' ]),
        ...mapGetters({ CURRENT_VIEW: 'view' })
    },
    methods: {
        setDescription (value) {
            this.description = value
            if (!this.is_loading){
                this.is_loading = true
                setTimeout(()=>{
                }, 1500)
            }
        },
        goBack () { this.$router.push('/cliente/monitores/cotacoes') },
        setOrders () { this.open('ORDERS') },
        setPreOrders () { this.open('PRE-ORDERS') },
        setProducts () { this.open('PRODUCTS') },
        isActiveTab(tab){ if (this.active_menu == tab) return 'button-containear-active' },
        closeOptionsSelect () { this.showOptionSelect = false },
        to_currency(value) {
            return this.$options.filters.currency(value);
        },
        is_open(menu) {
            return this.active_menu == menu;
        },
        open(menu) {
            if(menu) {
                if (menu == "PRE-ORDERS") {
                    if (!this.$store.state.current_user.usu_idLoja || this.$store.state.current_user.usu_acesso_comp_loja == 1) {
                        this.active_menu = menu;
                    }
                } else {
                    this.active_menu = menu;
                }
            }
        },
        async sync_request() {
            try {
                let result = await this.v3_svc.sync(this.$route.params.id)
                return result
            } catch(e) {
                ErrorHandlerService.handle(e, this.$store);
            }
            return null
        },
        async load_request(params = {}) {
            try {
                this.request = await this.v3_svc.load(this.$route.params.id).then(response => response.data)
                if(params.done) {
                    params.done(this.request)
                }
                this.$forceUpdate()
            } catch (e) {
                console.log(e.response, "xxxx", e)
            }
            this.is_loading = false
        },
        loadCategories() {
            this.service_v1
            .getCategoriasCotacao(this.$route.params.id)
            .then(response => response.data).then(data => {
                this.filter_by_category_options = [ { value: "", name: "Todas as categorias" } ]
                data.categorias.forEach(c =>
                    this.filter_by_category_options.push({
                        name: c.cat_descricao,
                        value: c.cat_id
                    })
                );
            });
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                { name: "Painel de Operações", route: 'monitors-page' },
                { name: "Cotações", route: 'monitors-page' },
                { name: `#${this.$route.params.id}`, route: 'cliente-monitores-vencedores' }
            ])
        },
        reset() {
            this.confirm_action({
                message: "Reiniciar Pedidos!",
                subtitle: `Esta operação removerá toda a seleção de compra, deseja continuar?`,
                cancel_text: 'Continuar',
                confirm_text: 'Reiniciar!',
                callback: () => {
                    this.is_loading = true
                    this.v3_svc.reset(this.request.id).then( () => {
                            this.request = undefined
                            this.load_request()
                        }
                    )
                }
            })
        },
        load_products() {
            return this.offer_svc.last_offers(this.id, this.seller_id)
                .then(response => response.data)
                .then(response => {
                    this.last_offers = response
                    this.request.requests_products.forEach(rp => {
                        response.forEach(lo => {
                            if(rp.product.id == lo.product_id) {
                                rp.last_offer = lo
                            }
                        })
                    })
                })
        },
    },
    async created() {
        await this.sync_request()
        this.load_request()
        this.update_breadcrumbs()
        this.open(this.$route.query.tab);
    }
};
</script>

<style lang="scss" scoped>

.winners-page-containear{
    padding:  0 15px 60px 0;
    font-size: 1vw;
}
.winners-page-route-select{
    display: flex;
    align-items: center;
}
.winners-page-route-select-text{
    font-weight: 400;
    font-size: 1.25em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.winners-page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.winners-page-title{
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 35px;
}
.winners-page-mobile-info{
    margin-top: 3vh;
    width: 36%;
}
.winners-page-buttons-container{
    display: flex;
    margin-top: 6vh;
    margin-bottom: 4vh;
    justify-content: space-between;
}
.winners-page-buttons-1{
    display: flex;
    width: 50%;
}
.button-containear{
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
}
.winners-page-buttons-1-icon{
    color: var(--primary-color);
}
.button-text{
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
  margin-left: 1vw;
}
.button-containear-active{
    background-color: #fdf0e9;
    .button-text{color: var(--primary-color);}
}
.winners-page-button-1{
    margin-left: 1.5vw;
}
.button-containear:hover{
  background-color: #fdf0e9;
}
.button-containear:hover > .button-text{
  color: var(--primary-color);
}
.winners-page-buttons-2{
    display: flex;
    width: 25%;
    justify-content: space-between;
}
.winners-page-filters-container{
    display: flex;
    margin-top: 5vh;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.winners-page-selects-container{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.winners-page-products-container{
    margin-top: 4vh;
    overflow-x: auto;
}
@media only screen and (max-width: 1200px){.winners-page-containear{font-size: 1.2vw;}}
@media only screen and (max-width: 1200px){
    .winners-page-buttons-1{width: 48%;}
    .winners-page-buttons-container{ flex-direction: column;}
    .winners-page-buttons-2{ width: 70%; margin-top: 1vh;}
}
@media only screen and (max-width: 1000px){
    .winners-page-containear{font-size: 1.5vw;}
    .winners-page-mobile-info{ width: 55%;}
    .winners-page-buttons-1{
        width: 50%;
        margin-bottom: 3vh;
    }
    .winners-page-selects-container{ width: 100%;}
}
@media only screen and (max-width: 870px){
    .winners-page-containear{font-size: 1.7vw;}
    .winners-page-buttons-1{ width: 60%;}
    .winners-page-buttons-2{ width: 80%;}
}
@media only screen and (max-width: 800px){
    .mobile{display: block; margin-bottom: 2vh;}
    .desktop{display: none;}
    .winners-page-filters-container{flex-direction: column;}
    .winners-page-mobile-info{width: 70%;}
    .winners-page-buttons-1{ width: 70%;}
}
@media only screen and (max-width: 680px){
    .winners-page-buttons-2{ width: 100%;}
    .winners-page-containear{font-size: 2vw;}
    .winners-page-buttons-1{ width: 100%; justify-content: space-between;}
}
@media only screen and (max-width: 580px) {
    .winners-page-buttons-2{ flex-direction: column; }
    .mobile-button{margin-bottom: 1.5vh;}
}
@media only screen and (max-width: 440px){
    .winners-page-containear{font-size: 3vw;}
    .winners-page-mobile-info{ width: 100%;}
    .winners-page-buttons-1-mobile{ width: 33%; }
}
@media only screen and (max-width: 370px){
    .winners-page-buttons-1{width: 100%;}
    .winners-page-containear{
        font-size: 4vw;
        padding:  0 15px 15px 0;
    }
}
</style>
