<template>
    <div class="table-container">
        <div class="desktop-table">
            <div class="page-table-header">
                <div class="icon-width"></div>
                <div class="page-table-header-text desc-width">
                    Descrição / EAN
                </div>
                <div class="page-table-header-text category-width">
                    Categoria / Qtd.
                </div>
                <div class="page-table-header-text qty-width">
                    Qtd. Cot
                </div>
                <div class="page-table-header-text qty-width">
                    Med. Cmp.
                </div>
                <div class="page-table-header-text unit-val-width">
                    Valor Unit. / %
                </div>
                <div class="page-table-header-text total-val-width">
                    Total / % Acumulado
                </div>
            </div>
            <div v-for="(produto, idx) in products" :key="idx">
                <div class="product-text page-table-row-container">
                    <div class="icon-width" @click="getProdutosInternos(produto)">
                        <span v-if="!produto.expanded"
                            class="material-icons product-tab-colapsed-icon">arrow_forward_ios</span>
                        <span v-else class="material-icons product-tab-expanded-icon">arrow_forward_ios</span>
                    </div>
                    <div class="page-table-desc-column desc-width">
                        {{ produto.pro_descricao }}<br>#{{ produto.pro_ean || '--' }}
                    </div>
                    <div class="page-table-desc-column category-width">
                        {{ produto.cat_descricao }} <br> {{ produto.quantidade }}
                    </div>
                    <div class="page-table-desc-column qty-width">
                        {{ produto.qtdCots }}
                    </div>
                    <div class="page-table-desc-column qty-width">
                        {{ (produto.quantidade / produto.qtdCots).toFixed(2) }}
                    </div>
                    <div class="page-table-desc-column unit-val-width">
                        {{
                            produto.cli_novoPedido == 1 &&
                                produto.cli_tipoPedido == 1
                                ? formartNumber(
                                    produto.valorTotal /
                                    produto.quantidade
                                )
                                : formartNumber(
                                    produto.valorTotal /
                                    produto.quantidade
                                )
                        }} <br> {{ produto.percent }}%
                    </div>
                    <div class="page-table-desc-column total-val-width">
                        {{ formartNumber(produto.valorTotal) }} <br> {{ produto.percentAcumulate }}%
                    </div>
                </div>
                <div v-if="produto.expanded">
                    <div class="page-table-header">
                        <div class="page-table-header-text desc-width">
                            Marca / Fornecedor
                        </div>
                        <div class="page-table-header-text date-width">
                            Data Compra / Número Cotação
                        </div>
                        <div class="page-table-header-text req-width">
                            N. Requisitantes
                        </div>
                        <div class="page-table-header-text qty-width">
                            Quantidade
                        </div>
                        <div class="page-table-header-text unit-val-width">
                            Valor Unit
                        </div>
                        <div class="page-table-header-text total-val-width">
                            Valor Total
                        </div>
                    </div>
                    <div v-for="(info, index) in produto.fornecedores" :key="`inf-line-${index}`"
                        class="product-text page-table-row-container">
                        <div class="page-table-desc-column desc-width">
                            {{ info.marca }}<br>#{{ info.nomeFornecedor || '--' }}
                        </div>
                        <div class="page-table-desc-column date-width">
                            {{ info.data_geracao | moment("DD/MM/YYYY") }} <br> {{ info.id_cotacao }}
                        </div>
                        <div class="page-table-desc-column req-width">
                            {{ info.requisitantes }}
                        </div>
                        <div class="page-table-desc-column qty-width">
                            {{ info.quantidade }}
                        </div>
                        <div class="page-table-desc-column unit-val-width">
                            {{
                                produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1
                                    ? formartNumber(
                                        info.valorTotal /
                                        info.quantidade
                                    )
                                    : formartNumber(
                                        info.valorTotal /
                                        info.quantidade
                                    )
                            }}
                        </div>
                        <div class="page-table-desc-column total-val-width">
                            {{ formartNumber(info.valorTotal) }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- mobile -->
        <div class="product-mobile-row" v-for="(produto, m) in products" :key="m">
            <div style="display: flex; justify-content: space-between;" @click="getProdutosInternos(produto)">
                <h6> {{ produto.pro_descricao }} </h6>
                <span v-if="!produto.expanded" class="material-icons product-tab-colapsed-icon">arrow_forward_ios</span>
                <span v-else class="material-icons product-tab-expanded-icon">arrow_forward_ios</span>
            </div>

            <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                <div style="width: 30%;">
                    <b>Categoria</b>
                    <p>{{ produto.cat_descricao }}</p>
                </div>
                <div style="width: 30%; text-align: center;">
                    <b>Qtd.</b>
                    <p>{{ produto.quantidade }}</p>
                </div>
                <div style="width: 30%; text-align: end;">
                    <b>Qtd. Cot</b>
                    <p>{{ produto.qtdCots }}</p>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                <div style="width: 30%;">
                    <b>Med. Cmp.</b>
                    <p>{{ (produto.quantidade / produto.qtdCots).toFixed(2) }}</p>
                </div>
                <div style="width: 30%; text-align: center;">
                    <b>Valor Unit.</b>
                    <p>{{
                        produto.cli_novoPedido == 1 &&
                            produto.cli_tipoPedido == 1
                            ? formartNumber(
                                produto.valorTotal /
                                produto.quantidade
                            )
                            : formartNumber(
                                produto.valorTotal /
                                produto.quantidade
                            )
                    }}</p>
                </div>
                <div style="width: 30%; text-align: end;">
                    <b>Valor Total</b>
                    <p>{{ formartNumber(produto.valorTotal) }}</p>
                </div>
            </div>
            <template v-if="produto.expanded">
                <div class="mobile-expanded-row" v-for="(info, index) in produto.fornecedores" :key="`inf-line-${index}`">
                    <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                        <div style="width: 30%;">
                            <b>Marca</b>
                            <p>{{ info.marca }}</p>
                        </div>
                        <div style="width: 30%; text-align: center;">
                            <b>Fornecedor</b>
                            <p>{{ info.nomeFornecedor || '--' }}</p>
                        </div>
                        <div style="width: 30%; text-align: end;">
                            <b>Data</b>
                            <p>{{ info.data_geracao | moment("DD/MM/YYYY") }}</p>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                        <div style="width: 30%;">
                            <b>Cotação</b>
                            <p>{{ info.id_cotacao }}</p>
                        </div>
                        <div style="width: 30%; text-align: center;">
                            <b>Fornecedor</b>
                            <p>{{ info.nomeFornecedor || '--' }}</p>
                        </div>
                        <div style="width: 30%; text-align: end;">
                            <b>Requisitantes</b>
                            <p>{{ info.requisitantes }}</p>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                        <div style="width: 30%;">
                            <b>Quantidade</b>
                            <p>{{ info.quantidade }}</p>
                        </div>
                        <div style="width: 30%; text-align: center;">
                            <b>Valor Unit</b>
                            <p>{{
                                produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1
                                    ? formartNumber(
                                        info.valorTotal /
                                        info.quantidade
                                    )
                                    : formartNumber(
                                        info.valorTotal /
                                        info.quantidade
                                    )
                            }}</p>
                        </div>
                        <div style="width: 30%; text-align: end;">
                            <b>Total</b>
                            <p>{{ formartNumber(info.valorTotal) }}</p>
                        </div>
                    </div>
                </div>
            </template>

        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import numeral from "numeral";
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'ProductsTable',
    props: {
        products: {
            type: Array
        },
        categories: {
            type: Array
        },
        total: {
            type: Number
        },
        date: {
            type: Object
        }
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        getCategory(p) {
            const resp = this.categories.filter(c => c.id == p.cat_id)
            return resp.length > 0 ? resp[0].cat_descricao : '--'
        },
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentualNumber(produto) {
            return parseFloat((produto.valorTotal * 100) / this.total);
        },
        getProdutosInternos(product) {
            this.isLoading = true
            if (!product.fornecedores) {
                var requestUrl =
                    `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?produtoFilter=` +
                    product.pro_id +
                    "&groupBy=pe.id_cotacao&imprimir=true&ordenar=data_geracao&tipoorder=desc";
                requestUrl += "&dateIni=" + this.date.primeiro_dia + "&dateFim=" + this.date.ultimo_dia;
                axios.get(requestUrl, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                    .then(({ data }) => {
                        product.fornecedores = data.produtos;
                        product.expanded = !product.expanded;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            if (
                                error.response.data.mensagem ==
                                CONSTANTS.TOKEN_EXPIRADO
                            ) {
                                // location.href = CONSTANTS.ROUTE_LOGIN;
                                window.location.assign(CONSTANTS.ROUTE_LOGIN)
                            }
                        } else if (error.response.status === 500) {
                            if (error.response.data.mensagem) {
                                this.mensagemErro =
                                    error.response.data.mensagem;
                            } else {
                                this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                            }
                        }
                        this.isLoading = false
                    });
            } else {
                product.expanded = !product.expanded;
                this.isLoading = false
            }
        },
    },
    mounted() {
        let lastPercent = 0;
        console.log(1111);

        this.products.forEach(newItem => {
            console.log(123);

            newItem.percent = this.getPercentualNumber(newItem).toFixed(2);
            newItem.percentAcumulate = parseFloat(
                parseFloat(newItem.percent) +
                parseFloat(lastPercent)
            ).toFixed(2);
            lastPercent = newItem.percentAcumulate;
        });
        this.$forceUpdate()
    },
}
</script>

<style lang="scss" scoped>
.table-container {
    height: 60vh;
    overflow: auto;
}

.product-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vh;
}

.page-table-header-text {
    color: #605f5f;
    padding-left: 10px;
}

.page-table-header {
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    font-weight: bold;
    font-size: 1.15em;
    color: #605f5f;
    display: flex;
    padding: 1vh 0;
}

.desc-width {
    flex: 1;
}

.category-width {
    width: 15%;
}

.qty-width {
    width: 10%;
}

.unit-val-width {
    width: 12%;
}

.total-val-width {
    width: 16%;
}

.percent-width {
    width: 8%;
}

.date-width {
    width: 25%;
}

.req-width {
    width: 15%;
}

.icon-width {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-analysis-mobile-header {
    display: none;
}

.product-tab-colapsed-icon {
    font-size: 1.5em;
    margin-right: 1vw;
    cursor: pointer;
}

.product-tab-expanded-icon {
    cursor: pointer;
    color: var(--primary-color);
    font-size: 1.5em;
    rotate: 90deg;
    margin-right: 1vw;
}

.percent-accumulated-width {
    width: 15%;
}

.page-table-line-special {
    background: #f7f7f7;
}

.page-table-desc-column {
    padding: 0.6em 0.8em;
}

.page-table-row-container {
    display: flex;
}

.product-mobile-row {
    display: none;
}

@media only screen and (max-width: 700px) {
    .table-container {
        height: 75vh;
    }

    .product-mobile-row {
        display: block;
        padding: 1.5vh 0;
        border-bottom: 1px solid lightgray;

        p {
            margin-top: 0;
        }

        h6 {
            font-weight: bold;
        }
    }

    .desktop-table {
        display: none;
    }

    .product-tab-colapsed-icon {
        font-size: 2em;
    }

    .product-tab-expanded-icon {
        font-size: 2em;
    }
    .mobile-expanded-row{
        background-color: rgb(239, 238, 238);
        padding: 2vh 3vw;
        margin-bottom: 1vh;
        border-radius: 8px;
    }
}
</style>