<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row" id="modalBodyPrint" >
            <div class="col-12" v-if="!stores_only">
                <div class="row">
                    <div class="col p-0">
                        <div class="store-info">
                            <label class="label-control">
                                {{ salesman.nomeVendedor }}
                                <span class="rate"><i class="fa fa-star" aria-hidden="true"></i> ({{ rate }})</span>
                                <span v-if="salesman.usu_telefone"> - {{ salesman.usu_telefone || "" | VMask('(##) #####-####') }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 p-0">
                        <div class="store-info">
                            <label class="label-control">produto</label>
                            <input type="text" class="form-control" v-model="product.descricaoProduto" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-6 p-0" v-if="salesman.pro_observacao">
                        <div class="store-info">
                            <label class="label-control">marca</label>
                            <input type="text" class="form-control" v-model="salesman.pro_observacao" disabled="disabled">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col p-0" v-if="salesman.embalagem">
                        <div class="store-info">
                            <label class="label-control">
                                embalagem
                                <i class="far fa-edit" @click="open_edit_wrap = true" v-if="product.flag_generico == 0 && !product.pro_principal && !product.possuiPedido"/>
                            </label>
                            <input type="text" class="form-control" v-model="salesman.embalagem" disabled="disabled">
                        </div>
                    </div>
                    <div class="col p-0">
                        <div class="store-info">
                            <label class="label-control">
                                preço
                                <i class="fa fa-handshake-o" @click="open_negotiation_modal = true" v-if="!product.possuiPedido" title="Negociar" />
                            </label>
                            <input type="text" class="form-control" :value="formatPrice(salesman.valor)" disabled="disabled">
                        </div>
                    </div>

                    <div class="col p-0" v-if="product.embalagem || product.unidade_compra">
                        <div class="store-info">
                            <label class="label-control">unid compra</label>
                            <input type="text" class="form-control" :value="product.flag_generico == 0 ? product.pro_embalagem : product.unidade_compra" disabled="disabled">
                        </div>
                    </div>
                    <div class="col p-0" v-if="salesman.observacao && salesman.observacao.trim().length > 0">
                        <div class="store-info">
                            <label class="label-control label-control">observações</label>
                            <input type="text" class="form-control" v-model="salesman.observacao" disabled="disabled">
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-2 p-0" v-if="delivery && delivery.data_entrega">
                        <div class="store-info outstand">
                            <label class="label-control label-control">data entrega</label>
                            <input type="text" class="form-control" :value="(delivery ? delivery.data_entrega : '') | formatDateOnly" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-2 p-0" v-if="salesman.faturamento_minimo">
                        <div class="store-info outstand">
                            <label class="label-control label-control">fat. min</label>
                            <input type="text" class="form-control" :value="formatPrice(salesman.faturamento_minimo)" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-2 p-0" v-if="delivery && delivery.forma">
                        <div class="store-info outstand">
                            <label class="label-control label-control">forma de pgto</label>
                            <input type="text" class="form-control" :value="delivery ? delivery.forma : ''" disabled="disabled">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3 mt-3">
                <div class="shadow p-3 rounded">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="header pl-2">ID</th>
                                <th class="header">Empresa</th>
                                <th class="header">Req.</th>
                                <th class="header">Est.</th>
                                <th class="header">Pedido</th>
                                <th class="header">Total Item</th>
                                <th class="header">Total Pedido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="store in visibleStores()" v-bind:key="'store-' + store.codLoja">
                                <td>{{ store.codSolicitacao }}</td>
                                <td>{{ store.descricaoLoja }}</td>
                                <td>{{ store.quantidade }}</td>
                                <td>{{ store.estoque }}</td>
                                <td class="amount-input">
                                    <input type="number" min="0" :readonly="store.vendedorPossuiPedido > 0"  v-on:keyup="saveOne($event, store)" :class="{ 'changed' : store.old_value != store.quantidadeSugestao && store.saved }" class="amount" v-model="store.quantidadeSugestao">
                                    <div class="product-modal-loader" :class="{ hidden : !store.is_loading }">
                                        <img src="/images/loader.svg"/>
                                    </div>
                                </td>
                                <td class="amount">
                                    {{ formatPrice(calculateAmount(store)) }}
                                </td>
                                <td class="amount">
                                    <span :class="{'lower' : isLower(store) }">
                                        {{ formatPrice(calculateTotalPedidoAmount(store)) }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ProductWrapModal
                v-if="open_edit_wrap"
                :product="product"
                @close="update_wrap(product)"
            />
            <NegotiationModal
                @close="reset_negotitation"
                :typing="salesman  || {}"
                :product="mapped_product || {}"
                v-if="open_negotiation_modal"
                :quotation_id="$route.params.id" />
        </div>
    </cc-modal> -->
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="subrequest-modal-header">
                {{`análise das quantidades - forn: #${this.salesman.nomeFornecedor}`}}
                <span v-on:click="close" class="material-icons subrequest-modal-close-icon">cancel</span>
            </div>
            <div class="subrequest-modal-body">
                <div class="subrequest-modal-body-title">
                    <div style="display:flex">
                        <div >Vendedor: {{ salesman.nomeVendedor }}</div>
                        <span class="rate"><i class="fa fa-star" aria-hidden="true"></i> ({{ rate }})</span>
                        <a v-if="salesman.usu_telefone" style="margin-left: 1vw; color: black" :href="'https://api.whatsapp.com/send?phone='+formatNumber(salesman.usu_telefone)" target="_blank">{{'Tel:' + (phone(salesman.usu_telefone))}}</a>
                    </div>
                    <div class="subrequest-modal-body-title-buttons">
                        <StandardButton class="subrequest-modal-body-mobile-width" :action="close" :iconName="'arrow_back'" :text="'Voltar'"/>
                        <StandardButton class="subrequest-modal-body-title-last-button subrequest-modal-body-mobile-width" :action="saveAllAndClose" :iconName="'email'" :text="'Enviar'"/>
                    </div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-product mobile-product-name">
                        <div class="subrequest-modal-body-input-title">Produto</div>
                        <div class="subrequest-modal-body-input-value">{{product.descricaoProduto}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-product mobile-product-brand" v-if="salesman.pro_observacao">
                        <div class="subrequest-modal-body-input-title">Marca</div>
                        <div class="subrequest-modal-body-input-value">{{salesman.pro_observacao}}</div>
                    </div>
                </div>
                <div class="subrequest-modal-body-input-product" style="width: 100%; margin-bottom: 2vh;">
                    <div class="subrequest-modal-body-input-title">Observação</div>
                    <div class="subrequest-modal-body-input-value">{{this.salesman.observacao}}</div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-smal" v-if="salesman.embalagem">
                        <div class="subrequest-modal-body-input-title">Embalagem <i class="far fa-edit" style="color: var(--primary-color); cursor: pointer" @click="open_edit_wrap = true" v-if="!product.possuiPedido"/></div>
                        <div class="subrequest-modal-body-input-value">{{salesman.embalagem}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal">
                        <div class="subrequest-modal-body-input-title">Preço <i class="fa fa-handshake-o" style="color: var(--primary-color); cursor: pointer" @click="open_negotiation_modal = true" v-if="!product.possuiPedido" title="Negociar" /></div>
                        <div class="subrequest-modal-body-input-value">{{formatPrice(salesman.valor)}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="product.embalagem || product.unidade_compra">
                        <div class="subrequest-modal-body-input-title">Unid. Compra</div>
                        <div class="subrequest-modal-body-input-value">{{product.flag_generico == 0 ? product.pro_embalagem : product.unidade_compra}}</div>
                    </div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-smal" v-if="delivery && delivery.data_entrega">
                        <div class="subrequest-modal-body-input-title">Data Entrega</div>
                        <div class="subrequest-modal-body-input-value">{{(delivery ? delivery.data_entrega : '') | formatDateOnly}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="salesman.faturamento_minimo">
                        <div class="subrequest-modal-body-input-title">Fat. Min</div>
                        <div class="subrequest-modal-body-input-value">{{formatPrice(salesman.faturamento_minimo)}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="delivery && delivery.forma">
                        <div class="subrequest-modal-body-input-title">Forma De Pgto</div>
                        <div class="subrequest-modal-body-input-value">{{delivery ? delivery.forma : ''}}</div>
                    </div>
                </div>
                <cc-loader v-if="is_loading" style="margin-top: 5vh"/>
                <div v-else class="subrequest-modal-table-overflow">
                    <div class="subrequest-modal-table-width">
                        <div class="subrequest-modal-table">
                            <div class="subrequest-modal-table-id-width">ID</div>
                            <div class="subrequest-modal-table-company-width">Empresa</div>
                            <div class="subrequest-modal-table-req-width">Req.</div>
                            <div class="subrequest-modal-table-est-width">Est.</div>
                            <div class="subrequest-modal-table-order-width">Pedido</div>
                            <div class="subrequest-modal-table-total-item-width">Total Item</div>
                            <div class="subrequest-modal-table-total-order-width">Total Pedido</div>
                        </div>
                        <div class="subrequest-modal-table-row" v-for="(store, idx) in visibleStores()" v-bind:key="idx" :class="getSpecialBackground(idx)">
                            <!-- desktop -->
                            <div class="subrequest-modal-table-id-width desktop">
                                <div class="subrequest-modal-mobile-header">ID</div>
                                {{store.codSolicitacao}}
                            </div>
                            <div class="subrequest-modal-table-company-width desktop">
                                <div class="subrequest-modal-mobile-header">Empresa</div>
                                {{ store.descricaoLoja }}
                            </div>
                            <div class="subrequest-modal-table-req-width desktop">
                                <div class="subrequest-modal-mobile-header">Req.</div>
                                {{ store.quantidade }}
                            </div>
                            <div class="subrequest-modal-table-est-width desktop">
                                <div class="subrequest-modal-mobile-header">Est.</div>
                                {{ store.estoque ?  store.estoque : '--'}}
                            </div>
                            <div class="subrequest-modal-table-order-width desktop">
                                <div class="subrequest-modal-mobile-header">Pedido</div>
                                <input
                                class="subrequest-modal-table-order-input"
                                :class="{'subrequest-modal-table-order-input-disabled':store.vendedorPossuiPedido > 0, 'subrequest-modal-table-order-input-altered': store.isAltered}"
                                type="number"
                                min="0"
                                :readonly="store.vendedorPossuiPedido > 0"
                                v-on:keydown="saveOne($event, store)"
                                v-model="store.quantidadeSugestao"
                                :id="'product-amount'+ idx"
                                v-on:keyup="process_event($event, idx)">
                            </div>
                            <div class="subrequest-modal-table-total-item-width desktop">
                                <div class="subrequest-modal-mobile-header">Total Item</div>
                                {{ formatPrice(calculateAmount(store)) }}
                            </div>
                            <div class="subrequest-modal-table-total-order-width desktop">
                                <div class="subrequest-modal-mobile-header">Total Pedido</div>
                                <span :class="{'lower' : isLower(store) }">
                                    {{ formatPrice(calculateTotalPedidoAmount(store)) }}
                                </span>
                            </div>

                            <!-- mobile -->
                            <div class="subrequest-modal-mobile-header mobile-table-title">
                                {{ store.descricaoLoja }}
                            </div>
                            <div class="mobile mobile-table-row">
                                <div class=" mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">ID</div>
                                    {{store.codSolicitacao}}
                                </div>
                                <div class="mobile-center mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">Req.</div>
                                    {{ store.quantidade }}
                                </div>
                                <div class="mobile-end mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">Est.</div>
                                    {{ store.estoque ?  store.estoque : '--'}}
                                </div>
                            </div>
                            <div class="mobile mobile-table-row">
                                <div class=" mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">Pedido</div>
                                    <input
                                    class="subrequest-modal-table-order-input"
                                    :class="{'subrequest-modal-table-order-input-disabled':store.vendedorPossuiPedido > 0, 'subrequest-modal-table-order-input-altered': store.isAltered}"
                                    type="number"
                                    min="0"
                                    :readonly="store.vendedorPossuiPedido > 0"
                                    v-on:keydown="saveOne($event, store)"
                                    v-model="store.quantidadeSugestao"
                                    :id="'product-amount'+ idx"
                                    v-on:keyup="process_event($event, idx)">
                                </div>
                                <div class="mobile-center mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">Total Item</div>
                                    {{ formatPrice(calculateAmount(store)) }}
                                </div>
                                <div class="mobile-end mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">Total Pedido</div>
                                    <span :class="{'lower' : isLower(store) }">
                                        {{ formatPrice(calculateTotalPedidoAmount(store)) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProductWrapModal
            v-if="open_edit_wrap"
            :product="product"
            :salesman="salesman"
            @close="update_wrap(product)"
        />
        <NegotiationModal
            @close="reset_negotitation"
            :typing="salesman  || {}"
            :product="mapped_product || {}"
            v-if="open_negotiation_modal"
            :quotation_id="$route.params.id" />
    </div>
</template>

<script>

import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState } from 'vuex'
import currencyPrecision from '@/mixins/currencyPrecision';
export default {
    components: {
        ProductWrapModal,
        NegotiationModal,
        StandardButton
    },
    props: ["quotationId", "product", "salesman", "sellers", "loj_id", "stores_only"],
    mixins: [ perm_mixin,currencyPrecision ],
    data() {
        return {
            modal: {
                title: `análise das quantidades - forn: #${this.salesman.nomeFornecedor}`,
                permitted: true,
                submit: this.saveAllAndClose,
                submit_text: 'Enviar',
                subtitle: 'Defina aqui as quantidades a serem compradas por unidade requisitante',
                icon_title: 'fas fa-calculator',
                style: {
                    width: "70%"
                }
            },
            open_negotiation_modal: false,
            open_edit_wrap: false,
            stores: [],
            is_loading: true,
            service: new QuotationService(),
            delivery : {},
            rate: 5,
            myStore: undefined
        };
    },
    methods: {
        phone (text = '') {
            if (!text) {
                text = '';
            }
            if (text.length < 6) {
                return text;
            }

            text = text.toString().replace('+55', '').replace(/\D/g, '');

            let mask = '($1) $2-$3';
            let regex = /^(\d{2})(\d{5})?(\d{4})?/;

            if (text.length > 13) {
                text = text.substring(text.length - 11);
            } else if (text.length == 13) {
                text = text.substring(text.length - 10);
            } else if (text.length == 12) {
                regex = /^(\d{3})(\d{5})?(\d{4})?/;
            } else if (text.length == 11) {
                regex = /^(\d{2})(\d{5})?(\d{4})?/;
            } else if (text.length == 10) {
                regex = /^(\d{2})(\d{4})?(\d{4})?/;
            } else if (text.length == 9) {
                regex = /^(\d{5})?(\d{4})?/;
                mask = '$1-$2';
            } else if (text.length == 8) {
                regex = /^(\d{4})?(\d{4})?/;
                mask = '$1-$2';
            } else {
                regex = /^(\d{2})?(\d{1,})?/;
                mask = '($1) $2';
            }

            return text.replace(regex, mask);
        },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        process_event(e, index) {

            if (e.key == "ArrowDown"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index + 1))
                if (!element) return
                if (element.className.includes('subrequest-modal-table-order-input-disabled')) this.process_event(e, index + 1)
                else {
                    element.focus()
                    element.select()
                }
            } else if (e.key == "ArrowUp"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index - 1))
                if (!element) return
                if (element.className.includes('subrequest-modal-table-order-input-disabled')) this.process_event(e, index - 1)
                else {
                    element.focus()
                    element.select()
                }
            }
		},
        is_focused(idx, product){
			let inputs = this.$refs["product-amount"];
			this.stores.forEach(p => p.focused = false)
			if(inputs && document.activeElement == inputs[idx]) {
				this.$set(product, "focused", true);
				this.$forceUpdate();
			}
		},
        reset_negotitation(reload, price, obs) {
            if (reload) {
                this.salesman.observacao = obs
                this.salesman.valor = price
                this.$emit('load_products')
            }
            this.open_negotiation_modal = false
            this.$forceUpdate()
        },
        update_wrap(product) {
            this.open_edit_wrap = false;
            this.reloadProviders()
        },
        visibleStores() {
            let resp = []
            this.stores.forEach(s => {
                if (this.myStore && this.myStore == s.codLoja) resp = [s]
                if( !this.myStore && s.loj_excluido!= '1' && (this.loj_id == undefined || s.codLoja == this.loj_id )){
                    resp.push(s)
                }
            })
            return resp
        },
        getDelivery(){
            let delivery = this.sellers
            .filter(s => s.fornecedores && s.fornecedores.length > 0)
            .map(s => {
                let providers = s.fornecedores.filter(f => f.idFornecedor == this.salesman.idFornecedor);
                return providers[0];
            })
            .filter(s => s != undefined)
        return delivery && delivery[0] ? delivery[0].entrega : {};
        },
        getProvider(store) {
            let storeData = this.sellers.filter(s => s.loj_id == store.codLoja)[0];
            let provider = storeData.fornecedores.filter(p => p.idFornecedor == store.idFornecedor)[0];
            return provider;
        },
        isLower(store) {
            return (this.calculateTotalPedidoAmount(store) > 0 && this.calculateTotalPedidoAmount(store) < this.salesman.usu_fatura_min);
        },
        calculateAmount(store) {
            let result = this.salesman.valor*store.quantidadeSugestao
            return this.product.flag_generico == 0 && !this.product.pro_principal && this.user.request_type != 1 ? parseFloat(this.product.qtd_embalagem)*result : result
        },
        calculateTotalPedidoAmount(store) {
            let total = store.total || 0;
            let result = this.salesman.valor*store.old_value
            result = this.product.flag_generico == 0 && !this.product.pro_principal && this.user.request_type != 1? parseFloat(this.product.qtd_embalagem)*result : result
            return (total)-(result) + this.calculateAmount(store);
        },
        saveOne(e, store) {
            this.$forceUpdate()
            let key = getKey(e)

            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                // etc
            }

            if(key == "Enter" || key == "ArrowDown" || key == "ArrowUp") {
                e.preventDefault()
                this.$set(store, 'is_loading', true);
                this.save(store, (data) => {
                    this.$set(store, 'is_loading', false);
                    this.$set(store, 'saved', true);
                    // this.reloadProviders();
                    store.isAltered = true
                });
            }
        },
        reloadProviders() {
            this.getStores();
            this.$emit("loadProviders", this.salesman);
        },
        close() {
            this.$emit("close", this.salesman);
        },
        save(store, callback) {
            this.service.sendQuantidade({
                numerocotacao: this.$route.params.id,
                quantidade: store.quantidadeSugestao || "0",
                vendedor: this.salesman.idVendedor,
                produto: store.codProduto,
                loja: store.codLoja
            })
            .then(response => response.data)
            .then(callback);
        },
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        saveAllAndClose() {
            this.is_loading = true
            let storesToSave = this.stores;
            let saved = 0;
            storesToSave.forEach(store => {
                store.quantidadeSugestao = store.quantidadeSugestao || "0"
                this.save(store, (data)  =>{
                    saved++;
                    if(saved == storesToSave.length) {
                        this.reloadProviders();
                        this.close();
                    }
                })
            })
        },
        load_reviews() {
            this.svc.get_evaluation(this.seller.usu_id)
            .then(response => response.data)
            .then((data)=>{
                if(data.todas_avaliacoes) {
                    let nota = 0
                    data.todas_avaliacoes.forEach((data)=>{
                        nota += parseInt(data.nota)/data.todas_avaliacoes.length
                    })
                    this.rate = nota
                }
            })
        },
        getStores() {
            this.is_loading = this.stores.length === 0;
            var quotationService = new QuotationService();
            quotationService
                .getLojasQuantidade(
                    this.salesman.pro_id,
                    this.quotationId,
                    this.salesman.idVendedor
                )
                .then(response => response.data)
                .then(data => {
                    this.stores = data.produtos;
                    this.is_loading = false;
                    this.stores.forEach(s => {
                        this.sellers.forEach(element => {
                            if(s.descricaoLoja == element.loj_descricao) s.loj_excluido = element.loj_excluido
                        });
                        if(s.quantidadeSugestao == null) {
                            s.quantidadeSugestao = s.quantidade
                        }
                        s.old_value = s.quantidadeSugestao
                    })
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        formatPrice(value) {
            return this.checkPrecisionValue(value)

            let val = (value / 1).toFixed(2).replace(".", ",");
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
//         formatPrice(value) {
//             //checkprecision
//         const decimal = (value.toString().split('.')[1] || '').length;
//          if (decimal > 2) {
//         //    return "R$ " + Number(value).toFixed(4);
//             let newval= Number(value).toFixed(4)+''
//             newval= newval.replace(".", ",")
//            return "R$ " + newval;
//          } else {
//            let val = (value / 1).toFixed().replace(".", ",");
//            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

//          }
// }
    },
    computed: {
        ...mapState([ 'user' ]),
        mapped_product: function() {
            return {
                produtoDescricao: this.product.descricaoProduto,
                ultimaCompra: this.product.last_purchase?.valor,
                pro_observacao: this.salesman.pro_observacao,
                observacao: this.salesman.observacao,
                generico: this.product.flag_generico
            }
        }
    },
    mounted() {
        this.delivery = this.getDelivery(this.sellers[0]);
        this.getStores();
        this.product.idProduto = this.product.prod_id
        this.perms.forEach(element => {
            if(element.resource == "MY_COMPANY" && element.action == 'READ'){
                this.myStore = this.user.store_id
            }
        });

        // hide scroll body
        document.querySelector("body").style.overflow = 'hidden'
    },
    destroyed() {
        document.querySelector("body").style.overflow = 'auto'
    }
}
</script>

<style lang="scss" scoped>
@import "./product-sub-requests.modal";
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container{
  background-color: white;
  width: 95vw;
  border-radius: 12px;
}
.subrequest-modal-header{
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px 10px 0px 0px;
    padding: 2vh 1vw;
    font-weight: 500;
    font-size: 2.2em;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    align-items: center;
}
.subrequest-modal-close-icon{
    font-size: 1.4em;
    cursor: pointer;
}
.subrequest-modal-body{
    padding: 5vh 5vw;
    max-height: 95vh;
    overflow-y: auto;
}
.subrequest-modal-mobile-header{
    display: none;
}
.subrequest-modal-body-title{
    display: flex;
    align-items: flex-start;
    font-size: 1.65em;
    margin-bottom: 2vh;
}
.rate{
    margin-left: 2vw;
}
.subrequest-modal-body-title-buttons{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 1vw;
}
.subrequest-modal-body-title-last-button{
    margin-left: 1vw;
}
.subrequest-modal-body-inputs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}
.subrequest-modal-body-input-product{width: 45%;}
.subrequest-modal-body-input-smal{width: 30%;}

.subrequest-modal-body-input-title{
    font-weight: 400;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #898989;
    display: flex;
    justify-content: space-between;
}
.subrequest-modal-body-input-value{
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 1vw;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.15px;
    color: #515151;
    background-color: #e9ecef;
    min-height: 3.5vh;
}
.subrequest-modal-table{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0.5vw;
    font-size: 1.2em;
    font-weight: 600;
    color: #605F5F;
}
.subrequest-modal-table-id-width{width: 10%;}
.subrequest-modal-table-company-width{flex: 1;}
.subrequest-modal-table-req-width{width: 11%;}
.subrequest-modal-table-est-width{width: 11%;}
.subrequest-modal-table-order-width{width: 11%;}
.subrequest-modal-table-total-item-width{width: 11%;}
.subrequest-modal-table-total-order-width{width: 11%;}
.subrequest-modal-table-row{
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    padding: 1vh 0.5vw;
    align-items: center;
}
.subrequest-modal-table-order-input{
    width: 80%;
    background: rgba(23, 162, 184, 0.05);
    border-radius: 8px;
    border: none;
    font-weight: 400;
    font-size: 1.1em;
    color: var(--primary-color);
    padding-left: 1vw;
    outline: 1px solid var(--primary-color);
}
.subrequest-modal-table-order-input:focus{
    background-color: #f7dea8;
}
.subrequest-modal-table-order-input-disabled{ cursor: not-allowed; }
.subrequest-modal-table-order-input-altered{
    background-color: lightgreen !important;
}
.subrequest-modal-table-order-input-disabled:focus{ outline: none; }
.page-table-line-special{ background: #F7F7F7; }
.mobile{display: none;}

@media only screen and (max-width: 1100px){
    .modal-mask{font-size: 1.5vw;}
}
@media only screen and (max-width: 850px){
    .mobile{display: flex;}
    .subrequest-modal-body-title{flex-direction: column;}
    .modal-mask{font-size: 2vw;}
    .modal-content-container{
        width: 110vw;
    }
    .subrequest-modal-table{display: none;}
    .subrequest-modal-table-row{
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 2vh;
        font-size: 1.5em;
    }
    .subrequest-modal-table-id-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-company-width{flex: unset; width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-req-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-est-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-order-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-total-item-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-table-total-order-width{width: 100%; display: flex; justify-content: space-between;}
    .subrequest-modal-mobile-header{
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }
    .subrequest-modal-body-mobile-width{
        width: 48%;
        font-size: 3em;
    }
    .subrequest-modal-body-title-buttons{
        width: 100%;
        justify-content: space-between;
    }
    .modal-mask{font-size: 3vw;}
    .subrequest-modal-body-input-product{ margin-bottom: 1vh;}
    .subrequest-modal-body-input-smal{ margin-bottom: 1vh;}
    .subrequest-modal-table{margin-top: 3vh;}
    .modal-content-container{width: 115vw;}
    .mobile-product-name{
        width: 65%;
    }
    .mobile-product-brand{
        width: 30%;
    }
    .mobile-table-row{
        width: 100%;
        justify-content: space-between;
    }
    .mobile-colum-size{
        width: 30%;
    }
    .mobile-center{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mobile-end{
        display: flex;
        flex-direction: column;
        align-items: end;
    }
    .mobile-table-title{
        text-align: left;
        width: 100%;
    }
    .desktop{display: none;}
}
</style>
