<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row" id="modalBodyPrint" >
            <div class="col-12" v-if="offer && seller && !stores_only">
                <div class="row">
                    <div class="col p-0">
                        <div class="store-info">
                            <label class="label-control">
                                {{ seller.name }}
                                <span class="rate"><i class="fa fa-star" aria-hidden="true"></i> ({{ rate }})</span>
                                <span v-if="seller.phone"> - {{ seller.phone || "" | VMask('(##) #####-####') }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 p-0">
                        <div class="store-info">
                            <label class="label-control">produto</label>
                            <input type="text" class="form-control" v-model="product.name" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-6 p-0" v-if="offer.brand">
                        <div class="store-info">
                            <label class="label-control">marca</label>
                            <input type="text" class="form-control" v-model="offer.brand.name" disabled="disabled">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col p-0" v-if="product.packing">
                        <div class="store-info">
                            <label class="label-control">embalagem</label>
                            <input type="text" class="form-control" :value="offer.quantity" disabled="disabled">
                        </div>
                    </div>

                    <div class="col p-0">
                        <div class="store-info">
                            <label class="label-control">
                                preço
                                <i class="fa fa-handshake-o"
                                    @click="open_negotiation_modal = true"
                                    v-if="!has_orders()" title="Negociar" />
                            </label>
                            <input type="text" class="form-control" :value="formatPrice(offer.price)" disabled="disabled">
                        </div>
                    </div>

                    <div class="col p-0" v-if="!product.brand">
                        <label class="label-control">
                            Unid Compra
                            <i class="far fa-edit"
                                @click="open_edit_wrap = true"
                                v-if="!has_orders() && product.brand" />
                        </label>
                        <input type="text" class="form-control" v-model="product.packing" disabled="disabled">
                    </div>

                    <div class="col p-0" v-if="offer.obs && offer.obs.trim().length > 0">
                        <div class="store-info">
                            <label class="label-control label-control">observações</label>
                            <input type="text" class="form-control" v-model="offer.obs" disabled="disabled">
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-2 p-0" v-if="delivery && delivery.date">
                        <div class="store-info outstand">
                            <label class="label-control label-control">data entrega</label>
                            <input type="text" class="form-control" :value="(delivery ? delivery.date : '') | formatDateOnly" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-2 p-0" v-if="seller.delivery.minimum_billing">
                        <div class="store-info outstand">
                            <label class="label-control label-control">fat. min</label>
                            <input type="text" class="form-control" :value="formatPrice(seller.delivery.minimum_billing)" disabled="disabled">
                        </div>
                    </div>
                    <div class="col-2 p-0" v-if="delivery && delivery.form">
                        <div class="store-info outstand">
                            <label class="label-control label-control">forma de pgto</label>
                            <input type="text" class="form-control" :value="delivery ? delivery.form : ''" disabled="disabled">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3 mt-3">
                <div class="shadow p-3 rounded">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="header text-center">ID</th>
                                <th class="header">Empresa</th>
                                <th class="header">Req.</th>
                                <th class="header">Est.</th>
                                <th class="header">Pedido</th>
                                <th class="header">Total Item</th>
                                <th class="header">Total Pedido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="store in visibleStores()" v-bind:key="'store-' + store.id">
                                <td class="text-center">{{ store.pre_order.subrequest ? store.pre_order.subrequest.id : null }}</td>
                                <td class="text-uppercase">{{ store.trading_name }}</td>
                                <td>{{ store.pre_order.subrequest ? store.pre_order.subrequest.quantity : null }}</td>
                                <td>{{ store.estoque }}</td>
                                <td class="amount-input">
                                    <input type="number"
                                        min="0"
                                        :readonly="store.has_order"
                                        v-on:keyup="saveOne($event, store)"
                                        :class="{ 'changed' : store.old_value != store.pre_order.quantity && store.saved }"
                                        class="amount"
                                        v-model.number="store.pre_order.quantity">
                                    <div class="product-modal-loader" :class="{ hidden : !store.is_loading }">
                                        <img src="/images/loader.svg"/>
                                    </div>
                                </td>
                                <td class="amount">
                                    {{ pre_order_amount(store.pre_order) | currency }}
                                </td>
                                <td class="amount">
                                    <span :class="{'lower' : isLower(store) }">
                                        {{ total_by_store(store.id) | currency }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ProductWrapModal
                v-if="open_edit_wrap"
                :product="product"
                @close="update_wrap(product)"
            />
            <NegotiationModal
                @close="reset_negotitation"
                :typing="seller  || {}"
                :product="mapped_product || {}"
                v-if="open_negotiation_modal"
                :quotation_id="$route.params.id" />
        </div>
    </cc-modal> -->

    <div>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="subrequest-modal-header">
                análise das quantidades - forn: {{offer.seller.provider.name}}
                <span v-on:click="close" class="material-icons subrequest-modal-close-icon">cancel</span>
            </div>
            <cc-loader style="margin-top: 10vh; margin-bottom: 10vh" v-if="loading"/>
            <div class="subrequest-modal-body" v-else>
                <div class="subrequest-modal-body-title">
                    <div style="display:flex">
                        <div  style="max-width: 40%">Vendedor: {{ seller.name }}</div>
                        <span class="rate"><i class="fa fa-star" aria-hidden="true"></i> ({{ rate }})</span>
                        <a v-if="seller.phone" style="margin-left: 1vw; color: black" :href="'https://api.whatsapp.com/send?phone='+formatNumber(seller.phone)" target="_blank">{{ seller.phone || "" | VMask('(##) #####-####') }}</a>
                    </div>
                    <div class="subrequest-modal-body-title-buttons">
                        <StandardButton :action="close" :iconName="'arrow_back'" :text="'Voltar'"/>
                        <StandardButton class="subrequest-modal-body-title-last-button" :action="saveAllAndClose" :iconName="'email'" :text="'Enviar'"/>
                    </div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-product">
                        <div class="subrequest-modal-body-input-title">Produto</div>
                        <div class="subrequest-modal-body-input-value">{{product.name}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-product" v-if="offer.brand">
                        <div class="subrequest-modal-body-input-title">Marca</div>
                        <div class="subrequest-modal-body-input-value">{{offer.brand.name}}</div>
                    </div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-smal" v-if="product.packing">
                        <div class="subrequest-modal-body-input-title">Embalagem <i class="far fa-edit" style="color: var(--primary-color); cursor: pointer" @click="open_edit_wrap = true" v-if="can('PRODUCT', 'UPDATE') && product.flag_generico == 0 && !product.pro_principal && !product.possuiPedido"/></div>
                        <div class="subrequest-modal-body-input-value">{{!product.is_generic ? product.packing_quantity : offer.quantity }}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal">
                        <div class="subrequest-modal-body-input-title">Preço </div>
                        <div class="subrequest-modal-body-input-value">{{formatPrice(offer.price)}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="!product.brand">
                        <div class="subrequest-modal-body-input-title">Unidade Compra</div>
                        <div class="subrequest-modal-body-input-value">{{product.packing}}</div>
                    </div>
                </div>
                <div class="subrequest-modal-body-inputs">
                    <div class="subrequest-modal-body-input-smal" v-if="delivery && delivery.date">
                        <div class="subrequest-modal-body-input-title">Data Entrega</div>
                        <div class="subrequest-modal-body-input-value">{{(delivery ? delivery.date : '') | formatDateOnly}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="seller.delivery.minimum_billing">
                        <div class="subrequest-modal-body-input-title">Fat. Min</div>
                        <div class="subrequest-modal-body-input-value">{{formatPrice(seller.delivery.minimum_billing)}}</div>
                    </div>
                    <div class="subrequest-modal-body-input-smal" v-if="delivery && delivery.form">
                        <div class="subrequest-modal-body-input-title">Forma De Pgto</div>
                        <div class="subrequest-modal-body-input-value">{{delivery ? delivery.form : ''}}</div>
                    </div>
                </div>
                <div class="subrequest-modal-table-overflow">
                    <div class="subrequest-modal-table-width">
                        <div class="subrequest-modal-table">
                            <div class="subrequest-modal-table-id-width">ID</div>
                            <div class="subrequest-modal-table-company-width">Empresa</div>
                            <div class="subrequest-modal-table-req-width">Req.</div>
                            <div class="subrequest-modal-table-est-width">Est.</div>
                            <div class="subrequest-modal-table-order-width">Pedido</div>
                            <div class="subrequest-modal-table-total-item-width">Total Item</div>
                            <div class="subrequest-modal-table-total-order-width">Total Pedido</div>
                        </div>
                        <div class="subrequest-modal-table-row" v-for="(store, idx) in visibleStores()" v-bind:key="'store-' + store.id" :class="getSpecialBackground(idx)">
                            <div class="subrequest-modal-table-id-width">{{ store.pre_order.subrequest ? store.pre_order.subrequest.id : null }}</div>
                            <div class="subrequest-modal-table-company-width">{{ store.trading_name }}</div>
                            <div class="subrequest-modal-table-req-width">{{ store.pre_order.subrequest ? store.pre_order.subrequest.quantity : null }}</div>
                            <div class="subrequest-modal-table-est-width">{{ store.estoque }}</div>
                            <div class="subrequest-modal-table-order-width">
                                <!-- <input
                                class="subrequest-modal-table-order-input"
                                :class="{'subrequest-modal-table-order-input-disabled':store.has_order, 'subrequest-modal-table-order-input-altered': store.isAltered}"
                                type="number"
                                min="0"
                                :readonly="store.has_order"
                                v-on:keydown="saveOne($event, store)"
                                v-model.number="store.pre_order.quantity"
                                :id="'product-amount'+ idx"> -->
                                <input
                                class="subrequest-modal-table-order-input"
                                :class="{'subrequest-modal-table-order-input-disabled':store.has_order, 'subrequest-modal-table-order-input-altered': store.isAltered}"
                                type="number"
                                min="0"
                                :readonly="store.has_order"
                                v-on:keydown="saveOne($event, store)"
                                v-model="store.pre_order.quantity"
                                :id="'product-amount'+ idx"
                                v-on:keyup="process_event($event, idx)">
                            </div>
                            <div class="subrequest-modal-table-total-item-width">{{ pre_order_amount(store.pre_order) | currency }}</div>
                            <div class="subrequest-modal-table-total-order-width">
                                <span :class="{'lower' : isLower(store) }">
                                    {{ total_by_store(store.id) | currency }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ProductWrapModal
        v-if="open_edit_wrap"
        :product="product"
        @close="update_wrap(product)"
    />
    </div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import V3RequestsService from "@/services/v3/request.service"
import PreOrderService from "@/services/v3/requests/pre-order.service";
import StoreService from "@/services/v3/stores/store.service"
import ErrorHandlerService from "@/services/ErrorHandlerService";
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import { perm_mixin } from '@/mixins/permission.mixin'

import { mapState } from 'vuex'

export default {
    components: {
        ProductWrapModal,
        NegotiationModal,
        StandardButton
    },
    mixins: [ perm_mixin ],
    props: [
        "offer", "product", "request",
        "loj_id", "stores_only"
    ],
    data() {
        return {
            modal: {
                title: `análise das quantidades - forn: #${this.offer?.seller.provider.name}`,
                permitted: true,
                submit: this.saveAllAndClose,
                submit_text: 'Enviar',
                subtitle: 'Defina aqui as quantidades a serem compradas por unidade requisitante',
                icon_title: 'fas fa-calculator',
                style: {
                    width: "70%"
                }
            },
            seller: null,
            open_negotiation_modal: false,
            open_edit_wrap: false,
            stores: [],
            loading: true,
            service: new QuotationService(),
            svc: new V3RequestsService(),
            store_svc: new StoreService(),
            po_svc: new PreOrderService(),
            delivery : {},
            rate: 5,
            po_coll: null,
            pos: null
        };
    },
    methods: {
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        process_event(e, index) {
            if (e.key == "ArrowDown"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index + 1))
                if (!element) return
                if (element.className.includes('subrequest-modal-table-order-input-disabled')) this.process_event(e, index + 1)
                else {
                    element.focus()
                    element.select()
                }
            } else if (e.key == "ArrowUp"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index - 1))
                if (!element) return
                if (element.className.includes('subrequest-modal-table-order-input-disabled')) this.process_event(e, index - 1)
                else {
                    element.focus()
                    element.select()
                }
            }
		},
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        reset_negotitation(reload, price, obs) {
            this.seller.observacao = obs
            this.seller.valor = price
            this.open_negotiation_modal = false
            this.$emit('load_products')
            this.$forceUpdate()
        },
        update_wrap(product) {
            this.open_edit_wrap = false;
            this.$set(this.seller,'embalagem', product.embalagem)
            this.reloadProviders()
        },
        visibleStores() {
            return this.stores.filter(s => this.loj_id == undefined || s.id == this.loj_id)
        },
        isLower(store) {
            return (this.total_by_store(store) > 0 && this.total_by_store(store) < this.seller.minimum_billing);
        },
        saveOne(e, store) {
            this.$forceUpdate()
            let key = getKey(e)

            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                // etc
            }

            if(key == "Enter" || key == "ArrowDown" || key == "ArrowUp") {
                e.preventDefault()
                this.$set(store, 'is_loading', true);
                this.save(store, (data) => {
                    this.$set(store, 'is_loading', false);
                    this.$set(store, 'saved', true);
                    // this.reloadProviders();
                    store.isAltered = true
                });
            }
        },
        reload() {
            this.$emit("reload", {
                done: () => {
                    this.load().then(this.load_stores)
                }
            });
        },
        close() {
            this.$emit("close", this.seller);
        },
        save(store, callback) {
            let params = {
                offer_id: this.offer.id,
                quantity: store.pre_order.quantity || 0,
                is_winner: store.pre_order.quantity > 0 ? 1 : 0
            }
            if(store.pre_order.subrequest){
                params.subrequest_id = store.pre_order.subrequest.id
            } else {
                params.store_id = store.id
            }

            this.svc.toggle(params).then(response => response.data).then(callback);
        },
        saveAllAndClose() {
            let storesToSave = this.stores;
            let saved = 0;
            storesToSave.forEach(store => {
                store.quantidadeSugestao = store.quantidadeSugestao || "0"
                this.save(store, (data)  =>{
                    saved++;
                    if(saved == storesToSave.length) {
                        this.reload();
                        this.close();
                        this.load_offer()
                    }
                })
            })
        },
        load_reviews() {
            this.svc.get_evaluation(this.seller.usu_id)
            .then(response => response.data)
            .then((data)=>{
                if(data.todas_avaliacoes) {
                    let nota = 0
                    data.todas_avaliacoes.forEach((data)=>{
                        nota += parseInt(data.nota)/data.todas_avaliacoes.length
                    })
                    this.rate = nota
                }
            })
        },
        load_stores() {
            this.is_loading = this.stores.length === 0;
            this.store_svc.load().then(response => response.data)
            .then(data => {
                this.stores = data;
                this.is_loading = false;
                this.stores.forEach(s => {
                    s.pre_order = this.pre_order_store(s.id)
                    s.has_order = this.has_order_store(s.id)
                })
                this.loading = false
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        pre_order_store(store_id) {
            return this.pos
                .filter(po => po.offer.request_product_id == this.offer.request_product_id)
                .find(po => po.subrequest.store_id == store_id) || {}
        },
        has_order_store(store_id) {
            return this.pos
            .filter(po => po.offer.seller_id == this.offer.seller_id)
            .filter(po => po.subrequest.store_id == store_id)
            .some(po => po.status == 'ORDERED')
        },
        has_orders() {
            return this.stores.some(s => s.has_order)
        },
        pre_order_amount(pre_order) {
            return pre_order.quantity*this.offer.price
        },
        total_by_store(store_id) {
            let result = 0
            this.pos.forEach(po => {
                if(po.subrequest.store_id == store_id) {
                    result += po.quantity * po.offer.price*(!this.product.is_generic ? this.product.packing_quantity : this.offer.quantity)
                }
            })
            return result
        },
        load_offer() {
            this.request.requests_products.forEach(rp => {
                let offer = rp.offers.find(o => o.id == this.offer_id)
                if(offer) {
                    this.offer = offer
                    this.seller = this.offer.seller
                    this.modal = {
                        ...this.modal,
                        title: `análise das quantidades - forn: #${this.offer?.seller.provider.name}`
                    }
                }
            })
        },
        load() {
            return this.po_svc.load(this.request.id, { seller_id: this.offer?.seller.id })
            .then(response => response.data)
            .then(response => {
                this.pos = response
            })
        }
    },
    computed: {
        ...mapState([ 'user' ]),
        mapped_product: function() {
            return {
                produtoDescricao: this.product.descricaoProduto,
                ultimaCompra: this.product.last_purchase.valor,
                pro_observacao: this.seller.pro_observacao,
                observacao: this.seller.observacao
            }
        }
    },
    async mounted() {
        await this.load()
        this.load_stores();
        this.seller = this.offer.seller
        this.delivery = this.seller.delivery
    }
}
</script>

<style lang="scss" scoped>
    @import "./_pre-orders.modal";
    .modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container{
  background-color: white;
  width: 95vw;
  border-radius: 12px;
}
.subrequest-modal-header{
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px 10px 0px 0px;
    padding: 2vh 1vw;
    font-weight: 500;
    font-size: 2.2em;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    align-items: center;
}
.subrequest-modal-close-icon{
    font-size: 1.4em;
    cursor: pointer;
}
.subrequest-modal-body{
    padding: 5vh 5vw;
    max-height: 95vh;
    overflow-y: auto;
}
.subrequest-modal-body-title{
    display: flex;
    align-items: flex-start;
    font-size: 1.65em;
    margin-bottom: 3vh;
}
.rate{
    margin-left: 2vw;
}
.subrequest-modal-body-title-buttons{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 1vw;
}
.subrequest-modal-body-title-last-button{
    margin-left: 1vw;
}
.subrequest-modal-body-inputs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.subrequest-modal-body-input-product{width: 45%;}
.subrequest-modal-body-input-smal{width: 30%;}

.subrequest-modal-body-input-title{
    font-weight: 400;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #898989;
    display: flex;
    justify-content: space-between;
}
.subrequest-modal-body-input-value{
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 1vw;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.15px;
    color: #515151;
    background-color: #e9ecef;
}
.subrequest-modal-table{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0.5vw;
    font-size: 1.2em;
    font-weight: 600;
    color: #605F5F;
}
.subrequest-modal-table-id-width{width: 10%;}
.subrequest-modal-table-company-width{flex: 1;}
.subrequest-modal-table-req-width{width: 11%;}
.subrequest-modal-table-est-width{width: 11%;}
.subrequest-modal-table-order-width{width: 11%;}
.subrequest-modal-table-total-item-width{width: 11%;}
.subrequest-modal-table-total-order-width{width: 11%;}
.subrequest-modal-table-row{
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    padding: 1vh 0.5vw;
    align-items: center;
}
.subrequest-modal-table-order-input{
    width: 80%;
    background: rgba(23, 162, 184, 0.05);
    border-radius: 8px;
    border: none;
    font-weight: 400;
    font-size: 1.1em;
    color: var(--primary-color);
    padding-left: 1vw;
    outline: 1px solid var(--primary-color);
}
.subrequest-modal-table-order-input:focus{
    background-color: #f7dea8;
}
.subrequest-modal-table-order-input-disabled{ cursor: not-allowed; }
.subrequest-modal-table-order-input-altered{
    background-color: lightgreen !important;
}
.subrequest-modal-table-order-input-disabled:focus{ outline: none; }
.page-table-line-special{ background: #F7F7F7; }
.subrequest-modal-table-width{min-width: 900px;}
.subrequest-modal-table-overflow{overflow-x: auto;}


@media only screen and (max-width: 1100px){
    .modal-mask{font-size: 1.5vw;}
}
@media only screen and (max-width: 850px){
    .subrequest-modal-body-title{flex-direction: column;}
    .modal-mask{font-size: 1.7vw;}
}
@media only screen and (max-width: 600px){
    .modal-mask{font-size: 2vw;}
}
@media only screen and (max-width: 450px){
    .modal-mask{font-size: 3vw;}
    .subrequest-modal-body-inputs{flex-direction: column; margin-bottom: 0;}
    .subrequest-modal-body-input-product{width: 100%; margin-bottom: 1vh;}
    .subrequest-modal-body-input-smal{width: 100%; margin-bottom: 1vh;}
    .subrequest-modal-table{margin-top: 3vh;}
    .modal-content-container{width: 110vw;}
}
</style>
