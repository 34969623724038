<style lang="scss" scoped>
	@import "./cart.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
        <div class="row container-fluid" slot="header">
            <div class="col d-flex justify-content-end actions">
                <cc-button
                    :classes="'fixed danger ml-2 fill'"
                    :content="'Voltar'"
                    :nolink="true"
                    @click="close"  />
                <cc-button
                    :classes="'fixed ml-2'"
                    :nolink="true"
                    :content="'Imprimir'"
                    v-if="VIEW == 'CONFIRMATION'"
                    :icon="'fa fa-print'"
                    @click="print"/>
                <cc-button
                    v-if="VIEW == 'PRODUCTS'"
                    @click="confirm_order"
                    :nolink="true"
                    :disabled="!store || total <= 0"
                    :classes="'success ml-2 fixed fill'"
                    :content="'Continuar'"  />
                <cc-button
                    v-if="VIEW == 'CONFIRMATION'"
                    :classes="'success ml-2 fixed fill'"
                    :content="'Finalizar Pedido'"
                    :disabled="!cart.store || !valid_amounts"
                    @click="send_order"
                    :icon="'fa fa-shopping-cart'"  />
            </div>
        </div>
		<div slot="body" class="container-fluid pt-0 h-100">
            <div class="row d-flex justify-content-center h-100 align-items-center" v-if="VIEW == 'STORE_SELECT'">
                <div class="col col-5">
                    <div class="row">
                        <div class="col">
                            <p class="text-center">Informe uma loja para continuar</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <cc-select-v2
                                :tracker="'id'"
                                :text="'name'"
                                :placeholder="'-'"
                                :options="stores"
                                v-model="store" />
                        </div>
                        <div class="col">
                            <cc-button
                                :classes="'success fixed'"
                                :content="'Continuar'"
                                :disabled="!store"
                                @click="select_store(store)"
                                :icon="'fa fa-shopping-cart'"  />
                        </div>
                    </div>
                </div>
            </div>
            <cc-cart-resume v-if="VIEW == 'CONFIRMATION'" />
            <div class="row" v-if="campaign && VIEW == 'PRODUCTS'">
                <div class="col col-lg-3 col-sm-4 col-md-3 pb-2" v-for="item in campaign.items" :key="item.id">
                    <cc-campaign-item
                        :item="item"
                        @open_campaign="open_campaign"
                        :cart_controls="true"
                        :loading="loading"/>
                </div>
            </div>
		</div>
	</cc-modal>
</template>

<script>

import CampaignService from "@/services/v3/campaigns/campaign.service"
import OrderService from "@/services/v3/campaigns/order.service";
import StoreService from "@/services/v3/stores/store.service"
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import CartResume from "./_cart-resume.partial.vue";
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import CampaingItem from '@/pages/campaigns/components/_campaign-product.component'

export default {
    mixins: [ perm_mixin, loaderMixin ],
    props: [ "id" ],
	components: {
        ccPagination : Pagination,
        'cc-cart-resume' : CartResume,
        'cc-campaign-item' : CampaingItem
   },
  	data() {
		return {
            VIEW: 'CONFIRMATION',
            stores: [],
			page: 1,
            store: null,
			total_items : 0,
			items_by_page_value: 10,
			loading: true,
            store_svc: new StoreService(),
			svc: new CampaignService(),
            order_service: new OrderService(),
            campaign: {
                provider: {},
                items: [
                    {},
                    {},
                    {},
                    {}
                ]
            },
			modal: {
				title: "...",
				icon_title: 'fas fa-cart-plus',
				cancel_text: 'Voltar',
                nobackbutton: true,
				style: {
					width: "85%",
                    height: "95%"
				}
			},
		};
	},
    computed: {
        ...mapState([ 'cart' ]),
        ...mapGetters('cart', [ 'cart_item_for', 'total', 'cart_request_for', 'valid_amounts' ])
    },
  	methods: {
        ...mapActions('cart', [
            'set_campaign', 'add_item',
            'remove_item', 'update_quantity',
            'set_store', 'reset'
        ]),
        print() {
            self.print()
        },
        send_order() {
            this.cart.providers.forEach(p => {
                this.order_service.send_order(this.cart_request_for(p)).then(response => response.data).then(data => {
                    this.present_info('Pedido enviado com sucesso!')
                    this.reset()
                    this.store = null
                    this.snack('Pedido enviado com sucesso - ' + p.name, 'success')
                }).catch(err => {
                    ErrorHandlerService.handle(err, this.$store);
                    let msg = "Ops! Ocorreu um erro ao enviar o pedido!"
                    this.snack(p.name + '-' + msg, 'success')
                })
            })
        },
        select_store() {
            if(this.store) {
                this.set_store(this.store)
                this.VIEW = 'PRODUCTS'
            }
        },
        close() {
            if(this.VIEW == 'CONFIRMATION') {
                this.$emit("close");
            } else if(this.VIEW == 'PRODUCTS') {
                this.VIEW = 'CONFIRMATION'
            } else {
                this.$emit("close");
            }
		},
        confirm_order() {
            this.VIEW = 'CONFIRMATION'
        },
        load() {
            this.loading = true
            this.svc.load(this.id).then(response => response.data).then(data => {
                this.campaign = data
                this.campaign.items.forEach(i => i.campaign = this.campaign)
                this.loading = false
                this.modal = { ...this.modal, title: data.name, permitted: this.total > 0 }
                this.set_campaign(this.campaign)
            }).catch(() => {
                ErrorHandlerService.handle(this.$store)
            })
        },
        load_stores() {
            this.store_svc.load().then(response => response.data).then(data => {
                this.stores = data
            })
        }
	},
	mounted() {
		// this.load()
        this.load_stores()
	}
};
</script>
