export const TOKEN_EXPIRADO = "Token Expirado";

function verifyUrlToRedirect() {

    let loginUrl = localStorage.getItem('loginURL')

    if (loginUrl && loginUrl != '') {

        return `https://${loginUrl}`
    } else {
        return process.env.VUE_APP_RETURN_ADDRESS
    }

}

export const REQUEST_CODE_SUCCESS = 200;
export const REQUEST_CODE_UNAUTHORIZED = 401;
export const REQUEST_CODE_TOO_MANY_REQUESTS = 429;

export const API_URL = process.env.VUE_APP_API_URL;
export const API_V3_URL = process.env.VUE_APP_API_V3_URL;
export const PROJECT_ENV = process.env.VUE_APP_ENV;
// export const ROUTE_LOGIN                    = process.env.VUE_APP_RETURN_ADDRESS;
export const ROUTE_LOGIN = verifyUrlToRedirect();
export const ROUTE_HOME = "/cliente";
export const ROUTE_NEW_QUOTATION_REQUEST = "/cliente/cotacao/nova";
export const ROUTE_REPORTS = ROUTE_HOME + "/relatorios";
export const ROUTE_MONITORS = ROUTE_HOME + "/monitores/cotacoes";
export const ROUTE_SETTINGS = "/configuracoes";
export const ROUTE_MONITORS_WINNERS = ROUTE_MONITORS + "/vencedores";
export const ROUTE_LOGOUT = ROUTE_HOME + "/logout";
// import { store } from '@/store/store'

// function verifyUrlToRedirect() {
//     let loginURl = store.state.whitelabel.data.providers.loginURl;

//     if (loginURl) {
//         return loginURl
//     } else {
//         return process.env.VUE_APP_RETURN_ADDRESS
//     }

// }
