<style lang="scss" scoped>
	@import "./management.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
		<div slot="header-actions">
            <div class="row">
                <div class="col actions">

                    <cc-button :content="'Fechar'"
                        @click="close();current_seller = null;VIEW = 'USER_LIST'"
                        :classes="'fill danger-outline-no-hover fixed'"
                        v-if="VIEW == 'USER_LIST'" />

                    <cc-button :content="'Voltar'"
                        :icon="'fas fa-chevron-left'"
                        @click="current_seller = null;VIEW = 'USER_LIST'"
                        :classes="'fill default fixed'"
                        v-if="VIEW != 'USER_LIST'" />
                    <cc-button
                        v-if="VIEW == 'USER_FORM' || VIEW == 'USER_EXTERNAL_FORM'"
                        :nolink="true"
                        :content="'Salvar'"
                        @click="save"
                        :classes="'thin ml-2 theme-3 fixed'" />
                    <cc-button
                        v-if="VIEW != 'USER_FORM' && VIEW != 'USER_EXTERNAL_FORM'"
                        :nolink="true"
                        :content="'Adicionar'"
                        @click="add"
                        :classes="'thin ml-2 theme-3 fixed'" />
                </div>
            </div>
        </div>
        <div slot="body" class="container-fluid pt-0 sellers" id="sellers-content">
            <div class="row">
                <UserForm
                    ref="seller-form"
                    @close="current_user = null;VIEW = 'USER_LIST'"
                    v-show="current_user && VIEW == 'USER_FORM'"
                    :profiles="profiles"
                    :secondary_profiles="secondary_profiles"
                    :stores="stores"
                    @reload="() => !buyer_id ? load() : $emit('reload') "
                    :user="current_user" />

                <UserExternalForm
                    ref="seller-form"
                    @close="current_user = null;VIEW = 'USER_LIST'"
                    v-show="current_user && VIEW == 'USER_EXTERNAL_FORM'"
                    :profiles="profiles"
                    :secondary_profiles="secondary_profiles"
                    :stores="stores"
                    @reload="() => !buyer_id ? load() : $emit('reload') "
                    :user="current_user" />


                <div class="col" id="sellers" v-show="VIEW == 'USER_LIST'">
                    <div class="list-group">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left px-3">ID</th>
                                    <th class="text-left px-3">Usuário</th>
                                    <th class="text-left px-3">Login</th>
                                    <th class="text-left px-3">Empresa</th>
                                    <th class="text-left px-3">Perfil</th>
                                    <th ></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.usu_id" class="pointer" >
                                    <td class="text-left px-3"><b>{{ user.usu_id }}</b></td>
                                    <td class="text-left px-3">{{ user.usu_nome }}</td>
                                    <td class="text-left px-3 text-uppercase" >{{ user.usu_login }}</td>
                                    <td class="text-left px-3"><b>{{ user.store ? user.store.loj_descricao : '-' }}</b></td>
                                    <td class="text-left px-3"><b>{{ user.profile ? user.profile.pfl_descricao : '-' }}</b></td>
                                    <td>
                                        <div class="actions">
                                            <i class="fas fa-edit" aria-hidden="true" title="Editar" @click="edit(user)"></i>
                                            <i class="fas fa-trash" aria-hidden="true" title="Remover" @click="remove(user)"></i>
                                            <i class="fas fa-external-link-square-alt" aria-hidden="true" title="Editar Usuário Externo" @click="edit_external(user)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
	</cc-modal>
</template>

<script>
import UserForm from "./_user.form.vue";
import UserExternalForm from "./_user_external.form.vue";
import StoreService from "@/services/v1/store.service";
import UserService from "@/services/v1/user.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	mixins: [ loaderMixin ],
	props: {
		client: {
			type: Object,
			required: true
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
        UserForm,
        UserExternalForm,
		ccPagination : Pagination
   	},
  	data() {
		return {
			users: [],
			current_user: {
                profile: {
                    pfl_descricao: ''
                }
            },
			loading: false,
			message: null,
			profiles: [],
			stores: [],
			svc: new UserService(),
			store_svc: new StoreService(),
            VIEW: "USER_LIST",
            secondary_profiles: [
                { value: 0, key: 'Repositor' },
                { value: 1, key: 'Gerente' },
                { value: 2, key: 'Conferente' },
            ],
			modal: {
				title: `meus usuários`,
				subtitle: `Gerencie seus usuários`,
				icon_title: 'fas fa-users',
				cancel_text: 'Voltar',
                nobackbutton: true,
				style: {
					width: "90%",
					height: "fit-content"
				}
			}
		};
	},
    watch: {
        'current_user.profile' : {
            handler: function() {
                this.$forceUpdate()
            },
            deep: true
        }
    },
  	methods: {
        add() {
            this.VIEW = 'USER_FORM'
            this.current_user = { client: this.client }
            this.update_modal({ title: `Novo Usuário` })
        },
		edit(user){
            this.VIEW = 'USER_FORM'
            this.current_user = { ...user, secondary_profiles: this.secondary_profiles }
            this.update_modal({ title: `${this.current_user.usu_nome}` })
        },

        edit_external(user) {
            this.VIEW = 'USER_EXTERNAL_FORM'
            this.current_user = { ...user}
            this.update_modal({ title: `${this.current_user.usu_nome}` })
        },
		remove(user){
			this.confirm_action({
				message: "Confirma remoção do usuário?",
				subtitle: `O usuário ${user.usu_nome} será removido!`,
				callback: () => {
					this.svc.remove(this.client.cli_id, user).then(() => this.load_users())
				}
			})
			return false
		},
		save() {
			this.present_loader("Salvando usuário...")
			this.message = null
			let callback = data => {
				this.dismiss_loader()
                const notification = {
                    type: 'success',
                    message: "Dados salvos com sucesso!"
                }
                this.$store.dispatch('notification/add', notification)
			}
			let callback_error = err => {
				this.dismiss_loader()
                let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
				if(err.response && err.response.data && err.response.data.error) {
					message = err.response.data.error
				}
                const notification = {
                    type: 'error',
                    message: message
                }
                this.$store.dispatch('notification/add', notification)
			}
			if(this.current_user.usu_id) {
				this.svc.update(this.client.cli_id, this.current_user.usu_id, this.current_user)
                .then(() => this.load_users())
                .then(callback, callback_error)

			} else {
				this.svc.create(this.client.cli_id, this.current_user)
				.then(() => this.load_users())
				.then(callback, callback_error)
			}
		},
		close() {
            this.$emit("close");
		},
		load_users() {
			this.present_loader("Carregando usuários...")
			return this.svc.all(this.client.cli_id).then(response => response.data).then(data => {
				this.load_profiles().then(() => {
					this.users = data.usuarios;
					this.users.forEach(u => u.password_confirmation = u.usu_senha)
					this.process_profiles()
				}).then(() => {
					this.load_stores().then(() => {
						this.process_stores()
					})
					this.dismiss_loader()
				})
			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_profiles() {
			return this.svc.load_profiles(this.client.cli_id).then(response => response.data).then(data => {
				this.profiles = data.perfil
			})
		},
		load_stores() {
			return this.store_svc.all(this.client.cli_id).then(response => response.data).then(data => {
				this.stores = data.lojas
			})
		},
		process_profiles() {
			this.users.forEach(u => u.profile = this.profiles.find(p => p.pfl_id == u.pfl_id))
            this.users.forEach(u => u.secondary_profile = this.secondary_profiles.find(p => u.usu_acesso_comp_loja == p.value))
		},
		process_stores() {
			this.users.forEach(u => u.store = this.stores.find(p => p.loj_id == u.usu_idLoja))
			this.$forceUpdate()
		},
        update_modal(new_settings) {
            this.modal = { ...this.modal, ...new_settings }
        },
	},
	mounted() {
		this.load_users()
	}
};
</script>
