<template>
  <div>
    <img src="@/assets/NewAdminBackground.jpg" class="login-img-background" />
    <!-- <div v-if="this.whitelabel.data.imgs.img_background != null" class="background-image">
      <img :src="this.whitelabel.data.imgs.img_background"/>
    </div> -->

    <div class="login-info-wrapper">
      <div class="card-login">
        <div class="login-header">
          <!-- <img src="/images/logo-3.jpeg" alt="Logo Clube da Cotação" /> -->
          <img
            :class="
              this.whitelabel.data.imgs.img_logo !== null ? 'logoStyle' : ''
            "
            :src="
              this.whitelabel.data.imgs.img_logo != null
                ? this.whitelabel.data.imgs.img_logo
                : '/images/logo-3.jpeg'
            "
            :alt="
              this.whitelabel.data.imgs.img_logo != null
                ? 'Logo'
                : 'Logo Clube da Cotação'
            "
          />
          <h3>Entre com sua conta</h3>
          <h4 style="color: gray">Apresentação</h4>
          <!-- <p @click="handleParagraphClick">testee</p> -->
        </div>
        <form id="login" class="login-content" @submit.prevent="efetuarLogin">
          <div class="login-content">
            <div class="form-group has-warning" style="height: 2rem">
              <input
                class="form-control input-login text-center"
                placeholder="Nome de usuario*"
                type="text"
                v-model="login"
              />
            </div>
            <div class="form-group has-warning">
              <input
                class="form-control input-login text-center"
                placeholder="Sua Senha*"
                type="password"
                v-model="senha"
              />
            </div>
          </div>
          <div class="login-bottom">
            <button
              type="submit"
              class="btn btn-warning btn-block btn-flat btn-login"
              :disabled="loading"
            >
              <span v-if="!loading">Entrar</span>
              <i class="fa fa-fw fa-circle-o-notch fa-spin" v-if="loading"></i>
            </button>
            <span class="send-password" @click="open_send_pass_form">
              Esqueci minha senha
              <i class="fas fa-arrow-right"></i>
            </span>
          </div>
        </form>
        <form
          id="send-password"
          class="login-content"
          @submit.prevent="send_password"
        >
          <div class="login-content">
            <div class="form-group has-warning" style="height: 2rem">
              <input
                class="form-control input-login text-center"
                placeholder="email ou login*"
                type="text"
                v-model="login"
              />
            </div>
          </div>
          <div class="login-bottom">
            <span class="send-password" @click="open_login_form">
              voltar
              <i class="fas fa-arrow-left"></i>
            </span>
            <button
              type="submit"
              class="btn btn-warning btn-block btn-flat btn-login"
              :disabled="loading"
            >
              <span v-if="!loading">Enviar</span>
              <i class="fa fa-fw fa-circle-o-notch fa-spin" v-if="loading"></i>
            </button>
          </div>

          <div class="login-bottom">
            <span class="feedback success" v-show="success_msg">
              <i class="far fa-check-circle pr-2"></i>{{ success_msg }}
            </span>
            <span class="feedback fail" v-show="error_msg">
              <i class="fas fa-exclamation-triangle pr-2"></i>{{ error_msg }}
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import StorageService from "@/services/TokenService";
import VarejoFacilService from "@/services/VarejoFacilService";
import CredentialService from "@/services/v2/credentials.service";
import { gsap } from "gsap";
import { loaderMixin } from "@/mixins/sweet-loader.mixin";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import ClientServiceV3 from "@/services/v3/ClientService.js";
import { mapState } from "vuex";
export default {
  mixins: [loaderMixin],
  data() {
    return {
      login: "",
      senha: "",
      loading: false,
      error_msg: "",
      success_msg: null,
      storage_svc: new StorageService(),
      vf_svc: new VarejoFacilService(),
      cred_svc: new CredentialService(),
      clientServiceV3: new ClientServiceV3(),
    };
  },
  methods: {
    handleParagraphClick() {
      console.log(this.whitelabel.data);
    },
    open_send_pass_form() {
      let selector = "#send-password";
      let login_selector = "#login";
      gsap.fromTo(
        login,
        { opacity: 0, x: "100%" },
        { display: "none", x: "-50%", duration: 0, ease: "back.out(1.7)" }
      );
      gsap.fromTo(
        selector,
        { opacity: 0, x: "50%" },
        {
          display: "block",
          opacity: 1,
          x: "0%",
          duration: 1,
          ease: "back.out(1.7)",
        }
      );
    },
    open_login_form() {
      let selector = "#send-password";
      let login_selector = "#login";
      gsap.fromTo(
        selector,
        { opacity: 0, x: "100%" },
        { display: "none", x: "-50%", duration: 0, ease: "back.out(1.7)" }
      );
      gsap.fromTo(
        login_selector,
        { opacity: 0, x: "-20%" },
        {
          display: "block",
          opacity: 1,
          x: "0%",
          duration: 1,
          ease: "back.out(1.1)",
        }
      );
    },
    send_password() {
      this.loading = true;
      this.success_msg = null;
      this.error_msg = null;
      this.cred_svc
        .send_password(this.login)
        .then(() => {
          this.loading = false;
          this.success_msg =
            "Senha enviada com sucesso! Verifique também na sua caixa de spam";
        })
        .catch((e) => {
          this.error_msg =
            "Não conseguimos processar sua requisição, verifique novamente sua credencial informada!";
          this.loading = false;
          ErrorHandlerService.handle(e, this.$store);
        });
    },
    check_home_page_url(settings) {
      if (settings) {
        let home = settings.find((s) => s.key == "DEFAULT_HOME_PAGE");
        if (home && home.value) {
          return home.value;
        }
      }
      return false;
    },
    sendHome(data) {
        let isMobile = window.innerWidth < 691;
      let home = this.check_home_page_url(data.usuario.settings);
      if (home) {
        if (home == "/ofertas") this.$router.push(home + "?login=true");
        else if(isMobile){
            this.$router.push("/inicio");
        }else this.$router.push(home);
      } else {
        if (isMobile) {
          this.$router.push("/inicio");
        } else {
          this.$router.push("/cliente/monitores/cotacoes");
        }
      }
      this.$forceUpdate();
    },
    efetuarLogin() {
      if (/\S/.test(this.login) && /\S/.test(this.senha)) {
        this.success_msg = null;
        this.error_msg = null;
        this.loading = true;
        axios
          .post(`${CONSTANTS.API_URL}/auth`, {
            usu_login: this.login,
            senha: this.senha,
          })
          .then(({ data }) => {
            this.loading = false;
            this.storage_svc.clear();
            this.storage_svc.put("token", data.token);
            this.storage_svc.put(
              "cli_tipoPedido",
              data.usuario.cliente.cli_tipoPedido
            );
            this.storage_svc.put("grupo", data.usuario.cliente.cli_nome_grupo);
            this.storage_svc.put("cli_id", data.usuario.cli_id);
            this.storage_svc.put("tpc_id", data.usuario.cliente.tpc_id);
            this.storage_svc.put("user_name", data.usuario.usu_nome);
            this.storage_svc.put("AlertCount", 0);
            this.storage_svc.put(
              "request_type",
              data.usuario.cliente.cli_tipoPedido
            );
            this.storage_svc.put(
              "user_settings",
              JSON.stringify(data.usuario.settings)
            );
            this.storage_svc.put(
              "attendance",
              JSON.stringify(data.usuario.attendance)
            );
            this.storage_svc.put(
              "store_id",
              JSON.stringify(data.usuario.usu_idLoja)
            );
            this.storage_svc.put("usu_id", JSON.stringify(data.usuario.usu_id));
            this.storage_svc.put(
              "closing",
              data.usuario.cliente.cli_metodo_fechamento
            );
            this.storage_svc.put("showOffers", !data.usuario.usu_idLoja);
            this.storage_svc.put(
              "cli_utilizaPrecoBase",
              data.usuario.cliente.cli_utilizaPrecoBase
            );
            if (data.usuario.usuarioPlano.length > 0) {
              this.storage_svc.put("plan", data.usuario.usuarioPlano[0].status);
            } else {
              this.storage_svc.put("plan", "0");
            }
            localStorage.setItem("cli_vindi", data.usuario.cliente.cli_vindi);
            this.vf_svc.setup_alert(data.usuario.settings);

            if (data.usuario.varejo_facil_pass)
              this.storage_svc.put(
                "has_vf_integration",
                data.usuario.varejo_facil_pass
              );
            this.$store.dispatch("user/load_user");
            // this.$store.dispatch('user/load_perms')
            if (data.usuario.cliente.cli_vindi == 1) {
              this.clientServiceV3.getContractsVindi().then((response) => {
                this.storage_svc.put(
                  "vindiAccount",
                  JSON.stringify(response.data.custumers.customers[0])
                );
                this.storage_svc.put(
                  "contracts",
                  JSON.stringify(response.data.plan.subscriptions)
                );
                this.storage_svc.put(
                  "contractsBills",
                  JSON.stringify(response.data.bills.bills)
                );
              });
              this.sendHome(data);
            } else {
              this.clientServiceV3.getContracts().then((response) => {
                this.storage_svc.put(
                  "contracts",
                  JSON.stringify(response.data)
                );
              });
              this.sendHome(data);
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              this.error_msg = error.response.data.mensagem;
              this.snack(this.error_msg, "error");
            } else {
              this.snack("Usuário ou senha inválido", "error");
            }
            throw error;
          });
      }
    },
  },
  computed: {
    ...mapState(["whitelabel"]),
  },
};
</script>
<style lang="scss">
@import "./sigin.page";
</style>
