import http from "../http";
import Service from "../../Service";

/**
 * @type CampaignService
 */
export default class CampaignService extends Service {

	load_campaigns(params) {
		return http.get(`clients/campaigns`, {
            headers: this.getHeaders(),
            params
        });
	}

    load(id) {
		return http.get(`clients/campaigns/${id}`, {
            headers: this.getHeaders()
        });
	}

    load_products() {
		return http.get(`clients/campaigns/products`, {
            headers: this.getHeaders()
        });
	}
    load_public_products() {
        return http.get(`external/campaigns/products`, {});
    }

}
