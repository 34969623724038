<template>

    <div class="monitors">
        <div class="body">
            <a id="download-pdf" style="display:none;"></a>
            <div class="container-fluid mb-5">
                <div class="row pb-4 py-3 d-flex justify-content-between">
                    <div class="not-print col-sm-12 col-md-3 col-lg-3">
                        <label for="" class="label-control">Nome do produto</label>
                        <cc-search v-model="buscaProduto" @keyup="getListaProdutos(page)" />
                    </div>
                    <div class="not-print col-md-3 col-lg-3">
                        <label for="" class="label-control">Selecione uma categoria</label>
                        <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="arrayCategoria" v-model="categoria"></cc-select-v2>
                    </div>
                    <div class="not-print col-2 filter-controls">
                        <cc-checkbox class="mt-1" v-model="purchased_only" :checked="purchased_only" :label="`Apenas comprados`" />
                    </div>
                    <div class="not-print filter-controls col-4 justify-content-end">
                        <cc-button :classes="{ 'hidden' : loading ||  hidden_button_pdf}" :content="'PDF'"  :title="'Exportar PDF'" :icon="'fa fa-file-pdf'" @click="generate_pdf()"></cc-button>
                        <cc-button :classes="''" :icon="'far fa-trash-alt'" @click="resetaFormulario()" :content="'Limpar Filtros'"  />
                        <cc-button :classes="'secondary'" :icon="'fas fa-tags'" @click="manage_categories = true" :content="'Categorias'"  />
                        <cc-dropdown-button v-if="options.length > 1" :content="'Adicionar Produto'" :icon="'fa fa-plus'" :options="options"/>
                        <cc-button v-if="options.length == 1" :content="'Adicionar Produto'" :icon="'fa fa-plus'" :classes="'success'" @click="show_add_product_modal = true" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 p-4 shadow">
                        <cc-loader v-show="loading" />
                        <no-data-available v-if="products.length == 0 && !loading" :text="'Sua lista de produtos está vazia'" />
                        <div class="row">
                            <div class="col col-4" v-for="prod in products" :key="prod.product_id">
                                <Card :data="prod" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OperationService from '@/services/v3/commons/operation.service';
import Card from './card/index.page.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { mapGetters } from 'vuex'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: {
        Card
    },
    data(){
        return {
            loading: false,
            page: 1,
            totalPaginas: 1,
            buscaProduto: '',
            categoria: '',
            products: [],
            arrayCategoria: [],
            items_by_page_value: 30,
            total_items: 1000,
            brands: null,
            current_product: {},
            buscaMarca: '',
            purchased_only: false,
            manage_categories: false,
            svc: new OperationService(),
            new_product: null,
            show_add_product_modal: false,
            hidden_button_pdf: true,
            options: [
                {
                    label: "Novo Produto",
                    action: () => { this.show_add_product_modal = true }
                }
            ],
            breadcrumbs: [
                {
                    name: "Meus Produtos",
                    route: 'products'
                }
            ]
        }
    },
    watch: {
        purchased_only: function(checked) {
            this.getListaProdutos();
        },
        categoria: function(){
            this.getListaProdutos();
        }
    },
    methods:{
        load_products() {
            this.svc.load_products()
            .then(response => response.data)
            .then(response => {
                this.products = response
            })
        }
    },
    mounted() {
        this.load_products()
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    },
    computed:{
        filtrarMarcas(){
            return this.brands.filter(marca =>{
                return marca.pro_observacao.toLowerCase().includes(this.buscaMarca.toLowerCase())
            })
        },
        ...mapGetters({ setting: 'user/setting' })
    }
}
</script>

<style lang="scss" scoped>
    @import './index.page';
</style>
