<template>
    <div class="row page-header">
        <div class="col">
            <h4 class="header-title">
                <i :class="icon" aria-hidden="true"></i>
                {{ title }}
            </h4>
            <h5 class="header-subtitle" v-if="subtitle">{{ subtitle }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    .page-header {
        padding: 0 1rem 1rem 1rem;
    }
    .header-title {
        color: $blue1;
        text-transform: uppercase;
        font-weight: bold;
        i {
            font-size: 1.6rem;
        }
    }
    .header-subtitle {
        text-transform: uppercase;
        box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
        padding-left: 1rem;
        font-weight: lighter;
        color: #314b5f;
        font-family: Helvetica,sans-serif !important;
    }
</style>
