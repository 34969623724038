import http from "../http";
import Service from "../../Service";

/**
 * @type OrderService
 */
export default class OrderService extends Service {

	send_order(order) {
		return http.post(`clients/campaigns/orders`, order, {
            headers: this.getHeaders()
        });
	}

}
