<template>
    <div class="bar-chart-container">
        <div class="bar-chart-y-container">
            <p id="testtest" class="bar-char-y-text">R$ {{ valueMax.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ {{ value83.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ {{ value66.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ {{ value50.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ {{ value33.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ {{ value16.toFixed(2) }}</p>
            <p class="bar-char-y-text">R$ 0.000</p>
        </div>
        <div class="bar-chart-colums-container">
            <div v-for="(colum, idx) in columnsList" :key="idx" class="bar-chart-colum-comparasion">


                <div class="bar-chart-colums-display">
                    <div class="bar-chart-minimum" :style="getPercent(colum.mimPercent)"></div>
                    <div class="bar-chart-medium" :style="getPercent(colum.mediumPercent)"></div>
                    <div class="bar-chart-max" :style="getPercent(colum.maxPercent)"></div>
                </div>
                <p>
                   {{colum.mes}}
                </p>

                <div class="bar-chart-hover-heigth"></div>
                <div class="bar-chart-hover-heigth2"></div>
                <div class="bar-chart-info-hover" :id="'info-hover-'+idx">
                    <div class="bar-chart-info-line">
                        <p class="bar-chart-info-hover-text">Media Preço Máximo :</p>
                        <p class="bar-chart-info-hover-text bar-chart-info-max">R${{ colum.max_valor.toFixed(2) }}</p>
                    </div>
                    <div class="bar-chart-info-line">
                        <p class="bar-chart-info-hover-text">Preço Médio :</p>
                        <p class="bar-chart-info-hover-text bar-chart-info-medium">R${{ colum.media.toFixed(2)}}</p>
                    </div>
                    <div class="bar-chart-info-line">
                        <p class="bar-chart-info-hover-text">Media Preço Mínimo:</p>
                        <p class="bar-chart-info-hover-text bar-chart-info-minimum"> R${{ colum.min_valor.toFixed(2) }}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'BarChartComponent',
    props: ['info'],
    data () {
        return {
            valueMax: 0,
            value83: 0,
            value66: 0,
            value50: 0,
            value33: 0,
            value16: 0,
            columnsList:[]
        }
    },
    methods:{
        getPercent(value){
            if (value) return 'height: ' + value + '%;'
            return 'height: 0%;'
        },
        getMonthText(value) {
            const year = value.split(' ')[1]
            let month = value.split(' ')[0]
            if (month == 'January') month = 'Janeiro'
            else if (month == 'February') month = 'Fevereiro'
            else if (month == 'March') month = 'Março'
            else if (month == 'April') month = 'Abril'
            else if (month == 'May') month = 'Maio'
            else if (month == 'June') month = 'Junho'
            else if (month == 'July') month = 'Julho'
            else if (month == 'August') month = 'Agosto'
            else if (month == 'September') month = 'Setembro'
            else if (month == 'October') month = 'Outubro'
            else if (month == 'November') month = 'Novembro'
            else if (month == 'December') month = 'Dezembro'
            return month + ' - ' + year
        }
    },
    created () {
        let localList = this.info.slice(-6)
        localList.forEach(element => {
            if (element.max_valor > this.valueMax) this.valueMax = element.max_valor
            element.mes = this.getMonthText(element.mes)
        });
        this.value16 = this.valueMax * 0.16
        this.value33 = this.valueMax * 0.33
        this.value50 = this.valueMax * 0.5
        this.value66 = this.valueMax * 0.66
        this.value83 = this.valueMax * 0.83
        this.columnsList = localList
        setTimeout(() => {
           localList.forEach(element => {
                element.maxPercent = element.max_valor / this.valueMax * 100
                element.mediumPercent = element.media / this.valueMax * 100
                element.mimPercent = element.min_valor / this.valueMax * 100
            });
            this.columnsList = localList
            this.$forceUpdate()
        }, 100);


    },
    mounted() {
        let list = document.getElementsByClassName('bar-chart-colums-display')
        list.forEach((element, idx) => {
            var rect = element.getBoundingClientRect()
            if (rect.right > 1100) {
                let hover = document.getElementById('info-hover-'+idx)
                hover.style.marginRight = '0'
                hover.style.marginLeft = '-300%'
            }
        });
    }
}
</script>

<style scoped>
.bar-chart-container{
    display: flex;
    height: 50vh;
}
.bar-chart-y-container{
    width: 10%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bar-char-y-text{
    font-weight: bold;
    font-size: 1.3em;
    color: rgb(100, 100, 100);
    margin: 0;
    margin-left: 10%;
}
.bar-chart-colums-container{
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.bar-chart-colum-comparasion{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.3em;
    text-transform: uppercase;
    color: rgb(100, 100, 100);
    width: 12%;
}
.bar-chart-colums-display:hover ~ .bar-chart-info-hover{
    display: block;
}

.bar-chart-colums-display{
    height: 85%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}
.bar-chart-minimum{
    background-color: #41a859;
    width: 45%;
    transition: height 1s;
    position: absolute;
    z-index: 3;
}
.bar-chart-medium{
    background-color: #f78c44;
    width: 45%;
    transition: height 1s;
    position: absolute;
    z-index: 2;
}
.bar-chart-max{
    background-color: #e75f5f;
    width: 45%;
    transition: height 1s;
    position: absolute;
    z-index: 1;
}

.bar-chart-hover-heigth{
    position: absolute;
    height: 40%;
    width: 100%;
    bottom: 0;
    z-index: 5;
}
.bar-chart-hover-heigth2{
    position: absolute;
    height: 70%;
    width: 100%;
    bottom: 0;
    z-index: 4;
}
.bar-chart-hover-heigth:hover ~ .bar-chart-info-hover{
    top: 50%;
    display: block;
}
.bar-chart-hover-heigth2:hover ~ .bar-chart-info-hover{
    top: 20%;
    display: block;
}

.bar-chart-colums-display:hover > .bar-chart-minimum {
    background-color: #30AA4C;
    color: white;
}
.bar-chart-colums-display:hover > .bar-chart-medium {
    background-color: var(--primary-color);
    color: white;
}
.bar-chart-colums-display:hover > .bar-chart-max {
    background-color: #E63535;
    color: white;
}
.bar-chart-info-hover{
    position: absolute;
    padding: 0.5vh 0.5vw;
    box-sizing: border-box;
    width: 23vw;
    text-align: center;
    transition: opacity 0.5s;
    z-index: 5;
    border-radius: 10px;
    border: 0.4vw solid var(--primary-color);
    background-color: white;
    margin-right: -300%;
    display: none;
}
.bar-chart-info-minimum{
    background-color: #41a859;
    color: white;
    padding: 0 0.5vw;
    border-radius: 5px;
}
.bar-chart-info-medium{
    background-color: #f78c44;
    color: white;
    padding: 0 0.5vw;
    border-radius: 5px;
}
.bar-chart-info-max{
    background-color: #e75f5f;
    color: white;
    padding: 0 0.5vw;
    border-radius: 5px;
}
.bar-chart-info-hover-text{
    margin: 0;
    font-size: 1em;
}
.bar-chart-info-line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5vh;
}
</style>
