<template>
  <div class="mobilemenu-page-container">
    <div class="mobilemenu-page">
      <div class="mobilemenu-header-containear">
        <div style="display: flex; align-items: center">
          <span class="material-icons account-icon">account_circle</span>
          <div class="mobilemenu-user-name">{{ user.user_name }}</div>
        </div>
        <i
          v-on:click="closePage()"
          class="fa-solid fa-2x fa-xmark"
          style="color: #898989"
        ></i>
      </div>
      <div class="mobilemenu-header-end-line"></div>
      <!-- v-if="can('HOME', '' || 'READ')" -->
      <div
        class="mobilemenu-icons-containear"
        v-if=true
        v-on:click="redirect('/inicio')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">home</span> Inicio
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('REPORT', '' || 'READ')"
        v-on:click="redirect('/dashboard/')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">space_dashboard</span>Dashboard
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('PRODUCT', '' || 'READ')"
        v-on:click="redirect('/produtos/')"
      >
        <div class="mobilemenu-line-container">
          <img
            class="mobilemenu-icons-package-img"
            src="@/assets/package.svg"
            alt=""
          />Produtos
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('SELLER', '' || 'READ')"
        v-on:click="redirect('/fornecedores/')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">store</span> Meus Fornecedores
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('MAP', 'READ_PROVIDERS_MAP' || 'READ')"
        v-on:click="redirect('/explorar/')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">map</span>Mapa de Fornecedores
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="
          this.can('REQUEST', 'CREATE') ||
          this.can('REQUEST', 'CREATE_RESUPPLY')
        "
        v-on:click="redirect('/cliente/cotacao/nova')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">add_shopping_cart</span>Nova
          Comprar/requisição
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('REQUEST', '' || 'READ')"
        v-on:click="showOptions = !showOptions"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">desktop_windows</span>Painel de operações
        </div>
        <span v-if="!showOptions" class="material-icons-outlined expand-icon"
          >chevron_right</span
        >
        <span
          v-else
          class="material-icons-outlined expand-icon"
          style="transform: rotate(90deg)"
          >chevron_right</span
        >
      </div>

      <div v-if="showOptions">
        <div
          class="mobilemenu-subline-container"
          v-on:click="redirect('/cliente/monitores/cotacoes')"
        >
          <span class="material-icons-outlined mobile-subline-icon"
            >adjust</span
          >
          <div class="sidebar-option menu-option-1">Monitor de Cotação</div>
        </div>
        <div
          class="mobilemenu-subline-container"
          v-on:click="redirect('/cliente/monitores/ressuprimento')"
        >
          <span class="material-icons-outlined mobile-subline-icon"
            >adjust</span
          >
          <div class="sidebar-option menu-option-1">
            Monitor de Ressuprimento
          </div>
        </div>
        <div
          class="mobilemenu-subline-container mobile-subline-icon"
          v-on:click="redirect('/cliente/monitores/mobile')"
        >
          <span class="material-icons-outlined">adjust</span>
          <div class="sidebar-option menu-option-1">Lista Mobile</div>
        </div>
        <div
          class="mobilemenu-subline-container mobile-subline-icon"
          v-on:click="redirect('/cliente/monitores/pedidos')"
        >
          <span class="material-icons-outlined">adjust</span>
          <div class="sidebar-option menu-option-1">Pedidos</div>
        </div>
        <!-- <div class="mobilemenu-subline-container mobile-subline-icon" v-on:click="redirect('/cliente/monitores/price')">
          <span class="material-icons-outlined">adjust</span>
          <div class="sidebar-option menu-option-1">
            Monitor Preço de Venda Varejo
          </div>
        </div>   -->
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('REPORT', '' || 'READ')"
        v-on:click="redirect('/relatorios/')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">trending_up</span>Relatórios
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="JSON.parse(this.storage_svc.get('showOffers'))"
        v-on:click="redirect('/ofertas/')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">local_offer</span>Ofertas
        </div>
      </div>

      <div
        class="mobilemenu-icons-containear"
        v-if="can('ACCOUNT_SETTINGS', 'READ' || 'READ')"
        v-on:click="redirect('/configuracoes')"
      >
        <div class="mobilemenu-line-container">
          <span class="material-icons">settings</span>Minhas Configuração
        </div>
      </div>

      <div class="mobilemenu-body-end-line"></div>

      <div
        style="margin-top: 3vh"
        class="mobilemenu-icons-containear"
        v-on:click="logout()"
      >
        <span class="material-icons">logout</span>
        <div class="mobilemenu-line-container">Sair</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as CONSTANTS from "@/constants/constants";
import { perm_mixin } from "@/mixins/permission.mixin";
import StorageService from "@/services/TokenService";

export default {
  name: "MobileMenu",
  mixins: [perm_mixin],
  props: ["setMobileMenu"],
  data() {
    return {
      showOptions: false,
      storage_svc: new StorageService(),
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    redirect(page) {
      this.$router.push(page);
      this.closePage();
    },
    closePage() {
      this.setMobileMenu(false);
    },
    logout() {
      this.$store.dispatch("user/logout");
      //   location.href = CONSTANTS.ROUTE_LOGIN;
      window.location.assign(CONSTANTS.ROUTE_LOGIN);
    },
  },
};
</script>

<style scoped>
.mobilemenu-page {
  display: none;
  padding: 30px;
  height: 100vh;
  height: 100vh;
  /* overflow-y:scroll ; */
  z-index: 12;
  /* background-color: red; */
}
.mobilemenu-page-container {
  display: none;
}
.mobilemenu-user-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
}
.mobilemenu-header-containear {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobilemenu-header-end-line {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 4vh;
}
.mobilemenu-icons-containear {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.2vh;
  font-weight: 400;
  font-size: 1.2rem;
  color: #ababab;
}
.mobile-subline-icon {
  color: #ababab;
}
.mobilemenu-icons-package-img {
  filter: brightness(0) saturate(100%) invert(74%) sepia(0%) saturate(18%)
    hue-rotate(195deg) brightness(95%) contrast(86%);
  width: 25px;
}
.account-icon {
  font-size: 35px;
  color: #ababab;
  margin-right: 15px;
}
.mobilemenu-body-end-line {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 4vh;
}
.mobilemenu-line-container {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 3vw;
}
.mobilemenu-subline-container {
  display: flex;
  align-items: center;
  font-size: 3.5vw;
  gap: 2vw;
  margin-top: 2vh;
}
.expand-icon {
  font-size: 7vw;
  color: #ababab;
}
.taghome-icon {
  background-color: var(--primary-color);
  font-weight: 400;
  font-size: 4vw;
  color: #ffffff;
  padding: 0.5vh 2vw;
  border-radius: 5px;
}
.sidebar-option {
  padding: 0.5vh 1vw;
}
.menu-option-1 {
  color: #a1a1a1;
  display: flex;
  align-items: center;
  padding: 0 1.5vw;
  border-radius: 0 7px 7px 0;
  text-transform: uppercase;
  margin-left: -1px;
}
@media only screen and (max-width: 630px) {
  .mobilemenu-page-container {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    z-index: 11;
    height: 100%;
    width: 100%;
  }
  .mobilemenu-page {
    display: block;
    position: fixed;
    background-color: white;
    z-index: 12;
    height: 100%;
    width: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0 8px 8px 0;
  }
}
</style>
