import http from "../http";
import Service from "../../Service";

/**
 * @type SellersService
 */
export default class SellersService extends Service {

	load_sellers(params) {
		return http.get(`commons/sellers`, {
            headers: this.getHeaders(),
            params
        });
	}

}
