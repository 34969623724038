<style lang="scss" scoped>
    @import "./_offers-by-product.component";
.makeorder-page-table-expand-title{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.1em;
    color: #A1A1A1;
    margin-bottom: 2vh;
}
.makeorder-page-table-expand-colum-name{flex: 1}
.makeorder-page-table-expand-colum{width: 10%;}
.makeorder-page-table-expand-check{
    color: var(--primary-color);
    cursor: pointer;
    margin-right: 1.5vw;
}
.makeorder-page-table-expand-row{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
    padding: 1vh 1vw;
}
.makeorder-page-table-orders-input{
    text-align: left;
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding-left: 1vw;
}
.makeorder-page-table-orders-input:focus{
    background-color: lightsalmon;
}
.makeorder-page-table-orders-input-disabled{
    background-color: lightgray;
    border: 1px solid #505050;
    cursor: default;
}
.makeorder-page-table-orders-input-up{
    background-color: lightcoral;
    color: white;
}
.makeorder-page-table-orders-input-dow{
    background-color: lightgreen;
    color: white;
}
.make-order-checkmark-input:checked ~ .make-order-checkmark {
    background-color: var(--primary-color);
}

</style>

<template>
    <!-- <tr class="last">
        <td colspan="10">
            <cc-loader v-if="is_loading" :show_txt="false"/>
            <table class="product-providers" v-else>
                <thead>
                <tr>
                    <th></th>
                    <th>Fornecedor</th>
                    <th>Marca</th>
                    <th>QTD. EMB</th>
                    <th>&nbsp; &nbsp; &nbsp;QTD</th>
                    <th>VL. UNITARIO</th>
                    <th>TOTAL ITEM</th>
                    <th>FAT. MIN</th>
                    <th>TOTAL FORNECEDOR</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(offer, idx) in offers" v-bind:key="offer.id">
                    <td>
                    <div class="checkbox-container">
                        <input type="checkbox"
                            :checked="is_winner(offer.pre_orders)"
                            v-on:click="toggle_offer(offer)"
                            :disabled="order"
                        />
                        <span class="checkmark" :class="{ disabled: order }"></span>
                    </div>
                    </td>
                    <td>
                    <div style="position:relative;display:inline;">
                        <i v-if="offer.loading" class="fas fa-circle-notch fa-spin typing-loader"></i>
                    </div>
                    <span>{{ offer.seller.provider.name }}</span>
                    <span class="obs" v-if="offer.obs">
                        <span class="label">OBS:</span>
                        {{ offer.obs }}
                    </span>
                    </td>
                    <td>{{ offer.brand ? offer.brand.name : '-' }}</td>
                    <td>{{ offer.quantity }}</td>
                    <td>
                        <div class="quantity-holder">
                            <input
                                type="text"
                                :ref="'product-amount'"
                                :data-visible="!order"
                                :data-idx="idx"
                                :class="{ disabled: order }"
                                v-on:keyup="process_event($event, offer.pre_order)"
                                v-on:blur="process_event($event, offer.pre_order)"
                                :readonly="order"
                                :disabled="order"
                                v-model="offer.pre_order.quantity"
                            />
                        </div>
                    </td>
                    <td>{{ offer.price | currency }}</td>
                    <td>{{ offer.price*offer.pre_order.quantity | currency }}</td>
                    <td>{{ offer.seller.delivery.minimum_billing | currency }}</td>
                    <td>{{ total_for_seller(offer) | currency }}</td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr> -->
    <div>
        <div class="makeorder-page-table-expand-title">
            <div class="makeorder-page-table-expand-colum-name">Fornecedor</div>
            <div class="makeorder-page-table-expand-colum">Marcas</div>
            <div class="makeorder-page-table-expand-colum">QTD. EMB</div>
            <div class="makeorder-page-table-expand-colum">QTD</div>
            <div class="makeorder-page-table-expand-colum">Valor Unitário</div>
            <div class="makeorder-page-table-expand-colum">Total Item</div>
            <div class="makeorder-page-table-expand-colum">Fat. Min</div>
            <div class="makeorder-page-table-expand-colum">Total Fornecedor</div>
        </div>
        <div class="makeorder-page-table-expand-row" v-for="(offer, idx) in offers" v-bind:key="offer.id" :class="{'page-table-line-special': idx % 2 != 0 }">
            <span  v-if="offer.isCheck" v-on:click="toggle_offer(offer)" class="material-icons-outlined makeorder-page-table-expand-check">check_box</span>
            <span v-else  v-on:click="toggle_offer(offer)" class="material-icons-outlined makeorder-page-table-expand-check">check_box_outline_blank</span>
            <div class="makeorder-page-table-expand-colum-name">{{ offer.seller.provider.name }}</div>
            <div class="makeorder-page-table-expand-colum">{{ offer.brand ? offer.brand.name : '-' }}</div>
            <div class="makeorder-page-table-expand-colum">{{ !po.subrequest.request_product.product.is_generic ? po.subrequest.request_product.product.packing_quantity : offer.quantity }}</div>
            <div class="makeorder-page-table-expand-colum">
                <div class="quantity-holder">
                    <input
                        type="text"
                        :ref="'product-amount'"
                        :data-visible="!order"
                        :data-idx="idx"
                        :class="{ 'makeorder-page-table-orders-input-disabled': order }"
                        class="makeorder-page-table-orders-input"
                        v-on:keyup="process_event($event, offer.pre_order)"
                        v-on:blur="process_event($event, offer.pre_order)"
                        :readonly="order"
                        :disabled="order"
                        v-model="offer.pre_order.quantity"
                    />
                </div>
            </div>
            <div class="makeorder-page-table-expand-colum">{{ offer.price | currency }}</div>
            <div class="makeorder-page-table-expand-colum">{{ po.offer.price*(!po.subrequest.request_product.product.is_generic ? po.subrequest.request_product.product.packing_quantity : offer.quantity)*po.quantity | currency }}</div>
            <div class="makeorder-page-table-expand-colum">{{ offer.seller.delivery.minimum_billing | currency }}</div>
            <div class="makeorder-page-table-expand-colum">{{ total_for_seller(offer) | currency }}</div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import ProductService from "@/services/ProductService";
import OrderService from "@/services/OrderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import ProductOrdersModal from '@/modals/products/orders';
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import V3RequestsService from "@/services/v3/request.service"
import OfferService from "@/services/v3/requests/offer.service"
import { mapState } from 'vuex'
import OfferCollection from '@/models/requests/offer.collection'
import { loaderMixin } from '@/mixins/sweet-loader.mixin'

export default {
    mixins: [ elementNavigationmMixin, loaderMixin ],
    props: {
        po: Object,
        order: Object,
        store_id: [ String, Number ]
    },
    components: {
        ccPagination: Pagination,
        popoverLastBuy,
        NegotiationModal,
        ProductOrdersModal,
        ProductWrapModal
    },
    data() {
        return {
            CONSTANTS: CONSTANTS,
            offers: [],
            sellers: [],
            openedPopovers: [],
            orderService: new OrderService(),
            product_svc: new ProductService(),
            svc: new V3RequestsService(),
            offer_svc: new OfferService(),
            current_seller: null,
            current_typing: null,
            current_product: null,
            search_terms: null,
            selected_status_filter: "WINNERS_ONLY",
            products: [],
            mapped_request: null,
            is_product_orders_modal_open: false,
            open_edit_wrap: false,
            is_loading: true
        };
    },
    watch: {
        preorder: function() {
            this.reload()
        }
    },
    computed: {
        ...mapState('requests', [ 'request' ]),
        ...mapState([ 'user' ])
    },
    methods: {
        getCheckBoxStyle (value) {if (value) return 'color: lightgray; cursor: default'},
        total_for_seller(offer) {
            return offer.pre_orders.reduce((x, { quantity }) => {
                return ((!this.po.subrequest.request_product.product.is_generic ? this.po.subrequest.request_product.product.packing_quantity : quantity) * offer.price) + x
            }, 0)
        },
        is_winner(pos) {
            return pos.some(po => po.subrequest.store_id == this.store_id)
        },
        close_wrap(reload = false) {
            this.current_product = null;
            this.open_edit_wrap = false
            if(reload) {
                this.$emit("reload_provider");
            }
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        show_orders(product) {
            if(product.ultimaCompra) {
                this.current_product = product
                this.is_product_orders_modal_open = true
            }
        },
        open_negotiation_modal(product) {

            this.current_product = product;

            let resolve = (data) => {
                if(data) {
                    this.$set(product, "digitacoes", data.digitacoes);
                }
                this.current_typing = product.digitacoes.filter(offer => {
                    return offer.idFornecedor == this.po.for_id &&
                        offer.idVendedor == this.po.vendedor_id &&
                        offer.vencedor_quantidade == 1 &&
                        offer.pro_id == product.idProduto
                })[0]
            }

            if(!product.digitacoes) {
                this.load_offers(product).then(resolve)
            } else {
                resolve();
            }

        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.menorPreco = new_value
            }
            this.current_typing = null;
            if(with_reload) {
                this.load_offers(this.current_product).then(data => {
                this.$set(this.current_product, "digitacoes", data.digitacoes);
                this.$emit("reload_provider");
                })
            }
        },
        process_event: function(e, po) {
            this.navigate(e, {
                ref: 'product-amount',
                callback: {
                    function: () => {
                        return po.quantity != po._quantity ? this.update_quantity(po, () => {
                            this.$emit("update_po", po)
                        }) : new Promise((resolve) => resolve())
                    }
                }
            })
        },
        toggle_offer(offer) {
            if (this.order) return
            console.log(offer)
            let po = offer.pre_order
            po.offer_id = offer.id
            po.quantity = po.quantity > 0 ? 0 : this.calculate_suggestion(po, offer)
            this.update_quantity(po, () => {
                this.$emit("update_po", po)
                offer.isCheck = !offer.isCheck
                this.$forceUpdate()
            })
        },
        calculate_suggestion(po, offer) {
            return Math.round(po.subrequest.quantity / offer.quantity) * offer.quantity
        },
        update_quantity(po, callback) {
            po.winner = po.quantity > 0
            let params = {
                offer_id: po.offer_id,
                quantity: po.quantity,
                subrequest_id: po.subrequest.id,
                is_winner: po.winner ? 1 : 0
            }
            return this.svc.toggle(params).then(response => response.data).then(callback);
        },
        reload() {
            this.mapped_request = new OfferCollection(this.request, this.user.stores)
                .store(this.preorder.subrequest.store_id)
                .product(this.preorder.product.id)
            this.offers = this.mapped_request.current_product.offers
        },
        load() {
            return this.offer_svc.load(this.request.id, {
                request_product_id: this.po.offer.request_product_id
            })
            .then(response => response.data)
            .then(response => {
                this.offers = response
                this.offers.forEach(o => {
                    o.pre_order = o.pre_orders.find(po => po.subrequest.store_id == this.store_id) || { subrequest: this.po.subrequest }
                    o.isCheck = this.is_winner(o.pre_orders)
                })
                this.is_loading = false
            })
        }
    },
    mounted() {
        this.load()
    }
};
</script>
