import http from "../http";
import Service from "../../Service";

/**
 * @type PreOrderService
 */
export default class PreOrderService extends Service {

    download_pdf(id, params) {
		return http.get(`/v2/clients/requests/${id}/pre-orders/pdf`, {
            params,
            headers: this.getHeaders(),
            responseType: 'arraybuffer'
        });
	}

    load(id, params) {
		return http.get(`/v2/clients/requests/${id}/pre-orders`, {
            headers: this.getHeaders(),
            params
        });
	}
}
