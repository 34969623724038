import http from "./http";
import httpV1 from "../Http";
import Service from "../Service";

/**
 * @type QuotationService
 */
export default class SellerService extends Service {

	all(data) {
		return http.get("sellers/", {
			headers: this.getHeaders(),
			params: data
		});
	}

	confirm_delivery(id) {
		return http.get(`sellers/order/${id}/received`, {
			headers: this.getHeaders()
		});
	}
	confirm_delivery_status_p(id) {
		return httpV1.put(`pedido?id_pedido=${id}`, {}, {
			headers: this.getHeaders()
		});
	}

    find(id) {
		return http.get(`sellers/${id}`, {
			headers: this.getHeaders()
		});
	}

    find_delivery(request_id, id) {
		return http.get(`requests/${request_id}/sellers/${id}/delivery`, {
			headers: this.getHeaders()
		});
	}


}
