<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="modal-content-header">
        <div>Salvar Filtro</div>
        <span class="material-icons modal-close-icon" v-on:click="close()">close</span>
      </div>
      <div class="modal-content-body">
        <div class="modal-input-title">Nome do filtro</div>
        <input class="modal-name-input" v-model="filterName" type="text" placeholder="Digite o nome do Filtro">
        <div class="modal-name-input-error" v-if="erroMessage">
          {{erroMessage}}
        </div>
        <div class="modal-body-buttons-container">
          <div class="modal-body-button-cancel" v-on:click="close()">Cancelar</div>
          <div class="modal-body-button-save" v-on:click="initSaveFilter()">Salvar </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveFilterModal',
  props: ['close', 'saveFilter', 'loadCustomFilter'],
  data () {
    return {
      filterName: undefined,
      erroMessage: undefined
    }
  },
  methods: {
    initSaveFilter () {
      if (!this.filterName) {
        this.erroMessage = 'Erro, por favor digite um nome para filtro'
        return false
      }
      this.saveFilter(this.filterName)
      this.close()
      this.loadCustomFilter()
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 30vw;
  border-radius: 12px;
}
.modal-content-header{
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}
.modal-close-icon{
  background-color: white;
  color: var(--primary-color);
  font-size: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.modal-content-body{
  border-radius: 0px 0px 10px 10px;
  padding: 2vh 2vw;
}
.modal-input-title{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.modal-name-input{
  width: 35vw;
  margin-top: 1vh;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 0.75vh;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.modal-name-input:focus{
  outline: none;
}
.modal-body-buttons-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
}
.modal-body-button-cancel{
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  width: 30%;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-color);
  text-align: center;
  padding-top: 2px;
  margin-right: 5%;
  cursor: pointer;
}
.modal-body-button-save{
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 30%;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.modal-name-input-error{
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
}
@media only screen and (max-width: 800px) {
  .modal-content-container{
    max-width: 110vw;
  }
  .modal-name-input{
    width: 85vw;
  }
}
</style>
