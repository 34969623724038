<style lang="scss" scoped>
	@import "./management.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4 d-flex justify-content-center">
				<div class="col" id="sellers">
					<div class="list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">#</th>
									<th class="text-left px-3">Empresa</th>
									<th class="text-left px-3" v-show="!current_store">Cidade</th>
									<th class="text-left px-3" v-show="!current_store">Telefone</th>
									<th class="text-left px-0" v-show="!current_store">CNPJ</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-show="store.visible" v-for="store in stores" :key="store.loj_id" @click="toggle_user(store)" class="pointer" :class="{ active: current_store && current_store.loj_id == store.loj_id }">
									<td class="text-left px-3"><b>{{ store.loj_numeroLoja }}</b></td>
									<td class="text-left px-3 text-uppercase"><b>{{ store.loj_nomeFantasia }}</b></td>
									<td class="text-left px-3" v-show="!current_store"><b>{{ store.city.cid_nome }}</b></td>
									<td class="text-left px-3" v-show="!current_store"><b>{{ store.loj_telefone || "" | VMask('(##) #####-####') }}</b></td>
									<td class="text-left px-0" v-show="!current_store">{{ `${parse_cnpj(store.loj_cnpj)}` || "" | VMask('## ### ###/####-##') }}</td>
									<td>
										<cc-button :icon="'fa fa-pencil'" :content="'Editar'" :classes="'fixed'" class="show-products"/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div id="seller-products" class="col" v-if="current_store">
					<label for="" class="label-control"><b>#{{ current_store ? current_store.loj_nomeFantasia : ''}}</b></label>
					<div class="list-group p-4">
						<ValidationObserver v-slot="{ handleSubmit }">
							<form action="" @submit.prevent="handleSubmit(save)">
								<div class="row pt-2">
                                    <div v-if="message" class="col-md-12 col-lg-12">
                                        <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                    </div>
                                    <div class="col d-flex justify-content-end">
                                        <button id="save-btn" class="btnFeedbackModal" type="submit">
											Salvar
										</button>
                                    </div>
                                </div>
								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-6">
										<label>Nome:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model="current_store.loj_descricao"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col-md-3 col-lg-6">
										<label>CNPJ:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input v-mask="'## ### ###/####-##'" type="text" v-model="current_store.loj_cnpj" readonly></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
								</div>

								<div class="row py-1  d-flex justify-content-center">
									<div class="col-md-3 col-lg-6">
										<label>Nome Fantasia:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model="current_store.loj_nomeFantasia"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col-md-3 col-lg-6">
										<label>Razão Social:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model="current_store.loj_razao"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
								</div>

								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-6">
										<label>Email:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model="current_store.loj_email"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col-md-3 col-lg-6">
										<label>Telefone:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input v-mask="'(##) #.####-####'" type="text" v-model="current_store.loj_telefone"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
								</div>

								<div class="row py-1 d-flex justify-content-center">
                                    <div class="col col-3">
										<label>Num. Loja:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model.number="current_store.loj_numeroLoja"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col col-3">
										<label>CEP:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input v-mask="'##.###-###'" type="text" v-model="current_store.loj_cep"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col col-3">
										<label>Estado:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-select-v2 :tracker="'est_id'" :text="'est_nome'" v-model="current_store.state" :options="states" @select="set_state"></cc-select-v2>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
									<div class="col col-3">
										<label>Cidade:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-select-v2 :tracker="'cid_id'" :text="'cid_nome'" v-model="current_store.city" :options="available_cities" @select="set_city"></cc-select-v2>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
								</div>

								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-8">
										<label>Rua:<span style="color:red !important">*</span></label>
										<cc-input type="text" v-model="current_store.loj_endereco"></cc-input>
									</div>
									<div class="col-md-3 col-lg-4">
										<label>Bairro:<span style="color:red !important">*</span></label>
										<ValidationProvider rules="required" v-slot="v">
											<cc-input type="text" v-model="current_store.loj_bairro"></cc-input>
											<span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
									</div>
								</div>
								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-12">
										<label>Complemento:</label>
										<cc-input type="text" v-model="current_store.loj_complemento"></cc-input>
									</div>
								</div>
								<div class="row pt-2">
									<div v-if="message" class="col-md-12 col-lg-12">
										<h4 class="py-4 text-center text-danger">{{message}}</h4>
									</div>
								</div>
							</form>
						</ValidationObserver>
					</div>
				</div>
			</div>
		</div>
	</cc-modal>
</template>

<script>

import StoreService from "@/services/v1/store.service";
import UserService from "@/services/v1/user.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { gsap } from "gsap";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	mixins: [ loaderMixin ],
	components: {
		ValidationProvider,
		ValidationObserver
	},
	props: {
		client: {
			type: Object,
			required: true
		}
	},
  	data() {
		return {
			stores: [],
			current_store: null,
			cities: [],
			states: [],
			loading: false,
			message: null,
			svc: new StoreService(),
			user_svc: new UserService(),
			modal: {
				title: `minhas empresas`,
				subtitle: `Gerencie suas empresas`,
				icon_title: 'fas fa-store',
				cancel_text: 'Voltar',
				style: {
					width: "90%",
					height: "fit-content"
				}
			}
		};
	},
	computed: {
		available_cities : function(val) {
			return this.current_store && this.current_store.state ? this.cities.filter(c => c.est_id == this.current_store.state.est_id) : []
		}
	},
  	methods: {
        parse_cnpj: function(val) {
            return val && `${val}`.length < 14 ? `0${val}` : val
        },
		set_state() {
			this.$set(this.current_store, 'state', this.current_store.state)
			this.$forceUpdate()
		},
		set_city() {
			this.$set(this.current_store, 'city', this.current_store.city)
			this.$forceUpdate()
		},
		toggle_user(store) {
			let products_width = 70
			if(this.current_store && this.current_store.loj_id == store.loj_id) {
				gsap.to('#seller-products', { duration: 1, width: 0 })
				gsap.to('#sellers', { duration: 1, 'max-width': "100%", 'flex': "0 0 100%"})
				this.current_store = null
			} else {
				this.current_store = store;
				gsap.fromTo('#seller-products', { 'width': "0%", 'flex': `0 0 0%` }, { duration: 1, 'flex': `0 0 ${products_width-6}%`, ease: 'expo.out'})
				gsap.fromTo('#sellers', { 'max-width': "100%", 'flex': '0 0 100%' }, { duration: 1, 'max-width': `"${100-products_width}%`, 'flex': `0 0 ${100-products_width}%`, ease: 'expo.out'})
			}
			this.$forceUpdate()
		},
		toggle_brands(product) {
			product.produtosSecundarios.filter(p => p.ult_preco_cotacao <= 0).forEach(p => p.visible = !p.visible)
			product.show_subproducts = product.produtosSecundarios.some(p => p.visible)
			this.$forceUpdate()
		},
		save() {
			this.present_loader("Salvando loja...")
			let callback = data => {
				this.dismiss_loader()
			}
			let callback_error = err => {
				this.dismiss_loader()
			}
			if(this.current_store.loj_id) {
				this.svc.update(this.client.cli_id, this.current_store.loj_id, this.current_store).then(callback, callback_error)
			} else {
				this.svc.create(this.client.cli_id, this.current_store).then(callback, callback_error)
			}
        },
		close() {
			if(this.current_store) {
				this.toggle_user(this.current_store)
			} else {
				this.$emit("close");
			}
		},
		load_stores() {
			return this.svc.all(this.client.cli_id)
			.then(response => response.data)
			.then(data => {
				this.stores = data.lojas;
				this.stores.forEach(s => s.visible = true)
				this.stores.forEach(s => {
					s.city = this.cities.find(ct => ct.cid_id == s.cid_id)
					s.state = this.states.find(st => st.est_id == s.est_id)
				})
				this.dismiss_loader()
			})
			.catch(error => {
				this.dismiss_loader()
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_region_data() {
			this.present_loader("Carregando empresas...")
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.cities = data.cidades
				this.states = data.estados
			})
		},
		update_offer(product, seller_id = this.current_store.usu_id) {
			this.$set(product, 'loading', true)
			return this.req_svc.update_request_product_offer(this.request.numeroCotacao, product.produto_id, { preco: product.preco, quantidadeDigitacao: 1, origem: "CLIENTE", vendedor: seller_id })
			.then(data => this.$set(product, 'loading', false))
			.then(response => response.data)
			.catch(error => {
				this.$set(product, 'loading', false)
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		process_visibility(p) {
			if(p.generico == 1) {
				p.produtosSecundarios.forEach(p => p.visible = (p.ult_preco_cotacao > 0 && this.filters.with_history) || !this.filters.with_history)
				p.show_subproducts = p.produtosSecundarios.some(p => p.visible)
				p.visible = (p.show_subproducts && this.filters.with_history) || !this.filters.with_history
			} else {
				let placeholder_product = {}
				p.show_subproducts = (p.ult_preco_cotacao > 0 && this.filters.with_history) || !this.filters.with_history
				p.visible = p.show_subproducts
				Object.assign(placeholder_product, p)
				p.produtosSecundarios = [ placeholder_product ]
			}
		},
		handle_navigation($event, product) {
			this.navigate($event, {
				callback: {
					function: this.update_offer,
					params: product
				},
				ref: 'price'
			})
		},
		copy_prices($evt, seller = this.current_store) {
			this.confirm_action({
				message: "Confirma ação?",
				subtitle: "Todos os preços da ultima cotação serão atualizados nesta ",
				callback: () => {
					this.present_loader("Copiando preços compra/cotação...")
					return this.quotation_svc.copy_prices(this.request.numeroCotacao, seller.usu_id)
					.then(() => this.load_products(seller))
					.then(() => this.dismiss_loader())
				}
			})
		}
	},
	mounted() {
		this.load_region_data().then(() => this.load_stores())
	}
};
</script>
