import http from "./http";
import Service from "../Service";

/**
 * @type DivergentService
 */
export default class DivergentService extends Service {
    getOptions(){
        return http.get('clients/divergencia/opcoes', {
          headers: this.getHeaders()
        })
      }
    saveDivergent(payload){
        return http.post('clients/divergencia/inserir',payload, {
            headers: this.getHeaders(),
          })
    }
    deleteDivergent(id){
      return http.delete('clients/divergencia/remove/'+id, {
          headers: this.getHeaders(),
        })
    } 
    updateDivergent(payload){
      return http.put('clients/divergencia/update/'+payload.id, payload, {
          headers: this.getHeaders(),
        })
    } 
    saveOrderDivergent(payload){
      return http.post('clients/divergencia/pedido/inserir',payload, {
          headers: this.getHeaders(),
        })
    }
    getOrderDivergent(id){
      return http.get('clients/divergencia/pedido/'+id, {
        headers: this.getHeaders(),
      })
    }
    listDivergent(){
      return http.get('clients/divergencia/list', {
        headers: this.getHeaders(),
      })
    }
    generateNewRebuy(list){
      return http.post('clients/divergencia/generate',{'ids': list}, {
        headers: this.getHeaders(),
      })
    }
    rebuyAll(list,cotId,orderId){
      return http.post(`clients/divergencia/monitor/divergencia/cotacao/${cotId}/pedido/${orderId}`,{'produtos': list}, {
        headers: this.getHeaders(),
      })
    }
}