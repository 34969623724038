<template>
    <div class="management-modal-body">
                <div class="management-modal-title-row">
                    <div class="management-modal-inputs-wrapper">
                        <div class="management-modal-inputs-container-wrapper">
                            <span class="management-modal-inputs-container-title">Filtrar grupos</span>
                            <input :placeholder="'Digite o nome do grupo'" class="manage-categories__input">
                        </div>
                    </div>
                    <div class="management-modal-inputs-wrapper">
                        <div class="management-modal-inputs-container-wrapper">
                            <span class="management-modal-inputs-container-title">Novo grupo de produto</span>
                            <div class="management-modal-add-wrapper">
                                <input @keyup.enter="addNewGroup(newGroupValue)" :placeholder="'Adicione uma Novo grupo de produto'" v-model="newGroupValue"
                                    class="manage-categories__new-category-input">
                                <span @click="addNewGroup(newGroupValue)" class="material-icons-outlined management-modal-inputs-container-icon">add</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-table-container">
                    <div class="modal-table-header">
                        <div class="modal-table-colum-id">ID</div>
                        <div class="modal-table-colum-name">Nome</div>
                        <div class="modal-table-colum-count">Produtos</div>
                        <div class="modal-table-colum-action text-center">Ação</div>
                    </div>
                    <div v-for="(value, idx) in groups" :key="value.id"
                        :class="{ 'page-table-line-special': idx % 2 != 0 }" class="modal-table-row">
                        <div class="modal-table-colum-id"># {{ value.id }}</div>
                        <div class="modal-table-colum-name">{{ value.descricao }}</div>
                        <div class="modal-table-colum-count">{{ value.count }}</div>
                        <div class="modal-table-colum-action text-center">
                            <span @click="editGroup(value)" class="material-icons-outlined edit-group-icon">edit</span>
                            <span @click="productsListId(value)" class="material-icons-outlined list-group-icon">fact_check</span>
                        </div>
                    </div>
                </div>

            </div>
    
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";

export default {
    name: 'ListGroupsModal',
    props: ['listId', 'addNewGroup', 'groups', 'editGroup', 'productsListId'],
    components: {
        StandardButton,
    },
    data() {
        return {
            newGroupValue: ''
        }
    }
}
</script>

<style scoped lang="scss">

.management-modal-content-container {
    background-color: white;
    border-radius: 12px;
    height: 100%;
}

.management-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.management-modal-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.management-modal-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.management-modal-body {
    padding: 2vh 2vw;
    overflow-y: auto;
    height: 100%;
}

.management-modal-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.management-modal-title {
    color: #707070;
    font-weight: 400;
    font-size: 2.2em;
}

.management-modal-inputs-wrapper {
    display: flex;
    align-items: center;
}

.management-modal-inputs-container-wrapper {
    display: flex;
    flex-direction: column;
}

.management-modal-inputs-container-title {
    color: #898989;
    font-weight: 400;
    font-size: 1.8em;
    letter-spacing: 0.15px;
}

.manage-categories__input {
    width: 27vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}

.management-modal-add-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.manage-categories__new-category-input {
    width: 27vw;
    height: 6vh;
    padding: 0px 10px;
    font-weight: 400;
    font-size: 1.1em;
    border-top: 2px solid #c2c2c3;
    border-left: 2px solid #c2c2c3;
    border-bottom: 2px solid #c2c2c3;
    border-right: none;
    border-radius: 5.23px 0px 0px 5.23px;
    color: #A1A1A1;
}

.management-modal-inputs-container-icon {
    position: absolute;
    width: 4vw;
    height: 6vh;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 0px 5px 5px 0px;
    border-top: 1px solid #c2c2c3;
    border-bottom: 1px solid #c2c2c3;
    border-right: 1px solid #c2c2c3;
    cursor: pointer;
}

.modal-table-container {
    min-height: 40vh;
    margin: 2em 0;
}

.modal-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 17px;
    color: #605F5F;
    margin-bottom: 1vh;
}

.modal-table-colum-id {
    width: 15%;
    padding-left: 0.5vw;
}
.modal-table-colum-count{
    width: 10%;
}

.modal-table-colum-name {
    flex: 1;
    padding-left: 0.5vw;
}

.modal-table-colum-action {
    width: 30%;
    padding-left: 0.5vw;
}

.modal-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    margin-bottom: 1vh;
    align-items: center;
}
.edit-group-icon{
    font-size: 1.3em;
    color: lightgreen;
    cursor: pointer;
}
.list-group-icon{
    font-size: 1.3em;
    color: lightblue;
    cursor: pointer;
    margin-left: 1vw;
}
@media only screen and (max-width: 800px) {
    .manage-categories__new-category-input{
        width: 80%;
    }
    .management-modal-inputs-container-icon{
        width: 20%;
    }
    .manage-categories__input{
        width: 35vw;
    }
}
</style>