<template>
    <div id="print-matrix">
        <div class="p-3 matrix-wrapper" id="matrix-wrapper">
            <div class="container-fluid">
                <table id="header-table">
                    <tbody>
                        <template v-if="buyer && provider">
                            <tr class="print-header text-center">
                                <td rowspan="7">
                                    <img class="logo" src="/images/logotipo.png"/>
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">Comprador</td>
                                <td colspan="3">Fornecedor</td>
                                <td colspan="3" v-if="provider.data_entrega">Detalhes do pedido</td>
                                <td v-if="provider.observacao">Observação:</td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">
                                    {{ buyer.trading_name.toUpperCase() }}
                                </td>
                                <td colspan="3">{{ provider.for_nome }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    {{ provider.data_entrega | formatDateOnly }}
                                </td>
                                <td v-if="provider.observacao">{{ provider.observacao }}</td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">{{ buyer.street }}, {{ buyer.district }}</td>
                                <td colspan="3">{{ provider.usu_nome }}</td>
                                <td colspan="3" v-if="provider.data_entrega && provider.tpc_nome">
                                    {{ provider.tpc_nome }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">{{ provider.cid_nome }}/{{ provider.est_sigla }}</td>
                                <td colspan="3">{{ provider.usu_email }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    Faturamento: {{ provider.faturamento_minimo | currency }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">CNPJ: {{ buyer.cnpj | cnpj }}</td>
                                <td colspan="3">Fone: {{ provider.usu_telefone }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    Cond. de Pgto: {{ provider.forma }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">Fone: {{ buyer.phone }}</td>
                                <td colspan="3"></td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    <span>Status:
                                        <span v-if="!provider.id_pedido" class="status pending">
                                            pendente
                                        </span>
                                        <span v-else class="status ordered">
                                            gerado
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <table v-if="products" :style="{ width: `${20 + (stores.length * field_size)}rem` }" id="">
                    <thead>
                        <tr>
                            <td class="col pro-col text-center">Produto</td>
                            <td v-for="store in stores" :key="store.id" class="col text-center" @click="goto(store, total_store(store))">
                                <span class="description" style="color:color: #4D4F5C">
                                    <i class="fas fa-store-alt" style="color: #6081e8;font-size: 1rem;"></i>
                                    {{ store.name }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="col text-center pro-col">
                                <div class="d-flex p-0">
                                    <p class="mb-0 info">
                                        <span class="description">
                                            Vlr:
                                        </span>
                                    </p>
                                    <p class="mb-0 info" title="ultimo preço">
                                        <span class="description">Ult Cmp:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description">Qtd:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description" style="color: #4D4F5C">Emb:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description">Total:</span>
                                    </p>
                                </div>
                            </td>
                            <td class="col text-center" v-for="store in stores" :key="'tot-' + store.id">
                                <span class="description store-total"
                                    :class="{ cursor: total_store(store) > 0, higher: total_store(store) > 0 && total_store(store) < min_fat }">
                                        {{ total_store(store).toFixed(2) | currency }}
                                </span>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="body" v-if="stores && stores.length > 0" :style="{ width: `${20 + (stores.length * field_size)}rem` }">
                        <template v-for="(item, index) in products">
                            <tr :key="'name-' + item.pro_id" class="prod-row">
                                <td class="text-left border-bottom-0 pl-0 prod-info">
                                    <div>
                                        <p class="pro-name" style="position:relative">
                                            {{ item.pro_principal_nome || item.pro_descricao }}
                                            <span class="brand">{{ item.pro_observacao }}</span>
                                            <i v-if="item.observacao" class="fas fa-info-circle" :title="item.observacao.toUpperCase()"></i>
                                        </p>
                                        <div class="text-center border-top-0 pt-0">
                                            <div class="d-flex p-0">
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">
                                                        <span :class="{ lower: item.valor < item.preco_ultima_compra, higher: item.valor > item.preco_ultima_compra, 'total-val': true }">
                                                            <svg v-if="item.valor < item.preco_ultima_compra" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
                                                            </svg>
                                                            <svg v-if="item.valor > item.preco_ultima_compra" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                                            </svg>
                                                            {{ item.valor | currency }}
                                                        </span>
                                                        <i v-if="!has_orders()" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(item)"></i>
                                                    </span>
                                                </p>
                                                <p class="mb-0 info" title="ultimo preço">
                                                    <span class="d-block text-center">{{ item.preco_ultima_compra | currency }}</span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">{{ quantity_sum(item.stores) }}</span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">
                                                        {{ item.pro_unidade_compra ? item.pro_unidade_compra : `${item.pro_embalagem.split(" ")[0]} ${item.pro_embalagem.split(" ")[1]}` }}
                                                        <i class="far fa-edit" v-if="can('PRODUCT', 'UPDATE') && !has_orders() && item.pro_generico == 0 && !item.pro_principal"/>
                                                    </span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center total-val">{{ total(item.stores) | currency }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td v-for="(store, idx) in stores" :key="store.id" class="quantity-wrapper">
                                    <div class="column-quantity">
                                        <p class="rounded form-control text-center m-auto"
                                            :class="'store-quantity'"
                                            :id="'ipt-' + index + '-' + idx"
                                            :ref="'y-' + index + '-' + idx">
                                            {{ get_quantity(item.stores, store) }}
                                            <span class="column-stock">{{ get_stock_label(item.stores, store) }}</span>
                                        </p>
                                        <div style="position: relative">
                                            <cc-loader v-show="filter_product(item.stores, store.id).loading == true" class="quantity-loader" :show_txt="false"></cc-loader>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin, perm_mixin ],
    props: {
        min_fat: [ Number ],
        products: [ Array ],
        buyer: [ Object ],
        provider: [ Object ]
    },
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false,
            field_size: 8
        }
    },
    methods: {
        filter_product(stores, store_id) {
            let result = stores.find(s => s.loj_id == store_id)
            if(!result) {
                result = {
                    quantidade: null,
                    loj_id: store_id,
                    pro_id: stores[0].pro_id,
                    valor: stores[0].valor,
                    pro_generico: stores[0].pro_generico,
                    pro_principal: stores[0].pro_principal,
                    pro_embalagem: stores[0].pro_embalagem
                }
                stores.push(result)
            }
            return result;
        },
        get_quantity(stores, store) {
            let result = this.filter_product(stores, store.id)
            return result ? result.quantidade : null
        },
        get_stock(stores, store) {
            let result = this.filter_product(stores, store.id)
            return result ? result.estoque : null
        },
        get_stock_label(stores, store) {
            let result = this.filter_product(stores, store.id);
            return result.estoque ? " - ("+result.estoque+")" : null
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.quantidade || 0) }, 0)
        },
        total_store(store) {
            let result = 0
            this.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.loj_id == store.id) {
                        let value = pp.pro_generico == 0 && pp.pro_principal == null && this.user.request_type != 1 ? parseInt(pp.pro_embalagem.split(' ')[1])*pp.valor : pp.valor
                        result += value*pp.quantidade
                    }
                })
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => { return sum + ((store.quantidade || 0)*(store.pro_generico == 0 && store.pro_principal == null && this.user.request_type != 1 ? parseInt(store.pro_embalagem.split(' ')[1])*store.valor : store.valor)) }, 0)
        },
        has_orders() {
            let result = false
            this.products.forEach(p =>
                p.stores.forEach(pp => {
                    if(p.id_pedido || pp.id_pedido) {
                        result = true
                    }
                })
            )
            return result
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        get_has_stock(products,store){
            let result = false;
            products.forEach( product =>{
                for (let j = 0; j < product.stores.length; j++) {
                    if((product.stores[j].loj_id == store.id) && product.stores[j].estoque){
                        result = true;
                        break;
                    }
                }
            })

            return result;
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
    }
}
</script>

<style lang="scss" scoped>
    $gray3: #4D4F5C;
$first-col-width: 33rem;
$cell-width: 8rem;
$primary-color: var(--primary-color);
$secondary-color: #4267B2;
$ternary-color: #20CE88;
$higher-green: #138413;
$lower-red: red;

@page :first {
    div {
        background-color: redaa;
    }
}

.empty-header {
    height: 80px;
    background-color: red;
}

.matrix-wrapper {
    .container-fluid {
        width: 100%;
        margin: 0;
        @media print {
            .header {
                position: fixed;
                top: 0;
                height: 200px;
            }
            .prod-row {
                page-break-inside: avoid;
                height: 190px;
            }
        }
        #header-table {
            margin-bottom: 20px;
            .print-header {
                td {
                    white-space: normal;
                    padding: 2px 20px;
                    font-size: 13px;
                    border-left: 1px solid $primary-color;
                }
                &:first-child td {
                    color: $primary-color;
                }
            }
        }
        .body {
            .prod-row {
                height: 65px;
                padding: 2px 0;
                font-size: 0.8rem;
                .quantity-wrapper {
                    align-self: end;
                    width: $cell-width !important;
                    max-width: $cell-width;
                }
                margin: 1rem 0;
                padding: 1rem 0.5rem;
                border-left: 1px solid white;
                border-bottom: 1px solid #f1f0f0;
                .prod-info {
                    min-width: 20rem;
                }
            }
        }
        thead {
            .description {
                font-weight: bolder;
                font-size: 0.9rem;
            }
            tr:last-child td {
                padding-bottom: 15px;
            }
        }
        .header {
            .pro-col {
                min-width: 20rem;
            }
            > div {
                display: flex;
            }
        }
    }
}
.pro-name {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 4px;
    .brand {
        color: $secondary-color;
    }
}
.info {
    width: 20%;
    margin-left: 10px;
}

th.store-quantity {
    line-break: anywhere;
    white-space: revert;
    cursor: pointer;
    &:hover {
        background-color: $secondary-color;
        span, i {
            color: white !important;
        }
    }
}
th.store-quantity {
    min-width: $cell-width;
}
p.store-quantity {
    width: 100%;
    height: 40px;
}

.logo {
    width: 130px !important;
}

.total {
    border-right: 1px dashed #ece9e9;
}

.total-val {
    white-space: nowrap;
}

thead td:first-child .description {
    font-weight: bolder;
    font-size: 0.9rem;
}
span.description {
    // width: fit-content;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 2px;
    margin-bottom: 2px;
    display: inline-block;
    text-align: center;
    color: $gray3;
}

.quantity-loader {
    position: absolute;
    width: 25%;
    top: -47px;
    left: 83%;
    height: fit-content !important;
}

.lower {
    background-color: $higher-green;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    opacity: 0.6;
    padding: 0.2rem;
}

.higher {
    color: white !important;
    border-radius: 6px;
    background-color: $lower-red;
    font-weight: 600;
    opacity: 0.6;
    padding: 0.2rem;
}

.fa-store-alt {
    margin-right: 4px;
}

.fa-info-circle {
    color: $secondary-color;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

.description.store-total {
    text-align: center;
    color: $ternary-color;
}

.obs {
    color: $secondary-color;
}

i.fa-handshake-o {
    width: 10px;
    padding-left: 4px;
    color: $primary-color;
    cursor: pointer;
}

.fa-edit {
    margin-left: 10px;
    color: $primary-color;
    cursor: pointer;
}

.pro-col {
    min-width: 450px;
}

.column-quantity{
    display: flex;
    justify-content: center;
    padding: 0 5px;
}
.column-stock{
    margin-left: 4px;
    align-items: center;
}
</style>
