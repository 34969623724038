<template>
    <div id="seller" class="col">
        <div class="list-group">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="user" @submit.prevent="handleSubmit( save )">
                    <div class="row py-3  d-flex justify-content-center">
                        <div class="col-md-3 col-lg-4">
                            <label>Nome:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="user.usu_nome"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Email:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="user.usu_email"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Login:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-model="user.usu_login"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row py-3 d-flex justify-content-center">
                        <div :class="{ 'col-md-2 col-lg-3' : user.profile.pfl_descricao == 'LOJA', 'col-md-3 col-lg-4' : user.profile.pfl_descricao !== 'LOJA' }">
                            <label>CPF:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input v-mask="'###.###.###-##'" type="text" v-model="user.usu_cpf"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div :class="{ 'col-md-2 col-lg-3' : user.profile.pfl_descricao == 'LOJA', 'col-md-3 col-lg-4' : user.profile.pfl_descricao !== 'LOJA' }">
                            <label>Perfil:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                                <cc-select-v2 :tracker="'pfl_id'"
                                    :text="'pfl_descricao'"
                                    v-model="user.profile"
                                    :options="profiles"
                                    @onselect="set_profile" />
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div v-if="user.profile.pfl_descricao == 'LOJA'" :class="{ 'col-md-2 col-lg-3' : user.profile.pfl_descricao == 'LOJA', 'col-md-3 col-lg-4' : user.profile.pfl_descricao !== 'LOJA' }">
                            <label>Perfil Secundário:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v" name="prof">
                                <cc-select-v2 :tracker="'value'"
                                    :text="'key'" v-model="user.secondary_profile"
                                    :options="secondary_profiles"
                                    @onselect="set_secondary_profile"></cc-select-v2>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div :class="{ 'col-md-2 col-lg-3' : user.profile.pfl_descricao == 'LOJA', 'col-md-3 col-lg-4' : user.profile.pfl_descricao !== 'LOJA' }">
                            <label>Empresa:</label>
                            <ValidationProvider rules="profile:@prof" v-slot="v">
                                <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" :empty="false" v-model="user.store" :options="stores" @input="set_store" :disabled="user.profile && user.profile.pfl_descricao == 'ADMINISTRADOR'"></cc-select-v2>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row py-3 d-flex justify-content-center">
                        <div class="col-md-3 col-lg-4">
                            <label>Telefone:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required" v-slot="v">
                                <cc-input type="text" v-mask="'(##) #.####-####'" v-model="user.usu_telefone"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Senha:<span style="color:red !important">*</span></label>
                            <ValidationProvider rules="required|password:@confirm" v-slot="v">
                                <cc-input type="password" v-model="user.usu_senha"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <label>Confirmar Senha:<span style="color:red !important">*</span></label>
                            <ValidationProvider name="confirm" rules="required" v-slot="v">
                                <cc-input type="password" v-model="user.password_confirmation"></cc-input>
                                <span class="error">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                </form>

            </ValidationObserver>

        </div>
    </div>
</template>

<script>
import UserService from "@/services/v1/user.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';


export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
		ValidationObserver
    },
    props: [ "user", "profiles", "stores", "secondary_profiles" ],
    data() {
        return {
            svc: new UserService()
        }
    },
    methods: {
        async set_store(){
			this.$set(this.current_user, 'store', this.current_user.store)
			this.$forceUpdate()
		},
        set_secondary_profile(profile) {
			this.$set(this.current_user, 'secondary_profile', profile)
			this.$forceUpdate()
		},
		set_profile(profile) {
			this.$set(this.current_user, 'profile', profile)
            this.current_user = { ...this.current_user, profile }
			if(profile && profile.pfl_descricao == 'ADMINISTRADOR') {
				this.$set(this.current_user, 'store', null)
                this.current_user = { ...this.current_user, store: null }
			}
            this.$forceUpdate()
		}
    }
}

</script>

