import http from "../http";
import Service from "../../Service";

/**
 * @type SysConfService
 */
export default class ProductGroupService extends Service {

	loadProductGroups(productId){
        
        return http.get('clients/grupo/produto/',{
            headers: this.getHeaders(),
            params: {"pro_id": productId}
        })
    }
    createProductGroups(name){
        return http.post('clients/grupo/produto/create',{descricao:name},{headers: this.getHeaders()})
    }
    updateGroup(newName, id){
        return http.put('clients/grupo/produto/edit/'+id,{descricao:newName},{headers: this.getHeaders()})
    }
    addProduct(productId, groupId){
        return http.post('clients/grupo/produto/create/item',{
            "id_grupo" : groupId,
            "pro_id": productId
        }, {headers: this.getHeaders()})
    }
    deleteProduct(id){
        return http.delete('clients/grupo/produto/item/'+id+'/remove', {headers: this.getHeaders()})
    }
    saveUserGroup(payload){
        return http.post('clients/grupo/produto/usuario', payload, {headers: this.getHeaders()})
    }
    deleteUserGroup(id){
        return http.delete('clients/grupo/produto/usuario/'+id, {headers: this.getHeaders()})
    }
    getGroupProducts(id,page,product){
        console.log(product);
        return http.get('clients/grupo/produto/grupo/'+id, {headers: this.getHeaders(), params:{page, 'pro_descricao':product }})
    }
}