<template>
    <div class="modal-mask" @click="close">
        <div class="modal-content-container" @click.stop>
            <div class="edit-brands-header-container">
                <span class="edit-brands-header-title">
                    Editar Código interno
                </span>
                <span @click="close" class="material-icons edit-brands-closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="edit-brands-body">
                <p class="edit-brands-text">{{ brand.pro_observacao }}</p>
                <input type="number" v-model="code" class="edit-brands-input" placeholder="Insira o novo código">
                <StandardButton text="Salvar" :action="save" iconName="save" class="edit-brands-save-button"/>
            </div>

        </div>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'EditBrandModal',
    components: { StandardButton },
    data(){
        return {
            code: undefined,
            isLoading: false
        }
    },
    props:['brand', 'close', 'update'],
    created(){
        if (this.brand.pro_codInterno) this.code = this.brand.pro_codInterno
    },
    methods:{
        save () {
            this.isLoading = true
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            let data = {
                'pro_codInterno': this.code,
                // generico: '0',
                // 'pro_pedido_direto': '',
                // descricao: this.brand.pro_descricao,
                // embalagem: this.brand.pro_embalagem,
                // pro_id: this.brand.pro_id,
                // codEan: this.brand.pro_ean,
                // pro_status: this.brand.pro_status,
                // lojas_sugestao: [],
                // unidade_compra: this.brand.pro_ultimaCompra
            }
            axios.put(`${CONSTANTS.API_V3_URL}/clients/products/${this.brand.pro_id}`,data,config).then(()=>{
                this.isLoading = false
                this.update()
            }).catch(error => {
                this.$swal.fire(
                    "Desculpe.",
                    error.response.data.mensagem,
                    "error"
                );
                this.isLoading = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    min-width: 50vw;
    border-radius: 12px;
    padding-bottom: 3vh;
    margin-top: 40px;
}

.edit-brands-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vw;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.edit-brands-header-title {
    color: #ffffff;
    letter-spacing: -2%;
    font-weight: 500;
    font-size: 32px;
}

.edit-brands-closebtn-icon {
    color: #FFFFFF;
    font-size: 3.5em;
    cursor: pointer;
}

.edit-brands-body {
    padding: 4vh 2vw;
}
.edit-brands-save-button{
    width: 40%;
    margin-left: auto;
    margin-top: 3vh;
}
.edit-brands-input{
    width: 100%;
    color: #a1a1a1;
    font-weight: 400;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 1vw;
}
.edit-brands-text{
    color: #898989;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 0.15px;
}
</style>