<template>
    <router-link :to="link || ''" v-if="!nolink">
        <button :disabled="disabled == true" class="justify-content-around w-100 px-4" :class="'fixed'" v-on="$listeners" v-bind="$attrs">
            <span v-if="content"><i  :class="'fas fa-chevron-left'"></i>{{ content }}</span>
            
        </button>
    </router-link>
    <button :disabled="disabled == true" class="justify-content-around w-100 px-4" :class="'fixed'" v-on="$listeners" v-bind="$attrs" v-else>
        <span v-if="content"><i  :class="'fas fa-chevron-left'"></i>{{ content }}</span>
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ["content", "classes", "icon", "link", "disabled", "nolink" ],
    methods: {
        execute_on_click() {
            this.$emit('click');
        }
    }
};
</script>
<style lang="scss" scoped>

@import "@/sass/commons/_variables";

.fa-chevron-left {
    color: white !important;

}
a {
    width: fit-content;
    height: fit-content;
}
button {
    height: fit-content;
    padding: 15px 15px;
    background: transparent;
    color: $gray3;
    border: 1px solid #e8e9ec;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.9rem;
    color:white;

    width: 140px !important;
    margin-right: 25px;
    background: #ff0000b0;

    

    &.fixed {
        height: 3rem;
        width: 12rem;
    }

    i {
        color: white;
        font-size: 1rem;
    }

    span i {
        margin-right: 0.6rem;
        color:white;
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        border: 1px solid $disabled-gray;
        color: $disabled-gray;
        filter: opacity(0.5);
        cursor: not-allowed;
        i {
            color: white;
        }
    }
/*
	&:hover {
        color: white;
        background-color: $primary-color;
        border: 1px solid $primary-color;
        i {
            color: white;
        }
        &[disabled] {
            border: 1px solid $disabled-gray;
            color: $disabled-gray;
            cursor: not-allowed;
            i {
                color: $disabled-gray;
            }
        }
    }
    */

    &.info {
        border: 1px solid #e8e9ec;
        &:hover {
            border: 1px solid #e8e9ec;
        }
    }

    &.danger-outline {
        border: 1px solid $brand-danger;
        color: $brand-danger;
        i {
            color: $brand-danger;
        }
        &:hover {
            background-color: $brand-danger;
            color: white;
            border: 1px solid $brand-danger;
            i {
                color: white;
            }
        }
    }

    &.danger {
        border: 1px solid $brand-danger;
        background-color: $brand-danger;
        color: white;
        i {
            color: white;
        }
    }

    &.freeze {
        color: #47c1fb;
        i {
            color: #47c1fb;
        }
        &:hover {
            background-color: #47c1fb;
            color: white !important;
            border: 1px solid #47c1fb;
            i {
                color: white;
            }
        }
    }

    &.success {
        background-color: white;
        color: $brand-success;
        border: 1px solid $brand-success;
        i {
            color: $brand-success;
        }
        &:hover {
            color: white;
            background-color: $brand-success;
            border: 1px solid $brand-success;
            i {
                color: white;
            }
        }
    }

    &.fill {
        &.default {
            color: white;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            i {
                color: white;
            }
            &[disabled] {
                border: 1px solid $disabled-gray;
                color: $disabled-gray;
                cursor: not-allowed;
                i {
                    color: $disabled-gray;
                }
            }
        }
        &.success {
            background-color: $brand-success;
            color: white;
            border: 1px solid $brand-success;
            i {
                color: white;
            }
            &:hover {
                color: $brand-success;
                background-color: white;
                border: 1px solid $brand-success;
                i {
                    color: $brand-success;
                }
            }
        }
        &.danger-outline {
            background-color: $brand-danger;
            color: white;
            border: 1px solid $brand-danger;
            i {
                color: white;
            }
            &:hover {
                color: $brand-danger;
                background-color: white;
                border: 1px solid $brand-danger;
                i {
                    color: $brand-danger;
                }
            }
        }

        &.danger-outline-no-hover {
            color: white;
            background-color: $brand-danger;
            border: 1px solid $brand-danger;
        }
    }

    &.secondary {
        background-color: white;
        color: $blue1;
        i {
            color: $blue1;
        }
        &:hover {
            color: white;
            background-color: $blue1;
            border: 1px solid white;
            i {
                color: white;
            }
        }
    }

    &.theme-1 {
        color: $primary-color;
        i {
            color: $primary-color;
        }
        &:hover {
            color: white;
            background-color: $primary-color;
            i {
                color: white;
            }
        }
    }

    &.theme-2 {
        background-color: white;
        color: $brand-success;
        i {
            color: $brand-success;
        }
        &:hover {
            color: white;
            background-color: $brand-success;
            border: 1px solid $brand-success;
            i {
                color: white;
            }
        }
    }

    &.theme-3 {
        color: white;
        background-color: $brand-success;
        border: 1px solid $brand-success;

    }

    &.active {
        color: $primary-color;
        border: 1px solid $primary-color;
        i {
            color: $primary-color;
        }
        &:hover {
            color: white;
            background-color: $primary-color;
            i {
                color: white;
            }
        }
    }
}

</style>
