import http from "../http";
import Service from "../../Service";

/**
 * @type OfferService
 */
export default class OfferService extends Service {
    update(offer) {
        return http.put(`/v2/clients/requests/offers/${offer.id}`, offer, {
            headers: this.getHeaders(),
        });
    }

    load(id, params) {
        return http.get(`/v2/clients/requests/${id}/offers`, {
            headers: this.getHeaders(),
            params,
        });
    }

    last_offers(request_id, seller_id) {
        return http.get(`/clients/products/offers/last`, {
            headers: this.getHeaders(),
            params: {
                id: request_id,
                seller_id,
            },
        });
    }

    create(offer) {
        return http.post(`/clients/requests/offers`, offer, {
            headers: this.getHeaders(),
        });
    }
}
