<template>
    <div class="add-obs-container">
        <div class="add-obs-modal">
            <header class="add-obs-header">
                <h1 class="add-obs-header-text">Adicionar observação</h1>
                <span @click="close" class="material-icons-outlined add-obs-close">cancel</span>
            </header>

            <body class="add-obs-body">
                <input type="text" v-model="inputText" placeholder="Insira sua observação aqui." class="add-obs-input" />
                <div class="add-obs-buttons-container">
                    <button @click="close" class="add-obs-button add-obs-cancel" :text="''">Voltar</button>
                    <button @click="save" class="add-obs-button add-obs-save" :text="''">Salvar</button>
                </div>
            </body>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AddObsModal',
    props: ['close', 'product', 'action'],
    data() {
        return {
            inputText: ''
        }
    },
    mounted() {
        if (this.product.observacao) this.inputText = this.product.observacao
    },
    methods: {
        save() {
            this.product.observacao = this.inputText
            this.close();
            this.action(this.product)
            if (!this.product.last_offer && !this.product.saved) {
                setTimeout(() => {
                    this.action(this.product)
                }, 1000);
            }

        }
    }
}
</script>

<style scoped lang="scss">
.add-obs-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.add-obs-modal {
    width: 50vw;
}

.add-obs-header {
    background-color: var(--primary-color);
    border-radius: 10px 10px 0 0;
    padding: 1.5vh 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-obs-header-text {
    font-size: 2em;
    color: white;

}

.add-obs-close {
    color: white;
    cursor: pointer;
    font-size: 3em;
}

.add-obs-body {
    height: 100%;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 3vh 2vw;
}

.add-obs-input {
    border: 2px solid gray;
    width: 100%;
    color: gray;
    font-size: 1.5em;
    padding: 1vh 2vw;
    border-radius: 5px;
}

.add-obs-input:focus {
    outline: none;
}

.add-obs-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10vh;
}

.add-obs-button {
    width: 45%;
}

.add-obs-cancel {
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background: #FFF;
    color: var(--primary-color);
    font-size: 1.8em;
    padding: 0.5vh 0;
}

.add-obs-save {
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: white;
    font-size: 1.8em;
    padding: 0.5vh 0;
}</style>