<template>
    <b-skeleton-wrapper :loading="loading">
        <template #loading>
            <b-card>
                <b-skeleton width="100%" height="100px"></b-skeleton>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="100%"></b-skeleton>
            </b-card>
        </template>
        <b-card
            v-if="item && item.product"
            :img-src="item.product.cover_url"
            :img-alt="item.name"
            img-to
            tag="article"
            class="mb-2 shadow"
        >
            <b-card-body class="text-left p-0">
                <span class="wrapper" v-if="!cart_controls">
                    <span class="finishes-at shadow">
                        <span class="time">Termina em: <br> {{ item.countdown }}</span>
                    </span>
                </span>
                <b-card-title>
                    {{ item.product.name }}
                    <br>
                    <small class="obs">{{ item.obs }}</small>
                </b-card-title>
                <b-card-text class="text-uppercase mb-1">{{ item.name }}</b-card-text>
                <b-card-text class="text-uppercase pt-1 mb-0 text-center provider-name">
                    <i class="fa fa-users" />
                    {{ campaign.provider.name }}
                </b-card-text>
                <b-row no-gutters class="pt-1">
                    <b-col md="12" class="text-center" v-if="cart_controls">
                        {{ cart_item_for(item).total | currency }}
                    </b-col>
                    <b-col md="4" class="cart-item-control-button remove" v-if="cart_controls">
                        <b-button
                            href="#"
                            @click="remove_item(item)">-</b-button>
                    </b-col>
                    <b-col md="4" class="text-center" v-if="cart_controls">
                        <cc-input
                            @input="(quantity) => update_quantity({ item, quantity })"
                            :classes="'text-center'"
                            type="number"
                            min="0"
                            :value="cart_item_for(item).quantity" />
                    </b-col>
                    <b-col md="4" class="cart-item-control-button add" v-if="cart_controls">
                        <b-button
                            href="#"
                            @click="add_item(item)">+</b-button>
                    </b-col>
                    <b-col md="12" class="text-center p-1 wrap-desc wrap mt-2">
                        {{ item.wrap_price | currency }} - {{ item.wrap }} {{ item.wrap != 'UN' ? `c/ ${item.wrap_quantity} UN` : '' }}
                    </b-col>
                    <b-col md="12" class="text-center p-1 wrap-desc unit">
                        {{ item.unit_price | currency }} / UN
                    </b-col>
                    <b-col md="12" class="cart-item-control-button mt-2" v-if="!cart_controls">
                        <cc-button
                            :icon="'fa fa-shopping-bag'"
                            :nolink="true"
                            :content="'Ver Ofertas'"
                            @click="open_campaign"
                            :classes="'success fill'" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </b-skeleton-wrapper>
</template>
<script>

import CampaignService from "@/services/v3/campaigns/campaign.service"
import OrderService from "@/services/v3/campaigns/order.service";
import StoreService from "@/services/v3/stores/store.service"
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
    mixins: [ perm_mixin, loaderMixin ],
    props: {
        item: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: true
        },
        cart_controls: {
            type: Boolean,
            default: false
        }
    },
  	data() {
		return {
            VIEW: 'STORE_SELECT',
            stores: [],
			page: 1,
            store: null,
			total_items : 0,
			items_by_page_value: 10,
            store_svc: new StoreService(),
			svc: new CampaignService(),
            order_service: new OrderService(),
            campaign: {
                provider: {},
                items: [
                    {},
                    {},
                    {},
                    {}
                ]
            },
			modal: {
				title: "...",
				icon_title: 'fas fa-cart-plus',
				cancel_text: 'Voltar',
                nobackbutton: true,
				style: {
					width: "80%",
                    height: "95%"
				}
			},
		};
	},
    computed: {
        ...mapState([ 'cart' ]),
        ...mapGetters('cart', [ 'cart_item_for', 'total', 'cart_request' ])
    },
  	methods: {
        ...mapActions('cart', [
            'set_campaign', 'add_item',
            'remove_item', 'update_quantity',
            'set_store', 'reset'
        ]),
        print() {
            self.print()
        },
        open_campaign() {
            this.$emit('open_campaign', this.campaign)
        },
        send_order() {
            this.order_service.send_order(this.cart_request).then(response => response.data).then(data => {
                this.present_info('Pedido enviado com sucesso!')
                this.reset()
                this.store = null
                this.$emit("close");
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
                let msg = "Ops! Ocorreu um erro ao enviar o pedido!"
                if(err.response && err.response.data.errors) {
                    msg = err.response.data.errors && err.response.data.errors[0]
                }
                const notification = {
                    type: 'error',
                    message: msg
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        select_store() {
            if(this.store) {
                this.set_store(this.store)
                this.VIEW = 'PRODUCTS'
            }
        },
        close() {
            if(this.VIEW == 'CONFIRMATION') {
                this.VIEW = 'PRODUCTS'
            } else if(this.VIEW == 'PRODUCTS') {
                this.VIEW = 'STORE_SELECT'
            } else {
                this.$emit("close");
            }
		},
        confirm_order() {
            this.VIEW = 'CONFIRMATION'
        },
        countdown() {
            if(!this.cart_controls) {
                setInterval(() => {
                    if(this.campaign && !this.cart_controls) {
                        let date = new Date(this.campaign.ends_at)
                        var now = new Date().getTime();
                        var timeleft = date.getTime() - now;
                        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                        let text = `${hours}:${minutes}:${seconds}`
                        if(days > 0) {
                            text = days > 1 ? `${days} dias ${text}h` : `${days} dia ${text}h`
                        }
                        this.item.countdown = text
                        this.$forceUpdate()
                    }
                }, 1000)
            }
        }
	},
    mounted() {
        this.campaign = this.item.campaign
        this.countdown()
    }
};
</script>
<style lang="scss" scoped>
    @import "@/sass/commons/_variables";
    @import "@/sass/commons/_tables";


    /deep/ .modal-body {
        height: 90vh !important;
        max-height: 95vh !important;
    }
    .card-title {
        font-size: 1.2rem;
        height: 66px;
        color: #605f5f;
        padding-top: 10px;
        i {
            color: $secondary-color;
        }
        .obs {
            color: $secondary-color;
            font-size: 0.8rem;
        }
    }

    .card-img {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
    }

    .wrap-desc {
        border: 1px solid $primary-color;
        &.wrap {
            border-radius: 15px 15px 0 0;
            border-bottom: 0px;
            color: $primary-color;
        }
        &.unit {
            border-radius:  0 0 15px 15px;
            background-color: $primary-color;
            color: white;
        }
    }

    .card-body {
        padding-bottom: 5px !important;
    }

    .card-img {
        height: 200px;
        border-radius: 15px 15px 0 0;
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        color: $secondary-color;
        text-transform: uppercase;
        font-weight: bolder;
    }

    .wrap-desc {
        color: $gray2;
    }

    .img {
        width: 175px;
        height: 75px;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .cart-item-control-button {
        display: flex;
        align-items: center;
        a {
            font-size: 1.2rem;
            border: 1px solid white;
            padding: 0 0.7rem;
            outline: none;
            &:hover {
                border: 1px solid gray;
            }
        }
        &.add {
            justify-content: left;
            padding-left: 15px;
            a {
                background-color: $ternary-color;
            }
        }
        &.remove {
            justify-content: right;
            padding-right: 15px;
            a {
                background-color: $lower-red;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 10rem !important;
        }
    }

    .resume {
        display: flex;
        padding: 10px;
        .resume-item {
            margin-right: 10px;
            white-space: nowrap;
            font-size: 1.6rem;
            color: $gray3;
            display: flex;
            align-items: center;
            i {
                margin-right: 10px;
            }
            .fa-coins {
                color: orange;
            }
            .fa-boxes {
                color: $ternary-color;
            }
        }
    }

    .provider-name {
        color: $primary-color;
    }

    .card {
        border-radius: 15px !important;
        cursor: pointer;
        width: 95%;
        &:hover {
            box-shadow: 0 2px 5px 0 #ffa50052, 0 2px 10px 0 #ffa50052 !important;
        }
    }

    .wrapper {
        width: 0;
        height: 0;
        position: absolute;
        .finishes-at {
            display: block;
            text-align: center;
            color: white;
            padding: 5px 10px;
            width: 180px;
            border-radius: 15px;
            font-weight: bolder;
            position: relative;
            bottom: 230px;
            right: 60px;
            background: rgb(6,134,238);
            background: linear-gradient(148deg, rgba(6,134,238,1) 40%, rgba(86,191,255,1) 72%, rgba(143,205,252,1) 93%);
            .time {
                white-space: nowrap;
            }
        }
    }

</style>
