const state = {
    data: {
        "colors": {
            "primary": null,
            "secondary": null,
            "textWhite": null,
            "textPrimary": null,
            "textSecondary": null,
            "textTertiary": null,
            "background": null,
            "backgroundWhite": null,
            "white": null,
            "danger": null,
            "gray-100": null,
            "gray-300": null,
            "gray-400": null,
            "gray-500": null,
            "gray-600": null,
            "gray-700": null,
            "gray-800": null,
            "gray-900": null,
            "green-300": null,
            "green-500": null,
            "green-700": null,
            "red-500": null,
            "red-700": null,
            "yellow-500": null,
            "gradient": {
                "primary": null
            }
        },
        "providers": {
            "id": null,
            "user_master": null,
            "cnpj": null,
            "revenda": null,
            "active_cpf": null,
            "text_window": null,
            "active_catalog": null,
            "loginURL": ''
        },
        "imgs": {
            "img_window": null,
            "img_loading": null,
            "img_background": null,
            "img_logo": null
        },
        "breakpoints": {
            "mobile": null
        },
        "header": {
            "backgroundColor": null,
            "iconColor": null,
            "iconActiveColor": null,
            "textColor": null
        }
    }
};

const mutations = {
    SET_DADOS(state, data) {
        state.data = data;
    }
};


const actions = {
    setTheme({ commit }, theme) {
        try {
            commit('SET_DADOS', theme);
            localStorage.setItem('whitelabelTheme', JSON.stringify(theme));
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    },
    fetchDados({ commit }) {
        const dados = localStorage.getItem('whitelabelTheme');
        if (dados) {
            commit('SET_DADOS', JSON.parse(dados));
        }
    }
    // setTheme({ commit }, theme) {
    //     try {
    //         commit('SET_DADOS', theme);
    //         localStorage.setItem('whitelabelTheme', JSON.stringify(dados));
    //     } catch (error) {
    //         console.error('Erro ao buscar dados:', error);
    //     }
    // }
};

export default {
    state,
    mutations,
    actions
};
