<template>
    <div class="manager-matrix-container">
        <div class="manage-matrix-row manage-matrix-header">
            <div class="manage-matrix-expand-width"></div>
            <h5 class="manage-matrix-product-width">Produto:</h5>
            <h5 class="manage-matrix-colum-width">Qtd:</h5>
            <h5 class="manage-matrix-colum-width">Emb:</h5>
            <h5 class="manage-matrix-colum-width">Total:</h5>
            <h5 class="manage-matrix-colum-width">Ult Cmp:</h5>
            <h5 class="manage-matrix-colum-width">Vlr:</h5>
            <h5 class="manage-matrix-colum-width">Diferença:</h5>
            <h5 class="manage-matrix-colum-width">Variação:</h5>
        </div>
        <div v-for="(product, idx) in products" :key="product.pro_id">
            <div class="manage-matrix-row manage-matrix-body-row">
                <div class="manage-matrix-expand-width" @click="toggle_collapse(product)">
                    <span v-if="product.expanded" class="material-icons-outlined expand-icon">expand_less</span>
                    <span v-else class="material-icons-outlined  expand-icon">expand_more</span>
                </div>
                <div class="manage-matrix-product-width">
                    <h5 class="manage-matrix-product-name">{{ product.pro_descricao }}</h5>
                    <p class="manage-matrix-obs"> {{ product.pro_observacao }}</p>
                </div>
                <p class="manage-matrix-colum-width">
                    <span class="mobile">Qtd:</span>
                    {{ product.quantidade }}
                </p>
                <p class="manage-matrix-colum-width"><span class="mobile">Emb:</span>{{ product.pro_unidade_compra ?
                    product.pro_unidade_compra :
                    product.pro_embalagem }}</p>
                <p class="manage-matrix-colum-width"><span class="mobile">Total:</span> {{ (product.valor * product.quantidade)*( product.pro_generico == 1 ? 1 : parseFloat(product.pro_embalagem.split(" ")[1])) | currency
                }} </p>
                <p class="manage-matrix-colum-width">
                    <span class="mobile">Ult Cmp:</span>
                    <span v-show="product.preco_ultima_compra" @click="show_orders(product)" title="Histórico de Pedidos"
                        class="material-icons-outlined makeorder-page-table-uniprice-icon">assignment</span>
                    {{ product.preco_ultima_compra | currency }}
                </p>
                <p class="manage-matrix-colum-width">
                    <span class="mobile">Vlr:</span>
                    <i v-if="!has_orders" class="fa fa-handshake-o" title="Negociar"
                        @click="open_negotiation_modal(product)"></i>
                    {{ product.valor | currency }}
                </p>
                <p class="manage-matrix-colum-width"><span class="mobile">Diferença:</span> {{ getDiff(product) | currency }} </p>
                <p :class="getDiffColor(getDiff(product))" class="manage-matrix-colum-width"><span class="mobile">Variação:</span> {{
                    getVar(product) | currency
                }}</p>
            </div>
            <div v-if="product.expanded" class="makeorder-page-table-expand">
                <cc-loader v-if="product.is_loading_typings" />
                <div v-else>
                    <div class="makeorder-page-table-expand-title">
                        <div class="makeorder-page-table-expand-colum-name">Fornecedor</div>
                        <div class="makeorder-page-table-expand-colum-name">Observação</div>
                        <div class="makeorder-page-table-expand-colum">Marcas</div>
                        <div class="makeorder-page-table-expand-colum">QTD. EMB</div>
                        <!-- <div class="makeorder-page-table-expand-colum">QTD</div> -->
                        <div class="makeorder-page-table-expand-colum">Valor Unitário</div>
                        <!-- <div class="makeorder-page-table-expand-colum">Total Item</div> -->
                        <div class="makeorder-page-table-expand-colum">Fat. Min</div>
                    </div>
                    <div class="makeorder-page-table-expand-row" v-for="(typing, idx2) in product.digitacoes"
                        :class="{ 'page-table-line-special': idx2 % 2 != 0 }" :key="idx2">

                        <div class="makeorder-page-table-expand-colum-name">
                            {{ typing.nomeFornecedor }}
                            <!-- <div style="display: flex; gap: 0.5vw; align-items: center;" v-if="typing.observacao">
                                <div
                                    style="color: var(--primary-color); background-color: lightyellow; font-size: 0.8em; padding: 0 3px; border: 1px solid var(--primary-color); border-radius: 5px;">
                                    Obs:</div>
                                <div :title="typing.observacao" class="makeorder-page-sub-obs"></div>
                            </div> -->
                        </div>
                        <div class="makeorder-page-table-expand-colum-name" :style="getObsColor(typing.observacao)">
                            {{ typing.observacao ? typing.observacao : '--' }}
                        </div>
                        <div class="makeorder-page-table-expand-colum mobile-obs-line">
                            {{ typing.pro_observacao ? typing.pro_observacao : '--' }}
                        </div>

                        <!-- desktop -->
                        <div class="makeorder-page-table-expand-colum desktop">
                            <div class="makeorder-page-table-mobile-header">QTD. EMB</div>
                            {{ typing.embalagem }}
                        </div>

                        <!-- desktop -->
                        <div class="makeorder-page-table-expand-colum desktop">
                            <div class="makeorder-page-table-mobile-header">Valor Unitário</div>
                            {{ typing.valor | currency }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile makeorder-mobile-row" style="align-items: flex-start; margin: 0.5vh 0;">
                            <div style="width: 30%;">
                                <div class="makeorder-page-table-mobile-header">QTD. EMB</div>
                                {{ typing.embalagem }}
                            </div>

                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <div class="makeorder-page-table-mobile-header">Valor Unitário</div>
                                {{ typing.valor | currency }}
                            </div>

                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <div class="makeorder-page-table-mobile-header">Fat. Min</div>
                                {{ typing.faturamento_minimo | currency }}
                            </div>

                        </div>



                        <!-- desktop -->
                        <div class="makeorder-page-table-expand-colum desktop">
                            <div class="makeorder-page-table-mobile-header">Fat. Min</div>
                            {{ typing.faturamento_minimo | currency }}
                        </div>



                    </div>
                </div>

            </div>

        </div>
        <ProductOrdersModal :product_id="current_product.pro_id" :product_name="current_product.pro_descricao"
            v-if="is_product_orders_modal_open" @close="is_product_orders_modal_open = false" />
    </div>
</template>

<script>
import ProductOrdersModal from '@/modals/products/orders';
import QuotationService from "@/services/QuotationService";

export default {
    name: 'priceManagerMatrix',
    props: ['products', 'getDiff', 'open_negotiation_modal', 'subrequest'],
    components: {
        ProductOrdersModal
    },
    data() {
        return {
            quotationService: new QuotationService(),
            has_orders: false,
            cliTipoPedido: localStorage.getItem('cli_tipoPedido'),
            current_product: undefined,
            is_product_orders_modal_open: false
        }
    },
    methods: {
        getCheckBoxStyle(value) { if (value) return 'color: lightgray; cursor: default' },
        getCheckBoxTitle(value) { if (value) return 'Pedido ja realizado para esse fornecedor.' },
        getObsColor(value) { if (value) return 'color: lightcoral;' },
        getDiffColor(diff) {
            if (diff > 0) return 'diff-up'
            else if (diff < 0) return 'diff-down'
        },
        getVar(produto) {
            if (!produto.pro_principal && this.cliTipoPedido == 0) return (this.getDiff(produto) * produto.pro_embalagem.split(' ')[1]) * produto.quantidade_sugestao
            return this.getDiff(produto) * produto.quantidade
        },
        show_orders(product) {
            this.current_product = product
            this.is_product_orders_modal_open = true
        },
        load_has_orders() {
            this.has_orders = this.subrequest.id_pedido
        },
        toggle_collapse(product, force_reload = false) {
            this.$set(product, "expanded", !product.expanded || force_reload);
            if (!product.digitacoes || force_reload) {
                this.$set(product, "is_loading_typings", true);
                this.load_offers(product, force_reload).then(data => {
                    this.$set(product, "is_loading_typings", false);
                    this.$set(product, "digitacoes", data.digitacoes);

                });
            }
        },
        toggle_winner(typing, product, sellerId = this.$route.params.sellerid, prod_id, will_be_winner = !this.is_winner(product)) {

            if (!!this.subrequest.id_pedido || typing.qtdPedido > 0) return

            if (product.quantidade > 0 || typing.vencedor_quantidade > 0) {
                this.$set(typing, 'loading', true);
                this.quotationService
                    .toggleQuotationWinner(this.$route.params.id, this.$route.params.sid, prod_id, sellerId, will_be_winner)
                    .then(response => response.data)
                    .then(() => {
                        this.quotationService.getDigitacaesProdutoComparativo(
                            this.$route.params.id,
                            this.$route.params.sid,
                            product.pro_id
                        ).then(response => response.data).then(data => {
                            this.$set(product, "digitacoes", data.digitacoes)
                            this.$set(typing, 'loading', false)
                            if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                                if (!will_be_winner) {
                                    this.$set(product, 'new_amount', 0)
                                    this.$set(product, 'quantidade_sugestao', 0)
                                } else {
                                    this.$set(product, 'quantidade_sugestao', product.quantidade)
                                    this.$set(product, 'new_amount', product.quantidade)
                                }
                            }
                            this.$emit("reload_provider");
                        })
                    })
            } else {
                this.quotationService
                    .toggleQuotationWinner(this.$route.params.id, this.$route.params.sid, prod_id, sellerId, will_be_winner)
                    .then(() => console.log("updating winner"))
                this.$set(typing, 'vencedor_quantidade', typing.vencedor_quantidade > 0 ? 0 : 1)
            };
        },
        load_offers(product) {
            return this.quotationService.getDigitacaesProdutoComparativo(
                this.$route.params.id,
                this.$route.params.sid,
                product.pro_id
            ).then(response => response.data)
        },
        update_quantity(product, callback) {
            if (!product.new_amount) product.new_amount = "0";
            let payload = {
                numerocotacao: this.$route.params.id,
                quantidade: product.new_amount,
                vendedor: product.idVendedor
                    ? product.idVendedor
                    : this.$route.params.sellerid,
                produto: product.idProduto,
                loja: this.$route.params.sid
            };
            this.$set(product, "new_amount", payload.quantidade);
            let productLoading = product.is_loading;
            this.$set(product, "is_loading", true);
            return this.quotationService
                .update_quantity(payload)
                .then(response => response.data)
                .then(data => {
                    if (!productLoading) {
                        product.is_loading = false;
                    }
                    this.$set(product, "quantidade_sugestao", payload.quantidade);
                    callback();
                    // this.reload_subrequest();
                }, err => {
                    product.is_loading = false;
                })
        },
        process_event_typing: function (e, typing, product) {
            e.preventDefault();
            let key = getKey(e);
            let new_amount = parseFloat(e.target.value);
            let idt = parseFloat(e.target.dataset.idt);
            let inputs = this.$refs["dig-en-" + product.idProduto];
            typing.new_amount = new_amount;
            if (typing.new_amount != typing.quantidade) typing.is_altered = true
            typing.idProduto = typing.pro_id;
            function getNext() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].dataset.idt == idt) {
                        if (i == inputs.length - 1) {
                            result = inputs[0];
                        } else {
                            result = inputs[i + 1];
                        }
                        break;
                    }
                }
                return result;
            }
            function getPrev() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].dataset.idt == idt) {
                        if (i == 0) {
                            result = inputs[inputs.length - 1];
                        } else {
                            result = inputs[i - 1];
                        }
                        break;
                    }
                }
                return result;
            }
            function getCurrent() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].dataset.idt == idt) {
                        result = inputs[i];
                        break;
                    }
                }
                return result;
            }
            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                if (e.keyCode === 16) return "Shift";
                if (e.keyCode === 8) return "Tab";
                // etc
            }
            if (key == "Enter" || key == "ArrowDown" || key == "ArrowUp" || e.type == "blur") {
                this.update_quantity(typing, () => {
                    this.$set(typing, "quantidade", typing.new_amount);
                    if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                        this.$set(product, "new_amount", typing.new_amount);
                        this.$set(product, "quantidade_sugestao", typing.new_amount);
                    }
                    this.$emit("reload_provider");
                    // this.load_offers(product).then(data => {
                    //     this.$set(product, "digitacoes", data.digitacoes);
                    // });

                    if (e.type !== "blur")
                        Vue.nextTick(() => {
                            if (key == "ArrowDown") {
                                getNext().focus();
                                getNext().select()
                            } else if (key == "ArrowUp") {
                                getPrev().focus();
                                getPrev().select();
                            } else {
                                getCurrent().focus();
                                getCurrent().select();
                            }
                        });
                });
            }
        },
    },
    created(){
        this.load_has_orders();
    }

}
</script>

<style lang="scss" scoped>
.manager-matrix-container {
    margin-top: 5vh;
}

.manage-matrix-row {
    display: flex;
    align-items: center;
}

.manage-matrix-product-width {
    width: 25%;
}

.manage-matrix-expand-width {
    width: 5%;
}

.manage-matrix-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    font-size: 1.5vw;
    color: #605F5F;
    align-items: center;
    padding: 1vh 0;
}

p {
    margin: 0;
}


.manage-matrix-body {
    display: flex;
    font-weight: 400;
    align-items: center;
    width: 75%;
    padding: 0 2vw;
}

.manage-matrix-product-name {
    font-size: 1rem;
    color: #605F5F;
    font-weight: 600;
    margin-bottom: 0.5vh;
}

.manage-matrix-obs {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0;
}

.manage-matrix-colum-width {
    flex: 1;
}

.manage-matrix-body-row {
    padding: 1.5vh 0;
    border-bottom: 1px solid #E5E5E5;
}

.diff-up {
    color: lightcoral;
    font-weight: bold;
}

.fa-handshake-o {
    width: 10px;
    padding-right: 2vw;
    color: var(--primary-color);
    cursor: pointer;
}

.diff-down {
    font-weight: bold;
    color: lightgreen;
}

.makeorder-page-table-uniprice-icon {
    color: #4267B2;
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}

.makeorder-page-table-expand {
    margin-left: 1.5vw;
}

.makeorder-page-table-expand-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.1em;
    color: #A1A1A1;
    margin-bottom: 2vh;
}

.makeorder-page-table-expand-row {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.05em;
    color: #A1A1A1;
    padding: 1vh 1vw;
}

.makeorder-page-table-expand-colum {
    width: 14%;
}

.makeorder-page-table-expand-colum-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.makeorder-page-table-expand-check {
    color: var(--primary-color);
    cursor: pointer;
    margin-right: 1.5vw;
}

.mobile {
    display: none;
}

.makeorder-page-table-mobile-header {
    display: none;
}

.makeorder-page-table-orders-input {
    text-align: left;
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding-left: 1vw;
    width: 50%;
}

.makeorder-page-table-orders-input:focus {
    background-color: lightsalmon;
}

.makeorder-page-table-orders-input-disabled {
    background-color: lightgray;
    border: 1px solid #505050;
    cursor: default;
}

.input-altered {
    background-color: #13c7213d;
    border: 1px solid #0606064d !important;
}

.expand-icon {
    color: var(--primary-color);
    margin-left: 20%;
    font-size: 3em;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .table-product-name {
        margin-left: 0;
        font-size: 1.2em;
    }

    .makeorder-page-table-header {
        display: none;
    }

    .makeorder-page-table-name-content {
        gap: 1vh;
    }

    .manage-matrix-body-row {
        flex-direction: column;
        font-size: 1.2em;
        gap: 1vh;
    }

    .makeorder-page-table-colapse-width {
        width: fit-content;
        margin-right: 3vw;
    }

    .makeorder-page-table-name-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .make-order-table-name-subcontent {}


    .makeorder-page-table-mobile-header {
        display: flex;
    }

    .manage-matrix-header {
        display: none;
    }

    .makeorder-page-table-mobile-header2 {
        font-weight: bold;
        font-size: 1.2em;
    }

    .makeorder-page-table-expand-title {
        display: none;
    }

    .manage-matrix-product-width {
        width: 100%;
    }

    .makeorder-page-table-expand-row {
        flex-direction: column;
    }

    .manage-matrix-colum-width {
        width: 100%;
        justify-content: space-between;
        display: flex;
    }

    .makeorder-page-table-expand-colum {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .makeorder-page-table-expand-colum-name {
        flex: 1;
        width: 100%;
        display: flex;
        font-weight: bold;
        gap: 3vw;
    }

    .mobile-obs-line {
        margin-left: 7vw;
    }

    .desktop {
        display: none !important
    }

    .mobile {
        display: flex;
    }

    .table-product-name-text {
        width: 60%;
    }

    .makeorder-mobile-row {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 5vw;
    }

    .makeorder-page-table-orders-input {
        width: 20vw;
    }

    .mobile-name-container {
        display: flex;
        align-items: center;
    }

    .makeorder-page-table-name-width {
        align-items: center;
    }

    .makeorder-page-table-row {
        margin-bottom: 5vh;
    }

    .make-order-header-obs-text {
        max-width: 105vw;
        width: unset;
    }
}

@media screen and (max-width: 850px) {
    .desktop {
        display: none !important
    }

    .mobile {
        display: flex;
    }

    .makeorder-page-table-mobile-header {
        display: flex;
    }
}</style>