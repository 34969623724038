<template>
    <div class="register-tab-container">
        <ProductsTable :categories="categories" :products="products"/>
        <Pagination classes="orange" :items_by_page_value="20" :total="total_items" :page="page" :page_limit="5"
            v-on:change="updatePage($event)" :skipScroll="true">
        </Pagination>
    </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import ProductsTable from "./ProductsTable.vue";

export default {
    name: 'registerTab',
    props: {
        svc: {
            type: Object
        },
        date: {
            type: Object
        },
        setLoading: {
            type: Function
        },
        categories:{
            type: Array
        }
    },
    components: {
        Pagination,
        ProductsTable
    },
    data() {
        return {
            products: [],
            total_items: undefined,
            page: 1
        }
    },
    mounted() {
        this.loadPage()
    },
    methods: {
        loadPage() {
            this.setLoading(true)
            this.svc.getCountDetails('proCadastrado', this.date, this.page).then(resp => {
                this.products = resp.data.data
                this.total_items = resp.data.total
                this.setLoading(false)
            });
        },
        updatePage(page){
            this.page = page
            this.loadPage()
        }
    },
}
</script>

<style lang="scss" scoped>
.register-tab-container {
    height: 70vh;
    padding: 2vh 3vw;
}
</style>