<template>
    <div class="mobile-button-container">
        <h3>{{ product.pro_descricao }}</h3>
        <div style="display: flex; justify-content: space-between;">
            <div class="size">
                <h5>Código</h5>
                <span>{{ product.pro_ean }}</span>
            </div>
            <div class="size center">
                <h5>Embalagem</h5>
                <span>{{ product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}</span>
            </div>
            <div class="size end">
                <h5>Quantidade</h5>
                <span>{{ product.quantidade }}</span>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
            <div class="size">
                <h5>Status</h5>
                <span>{{ product.is_discontinued ? 'Produto Cancelado' : product.pro_status }}</span>
            </div>
            <span v-if="product.is_discontinued" class="material-icons page-content-product-icon"
                @click="() => discontinue(product, store, 0)">refresh</span>
            <span v-else class="material-icons page-content-product-icon"
                @click="() => discontinue(product, store, 1)">delete_outline</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileRow',
    props: {
        product: {
            type: Object
        },
        store: {
            type: Object
        },
        discontinue:{
            type: Function
        }
    },
}
</script>

<style lang="scss" scoped>
.mobile-button-container {
    display: none;
    padding: 3vw 2vw;
}

h3 {
    font-weight: 300;
    font-size: 1.15em;
    color: #605F5F;
}

h5 {
    font-weight: 700;
    font-size: 1em;
    color: #605F5F;
    margin: 0;
}

span {
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
}

.size {
    width: 30%;
}

.center {
    text-align: center;
}

.end {
    text-align: end;
}
.page-content-product-icon {
	color: var(--primary-color);
	font-size: 2em;
	cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .mobile-button-container {
        display: block;
    }
}
</style>