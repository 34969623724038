import http from "../http";
import Service from "../../Service";

export default class DashboardService extends Service {
    formatDate (date, end) {
        if (!date) return null
        if (end) return date.split('T')[0] + ' 23:59:59'
        return date.split('T')[0] + ' 00:00:00'
    }
    getRankingSupliers(storeId, fromDate, toDate){
        return http.get('clients/dashboard/rankingFornecedor', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getRankingProducts(storeId, fromDate, toDate){
        return http.get('clients/dashboard/rankingProdutos', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getCotBuy(storeId, fromDate, toDate){
        return http.get('clients/dashboard/compraPorCot', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getCategory(storeId, fromDate, toDate){
        return http.get('clients/dashboard/categoria', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getRequest(storeId, fromDate, toDate){
        return http.get('clients/dashboard/requisitante', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getCounts(storeId, fromDate, toDate){
        return http.get('clients/dashboard/counts', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getCountsNew(storeId, fromDate, toDate){
        return http.get('clients/dashboard/counts/new', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate, true)}
        })
    }
    getCountDetails(type, date, page){
        return http.get('clients/dashboard/counts/detalhe', {
            headers: this.getHeaders(),
            params: {'tipo': type, dataInicio: date.primeiro_dia, dataFim: date.ultimo_dia, page}
        })
    }
}