<template>
    <a class="zap" :href="'https://api.whatsapp.com/send?phone=55' + processed_phone" target="_blank" v-show="processed_phone">
        <i class="fab fa-whatsapp"></i>
        {{ processed_phone || "" | VMask('(##) ####-#####') }}
    </a>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        phone: {
            type: String
        }
    },
    computed: {
        processed_phone() {
            let begin = 3

            if(!this.phone)return null

            if(this.phone.length == 10) {
                begin = 2
            }
            return `${this.phone.substr(0, 2)}${this.phone.substr(begin, this.phone.length)}`
        }
    }
}
</script>

<style scoped lang="scss">
    .zap {
        i {
            color:#4AC959;
            margin-right: 4px;
        }
        white-space: nowrap;
        color: black;
        width: fit-content;
        padding: 5px 10px;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }
</style>
