<template>
    <!-- <div class="tab-pane fade show active in mt-3" role="tabpanel">
        <div class="container-fluid mb-3">
            <div class="row">
                 <cc-mobile-note />
                 <div  class="col-7 d-flex align-items-center buttons">
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2" :content="'Consolidar'" :icon="'fa fa-share-square'" @click="consolidateListMobile()" />
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2" :content="'Visualizar Produtos'" :icon="'fa fa-search'" @click="productListMobile()" />
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2 danger-outline" :classes="'danger-outline'" :content="'Deletar Solicitação'" :icon="'fa fa-trash'" @click="deleteListMobile()" />
                 </div>
            </div>
        </div>

        <div class="p-4 ">

            <cc-loader v-show="is_loading_list" />

            <no-data-available v-if="listMobileList.length == 0 && !is_loading_list" :text="'Sua lista está vazia, para criar novas listas acesse nosso aplicativo mobile'" />

        <div class="table-responsive m-0">
            <table class="table" :class="{ hidden : (is_loading_list || listMobileList.length == 0) }">
                <thead>
                    <tr>
                        <th>

                                <input class="custom-checkbox" :id="'checkbox-all'" type="checkbox" @change="checkAllListMobile" />
                               <label class="check-title checkBoxTableHeader" :for="'checkbox-all'"></label>

                        </th>
                        <th>Cod</th>
                        <th>Data Envio</th>
                        <th>Descrição</th>
                        <th>Produtos</th>
                        <th>Empresa</th>
                        <th>Usuário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listMobileList" :key="item.lista_id">
                        <td>

                                <input v-model="listMobileSelect" class="custom-checkbox" :id="'checkbox-'+item.lista_id" type="checkbox" :value="item.lista_id" />
                                <label :for="'checkbox-'+item.lista_id"></label>

                        </td>
                        <td>{{ item.lista_id}}</td>
                        <td>{{ item.data_envio | formatDate}}</td>
                        <td>{{ item.lista_descricao}}</td>
                        <td>{{ item.qtd_produtos}}</td>
                        <td>{{ item.loj_descricao}}</td>
                        <td>{{ item.usu_nome}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        <ModalProductsListMobile id="modal" :listMobile="listMobileSelect" :nameListMobile="nameListMobile" v-if="showProductListMobile" @close="closeProductListMobile()" />
    </div> -->

    <div class="page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Lista mobile
            </div>
        </div>
        <div class="page-title">
            Lista Mobile
        </div>
        <cc-loader-full v-if="is_loading_list" />
        <div class="page-buttons-containear">
            <MobileNote/>
            <StandardButton class="page-button" :disabled="listMobileSelect.length == 0"  :action="deleteListMobile" :iconName="'delete'" :text="'Apagar'"/>
            <StandardButton class="page-button" :disabled="listMobileSelect.length == 0" :action=" consolidateListMobile" :iconName="'reply'" :text="'Consolidar'"/>
            <!-- <StandardButton class="page-button" :disabled="listMobileSelect.length == 0" :action="productListMobile" :iconName="'visibility'" :text="'Visualizar'"/> -->
            <StandardInput class="page-button status-button" :action="filterStatus" placeholder="Disponível" type="select" :list="[{text: 'Disponível', value: 8}, {text: 'Importada', value: 9}, {text: 'Excluida', value: 13}]" />
        </div>
        <div class="table-overflow">
            <div class="page-table">
                <div class="page-table-header">
                    <div class="page-colum-width-checkbox">
                        <span v-if="isAllCheck" v-on:click.stop="setAllCheck(false)" class="material-icons checkbox-icon-containear">check_box</span>
                        <span v-else v-on:click.stop="setAllCheck(true)" class="material-icons checkbox-icon-containear">check_box_outline_blank</span>
                    </div>
                    <div class="page-colum-width page-header-text">Cod</div>
                    <div class="page-colum-width page-header-text">Data Envio</div>
                    <div class="page-colum-width page-header-text">Descrição</div>
                    <div class="page-colum-width page-header-text">Produtos</div>
                    <div class="page-colum-width page-header-text">Empresa</div>
                    <div class="page-colum-width page-header-text">Usuário</div>
                </div>
                <div class="page-table-row" v-for="(item, idx) in listMobileList" :key="item.lista_id" :class="getSpecialBackground(idx)">
                    <div class="page-colum-width-checkbox table-row-separator">
                        <span v-on:click.stop="editItemCheck(item, false)" v-if="item.isCheck" class="material-icons checkbox-icon-containear">check_box</span>
                        <span v-on:click.stop="editItemCheck(item, true)" v-else class="material-icons checkbox-icon-containear">check_box_outline_blank</span>
                        <div class="mobile mobile-tab-mobile-header">{{ item.loj_descricao }}</div>
                    </div>

                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-1 table-row-separator desktop">
                        {{ item.lista_id}}
                    </div>
                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-1 table-row-separator desktop">
                        {{ item.data_envio | formatDate}}
                    </div>
                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-1 table-row-separator desktop">
                        {{ item.lista_descricao}}
                    </div>
                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-1 desktop" >
                        <div class="table-product-button" @click="selectProductsMobile(item.lista_id)">{{ item.qtd_produtos}}</div>

                    </div>
                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-2 table-row-separator desktop">
                        {{ item.loj_descricao}}
                    </div>
                    <!-- desktop -->
                    <div class="page-colum-width table-row-text-2 desktop">
                        {{ item.usu_nome}}
                    </div>

                    <!-- mobile -->
                    <div class="mobile mobile-table-row">
                        <div class="table-row-text-1">
                            <div class="mobile-tab-mobile-header">Cod</div>
                            {{ item.lista_id}}
                        </div>

                        <div class=" table-row-text-1 ">
                            <div class="mobile-tab-mobile-header">Usuário</div>
                            {{ item.usu_nome}}
                        </div>
                        <div class="table-row-text-1 mobile-flex-end">
                            <div class="mobile-tab-mobile-header">Descrição</div>
                            {{ item.lista_descricao}}
                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="mobile mobile-table-row">

                        <div class="table-row-text-1 mobile-line-container">
                            <div class="mobile-tab-mobile-header">Data Envio</div>
                            {{ item.data_envio | formatDate}}
                        </div>
                        <div class="table-row-text-1 mobile-line-container" style="justify-content: flex-end;">
                            <div class="mobile-tab-mobile-header">Produtos:</div>
                            <div class="table-product-button" @click="selectProductsMobile(item.lista_id)">{{ item.qtd_produtos}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ModalProductsListMobile
                                 :listMobile="mobileList"
                                 :nameListMobile="nameListMobile"
                                 v-if="showProductListMobile"
                                 @close="closeProductListMobile()"/>
    </div>
</template>

<script>
import ModalSendListMobile from "@/components/cliente/monitors/ModalSendListMobile";
import ModalProductsListMobile from "@/components/cliente/monitors/ModalProductsListMobile";
import QuotationService from '../../../services/QuotationService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import MobileNote from '@/components/notes/mobile-promotion.noteV2';
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    name: 'MobileRequestsTab',
    mixins: [ loaderMixin ],
    components: {
        ModalSendListMobile,
        ModalProductsListMobile,
        MobileNote,
        StandardButton,
        StandardInput
    },
    data() {
        return {
            service: new QuotationService(),
            listMobileSelect: [],
            listMobileList: [],
            showSendListMobile: false,
            showProductListMobile: false,
            is_loading_list: false,
            nameListMobile: [],
            isAllCheck: false,
            mobileList: [],
            statusFilter: 8
        }
    },
    methods: {
        filterStatus (value) {
            console.log(value);
            this.statusFilter = value
            this.listMobile()
        },
        selectProductsMobile(requestId) {
          this.prepareListMobileSelect()
          this.mobileList = []
          this.mobileList.push(requestId)
          this.mobileList.forEach(el => {
            let mobile = this.listMobileList.filter(list =>{
              return list.lista_id == el;
            });
            this.nameListMobile.push(mobile[0].lista_descricao);
          })
          this.showProductListMobile = true;
        },
        prepareListMobileSelect () {
            this.listMobileSelect = []
            this.listMobileList.forEach(item => {
                if (item.isCheck) this.listMobileSelect.push(item.lista_id)
            })
        },
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        setAllCheck (value) {
            this.isAllCheck = value
            if (this.isAllCheck) {
                this.listMobileList.forEach(item => item.isCheck = true)
            } else {
                this.listMobileList.forEach(item => item.isCheck = false)
            }
            this.prepareListMobileSelect()
            this.$forceUpdate()
        },
        editItemCheck (item, value) {
            item.isCheck = value
            if (value) this.listMobileSelect.push(item.lista_id)
            else this.prepareListMobileSelect()
            this.$forceUpdate()
        },
        deleteListMobile: function() {
            this.prepareListMobileSelect()
            if (this.listMobileSelect.length > 0) {
                this.$swal.fire({
                    title: "Tem certeza que deseja deletar a lista?",
                    text: "Sua solicitação será apagada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo deletar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service.deleteListMobile(this.listMobileSelect)
                        .then(response => response.data)
                        .then(data => {
                            this.listMobileSelect = []
                            this.listMobile();
                            this.$swal.fire(
                                "Deletado!",
                                "Sua lista foi apagada com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Erro!",
                                "Ocorreu um erro ao realizar a ação.",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                });
            } else {
                this.$swal.fire("Ops!", "Selecione uma lista!", "warning");
            }
        },
        closeSendListMobile: function() {
            this.showSendListMobile = false;
        },
        closeProductListMobile: function() {
            this.showProductListMobile = false;
        },
        consolidateListMobile() {
            this.prepareListMobileSelect()
            this.confirm_action({
                message: 'Confirma envio da lista para ressuprimento?',
                callback: () => {
                    this.is_loading_list = true;
                    var quotationService = new QuotationService();
                    quotationService.consolidateListMobile({
                        listas: this.listMobileSelect,
                        tipo: "S"
                    }).then(response => response.data).then(data => {
                        this.is_loading_list = false;
                        this.consolidatedSuccess()
                        this.listMobile()
                    }).catch(error => {
                        this.is_loading_list = false;
                        ErrorHandlerService.handle(error, this.$store);
                    });
                }
            })
        },
        consolidatedSuccess() {
            this.$swal
            .fire("Publicado!", "Sua lista foi publicada com sucesso.", "success")
            .then(() => (this.tab = "RESUPLY"));
        },
        checkAllListMobile(e) {
            this.listMobileSelect = [];
            if (e.target.checked) {
                this.listMobileList.forEach(element => {
                    this.listMobileSelect.push(element.lista_id);
                });
            }
        },
        sendListMobile: function() {
            if (this.listMobileSelect.length > 0) {
                this.showSendListMobile = true;
            } else {
                this.$swal.fire("Erro!", "Selecionar uma(s) lista(s).", "error");
            }
        },
        productListMobile: function() {
            this.prepareListMobileSelect()
            if (this.listMobileSelect.length > 0) {
                this.listMobileSelect.forEach(element => {
                    let mobile = this.listMobileList.filter(list =>{
                        return list.lista_id == element;
                    });
                    this.nameListMobile.push(mobile[0].lista_descricao);
                });
                this.showProductListMobile = true;
            } else {
                this.$swal.fire("Erro!", "Selecionar uma(s) lista(s).", "error");
            }
        },
        listMobile(){
            this.is_loading_list = true;

            this.service.getListMobile(null, null, this.statusFilter)
            .then(response => response.data)
            .then(data => {
                this.listMobileList = data.lista
                this.listMobileList.sort((a, b) => {
                    let pos2 = a.lista_id
                    let pos1 = b.lista_id
                    if (pos1 > pos2) return 1
                    if (pos2 > pos1) return -1
                    return 0
                })
                this.is_loading_list = false;
            })
            .catch(error => {
                this.is_loading_list = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
    },
    created() {
        this.listMobile();
        /* setTimeout(() => {
            console.log(this.listMobileList)
        }, 2000); */
    }
}
</script>

<style lang="scss" scoped>
.page-route-select{
    display: flex;
    align-items: center;
}
.page-route-select-text{
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.page-title{
    font-weight: 600;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}
.page{
    font-size: 1vw;
}
.page-buttons-containear{
    display: flex;
    align-items: flex-end;
    margin-top: 3vh
}
.page-button{
    margin-left: 3vw;
}
.page-table{
    margin-top: 6vh;
}
.table-row-separator{ border-right: 0.5px solid #DDE5E9; }
.page-table-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin-bottom: 2vh;
}
.page-colum-width{
    width: 15%;
    text-align: center;
}
.page-colum-width-checkbox{
    width: 5%;
    display: flex;
    justify-content: center;
}
.page-table-row{
    display: flex;
    padding: 1.5vh 0;
}
.page-header-text{
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
}
.table-row-text-1{
    font-weight: 300;
    font-size: 1.19em;
    color: #605F5F;
}
.table-row-text-2{
    font-weight: 500;
    font-size: 1.19em;
    color: #AFAFAF;
}
.checkbox-icon-containear{
    margin-left: 1vw;
    padding-right: 1vw;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.7em;
}
.table-overflow{
    margin-bottom: 5vh;
}
.page-table-line-special {
  background: #F7F7F7;
}
.mobile-tab-mobile-header{display: none;}
.table-product-button {
  background: rgba(23, 162, 184, 0.05);
  border-radius: 8px;
  margin: 0 50px;
  width: 10vw;
  color: #17A2B8;
  cursor: pointer;
}
.status-button{
    width: 20vw;
}
.mobile{display: none;}


@media only screen and (max-width: 850px) {
    .page-buttons-containear{
        flex-direction: column;
        align-items: stretch;
    }
    .mobile{display: flex;}
    .page-button{
        margin-left: 0;
        margin-top: 2vh;
    }
    .status-button{
        width: 100%;
    }

    .page{ font-size: 2.5vw; }
    .page-table-header{display: none;}
    .page-table-row{
        flex-direction: column;
        margin-top: 3vh;
        gap: 1vh;
        font-size: 1.5em;
    }
    .page-colum-width{
        display: flex;
        width: 100%;
        flex: unset;
        justify-content: space-between;
    }
    .mobile-tab-mobile-header{
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
        color: #605F5F;
    }
    .table-product-button{
        margin: 0;
        width: 15vw;
        text-align: center;
        border: 1px solid #17A2B8;
    }
    .table-row-separator{border-right: 0;}

    @import "../monitors.page";

    /deep/ .buttons {
        a {
            margin-left: 10px;
        }
    }
    .checkBoxTableHeader{
        margin-left: 0px !important;
    }
    .button-alter{
        cursor: pointer;
        font-size: 1.7em;
    }
    .page-colum-width-checkbox{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 3vw;
    }
    .mobile-table-row{
        justify-content: space-between;
        width: 100%;
    }
    .mobile-flex-end{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .mobile-line-container{
        display: flex;
        gap: 2vw;
    }
    .desktop{display: none;}
}
</style>
