<style lang="scss">
// @import '@/sass/commons/_variables';

// .client-sidebar {
//     transition: width .5s ease;
//     white-space: nowrap;
//     height: 100%;
//     width: 5rem;
//     z-index: 2;
//     display: flex;
//     position: fixed;
//     justify-content: center;
//     flex-direction: column;
//     //border-right: 1px solid #ddd;
//     background-color: transparent;
//     .control {
//         height: 50px;
//         margin: 0 0 30px;
//         padding: 0 22px;
//         position: absolute;
//         top: 5.3rem;
//         //background-color: $sidebar-background-color;
//         a {
//             cursor: pointer;
//             .icon {
//                 color: #ffffff;
//                 padding-top: 15px;
//                 display: block;
//                 font-size: 1.5rem;
//                 &.icon-close {
//                     margin-left: 10px;
//                     position: relative;
//                     &::before {
//                         content: '';
//                         transform: rotate(45deg);
//                         width: 2px;
//                         height: 20px;
//                         background-color:#ffffff;
//                         display: block;
//                         position: absolute;
//                     }
//                     &::after {
//                         content: '';
//                         color: $primary-color;
//                         transform: rotate(-45deg);
//                         width: 2px;
//                         height: 20px;
//                         background-color:#ffffff;
//                         display: block;
//                         position: absolute;
//                     }
//                 }
//             }
//         }
//     }
//     ul {
//         position: inherit;
//         list-style: none;
//         padding-top: 0 !important;
//         text-align: center;
//         width: 3.3rem;
//         background-color: transparent;
//         padding: 0px;
//         border-radius: 0 12px 12px 0;
//         margin-top: 20px;
//         li {
//             cursor: pointer;
//             border-radius: 0 4px 4px 0;
//             font-size: 14px;
//             width: 55px;
//             height: 35px;
//             display: flex;
//             align-items: center;
//             padding-right: 7px;
//             padding-left: 5px;
//             border: 1px solid rgba(0, 0, 0, 0);
//             margin: 20px 0;
//             &:hover {
//                 width: 15.3rem;
//                 position: relative;
//                 background-color: #fdf0e9 !important;
//                 z-index: 2;
//                 // -webkit-transition: width 0.2s ease-in-out;
//                 // -moz-transition: width 0.2s ease-in-out;
//                 // -o-transition: width 0.2s ease-in-out;
//                 transition: width 0.3s ease-out;
//                 // border: 1px solid $orange !important;
//                 .abc {
//                     display: flex;
//                     height: 3rem;
//                     width: 20rem;
//                     // color: white;
//                     &:hover {
//                         text-decoration: none;
//                     }
//                 }
//                 p {
//                     display: block !important;
//                 }
//                 .showOnHover{
//                     display: flex;
//                     flex-direction: column;
//                 }
//             }
//             &.active {
//                 //selected border - change this first
//                 background-color: #fdf0e9;
//             }

//             .abc {
//                 width: 100%;
//                 display: flex;
//                 padding-left: 10px;
//                 color: var(--primary-color);
//                 height: 100%;
//                 align-items: center;
//                 span {
//                     font-size: 18px;
//                     margin-right: 11px;
//                     &::before {
//                         color: #707070;
//                     }
//                     &.fa-medal {
//                         font-size: 1.2rem;
//                         &::before {
//                             color: var(--primary-color);
//                         }
//                     }
//                     &.icon-icon_home::before {
//                         color: #707070
//                     }
//                     &.fa-line-chart::before {
//                         color: #707070
//                     }
//                     &.fa-users::before {
//                         color: #707070
//                     }
//                     &.fa-shopping-bag::before {
//                         color:#707070
//                     }
//                     &.fa-boxes::before {
//                         color: #707070
//                     }
//                     &.icon-icon_desktop::before {
//                         color: #707070
//                     }
//                     &.icon-icon_cart_plus::before {
//                         color: #707070
//                     }
//                     &.icon-icon_sign_out_alt::before {
//                         color: #707070
//                     }

//                 }
//                 p {
//                     margin: 0;
//                     text-transform: uppercase;
//                     font-weight: 600;
//                     font-size: small;
//                 }
//             }
//         }
//     }
// 	&.expanded {
// 		width: 17rem;
//         ul {
//             width: 15.3rem;
//             // li {
//             //     width: 15.3rem;
//             // }
//         }
// 	}
// }
// .showOnHover{
//     display: none;
// }
// .new-sidebar__list {
//   margin: 0px 10px 4px 10px !important;
//   border-radius: 10px !important;
//   height: 60px !important;
//   background-color: white;
// }
// .new-sidebar__icon-color {
//   color: #707070;
//   font-size: 24px !important;
// }
// .new-sidebar__icon-color:focus {
//   color: red !important;
// }
// .new-sidebar__icon-color:hover {
//   color: var(--primary-color);
//   transition: ease 0.5s all;
// }
// .new-sidebar__router-link {
//   color: var(--primary-color) !important;
// }
// .new-sidebar__logout-list {
//   margin: 0px 10px 10px 10px !important;
//   border-radius: 10px !important;
//   height: 60px !important;
//   margin-left: 22px !important;
// }
// .new-sidebar__logout-link {
//   color: #f5222d !important;
//   display: flex;
// }
// .new-sidebar__logout-icon {
//   font-size: 24px !important;
// }
// .new-sidebar__logout-title {
//   color: var(--primary-color) !important;
//   margin-left: 10px;
// }
// .active-class {
//    color: var(--primary-color) !important;
// }
.client-sidebar {
    height: 100%;
    padding-top: 5%;
    z-index: 3;
    display: flex;
    position: fixed;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    font-size: 1vw;
    // display: none;
}
.new-sidebar__list {
  padding: 1vh 1vw;
  display: flex;
  cursor: pointer;
  border-left: 3px solid transparent;
  align-items: center;
  &:hover{
    border-left: 3px solid var(--primary-color);
    background-color: #fdf0e9;
  }
}
.new-sidebar__icon-color {
  color: #7c7c7c;
  font-size: 2em;
  padding: 0 0.7vw;
  border-radius: 7px;
  display: flex;
  align-items: center;
  height: 6.5vh;
}
.menu-option-2{
    width: 0;
    transition: width 0.15s ease-out;
    overflow: hidden;
    position: absolute;
    margin-left: 5.5vw;
    color: #7c7c7c;
    background-color: #fdf0e9;
    border-radius: 7px;
    padding: 2vh 0;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -5px;
}
.menu-option-1{
    color: #7c7c7c;
    display: flex;
    align-items: center;
    padding: 0 1.5vw;
    border-radius: 0 7px 7px 0;
    text-transform: uppercase;
    margin-left: -1px;
}
.green-icon{
    color: lightgreen !important;
}
.active-class {color: var(--primary-color);}
.client-sidebar-arrow-container{
    position: absolute;
    background-color: white;
    margin-left: 5vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid var(--primary-color);
    }
}
.client-sidebar-arrow-container2{
    position: absolute;
    background-color: white;
    margin-left: 25vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid var(--primary-color);
    }
}
.sidebar-home-alert{
    font-weight: 400;
    font-size: 1em;
    background-color: var(--primary-color);
    color: #FFFFFF;
    border-radius: 5px;
    padding: 0.5vw;
    margin-left: 3vw;
}
.sidebar-options{
    position: absolute;
    margin-left: 22.1vw;
    margin-top: 10vh;
    background-color: white;
    border-radius: 8px;
    padding: 0.5vh 0;
    display: flex;
    flex-direction: column;
    width: max-content;
    outline: 1px solid #ABABAB;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.sidebar-option{
    padding: 0.5vh 1vw;
    &:hover{background-color: #fdf0e9;}
}

@media only screen and (max-width: 630px) {
  .client-sidebar {
    display: none;
  }
}
</style>
<template>
    <!-- <div class="client-sidebar" v-bind:class="{ expanded: isExpanded }">
        <ul class="rounded not-print">
            <li class="new-sidebar__list" v-for="menu of permitted_menus" :key="menu.name" v-bind:class="{ active: menu.active, expanded: isExpanded }" @click="activate(menu)">
                <div class="new-sidebar__router-link abc" :to="{ path: menu.link }">
                  <span :class="{ 'active-class' : menu.active}" class="material-icons-outlined new-sidebar__icon-color">
                    {{ menu.icon }}
                  </span>
                    <div class="showOnHover">
                        <router-link style="color: var(--primary-color);" :to="{ path: menu.link }">
                            {{menu.name}}
                        </router-link>
                        <router-link class="menu-option-2" :to="{ path: menu.link2 }" style="color: var(--primary-color);" v-if="menu.name2">
                            {{menu.name2}}
                        </router-link>
                    </div>
                </div>
            </li>
            <li class="new-sidebar__logout-list" v-bind:class="{ expanded: isExpanded }">
              <a class="new-sidebar__logout-link" v-on:click="logout()">
                <span class="material-icons-outlined new-sidebar__logout-icon">
                  logout
                </span>
                <p class="new-sidebar__logout-title" :class="{ 'hidden' : !isExpanded}">Sair</p>
              </a>
            </li>
        </ul>
    </div> -->

    <div class="client-sidebar" :style="getExpandstyles()" @mouseover="showExpand()" @mouseleave="hideExpand()">
        <div class="hover-container" v-for="menu of permitted_menus()" :key="menu.name" @mouseover="showMenuOptions(menu)" @mouseleave="hideMenuOptions(menu)">
            <router-link :to="menu.link" v-on:click="redirect(menu)"  class="new-sidebar__list" >
                <span :class="{ 'active-class' : menu.active, active: menu.active, 'material-icons': menu.icon == 'local_offer','green-icon': menu.icon == 'local_offer', 'material-icons-outlined': menu.icon != 'local_offer'}"  class="new-sidebar__icon-color">
                    {{ menu.icon }}
                </span>
                <div v-if="isExpanded" class="menu-option-1" >
                    {{menu.name}}
                </div>
                <div v-if="isExpanded && menu.name == 'Home'" class="sidebar-home-alert">
                    Começe por Aqui
                </div>
                <span v-if="isExpanded && menu.options" class="material-icons-outlined" style="color: var(--primary-color);">chevron_right</span>
                <div class="sidebar-options" v-if="menu.showOptions && isExpanded" >
                    <router-link :to="option.link"  class="sidebar-option menu-option-1" v-for="option in menu.options" :key="option.name">
                        {{option.name}}
                    </router-link>
                </div>
            </router-link>
        </div>
        <div class="hover-container">
            <div v-on:click="logout()" class="new-sidebar__list">
                <span class="material-icons-outlined new-sidebar__icon-color">
                    logout
                </span>
                <div v-if="isExpanded" class="menu-option-1" >
                    sair
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import * as CONSTANTS from "@/constants/constants";
import StorageService from '@/services/TokenService';
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ perm_mixin ],
    data() {
        return {
            storage_svc: new StorageService(),
            isExpanded: false,
            CONSTANTS: CONSTANTS,
            menus: [
                {
                    name: "Home",
                    resource: 'HOME',
                    icon: "home",
                    link: '/home-page/',
                    active: false,
                    route: 'home'
                },
                {
                    name: "Dashboard",
                    resource: 'REPORT',
                    icon: "donut_large",
                    link: '/dashboard/',
                    active: false,
                    route: 'relatorios'
                },
                {
                    name: "Produtos",
                    resource: 'PRODUCT',
                    icon: "inventory_2",
                    link: '/produtos/',
                    route: "products",
                    active: false,
                },
                {
                    name: "Meus Fornecedores",
                    resource: 'SELLER',
                    icon: "groups",
                    link: '/fornecedores/',
                    route: "sellers",
                    active: false,
                },
                {
                    name: "Mapa de Fornecedores",
                    resource: 'MAP',
                    icon: "map",
                    link: '/explorar/',
                    active: false,
                    action: 'READ_PROVIDERS_MAP',
                    route: 'explore'
                },
                {},
                {
                    name: "painel de operações",
                    resource: 'REQUEST',
                    icon: "desktop_windows",
                    link: CONSTANTS.ROUTE_MONITORS,
                    params: { tab: 'cotacoes' },
                    route: "monitors-page",
                    active: false,
                    showOptions: false,
                    options: [
                        {
                            name: "Monitor de Cotação",
                            link: "/cliente/monitores/cotacoes"
                        },
                        {
                            name: "Monitor de Ressuprimento",
                            link: "/cliente/monitores/ressuprimento"
                        },
                        {
                            name: "Lista Mobile",
                            link: "/cliente/monitores/mobile"
                        },
                        {
                            name: "Pedidos",
                            link: "/cliente/monitores/pedidos"
                        },
                        // {
                        //     name: "Monitor Preço de Venda Varejo",
                        //     link: "/cliente/monitores/price"
                        // },
                    ]
                },
                {
                    name: "Relatórios",
                    resource: 'REPORT',
                    icon: "trending_up",
                    link: '/relatorios/',
                    active: false,
                    route: 'relatorios'
                },
                {
                },
                // {
                //     name: "Produtos em Destaque",
                //     resource: 'PRODUCT',
                //     action: 'SEE_HIGHLIGHTS',
                //     icon: "fas fa-medal",
                //     link: '/destaques',
                //     route: "highlights-index",
                //     active: false,
                // },
                {
                    name: "Minhas Configurações",
                    icon: "settings",
                    resource: 'ACCOUNT_SETTINGS',
                    action: 'READ',
                    link: CONSTANTS.ROUTE_SETTINGS,
                    route: "user-settings",
                    active: false,
                }
            ]
        };
    },
    computed: {

    },
    watch: {
        perms: function() {
            setTimeout(() => {
                this.process_request_permission_menu()
            }, 400);
        },
        '$route' (to, from){
            this.menus.forEach(m => {
                if(to.name == m.route) {
                    this.activate(m)
                }
            })
        }
    },
    methods: {
        permitted_menus: function() {
            return this.menus.filter(menu => menu.link)
                .filter(menu => menu.resource == null || this.can(menu.resource, menu.action || 'READ'))
        },
        hideMenuOptions(menu){if (menu.options) menu.showOptions = false},
        showMenuOptions(menu){
            if (menu.options) {
                menu.showOptions = true
            }
        },
        getExpandstyles() {if (this.isExpanded) return 'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);'},
        showExpand() {
            this.isExpanded = true
        },
        hideExpand() {
            this.isExpanded = false
            this.menus.forEach(menu => menu.showOptions = false)
        },
        redirect(menu){
            if (!menu.options) {
                this.menus.forEach(m => m.active = false)
                menu.active = true;
                this.$forceUpdate()
                this.isExpanded = false
                // this.$router.push(menu.link)
            }
        },
        logout() {
            console.log('logout')
            this.$store.dispatch('user/logout')
            window.location.assign(CONSTANTS.ROUTE_LOGIN)
        },
        activate(menu) {
            this.menus.forEach(m => m.active = false)
            menu.active = true;
            this.$forceUpdate()
        },
        defineMenuItemActive() {
            this.menus.map((menu,index) => {
                this.menus[index].active = false;
                if ( menu.link === this.$route.path ) {
                    this.menus[index].active = true;
                }
            });
        },
        process_request_permission_menu() {
            if (JSON.parse(this.storage_svc.get('showOffers'))){
                this.menus[8] = {
                    name: "Ofertas",
                    resource: 'CAMPAIGN',
                    icon: "local_offer",
                    link: '/ofertas',
                    route: "campaigns-index",
                    active: false,
                }
                this.$forceUpdate()
            }

            if(this.can('REQUEST', 'CREATE') || this.can('REQUEST', 'CREATE_RESUPPLY')) {
                let action = null
                if(this.can('REQUEST', 'CREATE')) {
                    action = 'CREATE'
                } else if(this.can('REQUEST', 'CREATE_RESUPPLY')) {
                    action = 'CREATE_RESUPPLY'
                }
                this.menus[5] = {
                    name: "Nova Compra/Requisição",
                    resource: 'REQUEST',
                    action: action,
                    icon: "add_shopping_cart",
                    link: CONSTANTS.ROUTE_NEW_QUOTATION_REQUEST,
                    active: false,
                }
                this.$forceUpdate()
            }
            this.$forceUpdate()
        }
    },
    mounted(){
        this.process_request_permission_menu()
        this.defineMenuItemActive();
    },
    beforeRouteUpdate (to, from, next) {
        this.defineMenuItemActive();
    }
}
</script>
