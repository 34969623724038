import { render, staticRenderFns } from "./addProviderHeader.vue?vue&type=template&id=ca02d010&scoped=true&"
import script from "./addProviderHeader.vue?vue&type=script&lang=js&"
export * from "./addProviderHeader.vue?vue&type=script&lang=js&"
import style0 from "./addProviderHeader.vue?vue&type=style&index=0&id=ca02d010&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca02d010",
  null
  
)

export default component.exports