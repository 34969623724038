<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body" class="container ml-3 mb-2">
            <div class="row pt-2">
                <div class="col-md-3 col-lg-3">
                    <img src="/images/logoclub.jpeg" class="img-fluid rounded-circle">
                </div>
                <div class="col-md-8 col-lg-8 pl-4">
                    <h5 class="modal-nome-vendedor pl-3">{{ seller.usu_nome }}</h5>
                    <h5 class="modal-subtitulo pl-3">{{sellersFornecedor}} - {{sellersCidade}}/{{sellersEstado}}</h5>
                    <div class=" d-flex flex-row col-md-12 col-lg-12">
                        <star-rating
                            :star-size="20"
                            :show-rating="false"
                            :rating= notaTotal
                            :read-only= "true"
                            inactive-color="white"
                            active-color="#f38505"
                            border-color='#f38505'
                            :border-width='1'
                        >
                        </star-rating> <h5 class="pt-3 pl-1">({{Math.round(notaTotal, -1)}}) {{ evaluations.length}} Avaliações</h5>
                    </div>
                </div>
            </div>
            <div class="row py-5">
                <div class="filter-state-toggle ml-3">
                    <button autofocus type="button" :class="{botaoModalFocus: isClassButton}" class="btn botao-modal" ref="botao" @click=" myAvaliacoes">Minhas avaliações<span v-if="isMinhasAvaliacoes">({{sellersMinhasAvaliacoes.length}})</span></button>
                    <button type="button" class="btn botao-modal" @click=" outrasAvaliacoes">Todas avaliações <span v-if="isOutrasAvaliacoes">({{ evaluations.length}})</span></button>
                </div>
            </div>
            <div class="row">
                <div v-if="isOutrasAvaliacoes" class="col">
                    <div class="bordaComentarios mb-4" v-for="(data,index) in evaluations" :key="'oe-' + index">
                        <h3> <star-rating
                            :star-size="20"
                            :show-rating="false"
                            :rating= data.nota
                            :read-only= "true"
                            inactive-color="white"
                            active-color="#f38505"
                            border-color='#f38505'
                            border-width='1'
                        ></star-rating></h3>
                        <span class="mensagem-modal" v-if="data.nota === 5">Vendedor Excelente!</span>
                        <span class="mensagem-modal" v-if="data.nota === 4">Vendedor bom mas poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 3">Vendendo regular poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 2">Vendedor ruim poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 1">Vendedor péssimo poderia melhorar.</span>
                        <div v-for="(dataComentario, index) in data.comentario" :key="'oc-' + index">
                            <span class="modal-texto-avaliacao">{{dataComentario.descricao}}</span>
                        </div>
                    </div>
                    <div v-if="evaluations.length === 0">Não há avaliações para este vendededor</div>
                </div>
                <div v-if="isMinhasAvaliacoes" class="col">
                    <div class="bordaComentarios mb-4" v-for="(data,index) in sellersMinhasAvaliacoes" :key="'me-' + index">
                        <h3>
                            <star-rating
                                :star-size="20"
                                :show-rating="false"
                                :rating= data.nota
                                :read-only= "true"
                                inactive-color="white"
                                active-color="#f38505"
                                border-color='#f38505'
                                border-width='1'
                            ></star-rating>
                        </h3>
                        <span class="mensagem-modal" v-if="data.nota === 5">Vendedor Excelente!</span>
                        <span class="mensagem-modal" v-if="data.nota === 4">Vendedor bom mas poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 3">Vendendo regular poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 2">Vendedor ruim poderia melhorar.</span>
                        <span class="mensagem-modal" v-if="data.nota === 1">Vendedor péssimo poderia melhorar.</span>
                        <div v-for="(dataComentario, index) in data.comentario" :key="'mc-' + index">
                            <span class="modal-texto-avaliacao">{{dataComentario.descricao}}</span>
                        </div>
                    </div>
                    <div v-if="sellersMinhasAvaliacoes.length === 0">Não há avaliações suas para este vendededor</div>
                </div>
            </div>
        </div>
    </cc-modal>
</template>

<script>
import ProviderService from '@/services/ProviderService'
export default {
    props: {
        seller: {
            type: Object,
            required: true
        }
    },
    data() {
        return  {
            svc: new ProviderService(),
            modal: {
                title: "vendedor",
                subtitle: 'Confira a avaliação dos vendedores',
                icon_title: 'fas fa-user-tie',
                style: {
                    width: "50%"
                }
            },
            evaluations: null,
            sellersName: '',
            sellersEstado:'',
            sellersCidade:'',
            sellersFornecedor: '',
            sellersTodasAvaliacoes:[],
            sellersMinhasAvaliacoes:[],
            isMinhasAvaliacoes: true,
            isOutrasAvaliacoes: false,
            notaTotal: 0,
            isClassButton: true,
        }
    },
    created() {
        this.svc.get_evaluation(this.seller.usu_id)
        .then(response => response.data)
        .then((data)=>{
            if(data.avaliacao.length > 0) {
                this.sellersName = data.avaliacao[0].nome_vendedor
                this.sellersFornecedor = data.avaliacao[0].fornecedor
                this.sellersEstado = data.avaliacao[0].estado
                this.sellersCidade = data.avaliacao[0].cidade
            }
            this.evaluations = data.todas_avaliacoes
            this.sellersMinhasAvaliacoes = data.minhas_avaliacoes
            this.notaTotalCalculo()
        })
    },
    methods: {
        notaTotalCalculo(){
            let nota = 0
            this.evaluations.forEach((data)=>{
                nota += parseInt(data.nota)/this.evaluations.length
            })
            this.notaTotal = nota
        },
        close() {
            this.$emit('close')
        },
        myAvaliacoes(){
            this.isMinhasAvaliacoes = true
            this.isOutrasAvaliacoes = false
            this.isClassButton = true
        },
        outrasAvaliacoes(){
            this.isMinhasAvaliacoes = false
            this.isOutrasAvaliacoes = true
            this.isClassButton = false
        }
    }
}
</script>

<style>

.modal-vendedor-titulo {
  font-family: Rubik;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #43425d;
}
.modal-nome-vendedor {
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
}
.modal-subtitulo {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #94959d;
}
.botao-modal {
  border: solid 1px #d7dae2;
  background-color: #ffffff;
  margin-left: 0px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4f5c;
}
.botao-modal:focus{
  color: var(--primary-color) !important;
  border-color: var(--primary-color);;
  background-color: #f8f9fb
}
.botaoModalFocus{
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  background-color: #f8f9fb
}
.modal-texto-avaliacao{
  opacity: 0.63;
  font-family: Rubik;
  font-size: 16px;
  text-align: left;
  color: #43425d;
}

.modal-link:hover{
  color: var(--primary-color) !important
}
.mensagem-modal {
  font-family: Rubik;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
  line-height: 2.19
}
</style>
