<template>
    <div @click="callAction" class="invitation-button-container" :class="{'invitation-button-disabled': disabled}">
        <span class="material-icons-outlined ">{{ icon }}</span>
        <p v-if="text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'InvitationButton',
    props:['text', 'icon', 'action', 'disabled'],
    methods:{
        callAction(){
            if (!this.disabled) this.action()
        }
    }
}
</script>

<style scoped lang="scss">
.invitation-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    border: 1px solid #E5E5E5;
    background-color: #FAFAFC;
    color: #505050;
    padding: 1.3vh 2vw;
    border-radius: 10px;
    cursor: pointer;
    p{
        margin-bottom: 0;
    }
    span{
        color: var(--primary-color);
    }
}
.invitation-button-disabled{
    background-color: rgb(242, 240, 240);
    cursor: default;
    span{
        color: gray;
    }
}
</style>