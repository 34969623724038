export const setFaviconAndTitle = (favicon, title) => {

    handleSetFavicon(favicon)
    handleSetTitle(title)
}


const getFaviconEl = () => {
    return document.getElementById("faviconAdmin");
}

const handleSetFavicon = (ref) => {
    const favicon = getFaviconEl();
    favicon.href = ref;
};

const getTitleEl = () => {
    return document.getElementById("docummentTitle");
}

const handleSetTitle = (ref) => {
    const docTitle = getTitleEl();
    docTitle.innerHTML = ref;
};

