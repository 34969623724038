export default {
    methods: {
        checkPrecisionValue(valor) {

          let num = Number(valor);
          let formattedNumber;


          const decimalCount = (num.toString().split('.')[1] || '').length;

          if (decimalCount > 2) {
            const lastTwoDigits = num.toString().slice(-2);
            if (lastTwoDigits === '00') {
              formattedNumber = num.toFixed(2);
            } else {
              let fixed = num.toFixed(4);
              let lastTwoDigit = fixed.toString().slice(-2);
              if (lastTwoDigit === '00') {
                formattedNumber = num.toFixed(2);
              } else {
                formattedNumber = num.toFixed(4);
              }
            }
          } else {
            formattedNumber = num.toFixed(2);
          }

          return this.formatForBrazil(formattedNumber);
        },
        formatForBrazil(value) {
          const parts = value.split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return parts.join(",").replace(".", ",");
        }
      },
    filters: {
      customCurrency(value,) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 4,
        }).format(parseFloat(value));
      },
      twoDecimal(value,) {
        if (!value || isNaN(value)) return '--'
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(parseFloat(value));
      },
      formatNumber(value,) {
        if (!value || isNaN(value)) return '--'
        return new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(parseFloat(value));
      }
    }
  };
