<template>
    <div class="new-provider-body-separator">
        <div class="new-provider-body-text-title">Informações da empresa</div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'CNPJ'" :mask="'## ### ###/####-##'" :auto="true" :value="provider.head_office.cnpj"  :action="setCnpj" :type="'text'"/>
            <StandardInput class="new-provider-body-input" :title="'Razão Social'" :auto="true" :action="setSocial" :value="provider.head_office.social_reazon" :type="'text'"/>
        </div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'Contato'" :auto="true" :value="provider.contact" :action="setContact" :type="'text'"/>
            <StandardInput class="new-provider-body-input" :title="'Nome Fantasia'" :auto="true" :value="provider.head_office.trading_name" :action="setFantasyName" :type="'text'"/>
            <!-- <StandardInput class="new-provider-body-input" :title="'Email'" :auto="true" :action="setEmail" :value="provider.head_office.email" :type="'text'"/> -->
        </div>
        <div class="new-provider-body-input-line">
            <!-- <StandardInput class="new-provider-body-input" :title="'Site'" :auto="true" :value="provider.site_url" :action="setSite" :type="'text'"/> -->
        </div>
        <div class="new-provider-body-input-line">
            <!-- <StandardInput class="new-provider-body-input" :mask="'(##) #.####-####'" :title="'Telefone'" :auto="true" :value="provider.head_office.phone" :action="setPhone" :type="'text'"/> -->
            <div class="new-provider-body-info-checks">
                <!-- <div >
                    <div class="new-provider-body-title">Tipo de entrega</div>
                    <div class="new-provider-body-info-checks-line">
                        <div style="display: flex; align-items: center; cursor: pointer;" v-on:click="setDeliveryType(1)">
                            <span v-if="provider.delivery_type_id == 1" class="material-icons-outlined new-provider-body-info-check-icon">check_box</span>
                            <span v-else class="material-icons-outlined new-provider-body-info-check-icon">check_box_outline_blank</span>
                            <div class="new-provider-body-info-check-text">CIF</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer;" v-on:click="setDeliveryType(2)">
                            <span v-if="provider.delivery_type_id == 2" class="material-icons-outlined new-provider-body-info-check-icon">check_box</span>
                            <span v-else class="material-icons-outlined new-provider-body-info-check-icon">check_box_outline_blank</span>
                            <div class="new-provider-body-info-check-text">CIF/FOB</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer;" v-on:click="setDeliveryType(3)">
                            <span v-if="provider.delivery_type_id == 3" class="material-icons-outlined new-provider-body-info-check-icon">check_box</span>
                            <span v-else class="material-icons-outlined new-provider-body-info-check-icon">check_box_outline_blank</span>
                            <div class="new-provider-body-info-check-text">FOB</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div >
                    <div class="new-provider-body-title">Fornecedor Plus?</div>
                    <div class="new-provider-body-info-checks-line">
                        <div style="display: flex; align-items: center; cursor: pointer;" v-on:click="setProviderPlus(true)">
                            <span v-if="provider.is_plus" class="material-icons-outlined new-provider-body-info-check-icon">check_box</span>
                            <span v-else class="material-icons-outlined new-provider-body-info-check-icon">check_box_outline_blank</span>
                            <div class="new-provider-body-info-check-text">Sim</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer;" v-on:click="setProviderPlus(false)">
                            <span v-if="!provider.is_plus" class="material-icons-outlined new-provider-body-info-check-icon">check_box</span>
                            <span v-else class="material-icons-outlined new-provider-body-info-check-icon">check_box_outline_blank</span>
                            <div class="new-provider-body-info-check-text">Não</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ProviderService from "@/services/v3/provider.service";
// import CategoryService from "@/services/v3/category.service";
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'

export default {
	mixins: [ loaderMixin ],
    props: ['provider', 'getCnpjInfo'],
	components: {
		ValidationProvider,
		ValidationObserver,
        StandardInput
   	},
  	data() {
		return {
            svc: new ProviderService(),
            // cat_svc: new CategoryService(),
            categories: [],
            resales: [],
            request_types: [],
            sectors: []
		};
	},
  	methods: {
        setSocial(value) { 
            this.provider.head_office.social_reazon = value
            this.provider.social_reazon = value
        },
        setEmail(value) { 
            this.provider.manager.email = value
            this.provider.head_office.email = value
            this.provider.email = value
        },
        setCnpj(value) {
            this.provider.head_office.cnpj = value 
            this.provider.cnpj = value
            let cnpj = this.provider.head_office.cnpj.replace('/', '')
			cnpj = cnpj.replace('-', '')
			cnpj = cnpj.replace(' ', '')
			cnpj = cnpj.replace(' ', '')
            if (cnpj.length > 13) this.getCnpjInfo(cnpj)
        },
        setFantasyName(value) { 
            console.log(123123);
            
            this.provider.head_office.trading_name = value 
            this.provider.trading_name = value
            this.provider.name = value
            this.$set(this.provider.manager, 'name', value)
            this.$set(this.provider.manager, 'login', value)
        },
        setContact(value) { this.provider.contact = value },
        setSite(value) { this.provider.site_url = value },
        setPhone(value) { 
            this.provider.phone = value 
            this.provider.head_office.phone = value 
        },
        setDeliveryType(value) {
            this.provider.delivery_type_id = value
            this.$forceUpdate()
        },
        setProviderPlus(value) {
            this.provider.is_plus = value
            this.$forceUpdate()
        }
	},
	mounted() {
	}
};
</script>

<style lang="scss" scoped>@import "./new-provider.modalV2";</style>