<template>
    <div class="monitors">
        <div class="body">
            <div class="map-page-guide">Mapa de Fornecedores</div>
            <div class="map-page-title">Mapa de Fornecedores</div>
            <cc-loader-full v-if="loading"/>
            <div class="explore-inputs-container">
                <StandardInput
                    class="mobile-width"
                    :title="'Estado'"
                    :type="'select'"
                    :action="(evt) => filter_state(evt)"
                    :list="formatStatesList(statusStates)"/>
                <StandardInput
                    class="mobile-width"
                    :title="'Cidade'"
                    :type="'select'"
                    :action="(evt) => setCity(evt)"
                    :list="formatStatesList(cities)"/>
                <StandardInput
                    class="mobile-width"
                    :title="'Fornecedor'"
                    :type="'text'"
                    :action="name => filters.name = name"/>
                <p class="count">Fornecedores: {{ total }}</p>
            </div>
            
            <div class="map-container" v-if="!loading">
                <l-map
                    :zoom="zoom"
                    :center="center"
                    class="map-view"
                    style=""
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-control class="map-controls">
                        <toggle-button
                            v-model="filters.related"
                            :color="{ checked: 'var(--primary-color)', unchecked: '#4267B2' }"
                            :font-size="12"
                            :height="30"
                            :sync="true"
                            @change="(evt) => control_filter('related', evt)"
                            :labels="{ checked: 'Meus Fornecedores', unchecked: 'Meus Fornecedores' }"
                            :width="toggle_width-10" />
                    </l-control>
                    <!-- <l-control class="map-controls">
                        <toggle-button
                            v-model="filters.plus"
                            :color="{ checked: 'var(--primary-color)', unchecked: '#4267B2' }"
                            :font-size="12"
                            :height="30"
                            :sync="true"
                            @change="(evt) => control_filter('plus', evt)"
                            :labels="{ checked: 'Parceiros Club', unchecked: 'Parceiros Club' }"
                            :width="toggle_width-35" />
                    </l-control> -->
                    <!-- <l-control class="map-controls">
                        <cc-input
                            placeholder="Fornecedor"
                            v-model="filters.name"
                            classes="'name'" />
                    </l-control> -->
                    <l-marker
                        :lat-lng="coords(prov.head_office)"
                        v-for="prov in valid_providers"
                        :key="prov.id">
                        <l-icon
                            class-name="provider-icon">
                            <i class="fas fa-map-marker-alt" ></i>
                        </l-icon>
                        <l-tooltip :options="{ permanent: false, interactive: true }">
                            <div style="display: flex;">
                                <picture >
                                    <img :src="prov.cover_url" style="width: 5vw;" alt="">
                                </picture>
                                <div>
                                    {{ prov.cnpj | VMask('##.###.###/####-##')}} <br>
                                    {{ prov.social_reazon }} <br>
                                    {{ prov.trading_name }} <br>
                                    {{ prov.phone ? prov.phone : '--' }}
                                </div>
                                
                            </div>
                        </l-tooltip>
                    </l-marker>
                    <l-marker
                        :lat-lng="coords(store)"
                        v-for="store in stores"
                        :key="store.id">
                        <l-icon
                            class-name="store-icon">
                            <i class="fas fa-store-alt shadow"></i>
                        </l-icon>
                        <l-tooltip :options="{ permanent: false, interactive: true }">
                        <div @click="innerClick">
                            {{ store.name }}
                            <p v-show="showParagraph">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                            sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                            Donec finibus semper metus id malesuada.
                            </p>
                        </div>
                        </l-tooltip>
                    </l-marker>
                </l-map>

                <div class="list-container">
                    <div v-for="prov in providers" @click="selectProvider(prov)" :key="prov.id" class="line-container" :class="{'selected-line': selectedProvider == prov}">
                       <p class="list-line" style="justify-content: left; gap: 2vw;">
                            <span v-if="selectedProvider == prov" class="material-icons-outlined">expand_more</span>
                            <span v-else class="material-icons-outlined">expand_less</span>
                            {{ prov.social_reazon }}
                        </p>
                        <div class="list-line" v-if="selectedProvider == prov">
                            <span class="line-title">CNPJ</span>
                            <span>{{ prov.cnpj | VMask('##.###.###/####-##')}} </span>
                            
                        </div>
                        <div class="list-line" v-if="selectedProvider == prov">
                            <span class="line-title">Numero</span>
                            <span>{{ prov.phone || '--' }}</span>
                        </div>
                        <div class="list-line" v-if="selectedProvider == prov">
                            <span class="line-title">Contato</span>
                            <span>{{ prov.contact}} </span>
                        </div>
                        <div class="list-line" v-if="selectedProvider == prov">
                            <span class="line-title">Email</span>
                            <span class="email-field">{{ prov.email}} </span>
                        </div>
                    </div>
                    
                </div>
            </div>

            
        </div>
    </div>
</template>
<script>

import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LControl } from "vue2-leaflet";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { perm_mixin } from '@/mixins/permission.mixin'
import ProviderService from '@/services/v3/provider.service'
import StoreService from '@/services/v3/stores/store.service'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import UserService from "@/services/v1/user.service";

export default {
    components: {
        LMap,
        LIcon,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LControl,
        StandardInput
    },
    mixins: [ perm_mixin, ],
    data(){
        return {
            svc: new ProviderService(),
            store_svc: new StoreService(),
			user_svc: new UserService(),
            allCities: [],
            cities: [],
            zoom: 10,
            filters: {
                related: true,
                unrelated: false,
                name: ''
            },
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            toggle_width: 160,
            showParagraph: false,
            total: undefined,
            providers: [],
            center: [-8.0538900,-34.8811100],
            stores: [],
            state: {},
            loading: true,
            showMap: true,
            selectedProvider: undefined,
            statusStates: []
        }
    },
    watch: {
        'filters.name': function(val) {
            let rx = new RegExp(val, 'gi')
            this.providers.forEach(p => p.visible = false)
            this.providers
                .filter(p => p.name.match(rx) || !val)
                .forEach(p => p.visible = true)
        }
    },
    computed: {
        head_office_state() {
            return this.stores.find(s => s.is_head_office).state.fu
        },
        // center() {
        //     return this.coords(this.stores.find(s => s.is_head_office))
        // },
        valid_providers() {
            return this.providers
            .filter(p => (this.selectedProvider && this.selectedProvider == p) || !this.selectedProvider)
            .filter(p => p.visible)
        }
    },
    methods: {
        filter_state(evt) {
            this.filters.related = false
            //this.filters.plus = false
            this.cities = this.allCities.filter(city => city.value.est_id == evt.value.est_id)
            this.state = evt.value
            this.load({ state: evt.value.est_sigla })
        },
        selectProvider(p){
            if (this.selectedProvider == p) this.selectedProvider = undefined
            else this.selectedProvider = p
            this.centerUpdate(this.coords(p.head_office))
        },
        setCity(city){
            this.loading = true
            this.user_svc.getCityIfo(city.key, this.state.est_nome).then(resp => {
                if (resp.data)this.centerUpdate([resp.data[0].lat, resp.data[0].lon])
                this.providers.forEach(p => {
                    p.head_office.city.name != city.key ? p.visible = false : p.visible = true
                })
                
                this.total = this.providers.filter(p => p.visible).length
                this.loading = false
            })
        },
        getStateCord(state) {
            if (state == 'SP') return [-23.5475000,-46.6361100]
            if (state == 'AC') return [-9.9747200 , -67.8100000]
            if (state == 'AL') return [-9.6658300 , -35.7352800]
            if (state == 'AP') return [0.0388900 , -51.0663900]
            if (state == 'AM') return [ -3.1019400 , -60.0250000]
            if (state == 'PA') return [-1.4558300 , -48.5044400]
            if (state == 'RO') return [-8.7619400 , -63.9038900]
            if (state == 'RR') return [2.8197200 , -60.6733300]
            if (state == 'TO') return [-10.1674500 , -48.3276600]
            if (state == 'MA') return [-2.5297200 , -44.3027800]
            if (state == 'PI') return [-5.0891700 , -42.8019400]
            if (state == 'CE') return [-3.7172200 , -38.5430600]
            if (state == 'RN') return [-5.7950000 , -35.2094400]
            if (state == 'PB') return [-7.1150000 , -34.8630600]
            if (state == 'PE') return [-8.0538900 , -34.8811100]
            if (state == 'SE') return [-10.9111100 , -37.0716700]
            if (state == 'BA') return [-12.9711100 , -38.5108300]
            if (state == 'GO') return [-16.6786100 , -49.2538900]
            if (state == 'MT') return [-15.5961100 , -56.0966700]
            if (state == 'MS') return [-20.4427800 , -54.6463900]
            if (state == 'DF') return [-15.7797200 , -47.9297200]
            if (state == 'RJ') return [-22.9027800 , -43.2075000]
            if (state == 'MG') return [-19.9208300 , -43.9377800]
            if (state == 'ES') return [-20.3194400 , -40.3377800]
            if (state == 'PR') return [-25.4277800 , -49.2730600]
            if (state == 'SC') return [-27.5966700 , -48.5491700]
            if (state == 'RS') return [-30.0330600 , -51.2300000]
        },
        control_filter(cond, evt) {
            switch(cond) {
                case 'related':
                    this.filters = { related: evt.value, plus: false }
                    this.load({ related: 1 })
                    break;
                // case 'plus':
                //     this.filters = { related: false, plus: evt.value }
                //     this.load({ plus: 1 })
                //     break;
            }
        },
        coords(store) {
            let lat = parseFloat(store.geolocation.latitude)
            let long = parseFloat(store.geolocation.longitude)
            if(isNaN(lat) || isNaN(long)) {
                return
            }
            return latLng(lat, long)
        },
        zoomUpdate(zoom) {
            this.zoom = zoom;
        },
        centerUpdate(center) {
            this.center = center;
        },
        innerClick() {
            alert("Click!");
        },
        load_stores() {
            this.store_svc.load().then(response => {
                this.stores = response.data
                const head = this.stores.find(s => s.is_head_office)
                this.center = this.coords(head)
                this.load({ state: head.state.fu })
            })
        },
        getStates(){
                this.user_svc.region_data().then(response => response.data).then(response => {
                 let states = response.estados;
				 this.allCities = response.cidades.map(city => {return {key: city.cid_nome, value: city}})
                 let filtroStates = [];
                 for (let index = 0; index < states.length; index++) {
                        filtroStates.push({
                            key:states[index].est_nome,
                            value:states[index]
                        })

                 }
                 this.statusStates = filtroStates;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });

        },
        load(params) {
            this.loading = true
            this.svc.load_providers(params).then(response => {
                this.providers = response.data.filter(p => p.head_office)
                    .filter(p => p.head_office.geolocation)
                    .filter(p => !isNaN(p.head_office.geolocation.latitude))
                    .filter(p => !isNaN(p.head_office.geolocation.longitude)).map(p => { return { ...p, visible: true } })
                this.total = response.data.length
                this.loading = false
                if (params.state) this.centerUpdate(this.getStateCord(params.state))
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        formatStatesList (list) {
            let response = []
            list.forEach(element => {
                response.push({
                    value: element,
                    text: element.key
                })
            });
            return response
        },
    },
    mounted() {
        this.getStates()
        this.load_stores()
    }
}
</script>

<style lang="scss" scoped>
    @import './explore.page';
    .cover-img img {
        width: 50px;
    }
    .map-controls {
        padding-top: 10px;
        margin: auto;
        padding-right: 10px;
        width: 170px;
    }
    .monitors {
        padding-bottom: 5vw;
    }
    .map-page-guide{
        font-weight: 400;
        font-size: 1em;
        color: #ABABAB;
    }
    .mobile-width{
        width: 20%;
    }
    .map-page-title{
        font-weight: 600;
        font-size: 2.25em;
        color: #605F5F;
        margin-top: 1vh;
        margin-bottom: 2vh;
    }
    .count{
        background-color: #2acd721a;
        padding: 1.4vh 0.5vw;
        border-radius: 8px;
        color: #30aa4c;
        font-weight: 300;
        font-size: 1.5em;
        margin: 0;
    }
    .explore-inputs-container{
        display: flex; 
        gap: 6vw; 
        align-items: flex-end; 
        margin-bottom: 3vw;
    }
    .map-container{
        display: flex;
        justify-content: space-between;
        height: 80vh;
    }
    .map-view{
        height: 100%;
        z-index: 0; 
        width: 70%;
    }
    .list-container{
        width: 25%;
        height: 100%;
        overflow: auto;
        padding: 0 2vw;
    }
    .line-container{
        padding: 0 0.5vw;
        margin-bottom: 2vw;
        border-radius: 8px;
        &:hover{
            outline: 1px solid var(--primary-color);
        }
    }
    .list-line{
        font-weight: 300;
        font-size: 1em;
        color: #605F5F;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;   
    }
    .line-title{
        font-weight: bold;
        font-size: 1.1em;
    }
    .selected-line{
        outline: 1px solid var(--primary-color);
    }
    .line-show-icon{
        color: gray;
    }
    .line-show-icon-active{
        color: var(--primary-color);
    }
    .email-field{
        width: 80%;
        text-align: end;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media only screen and (max-width: 800px) {
        .explore-inputs-container{
            flex-wrap: wrap;
            font-size: 0.7em;
        }
        .mobile-width{
            width: 45%;
        }
        .map-container{
            flex-direction: column;
            gap: 4vh;
        }
        .map-view{
            width: 100%;
            height: 90vh;
        }
        .list-container{
            width: 100%;
        }
    }
</style>
