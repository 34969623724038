<style lang="scss" scoped>
    @import "./_offers.component";
    .left-align {
  text-align: left !important;
}
.makeorder-page-table-header{
    display: flex;
    padding: 1vh 0.5vw;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
    margin-top: 2vh;
}
.makeorder-page-table-colapse-width{width: 5%;}
.makeorder-page-table-name-width{flex: 1}
.makeorder-page-table-req-width{width: 7%;}
.makeorder-page-table-quantity-width{width: 7%;}
.makeorder-page-table-orders-width{width: 7%;}
.makeorder-page-table-unity-width{width: 12%;}
.makeorder-page-table-stock-width{width: 7%;}
.makeorder-page-table-digi-width{
    width: 10%;
    display: flex;
    align-items: center;
}
.makeorder-page-table-uniprice-width{
    width: 10%;
    display: flex;
    align-items: center;
}
.makeorder-page-table-totalprice-width{width: 7%;}
.makeorder-page-table-row{
    display: flex;
    padding: 1vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
}
.makeorder-page-table-name-text{
    color: #605F5F;
    margin-right: 1vw;
    font-weight: 600;
}
.makeorder-page-table-colapse-icon{
    color: var(--primary-color);
    font-size: 2em;
    cursor: pointer;
}
.makeorder-page-table-digi-text{font-weight: 600;}
.makeorder-page-table-digi-icon{
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.makeorder-page-table-uniprice-icon{
    color: #4267B2;
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.makeorder-page-table-expand{margin-left: 1.5vw;}
.makeorder-page-table-expand-title{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.1em;
    color: #A1A1A1;
    margin-bottom: 2vh;
}
.makeorder-page-table-expand-row{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
    padding: 1vh 1vw;
}
.makeorder-page-table-expand-colum{width: 10%;}
.makeorder-page-table-expand-colum-name{flex: 1}
.page-table-line-special{background: #F7F7F7;}
.makeorder-page-table-input{
    border: none;
    background-color: transparent;
}
.makeorder-page-table-input:focus{
    outline: none;
}
.makeorder-page-table-input-container{
    display: flex;
    background: rgba(255, 113, 16, 0.04);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    width: 85%;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.75vw;
}
.makeorder-page-table-input-container-up{border: 1px solid #30AA4C;}
.makeorder-page-table-input-container-down{border: 1px solid #EF443B;}
.makeorder-page-table-orders-input{
    text-align: left;
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding-left: 1vw;
}
.makeorder-page-table-orders-input:focus{
    background-color: lightsalmon;
}
.makeorder-page-table-orders-input-disabled{
    background-color: lightgray;
    border: 1px solid #505050;
    cursor: default;
}
.makeorder-page-table-orders-input-up{
    background-color: lightcoral;
    color: white;
}
.makeorder-page-table-orders-input-dow{
    background-color: lightgreen;
    color: white;
}
.makeorder-page-table-expand-check{
    color: var(--primary-color);
    cursor: pointer;
    margin-right: 1.5vw;
}
.makeorder-page-table-name-obs{
    color: var(--primary-color);
    font-size: 1em;
}
.page-table-line-special{
    background: #F7F7F7;
}
.input-altered{background-color: #13c7213d; border: 1px solid #0606064d !important;}
.unity {
box-sizing: unset;
.fa-edit {
    margin-left: 10px;
    color: $primary-color;
    cursor: pointer;
}
.unity-wrapper {
    display: inline-block;
    width: 86px;
    img {
        height: 18px;
    }
    input[type=number] {
        border-radius: 10px 0 0 10px;
        border-width: 0;
        width: 50px;
        border: 4px solid #0606064d;
        display: inline-block;
        &:focus {
            border: 3px solid $primary-color;;
            outline: none;
            box-shadow: 0px 0px 0px 3px $primary-color inset;
        }
        &.higher {
            background-color: #13c7213d;
        }
        &.lower {
            background-color: #13c7213d;
        }
    }
    button {
        .fa-arrow-up {
            color: #0033ef9e;
        }
        .fa-arrow-down {
            color: #fb7272;
        }
        &.save {
            float: right;
            color: green;
            height: 32px;
            padding: 4px;
            position: relative;
            left: -12px;
            border-left: none;
            width: 1.5rem;
            border-radius: 0px 10px 10px 0px !important;
            i {
                border-color: #0606064d;
                left: 0.4rem;
                position: absolute;
                font-size: 0.9rem;
                margin: 0;
            }
        }
    }
}
}
</style>

<template>
    <!-- <div>
        <table class="typing-order-header">
            <thead>
                <tr>
                    <th>Produto/Marca</th>
                    <th class="orders">Req</th>
                    <th class="center">Qtd. Emb</th>
                    <th class="center">Pedido</th>
                    <th class="center">Und. Compra</th>
                    <th class="center">Estoque</th>
                    <th class="center">Digitação</th>
                    <th class="center">Ult. Compra</th>
                    <th class="right-align">Total</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(po, idx) in pos" v-bind="po.id">
                    <tr v-bind:key="po.id" class="first">
                        <td class="product-description">
                            <i class="fas fa-angle-right" v-if="!expanded(po.id)" @click="expand(po)"></i>
                            <i class="fas fa-angle-down" v-if="expanded(po.id)" @click="expand(po)"></i>
                            <span class="name">{{ po.product.name }}</span>
                            <br />
                            <span class="provider">{{ po.offer.brand ? po.offer.brand.name : '' }}</span>
                            <template v-if="po.offer.obs">
                                <span class="obs-desc">
                                    <span class="observation">obs:</span>
                                    {{ po.offer.obs }}
                                </span>
                            </template>
                        </td>
                        <td class="orders">{{ po.subrequest.quantity }}</td>
                        <td class="orders">
                            {{ po.offer.quantity }}
                        </td>
                        <td class="unity">
                            <div class="unity-wrapper">
                                <input
                                    min="0"
                                    type="number"
                                    :data-idx="idx"
                                    ref="product-amount"
                                    :data-visible="true"
                                    :class="{
                                        disabled : po.status == 'ORDERED',
                                        saved: po.saved
                                    }"
                                    :readonly="po.status == 'ORDERED'"
                                    v-model="po.quantity"
                                    v-on:keydown="process_event($event, po)"
                                    v-on:blur="process_event($event, po)"
                                    :key="'qtd-' + idx"
                                />
                                <button class="save" :disabled="order">
                                    <img :class="{ hidden: !po.product.is_loading }" src="/images/loader.svg" />
                                    <i v-if="!po.product.is_loading && po.suggestion > po.quantity" class="fa fa-arrow-down" ></i>
                                    <i v-if="!po.product.is_loading && po.suggestion < po.quantity" class="fa fa-arrow-up"></i>
                                    <i v-if="!po.product.is_loading && po.suggestion == po.quantity" class="fa fa-check" ></i>
                                </button>
                            </div>
                        </td>
                        <td class="unity left-align">
                            {{ po.product.packing }}
                            <i class="far fa-edit"
                                @click="edit_wrap(product)"
                                v-if="po.product.flag_generico == 0 && !po.product.pro_principal && !po.id_pedido" />
                        </td>
                        <td class="storage">{{ po.product.estoque ? po.product.estoque : '' }}</td>
                        <td class="typing-price"
                            :class="{
                                higher: po.product.menorPreco > po.product.ultimaCompra,
                                lower: po.product.menorPreco < po.product.ultimaCompra
                            }">
                            <span>{{ po.offer.price | currency }}</span>
                            <i v-show="po.status == 'NOT_ORDERED'"
                                class="fa fa-handshake-o"
                                title="Negociar"
                                @click="open_negotiation_modal(po)" />
                        </td>
                        <td>
                            <div class="last-purchase-actions">
                                <i  v-show="po.offer.request_product.metadata.length > 0 &&
                                            po.offer.request_product.metadata[0].last_order_item"
                                    @click="show_orders(po)"
                                    title="Histórico de Pedidos"
                                    class="fas fa-clipboard-list"></i>
                                {{ po.offer.request_product.metadata[0]
                                    ? po.offer.request_product.metadata[0] &&
                                        po.offer.request_product.metadata[0].last_order_item &&
                                        po.offer.request_product.metadata[0].last_order_item.price
                                    : 0 | currency }}
                            </div>
                        </td>
                        <td class="unity right-align">{{ po.offer.price*po.quantity | currency }}</td>
                    </tr>
                    <OffersByProduct
                        @update_po="update_po"
                        :po="po"
                        :order="order"
                        :store_id="store_id"
                        v-if="expanded(po.id)"
                        :key="'po-prod-' + po.product.id" />
                </template>
            </tbody>
        </table>
        <NegotiationModal
            @close="close_negotitation"
            :seller="seller"
            :po="current_po"
            v-if="is_negotiation_modal_open && current_po"
        />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="current_product"
            @close="close_wrap"
        />
        <ProductOrdersModal
            :product_id="current_po.offer.brand_id ? current_po.offer.brand_id : current_po.product.id"
            :product_name="current_po.product.name"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
    </div> -->
    <div style="overflow-x: auto;">
        <div style="min-width: 1000px;">
            <div class="makeorder-page-table-header">
                <div class="makeorder-page-table-colapse-width"></div>
                <div class="makeorder-page-table-name-width">Produto/Marca</div>
                <div class="makeorder-page-table-req-width">Req.</div>
                <div class="makeorder-page-table-quantity-width">Qtd Emb.</div>
                <div class="makeorder-page-table-orders-width">Pedido</div>
                <div class="makeorder-page-table-unity-width">Und Compra</div>
                <div class="makeorder-page-table-stock-width">Estoque</div>
                <div class="makeorder-page-table-digi-width">Digitação</div>
                <div class="makeorder-page-table-uniprice-width">Ult. Compra</div>
                <div class="makeorder-page-table-totalprice-width">Total</div>
            </div>
            <div v-for="(po, idx) in pos" v-bind:key="po.id">
                <div class="makeorder-page-table-row">
                    <div class="makeorder-page-table-colapse-width">
                        <span v-if="!expanded(po.id)" @click="expand(po)" style="font-size: 1.3em;" class="material-icons-outlined makeorder-page-table-colapse-icon">arrow_forward_ios</span>
                        <span v-else @click="expand(po)" class="material-icons-outlined makeorder-page-table-colapse-icon">expand_more</span>
                    </div>
                    <div class="makeorder-page-table-name-width makeorder-page-table-name-text">
                        <div>{{ po.product.name }}</div>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div class="makeorder-page-table-name-obs">{{ po.offer.brand ? po.offer.brand.name : '' }}</div>
                            <div v-if="po.offer.obs" style="display: flex; align-items: center;">
                                <div style="color: var(--primary-color); background-color: lightyellow; padding: 1px 3px; border: 1px solid var(--primary-color); border-radius: 5px;">Obs:</div>
                                <div style="margin: 0 1vw;" class="makeorder-page-table-name-text">{{ po.offer.obs }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="makeorder-page-table-req-width">{{ po.subrequest.quantity }}</div>
                    <div class="makeorder-page-table-quantity-width">{{ !po.subrequest.request_product.product.is_generic ? po.subrequest.request_product.product.packing_quantity : po.offer.quantity }}</div>
                    <div class="makeorder-page-table-orders-width">
                        <td class="unity">
                            <div class="unity-wrapper">
                                <input
                                    min="0"
                                    type="number"
                                    :data-idx="idx"
                                    ref="product-amount"
                                    :data-visible="true"
                                    :class="{
                                        disabled : po.status == 'ORDERED',
                                        saved: po.saved
                                    }"
                                    :readonly="po.status == 'ORDERED'"
                                    v-model="po.quantity"
                                    v-on:keydown="process_event($event, po)"
                                    v-on:blur="process_event($event, po)"
                                    :key="'qtd-' + idx"
                                />
                                <button class="save" :disabled="order">
                                    <img :class="{ hidden: !po.product.is_loading }" src="/images/loader.svg" />
                                    <i v-if="!po.product.is_loading && po.suggestion > po.quantity" class="fa fa-arrow-down" ></i>
                                    <i v-if="!po.product.is_loading && po.suggestion < po.quantity" class="fa fa-arrow-up"></i>
                                    <i v-if="!po.product.is_loading && po.suggestion == po.quantity" class="fa fa-check" ></i>
                                </button>
                            </div>
                        </td>
                    </div>
                    <div class="makeorder-page-table-unity-width">
                        {{ po.product.packing }}
                        <i class="far fa-edit" style="cursor: pointer;" @click="edit_wrap(product)" v-if="can('PRODUCT', 'UPDATE') && po.product.flag_generico == 0 && !po.product.pro_principal && !po.id_pedido"/>
                    </div>
                    <div class="makeorder-page-table-stock-width">{{ po.product.estoque ? po.product.estoque : '' }}</div>
                    <div class="makeorder-page-table-digi-width makeorder-page-table-digi-text">
                        <div :class="{
                                higher: po.product.menorPreco > po.product.ultimaCompra,
                                lower: po.product.menorPreco < po.product.ultimaCompra
                            }">{{ po.offer.price | currency }}</div>
                    </div>
                    <div class="makeorder-page-table-uniprice-width">
                        <i v-show="po.offer.request_product.metadata.length > 0 && po.offer.request_product.metadata[0].last_order_item" @click="show_orders(po)" title="Histórico de Pedidos" class="fas fa-clipboard-list makeorder-page-table-uniprice-icon"></i>
                        {{ po.offer.request_product.metadata[0]
                            ? po.offer.request_product.metadata[0] &&
                                po.offer.request_product.metadata[0].last_order_item &&
                                po.offer.request_product.metadata[0].last_order_item.price
                            : 0 | currency }}
                    </div>
                    <div class="makeorder-page-table-totalprice-width">{{ getTotal(po) | currency }}</div>
                </div>
                <OffersByProduct
                        @update_po="update_po"
                        :po="po"
                        :order="order"
                        :store_id="store_id"
                        v-if="expanded(po.id)"
                        :key="'po-prod-' + po.product.id" />
            </div>
        </div>
        <NegotiationModal
            @close="close_negotitation"
            :seller="seller"
            :po="current_po"
            v-if="is_negotiation_modal_open && current_po"
        />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="current_product"
            @close="close_wrap"
        />
        <ProductOrdersModal
            :product_id="current_po.offer.brand_id ? current_po.offer.brand_id : current_po.product.id"
            :product_name="current_po.product.name"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
    </div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import OffersByProduct from './_offers-by-product.componentV2'
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import OrderService from "@/services/OrderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import ProductOrdersModal from '@/modals/products/orders';
import NegotiationModal from './negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import V3RequestsService from "@/services/v3/request.service"
import PreOrderService from "@/services/v3/requests/pre-order.service"
import PreOrderCollection from '@/models/requests/pre-order.collection'
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ elementNavigationmMixin, perm_mixin ],
    props: {
        request: Object,
        seller: Object,
        store_id: [ Number, String ],
        order: Object
    },
    components: {
        ccPagination: Pagination,
        popoverLastBuy,
        NegotiationModal,
        ProductOrdersModal,
        ProductWrapModal,
        OffersByProduct
    },
    data() {
        return {
            CONSTANTS: CONSTANTS,
            pos: [],
            sellers: [],
            openedPopovers: [],
            orderService: new OrderService(),
            quotationService: new QuotationService(),
            product_svc: new ProductService(),
            svc: new V3RequestsService(),
            po_svc: new PreOrderService(),
            current_seller: null,
            current_typing: null,
            current_po: null,
            selected_status_filter: "WINNERS_ONLY",
            quotationId: [],
            products: [],
            is_product_orders_modal_open: false,
            open_edit_wrap: false,
            subrequest: null,
            expandeds: [],
            is_negotiation_modal_open: false
        };
    },
    methods: {
        expanded(id) {
            return this.expandeds.includes(id)
        },
        getTotal(po){
            return po.offer.price*po.quantity*( po.product && po.product.is_generic == 1 ? 1 : parseFloat(po.offer.quantity))
        },
        expand(po) {
            let expanded = this.expanded(po.id)
            if(expanded) {
                this.expandeds = this.expandeds.filter(i => i != po.id)
            } else {
                this.expandeds.push(po.id)
            }
        },
        close_wrap(reload = false) {
            this.current_product = null;
            this.open_edit_wrap = false
            if(reload) {
                this.emit_total()
            }
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        show_orders(po) {
            this.current_po = po
            this.is_product_orders_modal_open = true
        },
        open_negotiation_modal(po) {
            this.current_po = po;
            this.is_negotiation_modal_open = true
        },
        close_negotitation(with_reload = false) {
            this.current_po = null
            this.is_negotiation_modal_open = false
            this.load_pre_orders()
        },
        print() {
            self.print();
        },
        update_po(pre_order) {
            let po = this.pos.find(po => po.id == pre_order.id)
            if(po) {
                po.quantity = pre_order.quantity
            }
        },
        process_event: function(e, po) {
            this.navigate(e, {
                ref: 'product-amount',
                callback: {
                    function: () => {
                        return po.quantity != po._quantity ? this.update_quantity(po, () => {
                            this.emit_total()
                            this.$set(po, 'saved', true)
                        }) : new Promise((resolve) => resolve())
                    }
                }
            })
        },
        update_quantity(po, callback) {
            let params = {
                offer_id: po.offer.id,
                quantity: po.quantity,
                subrequest_id: po.subrequest.id,
                is_winner: po.quantity > 0 ? 1 : 0
            }
            return this.svc.toggle(params).then(response => response.data).then(callback);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        load_pre_orders() {
            return this.po_svc.load(this.request.id, {
                with_product: true
            })
            .then(response => response.data)
            .then(response => {
                this.emit_loaded(response)
                this.pos = response.filter(po => po.subrequest.store_id == this.store_id && po.offer.seller_id == this.seller.id)
                this.pos.forEach(po => {
                    po.product = po.subrequest.request_product.product
                    po._quantity = po.quantity
                    po.suggestion = this.calculate_suggestion(po, po.offer)
                    this.emit_total()
                })
            })
        },
        calculate_suggestion(po, offer) {
            return Math.round(po.subrequest.quantity / offer.quantity) * offer.quantity
        },
        emit_total() {
            let resp = 0
            this.pos.forEach(po=>{
                resp += this.getTotal(po)
            })
            this.$emit('total', resp)
        },
        emit_loaded(pos) {
            this.$emit('loaded', pos)
        }
    },
    async mounted() {
        await this.load_pre_orders()
        this.emit_total()
    }
};
</script>
