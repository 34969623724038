<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: #fff;
  min-width: 50vw;
  border-radius: 12px;
}
.new-request-modal__main-container {
  width: 60vw;
  height: 110vh;
}
.manage-categories__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.manage-categories__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.manage-categories__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.new-page__actions-container {
  display: flex;
  align-items: center;
  margin: 1.5em 2em;
  justify-content: space-between;
}
.new-page__actions-wrapper {}
.new-page__search-input {
  width: 30vw;
  height: 4vw;
  border: 1px solid #c2c2c3;
  border-radius: 5px;
  padding-left: 0.5em;
  color: #898989;
}
.new-page__actions-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.new-page__left-actions {}
.new-page__right-actions {
  display: flex;
}
.edit-providers-btn {
  margin-right: 1em;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.observation-input {
  width: 55vw;
  height: 20vh;
  border: 1px solid #c2c2c3;
  border-radius: 5px;
  padding: 0 0 15vh 1vw;
  /* word-wrap: break-word; */
}
.checkbox-title {}
.overflow-container{
  overflow-x: auto;
  max-height: 50vh;
}
.modal-table-container{
  min-height: 40vh;
}
.new-page__table-container {
  margin: 2em;
}

.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 2vh 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-colum{
  padding-left: 1vw;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 13px;
  color: #605F5F;
  margin-bottom: 2vh;
}
.checkbox-width {
  width: 8%;
}
.provider-width {
  width: 46%;
}
.seller-width {
  width: 46%;
}
.select-width {
  width: 25vw !important;
}
.datetime-width {
  width: 22vw !important;
  border-radius: 8px !important;
}

@media only screen and (max-width: 890px) {
  .mobile-container {
    flex-direction: column;
    justify-content: center;
  }
  .select-width {
    width: 35vw !important;
  }
  .datetime-width {
    width: 31vw !important;
  }
  .obs-title-mobile {
    display: flex;
    justify-content: center;
  }
  .observation-input {
    width: 35vw;
    height: 8vh;
    padding: 0 0 5vh 1vw;
  }
  .new-page__actions-wrapper {
    margin: 0 auto;
  }
  .new-page__search-input {
    width: 50vw;
    height: 6vw;
  }
  .new-page__left-actions {
    margin: 0.5em 0em;
  }
  .new-request-modal__main-container {
    height: 100vh;
  }
}

@media only screen and (max-height: 710px) {
  .new-request-modal__main-container {
    height: 110vh;
  }
}

@media only screen and (max-width: 680px) {
  .select-width {
    width: 35vw !important;
  }
  .datetime-width {
    width: 30vw !important;
  }
  .manage-categories__header-title {
    font-size: 20px;
  }
  .new-page__actions-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 590px) {
  .new-page__actions-container {
    justify-content: none;
    flex-direction: column;
  }
  .new-page__right-actions {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 400px) {
  .new-request-modal__main-container {
    width: 90vw;
  }
  .new-page__search-input {
    height: 8vw;
  }
}

@import '@/sass/commons/_variables';

/deep/ {

	.sub-title {
		padding: 0;
		text-transform: uppercase;
		color: $orange;
		font-weight: 600;
	}

	.multiselect__single {
		color: black !important;
	}

	.multiselect__option.multiselect__option--highlight {
		background-color: gray !important;
	}

	.modal-body {
		border-radius: 5px;
		padding: 2rem;
		overflow: auto !important;
	}
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="new-request-modal__main-container">
        <div class="manage-categories__header-container">
          <span class="manage-categories__header-title">
            Enviar Cotação
          </span>
          <span @click="closeDialog" class="material-icons manage-categories__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="new-page__actions-container">
          <div class="new-page__left-actions">
            <div class="new-page__actions-wrapper">
              <Button v-if="show_sellers" title="Voltar" :text="'Voltar'" :iconName="'arrow_back'" :action="() => { show_sellers = false}" />
	        </div>
            <div class="new-page__actions-wrapper">
              <Button v-if="!show_sellers" title="Voltar" :text="'Voltar'" :iconName="'arrow_back'" :action="closeDialog" />
	        </div>
          </div>
          <div class="new-page__right-actions">
            <div class="new-page__actions-wrapper">
              <Button title="Enviar" :text="'Enviar'" :iconName="'email'" :action="sendResuply" />
	        </div>
          </div>
        </div>
        <template v-if="!show_sellers">
          <div class="modal-mask" v-if="loading">
            <cc-loader style="margin-top: 10vh"/>
          </div>
          <div v-else>
            <div class="new-page__actions-container mobile-container">
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Data Entrega</span>
                </div>
                <div class="page-filters__right-select-wrapper">
                  <datetime :min-datetime="min_date" ref="datepicker" v-model="ressuply.expiration_date" auto :minute-step=10 type="datetime" class="form-control datetime-width"></datetime>
                  <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                    today
                  </span>
                </div>
              </div>
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Tipo de Entrega</span>
                </div>
                <cc-select-v2 class="select-width"
                              :tracker="'code'"
                              :text="'label'"
                              :options="deliveryTypes"
                              v-model="ressuply.delivery_type">
                </cc-select-v2>
              </div>
            </div>
            <div class="new-page__actions-container mobile-container">
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Forma de Pagamento</span>
                </div>
                <cc-select-v2 class="select-width"
                              :placeholder="'Selecione uma empresa'"
                              :tracker="'code'"
                              :text="'label'"
                              :options="payment_types"
                            v-model="ressuply.payment_type">
                </cc-select-v2>
              </div>
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Selecionar Vendedor</span>
                </div>
                <cc-select-v2 class="select-width"
                              :placeholder="'Selecione um vendedor'"
                              :tracker="'id'"
                              :text="'name'"
                              :options="sellers"
                              v-model="ressuply.seller_id">
                </cc-select-v2>
              </div>
            </div>
            <div class="new-page__actions-container mobile-container">
                <!-- <div class="new-page__actions-wrapper">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Plano de Pagamento entrega</span>
                    </div>
                    <cc-select-v2 class="select-width"
                                    :placeholder="'Selecione um plano'"
                                    :tracker="'code'"
                                    :text="'label'"
                                    :options="payment_types"
                                v-model="ressuply.delivery.form">
                    </cc-select-v2>
                </div> -->
                <div class="new-page__actions-wrapper">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Fat. Mínimo (R$) </span>
                    </div>
                    <StandardInput
                        class="standard-input-width"
                        :width="'25vw'"
                        :placeholder="'R$ 100,00'"
                        :type="'money'"
                        :action="(val) => ressuply.delivery.minimum_billing = val" />
                </div>
            </div>
            <div class="new-page__actions-container mobile-container">
                <!-- <div class="new-page__actions-wrapper">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Data Final entrega</span>
                    </div>
                    <div class="page-filters__right-select-wrapper">
                        <datetime :min-datetime="min_date" ref="datepicker" v-model="ressuply.delivery.date" auto :minute-step=10 type="datetime" class="form-control datetime-width"></datetime>
                        <span @click="open_datepicker_delivery(delivery)" class="material-icons-outlined date-icon">
                        today
                        </span>
                    </div>
                </div> -->
                <!-- <div class="new-page__actions-wrapper">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Obs entrega</span>
                    </div>
                    <StandardInputAuto
                        class="standard-input-width"
                        :width="'25vw'"
                        :placeholder="'Observacao..'"
                        :type="'text'"
                        :action="(val) => ressuply.delivery.obs = val" />
                </div> -->
            </div>
            <div class="new-page__actions-container">
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper obs-title-mobile">
                  <span class="new-page__actions-title">Observação Entrega</span>
                </div>
                <input class="observation-input" type="text" v-model="ressuply.delivery.obs">
              </div>
            </div>
            <!-- <div class="new-page__actions-container">
              <cc-checkbox class="checkbox-title" @change="(evt) => ressuply.is_public = evt.target.checked" :checked="ressuply.is_public" :label="'Cotação Pública?'" />
            </div> -->
          </div>
        </template>
        <!-- <template v-if="show_sellers">
          <cc-loader-full v-show="loading" />
          <div class="new-page__actions-container">
            <div class="new-page__actions-wrapper">
		          <div class="new-page__actions-title-wrapper">
		            <span class="new-page__actions-title">Buscar</span>
		          </div>
              <input class="new-page__search-input" @input="filter" :placeholder="'Digite o vendedor ou fornecedor'" type="text" v-model="filters.name">
	          </div>
          </div>
          <div class="new-page__table-container">
            <div class="overflow-container">
              <div class="modal-table-container">
                <div class="modal-table-header">
                  <div class="modal-table-colum checkbox-width">
                    <cc-checkbox :checked="filters.check_all"
                                 @change="(evt) => toggle_check(evt.target.checked)" />
                  </div>
                  <div class="modal-table-colum provider-width">Fornecedor</div>
                  <div class="modal-table-colum seller-width">Vendedor</div>
                </div>
                <div v-for="seller in sellers"
                     :key="seller.usu_id"
                     v-show="seller.visible">
                  <div class="modal-table-row">
                    <div class="modal-table-colum checkbox-width">
                      <cc-checkbox v-model="seller.checked" :checked="seller.checked" />
                    </div>
                    <div class="modal-table-colum provider-width">{{ seller.for_nome }}</div>
                    <div class="modal-table-colum seller-width">{{ seller.usu_nome }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template> -->
      </div>
    </div>
  </div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/v2/products.service";
import RequestServiceV3 from "@/services/v3/request.service";
import { Datetime } from 'vue-datetime';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import StorageService from '@/services/local-storage.service';
import SellerGroupService from "@/services/v2/seller-group.service";
import AuthService from '@/services/AuthService';
import { mapGetters } from 'vuex'
import Button from "@/components/ui/buttons/Standard-Button.vue";
import SellersService from "@/services/v3/commons/sellers.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'

export default {
  props: ["products", "quotation", "closeDialog", "provider_id", "providerName"],
  components: { datetime: Datetime, Button, StandardInput, StandardInputAuto },
  mixins: [loaderMixin],
  data() {
    return {
		min_date: moment().toDate().toISOString(),
        groups: [],
        filters: {
            check_all: true
        },
        show_sellers: false,
        sellers: [],
        client: {},
        prod_svc: new ProductService(),
        sellers_svc: new SellersService(),
        req_svc: new RequestServiceV3(),
		service : new QuotationService(),
        group_svc: new SellerGroupService(),
        auth_svc: new AuthService(),
		payment_types: [],
		deliveryTypes: [
			{
				code: 1,
				label: "CIF"
			},
			{
				code: 2,
				label: "CIF/FOB"
			}
		],
		loading: false,
		ressuply: {
            delivery: {},
            is_public: false,
			dateExpiration: moment()
			.add("2", "days")
			.format("DD/MM/YYYY")
		}
    };
  },
  methods: {
    toggle_check(val) {
        this.sellers.forEach(s => s.checked = val)
        this.filters.check_all = val
        this.$forceUpdate()
        return val
    },
    filter() {
        this.sellers.forEach(s => s.visible = false)
        let rx = new RegExp(this.filters.name, 'gi')
        this.sellers.filter(s => {
          if (!this.filter.name) return true
          if (s.usu_nome && s.usu_nome.match(rx)) return true
          if (s.for_nome && s.for_nome.match(rx)) return true
        })
        .forEach(s => s.visible = true)
    },
	open_datepicker() {
		document.getElementsByClassName('vdatetime-input')[0].click()
	},
    open_datepicker_delivery() {
        document.getElementsByClassName('vdatetime-input')[1].click()
    },
    close() {
      this.$emit("close");
	},
	load_payment_types() {
		this.service.list_payment_types()
		.then(response => response.data)
		.then(data => {
			this.payment_types = data.data.map(p => { return { label: p.ttp_nome, code: p.ttp_id } });
		})
	},
    load_sellers() {
        this.loading = true
        if(this.provider_id)
        this.sellers_svc.load_sellers({ provider_id: this.provider_id })
        .then(response => response.data).then(data => {
            this.sellers = data
            this.loading = false
        }).catch(() => {
            this.loading = false
        })
    },
    getStores() {
      var quotationService = new QuotationService();
      quotationService
        .getStoresClient()
        .then(response => response.data)
        .then(data => {
          this.stores = data.requisitantes.map(store => {
            return {
              value: store.loj_id,
              name: store.loj_descricao
            };
          });
          this.storeSelect = this.stores[0];
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    load_groups() {
        this.group_svc.all()
        .then(response => response.data)
        .then(data => this.groups = data)
        .catch(error => ErrorHandlerService.handle(error, this.$store))
	},
	build_products() {
		return this.products.map(p => Object.assign({}, { quantidade: p.quantidade, idProduto: p.pro_id, estoque: 0, observacao: '' }))
	},
    is_valid() {
        return this.ressuply.seller_id &&
            this.ressuply.expiration_date &&
            this.ressuply.delivery.minimum_billing &&
            this.ressuply.payment_type
    },
    sendResuply() {

        if(!this.is_valid()) {
            this.present_error('Preencha a data final, vendedor e dados da entrega')
            return
        }
        this.loading = true;



		var payload = {
			due_date: moment(this.ressuply.expiration_date).format("YYYY-MM-DD HH:mm"),
			delivery_type: this.ressuply.delivery_type ? this.ressuply.delivery_type.code : null,
			pagamento: this.ressuply.payment_type ? this.ressuply.payment_type.label : null,
      obs: ' ',
      is_public: this.ressuply.is_public,
      name: this.providerName,
      seller_id: this.ressuply.seller_id.id,
      type: "REQUEST_WITH_PRE_ORDERS",
      delivery: {
          date: this.ressuply.expiration_date,
          form: this.ressuply.payment_type ? this.ressuply.payment_type.label : null,
          minimum_billing: this.ressuply.delivery.minimum_billing,
          obs: this.ressuply.delivery.obs? this.ressuply.delivery.obs : ' '
      }
		}

        if(this.sellers.length > 0) {
            payload.fornecedores = this.sellers.filter(s => s.checked).map(s => s.usu_id).join(",")
        }

        this.req_svc.send_temps(payload)
        .then(response => response.data)
        .then(data => {
            this.close();
            this.$emit("finish");
        })
        .catch(error => {
            this.loading = false;
            ErrorHandlerService.handle(error, this.$store);
        });
    },
	load_tpc() {
		this.ressuply.delivery_type = this.deliveryTypes.filter(d => d.code == StorageService.tpc())[0];
	},
    load_settings() {
        let DEFAULT_PRIVATE_REQUESTS_SETTING = this.setting('DEFAULT_PRIVATE_REQUESTS')
        this.$set(this.ressuply, 'is_public', DEFAULT_PRIVATE_REQUESTS_SETTING && DEFAULT_PRIVATE_REQUESTS_SETTING.value == '1')
    },
    verify_client_deployed(){
        this.auth_svc.get_user().then(response => response.data).then(({ usuario }) => {
            this.client = usuario.cliente;
            if(this.client.cli_implantado == 2){
                this.load_settings();
            }

        }, err => {
            ErrorHandlerService.handle(err, this.$store, this.$store);
        })
    }
  },
  computed: {
    ...mapGetters({ setting: 'user/setting' })
  },
  mounted() {
    this.load_sellers()
	this.getStores()
	this.load_payment_types()
    this.load_tpc()
    this.load_groups()
    this.verify_client_deployed()
  }
};
</script>
