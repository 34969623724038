export const elementNavigationmMixin = {
    methods: {
        navigate(e, { callback, ref }) {
			let key = getKey(e)
			let inputs = this.$refs[ref].map(c => c.$el || c).filter(c => c.dataset.visible == 'true')
			inputs.sort((a, b) => {
                let pos1 = parseInt(a.dataset.idx)
				let pos2 = parseInt(b.dataset.idx)
				if (pos1 > pos2) return 1
				if (pos2 > pos1) return -1
				return 0
			})
			let idx = inputs.indexOf(e.target)
			let next_idx = idx+1 == inputs.length ? 0 : idx+1
			let prev_idx = idx == 0 ? inputs.length-1 : idx-1

			function getKey(e) {

				if (e.key) return e.key
				let keyFromCode = String.fromCharCode(e.keyCode)

				if (keyFromCode) return keyFromCode;
				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter"
				if (e.keyCode === 16) return "Shift"
				if (e.keyCode === 8) return "Tab"
				// etc
            }

            const default_callback = (x) =>  {
                if(x != undefined) {
                    inputs[x].focus()
                    inputs[x].select()
                }
            }

			if(key == "ArrowUp") {
				e.preventDefault()
				callback ? callback.function(callback.params).then(() => default_callback(prev_idx)) : default_callback(prev_idx)
			} else if(key == "ArrowDown" || key == "Tab" || key == "Enter") {
				e.preventDefault()
                callback ? callback.function(callback.params).then(() => default_callback(next_idx)) : default_callback(next_idx)
            } else if(e.type == "blur") {
				e.preventDefault()
				callback && callback.function(callback.params)
			} else if(key == "Enter") {
				e.preventDefault()
				callback ? callback.function(callback.params).then() : default_callback(idx)
			}
		},
		async navigatrix(e, { callback, x, y }) {
			e.preventDefault()
			let key = getKey(e)
			let inputsy = [], inputsx = []

			for(var ref in this.$refs) {
				if(ref.match(`y-${x}-`)) {
					inputsx.push(this.$refs[ref])
				}
				if(ref.match(new RegExp(`-${y}$`))) {
					inputsy.push(this.$refs[ref])
				}
			}
			inputsy = inputsy.flat()
			inputsx = inputsx.flat()
			let idy = y
			let idx = x

			let next_idx = idy+1
			let prev_idx = idy-1
			let next_idy = idx+1
			let prev_idy = idx-1
			function getKey(e) {

				if (e.key) return e.key
				let keyFromCode = String.fromCharCode(e.keyCode)

				if (keyFromCode) return keyFromCode;
				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter"
				if (e.keyCode === 16) return "Shift"
				if (e.keyCode === 8) return "Tab"
				// etc
			}
            let go = (i, inps, direction = 1, key = 'idx') => {
                let ipt = inps.find(j => j.dataset[key] == i)
                if(ipt == undefined) {
                    // if(direction == 1) {
                    //     ipt = inps[0]
                    // }
                    // if(direction == -1) {
                    //     ipt = inps[inps.length-1]
                    // }
					return
                } else {
					ipt.focus()
                	ipt.select()
				}
            }
			if(key == "ArrowRight") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(next_idx, inputsx, 1)) : go(next_idx, inputsx, 1)
			} else if(key == "ArrowLeft") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(prev_idx, inputsx, -1)) : go(prev_idx, inputsx, -1)
			} else if(key == "ArrowUp") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(prev_idy, inputsy, -1, 'idy')) : go(prev_idy, inputsy, -1, 'idy')
			} else if(key == "ArrowDown" || key == "Tab") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(next_idy, inputsy, 1, 'idy')) : go(next_idy, inputsy, 1, 'idy')
			} else if(e.type == "blur") {
                callback.function ? callback.function(callback.params) : ''
            }
		}
    }
}
