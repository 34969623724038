<template>
  <div>
    <div class="input-title">{{title}}</div>
    <div class="input-containear" v-on:click="openSelect()">
      <input type="text" v-model="productSearch" class="input-value" :placeholder="placeholder">
      <span v-if="!isSelectOpen" class="material-icons input-icon">search</span>
      <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect()">close</span>
    </div>
    <div v-if="isSelectOpen">
      <div class="select-body">
        <div v-for="(x, idx) in list" :key="idx">
          <div v-if="showProduct(x.text)" v-on:click="callAction(x)" class="select-body-line">
            <div class="select-body-line-text">{{x.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSelectOpen" class="iput-close-body" v-on:click="closeSelect()"></div>
  </div>
</template>

<script>
export default {
  name: 'StandardInputListFiltered',
  props: ['title', 'list', 'action', 'placeholder', 'selectWidth', 'width'],
  data () {
    return {
      isSelectOpen: false,
      productSearch: ''
    }
  },
  methods: {
    getWidth(){return 'width: ' + this.width},
    getSelectWidth(){return 'width: ' + this.selectWidth},
    openSelect () { this.isSelectOpen = true },
    closeSelect () { this.isSelectOpen = false },
    showProduct (text) {
      if (this.productSearch === '') return true
      if (text.toLowerCase().includes(this.productSearch.toLowerCase())) return true
      return false
    },
    callAction(x) {
      this.productSearch = x.text
      this.action(x.value)
      this.isSelectOpen = false
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.input-title{
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: #898989;
  margin-bottom: 10px;
}
.input-containear{
  border: 1px solid #c2c2c3;
  font-size: 17px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #B3B3B3;
  cursor: pointer;
}
.input-select-value{
  border: none;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
  flex: 1;
  height: 24px;
}
.input-select-value:focus{
  border: none;
  outline: none;
}
.select-body{
  position: absolute;
  z-index: 3;
  background-color: white;
  overflow-y: scroll;
  max-height: 300px;
}
.select-body-line{
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}
.select-body-line:hover{ background-color: rgba(255, 247, 247, 0.75); }
.select-body-line-text{
  font-weight: 300;
  font-size: 15px;
  color: #505050;
  margin-left: 5px;
}
.iput-close-body{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
</style>