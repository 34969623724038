<template>
    <div class="add-provider-container">
        <Step1 :next="moveToStep2" v-if="step==1" :cancel="close"/>
        <StepCreate v-else-if="step==1.5" :cnpj="store" :next="moveToStep2" :cancel="close"/>
        <Step2 v-else-if="step==2" :back="()=>step=1" :store="store" :close="close"/>
    </div>
</template>
<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import StepCreate from './StepCreate.vue';

export default {
    name: 'AddProvider',
    props:['close'],
    data(){
        return{
            step: 1,
            store: undefined
        }
    },
    components:{
        Step1,
        StepCreate,
        Step2
    },
    methods:{
        moveToStep2(store){
            console.log(typeof(store));
            if(typeof(store) != 'string'){
                console.log('trocou');
                this.store = store
                this.step = 2
            } else {
                this.store = store
                this.step = 1.5
            }
        },

    }
}
</script>

<style scoped>
.add-provider-container{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}
@media only screen and (max-width: 850px) {
    .add-provider-container{font-size: 2vw;}
}
@media only screen and (max-width: 600px) {
    .add-provider-container{font-size: 3vw;}
}
</style>