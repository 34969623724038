<template>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col modal-header d-block">
                        <h5>
                            <i class="header-icon" :class="'fas fa-user-cog'"></i>
                            Minha Conta Club
                        </h5>
                        <p class="subtitle">Gerencie suas informações</p>
                    </div>
                    <div class="col-10">
                        <div class="row py-3 d-flex justify-content-end">
                            <div class="col col-md-2 col-lg-2 filter-controls">
                                <cc-button :classes="'fixed secondary'" :icon="'fas fa-store'" :content="'Minhas Empresas'" @click="show_store_management_modal=true"/>
                            </div>
                            <div class="col col-md-2 col-lg-2 filter-controls">
                                <cc-button :classes="'fixed secondary'" :icon="'fas fa-users'" :content="'Meus Usuários'" @click="show_user_management_modal=true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-12 p-4  rounded">
                        <cc-loader v-show="loading" />
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form action="" v-show="!loading" v-if="client && client.user" @submit.prevent="handleSubmit(save)">
                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Nome:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_nome" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Email:<span style="color:red !important">*</span></label>
                                        <ValidationProvider rules="required" v-slot="v">
                                            <cc-input type="text" v-model.trim="client.cli_email"></cc-input>
                                            <span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Contato:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_contato" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Telefone:<span style="color:red !important">*</span></label>
                                        <ValidationProvider rules="required" v-slot="v">
                                            <cc-input v-mask="'(##) #.####-####'" type="text" v-model="client.cli_telefone"></cc-input>
                                            <span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Nome Fantasia:<span style="color:red !important">*</span></label>
                                        <cc-input :classes="'text-uppercase'" type="text" v-model="client.cli_nomeFantasia" readonly></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Nome Grupo:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_nome_grupo" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                </div>
                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>CNPJ:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-mask="'## ### ###/####-##'" v-model="client.cli_cnpj" readonly></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Website:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_site" readonly></cc-input>
                                    </div>
                                </div>

                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <cc-checkbox
                                            @change="(evt) => client.cli_implantado = evt.target.checked ? 2 : 1"
                                            :checked="client.cli_implantado == 2"
                                            :label="'Aceito novos fornecedores'" />
                                    </div>
                                    <div class="col-md-3 col-lg-4"></div>
                                </div>

                                <div class="row pt-2">
                                    <div v-if="message" class="col-md-12 col-lg-12">
                                        <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                    </div>
                                    <div class="col d-flex justify-content-center">
                                        <button class="btnFeedbackModal" type="submit">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
        <user-management-modal :client="client" v-if="show_user_management_modal" @close="show_user_management_modal=false"></user-management-modal>
        <store-management-modal :client="client" v-if="show_store_management_modal" @close="show_store_management_modal=false"></store-management-modal>
    </div> -->
    <div class="page">
        <cc-loader-full v-if="loading"/>
        <div class="page-title">Configuração da Conta</div>
        <div class="page-section-select-containear">
            <div class="page-section-select-size" :class="isActiveContainear('general')" v-on:click="changeActiveTab('general')">
                <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('general')">
                    settings
                </span>
                <div class="page-section-select-text">
                    Dados Gerais
                </div>
            </div>
            <div class="page-section-select-size" :class="isActiveContainear('users')" v-on:click="changeActiveTab('users')">
                <span class="material-icons desactive-icon" :class="isActiveIcon('users')">
                    person
                </span>
                <div class="page-section-select-text">
                    Usuários
                </div>
            </div>
            <div class="page-section-select-size" :class="isActiveContainear('stores')" v-on:click="changeActiveTab('stores')">
                <span class="material-icons-round desactive-icon" :class="isActiveIcon('stores')">
                    storefront
                </span>
                <div class="page-section-select-text">
                    Empresas
                </div>
            </div>

            <div class="page-section-select-size" :class="isActiveContainear('plan')" v-on:click="changeActiveTab('plan')">
                <span class="material-icons-round desactive-icon" :class="isActiveIcon('plan')">
                    verified
                </span>
                <div class="page-section-select-text">
                    Assinaturas
                </div>
            </div>

            <div v-if="pageTab == 'users' && userTab == 'userList'" class="users-tab-desktop-hide users-tab-list-add-user-containear">
                <div class="users-tab-list-add-user-button" v-on:click="addUser()">
                    <span class="material-icons users-tab-add-user-icon">person_add</span>
                    <div class="users-tab-add-user-text">Adicionar novo usuário</div>
                </div>
                <div class="users-tab-list-add-user-button" v-on:click="editUsers()">
                    <span class="material-icons users-tab-add-user-icon">contact_mail</span>
                    <div class="users-tab-add-user-text">Editar perfis</div>
                </div>
            </div>
            <div v-if="(pageTab == 'plan' && storage_svc.get('cli_vindi') == 1 && isCanceled == false && JSON.parse(storage_svc.get('contracts')).length > 0)" class="users-tab-desktop-hide users-tab-list-add-user-containear" >
                <div class="plan-store-table-delete" v-on:click="deleteContract()">Cancelar assinatura</div>
            </div>
            <div v-if="pageTab == 'plan' && storage_svc.get('cli_vindi') == 1 && isCanceled == true" class="users-tab-desktop-hide users-tab-list-add-user-containear" >
                <div class="plan-store-table-reactivate" v-on:click="reativateContract()">Reativar assinatura</div>
            </div>

        </div>
        <div style="margin-bottom: 5vh;" v-if="pageTab == 'general'">
            <div class="general-page-body-containear">
                <div class="general-page-body-colum">
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Razão social</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Razão social da empresa.">info</span>
                    </div>
                    <div class="general-page-body-disable-input">{{client.cli_nome}}</div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Nome Fantasia</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Nome Fantasia da empresa.">info</span>
                    </div>
                    <div class="general-page-body-disable-input">{{client.cli_nomeFantasia}}</div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">CNPJ</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="CNPJ da empresa.">info</span>
                    </div>
                    <div class="general-page-body-disable-input">{{`${parse_cnpj(client.cli_cnpj)}` || "" | VMask('## ### ###/####-##')}}</div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Nome grupo</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                    </div>
                    <div class="general-page-body-disable-input">{{client.cli_nome_grupo}}</div>
                </div>
                <div class="general-page-body-colum">
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Email</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Email associado a conta.">info</span>
                    </div>
                    <div class="general-page-body-input-text">
                        <input class="general-page-body-input" type="text" v-model="client.cli_email">
                        <span class="material-icons-round edit-icon">edit</span>
                    </div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Telefone</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Telefone para contato.">info</span>
                    </div>
                    <div class="general-page-body-input-text">
                        <input class="general-page-body-input" v-mask="'(##) #.####-####'" type="text" v-model="client.cli_telefone">
                        <span class="material-icons-round edit-icon">edit</span>
                    </div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Nome do contato</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Nome do representante da marca.">info</span>
                    </div>
                    <div class="general-page-body-input-text">
                        <input class="general-page-body-input" type="text" v-model="client.cli_contato">
                        <span class="material-icons-round edit-icon">edit</span>
                    </div>
                    <div class="general-page-body-title">
                        <div class="general-page-body-title-text">Website</div>
                        <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Link para o site da empresa(caso tiver).">info</span>
                    </div>
                    <div class="general-page-body-input-text">
                        <input class="general-page-body-input" type="text" v-model="client.cli_site">
                        <span class="material-icons-round edit-icon">edit</span>
                    </div>
                </div>
            </div>
            <div class="general-page-body-check-containear">
                <div v-on:click="togleImplantado()" class="general-page-body-check">
                    <span class="material-icons" v-if="client.cli_implantado == '2'">check_box</span>
                    <span class="material-icons" v-else>check_box_outline_blank</span>
                </div>
                <div class="general-page-body-check-text">
                    Aceito novos fornecedores
                </div>
            </div>
            <div class="general-page-body-save-containear">
                <div class="general-page-body-save-button" v-on:click="save()">Salvar</div>
            </div>
        </div>
        <div style="margin-bottom: 5vh;" v-else-if="pageTab == 'users'">
            <div v-if="userTab == 'userList'" class="users-tab-list-containear-overflow">
                <div class="users-tab-mobile-show users-tab-list-add-user-containear-mobile">
                    <div class="users-tab-list-add-user-button users-tab-list-add-user-button-mobile" v-on:click="addUser()">
                        <span class="material-icons users-tab-add-user-icon">person_add</span>
                        <div class="users-tab-add-user-text">Adicionar novo usuário</div>
                    </div>
                    <div class="users-tab-list-add-user-button" v-on:click="editUsers()">
                        <span class="material-icons users-tab-add-user-icon">contact_mail</span>
                        <div class="users-tab-add-user-text">Editar perfis</div>
                    </div>
                </div>
                <div class="users-tab-list-containear">
                    <div class="users-tab-header">
                        <div class="users-tab-colum-small">ID</div>
                        <div class="users-tab-colum">Usuario/Login</div>
                        <div class="users-tab-colum">Empresa</div>
                        <div class="users-tab-colum">Perfil/Sub-perfil</div>
                        <div class="users-tab-colum-small" style="text-align: center;">Ações</div>
                    </div>
                    <!-- desktop -->
                    <div class="users-tab-content-line desktop" v-for="(user, idx) in users" :key="user.id" :class="getSpecialBackground(idx)">
                        <div class="users-tab-colum-small" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">ID</div>
                            {{user.usu_id}}
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Usuario</div>
                            <div>
                                <p class="user-tab-name-text">{{user.usu_nome}} </p>
                                <p class="user-tab-name-subtext">{{user.usu_login}}</p>
                            </div>
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Empresa</div>
                            {{user.store ? user.store.loj_descricao : '-'}}
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Perfil</div>

                            <div>
                                <p class="user-tab-name-text">{{user.profile ? user.profile.pfl_descricao : '-'}} </p>
                                <p class="user-tab-name-subtext">{{user.store && user.secondary_profile ? user.secondary_profile.key : '-'}}</p>
                            </div>
                        </div>
                        <div class="users-tab-colum-small users-tab-colum-actions">
                            <div class="settings-page-mobile-header">Ações</div>
                            <div>
                                <span class="material-icons" style="cursor: pointer; margin-right: 15px" v-on:click="editUserTab(user)">edit</span>
                                <span class="material-icons" title="Grupo de Produtos"style="cursor: pointer; color: #E77557; margin-right: 15px" v-on:click="showProductGroup=user">group_work</span>
                                
                                <!-- <span class="material-icons" style="color: var(--primary-color); cursor: pointer; margin-right: 15px" v-on:click="editExternalUserTab(user)">drive_file_rename_outline</span> -->
                                <span class="material-icons" style="color: #CD2A2A; cursor: pointer" v-on:click="remove(user)">delete</span>
                            </div>

                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="mobile"  v-for="(user, idx) in users" :key="user.id" :class="getSpecialBackground(idx)">
                        <div class="mobile-table-row">
                            <p class="user-tab-name-text">Nome: {{user.usu_nome}} </p>
                            <p>{{user.usu_id}}</p>
                        </div>
                        <div class="mobile-table-row" style="margin-bottom: 1vh;">
                            <div style="width: 100%; word-wrap: break-word;">
                                <p class="user-tab-name-text">Login: {{user.usu_login}}</p>
                            </div>
                        </div>
                        <div class="mobile-table-row">

                            <div style="width: 34%;" >
                                <p class="user-tab-name-text">Empresa</p>
                                <p>{{user.store ? user.store.loj_descricao : '-'}}</p>
                            </div>
                            <div style="width: 24%;" class="mobile-table-center">
                                <p class="user-tab-name-text">Perfil</p>
                                <p>{{user.profile ? user.profile.pfl_descricao : '-'}} </p>
                            </div>
                            <div style="width: 24%; " class="mobile-table-end">
                                <p class="user-tab-name-text">Sub-perfil</p>
                                <p>{{user.secondary_profile ? user.secondary_profile.key : '-'}}</p>
                            </div>
                        </div>
                        <div class="mobile-table-row" style="justify-content: space-evenly; margin-bottom: 2vh;">
                            <span class="material-icons" style="cursor: pointer; margin-right: 15px" v-on:click="editUserTab(user)">edit</span>
                            <span class="material-icons" title="Grupo de Produtos"style="cursor: pointer; color: #E77557; margin-right: 15px" v-on:click="showProductGroup=user">group_work</span>
                            <!-- <span class="material-icons" style="color: var(--primary-color); cursor: pointer; margin-right: 15px" v-on:click="editExternalUserTab(user)">drive_file_rename_outline</span> -->
                            <span class="material-icons" style="color: #CD2A2A; cursor: pointer" v-on:click="remove(user)">delete</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="userTab == 'editUser'">
                <div v-if="editUser.isNewUser" class="edituser-tab-newuser-title-container">
                    <div class="edituser-tab-newuser-title">Adicionar novo usuário</div>

                    <!-- desktop -->
                    <div class="edituser-tab-selector-divison desktop">
                        <div class="edituser-tab-buttons-cancel" v-on:click="cancel()">Cancelar</div>
                        <div class="general-page-body-save-button" v-on:click="test()">Salvar</div>
                    </div>
                </div>
                <div v-else class="edituser-tab-selector">
                    <div class="edituser-tab-selector-divison">
                        <div class="edituser-tab-selector-icon edituser-tab-selector-icon-active">
                            <span class="material-icons-outlined desactive-icon">manage_accounts</span>
                            Dados do usuário
                        </div>
                        <div class="edituser-tab-selector-icon" v-on:click="editExternalUserTab(editUser)">
                            <span class="material-icons-outlined desactive-icon">contact_mail</span>
                            Integração
                        </div>
                    </div>
                    <!-- desktop -->
                    <div class="edituser-tab-selector-divison desktop">
                        <div class="edituser-tab-buttons-cancel" v-on:click="cancel()">Cancelar</div>
                        <div class="general-page-body-save-button" v-on:click="test()">Salvar</div>
                    </div>

                </div>
                <div class="edituser-tab-containear">
                    <div class="general-page-body-colum" >
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Nome</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Nome do usuario.">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.usu_nome">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <div class="general-page-body-title">
                            <div v-if="!editUser.isNewUser" class="general-page-body-title-text">Email</div>
                            <div v-else class="general-page-body-title-text">Email/Login</div>
                            <span class="material-icons-round" style="color: #E77557; cursor: default" title="Email da conta.">info</span>

                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.usu_email">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>

                        <div v-if="!editUser.isNewUser">
                            <div class="general-page-body-title">
                                <div class="general-page-body-title-text">Login</div>
                                <span class="material-icons-round" style="color: #E77557; cursor: default" title="Nome de ussuario para login.">info</span>
                            </div>
                            <div class="general-page-body-disable-input">{{editUser.usu_login}}</div>

                        </div>

                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Nome do contato</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.usu_nome">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <!-- mobile -->
                        <div class="general-page-body-title mobile" >
                            <div class="general-page-body-title-text">Senha</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text mobile">
                            <input class="general-page-body-input" type="password" v-model="editUser.usu_senha">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                    </div>
                    <div class="general-page-body-colum">
                        <div style="display: flex; justify-content: space-between;">
                            <div style="flex: 1;">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Perfil</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-input-text">
                                    <select v-model="editUserProfile" class="edituser-tab-select">
                                        <option v-for="(profile, idx) in profiles" :key="idx" :value="profile">{{profile.pfl_descricao}}</option>
                                    </select>
                                    <span class="material-icons-round edit-icon">edit</span>
                                </div>
                            </div>
                            <div class="desktop" style="flex: 1; margin-left: 30px" v-if="editUserProfile && editUserProfile.pfl_descricao != 'ADMINISTRADOR' && editUser.store && editUser.store.loj_descricao != 'Nenhuma'">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Perfil secundário</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-input-text">
                                    <select v-model="editUser.secondary_profile" class="edituser-tab-select">
                                        <option v-for="profile in secondary_profiles" :key="profile.cli_id" :value="profile">{{profile.key}}</option>
                                    </select>
                                    <span class="material-icons-round edit-icon">edit</span>
                                </div>
                            </div>
                        </div>
                        <div class="general-page-body-title" v-if="editUserProfile && editUserProfile.pfl_descricao != 'ADMINISTRADOR'">
                            <div class="general-page-body-title-text">Empresa</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text" v-if="editUserProfile && editUserProfile.pfl_descricao != 'ADMINISTRADOR'">
                            <select v-model="editUser.store" @change="updateHtml" class="edituser-tab-select">
                                <option v-for="(store, idx) in [...stores, {loj_descricao: 'Nenhuma'}]" :key="idx"  :value="store">{{store.loj_descricao}}</option>
                            </select>
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>

                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Telefone</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Telefone para contato.">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" v-mask="'(##) #.####-####'" type="text" v-model="editUser.usu_telefone">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>

                        <!-- desktop -->
                        <div class="general-page-body-title desktop">
                            <div class="general-page-body-title-text">Senha</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text desktop">
                            <input class="general-page-body-input" type="password" v-model="editUser.usu_senha">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>

                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Confirmar Senha</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="password" v-model="editUser.password_confirmation">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                    </div>
                </div>
                <!-- mobile -->
                <div class="edituser-tab-selector-divison mobile" style="width:98%">
                    <div class="edituser-tab-buttons-cancel" v-on:click="cancel()">Cancelar</div>
                    <div class="general-page-body-save-button" v-on:click="test()">Salvar</div>
                </div>
            </div>
            <div v-if="userTab == 'editExternalUser'">
                <div class="edituser-tab-selector">
                    <div class="edituser-tab-selector-divison">
                        <div class="edituser-tab-selector-icon" v-on:click="editUserTab(editUser)">
                            <span class="material-icons-outlined desactive-icon">manage_accounts</span>
                            Dados do usuário
                        </div>
                        <div class="edituser-tab-selector-icon edituser-tab-selector-icon-active">
                            <span class="material-icons-outlined desactive-icon">contact_mail</span>
                            Integração
                        </div>
                    </div>
                    <!-- <div class="edituser-tab-selector-divison">
                        <div class="edituser-tab-buttons-cancel" v-on:click="cancel()">Cancelar</div>
                        <div class="general-page-body-save-button" v-on:click="test()">Salvar</div>
                    </div> -->
                </div>
                <cc-loader class="externaluser-loader" v-if="loading" />
                <div v-else class="edituser-tab-containear">
                    <div class="general-page-body-colum">
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Usuário Externo </div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.varejo_facil_user">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Subdominio</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.varejo_facil_subdomain">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <div class="general-page-body-title" v-if="editExternalUserProfile == 'VAREJO FACIL'">
                            <div class="general-page-body-title-text">Token</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text" v-if="editExternalUserProfile == 'VAREJO FACIL'">
                            <input class="general-page-body-input" type="text" v-model="editUser.varejo_token">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                    </div>
                    <div class="general-page-body-colum">
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Senha</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editUser.varejo_facil_pass">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Tipo</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <select v-model="editExternalUserProfile" class="edituser-tab-select">
                                <option v-for="profile in externalProfiles" :key="profile" :value="profile">{{profile}}</option>
                            </select>
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                    </div>
                </div>
                <!-- mobile -->
                <div class="edituser-tab-selector-divison mobile" style="width:98%">
                    <div class="edituser-tab-buttons-cancel" v-on:click="cancel()">Cancelar</div>
                    <div class="general-page-body-save-button" v-on:click="test()">Salvar</div>
                </div>
            </div>
        </div>
        <div style="margin-bottom: 5vh;" v-else-if="pageTab == 'stores'">
            <div v-if="storesTab == 'storesList'" class="users-tab-list-containear-overflow">
                <div class="users-tab-list-containear">
                    <div class="users-tab-header">
                        <div class="users-tab-colum-company">Empresa</div>
                        <div class="users-tab-colum-name">Nome abreviado</div>
                        <div class="users-tab-colum">Cidade</div>
                        <div class="users-tab-colum">Telefone</div>
                        <div class="users-tab-colum">CNPJ</div>
                        <div class="users-tab-colum-action">Ações</div>
                    </div>
                    <!-- desktop -->
                    <div class="users-tab-content-line desktop" :class="getSpecialBackground(idx)" v-for="(store, idx) in stores" :key="idx">
                        <div class=" users-tab-colum-company" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header" >Empresa</div>
                            {{ store.loj_razao }} <br>
                            {{ store.loj_nomeFantasia }}
                        </div>
                        <div class="users-tab-colum-name" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Nome abreviado</div>
                            {{ store.loj_descricao}}
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Cidade</div>
                            {{ store.city ? store.city.cid_nome : '--' }}
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">Telefone</div>
                            {{ store.loj_telefone || "" | VMask('(##) #####-####') }}
                        </div>
                        <div class="users-tab-colum" style="border-right: 0.5px solid #f2f2f2;">
                            <div class="settings-page-mobile-header">CNPJ</div>
                            {{ `${parse_cnpj(store.loj_cnpj)}` || "" | VMask('## ### ###/####-##') }}
                        </div>
                        <div class="users-tab-colum-action" style="text-align: center;">
                            <div class="settings-page-mobile-header">Ações</div>
                            <span class="material-icons" style="cursor: pointer" v-on:click="setEditStore(store)">edit</span>
                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="mobile" v-for="(store, idx) in stores" :key="idx + stores.length">
                        <div class="mobile-table-row" style="margin-bottom: 1vh;">
                            <p class="user-tab-name-text">{{ store.loj_razao }}</p>
                            <p > <b>CNPJ:</b>  {{ `${parse_cnpj(store.loj_cnpj)}` || "" | VMask('## ### ###/####-##') }}</p>
                        </div>
                        <div class="mobile-table-row">
                            <div style="width: 35%; word-wrap: break-word;">
                                <p class="user-tab-name-text">Nome abreviado</p>
                                <p>{{ store.loj_descricao}}</p>
                            </div>
                            <div style="width: 25%;" class="mobile-table-center">
                                <p class="user-tab-name-text">Cidade</p>
                                <p>{{ store.city ? store.city.cid_nome : '--' }}</p>
                            </div>
                            <div style="width: 30%;" class="mobile-table-end">
                                <p class="user-tab-name-text">Telefone</p>
                                <p>{{ store.loj_telefone || "" | VMask('(##) #####-####') }} </p>
                            </div>
                        </div>
                        <div class="mobile-table-row" style="justify-content: space-evenly; margin-bottom: 2vh;">
                            <span class="material-icons" style="cursor: pointer" v-on:click="setEditStore(store)">edit</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="storesTab == 'storesEdit'">
                <div class="edituser-tab-containear">
                    <div class="general-page-body-colum">
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Razão social</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-disable-input">{{editStore.loj_razao}}</div>

                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Nome</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Nome do usuario.">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editStore.loj_descricao">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Nome Fantasia</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-disable-input">{{editStore.loj_nomeFantasia}}</div>
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">CNPJ</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-disable-input">{{`${parse_cnpj(editStore.loj_cnpj)}` || "" | VMask('## ### ###/####-##')}}</div>
                        <!-- <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Nome do contato</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-disable-input">{{editStore.loj_descricao}}</div> -->
                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Email</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" type="text" v-model="editStore.loj_email">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>
                    </div>
                    <div class="general-page-body-colum">


                        <div class="general-page-body-title">
                            <div class="general-page-body-title-text">Telefone</div>
                            <span class="material-icons-round" style="color: var(--secondary-color); cursor: default" title="Telefone para contato.">info</span>
                        </div>
                        <div class="general-page-body-input-text">
                            <input class="general-page-body-input" v-mask="'(##) #.####-####'" type="text" v-model="editStore.loj_telefone">
                            <span class="material-icons-round edit-icon">edit</span>
                        </div>


                        <div class="store-tab-sub-row">
                            <div class="store-tab-address-containear">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Nº loja</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.loj_numeroLoja}}</div>
                            </div>
                            <div class="store-tab-address-containear">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">CEP</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.loj_cep}}</div>
                            </div>
                            <div style="flex: 1">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Estado</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.state.est_nome}}</div>
                            </div>
                        </div>
                        <div class="store-tab-sub-row">
                            <div class="store-tab-address-containear">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Cidade</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.city.cid_nome}}</div>
                            </div>
                            <div class="store-tab-address-containear">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Rua</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.loj_endereco}}</div>
                            </div>
                            <div style="flex: 1">
                                <div class="general-page-body-title">
                                    <div class="general-page-body-title-text">Bairro</div>
                                    <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                                </div>
                                <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.loj_bairro}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="general-page-body-title">
                                <div class="general-page-body-title-text">Complemento</div>
                                <span class="material-icons-round" style="color: var(--secondary-color); cursor: default">info</span>
                            </div>
                            <div class="general-page-body-disable-input store-tab-adress-input-mobile">{{editStore.loj_complemento}}</div>
                        </div>
                    </div>
                </div>
                <div class="edituser-tab-buttons mobile-buttons-align">
                    <div class="edituser-tab-buttons-cancel" v-on:click="cancelStoreEdit()">Cancelar</div>
                    <div class="general-page-body-save-button" v-on:click="saveEditStore()">Salvar</div>
                </div>
            </div>
        </div>
        <div style="margin-bottom: 5vh;" v-else-if="pageTab == 'plan'">
            <div class="page-plan-containear" v-if="planTab == 'resumePlan'">
                <!-- <div class="page-plan-info">
                    <div class="page-plan-info-table">
                        <div class="page-plan-info-table-header">
                            <div class="info-table-product-width">Produto</div>
                            <div class="info-table-product-quantity">Quantidade</div>
                        </div>
                        <div class="page-plan-info-table-row">
                            <div class="info-table-product-width">Club Prata</div>
                            <div class="info-table-product-quantity">5</div>
                        </div>
                        <div class="page-plan-info-table-row">
                            <div class="info-table-product-width">Consultar Preço por Venda</div>
                            <div class="info-table-product-quantity">1</div>
                        </div>
                        <div class="page-plan-info-total-row">
                            <div class="info-table-product-width">Valor Total</div>
                            <div class="info-table-product-quantity">125,90</div>
                        </div>
                    </div>
                    <div class="page-plan-info-buttons">
                        <StandardButton class="mobile-button page-plan-info-first-button" :action="testMethod" :iconName="'cancel'" :text="'Cancelar Conta'"/>
                        <StandardButton class="page-plan-info-second-button mobile-button" :action="showPlans" :iconName="'zoom_in'" :text="'Mais Detalhes'"/>
                    </div>
                </div> -->
                <div class="plan-store-table-overflow">
                    <div class="plan-store-table">
                        <div class="users-tab-header table-padding">
                            <div class="plan-stores-table-colum-name">Razão Social</div>
                            <div class="plan-stores-table-colum-cnpj">CNPJ</div>
                            <!-- <div class="plan-stores-table-colum-value">Valor</div>
                            <div class="plan-stores-table-colum-descont">Desconto</div> -->
                            <div class="plan-stores-table-colum-total">Valor Final</div>
                            <div class="plan-stores-table-colum-status">Status</div>
                        </div>
                        <!-- desktop -->
                        <div class="plan-store-table-row desktop" :class="getSpecialBackground(idx)" v-for="(store, idx) in storeContracts" :key="idx">
                            <div class="plan-stores-table-colum-name">{{store.loj_razao}}</div>
                            <div class="plan-stores-table-colum-cnpj">{{`${parse_cnpj(store.loj_cnpj)}` || "" | VMask('## ### ###/####-##')}}</div>

                            <div class="plan-stores-table-colum-total" v-if="store.cobracas.length > 0">R$ {{store.cobracas[store.cobracas.length-1].amount}}</div>
                            <div class="plan-stores-table-colum-total" v-else>R$ --</div>
                            <div class="plan-stores-table-colum-status">
                                <div v-if="havePendencie" class="plan-stores-table-pendencie" title="Ver boletos" style="background-color: lightcoral;" v-on:click="openPendencieModal(store)">
                                    <span class="material-icons" style="color: white">monetization_on</span>
                                    <div style="margin-left: 0.25vw">pendência</div>
                                </div>
                                <div v-else-if="haveBills" class="plan-stores-table-pendencie" title="Ver boletos" style="background-color: lightblue;" v-on:click="openPendencieModal(store)">
                                    <span class="material-icons" style="color: white">monetization_on</span>
                                    <div style="margin-left: 0.25vw">Boletos disponiveis</div>
                                </div>
                                <div v-else  class="plan-stores-table-pendencie" title="Ver boletos" style="background-color: lightgreen;" v-on:click="openPendencieModal(store)">
                                    <span class="material-icons" style="color: white">monetization_on</span>
                                    <div style="margin-left: 0.25vw">sem pendência</div>
                                </div>
                            </div>
                            <PendenciesModal v-if="store.showPendenciesModal" :store="store" :closeModal="() => {store.showPendenciesModal = false; $forceUpdate()}"/>
                        </div>
                        <!-- mobile -->
                        <div class="mobile" :class="getSpecialBackground(idx)" v-for="(store, idx) in storeContracts" :key="idx + storeContracts.length">
                            <div class="mobile-table-row" style="margin-top: 1vh;">
                                <p class="user-tab-name-text">{{store.loj_razao}}</p>
                                <div v-if="haveBills" class="plan-stores-table-pendencie" title="Ver boletos" style="background-color: lightcoral;" v-on:click="openPendencieModal(store)">
                                    <span class="material-icons" style="color: white">monetization_on</span>
                                    <div style="margin-left: 0.25vw">pendência</div>
                                </div>
                                <div v-else  class="plan-stores-table-pendencie" title="Ver boletos" style="background-color: lightgreen;" >
                                    <span class="material-icons" style="color: white">monetization_on</span>
                                    <div style="margin-left: 0.25vw">sem pendência</div>
                                </div>
                            </div>
                            <PendenciesModal v-if="store.showPendenciesModal" :store="store" :closeModal="() => {store.showPendenciesModal = false; $forceUpdate()}"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-plan-containear" v-else-if="planTab == 'plansTab'">
                <div class="page-plan-title">
                    <div class="page-plan-title-text">Meu Plano</div>
                    <StandardButton class="page-plan-comeback-button" :action="hidePlans" :iconName="'arrow_back'" :text="'Voltar'"/>
                </div>
                <div class="page-plan-table-overflow">
                    <div class="page-plan-table-containear">
                        <div class="page-plan-table-legend-colum">
                            <div class="page-plan-table-legend-title">Planos e suas vantagens</div>
                            <div class="page-plan-table-legend-line ">Sistema de Cotacao </div>
                            <div class="page-plan-table-legend-line page-plan-table-special-line">Sem FIdelidade</div>
                            <div class="page-plan-table-legend-line">Club de Ofertas;</div>
                            <div class="page-plan-table-legend-line page-plan-table-special-line">Consulta fornecedores por regiao;</div>
                            <div class="page-plan-table-legend-line">Consulta preço de venda base nacional;</div>
                            <div class="page-plan-table-legend-line page-plan-table-special-line">Suporte online</div>
                            <div class="page-plan-table-legend-line">Painel de Relatorio</div>
                            <div class="page-plan-table-legend-line page-plan-table-special-line">App Android / Ios;</div>
                            <div class="page-plan-table-legend-line">Consulta preco de venda por cidade	69,9;</div>
                        </div>
                        <div class="page-plan-table-plan-colum">
                            <div class="page-plan-table-plan-header">
                                <div class="green-circle"></div>
                                <div class="freeplan-header-name">Grátis</div>
                                <div class="freeplan-header-title">Grátis</div>
                                <div class="freeplan-header-info">Mensalidade por CNPJ</div>
                            </div>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-disable">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-disable">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-disable">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-disable">check_circle</span>
                        </div>
                        <div class="page-plan-table-plan-colum">
                            <div class="page-plan-table-plan-header">
                                <div class="brow-circle"></div>
                                <div class="freeplan-header-name">Bronze</div>
                                <div class="freeplan-header-title">R$ 69,90</div>
                                <div class="freeplan-header-info">Mensalidade por CNPJ</div>
                            </div>
                            <div class="page-plan-table-products">50 Produtos</div>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                        </div>
                        <div class="page-plan-table-plan-colum">
                            <div class="page-plan-table-plan-header">
                                <div class="grey-circle"></div>
                                <div class="freeplan-header-name">Prata</div>
                                <div class="freeplan-header-title">R$ 69,90</div>
                                <div class="freeplan-header-info">Mensalidade por CNPJ</div>
                            </div>
                            <div class="page-plan-table-products">300 Produtos</div>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                        </div>
                        <div class="page-plan-table-plan-colum">
                            <div class="page-plan-table-plan-header">
                                <div class="gold-circle"></div>
                                <div class="freeplan-header-name">Ouro</div>
                                <div class="freeplan-header-title">R$ 69,90</div>
                                <div class="freeplan-header-info">Mensalidade por CNPJ</div>
                            </div>
                            <div class="page-plan-table-products">900 Produtos</div>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                        </div>
                        <div class="page-plan-table-plan-colum">
                            <div class="page-plan-table-plan-header">
                                <div class="blue-circle"></div>
                                <div class="freeplan-header-name">Diamante</div>
                                <div class="freeplan-header-title">R$ 69,90</div>
                                <div class="freeplan-header-info">Mensalidade por CNPJ</div>
                            </div>
                            <div class="page-plan-table-products"> Sem Limite de Produtos</div>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon page-plan-table-special-line check-icon-active">check_circle</span>
                            <span class="material-icons-outlined page-plan-table-check-icon check-icon-active">check_circle</span>
                        </div>
                    </div>
                </div>
            </div>
            <VindiPlansVue v-else-if="planTab == 'plansVindiTab'" />
        </div>
        <EditUsersPermission :id="client.cli_id" :reload="load_users" :closeDialog="() => showEditUserPermisions = false" v-if="showEditUserPermisions"/>
        <EditProductGroup v-if="showProductGroup" :user="showProductGroup" :closeModal="()=>showProductGroup=false"/>
    </div>
</template>
<script>

import AuthService from '@/services/AuthService'
import ClientService from "@/services/v2/client.service";
import StorageService from '@/services/TokenService';
import ClientServiceV3 from "@/services/v3/ClientService.js"
import ErrorHandlerService from "@/services/ErrorHandlerService";
import UserManagementModal from "@/modals/client/users/management.modal";
import StoreManagementModal from "@/modals/client/stores/management.modal";
import UserService from "@/services/v1/user.service";
import StoreService from "@/services/v1/store.service";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import PendenciesModal from './pendenciesModal.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import VindiPlansVue from './VindiPlans.vue';
import EditUsersPermission from './EditUsersPermision.vue';
import ExternalUserService from '@/services/ExternalUserService.js'
import EditProductGroup from './EditProductGroupModal.vue';


export default {
    name: "AccountSettings",
    components: {
        ValidationObserver,
        ValidationProvider,
        'user-management-modal': UserManagementModal,
        'store-management-modal': StoreManagementModal,
        StandardButton,
        PendenciesModal,
        VindiPlansVue,
        EditUsersPermission,
        EditProductGroup
    },
    mixins: [ loaderMixin ],
    data(){
        return{
        externalUserService: new ExternalUserService(),
        image:"/images/search.png",
        pageTab: 'general',
        userTab: 'userList',
        storesTab: 'storesList',
        planTab: 'resumePlan',
        editUser: {},
        editUserProfile: {},
        editExternalUserProfile: undefined,
        externalProfiles: ['PRIME', 'VAREJO FACIL', 'SEM INTEGRAÇÃO'],
        editStore: {},
        showProductGroup: undefined,
        showEditUserPermisions: false,
        haveBills: false,
        havePendencie: false,
        users: [],
            loading: false,
            cities: [],
            states: [],
            client: {},
            stores: [],
            profiles: [],
            message: null,
            show_user_management_modal: false,
            show_store_management_modal: false,
            service: new AuthService(),
            svc: new ClientService(),
            userSvc: new UserService(),
            store_svc: new StoreService(),
            clientServiceV3: new ClientServiceV3(),
            storage_svc: new StorageService(),
            breadcrumbs: [
                {
                    name: "Minhas Configurações",
                    route: 'settings'
                }
            ],
            secondary_profiles: [
                { value: 0, key: 'Repositor' },
                { value: 1, key: 'Gerente' },
                { value: 2, key: 'Conferente' },
            ],
            showPendenciesModal: false,
            isCanceled: false,
            storeContracts: JSON.parse(localStorage.getItem('contracts'))
        }
    },
    computed: {
        available_cities : function(val) {
			return this.editStore && this.editStore.state ? this.cities.filter(c => c.est_id == this.editStore.state.est_id) : []
		}
    },
    watch: {
        editUserProfile : {
            handler: function() {
                this.$forceUpdate()
            },
            deep: true
        }
    },
    methods: {
        editUsers() {
            this.showEditUserPermisions = true
        },
        sortStores(stores) {
            let respList = []
            for (let i = 0; i < stores.length; i++){
                if (stores[i].contract && stores[i].contract.cnt_contas_atrasada > 0) respList.unshift(stores[i])
                else respList.push(stores[i])
            }
            return respList
        },
        showPlans () { this.planTab = 'plansTab' },
        hidePlans () { this.planTab = 'resumePlan'},
        getSpecialBackground (idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
        remove(user){
			this.confirm_action({
				message: "Confirma remoção do usuário?",
				subtitle: `O usuário ${user.usu_nome} será removido!`,
				callback: () => {
					this.userSvc.remove(this.client.cli_id, user).then(() => this.load_users())
				}
			})
			return false
		},
        setEditStore(store){
            this.storesTab = 'storesEdit'
            this.editStore = store
        },
        load_region_data() {
			return this.userSvc.region_data().then(response => response.data).then(data => {
				this.cities = data.cidades
				this.states = data.estados
                if (this.stores) {
                    this.stores.forEach(s => {
                        s.city = this.cities.find(ct => ct.cid_id == s.cid_id)
                        s.state = this.states.find(st => st.est_id == s.est_id)
                    })
                }
                this.loading = false
			})
		},
        parse_cnpj: function(val) {
            return val && `${val}`.length < 14 ? `0${val}` : val
        },
        test(){
            this.saveEdit()
        },
        cancel(){
            this.userTab = 'userList',
            this.editUser = {}
        },
        cancelStoreEdit(){
            this.storesTab = 'storesList',
            this.editStore = {}
        },
        saveEditStore() {
			let callback = data => {
                this.cancelStoreEdit()
			}
			let callback_error = err => {}
			if(this.editStore.loj_id) {
				this.store_svc.update(this.client.cli_id, this.editStore.loj_id, this.editStore).then(callback, callback_error)
			} else {
				this.store_svc.create(this.client.cli_id, this.editStore).then(callback, callback_error)
			}
        },
        updateHtml(){
            this.$forceUpdate()
        },
        save() {
            this.svc.update_client(this.client).then((response) => {
                this.$swal.fire({
                    html: `<span>Configuração salva com sucesso</span>`,
                    showConfirmButton: true,
                    icon: 'success'
                })
            })
        },
        isActiveTab(tab){
            if (tab == this.pageTab) return true
            return false
        },
        isActiveContainear(tab){
            if (this.isActiveTab(tab)) return 'page-section-select-size-active'
        },
        isActiveIcon(tab){
            if (this.isActiveTab(tab)) return 'active-icon'
        },
        changeActiveTab(tab) {
            this.pageTab = tab
        },
        editUserTab(user){
            this.userTab = 'editUser',
            this.editUser = user
            this.editUserProfile = user.profile
        },
        addUser(){
            this.userTab = 'editUser',
            this.editUser = {isNewUser: true}
            this.editUserProfile = {}
        },
        editExternalUserTab(user){
            this.userTab = 'editExternalUser',
            this.editUser = user
            if (user.usu_integracao == 'P'){
                this.editExternalUserProfile = 'PRIME'
                this.loading = true
                this.externalUserService.getExternalUser(user.usu_id).then(response => {
                    if (response.data.length == 3){
                        this.editUser.varejo_facil_user = response.data[0].value
                        this.editUser.varejo_facil_pass = response.data[1].value
                        this.editUser.varejo_facil_subdomain = response.data[2].value
                    } else {
                        this.editUser.varejo_facil_user = ''
                        this.editUser.varejo_facil_pass = ''
                        this.editUser.varejo_facil_subdomain = ''
                    }
                    this.loading = false
                })
            } else  if (user.usu_integracao == 'V') {
                this.editExternalUserProfile = 'VAREJO FACIL'
            } else {
                this.editExternalUserProfile = 'SEM INTEGRAÇÃO'
            }
        },
        togleImplantado(){
            if (this.client.cli_implantado == '2') this.client.cli_implantado = '1'
            else this.client.cli_implantado = '2'
        },
        load_users() {
			return this.userSvc.all(this.client.cli_id).then(response => response.data).then(data => {
				this.load_profiles().then(() => {
					this.users = data.usuarios;
					this.users.forEach(u => u.password_confirmation = u.usu_senha)
					this.process_profiles()
				}).then(() => {
					this.load_stores().then(() => {
						this.process_stores()
					})
				})
			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
        load_profiles() {
			return this.userSvc.load_profiles(this.client.cli_id).then(response => response.data).then(data => {
				this.profiles = data.perfil
			})
		},
        process_stores() {
			this.users.forEach(u => u.store = this.stores.find(p => p.loj_id == u.usu_idLoja))
            this.loading = false
			this.$forceUpdate()
		},
        process_profiles() {
			this.users.forEach(u => u.profile = this.profiles.find(p => p.pfl_id == u.pfl_id))
            this.users.forEach(u => u.secondary_profile = this.secondary_profiles.find(p => u.usu_acesso_comp_loja == p.value))
		},
        deleteContract(){
            this.confirm_action({
				message: "Confirma remoção do contrato?",
				callback: () => {
                    this.loading = true
					this.clientServiceV3.deleteContractsVindi(this.stores[0].contract.id).then(() => {
                        this.clientServiceV3.getContractsVindi().then(response => {
                            this.storage_svc.put('contracts', JSON.stringify(response.data.bills.bills))
                            this.load_stores()
                        })
                    })
				}
			})
        },
        reativateContract(){
            this.confirm_action({
				message: "Confirma reativação do contrato?",
				callback: () => {
                    this.loading = true
					this.clientServiceV3.reativateContractsVindi(this.stores[0].contract.id).then(resp => {
                        this.clientServiceV3.getContractsVindi().then(response => {
                            this.storage_svc.put('contracts', JSON.stringify(response.data.bills.bills))
                            this.load_stores()
                        })
                    })
				}
			})
        },
        load_stores() {
            const contracts = JSON.parse(this.storage_svc.get('contracts'))
			return this.store_svc.all(this.client.cli_id).then(response => response.data).then(data => {
                if(data.lojas) {
                    this.stores = data.lojas
                    
                }
                if (this.stores && this.storage_svc.get('cli_vindi') == 0) {
                    this.stores.forEach(store => {
                        contracts.forEach(contract => {
                            if (contract.loj_cnpj == store.loj_cnpj){ store.contract = contract}
                        })
                    })
                } else if (this.stores && this.storage_svc.get('cli_vindi') == 1){
                    const bills = JSON.parse(this.storage_svc.get('contractsBills'))
                    const pendingBills = bills.some( bill => {return bill.status = "pending"} )
                    for (let index = 0; index < this.stores.length; index++) {
                        this.stores[index].contract = contracts[contracts.length - 1]
                        this.stores[index].bills = bills
                        this.stores[index].pendingBills = pendingBills
                    }
                    if (contracts.length > 0) this.isCanceled = contracts[contracts.length - 1].status == 'canceled'
                }
                // this.stores.unshift({loj_descricao: 'Nenhuma'})
                this.load_region_data()
			})
		},

        saveEdit() {
            if (!this.editUser.usu_telefone) {
                const notification = {
                    type: 'error',
                    message: 'Por favor, insira um numero de telefone.'
                }
                this.$store.dispatch('notification/add', notification)
                return
            }
            this.editUser.profile = this.editUserProfile
			this.message = null
			let callback = data => {
                const notification = {
                    type: 'success',
                    message: "Dados salvos com sucesso!"
                }
                this.$store.dispatch('notification/add', notification)
                this.cancel()
			}
			let callback_error = err => {
                let message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
				if(err.response && err.response.data && err.response.data.error) {
					message = err.response.data.error
				}
                const notification = {
                    type: 'error',
                    message: message
                }
                this.$store.dispatch('notification/add', notification)
			}
			if(this.editUser.usu_id) {
                if (this.editExternalUserProfile == 'VAREJO FACIL') this.editUser.usu_integracao = 'V'
                else if (this.editExternalUserProfile == 'PRIME') this.editUser.usu_integracao = 'P'
                else this.editUser.usu_integracao = null
                if (this.editUserProfile && this.editUserProfile.pfl_descricao == 'ADMINISTRADOR') {
                    this.editUser.store = {}
                    this.editUser.secondary_profile = {}
                } else this.editUser.usu_comparativo = 1

                console.log(this.editUser);
				this.userSvc.update(this.client.cli_id, this.editUser.usu_id, this.editUser)
                .then(() => this.load_users())
                .then(callback, callback_error)

			} else {
                this.editUser.usu_login = this.editUser.usu_email
				this.userSvc.create(this.client.cli_id, this.editUser)
				.then(() => this.load_users())
				.then(callback, callback_error)
			}
		},
        openPendencieModal(store){
            store.showPendenciesModal = true;
            this.$forceUpdate();
        }
    },
    created() {
        if (this.$route.params.tab) this.pageTab = this.$route.params.tab
        if (this.storage_svc.get('cli_vindi') == 1 && JSON.parse(this.storage_svc.get('contracts')).length == 0) this.planTab = "plansVindiTab"
        let contracts = JSON.parse(this.storage_svc.get('contracts'))[0]
        if (contracts && contracts.cobracas.length > 0) {
            this.haveBills = true
            for (let index = 0; index < contracts.cobracas.length; index++) {
                const element = contracts.cobracas[index];
                if (element.status == "unpaid" && new Date(element.dueDate) < new Date()) this.havePendencie = true
            }
        }
        this.loading = true
        this.client = this.service.get_user().then(response => response.data).then(({ usuario }) => {
            this.client = usuario.cliente
            this.client.user = usuario
            delete this.client.user.cliente
            this.load_users()
        }, err => {
            this.loading = false;
            ErrorHandlerService.handle(err, this.$store, this.$store);
        })
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .error {
        color: $lower-red;
    }

    .btnFeedbackModal {
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px $blue1;
        background-color: $secondary-color;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 15px
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        font-weight: bolder;
        font-size: 1.15rem;
        color: $secondary-color;
        text-transform: uppercase;
    }

    .modal-header {
        border: none;
        .subtitle {
            box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
            padding-left: 1rem;
            color: #314b5f;
            font-size: 0.9rem;
            font-family: Helvetica,sans-serif !important;
            font-weight: lighter;
        }
    }
    .page{
        font-size: 1vw;
        padding-right: 4vw;
    }
    .page-title{
        font-weight: 700;
        letter-spacing: 0.5px;
        color: #605F5F;
        // font-size: 32px;
        font-size: 2.7em;
    }
    .page-section-select-containear{
        border-bottom: 0.5px solid #E5E5E5;
        display: flex;
        margin-top: 56px;
        align-items: flex-end;
        padding-bottom: 1vh;
    }

    .page-section-select-size{
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 7.5vh;
        min-width: 12vw;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        margin-right: 2vw;
    }
    .page-section-select-size:hover {
        background-color: #fdf0e9;
    }
    .page-section-select-size-active{
        cursor: default;
        border-radius: 8px 8px 8px 8px;
        margin-top: 20px;
        background-color: #fdf0e9;
    }
    .page-section-select-size-active > .page-section-select-text{
        color: #202020 !important;
    }
    .page-section-select-text{
        font-weight: 600;
        font-size: 1.1em;
        color: #202020;
        margin-left: 1vw;
    }
    .page-section-select-size:hover > .page-section-select-text{
        color: var(--primary-color);
    }
    .active-icon{
        color: var(--primary-color) !important;
    }
    .desactive-icon{
        color: var(--primary-color);
    }
    .general-page-body-containear{
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
    }
    .user-tab-name-text{
        margin: 0;
        text-transform: capitalize;
        font-weight: bold;
    }
    .general-page-body-colum{
        width: 45%;
    }
    .general-page-body-title{
        display: flex;
    }
    .general-page-body-title-text{
        font-weight: 400;
        font-size: 1.5em;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #666666;
        margin-right: 5px;
        margin-bottom: 10px;
    }
    .general-page-body-disable-input{
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        padding: 12px;
        font-weight: 400;
        font-size: 1.1em;
        letter-spacing: 0.15px;
        color: #666666;
        cursor: default;
        margin-bottom: 30px;
        height: 6vh;
    }
    .edituser-tab-selector{
        display: flex;
        margin-top: 8vh;
        justify-content: space-between;
    }
    .edituser-tab-selector-icon{
        cursor: pointer;
        min-width: 14vw;
        font-size: 1.1em;
        color: #202020;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        padding: 2vh 0;
        border-radius: 8px;
        font-weight: 600;
    }
    .edituser-tab-selector-icon:hover{
        background-color: #fdf0e9;
    }
    .edituser-tab-selector-icon-active{
        background-color: #fdf0e9;
        cursor: default;
    }
    .edituser-tab-selector-divison{
        display: flex;
        gap: 2vw;
    }
    .general-page-body-input-text{
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        height: 6vh;
    }
    .edit-icon{
        color: #E5E5E5;
    }
    .general-page-body-input{
        border: none;
        font-weight: 400;
        font-size: 1.1em;
        letter-spacing: 0.15px;
        color: #666666;
        width: 80%;
    }
    .general-page-body-input:focus{
        outline: none;
    }
    .general-page-body-check-containear{
        display: flex;
        align-items: center;
    }
    .general-page-body-check-text{
        font-weight: 400;
        font-size: 1.19em;
        letter-spacing: 0.15px;
        color: #666666;
        margin-left: 10px;
    }
    .general-page-body-check{
        color: blue;
        cursor: pointer;
    }
    .general-page-body-save-containear{
        display: flex;
        justify-content: center;
    }
    .general-page-body-save-button{
        background: var(--primary-color);
        border-radius: 8px;
        padding: 2vh 3vw;
        font-weight: 600;
        font-size: 1.7em;
        color: #FFFFFF;
        cursor: pointer;
        margin-left: 20px;
    }
    .users-tab-header{
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        margin-top: 45px;
        padding: 1vh 0px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.2em;
        color: #605F5F;
    }
    .users-tab-content-line{
        display: flex;
        padding: 1vh 0;
        align-items: center;
        font-weight: 300;
        font-size: 1em;
        color: #605F5F;

    }
    .externaluser-loader{
        margin-top: 10vh;
    }
    .store-tab-address-containear{
        margin-right: 30px;
        flex: 1
    }
    .users-tab-colum{
        margin-left: 15px;
        flex: 1;
    }
    .users-tab-colum-small{
        margin-left: 15px;
        width: 15%;
    }
    .users-tab-colum-actions{
        display: flex;
        justify-content: center;
    }
    .edituser-tab-containear{
        margin-top: 2vh;
        display: flex;
        justify-content: space-between;
    }
    .edituser-tab-select{
        width: 100%;
        border: none;
        color: #B3B3B3;
    }
    .edituser-tab-select:focus{
        outline: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }
    .edituser-tab-buttons{
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
    .edituser-tab-buttons-cancel{
        border: 2px solid var(--primary-color);
        padding: 2vh 2vw;
        border-radius: 8px;
        font-weight: 500;
        font-size: 1.7em;
        color: var(--primary-color);
        cursor: pointer;
    }
    .users-tab-list-add-user-containear{
        display: flex;
        justify-content: flex-end;
        flex: 1;
    }
    .users-tab-list-add-user-button{
        display: flex;
        align-items: center;
        border: 1px  solid #E5E5E5;
        border-radius: 8px;
        padding: 14px 18px;
        cursor: pointer;
        background-color: white;

        margin-bottom: 10px;
    }
    .users-tab-list-add-user-button{ margin-left: 2vw; }
    .users-tab-list-add-user-button:hover{
        background-color: var(--primary-color);
    }
    .users-tab-add-user-icon{
        color: var(--primary-color);
        margin-right: 10px;
    }
    .users-tab-list-add-user-button:hover > .users-tab-add-user-icon{
        color: white;
    }
    .users-tab-add-user-text{
        font-weight: 600;
        font-size: 1.1em;
        line-height: 17px;
        color: #202020;
    }
    .users-tab-list-add-user-button:hover > .users-tab-add-user-text{
        color: white;
    }
    .edituser-tab-newuser-title-container{

        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    .edituser-tab-newuser-title {
        font-weight: 600;
        font-size: 2.04em;
        letter-spacing: 1px;
        color: var(--primary-color);
    }
    .users-tab-mobile-show{
        display: none;
    }
    .users-tab-list-add-user-button-mobile{
        padding: 5px !important;
    }
    .page-plan-containear{
        font-size: 1em;
    }
    .page-plan-info{
        display: flex;
    }
    .page-plan-info-table{
        width: 70%;
    }
    .page-plan-info-buttons{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .page-plan-info-table-header{
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        padding: 1vh 5px;
        display: flex;
        font-weight: 400;
        font-size: 1.61em;
        color: #605F5F;
        margin-bottom: 2vh;
    }
    .settings-page-mobile-header{display: none;}
    .page-plan-info-table-row{
        display: flex;
        font-weight: 300;
        font-size: 1.36em;
        color: #605F5F;
        margin-bottom: 1vh;
        padding: 0 5px;
    }
    .page-plan-info-total-row{
        display: flex;
        font-weight: 600;
        font-size: 1.36em;
        color: #505050;
        margin-top: 2vh;
        padding: 0 5px;
    }
    .table-padding{
        padding: 1vh 5px;
        margin-bottom: 2vh;
    }
    .page-plan-info-second-button{ margin-top: 4vh; }
    .info-table-product-width{ width: 30%; }
    .info-table-product-quantity{ width: 30%; }
    .plan-stores-table-colum-name{flex: 1; padding-right: 1vw;}
    .plan-stores-table-colum-cnpj{width: 30%;}
    .plan-stores-table-colum-contact{width: 10%;}
    .plan-stores-table-colum-value{width: 11%;}
    .plan-stores-table-colum-date{width: 14%; }
    .plan-stores-table-colum-descont{width: 11%;}
    .plan-stores-table-colum-total{width: 25%;}
    .plan-stores-table-colum-status{width: 14%;}

    .plan-store-table-row{
        display: flex;
        padding: 2vh 5px;
        font-weight: 300;
        font-size: 1em;
        color: #605F5F;
        text-transform: uppercase;
    }
    .page-table-line-special{ background: #F7F7F7; }
    .plan-store-table{ min-width: 1100px; }
    .plan-store-table-overflow{ overflow-x: auto;}
    .page-plan-title{
        display: flex;
    }
    .page-plan-table-overflow{ overflow-x: auto; }
    .page-plan-title-text{
        font-weight: 600;
        font-size: 2.7em;
        letter-spacing: 0.15px;
        color: #605F5F;
    }
    .page-plan-comeback-button{
        width: fit-content;
        margin-left: auto;
    }
    .page-plan-table-containear{
        margin-top: 2vh;
        display: flex;
        justify-content: space-between;
        min-width: 1100px;
    }
    .page-plan-table-legend-colum{
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        width: 17%;
    }
    .page-plan-table-plan-colum{
        display: flex;
        flex-direction: column;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        width: 14%;
        cursor: pointer;
    }
    .page-plan-table-legend-title{
        font-weight: 600;
        font-size: 2.5em;
        color: var(--primary-color);
        height: 17vh;
        padding-left: 5%;
        padding-top: 2vh;
    }
    .page-plan-table-legend-line{
        display: flex;
        align-items: center;
        padding: 0 5%;
        height: 7vh;
        font-weight: 300;
        font-size: 1.2em;
        color: #404040;
    }
    .page-plan-table-special-line{ background: #F7F7F7; }
    .page-plan-table-plan-header{
        height: 17vh;
        padding-left: 5%;
        padding-top: 2vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .green-circle{
        height: 1.5em;
        width: 1.5em;
        background-color: #2ACD72;
        border-radius: 50%;
    }
    .brow-circle{
        height: 1.5em;
        width: 1.5em;
        background-color: #9E4912;
        border-radius: 50%;
    }
    .grey-circle{
        height: 1.5em;
        width: 1.5em;
        background-color: #75787E;
        border-radius: 50%;
    }
    .gold-circle{
        height: 1.5em;
        width: 1.5em;
        background-color: #FFCA2B;
        border-radius: 50%;
    }
    .blue-circle{
        height: 1.5em;
        width: 1.5em;
        background-color: #17A2B8;
        border-radius: 50%;
    }
    .freeplan-header-name{
        font-weight: 500;
        font-size: 1.2em;
        color: #75787E;
    }
    .freeplan-header-title{
        font-weight: 500;
        font-size: 2.2em;
        color: var(--primary-color);
    }
    .freeplan-header-info{
        font-weight: 500;
        font-size: 0.9em;
        color: #75787E;
    }
    .page-plan-table-check-icon{
        height: 7vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .check-icon-disable{ color: #E5E5E5; }
    .check-icon-active{ color: #75787E; }
    .page-plan-table-products{
        font-weight: 600;
        font-size: 1.2em;
        color: #505050;
        height: 7vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .plan-stores-table-pendencie{
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 0.3vh 0.6vw;
        width: fit-content;
        cursor: pointer;
        color: white;
    }
    .users-tab-colum-company{
        width: 25%;
        padding: 0.3vh 0.6vw;
    }
    .users-tab-colum-name{
        width: 20%;
        padding: 0.3vh 0.6vw;
    }
    .users-tab-colum-action{
        width: 10%;
        text-align: center;
    }
    .store-tab-sub-row{
        display: flex;
    }
    .plan-store-table-delete{
        border-radius: 8px;
        background-color: #fdf0e9;
        padding: 14px 18px;
        cursor: pointer;
        color: lightcoral;
        font-weight: 600;
        font-size: 1.2em;
        outline: 1px solid lightcoral;
        margin-bottom: 10px;
        &:hover{
            background-color: white;
        }
    }
    .plan-store-table-reactivate{
        border-radius: 8px;
        background-color: #ebf7ec;
        padding: 14px 18px;
        cursor: pointer;
        color: lightgreen;
        font-weight: 600;
        font-size: 1.2em;
        outline: 1px solid lightgreen;
        margin-bottom: 10px;
        &:hover{
            background-color: white;
        }
    }
    .mobile{display: none;}

    @media only screen and (max-width: 800px) {
        .page{ font-size: 1.2vw;  padding-right: 0;}
        .mobile{display: block;}

        .general-page-body-title{
            display: flex;
        }
        .general-page-body-input-text{
            display: flex;
        }
        .page-section-select-size{
            width: 100%;
        }
        .edituser-tab-containear{
            width: 97%;
        }
        .general-page-body-containear{
            margin-right: 3vw;
        }
        .users-tab-desktop-hide{
            display: none;
        }
        .users-tab-mobile-show{
            display: flex;
        }
        .users-tab-list-add-user-containear-mobile{
            margin-top: 20px;
            margin-bottom: -15px;
        }
        .info-table-product-width{ width: 50%; }
        .info-table-product-quantity{ width: 50%; }
        .page{ font-size: 2vw; }
        .users-tab-header{
            display: none;
        }
        .page-plan-info{
            flex-direction: column;
        }
        .page-plan-info-table{
            width: 100%;
        }
        .mobile-button{
            width: 100%;
        }
        .page-plan-info-first-button{
            margin-top: 2vh;
        }
        .users-tab-content-line{
            flex-direction: column;
            gap: 1vh;
            margin-bottom: 5vh;
        }
        .users-tab-colum{
            display: flex;
            width: 100%;
            flex: unset;
            justify-content: space-between;
            font-size: 1.4em;
            margin-left: 0;
            padding-right: 3vw;
            text-align: end;
        }
        .users-tab-colum-company{
            width: 100%;
            padding: 0.3vh 0.6vw;
            display: flex;
            width: 100%;
            flex: unset;
            justify-content: space-between;
            font-size: 1.4em;
            margin-left: 0;
            padding-right: 3vw;
            text-align: end;
        }
        .users-tab-colum-name{
            width: 100%;
            padding: 0.3vh 0.6vw;
            display: flex;
            width: 100%;
            flex: unset;
            justify-content: space-between;
            font-size: 1.4em;
            margin-left: 0;
            padding-right: 3vw;
            text-align: end;
        }
        .users-tab-colum-action{
            width: 100%;
            display: flex;
            width: 100%;
            flex: unset;
            justify-content: space-between;
            font-size: 1.4em;
            margin-left: 0;
            padding-right: 3vw;
            text-align: end;
        }
        .settings-page-mobile-header{
            display: flex;
            font-size: 1.1em;
            font-weight: 700;
        }
        .general-page-body-disable-input{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        .page-section-select-size{
            margin-right: 0;
        }
        .edituser-tab-buttons{
            flex-direction: column-reverse;
            margin-top: 15px;
        }
        .general-page-body-save-button{
            margin-left: 0;
            text-align: center;
            width: 45%;
        }
        .edituser-tab-buttons-cancel{
            text-align: center;
            width: 45%;
        }
        .store-tab-address-containear{
            margin-right: 5px;
        }
        .store-tab-adress-input-mobile{
            height: 6vh
        }
        .users-tab-list-add-user-button{
            padding: 0 4px;
        }
        .mobile-table-row{
            display: flex;
            justify-content: space-between;
            width: 97%;
            color: #605F5F;
            font-size: 1.2em;
        }
        .mobile-table-end{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .mobile-table-center{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .users-tab-list-containear{margin-top: 3vh;}
        .user-tab-name-text{font-size: 1.1em;}
        p{margin: 0;}
        .edituser-tab-selector{
            flex-direction: column-reverse;
        }
        .edituser-tab-selector-divison{
            justify-content: space-between;
            display: flex;
            margin-bottom: 1vh;
        }
        .edituser-tab-selector-icon{width: 45%;}
        .edituser-tab-newuser-title-container{
            flex-direction: column;
            gap: 1vh;
            width: 98%;
        }
        .store-tab-sub-row{
            flex-direction: column;
        }
        .mobile-buttons-align{
            flex-direction: row;
            gap: 5vw;
        }
        .plan-store-table{min-width: 0;}
        .desktop{display: none;}
    }
    @media only screen and (max-width: 550px){
        .page{ font-size: 3vw;}
    }
</style>
