<template>
    <div class="makeorder-page-table-uniprice-width">
        <div class="makeorder-page-table-mobile-header">Ult. Compra</div>
        <div style="display: flex; align-items: center;">
            <span v-show="product.ultimaCompra" @click="show_orders(product)" title="Histórico de Pedidos" class="material-icons-outlined makeorder-page-table-uniprice-icon">assignment</span>
            {{ product.ultimaCompra ? product.ultimaCompra : 0 | customCurrency }}
        </div>

    </div>
</template>

<script>
import currencyPrecision from '../../mixins/currencyPrecision';

export default {
    name: 'LastBuyLine',
    props: ['product','show_orders'],
    mixins: [currencyPrecision]
}
</script>

<style scoped>
.makeorder-page-table-uniprice-width{
    width: 10%;
    display: flex;
    align-items: center;
}

.makeorder-page-table-mobile-header {
    display: none;
}
.makeorder-page-table-uniprice-icon{
    color: #4267B2;
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}


@media screen and (max-width: 850px) {
    .makeorder-page-table-uniprice-width{
        width: fit-content !important;
        display: flex;
        gap: 2vw;
        align-items: flex-end;
    }

    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>
