<template>
    <div class="page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Produtos
            </div>
        </div>
        <div class="page-title">
            Produtos
        </div>
        <div class="products-section-select-containear">
            <div class="products-section-select-size"  :class="getActiveTab('myProducts')" v-on:click="tab = 'myProducts'">
                <span class="material-icons-outlined desactive-icon" >
                    inventory_2
                </span>
                <div class="product-section-select-text">
                    Meus Produtos
                </div>
            </div>
            <div class="products-section-select-size" :class="getActiveTab('foodService')" v-on:click="tab = 'foodService'">
                <span class="material-icons desactive-icon"  >
                    storefront
                </span>
                <div class="product-section-select-text">
                    Food Service
                </div>
            </div>
        </div>
        <MyProductsTab v-if="tab == 'myProducts'"/>
        <FoodServiceTab v-else-if="tab == 'foodService'" />

    </div>
</template>
<script>
import MyProductsTab from './MyProductsTab'
import FoodServiceTab from './FoodServiceTab.vue'
export default {
    name: 'ProductsPage',
    components: {
        MyProductsTab,
        FoodServiceTab
    },
    created () {
        if (this.$route.params.tab) this.tab = this.$route.params.tab
    },
    data() {
        return {
            tab: 'myProducts',
        }
    },
    methods: {
        getActiveTab (value) {
            if (value == this.tab) return 'product-section-select-size-active'
        }
    }
}
</script>


<style scoped >
.page {
    font-size: 1vw;
}
.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.page-title {
    font-weight: 600;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 45px;
}

.products-section-select-containear{
    border-bottom: 0.5px solid #E5E5E5;
    display: flex;
    margin-top: 4vh;
    align-items: flex-end;
    padding-bottom: 1vh;
    margin-bottom: 3vh;
}
.products-section-select-size{
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    margin-right: 2vw;
}
.products-section-select-size:hover {
    background-color: #fdf0e9;
}
.products-section-select-size-active{
    cursor: default;
    border-radius: 8px 8px 8px 8px;
    margin-top: 20px;
    background-color: #fdf0e9;
}
.product-section-select-text{
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
    margin-left: 1vw;
}
.desactive-icon{
    color: var(--primary-color);
}
.products-section-select-size:hover > .product-section-select-text{
    color: var(--primary-color);
}
.product-section-select-size-active{
    cursor: default;
    border-radius: 8px 8px 8px 8px;
    background-color: #fdf0e9;
}
.product-section-select-size-active > .product-section-select-text{
    color: #202020 !important;
}
@media only screen and (max-width: 1000px) {
    .page {
        font-size: 1.2vw;
    }
}
@media only screen and (max-width: 850px) {
    .page { font-size: 2vw; }
}
@media only screen and (max-width: 550px) {
    .page {
        font-size: 3vw;
    }
}
</style>
