<template>
    <div class="request-table-container">
            <div v-for="(item, idx) in list" :key="idx" class="mobile-table-line" :class="{ 'page-table-line-special': idx % 2 != 0 }">
                <h1 class="mobile-table-title" style="margin-bottom: 1.5vh;">{{ item.pro_descricao }} -- {{ item.pro_ean }}</h1>
                <div class="mobile-table-row">
                    <div class="mobile-table-cell">
                        <h2 class="mobile-table-title">Embalagem</h2>
                        <span class="mobile-table-cell-text">{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</span>
                    </div>
                    <div class="mobile-table-cell">
                        <h2 class="mobile-table-title">Quantidade</h2>
                        <span class="mobile-table-cell-text">{{ item.quantidade ? item.quantidade : '--' }}</span>
                    </div>
                    <div class="mobile-table-cell">
                        <h2 class="mobile-table-title">Estoque</h2>
                        <span class="mobile-table-cell-text">{{ item.estoque ? item.estoque : '--' }}</span>
                    </div>
                </div>
                <!-- <th class="request-table-cell request-table-cot-width">{{ item.pro_ean }}</th>
                <th class="request-table-cell">{{ item.pro_descricao }}</th>
                <th class="request-table-cell">{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</th>
                <th class="request-table-cell">{{ item.quantidade ? item.quantidade : '--' }}</th>
                <th class="request-table-cell">{{ item.estoque ? item.estoque : '--' }}</th> -->
            </div>
    </div>
</template>

<script>

export default {
    name: 'SelectProductTableMobile',
    props: ['list'],
}
</script>

<style scoped lang="scss">
.request-table-container {
    display: none;
    margin: 20px;
    overflow: auto;
    max-height: 70vh;
    scrollbar-width: unset;
}

.request-table-body {
    outline: red;
}

.request-table-header {
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    color: #605F5F;
    font-size: 1.1em;
    font-weight: 400;
}

.request-table-product-width {
    flex: 1;
}

.request-table-number-width {
    width: 25%;
}

.request-table-row {
    color: #898888;
    font-size: 1em;
}

.request-table-cell {
    padding: 1vh 0 !important;
    border-bottom: none;
}

.request-table-cot-width {
    width: 10%;
    padding-left: 2% !important;
}

.request-table-button {
    height: 5vh;
}
.request-table-quantity-width{
    width: 12%;
}
.mobile-table-title{
    font-size: 1.2em;
    font-weight: bold;
    color: #605F5F;
    margin-bottom: 0;
}
.mobile-table-row{
    display: flex;
    justify-content: space-between;
}
.mobile-table-cell{
    text-align: center;
}
.mobile-table-cell-text{
    font-size: 1em;
    color: #605F5F;
}
.mobile-table-line{
    margin-bottom: 1vh;
    padding: 2vh 1vw;
}
.page-table-line-special {
  background: #F7F7F7;
}
@media only screen and (max-width: 850px){
    .request-table-container{display: block;}
}
</style>