<template>
    <!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow">
				<div class="col" v-show="provider">
                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form action="" v-if="provider" @submit.prevent="handleSubmit(save)">

                            <div class="row d-flex justify-content-center section-header">
                                <div class="col-12">
                                    <label>Informações da empresa </label>
                                </div>
                            </div>

                            <ProviderFields :provider="provider" />

                            <div v-if="!provider.id" class="row section-header">
                                <div class="col-12">
                                    <label>Informações do usuário</label>
                                </div>
                            </div>

                            <UserFields v-if="!provider.id" :provider="provider" />

                            <div class="row section-header">
                                <div class="col-12">
                                    <label>Informações da matriz</label>
                                </div>
                            </div>

                            <StoreFields :provider="provider" />

                            <div class="row pt-2">
                                <div v-if="message" class="col-md-12 col-lg-12">
                                    <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                </div>
                                <div class="col d-flex justify-content-center">
                                    <button class="btnFeedbackModal" type="submit">Salvar</button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
				</div>
			</div>
		</div>
	</cc-modal> -->
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-provider-header">
                <!-- <div>{{id ? "Editar fornecedor" : "Novo Fornecedor"}}</div> -->
                <div v-if="id">Editar fornecedor</div>
                <div v-else>Novo Fornecedor</div>
                <span v-on:click="close" class="material-icons new-provider-close-icon">cancel</span>
            </div>
            <div v-if="!loading" class="new-provider-body">
                <div class="new-provider-body-buttons">
                    <StandardButton class="new-provider-buttons-size" :action="close" :iconName="'arrow_back'"
                        :text="'Voltar'" />
                    <StandardButton class="new-provider-buttons-size" :action="save" :iconName="'save'" :text="'Salvar'" />
                </div>
                <div v-if="message" class="new-provider-error-message">{{ message }}</div>
                <ProviderField :provider="provider" :getCnpjInfo="getCnpjInfo" />
                <UserField v-if="!provider.id" :provider="provider" />
                <StoreFields :states="states" :allCities="allCities" :highlightAdress="highlightAdress" :provider="provider" />
            </div>
            <cc-loader-full v-else/>
        </div>
    </div>
</template>

<script>

import ProviderService from "@/services/v3/provider.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UserFields from './_user.fields'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue';
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StoreFields from './newStore.vue'
import ProviderField from './NewProvider.vue'
import UserField from './NewUser.vue'
import BrasilApiService from "../../../services/BrasilApi";
import UserService from "@/services/v1/user.service";
import QuotationService from "@/services/QuotationService";
import InvitationService from '@/services/v3/invitation.service'

export default {
    mixins: [loaderMixin],
    props: ['registration', 'id', 'reload'],
    components: {
        StoreFields,
        ValidationProvider,
        ValidationObserver,
        StandardButton,
        StandardInput,
        ProviderField,
        UserField
    },
    data() {
        return {
            users: [],
            categories: [],
            allCities: [],
            states: [],
            loading: true,
            message: null,
            stores: [],
            svc: new ProviderService(),
            user_svc: new UserService(),
            invitSvc: new InvitationService(),
            req_svc: new QuotationService(),
            brasilApi: new BrasilApiService(),
            provider: {
                manager: {},
                head_office: {},
                settings: [
                    { key: 'CLIENT_RELEASE_STATUS' }
                ]
            },
            isLoadingCnpj: false,
            highlightAdress: false,
            modal: {
                title: this.client_id ? `Editar Fornecedor` : 'Novo Fornecedor',
                icon_title: 'fas fa-users',
                cancel_text: 'Voltar',
                style: {
                    width: "90%",
                    height: "fit-content"
                }
            }
        };
    },
    watch: {
        'provider.social_reazon': function (val) {
            this.provider.head_office.social_reazon = val
        },
        'provider.trading_name': function (val) {
            this.provider.head_office.trading_name = val
            this.provider.name = val
        },
        'provider.email': function (val) {
            if (this.provider.manager) {
                this.provider.manager.email = val
            }
            this.provider.head_office.email = val
        },
        'provider.cnpj': function (val) {
            this.provider.head_office.cnpj = val
        },
        'provider.phone': function (val) {
            this.provider.head_office.phone = val
            if (this.provider.manager) {
                this.provider.manager.phone = val
            }
        }
    },
    methods: {
        getCnpjInfo(cnpj) {
            if(this.isLoadingCnpj) return
            this.isLoadingCnpj = true
            this.brasilApi.getCnpjInfo(cnpj).then(resp => {
                this.isLoadingCnpj = false
                this.$set(this.provider, 'contact', resp.data.qsa[0] ? resp.data.qsa[0].nome_socio : '')
                this.$set(this.provider, 'phone', resp.data.ddd_telefone_1)
                this.$set(this.provider.head_office, 'social_reazon', resp.data.razao_social)
                this.$set(this.provider.head_office, 'trading_name', resp.data.razao_social.replace('LTDA', ''))
                // this.$set(this.provider.head_office, 'state', resp.data.uf)
                // this.$set(this.provider.head_office, 'city', resp.data.municipio)
                this.$set(this.provider.head_office, 'zip_code', resp.data.cep)
                this.$set(this.provider.head_office, 'street', resp.data.logradouro + ' ' + resp.data.numero)
                this.$set(this.provider.head_office, 'district', resp.data.bairro)
                this.states.forEach(element => {
                    if( element.value.est_sigla == resp.data.uf){
                        this.$set(this.provider.head_office, 'state', {...element.value, id:element.value.est_id})
                        this.allCities.forEach(element2 => {
                            if( element2.est_id == element.value.est_id && element2.cid_nome.toLowerCase() == resp.data.municipio.toLowerCase()){
                                this.$set(this.provider.head_office, 'city', {...element2, id: element2.cid_id})
                            }
                        });
                    }
                });

            })
        },
        load_region_data() {
            return this.user_svc.region_data().then(response => response.data).then(data => {
                this.states = data.estados.map(c => ({ value: c, text: c.est_nome }))
                this.allCities = data.cidades
                this.loading = false
            })
        },
        remove(event, user) {
            event.preventDefault()
            event.stopPropagation()
            this.confirm_action({
                message: "Confirma remoção do usuário?",
                subtitle: `O usuário ${user.usu_nome} será removido!`,
                callback: () => {
                    this.svc.remove(this.provider.cli_id, user).then(() => this.load_users())
                }
            })
            return false
        },
        save() {
            if (!this.provider.head_office.state) {
                this.highlightAdress = true
                return
            }
            this.present_loader("Salvando usuário...")
            this.message = null
            let callback = data => {
                this.$swal.fire(
                    'Sucesso!',
                    `Fornecedor ${this.id ? 'atualizado' : 'criado'} sucesso.`,
                    'success'
                );

                this.svc.find_by_phone(this.provider.manager.phone).then(resp => {
                    const invitation = {
                        receiver_name: this.provider.contact,
                        receiver_phone: this.provider.manager.phone,
                        receiver_company_name: this.provider.trading_name,
                        invited_user_id: resp.data.usu_id
                    }
                    this.invitSvc.send(invitation).then(() => {
                        this.req_svc.enableSeller(resp.data.usu_id).then(() => {
                            this.dismiss_loader()
                            this.reload()
                        })
                    })
                })
                this.close(true)
            }

            let callback_error = err => {
                this.dismiss_loader()
                this.message = 'Ops! Houve um erro ao salvar o Fornecedor, tente novamente mais tarde!'
                // if(err.response && err.response.data && err.response.data.errors) {
                //     let errors = err.response.data.errors
                // 	this.message = Object.values(errors).flat()[0]
                // } else {
                //     this.message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
                // }
                // ErrorHandlerService.handle(error, this.$store);
            }
            if (this.provider.id) {
                this.svc.update(this.provider).then(callback, callback_error)
            } else {
                this.svc.create(this.provider).then(callback, callback_error)
            }
        },
        add_new() {
            this.toggle_user({ store: this.stores[0], profile: {} })
        },
        close(reload = false) {
            this.$emit("close", reload);
        },
        load_provider() {
            if (this.id) {
                this.present_loader("Carregando fornecedor...")
                return this.svc.show(this.id).then(response => response.data).then(data => {
                    this.provider = data
                    this.provider.manager = {}
                    this.loading = false
                    this.dismiss_loader()
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
            }
            if (this.registration) {
                this.provider = {
                    ...this.provider,
                    registration_id: this.registration.id,
                    cnpj: this.registration.cnpj,
                    email: this.registration.email,
                    manager: {
                        name: this.registration.name,
                        password: this.registration.password
                    },
                    phone: this.registration.phone,
                }
                this.loading = false
            } else this.loading = false
        },
        process_stores() {
            this.users.forEach(u => u.store = this.stores.find(p => p.loj_id == u.usu_idLoja))
            this.$forceUpdate()
        }
    },
    mounted() {
        let resp = ''
        for (let index = 0; index < 6; index++) resp += Math.floor(Math.random() * 10);
        this.provider.manager.password = resp
        this.load_region_data()
    }
};
</script>

<style lang="scss" scoped>@import "./new-provider.modalV2";</style>
