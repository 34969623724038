import Service from "../Service";
import http from "./http";

/**
 * @type QuotationService
 */
export default class RequestsService extends Service {

	create(data) {
		return http.post("requests/", data, {
			headers: this.getHeaders()
		});
	}

	resupply(data) {
		return http.post("requests/resupply", data, {
			headers: this.getHeaders(),
			timeout: 36000
		});
    }

    save_pre_request_details(id, data) {
		return http.post(`requests/${id}/details`, data, {
			headers: this.getHeaders()
		});
	}

	import_products(data) {
		return http.post("requests/products/import", data, {
			headers: this.getHeaders(),
			timeout: 36000
		});
	}

	add_seller(id, data) {
		return http.put(`requests/${id}/sellers/add`, data, {
			headers: this.getHeaders()
		});
	}

    add_sellers(id, data) {
		return http.put(`requests/${id}/sellers/addmany`, data, {
			headers: this.getHeaders()
		});
	}

	remove_seller(id, sellerid) {
		return http.delete(`requests/${id}/sellers/${sellerid}`, {
			headers: this.getHeaders()
		});
	}

	remove_all_sellers(id) {
		return http.delete(`requests/${id}/sellers/all`, {
			headers: this.getHeaders()
		});
	}

	sellers_in(id, page) {
		return http.get(`requests/${id}/sellers?page=${page}`, {
			headers: this.getHeaders(),
			timeout: 20000
		});
	}

	sellers_out(id, data) {
		return http.get(`requests/${id}/sellers/out`, {
			headers: this.getHeaders(),
			params: data,
			timeout: 20000
		});
	}

	request_seller_products(id, seller_id, page_size, query_params, token) {
		return http.get(`/requests/${id}/sellers/${seller_id}/products`, {
            cancelToken: token,
			headers: this.getHeaders(),
			timeout: 60000,
            params: {
                ...query_params,
                page_size,
                with_last_offer: query_params.with_last_offer ? 1 : 0,
            }
		});
	}

	request_products(id, params) {
		return http.get(`/requests/${id}/products`, {
			headers: this.getHeaders(),
			timeout: 20000,
			params: params
		});
	}

	request_products_matrix(id, params) {
		return http.get(`/requests/${id}/products/matrix`, {
			headers: this.getHeaders(),
			params: params
		});
	}

	request_products_with_offers(id, params, token = null) {
		return http.get(`/requests/${id}/products/offers`, {
            headers: this.getHeaders(),
            params: params,
            timeout: 50000,
            cancelToken: token
        });
	}

    others_offers(id, params) {
		return http.get(`/requests/${id}/offers/others`, {
			headers: this.getHeaders(),
			params: params,
			timeout: 50000
		});
	}

	request_orders_by_seller(id, seller_id) {
		return http.get(`/requests/${id}/sellers/${seller_id}/orders`, {
			headers: this.getHeaders()
		});
	}

	request_report(id) {
		return http.get(`/requests/${id}/reports/psmatrix`, {
			headers: this.getHeaders(),
			timout: 20000,
			responseType: 'blob'
		});
	}

	subrequests_with_sellers_for(id) {
		return http.get(`/requests/${id}/subrequests`, {
			headers: this.getHeaders()
		});
	}

	update_request_product_offer(id, product_id, data) {
		return http.put(`/requests/${id}/products/${product_id}/offer`, data, {
			headers: this.getHeaders(),
		});
	}

	request_report_for_seller_by_store(id, store_id, page, for_id) {
		return http.get(`/requests/${id}/store/${store_id}?registrosPorPagina=20&pagina=${page}&fornecedor=${for_id}`, {
			headers: this.getHeaders(),
		});
	}

    add_product_to_resupply_request(id, data) {
		return http.post(`/requests/${id}/products/add`, data, {
			headers: this.getHeaders()
		});
	}

	winners_map_for(id, store_id, seller_id, provider_id) {
		return http.get(`/requests/${id}/store/${store_id}/offers?provider_id=${provider_id}&seller_id=${seller_id}`, {
			headers: this.getHeaders(),
			timeout: 20000
		});
    }

    notify_sellers(id) {
		return http.get(`/requests/${id}/sellers/notify`, {
			headers: this.getHeaders(),
			timeout: 20000
		});
	}

	prerequest_pdf_report(params) {
		return http.get(`/requests/${params.id_cotacao}/sellers/${params.usu_id}/pre/report`, {
			headers: this.getHeaders(),
			timout: 20000,
			responseType: 'arraybuffer',
			params: {
				id_pedido: params.id_pedido,
				numeroCotacao: params.id_cotacao,
				loja: params.id_loja,
				id_vendedor: params.usu_id
			}
		});
    }

    request_products_pdf_report(id, store_id) {
		return http.get(`/requests/${id}/products/pdf`, {
			headers: this.getHeaders(),
			timout: 20000,
			responseType: 'arraybuffer',
			params: { store_id: store_id }
		});
	}

    request_stores(id) {
		return http.get(`/requests/${id}/subrequests/stores`, {
			headers: this.getHeaders(),
			timeout: 20000
		});
	}

    store_request(id, data){
        return http.put(`/requests/${id}/store`, data, {
			headers: this.getHeaders()
		});
    }
	importExcel(data){
		return http.put("/offerImport/", data, {
			headers: this.getHeaders(),
			timeout: 500000
		});
	}
    importExcelV2(data){
		return http.put("/offerImportV2/", data, {
			headers: this.getHeaders(),
			timout: 120000
		});
	}
}
