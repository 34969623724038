export const pag_mixin = {
    data() {
        return {
            collection: [],
            _collection: [],
            page: 1,
            per_page: 10,
            async: false,
            start_idx: 0,
            collection_size: 0,
            is_loading: true,
        }
    },
    watch: {
        page: function(new_page) {
            this.page = new_page > 0 ? new_page : 1
            this.paginate(this._collection)
        }
    },
    methods: {
        paginate(collection) {
            if(this.async) {
                this._collection = collection
                this.collection_size = collection.length
                this.start_idx = (this.page - 1) * this.per_page
                this.end_idx = this.start_idx + this.per_page
                if(this.end_idx > this.collection_size) {
                    this.end_idx = this.collection_size
                }
                this.collection = this._collection.slice(this.start_idx, this.end_idx)
            } else {
                this._collection = collection
                this.collection = collection
                this.start_idx = (this.page - 1) * this.per_page
                this.end_idx = this.start_idx + this.per_page
                if(this.end_idx > this.collection_size) {
                    this.end_idx = this.collection_size
                }
            }
        }
    }
}
