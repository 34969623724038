<template>
    <div class="page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Meus produtos
            </div>
        </div>
        <div class="page-title">Monitor de Ressuprimento </div>
        <div class="page-buttons-containear">
            <StandardInput style="margin-right: 3vw;" :placeholder="'Selecione a empresa'"
                     :action="setSelectedStore"
                     class="mobile-button-size"
                     :list="storesList"
                     :type="'select'"/>
            <div class="mobile-button-size"></div>

            <StandardButton class="page-button-width mobile-button-size" v-if="can('REQUEST', 'CREATE')" :action="sendResupply" :iconName="'mail_outline'" :text="'Enviar Cotação'"/>
            <StandardButton class="page-button-width mobile-button-size" v-if="can('REQUEST', 'DELETE_RESUPPLY')" :action="deleteQuote" :iconName="'delete_outline'" :text="'Apagar'"/>
            <StandardButton class="page-button-width mobile-button-size" v-if="can('REQUEST', 'CREATE_RESUPPLY')" :action="() => {showImportExcel = true; showImportResupplyModal = true}" :iconName="'south'" :text="'Importar'"/>
            <StandardButton class="page-button-width mobile-button-size" v-if="can('REQUEST', 'CREATE_RESUPPLY')" :action="mergeResupply" :iconName="'grading'" :text="'Agrupar'"/>
            <div class="resuply-check-container" @click="statusFilter=!statusFilter">
                <span class="material-icons-outlined resuply-check-icon" v-if="!statusFilter">check_box_outline_blank</span>
                <span class="material-icons-outlined resuply-check-icon" v-else>check_box</span>
                Visualizar cancelados
            </div>

        </div>
        <div class="table-overflow">
            <div class="table-width">
                <div class="table-header">
                    <span v-if="isAllCheck" v-on:click.stop="setAllCheck(false)" class="material-icons checkbox-icon-containear">check_box</span>
                    <span v-else v-on:click.stop="setAllCheck(true)" class="material-icons checkbox-icon-containear">check_box_outline_blank</span>
                    <div class="table-colum-align table-header-text date-width">Data</div>
                    <div class="table-colum-align table-header-text request-width">Solicitação</div>
                    <div class="table-colum-align table-header-text suplier-width">Empresa</div>
                    <div class="table-colum-align table-header-text product-width">Produtos</div>
                    <div class="table-colum-align table-header-text actions-width">Ações</div>
                </div>
                <div class="table-row" :class="getSpecialBackground(index)" v-for="(item, index) in filterResuplies(resupplies)" :key="index">
                    <div class="table-row-separator mobile-check-container">
                        <span v-on:click.stop="editItemCheck(item, false)" v-if="item.isCheck" class="material-icons  checkbox-icon-containear">check_box</span>
                        <span v-on:click.stop="editItemCheck(item, true)" v-else class="material-icons  checkbox-icon-containear">check_box_outline_blank</span>
                        <div class="table-mobile-header mobile"> {{ `${item.nomeLoja} ${item.nomeSolicitacao ? `(${item.nomeSolicitacao})` : '' }` }}</div>

                    </div>

                    <!-- desktop -->
                    <div class="desktop table-colum-align table-row-text table-row-separator date-width">
                        <div class="table-mobile-header">Data</div>
                        {{ formatDate(item.dataCotacao) }}
                    </div>
                    <div class="desktop table-colum-align table-row-text table-row-separator request-width">
                        <div class="table-mobile-header">Solicitação</div>
                        {{ item.numeroCotacao}}
                    </div>
                    <div class="desktop table-colum-align table-row-text table-row-separator suplier-width" >
                        <div class="table-mobile-header">Empresa</div>
                        {{ `${item.nomeLoja} ${item.nomeSolicitacao ? `(${item.nomeSolicitacao})` : '' }` }}
                    </div>
                    <div class="desktop table-colum-align table-row-text table-row-separator product-width">
                        <div class="table-mobile-header">Produtos</div>
                        {{item.total_produtos}}
                    </div>

                    <!-- mobile -->
                    <div class="mobile mobile-table-row">
                        <div class="table-row-text" style="width: 30%;">
                            <div class="table-mobile-header">Data</div>
                            {{ formatDate(item.dataCotacao) }}
                        </div>
                        <div  class="table-row-text mobile-table-center" style="width: 35%;">
                            <div class="table-mobile-header">Empresa</div>
                            {{ `${item.nomeLoja} ${item.nomeSolicitacao ? `(${item.nomeSolicitacao})` : '' }` }}
                        </div>
                        <div  class="table-row-text mobile-table-left" style="width: 30%;">
                            <div class="table-mobile-header">Produtos</div>
                            {{item.total_produtos}}
                        </div>
                    </div>


                    <div class="table-colum-align actions-width">

                            <span v-if="can('REQUEST', 'UPDATE_RESUPPLY')" @click="showChangeStore(item)" title="Alterar Empresa" class="material-icons row-icon" style="color: #2fa7ba; font-size: 1.5em">sync_alt</span>
                            <span v-if="can('REQUEST', 'UPDATE_RESUPPLY') || item.status == 15" @click="editClick(item)" title="Editar" class="material-icons row-icon" style="color: #f69250; font-size: 1.5em">edit</span>
                            <span class="material-icons-outlined row-icon" title="Lista mobile" style="color: lightgreen; font-size: 1.6em" v-if="item.list_ids.length > 0" v-on:click="mobileItem = item">phone_iphone</span>
                            <span @click="showRequestedProductsModal=item.numeroCotacao" class="material-icons-outlined products-icon row-icon" title="Listar produtos">inventory_2</span>
                            <span v-if="can('REQUEST', 'UPDATE_RESUPPLY') && item.status == 15" @click="createExcel(item)" title="Gerar Excel" class="material-icons row-icon" style="color: lightseagreen; font-size: 1.5em">folder</span>
                    </div>
                </div>
            </div>
        </div>

        <ModalProductsResupply id="modal"
            :quotation="resupplyClick"
            :item="current_item"
            :closeDialog="closeProductResupply"
            v-if="showProductResupply" @close="closeProductResupply()" @loadRessuply="load_resupplies()" />
        <ModalImportExcel id="modal"
                          @finish="importSuccess"
                          v-if="showImportExcel && showImportResupplyModal"
                          :closeDialog="closeImportResupplyModal"
                          @close="closeImportExcel()"/>
        <ModalImportNewProducts id="modal-new-products" @reload="load_resupplies()" @close="new_products = []" v-if="new_products && new_products.length > 0" :products="new_products" />
        <ModalSendResupply id="modal"
                           :quotations="resupplySelect"
                           @finish="publishSuccess();"
                           v-if="showSendResupplyModal"
                           :closeDialog="closeSendResupplyModal"
                           @close="closeSendResuply()" />
        <ModalChangeStore id="modal" @finish="changeStoreSuccess" :ressuply="resupplySelected" v-if="showModalChangeStore" @close="closeModalChangeStore()" />
        <MobileModal v-if="mobileItem" :mobileItem="mobileItem" :close="() => mobileItem = undefined"/>
        <ModalQuotationProducts :quotation="showRequestedProductsModal" :hideExtraInfo="true"
                                    v-if="showRequestedProductsModal"
                                    :closeDialog="()=>showRequestedProductsModal=false"/>
        <cc-loader-full v-if="loading"/>
    </div>
</template>
<script>
import QuotationService from '@/services/QuotationService';
import ModalProductsResupply from "@/modals/resupply-products/resupply-products.modal";
import ModalImportExcel from "@/modals/import-resupply/import-resupply.modal";
import ModalImportNewProducts from "./resupply/import-products.modal";
import ModalSendResupply from "@/modals/send-resupply/send-resupply.modal";
import ModalChangeStore from "@/modals/change-store/change-store.modal";
import ModalQuotationProducts from "@/modals/requested-products/requested-products.modal";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import NoDataAvailable from "@/components/utils/no-data.utils"
import StorageService from '@/services/local-storage.service';
import RequestsService from "@/services/v2/requests.service";
import { perm_mixin } from '@/mixins/permission.mixin';
import MobileNote from '@/components/notes/mobile-promotion.noteV2';
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import MobileModal from './MobileModal.vue';
import RessuplyExcelDownload from '@/services/RessuplyExcelDownload.js'


export default {
    mixins: [ perm_mixin ],
    components: {
        ModalProductsResupply,
        ModalImportExcel,
        ModalSendResupply,
        ModalQuotationProducts,
        ModalImportNewProducts,
        ModalChangeStore,
        'no-data-available': NoDataAvailable,
        MobileNote,
        StandardButton,
        StandardInput,
        MobileModal
    },
    data() {
        return {
            showImportResupplyModal: false,
            showSendResupplyModal: false,
            loading: false,
            resupplies: [],
            listResupplies: [],
            new_products: [],
            service: new QuotationService(),
            req_svc: new RequestsService(),
            mobileItem: undefined,
            resupplySelect: [],
            showProductResupply: false,
            showSendResuply: false,
            showImportExcel: false,
            showModalChangeStore: false,
            statusFilter: false,
            showRequestedProductsModal: undefined,
            resupplySelected: null,
            isAllCheck: false,
            current_item: null,
            storesList: [{text: 'Todos', value: undefined}],
            selectedStore: undefined
        }
    },
    watch:{
        statusFilter(){
            this.load_resupplies()
        }
    },
    methods: {
        filterResuplies(resupplies){
            if (this.selectedStore){
                const resp = []
                for (let index = 0; index < resupplies.length; index++) {
                    const element = resupplies[index];
                    if(element.nomeLoja == this.selectedStore) resp.push(element)
                }
                return resp
            }
            else return resupplies
        },
        editClick(item) {
            if (item.status == 15) {
                this.$store.commit('setEditResuply', item)
                this.$router.push('/cliente/monitores/ressuprimento/edit')
                return
            }
            this.current_item=item;
            this.resupplyClick = item.numeroCotacao;
            this.showProductResupply = true
        },
        createExcel (item) {
            this.loading = true
            this.service.getProductsResupply(item.numeroCotacao).then(resp => {
                let products = []
                const formater = new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })
                resp.data.produtos.forEach(p => {
                    p.balance = (p.custo_atual || 0) * (p.pro_generico == 1 ? p.quantidade : p.pro_embalagem.split(' ')[1])
                    if (p.preco_venda && p.custo_atual) p.margen = ((p.preco_venda/p.custo_atual || 0)-1)*100
                    else p.margen = 0
                    products.push([
                        p.pro_ean,
                        p.pro_descricao,
                        p.pro_embalagem.split(' ')[0],
                        p.pro_embalagem.split(' ')[1],
                        '',
                        '',
                        '',
                        p.quantidade,
                        '',
                        '',
                        p.observacao,
                        'R$ '+p.custo_atual,
                        'R$ '+p.balance,
                        'R$ '+p.preco_venda,
                        formater.format(parseFloat(p.margen)) + '%',
                        '--',
                        '--'
                    ])
                });
                new RessuplyExcelDownload(products)
                this.loading = false
            })
        },
        setSelectedStore(value){
            this.selectedStore = value
        },
        getSpecialBackground (idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
        closeImportResupplyModal: function() {
          this.showImportResupplyModal = false;
        },
        closeSendResupplyModal: function() {
          this.showSendResupplyModal = false;
        },
        setAllCheck (value) {
            this.isAllCheck = value
            if (this.isAllCheck) {
                this.resupplies.forEach(item => item.isCheck = true)
            } else {
                this.resupplies.forEach(item => item.isCheck = false)
            }
            this.$forceUpdate()
        },
        editItemCheck (item, value) {
            item.isCheck = value
            this.$forceUpdate()
        },
        formatDate (date) {
            return date.slice(0,16)
        },
        prepareResupplySelect () {
            this.resupplySelect = []
            this.resupplies.forEach(item => {
                if (item.isCheck) this.resupplySelect.push(item.numeroCotacao)
            })
        },
        sendResupply: function() {
            this.prepareResupplySelect()
            if (this.resupplySelect.length > 0) {
                /* this.showSendResuply = true; */
                this.showSendResupplyModal = true;
            } else {
                this.$swal.fire("Erro!", "Selecione uma cotação.", "error");
            }
        },
        importSuccess(new_products) {
            if(new_products) {
                this.new_products = new_products.map(p => {
                    let obj = p[Object.keys(p)[0]];
                    return {
                        'cot_id': Object.keys(p)[0],
                        'pro_ean': obj[0],
                        'pro_descricao': obj[1],
                        'pro_embalagem': `${obj[2]} ${obj[3]}`,
                        'estoque': obj[4],
                        'quantidade': obj[7],
                        'cat_id': obj.length > 8 ? obj[8] : null,
                        'pro_observacao': obj.length > 10 ? obj[10] : null
                    }
                })
            }
            this.$swal.fire(
                "Importado!",
                "Sua cotação foi importada com sucesso.",
                "success"
            );
            this.load_resupplies()
        },

        changeStoreSuccess() {

            this.$swal.fire(
                "Alterado!",
                "A troca da empresa foi efetuada com sucesso.",
                "success"
            );
            this.load_resupplies()
        },
        publishSuccess() {
            this.$swal
                .fire("Publicado!", "Sua cotação foi publicada com sucesso.", "success")
                .then(() => this.load_resupplies())
                .then(() => this.resupplySelect = [])
                .then(() => this.$router.push({ name: 'monitors-page', params: { tab: 'cotacoes' }}));
        },
        closeProductResupply: function() {
            this.showProductResupply = false;
        },
        load_resupplies() {
            this.loading = true;
            this.service
                .listResupply(this.statusFilter)
                .then(response => response.data)
                .then(data => {
                    
                this.resupplies = data.cotacoes.data;
                for (let index = 0; index < this.resupplies.length; index++) {
                    const element = this.resupplies[index];
                    let skip = false
                    for (let index2 = 0; index2 < this.storesList.length; index2++) {
                        const element2 = this.storesList[index2];
                        if (element.nomeLoja == element2.text) skip = true
                    }
                    if (!skip) this.storesList.push({text: element.nomeLoja, value: element.nomeLoja})
                }
                this.loading = false;
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        checkAllResupply(e) {
            this.resupplySelect = [];
            if (e.target.checked) {
                this.resupplies.forEach(element => {
                    this.resupplySelect.push(element.numeroCotacao);
                });
            }
        },
        closeImportExcel: function() {
            this.showImportExcel = false;
        },
        closeSendResuply: function() {
            /* this.showSendResuply = false; */
        },
        closeModalChangeStore: function() {
            this.showModalChangeStore = false;
        },
        deleteQuote: function() {
            this.prepareResupplySelect()
            if (this.resupplySelect.length > 0) {
                this.$swal
                .fire({
                    title: "Tem certeza que deseja deletar a solicitação?",
                    text: "Sua solicitação será apagada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo deletar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service
                        .deletequotationresuply(this.resupplySelect)
                        .then(response => response.data)
                        .then(data => {
                            this.load_resupplies();
                            this.resupplySelect = []
                            this.$swal.fire(
                                "Deletado!",
                                "Sua soliticação foi apagada com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Erro!",
                                "Ocorreu um erro ao realizar a ação.",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                })
            } else {
                this.$swal.fire("Ops!", "Selecione uma cotação!", "warning");
            }
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                {
                    name: "Painel de Operações",
                    route: 'monitors-page'
                },
                {
                    name: "Ressuprimento",
                    route: 'monitors-page'
                }
            ]);
        },
        mergeResupply(){
            if(this.verifyStoreResupply()){
                this.$swal
                .fire({
                    title: "Tem certeza que deseja agrupar as solicitações?",
                    text: "Suas solicitações serão agrupadas.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo agrupar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service
                        .mergequotationresuply(this.resupplySelect)
                        .then(response => response.data)
                        .then(data => {

                            this.resupplySelect = []

                            this.load_resupplies();

                            this.$swal.fire(
                                "Agrupado!",
                                "Suas solicitações foram agrupadas com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Ops!",
                                "Ocorreu um erro! Selecione apenas cotacoes da mesma loja e tente novamente!",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                })

            }else{
                this.$swal.fire("Ops!", "Selecione solicitações da mesma loja!", "warning");
            }
        },
        verifyStoreResupply(){
            this.prepareResupplySelect()
            let list = [];
            this.resupplySelect.forEach(resupply=>{
                this.resupplies
                    .filter(r => resupply == r.numeroCotacao)
                    .map(x => list.push(x));
            });
            let result = false;
            let uniqueStore = [...new Set(list.map(item => item.loj_id))];
            if(this.resupplySelect.length > 0 && uniqueStore.length == 1){
                result = true;
            }
            return result;
        },
        showChangeStore(ressuply){
            this.resupplySelected = ressuply;
            this.showModalChangeStore = true;
        }

    },
    created() {
        this.load_resupplies()
        this.update_breadcrumbs()
    }
}
</script>
<style lang="scss" scoped>
    // @import "../monitors.page";

    // /deep/ .buttons {
    //     a {
    //         margin-left: 10px;
    //     }
    // }
    // .checkBoxTableHeader{
    //     margin-left: 0px !important;
    // }
    // .button-alter{
    //     cursor: pointer;
    //     font-size: 20px;
    // }
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color: var(--primary-color);
  margin-right: 20px;
}
.page{
    font-size: 1vw;
    margin-bottom: 15vh;
}
.page-title{
  font-weight: 700;
  font-size: 2.72em;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 40px;
}
.mobile{display: none;}
.page-buttons-containear{
    display: flex;
    align-items: flex-end;
}
.button-icon{
    color: var(--primary-color);
    margin-right: 1vw;
    font-size: 2.55em;
}
.table-header{
    margin-top: 6vh;
    margin-bottom: 1.5vh;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    display: flex;
    align-items: center;
}
.table-colum-align{padding-left: 2vw;}
.date-width{width: 15%;}
.request-width{width: 15%;}
.product-width{width: 15%;}
.actions-width{width: 15%;}
.suplier-width{flex: 1; text-align: left; margin-left: 3vw;}
.table-row-separator{ border-right: 1px solid #DDE5E9; }
.table-header-text{
    font-style: normal;
    font-weight: 600;
    font-size: 1.19em;
    line-height: 17px;
    color: #605F5F;
}
.table-mobile-header{display: none;}
.table-row{
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}
.table-row-text{
    font-weight: 300;
    font-size: 1.19em;
    line-height: 17px;
    color: #605F5F;
}
.checkbox-icon-containear{
    margin-left: 1vw;
    padding-right: 1vw;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.5em;
}
.row-icon{
    margin-right: 1vw;
    cursor: pointer;
}
.products-icon{
    cursor: pointer;
    color: lightgreen;
}
.mobile-note-container{
    margin-top: 3vh;
    margin-right: 3vw;
}
.page-button-width{
    margin-right: 2vw;
}
.resuply-check-container{
    display: flex;
    gap: 1vw;
    cursor: pointer;
    align-items: center;
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
}
.resuply-check-icon{
    font-size: 2em;
    color: var(--primary-color);
}
.page-table-line-special{ background: #F7F7F7; }
@media only screen and (max-width: 800px) {
    .mobile{display: flex;}

  .page-buttons-containear{
      flex-wrap: wrap;
  }
  .mobile-button-size{
    width: 55vw;
    margin-top: 2vh;
  }
  .page{
      padding: 0 1vw 3vh 0;
      font-size: 2.5vw;
  }
  .table-header{display: none;}
  .table-row{
    margin-top: 3vh;
    flex-direction: column;
    gap: 2vh;
    font-size: 1.5em;
  }
  .table-colum-align{
    width: 100%;
    flex: unset;
    display: flex;
    justify-content: space-between;
    margin-left: 0;
  }
  .table-mobile-header{
    display: flex;
    font-weight: 700;
    font-size: 1.1em;
    color: #605F5F;
    margin-bottom: 1vh;
  }
    .table-row-separator{ border-right: none; }
    .mobile-check-container{
        width: 100%;
        display: flex;
        gap: 2vw;
    }
    .mobile-table-row{
        display: flex;
        justify-content: space-between;
    }
    .mobile-table-left{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .mobile-table-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .actions-width{
        justify-content: space-evenly;
    }
    .desktop{display: none;}
}
</style>
