<template>
    <div id="print-request-report" class="container-fluid p-3 matrix-wrapper">

        <table class="table shadow" v-for="provider in providers" :key="provider.provider_id">
            <thead>
                <tr>
                    <th colspan="2" class="provider-name text-left">
                        <p><span>{{ provider.name }}</span> - {{ provider.seller_name }}</p>

                        <span>{{ provider.seller_phone || "" | VMask('(##) #####-####') }}</span>
                    </th>
                    <th class="text-left provider-name val">
                        <span class="total-prov">Fat. Min: <span style="color: #20CE88;font-size: 0.9rem;">{{ provider.minimum_billing | currency }}</span></span><br>
                    </th>
                    <th class="text-left provider-name val">
                        <span class="total-prov">Total: <span style="color: #20CE88;font-size: 0.9rem;">{{ total_provider(provider) | currency }}</span></span>
                    </th>
                    <th class="text-left">Qtd. Ult. compra</th>
                    <th class="text-left not-print">Última Compra</th>
                    <th class="text-left print-only">Últ. Cmp.</th>
                    <th class="text-left not-print">Menor Preço</th>
                    <th class="text-left print-only">Mnr. Prç.</th>
                    <th class="text-left not-print">2 Menor Preço</th>
                    <th class="text-left print-only">2 Mnr. Prç.</th>
                    <th class="text-left not-print">3 Menor Preço</th>
                    <th class="text-left print-only">3 Mnr. Prç.</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="product in provider.products">
                    <tr :key="'name-' + product.id + '-prov-' + provider.provider_id">
                        <td colspan="4" class="text-left border-bottom-0 pl-0 pro-name-cell">
                            <div class="col">
                                <p class="mb-0 pro-name" style="position:relative">
                                    {{ product.name }}
                                    <span class="brand">{{ product.brand }}</span>
                                    <i v-if="product.observations" class="fas fa-info-circle" :title="product.observations.toUpperCase()"></i>
                                </p>
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ product.qtd_last_purchase }}
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ product.last_purchase | currency }}
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                <span :class="{ lower: product.offer_price < product.last_purchase, higher: product.offer_price > product.last_purchase, 'total-val': true }">
                                    {{ offer_at(product, 1).offer_price | currency }}
                                </span>
                                <span class="prov-name" v-show="offer_at(product, 1).provider_name">
                                    ({{ trim(offer_at(product, 1).provider_name) }})
                                </span>
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ offer_at(product, 2).offer_price | currency }}
                                <span class="prov-name" v-show="offer_at(product, 2).provider_name">
                                    ({{ trim(offer_at(product, 2).provider_name) }})
                                </span>
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ offer_at(product, 3).offer_price | currency }}
                                <span class="prov-name" v-show="offer_at(product, 3).provider_name">
                                    ({{ trim(offer_at(product, 3).provider_name) }}}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr :key="product.pro_id">
                        <td class="text-center border-top-0 pt-0 pro-data" colspan="4">
                            <div class="col d-flex p-0">
                                <p class="mb-0 info">
                                    <span class="description">Qtd:</span>
                                    <span class="text-center">{{ quantity_sum(product.stores) }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Emb:</span>
                                    <span class="text-center">
                                        {{ product.purchase_unity ? product.purchase_unity : `${product.packing.split(" ")[0]} ${product.packing.split(" ")[1]}` }}
                                    </span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Total:</span>
                                    <span class="text-center total-val">{{ total(product.stores) | currency }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Estoque:</span>
                                    <span class="text-center total-val">{{ product.inventory }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
import V3RequestsService from "@/services/v3/request.service"

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin ],
    props: [ "data" ],
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            v3_svc: new V3RequestsService(),
            products: null,
            providers: [],
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false
        }
    },
    methods: {
        offer_at(product, position) {
            position = position - 1
            return product.offers && product.offers[position] ? product.offers[position] : {}
        },
        filter_product(product, store_id) {
            let result = product.stores.find(s => s.store_id == store_id)
            return result || {}
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.requested_quantity || 0) }, 0)
        },
        total_provider(provider) {
            let result = 0;
            provider.products.forEach(p => {
                result += this.total(p.stores)
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => {
                return sum + (
                    (store.requested_quantity || 0)*
                    (store.is_generic == 0 && store.main_product_id == null && this.user.request_type != 1 ?
                        parseInt(store.packing.split(' ')[1])*store.offer_price : store.offer_price)
                    )
            }, 0)
        },
        process_available_providers() {
            this.products.forEach(pp => {
                let prov = this.providers.find(prov => prov.provider_id == pp.provider_id && pp.seller_id == prov.seller_id)
                if(prov) {
                    let pro = prov.products.find(pr => pr.id == pp.id)
                    if(pro) {
                        pro.stores.push(pp)
                    } else {
                        prov.products.push({ ...pp, stores: [ pp ] })
                    }
                } else {
                    this.providers.push({
                        provider_id: pp.provider_id,
                        seller_id: pp.seller_id,
                        seller_name: pp.seller_name,
                        seller_phone: pp.seller_phone,
                        minimum_billing: pp.minimum_billing,
                        name: pp.provider_name, products: [ { ...pp, stores: [ pp ] } ]
                    })
                }
            })
        },
        process_available_stores() {
            this.stores.forEach(store => {
                this.products.forEach(p => {
                    p.stores = []
                    if(store.id == p.store_id) {
                        if(!store.products) {
                            store.products = []
                        }
                        if(store.id == p.store_id) {
                            store.products.push(p)
                        }
                    }
                })
            })
        },
        sort_products() {
            this.providers.forEach(p => {
                p.products.sort((a, b) => {
                    if (a.name > b.name) return 1
                    if (b.name > a.name) return -1
                    return 0
                })
            })
        },
        sort_providers() {
            this.providers.sort((a, b) => {
                if (a.name > b.name) return 1
                if (b.name > a.name) return -1
                return 0
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
				if (a.position > b.position) return 1
				if (b.position > a.position) return -1
				return 0
			})
        },
        trim(e){
            let result = `${String(e).substring(0,13)}`;
            return e && String(e).length > 13 ? (result + "..") : result
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        load_offers() {
            return this.v3_svc.full_general_request_report(this.$route.params.id)
            .then(response => response.data).then(data => {
                data.forEach(off => {
                    this.providers.forEach(prov => {
                        prov.products.forEach(prod => {
                            if(prod.id == off.id) {
                                if(prod.offers) {
                                    if(!prod.offers.some(p => {
                                        return p.id == off.id && p.seller_id == off.seller_id
                                    })) {
                                        prod.offers.push(off)
                                    }
                                } else {
                                    prod.offers = [ off ]
                                }
                            }
                        })
                    })
                })
                this.providers.forEach(prov => {
                    prov.products.forEach(prod => {
                        prod.offers.sort((a, b) => {
                            if (a.offer_price > b.offer_price) return 1
                            if (b.offer_price > a.offer_price) return -1
                            return 0
                        })
                    })
                })
                this.$forceUpdate()
            }).then(() => {
                Vue.nextTick(() => {
                    window.document.title = `cotacao-${this.$route.params.id}.pdf`
                    setTimeout(function() {
                        window.print()
                        window.document.title = 'Club da Cotação'
                    }, 500)
                })
            })
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
        this.products = this.data
        this.sort_stores()
        this.process_available_stores()
        this.process_available_providers()
        this.sort_products()
        this.load_offers()
        this.sort_providers()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    $fixed_width: 550px;

    .total-provider {
        color: $ternary-color;
    }
    .print-only{display: none;}

    .table {
        margin: 30px 0;
        &:first-child {
            margin-top: 0 !important;
        }
        th {
            padding: 4px !important;
            font-size: 0.8rem;
        }
        td {
            width: 176px;
            font-size: 14px;
            padding: 0.35rem !important;
        }
    }
    .provider-name {
        text-align: center;
        p{margin-bottom: 0;}
        &.val {
            min-width: 10rem;
        }
        .total-prov {
            white-space: nowrap;
        }
        &:first-child {
            width: 20%;
        }
        span {
            font-weight: bolder;
            color: var(--primary-color);
        }
    }

    .pro-name-cell {
        .col {
            width: $fixed_width;
            min-width: $fixed_width;
            max-width: $fixed_width;
        }
    }

    .pro-data div {
        min-width: $fixed_width;
        max-width: $fixed_width;
        width: $fixed_width;
    }

    .pro-name {
        font-weight: bold;
        font-size: 0.8rem;
        .brand {
            color: var(--primary-color);
        }
    }
    .info {
        width: 33%;
        margin-left: 10px;
    }

    th.store-quantity {
        line-break: anywhere;
        white-space: revert;
        cursor: pointer;
        &:hover {
            background-color: $secondary-color;
            span, i {
                color: white !important;
            }
        }
    }
    th.store-quantity {
        min-width: 130px;
    }
    input.store-quantity {
        width: 100%;
    }

    input.store-quantity-reduce {
        width: 60%;
    }

    .total {
        border-right: 1px dashed #ece9e9;
    }

    .total-val {
        // color: white;
        padding: 0.2rem ​0.4rem ​0.1rem;
        margin-right: 2px;
        white-space: nowrap;
    }

    span.description {
        // width: fit-content;
        font-size: 0.8rem;
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: 4px;
        margin-left: 2px;
        display: inline-block;
        text-align: center;
        color: $gray2;
    }

    tr:hover {
        td {
            background-color: white;
        }
    }

    .quantity-loader {
        position: absolute;
        width: 25%;
        top: -47px;
        left: 83%;
        height: fit-content !important;
    }

    @media screen {
        .lower {
            background-color: $higher-green;
            border-radius: 6px;
            color: white !important;
            font-weight: 600;
            opacity: 0.6;
            padding: 0.2rem;
        }
    }

    @media print {
        .lower {
            color: $higher-green !important;
        }
    }

    @media print {
        .higher {
            color: $lower-red !important;
        }
    }

    @media screen {
        .higher {
            color: white !important;
            border-radius: 6px;
            background-color: $lower-red;
            font-weight: 600;
            opacity: 0.6;
            padding: 0.2rem;
        }
    }

    .fa-store-alt {
        margin-right: 4px;
    }

    .fa-info-circle {
        color: $secondary-color;
        cursor: pointer;
    }

    .cursor {
        cursor: pointer;
    }

    .description.store-total {
        text-align: center;
        color: $ternary-color;
    }

    .obs {
        color: $secondary-color;
    }

    i.fa-handshake-o {
        width: 10px;
        padding-left: 4px;
        color: $orange;
        cursor: pointer;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .matrix-wrapper {
        overflow-x: scroll;
    }

    .pro-col {
        min-width: 600px;
    }

    .column-quantity {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        @media screen {
            span:last-child {
                margin-right: 4px;
                color: var(--primary-color);
            }
        }
        .prov-name {
            margin-left: 2px !important;
        }
    }
    .column-stock{
        width: 45px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }
    @media print {
        .shadow{
            box-shadow: none !important;
            border-bottom: 3px solid var(--primary-color);
            border-left: 1px solid lightgray;
            border-right: 1px solid lightgray;
            margin-bottom: 10vh;
        }
        .print-only{display: table-cell;}
        .provider-name{
            p{background-color: lightgoldenrodyellow !important;}
        }
    }
</style>
