<style lang="scss" scoped>
	@import "./modal.component";
</style>
<template>
	<transition name="modal">
		<div class="modal-mask add-product-modal">
			<div class="modal-wrapper">
				<div class="modal-container" :style="modal.style">
					<div class="modal-header">
						<slot name="header">
							<div class="container-fluid">
								<div class="row">
									<div class="col">
										<h5>
											<i v-if="modal.icon_title" class="header-icon" :class="modal.icon_title"></i>
											{{ modal.title }}
										</h5>
										<p v-if="modal.subtitle" class="subtitle">{{ modal.subtitle }}</p>
									</div>
									<slot name="header-actions" />
									 
										
								</div>
							</div>
						</slot>
						<cc-button-back v-if="!modal.nobackbutton"  type="button" @click="close" :content="modal.cancel_text || 'Voltar'"/>
						<button v-if="modal.submit" class="btnFeedbackModal" :title="modal.unpermitted_message" :class="{ disabled : !modal.permitted }" type="button" @click="modal.submit" :disabled="!modal.permitted">{{ modal.submit_text || 'Salvar Alterações' }}</button>
					</div>
					<div class="modal-body">
						<slot name="body"></slot>
					</div>
					<div class="modal-footer not-print">
						<slot name="footer">
							
							
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
	export default {
		props: ["modal", "event"],
		methods: {
			close() {
				this.$emit('close');
			}
		}
	};
</script>
