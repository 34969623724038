<template>
    <div id="print-order" style="width:680px;max-width: 680px;">
        <div class="request-info" v-for="req in order_data.requests" v-bind:key="req.request.id_pedido" :style="pageBreak()">

            <table align="center" width="820" CELLSPACING="0" cellpadding="5" style="width: 100%;" >
                <tr>
                    <td ><img style="float:left;margin-right: 100px;width:10rem;" :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')" /></td>
                    <td style="margin: 0;text-align: center;" >
                        <h4 v-if="req.order">Pedido de número.: <strong>#{{ req.order.id_pedido }}</strong></h4>
                    </td>
                </tr>
            </table>
            <table align="center" width="820" CELLSPACING="0" cellpadding="5" style="width: 100%;" >
                <tr style="text-align: center; border-bottom: 1px solid black" >
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal; ">
                            <strong>Empresa Compradora: </strong>{{ req.store.loj_razao }}
                        </h5>
                    </td>
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal;">
                            <strong>Nome Fantasia: </strong>{{ req.store.loj_descricao }}
                        </h5>
                    </td>
                </tr>
                <tr style="text-align: center; margin-top: -20px;border-bottom: 1px solid black" >
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal;">
                            <strong>CNPJ:</strong>{{ req.store.loj_cnpj || "" | VMask('## ### ###/####-##') }}
                        </h5>
                    </td>
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal;">
                            <strong>Telefone:</strong>{{ req.store.loj_telefone }}
                        </h5>
                    </td>
                </tr>
                <tr style="text-align: center; margin-top: -20px">
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal;">
                            <strong>Endereço:</strong>{{ req.store.loj_endereco }}
                        </h5>
                    </td>
                    <td style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;;font-weight: normal;">
                            <strong>Email:</strong>{{ req.store.loj_email }}
                        </h5>
                    </td>
                </tr>
                <tr style="text-align: center; margin-top: -20px;border-bottom: 1px solid black" >
                    <td colspan="2" style="border-bottom: 1px solid black">
                        <h5 style="text-align: left;margin: 0;font-size: 12px;text-align: left;font-weight: normal;">
                            <strong>Observação Empresa:</strong>{{ req.store.loj_observacao }}
                        </h5>
                    </td>
                </tr>
            </table>

            <template v-for="seller in req.sellers">
                <div style="width: 100%;margin: 5px auto;display: flex;justify-content: space-between;" v-bind:key="'header-' + seller.id_pedido">
                    <div style="-webkit-print-color-adjust: exact;background-color:green !important;float:left;font-size: 8px;padding: 3px 8px;;border-radius: 5px;font-weight: bold;color: white;"
                        :style="{ background: req.order ? 'green !important' : 'var(--primary-color) !important' }">
                        {{  req.order ? ('Pedido Gerado - nº' + seller.id_pedido) : 'Sem Pedido' }}
                        {{ '--' + formatDate(req.order.data_geracao) }}
                    </div>
                    <div style="font-size: 12px;font-weight: bold;">
                        <span>Fornecedor: {{ seller.for_nome }} - CNPJ: {{ seller.for_cnpj|| "" | VMask('## ### ###/####-##')  }}</span>
                    </div>
                    <div style="padding-right: 40px;text-align:right; font-size: 12px;font-weight: bold;">
                        Valor Total: {{ req.order.valor_pedido | currency }}
                    </div>
                </div>
                <table align="center" CELLSPACING="0" cellpadding="20" style="width: 100%;"  v-bind:key="'summary-' + seller.id_pedido">
                    <tr>
                        <td style="padding: 1mm; font-size: 10px; width: 150px;">Data de Entrega: {{ seller.entrega ? seller.entrega.data_entrega : '' | formatDateOnly  }}</td>
                        <td style=" padding: 1mm;font-size: 10px;width: 200px;">Cond. de Pagamento: {{ seller.entrega ? seller.entrega.forma : '-' }}</td>
                        <td style="padding: 1mm;font-size: 10px">Observação: {{ seller.entrega ? seller.entrega.observacao : '-' }}</td>
                        <td v-if="req.order" style="padding: 1mm;font-size: 10px">Observação do cliente: {{ req.order ? req.order.observacao : '-' }}</td>
                        <td v-if="req.detail" style="padding: 1mm;font-size: 10px">Observação do cliente: {{ req_detail.obs }}</td>
                    </tr>
                </table>
                <table align="center" CELLSPACING="0" cellpadding="20" style="-webkit-print-color-adjust: exact;width: 100%;background-color: lightyellow;border: solid 1px #DDDDDD;"  v-bind:key="'phone-' + seller.id_pedido">
                    <tr>
                        <td style="padding: 1mm; font-size: 10px; ">
                            Vendedor: {{ seller.usu_nome }}  |
                            Email: {{ seller.usu_email }}  |
                            Telefone: {{ seller.usu_telefone }}
                        </td>
                    </tr>
                </table>
                <table align="center" CELLSPACING="0" cellpadding="20" style="width: 100%;" v-bind:key="'prod-' + seller.id_pedido">
                    <thead>
                        <tr>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm; font-size: 8px; width: 85px">Código</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm; font-size: 8px; width: 220px">Descrição</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px">Marca</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width: 60px;text-align:center">Qnt na Emb</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:35px;text-align:center">Qnt</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:75px">Unid. Compra</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:60px">Digitação</th>
                            <th style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:70px">Total</th>
                        </tr>
                    </thead>
                    <tr v-for="prod in seller.produtos" v-bind:key="'prod-' + prod.pro_ean + '-' + seller.idPedido">
                        <td style="border: solid 1px #DDDDDD; padding: 1mm; font-size: 8px; width: 85px">{{ prod.ean }}</td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm; font-size: 8px; width: 270px">
                            {{ prod.prin_produto ? prod.prin_produto : prod.produto }}<br />
                            <span style="-webkit-print-color-adjust: exact;background: yellow;">{{ prod.observacao }}</span>
                        </td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px">{{ prod.pro_observacao }}</td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width: 60px;text-align:center">
                            {{ !prod.pro_principal ? prod.embalagem : prod.quantidade_digitacao }}
                        </td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width: 35px;text-align:center">{{ prod.quantidade }}</td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:75px">{{ prod.prin_pro_unidade_compra ? prod.prin_pro_unidade_compra : prod.pro_unidade_compra }}</td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:60px">{{ (prod.pro_generico == 0 && prod.pro_principal != null ? prod.valorUnitario : prod.valorUnitario) | currency }}</td>
                        <td style="border: solid 1px #DDDDDD; padding: 1mm;font-size: 8px; width:70px">{{ prod.valorTotal | currency }}</td>
                    </tr>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["order_data", 'printColapsed'],
    data() {
        return {
        }
    },
    methods:{
        pageBreak(){
            if(!this.printColapsed) {
                return 'page-break-after: always;'
            }
        },
        formatDate(date) { return '  '+date.split(' ')[0].split('-')[2] + '-' +
                                    date.split(' ')[0].split('-')[1] + '-' +
                                    date.split(' ')[0].split('-')[0] + ' ' +
                                    date.split(' ')[1].split(':')[0] + ':' +
                                    date.split(' ')[1].split(':')[1] }
    },
    computed: {
        ...mapState(["whitelabel"])
    },
}
</script>

<style>

</style>
