<style lang="scss" scoped>
    @import "./_summary.component";
</style>
<template>
    <div class="row summary" v-if="store">
        <div class="col-4">
            <div class="info-block">
                <span class="tag">comprador</span>
                <span style="color:black !important" class="header">{{ store.name.toUpperCase() }}</span>
                <span>{{ store.street }}, {{ store.district }} - {{ store.city.name }}/{{ store.state.name }}</span>
                <span v-if="store.phone">Fone: {{ store.phone }}</span>
                <span>CNPJ: {{ store.cnpj | cnpj }}</span>
            </div>
        </div>
        <div class="col">
            <div class="info-block">
                <span class="tag">fornecedor</span>
                <span class="header">
                    <span class="provider">{{ seller.provider.name }}</span>
                    <!-- <span class="rate shadow" @click="show_reviews = true">
                        <i class="fa fa-star" aria-hidden="true"></i> ({{ seller.rate }})
                    </span> -->
                </span>
                <span>{{ seller.name }}</span>
                <span>{{ seller.email }}</span>
                <span v-if="seller.phone">Fone: <cc-whatsapp :phone="seller.phone" /></span>
            </div>
        </div>
        <div class="col" v-if="seller.delivery">
            <div class="info-block">
                <span class="tag">detalhes do pedido</span>
                <span class="header"></span>
                <span>Entrega: {{ seller.delivery.date | formatDateOnly }} </span>
                <span v-if="seller.delivery.tcp">Tipo Entrega: {{ seller.delivery.tcp }} </span>
                <span>Faturamento: {{ seller.delivery.minimum_billing | currency }}</span>
                <span>Cond. de Pgto: {{ seller.form }}</span>
                <span>Status:
                    <span v-if="!order" class="status pending">
                        pendente
                    </span>
                    <span v-else class="status ordered">
                        gerado
                    </span>
                </span>
            </div>
        </div>
        <div class="col" v-if="obs">
            <div class="info-block">
                <span class="tag">observações</span>
                <div class="text-box">{{ obs.obs }}</div>
            </div>
        </div>
        <div class="col" v-if="obs">
            <div class="info-block">
                <span class="tag controls">
                    Informar observação do pedido
                    <i class="fa fa-edit" @click="toggle_editing" v-if="!obs.editing && !order"></i>
                    <i class="fa fa-save" @click="save_obs" v-if="obs.editing"></i>
                    <i class="far fa-times-circle" @click="obs.editing = false" v-if="obs.editing"></i>
                </span>
                <div class="text-box">
                    <textarea :disabled="!obs.editing" class="form-input" cols="40" rows="6" v-model="obs.obs"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RequestService from "@/services/v3/request.service"
export default {
    props: {
        store: Object,
        seller: Object,
        order: Object,
        request: Object
    },
    data() {
        return {
            preorders: null,
            obs: null,
            sellers: [],
            svc: new RequestService()
        };
    },
    methods: {
        toggle_editing() {
            this.$set(this.obs, 'editing', !this.obs.editing)
        },
        load_obs() {
            this.obs = {
                obs: '',
                store_id: this.store.id,
                request_id: this.request.legacy_id,
                seller_id: this.seller.id
            }
            return this.svc.load_obs(this.request.legacy_id, this.store.id)
                .then(response => response.data)
                .then(data => this.obs = data)
        },
        save_obs() {
            return this.svc.save_obs(this.obs).then(response => response.data)
            .then(data => {
                this.obs.editing = false
                this.$swal.fire(
                    "Salvo!",
                    "Suas observações foram salvas com sucesso!",
                    "success"
                )
            }, err => {
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao salvar observações.",
                    "error"
                )
            })
        },
    },
    mounted() {
        this.load_obs()
    }
};
</script>
