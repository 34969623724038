<template>
    <div class="add-provider-alert-container" v-on:click="close">
        <div class="alert-container">
            <span class="material-icons-outlined add-provider-alert-icon">check_circle</span>
            <h1 class="add-provider-alert-title">{{ text }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name:'AddProviderAlert',
    props:['close', 'text']
}
</script>

<style scoped>
.add-provider-alert-container{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 6;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.alert-container{
    height: 30vh;
    width: 50vw;
    border-radius: 12px;
    background: #30AA4C;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
}
.add-provider-alert-icon{
    color: white;
    font-size: 10em;
   
}
.add-provider-alert-title{
    color: white;
    font-size: 3em;
    text-align: center;
    width: 50%;
}
@media only screen and (max-width: 850px){
    .alert-container{
        width: 85%;
    }
}
</style>