<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .category-title {
        color: $primary-color;
        font-size: 1.4rem;
    }

    .title {
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.7rem;
        padding: 1rem;
        i {
            margin-right: 10px;
            color: $primary-color;
        }
        p {
            margin: 0;
            color: $gray4;
        }
    }

    .header-title {
        color: $primary-color !important;
        text-transform: uppercase;
        font-weight: bold;
        i {
            font-size: 1.6rem;
            margin-right: 0.5rem;
        }
    }

    /deep/ {

        .card-body {
            padding-top: 0px;
        }
        .card-body .card-body {
            padding-top: 0px;
            border-top: 1px solid #d5d5d5;
            padding-top: 8px;
        }
        .card-img {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
        .card-text b {
            color: black;
        }

        .nav-prev {
            position: relative;
            left: 25px;
        }
        .nav-next {
            position: relative;
            right: 25px;
        }
        .nav-next, .nav-prev {
            color: white;
            background-color: $primary-color;
            padding: 5px;
            display: block;
            width: 35px;
            height: 35px;
            text-align: center;
            border: 1px solid $primary-color;
            &:hover {
                background-color: white;
                color: $primary-color;
            }
        }
        .VueCarousel-navigation-button:focus {
            outline: 0 !important;
        }
    }

    .filter-containear{
        padding: 0 35px 0 0;
    }
    .banner{
        width: 100%;
    }
    .item-suplier-icon{
        width: 1.2vw;
        font-size: 1.2vw;
        color: #6B7588;
    }
    .filter-title{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #3A3A3C;
        margin-top: 20px
    }
    .filter-subtitle{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #6B7588;
        margin-top: 8px
    }
    .banner-skeleton{
        height: 300px;
        background-color: lightgray;
        border-radius: 10px;
    }
    .products-skeletons{
        margin-left: 30px;
        margin-top: 140px;
    }
    .card-skeleton{
        background: lightgray;
        height: 500px;
        width: 75%;
        border-radius: 10px;
        margin-bottom: 30px;
    }
    .filter-head{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }
    .filter-text{
        color: #6B7588;
        font-size: 18px;
    }
    .see-orders-text{
        color: var(--primary-color);
        cursor: pointer;
    }
    .filter-tags{
        display: flex;
        margin-top: 8px;
        @media only screen and (max-width: 570px){
            justify-content: space-between;

        }

    }
    .active-tag{
        background-color: var(--primary-color) !important;
        color: white !important;
        cursor: default !important;
    }
    .product-tag{
        background: #FAFAFC;
        border-radius: 8px;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .suplier-tag{
        background: #FAFAFC;
        border-radius: 8px;
        margin-left: 25px;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .filter-inputs-containear{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: flex-end;
    }
    .filter-body{
        margin-right: 80px;
    }
    .search-input-containear{
        border: 1px solid #E5E5E5;
        display: flex;
        padding: 2px 4px;
        border-radius: 7px;
        width: 30%;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .select-input-text{
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.15px;
        color: #6e6e6e;
    }
    .search-input{
        border: none;
        width: 100%;
        font-size: 17px;
    }
    .search-input:focus{
        outline: none;
    }
    .select-input-containear{
        border: 1px solid #E5E5E5;
        display: flex;
        padding: 2px 4px;
        border-radius: 7px;
        height: 50px;
    }
    .select-input{
        border: none;
        width: 100%;
        cursor: pointer;
        color: grey;
        padding: 0 5px;
    }
    .select-input:focus{
        outline: none;
    }
    .state-input-size{
        width: 30%;
    }
    .category-input-size{
        width: 30%;
    }
    .filter-end-line{
        border-bottom: 1px solid #E5E5E5;
        margin-top: 30px;
    }
    .catalog-containear{
        margin-top: 60px;
        background-color: #8989890d;
        border-radius: 10px;
        margin-right: 35px;
        padding-top: 2vh;
    }
    .category-title{
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
        color: var(--primary-color);
        background-color: white;
        border-radius: 10px;
        padding: 1vh 2vw;
        width: fit-content;
    }
    .category-title-bar{
        background: var(--primary-color);
        border-radius: 10px;
        width: 119px;
        height: 13px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    select{
        background: white;
    }
    .products-containear{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 100px;
    }
    .product-containear{
        width: 90%;
        margin-bottom: 40px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 6px;
        background-color: white;
    }
    .product-image{
        border-radius: 10px;
        max-width: 100%;
        height: 170px;
    }
    .image-alignt{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .product-name{
        font-weight: 700;
        font-size: 15px;
        margin-top: 5px;
        height: 50px;
        color: #605F5F;
        text-transform: uppercase;
    }
    .item-suplier-containear{
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .product-info{
        margin: 10px
    }
    .suplier-text{
        flex: 1;
        color: #6B7588;
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
    }
    .suplier-containear{
        margin-left: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 8px;
        width: 75%;
    }
    .suplier-align{
        min-height: 300px;
    }
    .suplier-name{
        color: #6B7588;
        font-weight: 400;
        font-size: 14px;
        border-bottom: 1px solid black;
        cursor: pointer;
    }
    .payment-name{
        color: #6B7588;
        font-weight: 400;
        font-size: 14px;
        border-bottom: 1px solid black;
    }
    .suplier-name:hover {
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
    }
    .product-price{
        color: var(--primary-color);
        font-weight: 700;
        font-size: 20px;
        margin-top: 10px;
        padding-left: 0.8vw;
    }
    .product-price-unity{
        background-color: var(--primary-color);
        border-radius: 10px;
        color: white;
        font-weight: 700;
        font-size: 20px;
        padding: 0 1vw;
        width: fit-content;
    }
    .product-package-containear{
        display: flex;
    }
    .product-package-text{
        color: #6B7588;
        font-weight: 300;
        font-size: 14px;
        margin-left: 5px;
    }
    .product-buttons-containear{
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        height: 64px;
    }
    .product-buttons-buy{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px 20px;
        background-color: var(--primary-color);
        border-radius: 10px;
        font-size: 20px;
        color: white;
        cursor: pointer;
        &:hover{
            color: var(--primary-color);
            outline: 1px solid var(--primary-color);
            background-color: white;
        }
    }
    .product-button-subtrack{
        border: 1px solid #F2F2F2;
        border-radius: 5px 0 0 5px;
        width: 30%;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-button-value{
        border: none;
        border-top: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;
        width: 15%;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:focus{
            border: 1px solid var(--primary-color);
            outline: none;
        }
    }
    .product-button-add{
        border: 1px solid #F2F2F2;
        border-radius: 0 5px 5px 0;
        width: 30%;
        background: var(--primary-color);
        font-size: 20px;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product-obs-containear{
        margin-top: 15px;
        height: 40px;
    }
    .product-obs-content{
        display: flex;
        align-items: center;
    }
    .product-obs-text{
        font-weight: 400;
        font-size: 15px;
        color: #EF443B;
    }
    .supliers-containear{
        margin-top: 60px;
        margin-left: 30px;
    }
    .supliers-title{
        font-weight: 500;
        font-size: 32px;
        color: #6e6e6e;
        margin-bottom: 15px;
    }
    .suplier-image{
        border-radius: 5px 5px 0 0;
        height: 170px;
        width: 100%;
    }
    .suplier-body-margin{
        margin-left: 10px;
    }
    .suplier-info-name{
        color: #3A3A3C;
        font-weight: 600;
        font-size: 20px;
        margin-top: 15px;
    }
    .suplier-text-title{
        color: #8F90A6;
        font-weight: 500;
        font-size: 14px;
        margin-top: 8px;
    }
    .suplier-text{
        color: #8F90A6;
        font-weight: 300;
        font-size: 14px;
        margin-top: 5px;
    }
    .mobile{
        display: none;
    }
    .suplier-button{
        background-color: var(--primary-color);
        text-align: center;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        color: white;
        margin: 15px 10px 10px 10px;
    }
    .product-align{
        min-height: 350px;
    }
    .category-containear{
        margin-left: 25px;
    }
    .carousel-banner-img{
        height:40vh;
        width: 100% ;
    }
    @media only screen and (max-width: 1600px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
    @media only screen and (max-width: 1400px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    @media only screen and (max-width: 1100px){
        .product-containear{
            width: 85%;
        }
        .product-name{
            font-size: 19px
        }
    }
    @media only screen and (max-width: 880px){
        .products-containear{
            grid-template-columns: 1fr 1fr 1fr;
        }
        .product-obs-containear{
            height: 50px;
        }
        .carousel-banner-img{height: 15vh;}
        .filter-body{
            margin-right: 0;
        }
        .filter-containear{padding: 0 5vw;}
        .product-obs-containear{
            height: 50px;
        }
        .products-containear{
            grid-template-columns: 1fr 1fr;
        }
        .mobile{
            display: flex;
        }
        .product-price-unity{
            font-size: 15px;
            padding: 0 1.5vw;
        }
        .product-price{
            font-size: 15px;
        }
        .product-buttons-buy{
            font-size: 15px;
        }
        .product-buttons-containear{
            margin-top: 0;
            padding: 10px;
        }
        .filter-inputs-containear{
            flex-direction: column;
            align-items: baseline;
        }
        .search-input-containear{
            width: 100%;
            margin-bottom: 10px;
        }
        .state-input-size{
            width: 100%;
            margin-bottom: 10px;
        }
        .category-input-size{
            width: 100%;
        }
        .filter-end-line{
            margin-top: 85px;
        }
        .product-name{
            height: 95px;
            font-size: 17px;
        }
        .desktop{
            display: none;
        }
        .page-margin{ margin-left: 0;}
        .product-containear{ width: 95%;}
        .category-containear{ margin-left: 15px;}
        .item-suplier-containear{flex-wrap: wrap;}
    }
</style>

<template>
    <div class="body page-margin">
        <!-- <div class="row m-2 mt-5">
            <div class="col col-12 pb-4 px-1">
                <carousel
                    :autoplay="true"
                    :perPage="1"
                    :loop="true"
                    :autoplayTimeout="8000"
                    :navigationEnabled="true"
                    :navigation-prev-label="prevLabel"
                    :navigation-next-label="nextLabel"
                    :navigationClickTargetSize="3"
                    :paginationEnabled="true" style="width:99%;">
                    <slide v-for="(banner, i) in banners" :key="i">
                        <img :src="banner" style="height:300px;width: 100% !important;"  />
                    </slide>
                </carousel>
            </div>
            <div class="col col-11" style="margin: auto; border: 1px solid green">
                <div class="row">
                    <div class="col col-4 pb-3">
                        <h4 class="header-title"><i class="fa fa-shopping-bag" aria-hidden="true"></i>Produtos em oferta</h4>
                        <p class="header-subtitle">Confira as ofertas que só o Club tem</p>
                    </div>
                    <div class="col col-2">
                        <cc-button
                            :classes="'fixed fill'"
                            :content="'Histórico de Pedidos'"
                            icon="fas fa-history"
                            link="/ofertas/pedidos" />
                    </div>
                    <div class="col col-2">
                        <cc-select-v2
                            :placeholder="'Selecione um estado'"
                            :tracker="'est_id'"
                            :text="'est_nome'"
                            v-model="filters.state"
                            @select="load_items"
                            :searchable="true"
                            :options="states" />
                    </div>
                    <div class="col col-2">
                        <cc-select-v2
                            :placeholder="'Categoria'"
                            :tracker="'id'"
                            :text="'name'"
                            v-model="filters.category"
                            @select="filter_category"
                            :searchable="true"
                            :options="categories" />
                    </div>
                    <div class="col col-2">
                        <cc-input
                            :placeholder="'Produto'"
                            v-model="filters.product"
                            @input="filter" />
                    </div>
                </div>
            </div>
            <div class="col col-12" v-if="!loading && (!items || items.length == 0)">
                <no-data-available text="Sem Ofertas Dispoíveis no momento" />
            </div>
            <div class="col col-11" style="margin: auto;">
                <div class="row">
                    <div class="col col-12">
                        <div class="row" v-for="cat in visible_categories()" :key="cat.id">
                            <div class="col-12 mt-4 border-bottom category-title">
                                {{ cat.name }}
                            </div>
                            <div class="col col-3 pt-5" v-for="item in cat.items" :key="cat.id + '-' + item.id" v-show="item.visible">
                                <cc-campaign-item
                                    :item="item"
                                    @open_campaign="open_campaign"
                                    :cart_controls="true"
                                    :loading="loading"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="filter-containear">
            <cc-loader-full v-if="loading"/>
            <div v-if="loading">
                <div class="banner-skeleton"></div>
            </div>
            <div v-else>
                <carousel
                    :autoplay="true"
                    :perPage="1"
                    :loop="true"
                    :autoplayTimeout="8000"
                    :navigationEnabled="true"
                    :navigation-prev-label="prevLabel"
                    :navigation-next-label="nextLabel"
                    :navigationClickTargetSize="3"
                    :paginationEnabled="true" style="width:99%;">
                    <slide v-for="(banner, i) in banners" :key="i">
                        <img :src="banner" class="carousel-banner-img"   />
                    </slide>
                </carousel>
            </div>
            <div class="filter-title">
                Produtos em Oferta
            </div>
            <div class="filter-subtitle">
                Confira as ofertas que só o Club tem
            </div>
            <div class="filter-body">
                <div class="filter-head">
                    <div class="filter-text">
                        Filtrar por
                    </div>
                    <StandardButton :action="() => { $router.push('/ofertas/pedidos/') }" class="desktop" :iconName="'history'" :text="'Ver historico de pedidos'"/>
                </div>
                <div class="filter-tags">
                    <div class="product-tag" :class="isActiveTag('product')" v-on:click="pageFilter='product'">
                        Produto
                    </div>
                    <div class="suplier-tag" :class="isActiveTag('suplier')" v-on:click="pageFilter='suplier'">
                        Fornecedor
                    </div>
                    <StandardButton style="margin-left:10px;font-size:12px;" :action="() => { $router.push('/ofertas/pedidos/') }" class="mobile" :iconName="'history'" :text="'Histórico de pedidos'"/>
                </div>
                <div class="filter-inputs-containear">

                    <div class="search-input-containear" v-if="pageFilter=='product'">
                        <input v-model="searchTextProduct" type="text" class="search-input" placeholder="Digite aqui o nome do produto">
                        <img src="@/assets/search.png" alt="busca">
                    </div>
                    <div class="search-input-containear" v-else>
                        <input v-model="searchTextSuplier" type="text" class="search-input" placeholder="Digite aqui o nome do fornecedor">
                        <img src="@/assets/search.png" alt="busca">
                    </div>

                    <div class="state-input-size">
                        <div class="select-input-text">
                            Estado
                        </div>
                        <div class="select-input-containear">
                            <select v-model="selectedState" class="select-input">
                                <option value="" disabled>Selecione o estado</option>
                                <option v-for="state in states" :key="state.est_id" :value="state">{{state.est_nome}}</option>
                            </select>
                        </div>
                    </div>



                    <div class="category-input-size">
                        <div class="select-input-text">
                            Categorias
                        </div>
                        <div class="select-input-containear">
                            <select v-model="selectedcategory" class="select-input">
                                <option value="">Selecione uma categoria</option>
                                <option v-for="category in categories" :key="category.id" :value="category">{{category.name}}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="loading">
            <div class="products-skeletons products-containear">
                <div v-for="x in [1,2,3,4,5,6,7]" :key="x" class="card-skeleton">

                </div>
            </div>
        </div>
        <div v-else>
            <div class="catalog-containear" v-if="pageFilter=='product'">
                <div v-for="category in categories" :key="category.id">

                    <div class="category-containear" v-if="checkCategory(category)">
                        <div class="category-title">
                            {{category.name}}
                        </div>
                        <div class="category-title-bar"></div>
                        <div class="products-containear">
                            <div v-for="item in category.items" :key="item.id">
                                <div class="product-containear">
                                    <div class="product-align">
                                        <div class="image-alignt">
                                            <img class="product-image" :src="item.product.cover_url" :alt="item.product.name">
                                        </div>
                                        <div class="product-info">
                                            <div class="product-name" :title="item.product.name">
                                                {{formatName(item.product.name)}}
                                            </div>
                                            <div class="item-suplier-containear">
                                                <img class="item-suplier-icon" src="@/assets/suplier-icon.svg" alt="Fornecedor">
                                                <div class="suplier-text">Fornecedor</div>
                                                <div @click="showProviderDetailsModal = true; openProviderDetails(item.campaign.provider)"
                                                     class="suplier-name">
                                                  {{item.campaign.provider.name}}
                                                </div>
                                            </div>
                                            <div class="item-suplier-containear">
                                                <span class="material-icons-outlined item-suplier-icon">paid</span>
                                                <div class="suplier-text">Condição de pagamento</div>
                                                <div class="payment-name">
                                                  {{item.campaign.payment_condition}}
                                                </div>
                                            </div>
                                            <div v-if="item.wrap_quantity > 1" class="product-price">
                                                Vlr. Emb: {{item.wrap_price.toLocaleString('pt-BR', {
                                                    currency: 'BRL',
                                                    style: 'currency',
                                                    minimumFractionDigits: 2
                                                })}}
                                            </div>
                                            <div v-else class="product-price">Valor unitário</div>
                                            <div class="product-price-unity" >
                                                Vlr. Unit: {{item.unit_price.toLocaleString('pt-BR', {
                                                    currency: 'BRL',
                                                    style: 'currency',
                                                    minimumFractionDigits: 2
                                                })}}
                                            </div>
                                            <div class="product-package-containear">
                                                <img src="@/assets/package.svg" alt="pacote">
                                                <div class="product-package-text">
                                                    Pacote com {{item.wrap_quantity}} unidade(s)
                                                </div>
                                            </div>
                                            <div class="product-obs-containear" v-if="item.obs">
                                                <div class="product-obs-content" >
                                                    <div class="product-obs-text">
                                                        {{formatObs(item.obs)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-buttons-containear" v-if="item.showOption">
                                        <div class="product-button-subtrack" v-on:click="() => {removeItem(item), item.isChanged = true}">-</div>
                                        <!-- <div class="product-button-value">{{cart_item_for(item).quantity}}</div> -->
                                        <input :id="item.id" :style="getChangeInput(item.isChanged)" class="product-button-value" type="number" :value="cart_item_for(item).quantity" v-on:change="event => {setNewValue(event, item)}">
                                        <div class="product-button-add" @click="() => {add_item(item); item.isChanged = true}">+</div>
                                    </div>

                                    <div v-else class="product-buttons-containear">
                                        <div class="product-buttons-buy" @click="showBuyOption(item)">
                                            <span class="material-icons-outlined">shopping_cart</span>
                                            Quero Comprar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="supliers-containear" v-else>
                <div class="supliers-title">
                    Fornecedor
                </div>
                <div class="products-containear">
                    <div v-for="item in checkSupliers(supliers)" :key="item.id" class="suplier-containear">
                        <div class="suplier-align">
                            <img class="suplier-image" :src="item.provider.cover_url" :alt="item.provider.name">
                            <div class="suplier-body-margin">
                                <div class="suplier-info-name">
                                    {{item.provider.name}}
                                </div>
                                <div class="suplier-text-title">
                                    CNPJ
                                </div>
                                <div class="suplier-text">
                                    {{item.provider.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}}
                                </div>
                                <div class="suplier-text-title">
                                    Faturamento mínimo
                                </div>
                                <div class="suplier-text">
                                    {{item.provider.minimum_billing | currency}}
                                </div>
                            </div>

                        </div>
                        <div class="suplier-button" v-on:click="setSearchInput(item.provider.name)">
                            Ver Produto
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cc-campaign-cart
            v-if="show_resume"
            @close="show_resume = false" />
        <CartButtom @click="show_resume = true" />
        <ProviderDetailsModal v-if="showProviderDetailsModal"
                              :closeDialog="closeProviderDetailsModal"
                              :providerInfo="providerInfo"/>
    </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import CampaignService from '@/services/v3/campaigns/campaign.service'
import SysConfService from '@/services/v3/management/sysconf.service'
import CampaignCartModal from './components/cart.modal'
import CartButtom from './components/_cart-buttom.component'
import CampaingItem from './components/_campaign-product.component.vue'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import UserService from "@/services/v1/user.service";
import ProviderDetailsModal from '@/modals/providerDetailsModal.vue'
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'

export default {
    name: 'offers',
    mixins: [ perm_mixin ],
    components: {
        ccPagination: Pagination,
        'cc-campaign-cart' : CampaignCartModal,
        'cc-campaign-item' : CampaingItem,
        CartButtom,
        ProviderDetailsModal,
        StandardButton,
    },
    data() {
        return {
            showProviderDetailsModal: false,
            providerInfo: undefined,
            svc: new CampaignService(),
            user_svc: new UserService(),
            sysconf_svc: new SysConfService(),
            breadcrumbs: [
                {
                    name: "Painel de Controle"
                }
            ],
            loading: true,
            banners: [],
            filters: {
            },
            states: [],
            selectedState: {},
            nextLabel: "<span class='nav-next rounded-circle'><i class='fas fa-caret-right'></i></span>",
            prevLabel: "<span class='nav-prev rounded-circle'><i class='fas fa-caret-left'></i></span>",
            show_resume: false,
            items: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
            ],
            categories: [],
            selectedcategory: '',
            pageFilter: 'product',
            supliers: [],
            searchSuplierNameId: undefined,
            searchTextSuplier: '',
            searchTextProduct: ''
        };
    },
    computed: {
        ...mapState([ 'user' ]),
        ...mapGetters('cart', [ 'cart_item_for']),
    },
    watch: {
        'user.stores': function(stores) {
            this.init_state_filter()
            this.load_items()
            this.load_campaign_banners()
        },
        'user': function() {
            if (!this.can('CAMPAIGN', 'READ')) {
                this.$router.push("/cliente/monitores/cotacoes");
            }
        },
        searchTextProduct: function(){
            this.process_categories(this.items)
        },
        searchSuplierNameId: function(){
            this.process_categories(this.items)
        },
        selectedState: function(){
            this.process_categories(this.items)
        },
    },
    methods: {
        getChangeInput(value){
            if (value) return 'background-color: lightgreen; color: rgb(66 66 66)'
        },
        setNewValue(event, item){
            if (event.target.value == 0) item.showOption = false
            this.update_quantity({item: item, quantity:event.target.value})
            item.isChanged = true
            this.$forceUpdate()
        },
        checkSupliers(supliers){
            let resp = []
            for (let index = 0; index < supliers.length; index++) if (supliers[index].provider.name.toLowerCase().includes(this.searchTextSuplier.toLowerCase())) resp.push(supliers[index])
            return resp
        },
        showBuyOption(item) {
            item.showOption = true
            this.add_item(item)
            this.$forceUpdate()
            setTimeout(() => document.getElementById(item.id).focus(), 50);
        },
        removeItem(item){
            if (this.cart_item_for(item).quantity == 1) item.showOption = false
            this.remove_item(item)
        },
        shuffle(array) {
            let currentIndex = array.length,  randomIndex;
            // While there remain elements to shuffle.
            while (currentIndex != 0) {
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        },
        ...mapActions('user', [ 'fetch_stores']),
        ...mapActions('cart', [ 'remove_item', 'add_item', 'update_quantity']),
        formatName(name){
            if (name.length > 55) name = name.slice(0,54) + '...'
            name = name.toLowerCase()
            name = name.substring(0, 1).toUpperCase() + name.substring(1);
            return name
        },
        formatObs(obs){
            if (obs.length > 25) return 'Observação: ' + obs.slice(0,24) + '...'
            return 'Observação: ' + obs
        },
        visible_categories() {
            return this.categories.filter(cat => cat.visible == true).filter(cat => cat.items.some(i => i.visible))
        },
        isActiveTag(text){
            if (text == this.pageFilter) return 'active-tag'
        },




        checkItems(items){
            let result = []
            if (this.searchTextProduct != ''){
                items.forEach(item => {
                    if (item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase()) && item.is_enabled) result.push(item)
                    if (this.cart_item_for(item).quantity > 0) item.showOption = true
                })
            } else result = items

            if (this.searchSuplierNameId) {
                let resp = []
                result.forEach(item => {
                    if (item.campaign.provider.name == this.searchSuplierNameId) resp.push(item)
                })
                result = resp
            }

            let resp2 = []
            result.forEach(item => {
                if (this.cart_item_for(item).quantity > 0) item.showOption = true
                if (item.is_enabled) resp2.push(item)
            })
            result = resp2
            return result
        },
        checkCategory(category){
            if (!category.visible) return false
            // if (this.selectedState.est_id > 0 && this.selectedState.est_id != category.items[0].campaign.provider.head_office.state.id) return false
            // if (this.selectedState.est_id > 0){
            //     let go = false
            //     category.items.forEach(item => {
            //         if (this.selectedState.est_id == item.campaign.provider.head_office.state.id) go = true
            //     })
            //     if (!go) return false
            // }
            if (this.selectedcategory != '' && category.name != this.selectedcategory.name) return false
            // if (this.searchSuplierNameId && this.searchTextProduct != ''){
            //     let show = false
            //     category.items.forEach(item => {
            //         if (item.campaign.provider.name == this.searchSuplierNameId &&  item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) show = true
            //     })
            //     return show
            // }
            // else if (this.searchSuplierNameId) {
            //     let show = false
            //     category.items.forEach(item => {
            //         if (item.campaign.provider.name == this.searchSuplierNameId ) show = true
            //     })
            //     return show
            // }
            // else if (this.searchTextProduct != ''){
            //     let show = false
            //     category.items.forEach(item => {
            //         if (item.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) show = true
            //     })
            //     return show
            // }
            return true
        },
        setSearchInput(id){
            this.searchSuplierNameId = id
            this.pageFilter = 'product'
        },
        countdown() {
            setInterval(() => {
                this.items.forEach(c => {
                    let date = new Date(c.ends_at)
                    var now = new Date().getTime();
                    var timeleft = date.getTime() - now;
                    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                    let text = `${hours}:${minutes}:${seconds}`
                    if(days > 0) {
                        text = days > 1 ? `${days} dias ${text}h` : `${days} dia ${text}h`
                    }
                    c.countdown = text
                    this.$forceUpdate()
                })
            }, 1000)
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = [ { est_nome: 'Todos', est_id: -1 }, ...data.estados ]
			})
		},
        open_campaign(camp) {
            this.current_campaign = camp
        },
        load_campaign_banners() {
            this.sysconf_svc.load_campaign_state_banners().then(response => response.data).then(data => {
                this.banners = JSON.parse(data.value)
            })
        },
        load_items(params = this.filters.state) {
            let fu = params?.est_sigla
            this.svc.load_products({ fu }).then(response => response.data).then(data => {
                if (data.length == 0 && this.$route.query.login == 'true')this.$router.push("/cliente/monitores/cotacoes");
                const items = []
                data.forEach(item =>  { if (item.is_enabled) items.push(item) })
                this.items = this.shuffle(items)
                this.process_categories(this.items)
                this.processSupliers(this.items)
                this.states.forEach(state => { if (state.est_id == this.user.stores[0].state.id) this.selectedState = state })
                this.loading = false
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
            })
        },
        filter_category(category) {
            this.filters.category = category
            this.filter()
        },
        filter() {
            this.categories.forEach(cat => cat.visible = false)
            this.categories.forEach(cat => {
                cat.items.forEach(i => {
                    let rx = new RegExp(this.filters.product, 'gi')
                    i.visible = !this.filters.product || i.product.name.match(rx)
                })
            })
            this.categories.filter(cat => !this.filters.category || this.filters.category.id == cat.id)
                .forEach(cat => cat.visible = true)
            this.$forceUpdate()
        },



        process_categories(itemsArray) {
            itemsArray = this.checkSearch(itemsArray)
            itemsArray = this.checkSuplier(itemsArray)
            itemsArray = this.checkState(itemsArray)
            let cat = null
            this.categories = []
            itemsArray.filter(i => i.product.category).forEach(i => {
                cat = this.categories.find(c => c.id == i.product.category.id)
                cat ? cat.items.push({ ...i, visible: true }) : this.categories.push({ ...i.product.category, visible: true, items: [ { ...i, visible: true } ] })
            })
        },
        checkState(itemsList){
            if (this.selectedState.est_id <= 0) return itemsList
            const items = []
            for (let index = 0; index < itemsList.length; index++) {
                const element = itemsList[index];
                if (this.selectedState.est_id == element.campaign.provider.head_office.state.id) items.push(element)
            }
            return items
        },
        checkSuplier(itemsList){
            if (!this.searchSuplierNameId) return itemsList
            const items = []
            for (let index = 0; index < itemsList.length; index++) {
                const element = itemsList[index];
                if (element.campaign.provider.name == this.searchSuplierNameId) items.push(element)
            }
            return items
        },
        checkSearch(itemsList){
            if (this.searchTextProduct == '') return itemsList
            const items = []
            for (let index = 0; index < itemsList.length; index++) {
                const element = itemsList[index];
                if (element.product.name.toLowerCase().includes(this.searchTextProduct.toLowerCase())) items.push(element)
            }
            return items
        },
        processSupliers(items){
            for (let x = 0; x < items.length; x++){
                let add = true
                for (let y = 0; y < this.supliers.length; y++){
                    if (items[x].campaign.provider.id == this.supliers[y].provider.id) add = false
                }
                if (add) this.supliers.push(items[x].campaign)
            }
        },
        init_state_filter() {
            let store = this.user.stores.find(s => s.is_head_office)
            this.filters.state = this.states.find(s => s.est_id == store.est_id)
        },
        closeProviderDetailsModal: function() {
            this.showProviderDetailsModal = false;
        },
        openProviderDetails(payload) {
          this.providerInfo = payload;
        },
    },
    async created() {
        await this.load_region_data()
        this.load_items()
        this.load_campaign_banners()
        if (!this.can('CAMPAIGN', 'READ')) {
            this.$router.push("/cliente/monitores/cotacoes");
        }
    }
};
</script>
