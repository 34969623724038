<template>
    <div>
        <div class="input-title">{{title}}</div>
        <input class="input-value" :disabled="!active" v-if="isDocument" v-mask="'## ### ###/####-##'" v-model="value" type="text">
        <input class="input-value" :disabled="!active" v-else-if="isCep" v-mask="'##.###-###'" v-model="value" type="text">
        <input class="input-value" :disabled="!active" v-else-if="isCardNumber" v-mask="'#### #### #### ####'" v-model="value" type="text">
        <input class="input-value" :disabled="!active" v-else-if="isDate" v-mask="'##/20##'" v-model="value" type="text">
        <input class="input-value" :disabled="!active" v-else v-model="value" :type="isNumber ? 'number' : 'text'">
    </div>
</template>

<script>
export default {
    name: 'VindiPlansInput',
    props: ['title', 'propValue', 'isDocument', 'isNumber', 'active', 'isCep', 'isCardNumber', 'isDate', 'setValue'],
    data () {
        return {
            value: undefined
        }
    },
    created () {
        this.value = this.propValue
    },
    watch: {
        value (value) { if (this.setValue) this.setValue(value) }
    }
}
</script>

<style>
.input-title{
    font-weight: 300;
    font-size: 1.2em;
    color: #909090;
    margin-top: 1.5vh;
}
.input-value{
    font-weight: 300;
    font-size: 1.4em;
    color: #6e6e6e;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5vh 1vw;
    margin-top: 0.5vh;
    width: 100%;
}
.input-value:focus {
    outline: none;
}
</style>