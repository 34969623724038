import { render, staticRenderFns } from "./_campaign-product.component.vue?vue&type=template&id=f985fb60&scoped=true&"
import script from "./_campaign-product.component.vue?vue&type=script&lang=js&"
export * from "./_campaign-product.component.vue?vue&type=script&lang=js&"
import style0 from "./_campaign-product.component.vue?vue&type=style&index=0&id=f985fb60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f985fb60",
  null
  
)

export default component.exports