<template>
  <div>
    <cc-loader-full v-if="pageLoading"/>
    <div class="page">
      <div class="page-route-select">
          <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
              Home
          </router-link>
          <span class="material-icons page-route-select-icon">play_arrow</span>
          <div class="page-route-select-text">
              Meus produtos
          </div>
      </div>
      <div class="page-title">Monitor Preço de Venda Varejo</div>
      <div class="page-filter-Buttons">
        <div class="component-containear">
          <div class="icon-containear">
              <span class="material-icons-outlined component-icon">info</span>
          </div>
          <div class="component-text">
            <div>Gerencie suas compras</div>
            <div>Faça seu pedido também pelo aplicativo <a style="color: #17A2B8" target="_blank" href="https://play.google.com/store/apps/details?id=br.com.clubdacotacao.clubdacotacao&hl=pt_BR">android</a> ou <a style="color: #17A2B8"  target="_blank" href="https://itunes.apple.com/br/app/club-da-cotacao/id1331105972?mt=8">ios</a></div>
          </div>
        </div>
        <StandardButton class="page-filter-Button" v-if="products.length > 0" :action="cleanFilter" :iconName="'delete'" :text="'Limpar Filtro'"/>
        <StandardButton class="page-filter-Button" v-if="selectedCustomFilter" :action="setShowEditFilter" :iconName="'edit'" :text="'Editar Filtro'"/>
        <StandardButton class="page-filter-Button" v-else-if="products.length > 0" :action="showFilter" :iconName="'add_circle_outline'" :text="'Salvar Filtro'"/>
        <div class="page-filter-Button-visualized" v-on:click="requestProducts()">Visualizar</div>
      </div>
      <div class="page-filters-containear">

        <div class="Select-containear-product">
          <div class="input-title">Buscar por produto</div>
          <div class="input-containear" v-on:click.stop="openSelect1()">
            <input type="text" v-model="productSearch" class="input-value" placeholder="Digite o nome">
            <span v-if="!showSelectBody1" class="material-icons input-icon">search</span>
            <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect1()">close</span>
          </div>
          <div v-if="showSelectBody1">
            <div class="select-body product-width">
              <cc-loader v-if="isLoading" style="margin-top: 3vh;"/>
              <div v-else v-on:click.stop="toogleCheck(product)" v-for="product in productList" :key="product.id" class="select-body-line">
                <span v-if="product.isCheck" class="material-icons checkbox-icon">check_box</span>
                <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
                <div class="select-body-line-text">{{product.descricao}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="Select-containear">
          <div class="input-title">Período</div>
          <div class="input-containear" v-on:click.stop="openSelect2()">
            <div v-if="!selectedPeriod" class="input-value">Selecione o período</div>
            <div v-else class="input-value">{{selectedPeriod.text}}</div>
            <span v-if="!showSelectBody2" class="material-icons input-icon">expand_more</span>
            <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect2()">close</span>
          </div>
          <div v-if="showSelectBody2">
            <div class="select-body period-width">
              <div v-for="period in periodList" v-on:click="selectPeriod(period)" :key="period.text" class="select-body-line">
                <div class="select-body-line-text">{{period.text}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="Select-containear-city" >
          <div class="input-title">Estado</div>
          <div class="input-containear" v-on:click.stop="openSelectState()">
            <input type="text" v-model="stateSearch" class="input-value" placeholder="Digite o nome">
            <span v-if="!showSelectBodyState" class="material-icons input-icon">search</span>
            <span v-else class="material-icons input-icon" v-on:click.stop="closeSelectState()">close</span>
          </div>
          <div v-if="showSelectBodyState">
            <div class="select-body city-width">
              <cc-loader v-if="isLoading" style="margin-top: 3vh;"/>
              <div v-else v-on:click.stop="toogleCheckState(city)" v-for="(city, index) in statesList" :key="index" class="select-body-line">
                <span v-if="city.isCheck" class="material-icons checkbox-icon">check_box</span>
                <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
                <div class="select-body-line-text">{{city.text}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="Select-containear">
          <div class="input-title">Filtros Salvos</div>
          <div class="input-containear" v-on:click.stop="openSelect3()">
            <div v-if="!selectedCustomFilter" class="input-value">Selecione filtro</div>
            <div v-else class="input-value">{{selectedCustomFilter.filterName}}</div>
            <span v-if="!showSelectBody3" class="material-icons input-icon">expand_more</span>
            <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect3()">close</span>
          </div>
          <div v-if="showSelectBody3">
            <div class="select-body filter-width">
              <div v-for="filter in customFilter" :key="filter.filterName" class="select-body-line" v-on:click="selectFilter(filter)">
                <div class="select-body-line-text">{{filter.filterName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="toggle-selected-options" v-on:click="showSelectedProducts = !showSelectedProducts">
        <span v-if="!showSelectedProducts" class="material-icons toggle-selected-options-icon">expand_more</span>
        <span v-else class="material-icons toggle-selected-options-icon">expand_less</span>
        <div v-if="!showSelectedProducts">Mostrar produtos selecionados</div>
        <div v-else>Minimizar produtos selecionados</div>
      </div> -->

      <div class="selected-options-containear">
        <!-- <div class="selected-option" v-for="option in selectedProducts" :key="option.gtin">
          <div>{{option.descricao}}</div>
          <span class="material-icons" v-on:click="cancelSelectedProduct(option)" style="cursor: pointer">close</span>
        </div> -->
        <div class="selected-option" v-for="(option, index) in selectedStates" :key="index">
          <div>{{option}}</div>
          <span class="material-icons" v-on:click="cancelSelectedState(option)" style="cursor: pointer">close</span>
        </div>
        <div class="selected-option" v-if="selectedPeriod">
          <div>{{selectedPeriod.text}}</div>
          <span class="material-icons" style="cursor: pointer" v-on:click="() => selectedPeriod = undefined">close</span>
        </div>
      </div>



      <!-- <div class="page-tab-selector-containear">
        <div :class="getTab('grid')" v-on:click="tab='grid'">
          <span class="material-icons">grid_view</span>
          <div>card</div>
        </div>
        <div :class="getTab('table')" v-on:click="tab='table'">
          <span class="material-icons">view_list</span>
          <div>Tabela</div>
        </div>
      </div> -->
      <!-- <cc-loader v-if="pageLoading"/> -->
      <div class="page-content-containear">
        <div v-if="tab == 'grid'">
          <div v-if="pageLoading" class="page-grid-containear">
            <div v-for="card in [1, 2, 3, 4, 5, 6, 7, 8]" :key="card">
              <div class="loader-sekeleton"></div>
            </div>
          </div>
          <div v-else class="page-grid-containear">
            <div v-for="(product, idx) in products" :key="idx">
              <div class="page-grid">
                <div class="page-grid-uper">
                  <div class="page-grid-uper-img-containear">
                    <img class="page-grid-uper-img" :src="product.img" alt="">
                  </div>
                  <div class="page-grid-uper-info">
                    <div class="page-grid-uper-info-title-container">
                      <div class="page-grid-uper-info-title">{{product.descricao}}</div>
                      <span v-on:click="cancelSelectedProduct(product)" class="material-icons-outlined page-grid-uper-info-close-icon">cancel</span>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>GTIN</div>
                      <div>{{product.gtin}}</div>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>Seção</div>
                      <div>{{product.section}}</div>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>Categorias</div>
                      <div class="grid-category-value">{{product.category}}</div>
                    </div>
                  </div>
                </div>
                <div class="page-grid-lower">
                  <div class="page-grid-lower-colum">
                    <div class="page-grid-lower-colum-title">Inf. preço de venda</div>
                    <div class="page-grid-lower-info-row">
                      <div>Nº lojas</div>
                      <div class="page-grid-lower-stores">{{product.cnpjs_distintos}}</div>
                    </div>
                    <div class="page-grid-lower-info-row">
                      <div>Preço mínimo</div>
                      <div class="page-grid-lower-min">{{product.preco_minimo.toFixed(2)}}</div>
                    </div>
                    <div class="page-grid-lower-info-row">
                      <div>Média de Preço mínimo</div>
                      <div class="page-grid-lower-medium">{{product.preco_quartil_inferior.toFixed(2)}}</div>
                    </div>
                    <div class="page-grid-lower-info-row">
                      <div>Preço médio</div>
                      <div class="page-grid-lower-med">{{product.preco_medio.toFixed(2)}}</div>
                    </div>
                    <div class="page-grid-lower-info-row">
                      <div>Média de Preço máximo</div>
                      <div class="page-grid-lower-medium">{{product.preco_quartil_superior.toFixed(2)}}</div>
                    </div>
                    <div class="page-grid-lower-info-row">
                      <div>Preço máximo</div>
                      <div class="page-grid-lower-max">{{product.preco_maximo.toFixed(2)}}</div>
                    </div>
                  </div>
                  <!-- <div class="page-grid-lower-colum">
                    <div class="page-grid-lower-colum-title">Inf. preço de Compra</div>
                    <div class="page-grid-uper-info-row">
                      <div>Nº lojas</div>
                      <div class="page-grid-lower-stores">Em breve</div>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>Preço mínimo</div>
                      <div class="page-grid-lower-min">Em breve</div>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>Preço médio</div>
                      <div class="page-grid-lower-med">Em breve</div>
                    </div>
                    <div class="page-grid-uper-info-row">
                      <div>Preço máximo</div>
                      <div class="page-grid-lower-max">Em breve</div>
                    </div>
                  </div> -->
                </div>
                <div v-on:click="showSellersPrice(product)" class="details-button">
                  Ver Detalhes
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="tab == 'table'">
          <div class="overflow-container">
            <div class="width-container">
              <div class="page-table-header">
                <div class="table-colum">Identificação</div>
                <div class="table-colum">Descrição</div>
                <div class="table-colum">Inf. preço de venda</div>
                <div class="table-action-colum">Ações</div>
                <div class="table-colum">Inf. preço de compra</div>
              </div>
              <div class="table-line" v-for="(product, idx) in products" :class="getSpecialBackground(idx)" :key="idx">
                <div class="table-colum table-text">
                  {{product.gtin}}
                </div>
                <div class="table-colum table-text">
                  {{product.descricao}}
                </div>
                <div class="table-colum">
                  <div class="page-table-info-align">
                    <div class="page-table-info-row">
                      <div>Nº lojas</div>
                      <div class="page-grid-lower-stores">{{product.cnpjs_distintos}}</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço mínimo</div>
                      <div class="page-grid-lower-min">{{product.preco_minimo.toFixed(2)}}</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Media de Preço mínimo</div>
                      <div class="page-grid-lower-min">{{product.preco_quartil_inferior.toFixed(2)}}</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço médio</div>
                      <div class="page-grid-lower-med">{{product.preco_medio.toFixed(2)}}</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Media de Preço máximo</div>
                      <div class="page-grid-lower-max">{{product.preco_quartil_superior.toFixed(2)}}</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço máximo</div>
                      <div class="page-grid-lower-max">{{product.preco_maximo.toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
                <div v-on:click="showSellersPrice(product)" class="table-action-colum details-button-table">
                  Ver Detalhes
                </div>
                <div class="table-colum">
                  <div class="page-table-info-align">
                    <div class="page-table-info-row">
                      <div>Nº lojas</div>
                      <div class="page-grid-lower-stores">Em breve</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço mínimo</div>
                      <div class="page-grid-lower-min">Em breve</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço médio</div>
                      <div class="page-grid-lower-med">Em breve</div>
                    </div>
                    <div class="page-table-info-row">
                      <div>Preço máximo</div>
                      <div class="page-grid-lower-max">Em breve</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <SaveFilterModal :loadCustomFilter="loadCustomFilter" v-if="showSaveFilter" :close="closeSaveFilter" :saveFilter="saveFilter"/>
      <EditFilterModal :filter="selectedCustomFilter" v-if="showEditFilter" :close="closeEditFilter"/>
      <SellersPriceModal v-if="productDetails" :cities="citiesFilter" :setCities="setCitiesFilter" :initSelectedStates="selectedStates" :dateEnd="requestDateEnd" :dateBeguin="requestDateBeguin" :service="infoPriceService" :product="productDetails" :close="closeProductSellers"/>
    </div>
  </div>
</template>

<script>
import InfoPriceService from '@/services/InfoPrice.js'
import SaveFilterModal from '@/modals/persist-filter/saveFilter.vue'
import EditFilterModal from '@/modals/persist-filter/editFilter.vue'
import SellersPriceModal from '@/modals/sellersPriceModal.vue'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import StorageService from '@/services/TokenService';
import { mapState } from 'vuex'

export default {
  name: 'PriceMonitor',
  components : {
    SaveFilterModal,
    EditFilterModal,
    SellersPriceModal,
    StandardButton
  },
  data () {
    return {
      infoPriceService: new InfoPriceService(),
      storage_svc: new StorageService(),
      userPlan: "0",
      productList: [],
      statesList: [],
      showSelectBody1: false,
      showSelectBody2: false,
      showSelectBody3: false,
      showSelectBodyState: false,
      showSelectcities: false,
      citiesFilter: [],
      periodList: [{
        text: 'Ultima semana',
        value: 7
      }, {
        text: 'Ultima quinzena',
        value: 15
      }, {
        text: 'Ultimo mês',
        value: 30
      }],
      tab: 'grid',
      products: [],
      selectedProducts: [],
      selectedStates: [],
      selectedPeriod: {text: 'Ultima quinzena',value: 15},
      requestDateBeguin: undefined,
      requestDateEnd: undefined,
      productSearch: '',
      stateSearch: '',
      isLoading: false,
      pageLoading: true,
      showSaveFilter: false,
      showEditFilter: false,
      customFilter: [],
      selectedCustomFilter: undefined,
      productDetails: undefined,
      showSelectedProducts: false,
      firstCheck: true
    }
  },
  watch: {
    productSearch () {
      if (!this.isLoading){
        this.isLoading = true
        setTimeout(() => {
          this.infoPriceService.reloadProducts(this.productSearch).then(response => {
            this.productList = response
            this.isLoading = false
          })
        },1000)
      }
    },
    stateSearch () {
      if (!this.isLoading){
        this.isLoading = true
        setTimeout(() => {
          this.infoPriceService.loadStates(this.stateSearch).then(response => {
            this.statesList = []
            response.forEach(state => {
              this.statesList.push({
                text: state,
                isCheck: false
              })
            })
            this.isLoading = false
          })
        },1000)
      }
    }
  },
  methods: {
    setCitiesFilter (value) {
      this.citiesFilter = value
    },
    closeInputs () {
      this.showSelectBody1 = false
      this.showSelectBody2 = false
      this.showSelectBody3 = false
      this.showSelectBodyState = false
    },
    showSellersPrice (product) {
      if (this.userPlan === '1'){
        product.img = this.getProductImg(product)
        product.section = this.getSection(product)
        product.category = this.getCategory(product)
        this.productDetails = product
      } else {
        this.$swal.fire("Desculpe!", " Plano não disponivel para você. Entre em contato com nosso Comerical: 81 99984-5221", "error");
      }
    },
    closeProductSellers () {
      this.productDetails = undefined
    },
    selectFilter (filter) {
      this.selectedCustomFilter = filter
      this.showSelectBody3 = false
      this.selectedPeriod = filter.selectedPeriod
      this.selectedProducts = filter.selectedProducts
      this.requestProducts()
    },
    loadCustomFilter () {
      let filters = localStorage.getItem('filters');
      if (filters) {
        this.customFilter = JSON.parse(filters)
      }
    },
    closeSaveFilter () { this.showSaveFilter = false },
    closeEditFilter () { this.showEditFilter = false },
    showFilter () { this.showSaveFilter = true },
    setShowEditFilter () { this.showEditFilter = true },
    saveFilter (filterName) {
      let filters = localStorage.getItem('filters');
      if (!filters) {
        localStorage.setItem('filters', JSON.stringify([{filterName: filterName, selectedProducts: this.selectedProducts, selectedPeriod: this.selectedPeriod}]));
      } else {
        filters = JSON.parse(filters)
        filters.push({filterName: filterName, selectedProducts: this.selectedProducts, selectedPeriod: this.selectedPeriod})
        localStorage.setItem('filters', JSON.stringify(filters));
      }
    },
    openSelect1 () {this.showSelectBody1 = true},
    openSelect2 () {this.showSelectBody2 = true},
    openSelect3 () {this.showSelectBody3 = true},
    openSelectState () {this.showSelectBodyState = true},
    closeSelect1 () {this.showSelectBody1 = false},
    closeSelect2 () {this.showSelectBody2 = false},
    closeSelect3 () {this.showSelectBody3 = false},
    closeSelectState () {this.showSelectBodyState = false},
    selectPeriod (period) {this.selectedPeriod = period; this.showSelectBody2 = false},
    toogleCheck (product) {
      if (this.firstCheck) {
        this.firstCheck = false
        this.selectedProducts = []
      }
      product.isCheck = !product.isCheck
      if (product.isCheck) this.selectedProducts.push(product)
      else {
        for (let i = 0; i < this.selectedProducts.length; i++){
          if (this.selectedProducts[i].identificador == product.identificador) this.selectedProducts.splice(i, 1)
        }
      }
      this.$forceUpdate()
    },
    toogleCheckState (city) {
      city.isCheck = !city.isCheck
      if (city.isCheck) {
        this.selectedStates.push(city.text)
      }
      else {
        for (let i = 0; i < this.selectedStates.length; i++) {
          if (this.selectedStates == city.text) this.selectedStates.splice(i, 1)
        }
      }
    },
    cancelSelectedProduct (product) {
      for (let i = 0; i < this.selectedProducts.length; i++){
        if ('0' + this.selectedProducts[i].identificador == product.gtin) this.selectedProducts.splice(i, 1)
        else if (this.selectedProducts[i].identificador == product.gtin) this.selectedProducts.splice(i, 1)
      }
      for (let i = 0; i < this.productList.length; i++){
        if ('0' + this.productList[i].identificador == product.gtin) {this.productList[i].isCheck = false}
        else if (this.productList[i].identificador == product.gtin) this.productList[i].isCheck = false
      }
      for (let i = 0; i < this.products.length; i++){
        if (this.products[i].gtin == product.gtin) {
          this.products.splice(i, 1)
          this.$forceUpdate()
        }
      }
    },
    cancelSelectedState (city) {
      for (let i = 0; i < this.selectedStates.length; i++){
        if (this.selectedStates[i] == city) this.selectedStates.splice(i, 1)
      }
      for (let i = 0; i < this.statesList.length; i++){
        if (this.statesList[i].text == city) this.statesList[i].isCheck = false
      }
    },
    getTab (value) {
      if (this.tab == value) return 'page-tab-selector'
      return 'page-tab-selector-disable'
    },
    getSpecialBackground (idx) {
      if (idx % 2 == 0) return 'page-table-line-special'
    },
    getEndDate () {
      var currentdate = new Date();
      currentdate.setDate(currentdate.getDate()- this.selectedPeriod.value)
      return currentdate.toISOString().split('T')[0]
    },
    requestProducts () {
      this.pageLoading = true
      if (this.selectedProducts.length == 0 || this.selectedPeriod == undefined) {
        this.$swal.fire("Erro", "Selecione os produtos e o periodo que deseja buscar.", "error");
        this.pageLoading = false
        return
      }
      const requestList = []
      this.selectedProducts.forEach(product => {
        requestList.push(product.identificador)
      })
      const payload = {cities: [], states: this.selectedStates, gtins: requestList, dateEnd: new Date().toISOString().split('T')[0], dateBeguin: this.getEndDate()}
      this.infoPriceService.requestProducts(payload).then(response => {
        this.requestDateBeguin = payload.dateBeguin
        this.requestDateEnd = payload.dateEnd
        this.products = response
        this.products.forEach(product => {
          product.img = this.getProductImg(product)
          product.section = this.getSection(product)
          product.category = this.getCategory(product)
        })
        this.pageLoading = false
      })
    },
    getProductImg (product) {
      for (let i = 0; i < this.selectedProducts.length; i++){
        if ('0' + this.selectedProducts[i].identificador === product.gtin) { return this.selectedProducts[i].foto }
        else if (this.selectedProducts[i].identificador === product.gtin) { return this.selectedProducts[i].foto }
      }
    },
    getSection (product) {
      for (let i = 0; i < this.selectedProducts.length; i++){
        if ('0' + this.selectedProducts[i].identificador === product.gtin){return this.selectedProducts[i].secao}
        else if (this.selectedProducts[i].identificador === product.gtin){return this.selectedProducts[i].secao}
      }
    },
    getCategory (product) {
      for (let i = 0; i < this.selectedProducts.length; i++){
        if ('0' + this.selectedProducts[i].identificador === product.gtin){return this.selectedProducts[i].categoria}
        else if (this.selectedProducts[i].identificador === product.gtin){return this.selectedProducts[i].categoria}
      }
    },
    cleanFilter () {
      this.selectedProducts = []
      this.products = []
      this.selectedStates = []
      this.productList.forEach(product => { product.isCheck = false })
      this.statesList.forEach(city => { city.isCheck = false })
    }
  },
  computed: {
      ...mapState([ 'user' ])
  },
  mounted () {
    this.loadCustomFilter()
    this.selectedStates.push(this.user.stores[0].state.fu == 'SP'? 'Grande SP' : this.user.stores[0].state.fu)
    this.userPlan = this.storage_svc.get('plan')
    this.infoPriceService.loadProducts('').then(resp => {
      this.productList = resp.filterList
      this.selectedProducts = resp.productsList
      this.infoPriceService.loadStates('').then(resp2 => {
        resp2.forEach(state => this.statesList.push(state.key))
        this.statesList.sort()
        const newList = []
        for (let index = 0; index < this.statesList.length; index++) newList.push({text: this.statesList[index], isCheck: false})
        this.statesList = newList
        this.requestProducts()
      })
    })
  }
}
</script>

<style scoped>
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color: var(--primary-color);
  margin-right: 20px;
}
.page{
  font-size: 1vw;
}
.page-title{
  font-weight: 700;
  font-size: 2.72em;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 40px;
}
.page-filter-Buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: 3vh;
  margin-bottom: 1.5vh;
  align-items: flex-end;
}
.page-filter-Button-visualized{
  background: var(--primary-color);
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 14px 0;
  font-weight: 700;
  font-size: 1.36em;
  line-height: 25px;
  color: #FFFFFF;
  cursor: pointer;
  width: 145px;
  text-align: center;
}
.toggle-selected-options-icon{
  color: var(--primary-color);
}
.page-filter-Button{
  margin-right: 2vw;
}
.page-filter-Button-save-icon{
  color: #212529;
}
.grid-category-value{
    max-width: 50%;
    text-align: right;
    line-height: 2vh;
}
.page-filters-containear{
  display: flex;
}
.page-loading{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
.input-title{
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.Select-containear{
  flex: 1;
  margin-left: 2vw;
}
.Select-containear-product{
  width: 30%;
}
.Select-containear-city{
  width: 26%;
  margin-left: 2vw;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}
.input-value{
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 1.19em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #B3B3B3;
  cursor: pointer;
}
.product-width{ width: 28.8%; }
.city-width{ width: 25%; }
.period-width{ width: 18%;}
.filter-width{ width: 18%;}
.select-body{
  position: absolute;
  background-color: white;
  overflow-y: scroll;
  max-height: 300px;
}
.select-body::-webkit-scrollbar {
  width: 7px;
}
.select-body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  height: 10px;
}
.select-body-line{
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}
.select-body-line:hover{
  background-color: rgba(255, 247, 247, 0.75);;
}
.select-body-line-text{
  font-weight: 300;
  font-size: 1.27em;
  color: #505050;
  margin-left: 5px;
}
.checkbox-icon{
  color: var(--primary-color);
}
.page-tab-selector-containear{
  display: flex;
  margin-top: 50px;
  width: 250px;
  justify-content: space-between;
}
.page-tab-selector{
  background: var(--primary-color);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  padding: 15px;
  color: white;
  width: 110px;
  justify-content: center;
}
.page-tab-selector-disable{
  background: #FAFAFC;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
  width: 110px;
}
.page-content-containear{
  margin-top: 50px;
  margin-bottom: 150px;
}
.page-grid-containear{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.page-grid{
  border: 0.62585px solid #E5E5E5;
  width: 90%;
  padding: 15px 22px;
  border-radius: 8px;
  margin-top: 20px;
}
.page-grid-uper{
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #FCF9F7;
  min-height: 20vh;
}
.page-grid-uper-img-containear{
  width: 50%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-grid-uper-img{
  max-width: 100%;
  max-height: 100px;
}
.page-grid-uper-info{
  padding-left: 10%;
}
.page-grid-uper-info-title-container{
  display: flex;
  justify-content: space-between;
}
.page-grid-uper-info-title{
  font-weight: 600;
  font-size: 1.27em;
  color: #303030;
}
.page-grid-uper-info-close-icon{
  cursor: pointer;
  font-size: 2.3em;
  color: lightcoral;
}
.page-grid-uper-info-row{
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.02em;
  line-height: 7px;
  color: #C1C1C1;
  margin-top: 10px;
  align-items: center;
}
.page-grid-lower-info-row{
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.19em;
  line-height: 7px;
  color: #C1C1C1;
  margin-top: 10px;
  align-items: center;
}
.page-table-info-row{
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.02em;
  line-height: 7px;
  color: #605F5F;
  margin-top: 10px;
  align-items: center;
}
.page-grid-lower{
  display: flex;
  margin-top: 6px;
  justify-content: space-between;
}
.page-grid-lower-colum{
  flex: 1;
}
.page-grid-lower-colum-title{
  font-weight: 400;
  font-size: 1.27em;
  color: #707070;
}
.page-grid-lower-stores{
  font-weight: 300;
  font-size: 0.9em;
  text-decoration-line: underline;
  color: var(--primary-color);
}
.page-grid-lower-medium{
  font-weight: 300;
  font-size: 0.9em;
  color: #212529;
  border: 1px solid #212529;
  padding: 5px 3px;
  border-radius: 4px;
}
.page-grid-lower-min{
  background: rgba(48, 170, 76, 0.5);
  border-radius: 2px;
  padding: 5px;
  min-width: 8%;
  color: white;
  text-align: center;
  font-size: 0.9em;
}
.page-grid-lower-med{
  background: #be9415d8;
  border-radius: 2px;
  padding: 5px;
  color: white;
  min-width: 8%;
  text-align: center;
  font-size: 0.9em;
}
.page-grid-lower-max{
  min-width: 8%;
  background: #CD2A2A80;
  border-radius: 2px;
  padding: 5px;
  color: white;
  font-size: 0.9em;
  text-align: center;
}
.page-table-header{
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  margin-top: 30px;
  display: flex;
  padding: 15px 25px;
  margin-bottom: 5px;
}
.table-colum{
  flex: 1;
}
.table-action-colum{
  width: 10%;
  text-align: center;
}
.table-line{
  border: 1px solid #E5E5E5;
  display: flex;
  padding: 15px 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
}
.page-table-info-align{
  padding-right: 25%;
}
.table-text{
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.19em;
  color: #605F5F;
}
.page-table-line-special{
  background: #F7F7F7;
}
.selected-options-containear{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5vw;
}
.selected-option{
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1vh 5%;
  font-weight: 300;
  font-size: 0.93em;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-top: 1vh;
}
.width-container{
  min-width: 1300px;
}
.toggle-selected-options{
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  padding: 1.5vh;
  width: fit-content;
  margin-top: 2vh;
  font-weight: 400;
  font-size: 1.36em;
  line-height: 19px;
  color: #605F5F;
  align-items: center;
  cursor: pointer;
}
.loader-sekeleton{
  width: 90%;
  padding: 15px 22px;
  border-radius: 8px;
  margin-top: 20px;
  min-height: 30vh;
  background-color: lightgray;
}
.details-button{
  border: 0.920814px solid rgba(255, 113, 16, 0.9);
  border-radius: 3.68325px;
  padding: 12px 0;
  font-weight: 500;
  font-size: 1.27em;
  line-height: 9px;
  color: rgba(255, 113, 16, 0.9);
  width: 40%;
  margin: 1vh auto;
  text-align: center;
  cursor: pointer;
}
.details-button-table{
  border: 0.920814px solid rgba(255, 113, 16, 0.9);
  color: rgba(255, 113, 16, 0.9);
  border-radius: 3.68325px;
  padding: 12px 0;
  background-color: white;
  height: 50px;
  text-align: center;
  cursor: pointer;
}
.component-containear{
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1vh 3vw;
    height: 10vh;
    display: flex;
    margin-right: auto;
}
.component-icon{
    font-size: 40px;
    color: #17A2B8;
    background-color: rgba(23, 162, 184, 0.1);
    border-radius: 50%;
    padding: 0.8vh;
    margin-right: 1vw;
}
.component-text{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: 400;
    font-size: 14px;
    color: #707070;
}
@media only screen and (max-width: 1300px) {
  .overflow-container{
    overflow-x: scroll;
  }
  .page-grid-containear{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .selected-options-containear{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1160px) {
  .page-grid-containear{
    grid-template-columns: 1fr 1fr;
  }
  .selected-options-containear{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1000px) {
    .page{ font-size: 1.2vw; }
}
@media only screen and (max-width: 800px) {
  .page-grid-containear{
    grid-template-columns: 1fr;
  }
  .selected-options-containear{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .page-grid{
    width: 100%;
  }
  .page-filters-containear{
    flex-direction: column;
  }
  .Select-containear{
    width: 100%;
    margin-top: 1vh;
    margin-left: 0;
  }
  .Select-containear-product{
    width: 100%;
  }
  .Select-containear-city{
    width: 100%;
    margin-left: 0;
  }
  .select-body{
    width: 99%;
  }
}
@media only screen and (max-width: 780px) {
  .page-filter-Buttons{
    flex-wrap: wrap;
    gap: 1vh;
  }
    .page{ font-size: 1.4vw; }
}
@media only screen and (max-width: 650px) {
    .page{ font-size: 1.6vw; }
}
@media only screen and (max-width: 550px) {
    .page{ font-size: 15px; }
}
</style>
