import http from "./http";
import Service from "../Service";

/**
 * @type ClientService
 */
export default class InvitationService extends Service {

	list(params) {
		return http.get(`invitations`, {
			headers: this.getHeaders(),
            params
		});
	}

    send(invitation) {
		return http.post(`invitations`, invitation, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

    renotify_all() {
		return http.get(`invitations/renotify`, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

    cancel(invitation) {
		return http.delete(`invitations/${invitation.id}`, {
			headers: this.getHeaders()
		});
	}
	createSeller(seller) {
        return http.post(`clients/sellers`, seller, {
			headers: this.getHeaders()
		});
    }
	createInternalSeller(seller) {
        return http.post(`clients/sellers/internal`, seller, {
			headers: this.getHeaders()
		});
    }
	activateSeller(seller_id, cli_id) {
		return http.patch(`clients/sellers/${seller_id}/clients/activate`, {client_id: cli_id}, {
			headers: this.getHeaders()
		});
	}
}
