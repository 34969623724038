<template>
<div>
  <div>
    <div class="modal-mask">
        <div class="modal-content-container" id="modalBodyPrint">
            <div class="ressuply-products__header-container">
                <span v-if="!new_product" class="ressuply-products__header-title not-print">
                    Produtos da Requisição: {{ quotation }}
                    <!-- <template v-if="!editing">{{ item.nomeSolicitacao }}</template>
                    <template v-else><input v-model="item.nomeSolicitacao"></template>
                    <span v-if="!editing" @click="editing = true" title="Editar" class="material-icons row-icon" style="color: #909090">edit</span>
                    <span v-else @click="save_title" title="Salvar" class="material-icons row-icon">save</span> -->
                    <div @click="editName()" style="cursor: pointer;" v-if="item.nomeSolicitacao && !editing">
                      - {{ item.nomeSolicitacao }}
                      <span title="Editar" class="material-icons" style="color: white">edit</span>
                    </div>
                    <span v-else-if="!editing" title="Editar" @click="editName()" class="material-icons edit-request-name-icon" style="color: white">edit</span>
                    <div class="edit-request-name" v-if="editing">
                      <input v-on:keyup.enter="save_title" id="editNameInput" class="edit-request-name-input" v-model="item.nomeSolicitacao">
                      <span v-if="item.nomeSolicitacao" @click="save_title" title="Salvar" class="material-icons edit-request-name-icon">save</span>
                    </div>
                </span>
                <span v-if="new_product" class="ressuply-products__header-title not-print">
                  Adicionar Produtos na Requisição: {{ quotation }}
                </span>
                <!-- Hidden title only for print -->
                <div style="display: flex;">
                  <img :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')" style="width: 135px; height: 70px; margin-right: 25px;" alt="" class="show-print">
                  <span hidden style="font-size: 2.5vw;" class="ressuply-products__header-title show-print">
                    Produtos da Requisição: {{ quotation }}
                    <div>{{item.nomeLoja}}</div>
                  </span>
                </div>


                <span @click="closeDialog" class="material-icons ressuply-products__closebtn-icon not-print">
                  cancel
                </span>
            </div>
            <div class="ressuply-products__actions__div1-container" v-if="!new_product">
              <div class="ressuply-products__actions__container not-print">
                <StandardInput title="Nome do produto" class="resupply-filter-select" :type="'text'" :action="value => productFilter = value" />
                <StandardInput title="Categoria" class="resupply-filter-select" :type="'select'" :list="my_categories" :action="value => categoryFilter = value" />
                  <div v-if="!new_product" @click="closeDialog" class="ressuply-products__actions__wrapper">
                    <span class="material-icons ressuply-products__actions-icon">
                      arrow_back
                    </span>
                    <span class="ressuply-products__actions-title">
                      Voltar
                    </span>
                  </div>
                  <div v-if="!new_product" @click="toggle_my_products" class="ressuply-products__actions__wrapper">
                    <span class="material-icons ressuply-products__actions-icon">
                      add_circle_outline
                    </span>
                    <span class="ressuply-products__actions-title">
                      Produtos
                    </span>
                  </div>
                  <div @click="save" class="ressuply-products__actions__wrapper">
                    <span class="material-icons ressuply-products__actions-icon">
                      save
                    </span>
                    <span class="ressuply-products__actions-title">
                      Salvar
                    </span>
                  </div>
                <div v-on:click="showMore = !showMore"  class="ressuply-products__actions__wrapper">
                  <span class="material-icons ressuply-products__actions-icon">
                    more
                  </span>
                  <span class="ressuply-products__actions-title">
                    Mais
                  </span>
                  <div v-on:click="showMore = !showMore" v-if="showMore" class="ressuply-more-container">
                    <div class="ressuply-more-body">
                      <p class="ressuply-more-line" @click="print">Imprimir</p>
                      <p class="ressuply-more-line" @click="applySugestion">Aplicar Sugestões</p>
                      <p class="ressuply-more-line" @click="extract">Gerar nova requisição</p>
                      <p class="ressuply-more-line" @click="exportProducts">Exportar produtos</p>
                    </div>
                  </div>
                </div>
                <div v-on:click="showMore = !showMore" v-if="showMore" class="ressuply-more-layer"></div>
              </div>
              <div class="ressuply-products__actions__container not-print mobile-buttons-container">
                
                
                
                
                <div class="resupply-check-container" v-on:click="directOrder = !directOrder">
                  <span v-if="!directOrder" style="color: var(--primary-color);" class="material-icons-outlined">check_box_outline_blank</span>
                  <span v-else style="color: var(--primary-color);" class="material-icons-outlined">check_box</span>
                  Apenas itens de pedido direto
                </div>
            </div>
            <div class="ressuply-products-filter">

            </div>
            </div>
            <div v-if="new_product" class="ressuply-products__actions__div2-container not-print">
              <div class="ressuply-products__search-product__container">
              <div class="ressuply-products__search-product__search-wrapper">
                <span class="ressuply-products__search-product__search-title">
                  Buscar Produto
                </span>
              </div>
              <div class="ressuply-products__search-product__input-wrapper">
                <div class="ressuply-products__search-product__input-container">
                  <input placeholder="Digite o nome do produto"
                         v-model="product_name_filter"
                         @keyup="searchProduct()"
                         class="ressuply-products__search-product__search-input"
                         type="text"/>
                  <span @click="searchProduct()" class="material-icons ressuply-products__search-product__search-icon">
                    search
                  </span>
                </div>
                <div class="ressuply-products__actions__div2-container not-print">
                <div v-if="!new_product" @click="closeDialog" class="ressuply-products__actions__wrapper">
                  <span class="material-icons ressuply-products__actions-icon">
                    arrow_back
                  </span>
                  <span class="ressuply-products__actions-title">
                    Voltar
                  </span>
                </div>
                <div v-if="!new_product" @click="closeDialog" class="ressuply-products__actions__wrapper">
                  <span class="material-icons ressuply-products__actions-icon">
                    add_circle_outline
                  </span>
                  <span class="ressuply-products__actions-title">
                    Adicionar Produtos
                  </span>
                </div>
                <div v-if="new_product" @click="toggle_my_products" class="ressuply-products__actions__wrapper">
                  <span class="material-icons ressuply-products__actions-icon">
                    arrow_back
                  </span>
                  <span class="ressuply-products__actions-title">
                    Voltar
                  </span>
                </div>
                <div  v-if="new_product" @click="showNewProductModal = true" class="ressuply-products__actions__wrapper">
                  <span class="material-icons ressuply-products__actions-icon">
                    add_circle
                  </span>
                  <span class="ressuply-products__actions-title">
                    Novo Produto
                  </span>
                </div>
               </div>
              </div>
             </div>
            </div>
        <cc-loader v-if="loading" style="margin-top: 20vh; max-height: none;"/>
        <div v-else>
          <div>
              <div v-show="!new_product" class="ressuply-products__table-container">
                <table class="ressuply-products__table-wrapper">
                  <thead class="ressuply-products__table-thead desktop">
                    <tr class="ressuply-products__table-thead-tr">
                      <td class="ressuply-products__table-td not-print  ressuply-products__table-check-width">
                          <cc-checkbox
                              @change="(evt) => extract_all(evt.target.checked)" />
                      </td>
                      <td class="ressuply-products__table-td not-print"></td>
                      <td class="ressuply-products__table-td ressuply-products__table-code-width">Código</td>
                      <td class="ressuply-products__table-td ressuply-products__table-name-width">Nome</td>
                      <td class="ressuply-products__table-td ressuply-products__table-pack-width">Embalagem</td>
                      <td class="ressuply-products__table-td ressuply-products__table-qtd-width">Qtd.</td>
                      <td class="ressuply-products__table-td ressuply-products__table-est-width">Estq.</td>
                      <td class="ressuply-products__table-td ressuply-products__table-qtdp-width">Qtd. Pdr.</td>
                      <td class="ressuply-products__table-td ressuply-products__table-sug-width">Sugst.</td>
                    </tr>
                  </thead>
                  <tbody class="ressuply-products__table-tbody">


                    <tr v-for="(item, idx) in visible_products()"
                        :key="item.idProduto"
                        class="ressuply-products__table-tbody-tr"
                        :class="getSpecialBackground(idx)">
                      <!-- desktop -->
                      <td class="not-print  ressuply-products__table-check-width desktop">
                          <cc-checkbox
                              @change="(evt) => item.extract = evt.target.checked"
                              :checked="item.extract" />
                      </td>
                      <td class=" desktop ressuply-products__table-td not-print">
                          <span @click="remove(item)" class="material-icons ressuply-products__table__delete-icon">delete_outline</span>
                      </td>
                      <td class="ressuply-products__table-td desktop">{{ item.pro_ean }}</td>
                      <td class="ressuply-products__table-td desktop">{{ item.pro_descricao }}</td>
                      <td class="ressuply-products__table-td desktop" style="display: flex; align-items: center;">
                        {{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}
                        <i class="far fa-edit" @click="edit_wrap(item)" v-if="can('PRODUCT', 'DELETE') && item.pro_generico == 0"></i>
                      </td>
                      <td class="ressuply-products__table-td show-print desktop">{{item.quantidade}}</td>
                      <td class="ressuply-products__table-td not-print desktop">
                        <div class="ressuply-products__edit-amount-wrapper not-print desktop">
                          <input min="0"
                                type="number"
                                ref="amount"
                                v-model="item.new_amount"
                                class="product-amount text-center not-print focused-input"
                                :class="{ active : item.saved == true, error: item.error }"
                                data-visible="true"
                                :data-idx="idx"
                                @keydown="handle_navigation($event, item)"
                                @blur="handle_navigation($event, item)"
                                :key="'qtd' + item.prod_id"/>
                          <span @click="handle_navigation($event, item)" class="material-icons ressuply-products__edit-amount-icon not-print">
                            add
                          </span>
                        </div>
                      </td>

                      <td class="ressuply-products__table-td not-print desktop">
                        <div class="ressuply-products__edit-amount-wrapper not-print">
                          <input min="0"
                                type="number"
                                v-model="item.estoque"
                                style="border-radius: 5px;"
                                class="product-amount text-center not-print focused-input"
                                :class="{ active : item.savedEstoque }"
                                v-on:change="estoqueUpdate(item)"
                                :disabled="!item.qtdEdit"
                                v-on:keyup.enter="saveQTD(item)"
                                data-visible="true"/>
                               <span v-if="!item.qtdEdit" class="material-icons-outlined fa-edit2" v-on:click="editQTD(item)">drive_file_rename_outline</span>
                               <span v-else class="material-icons-outlined fa-edit2" style="color: var(--primary-color);" v-on:click="closeEdit(item)">save</span>
                        </div>
                      </td>

                      <!-- <td class="ressuply-products__table-td">{{item.estoque || 0}}</td> -->

                      <td class="ressuply-products__table-td not-print desktop">
                        <div class="ressuply-products__edit-amount-wrapper not-print">
                          <input min="0"
                                type="number"
                                v-model="item.qtd_padrao"
                                style="border-radius: 5px;"
                                class="product-amount text-center not-print focused-input"
                                :class="{ active : item.saveQTD }"
                                v-on:keyup.enter="saveQTD(item)"
                                v-on:change="QTDUpdate(item)"
                                :disabled="!item.qtdEdit"
                                data-visible="true"/>
                        </div>
                      </td>
                      <!-- <td class="ressuply-products__table-td">{{item.qtd_padrao || 0}}</td> -->
                      <td class="ressuply-products__table-td desktop">{{getSugestionPrice(item)}}</td>

                      <!-- mobile -->
                      <td class="mobile mobile-name-row not-print">
                        {{ item.pro_descricao }}
                        <cc-checkbox
                              @change="(evt) => item.extract = evt.target.checked"
                              :checked="item.extract" />
                        <span @click="remove(item)" class="material-icons ressuply-products__table__delete-icon">delete_outline</span>
                      </td>
                      <td class="mobile mobile-table-row not-print">
                        <div class="mobile-input-size">
                          <p class="mobile-table-title">Código</p>
                          {{ item.pro_ean }}
                        </div>
                        <div class="mobile-center mobile-input-size">
                          <p class="mobile-table-title">Embalagem</p>
                          <div>
                            {{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}
                            <i class="far fa-edit" @click="edit_wrap(item)" v-if="can('PRODUCT', 'UPDATE') && item.pro_generico == 0"></i>
                          </div>
                        </div>
                        <div class="mobile-end mobile-input-size">
                          <p class="mobile-table-title">Sugestão</p>
                          {{item.pro_sugestao || 0}}
                        </div>
                      </td>
                      <td class="mobile mobile-table-row not-print">
                        <div class="mobile-input-size">
                          <p class="mobile-table-title">	Qtd.</p>
                          <div class="ressuply-products__edit-amount-wrapper not-print">
                            <input min="0"
                                  type="number"
                                  v-model="item.new_amount"
                                  class="product-amount text-center not-print focused-input"
                                  :class="{ active : item.saved == true, error: item.error }"
                                  data-visible="true"
                                  @keydown="handle_navigation($event, item)"
                                  @blur="handle_navigation($event, item)"
                                  :key="'qtd' + item.prod_id"/>
                            <span @click="handle_navigation($event, item)" class="material-icons ressuply-products__edit-amount-icon not-print">
                              add
                            </span>
                          </div>
                        </div>
                        <div class="mobile-input-size mobile-center">
                          <p class="mobile-table-title">Estq.	</p>
                          <div class="ressuply-products__edit-amount-wrapper not-print">
                            <input min="0"
                                  type="number"
                                  v-model="item.estoque"
                                  style="border-radius: 5px;"
                                  class="product-amount text-center not-print focused-input"
                                  :class="{ active : item.savedEstoque }"
                                  v-on:change="estoqueUpdate(item)"
                                  :disabled="!item.qtdEdit"
                                  v-on:keyup.enter="saveQTD(item)"
                                  data-visible="true"/>
                                <span v-if="!item.qtdEdit" class="material-icons-outlined fa-edit2" v-on:click="editQTD(item)">drive_file_rename_outline</span>
                                <span v-else class="material-icons-outlined fa-edit2" style="color: var(--primary-color);" v-on:click="closeEdit(item)">save</span>
                          </div>
                        </div>
                        <div class="mobile-input-size mobile-end">
                          <p class="mobile-table-title">Qtd. Pdr.		</p>
                          <div class="ressuply-products__edit-amount-wrapper not-print">
                            <input min="0"
                                  type="number"
                                  v-model="item.qtd_padrao"
                                  style="border-radius: 5px;"
                                  class="product-amount text-center not-print focused-input"
                                  :class="{ active : item.saveQTD }"
                                  v-on:keyup.enter="saveQTD(item)"
                                  v-on:change="QTDUpdate(item)"
                                  :disabled="!item.qtdEdit"
                                  data-visible="true"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div v-show="new_product" class="ressuply-products__table2-container">
              <table class="ressuply-products__table-wrapper">
                  <thead class="ressuply-products__table-thead desktop">
                    <tr class="ressuply-products__table-thead-tr">
                      <td class="ressuply-products__table-td">Código</td>
                      <td class="ressuply-products__table-td">Nome</td>
                      <td class="ressuply-products__table-td">Embalagem</td>
                      <td class="ressuply-products__table-td not-print">Quantidade</td>
                    </tr>
                  </thead>
                  <tbody class="ressuply-products__table-tbody">
                    <tr v-for="(item, idx) in my_products"
                        :key="'prod-' + item.pro_id"
                        :class="getLineClass(item, idx)"
                        class="ressuply-products__table-tbody-tr">

                      <!-- desktop   -->
                      <td class="ressuply-products__table-td desktop">{{ item.pro_ean }}</td>
                      <td class="ressuply-products__table-td desktop">{{ item.pro_descricao }}</td>
                      <td class="ressuply-products__table-td desktop">{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</td>
                      <td class="ressuply-products__table-td not-print desktop">
                        <div class="ressuply-products__edit-amount-wrapper">
                          <input min="0"
                                type="number"
                                :data-idx="idx"
                                ref="new-amount"
                                class="product-amount text-center w-25 not-print focused-input"
                                :class="{ active : item.saved }"
                                v-model.number="item.new_amount"
                                data-visible="true"
                                @keydown="handle_my_products_navigation($event, item)"
                                @blur="handle_my_products_navigation($event, item)"
                                :key="'qtd' + item.prod_id"/>
                        <span @click="handle_my_products_navigation($event, item)" class="material-icons ressuply-products__edit-amount-icon not-print">
                          add
                        </span>
                        </div>
                      </td>

                      <!-- mobile -->
                      <td class="mobile mobile-name-row">
                        {{ item.pro_descricao }}
                      </td>
                      <td class="mobile mobile-table-row">
                        <div>
                          <p class="mobile-table-title">Código</p>
                          {{ item.pro_ean }}
                        </div>
                        <div class="mobile-center">
                          <p class="mobile-table-title">Embalagem</p>
                          {{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}
                        </div>
                        <div class="mobile-input-size mobile-end">
                          <p class="mobile-table-title">Quantidade</p>
                          <div class="ressuply-products__edit-amount-wrapper">
                            <input min="0"
                                  type="number"
                                  :data-idx="idx"
                                  class="product-amount text-center not-print focused-input"
                                  :class="{ active : item.saved }"
                                  v-model.number="item.new_amount"
                                  data-visible="true"
                                  @keydown="handle_my_products_navigation($event, item)"
                                  @blur="handle_my_products_navigation($event, item)"
                                  :key="'qtd' + item.prod_id"/>
                          <span @click="handle_my_products_navigation($event, item)" class="material-icons ressuply-products__edit-amount-icon not-print">
                            add
                          </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                          <div class="footer" :key="'footer'">
                              <cc-pagination :key="'pag'"
                                            :items_by_page_value="items_by_page_value"
                                            :total="total_items"
                                            :page="page"
                                            :page_limit="5"
                                            classes="orange"
                                            v-on:change="update_page($event)">
                              </cc-pagination>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ProductWrapModal v-if="open_edit_wrap && current_product" :product="current_product" @close="open_edit_wrap = false;getProducts()" />
  <new-product-modal v-if="showNewProductModal"
            		   :product="{}"
                   :closeDialog="() => showNewProductModal = null"
                   :categories="[]"
                   @emit_product="() => {}"
            		   @reload_products="()=>{}"
            		   @close="showNewProductModal = null" />
</div>
</template>

<script>

import QuotationService from "@/services/QuotationService";
import RequestsService from "@/services/v2/requests.service";
import RequestsServiceV3 from "@/services/v3/request.service";
import ProductWrapModal from "@/modals/product-wrap/product-wrap.modal"
import { elementNavigationmMixin } from "@/mixins/navigation.mixin";
import Pagination from "@/components/cliente/base-components/Pagination";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import NewProductModal from "@/modals/new-product/new-product.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState } from "vuex";

export default {
    components: { ProductWrapModal, ccPagination : Pagination , StandardInput, 'new-product-modal': NewProductModal},
    mixins: [ elementNavigationmMixin, typingRemoteRequestControlMixin, loaderMixin, perm_mixin ],
    props: ["quotation", "closeDialog", "item"],
    data() {
        return {
            focused: false,
            products: [],
            savedProducts: [],
            loading: true,
            open_edit_wrap: false,
            showMore: false,
            new_product: null,
            showNewProductModal: false,
            directOrder: false,
            page: 1,
            my_products: [],
            my_categories: [{value: undefined, text: 'TODOS'}],
            productFilter: '',
            categoryFilter: '',
            product_name_filter: null,
            total_items: null,
            items_by_page_value: 30,
            editing: false,
            svc: new RequestsService(),
            searchTime: undefined,
            svc_v3: new RequestsServiceV3(),
            service: new QuotationService(),
            modal: {
                title: `Produtos de ressuprimento #${this.quotation}`,
                subtitle: "Gerencie os produtos de ressuprimento",
                icon_title: "fas fa-boxes",
                unpermitted_message:
                    "Aceite o termo de consentimento para continuar.",
                permitted: true,
                cancel_text: "voltar",
                style: {
                    width: "90%",
                },
            },
        };
    },
    mounted() {
        this.getProducts();
        this.load_my_products();
    },
    methods: {
        closeEdit(item){
          item.qtdEdit = false
          this.saveQTD(item)
          this.$forceUpdate()
        },
        getSugestionPrice(item){
          if (item.qtd_padrao - item.estoque > 0){
            if (item.pro_generico) return item.qtd_padrao - item.estoque
            return Math.round((item.qtd_padrao - item.estoque) / item.pro_embalagem.split(' ')[1])
          } else return 0
        },
        editQTD(item) {
          item.qtdEdit = true
          this.$forceUpdate()
        },
        QTDUpdate(item) {
          item.QTDChange = true
          this.$forceUpdate()
        },
        estoqueUpdate(item) {
          item.estoqueChange = true
          this.$forceUpdate()
        },
        saveQTD(item){
          var quotationService = new QuotationService();
          return quotationService.updateQuotationProductAmount(this.quotation, item).then(() => {
              if(item.estoqueChange) item.savedEstoque = true
              if(item.QTDChange) item.saveQTD = true
              this.$forceUpdate()
          })
        },
        editName(){
          this.editing = true
          setTimeout(() => document.getElementById("editNameInput").focus(), 100)
        },
        exportProducts(){
          let text = ''
          this.visible_products().forEach(t => text += t.pro_ean +','+ t.new_amount +'\n')
          var blob = new Blob([text], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, 'products.txt');
        },
        extract_all(ex) {
            this.visible_products().forEach(i => i.extract = !!ex)
            this.$forceUpdate()
        },
        any_to_extract() {
            return this.visible_products().some(i => i.extract)
        },
        save_title() {
          if (!this.item.nomeSolicitacao) return
            this.loading=true
            this.svc_v3.update_resupply_title(this.quotation, this.item.nomeSolicitacao).then(resp => {
                this.editing = false
                this.loading=false
                this.snack('Atualizado com sucesso!')
            }).catch((error) => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        extract() {
            if(!this.any_to_extract()) {
                this.present_error("Selecione ao menos 1 item para proseguir!")
                return
            }
            let items = this.visible_products().filter(p => p.extract)
                .map(i => { return { ...i, id: i.idProduto, quantity: i.quantidade }})
            this.confirm_action({
				message: "Também excluir produtos selecionados da requisição atual?",
                cancel_text: "Não",
                confirm_tex: "Sim",
                deny_text: "Enviar sem exclusão",
                deny_callback: (result) => {
                    this.present_loader("Enviando requisição...")
                    this.svc_v3.extract_resupply(this.quotation, { items })
                    .then(this.getProducts)
                    .then((resp) => {
                        this.$emit("loadRessuply");
                        this.dismiss_loader()
                    })
                },
				callback: (result) => {
                    this.present_loader("Enviando requisição...")
                    this.svc_v3.extract_resupply(this.quotation, { items, remove_origin: true })
                    .then(this.getProducts)
                    .then((resp) => {
                        this.$emit("loadRessuply");
                        this.dismiss_loader()
                    })
				}
			})
        },
       getSpecialBackground (idx) {
         if (idx % 2 != 0) return 'page-table-line-special'
       },
        visible_products() {
          let resp = this.products.filter(p => !p.deleted)
          if (this.productFilter) resp = resp.filter(p => p.pro_descricao.toLowerCase().includes(this.productFilter.toLowerCase()))
          if (this.categoryFilter) resp = resp.filter(p => p.cat_id == this.categoryFilter.cat_id)
          if (this.directOrder) resp = resp.filter(p => p.pro_pedido_direto)
          return resp
        },
        cancelSearch () {
            if (this.cancelSource) {
                this.cancelSource.cancel(
                    "Start new search, stop active search"
                );
            }
        },
        toggle_my_products() {
            this.new_product = this.new_product ? null : {};
			/* if(this.new_product) {
                gsap.fromTo('.slot-1', { x: "0%" }, { x: "-120%", duration: 0.5, ease: "back.out(1)" })
                gsap.fromTo('.slot-2', { x: "120%" }, { x: "-100%", duration: 0.5, ease: "back.out(1)" })
			} else {
                gsap.fromTo('.slot-1', { x: "120%" }, { x: "0%", duration: 0.5, ease: "back.out(1)" })
                gsap.fromTo('.slot-2', { x: "0%" }, { x: "-220%", duration: 0.5, ease: "back.out(1)" })
			} */
			this.$forceUpdate()
        },
        add_product(prod) {

            let data = { quantidade: prod.new_amount, prod_id: prod.pro_id, flag_generico: prod.pro_generico }

            if(isNaN(prod.new_amount) || prod.new_amount == prod.quantidade)return Promise.resolve()

            return this.svc.add_product_to_resupply_request(this.quotation, data).then(() => {
                prod.saved = true
                const notification = {
                    type: 'success',
                    message: 'Produto adicionado com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                prod.saved = true
                prod.quantidade = prod.new_amount
                this.savedProducts.push(prod)
                this.getProducts()
            })
        },
        handle_navigation($event, prod) {
            this.navigate($event, {
            ref: 'amount',
                    callback: {
                        function: this.update_one,
                        params: prod
                    }
          })
        },
        handle_my_products_navigation($event, product) {
			this.navigate($event, {
				ref: 'new-amount',
                callback: {
                    function: this.add_product,
                    params: product
                }
			})
		},
        update_page(page) {
			this.page = page;
			this.load_my_products();
		},
        edit_wrap(product) {
            product.embalagem = product.pro_embalagem
            product.descricaoProduto = product.pro_descricao
            this.current_product = product
            this.open_edit_wrap = true
        },
        close() {
            this.$emit("close");
        },
        /* group_name() {
          return localStorage.getItem("grupo")
        }, */
        getProducts() {
            return this.service
                .getProductsResupply(this.quotation)
                .then((response) => response.data)
                .then((data) => {
                  this.loading = false;
                    this.products = data.produtos;
                    this.products.forEach((p) => {
                      p.new_amount = p.quantidade
                      this.savedProducts.forEach(element => {
                          if (element.pro_id == p.pro_id) p.saved = true
                      });
                    });
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        searchProduct(){
          const time = new Date()
            this.searchTime = time
            setTimeout(() => {
              if (this.searchTime == time) this.load_my_products()
            }, 500);
        },
        load_my_products() {
            this.cancelSearch(() => {
                this.loading = true;
            });
            const params = {
                nomeproduto: this.product_name_filter,
                page: this.page,
                deleted: 0,
            };
            this.service
                .getProdutosClientePorPagina(params, this.cancelSource?.token)
                .then((data) => {
                    this.my_products = data.data.produtos.data;
                    data.data.categorias.forEach(element => {
                      if (element.cat_excluido != '1' && element.cat_status != '0') this.my_categories.push({value:element, text: element.cat_descricao})
                    });
                    
                    this.page = data.data.produtos.current_page;
                    setTimeout(() => {
                      this.total_items = data.data.produtos.total;
                    }, 200);
                    //this.totalPaginas = data.data.produtos.last_page;
                    this.products.forEach((v) => (v.expanded = false));
                })
                .catch((error) => {
                    this.loading = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        applySugestion() {
            this.products
                .forEach((p) => {
                  const result = this.getSugestionPrice(p)
                  if (result == 0) return
                  p.new_amount =  result
                  p.quantidade = result
                });
            this.$forceUpdate();
        },
        save() {
            this.loading = false;
            let items = this.products.map(p => { return { "quantidade" : ("" + p.quantidade), "pro_id" : p.pro_id}  });
            var quotationService = new QuotationService();
            quotationService.updateQuotationProductsAmount(this.quotation, items)
                .then(() => {
                    this.loading = true;
                    this.closeDialog()
                    this.$swal.fire(
                      'Sucesso!',
                      'Valores atualizados com sucesso.',
                      'success'
                    );
                });
        },
        update_one(product) {
          if(isNaN(product.quantidade) || product.new_amount == product.quantidade)return Promise.resolve()
            var quotationService = new QuotationService();
            return quotationService.updateQuotationProductAmount(this.quotation, {
                pro_id: product.pro_id,
                quantidade: product.new_amount
            }).then(() => {
                product.saved = true
                product.quantidade = product.new_amount
                this.$forceUpdate()
            }).catch(() => {
                product.error = true
                this.$forceUpdate()
            })
        },
        print() {
            setTimeout(() => {

                var conteudoModal = window.document.getElementById("modalBodyPrint").innerHTML;

                var conteudoImpressao =
                    "<html>" +
                    "<head>" +
                    '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css">' +
                    '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
                    "<title>" +
                    "</title>" +
                    "<style> \
                    @media print {    \
                        .not-print, .not-print * { \
                            display: none !important; \
                        } \
                        .show-print, .show-print * { \
                            display: table-cell !important; \
                        } \
                        .ressuply-products__table-check-width{padding-left: 1%;}\
                        .ressuply-products__table-code-width{width: 9%;}\
                        .ressuply-products__table-name-width{flex: 1;}\
                        .ressuply-products__table-pack-width{width: 16%;}\
                        .ressuply-products__table-qtd-width{width: 8%;}\
                        .ressuply-products__table-est-width{width: 8%;}\
                        .ressuply-products__table-qtdp-width{width: 9%;}\
                        .ressuply-products__table-sug-width{width: 8%;}\
                        .ressuply-products__header-title{margin-bottom: 20vh} \
                        .ressuply-products__table-wrapper {width: 100vw} \
                        .ressuply-products__table-thead-tr {border: 2px solid lightgray; font-size: 1.7vw;} \
                        .ressuply-products__table-td {border-right: 2px solid lightgray; padding-left: 1vw; font-size: 13px; text-transform: lowercase} \
                        .ressuply-products__table-tbody-tr {border: 2px solid lightgray; font-size: 2vw} \
                        .ressuply-products__table-container{margin-top: -15vh} \
                        @media print { @page{ margin: 3%;   }}\
                    } \
                </style>" +
                    "</head>" +
                    "<body>" +
                    conteudoModal +
                    "</body>";

                let name = "_blank";
                let specs = [
                    "fullscreen=yes",
                    "titlebar=yes",
                    "scrollbars=yes",
                ];
                let replace = true;

                specs = !!specs.length ? specs.join(',') : '';

                var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);

                telaImpressao.document.write(conteudoImpressao);

                setTimeout(() => {
                    telaImpressao.document.close();
                    telaImpressao.focus();
                    telaImpressao.print();
                    telaImpressao.close();
                }, 1500);

                this.imprimindo = false;
            }, 500);
        },
        getLineClass (item, idx) {
          let resp = ''
          resp += this.getSpecialBackground (idx)
          if (item.focused == true) resp += ' active'
          return resp
        },
        remove(product){
            var quotationService = new QuotationService();
            quotationService.remove_product_ressuply(this.quotation, product.pro_id)
                .then(() => {
                    product.deleted = true
                    this.$emit("loadRessuply");
                    this.$forceUpdate()
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        }
    },
    computed: {
        ...mapState(["whitelabel"])
    },
};
</script>
<style lang="scss" scoped>
body {
  font-family: Rubik;
}
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}
.modal-content-container {
  background-color: #fff;
  border-radius: 12px;
  width: 100vw;
  min-height: 110vh;
}
.active-input {
  border: 1px solid red;
}
.inactive-input {
  border: 1px solid purple;
}
.ressuply-products__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.ressuply-products__header-title {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 2em;
}
.ressuply-products__closebtn-icon {
  color: #FFFFFF;
  font-size: 2.5em;
  cursor: pointer;
}

.ressuply-products__actions__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 1.5vh 2vw;
}
.ressuply-products-filter{
  margin: 1.5vh 2vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ressuply-products__actions__div2-container {
  display: flex;
  align-items: center;
}
.ressuply-products__actions__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  margin-left: 1vw;
  width: 12vw;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
}
.ressuply-products__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.ressuply-products__actions-title {
  font-weight: 400;
  font-size: 1.1vw;
  letter-spacing: 2%;
  color: #505050;
}

.ressuply-products__table-container {
  margin: 1.5vh 2vw;
  overflow-x: auto;
  max-height: 75vh;
}
.ressuply-products__table2-container {
  margin: 1vh 2vw;
  overflow-x: auto;
  max-height: 70vh;
}
.ressuply-products__table-wrapper {

}
.ressuply-products__table-thead {}
.ressuply-products__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-weight: 400;
}
.ressuply-products__table-td {
  font-family: Rubik;
  color: #605F5F;
  font-size: 1em;
  font-weight: 300;
}
.ressuply-products__table-check-width{
  padding-left: 1%;
}
.ressuply-products__table-code-width{
  width: 10%;
}
.ressuply-products__table-name-width{
  flex: 1;
}
.ressuply-products__table-pack-width{
  width: 11%;
}
.ressuply-products__table-qtd-width{
  width: 10%;
}
.ressuply-products__table-est-width{
  width: 9%;
}
.ressuply-products__table-qtdp-width{
  width: 9%;
}
.ressuply-products__table-sug-width{
  width: 9%;
}
.resupply-filter-select{
  width: 20%;
}
.resupply-check-container{
  display: flex;
  align-items: center;
  gap: 1vw;
  font-weight: 400;
  font-size: 1.1vw;
  letter-spacing: 2%;
  color: #505050;
  cursor: pointer;
}
.ressuply-products__table-tbody {}
.ressuply-products__table-tbody-tr {}
.ressuply-products__table__delete-icon {
  color: #CD2A2A;
  cursor: pointer;
}
.ressuply-products__edit-amount-wrapper {
  display: flex;
  align-items: center;
}
.ressuply-products__edit-amount-icon {
  color: #fff;
  background-color: #E5E5E5;
  border: 1.5px solid #E5E5E5;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
.focused-input:focus-visible + .ressuply-products__edit-amount-icon {
  background-color: var(--primary-color);
}
.product-amount {
  border-radius: 5px 0px 0px 5px;
  border: 2px solid #E5E5E5;
  width: 50%;
  text-align: center;
  outline: none;
}
.product-amount.active {
  background-color: #9fff9f73;
  color: green;
}
.show-print{
  display: none;
}
.ressuply-products__pagination-container {}
.ressuply-products__search-product__container {
  margin: 1vh 2vw;
}
.ressuply-products__search-product__title-wrapper {
  margin-bottom: 1vh;
}

.ressuply-products__search-product__search-wrapper {}
.ressuply-products__search-product__input-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.ressuply-products__search-product__input-container {
  display: flex;
  align-items: center;
}
.ressuply-products__search-product__search-icon {
  position: relative;
  font-size: 2em;
  left: -40px;
  cursor: pointer;
  color: #898989;
}
.ressuply-products__search-product__search-input {
  width: 25vw;
  color: #A1A1A1;
  font-weight: 400;
  font-size: 1.2em;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 1vw;
}

.ressuply-more-container{
  position: relative;
  right: -4vw;
  top: 3vh;
}
.ressuply-more-body{
  position: absolute;
  right: 0;
  margin-top: 1vh;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  padding: 0.5vh 0;
  border-radius: 10px;
  z-index: 2;
}
.ressuply-more-layer{
  position: absolute;
  top: 0;
  left: 0;
  height: 125vh;
  width: 100%;
}
.ressuply-more-line{
  width: 13vw;
  text-align: right;
  margin: 0;
  padding: 0.5vh 1vw;
  cursor: pointer;
}
.ressuply-more-line:hover{
  background-color: white;
}
.ressuply-products__search-product__search-title {
  color: #898989;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-bottom: 0.5vh;
}
.active {
  background-color: #9fff9f73;
  color: black;
  border-color: #9fff9f73;
}
.error {
  background-color: #ff000059;
  color: white;
  border-color: #ff000059;
}
.focused-input:focus {
  border: 1.5px solid var(--primary-color);
}
.page-table-line-special {
  background: #F7F7F7;
}
.edit-request-name{
  display: flex;
  align-items: center;
  margin-left: 1vw;
}
.edit-request-name-input{
  background-color: white;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 28px;
  color: #605F5F;
  padding: 0 1vw;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}
.edit-request-name-icon{
  font-size: 1.3em;
  margin-left: 1.5vw;
  cursor: pointer;
}
.mobile{display: none;}

@media only screen and (max-width: 750px) {

  .mobile{display: flex;}
  .ressuply-products__table-container {
    max-height: 50vh;
    margin: 10px;
  }
  .ressuply-products__actions__container{
    flex-wrap: wrap;
  }
  .resupply-filter-select{
    width: 47%;
  }
  .ressuply-products__table2-container {
    max-height: 80vh;
    margin: 10px;
  }
  .modal-mask {}
  .modal-content-container {
    height: 100vh;
    width: 115vw;
  }
  .ressuply-products__actions__div2-container {
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    width: 100%;
  }
  .ressuply-products__actions__div1-container {
    margin: 0px 5px
  }
  .ressuply-products__search-product__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ressuply-products__search-product__title-wrapper {
    margin-bottom: 0px;
    margin: 10px 0px;
  }
  .ressuply-products__search-product__add-title {
    font-size: 18px;
  }
  .ressuply-products__search-product__search-title {
    font-size: 16px;
  }
  .ressuply-products__search-product__input-container {
    margin-bottom: 5px;
    width: 100%;
  }
  .ressuply-products__search-product__search-input {
    width: 100%;
    font-size: 12px;
  }
  .ressuply-products__actions-title {
    font-size: 14px;
  }
  .ressuply-products__actions-icon {
    font-size: 14px;
  }
  .ressuply-products__actions__wrapper {
    margin: 5px 0px;
    width: 48%;
  }
  .ressuply-products__header-title {
    font-size: 20px;
  }
  .ressuply-products__search-product__container {
    margin: 0px 5px;
    width: 100%;
  }
  .mobile-buttons-container{
    flex-wrap: wrap
  }
  .resupply-check-container{
    font-size: 1.4em
  }
  .mobile-name-row{
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1vh;
    gap: 4vw;
  }
  .mobile-center{
    text-align: center;
  }
  .mobile-end{
    text-align: end;
  }
  .mobile-table-title{
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
  }
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5vh;
    font-size: 1.3em;
  }
  .product-amount{
    width: 100%;
  }
  .mobile-input-size{
    width: 30%;
  }
  .ressuply-products__table-container {
    max-height: 70vh;
  }
  .ressuply-more-line{
    width: 30vw;
  }
  .ressuply-more-container{
    right: -18vw;
    top: 1vh;
  }
  .desktop{display: none !important}
}

@media print {
  .ressuply-products__table-pack-width{width: 5%}
}
    @import '@/sass/commons/_variables';

    .edit-amount input {
        margin: auto;
        width: 200px;
        text-align: center;
    }

    .fa-trash {
        cursor: pointer;
        font-size: 20px;
        color: red!important;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .fa-edit2 {
        margin-left: 10px;
        color: lightblue;
        cursor: pointer;
        font-size: 1.5em;
    }

    .header-title {
        padding-right: 23px;
        text-align: left;
        color: gray;
        font-size: 22px;
    }
    .active {
        background-color: #9fff9f73;
        color: black;
        border-color: #9fff9f73;
    }
    .error {
        background-color: #ff000059;
        color: white;
        border-color: #ff000059;
    }
    .actions {
        text-align: left;
        color: gray;
        display: flex;
        height: 100%;
        a {
            margin-left: 10px;
        }
        .inner-button-loader {
            width: 100%;
            img {
                height: 1.4rem;
            }
        }
    }
    .fa-trash {
        color: $brand-danger;
    }
    .window {
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        .amount {
            width: 10%;
        }
        .add-prod-icon {
            margin-right: 10px;
            color: $primary-color;
        }
        /* .slot-1, .slot-2 {
            min-width: 100%;
        }
        .slot-2 {
            transform: translate(120%, 0px);
        } */
        input[type="number"] {
            -moz-appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &.product-amount {
                border-radius: 5px;
                border: 3.1px solid #0000ff4d;
                width: 25%;
                text-align: center;
                margin-left: 23px;
                margin-right: 8px;
                outline: none;
                &.active {
                    background-color: #9fff9f73;
                    color: green;
                }
            }
        }
    }
</style>
