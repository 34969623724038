<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="import-resupply__main-container">
        <div class="manage-categories__header-container">
          <span class="manage-categories__header-title">
            Importar Cotação
          </span>
          <span @click="closeDialog" class="material-icons manage-categories__closebtn-icon">
            cancel
          </span>
       </div>
       <cc-loader-full v-show="loading" :text="'Aguarde... Essa operação pode levar alguns minutos.'" />
       <div class="import-resupply__inputs-container">
         <div class="import-resupply__inputs-wrapper">
           <span class="import-resupply__inputs-title">Tipo de Importação</span>
           <cc-select-v2 class="import-resupply__import-type-input"
                         v-model="importType"
                         @select="checkOrders"
                         placeholder=""
                         open-direction="below"
                         :tracker="'code'"
                         :text="'label'"
                         :options="import_methods">
            </cc-select-v2>
         </div>
         <div v-if="importType && (importType.code !='VAREJOFACIL' && importType.code !='TXT')" class="import-resupply__inputs-wrapper">
           <!-- <span class="import-resupply__inputs-title">Selecione a empresa</span>
           <cc-select-v2 class="import-resupply__import-type-input"
                         v-model="storeSelect"
                         placeholder=""
                         open-direction="below"
                         :tracker="'code'"
                         :text="'label'"
                         :options="stores">
            </cc-select-v2> -->
            <StandardInputListFiltered style="margin-top: -5%;" class="import-resupply__import-type-input" :action="setStoreSelect" :title="'Selecione a empresa'" :width="'30vw'" :selectWidth="'29.6vw'" :list="stores"/>
         </div>
         <div v-if="importType && importType.code =='VAREJOFACIL'" class="import-resupply__inputs-wrapper">
           <span class="import-resupply__inputs-title">Selecione a empresa</span>
           <cc-select-v2 class="import-resupply__import-type-input"
                         v-model="storeSelect"
                         v-if="!all_stores"
                         open-direction="below"
                         placeholder=""
                         :tracker="'code'"
                         :text="'text'"
                         :options="stores">
           </cc-select-v2>
           <input v-if="all_stores" class="disabled-input" type="text" disabled>
           <CustomCheckboxV2 class="custom-checkbox" :label="'Enviar para todas empresas'" v-model="all_stores"/>
         </div>
        </div>
        <div v-if="storeSelect && (importType == null || importType.code == 'EXCEL' || importType.code == '15')" class="import-resupply__quotation-code-container">
          <div style="margin: 0 auto;" class="import-resupply__inputs-wrapper">
           <vue-dropzone ref="myVueDropzone" id="dropzone" style="width: 30vw;" class="dropzone" :useCustomSlot="true" :options="dropzoneOptions" v-on:vdropzone-success="successResponse" v-on:vdropzone-sending="sendingEvent" >
             <div class="dropzone-custom-content">
               <i class="fas fa-cloud-upload-alt"></i>
               <h5 class="dropzone-custom-title">Solte o arquivo excel para criar a solicitação</h5>
             </div>
           </vue-dropzone>
         </div>
        </div>
        <div v-if="(storeSelect && importType.code == 'VR') || importType && importType.code == 'TXT'" class="import-resupply__quotation-code-container">
          <div style="margin: 0 auto;" class="import-resupply__inputs-wrapper">
           <vue-dropzone ref="myVueDropzoneFile"
                         id="dropzoneFile"
                         style="width: 30vw;"
                         class="dropzone"
                         :useCustomSlot="true"
                         :options="dropzoneOptionsFile"
                         v-on:vdropzone-success="successResponseFile"
                         v-on:vdropzone-sending="sendingEvent" >
            <div class="dropzone-custom-content">
              <i class="fas fa-cloud-upload-alt"></i>
              <h5 class="dropzone-custom-title">Solte o arquivo para criar a solicitação</h5>
            </div>
          </vue-dropzone>
         </div>
        </div>
        <div v-if="(storeSelect || all_stores) && importType && importType.code =='VAREJOFACIL'" class="import-resupply__quotation-code-container">
          <div class="import-resupply__quotation-code-wrapper">
            <span class="import-resupply__inputs-title">Cod. da Cotação</span>
            <input class="import-resupply__quotation-code-input" :placeholder="'Cod. da Cotação'" v-model="quotation" type="number">
          </div>
        </div>
        <div class="import-resupply__buttons-container">
          <StandardButton v-if="quotation ? hasQuotationCode = true : hasQuotationCode = false" style="margin-left: 20px;" :action="importVarejoFacil" :text="'Salvar'"></StandardButton>
          <StandardButton v-else style="margin-left: 20px;" :disabled="true" :text="'Salvar'"></StandardButton>
          <StandardButton :action="closeDialog" :text="'Cancelar'"></StandardButton>
        </div>
      </div>
    </div>
  </div>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <cc-loader-full v-show="loading" :text="'Aguarde... Essa operação pode levar alguns minutos.'" />
            <div class="col-6">
                <label for="inputCodigo" class="control-label">Tipo de importação</label>
                <multiselect v-model="importType" @select="checkOrders" placeholder="" open-direction="below" track-by="code" label="label" selected-label="Selecionado" :options="import_methods" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-6 store-select" v-if="importType && (importType.code !='VAREJOFACIL' && importType.code !='TXT')">
                <label for="inputCodigo" class="control-label">Selecione a empresa</label>
                <multiselect v-model="storeSelect" open-direction="below" placeholder="" track-by="code" label="label" selected-label="Selecionado" :options="stores" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-6 store-select" v-if="importType && importType.code =='VAREJOFACIL'">
                <label for="inputCodigo" class="control-label">Selecione a empresa</label>
                <multiselect v-model="storeSelect" :disabled="all_stores" open-direction="below" placeholder="" track-by="code" label="label" selected-label="Selecionado" :options="stores" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                <cc-checkbox v-model="all_stores" :label="'Enviar para todas'" class="pt-3 pl-2" />
            </div>
            <div class="col-12 store-select pt-4" v-if="(storeSelect || all_stores) && importType && importType.code =='VAREJOFACIL'">
                <label for="inputCodigo" class="control-label">Cod. da Cotação</label>
                <cc-input :placeholder="'Cod. da Cotação'" :theme="'theme-green'" class="mt-1" v-model="quotation" />
            </div>
            <div class="pt-3 col-12" v-if="storeSelect && (importType == null || importType.code == 'EXCEL')">
                <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone" :useCustomSlot="true" :options="dropzoneOptions" v-on:vdropzone-success="successResponse" v-on:vdropzone-sending="sendingEvent" >
                    <div class="dropzone-custom-content">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <h5 class="dropzone-custom-title">Solte o arquivo excel para criar a solicitação</h5>
                    </div>
                </vue-dropzone>
            </div>
            <div class="pt-3 col-12" v-if="(storeSelect && importType.code == 'VR') || importType && importType.code == 'TXT'">
                <vue-dropzone
                    ref="myVueDropzoneFile"
                    id="dropzoneFile"
                    class="dropzone"
                    :useCustomSlot="true"
                    :options="dropzoneOptionsFile"
                    v-on:vdropzone-success="successResponseFile"
                    v-on:vdropzone-sending="sendingEvent" >
                    <div class="dropzone-custom-content">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <h5 class="dropzone-custom-title">Solte o arquivo para criar a solicitação</h5>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </cc-modal> -->
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import QuotationService from "@/services/QuotationService";
import VarejoFacilService from "@/services/VarejoFacilService";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import * as CONSTANTS from "@/constants/constants";
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFilteredImportExcel.vue'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import CustomCheckboxV2 from '@/components/ui/inputs/CustomCheckboxV2.vue'

export default {
    components: { vueDropzone: vue2Dropzone, StandardButton, CustomCheckboxV2, StandardInputListFiltered},
    props: {
      closeDialog: {
        type: Function,
      }
    },
    data() {
        return {
            hasQuotationCode: false,
            modal: {
                title: "importar cotação",
                permitted: true,
                submit: this.importVarejoFacil,
                submit_text: 'Enviar',
                subtitle: 'Aqui você pode importar sua requisição, agilizando a abertura da cotação',
                icon_title: 'fas fa-download',
                style: {
                    width: "70%"
                }
            },
            all_stores: false,
            import_methods: [
                {
                    code: "VAREJOFACIL",
                    label: "Sistema E.R.P"
                },
                {
                  code: "15",
                  label: "ENCARTE REDE"
                },
                {
                    code: "EXCEL",
                    label: "EXCEL"
                },
                {
                    code: "VR",
                    label: "Arquivo Txt"
                },
                {
                    code: "TXT",
                    label: "Arquivo Txt Personalizado"
                }
            ],
            stores: [],
            quotations: [],
            quotation: null,
            storeSelect: null,
            lojaSelectImportExcel: null,
            loading: false,
            service: new VarejoFacilService(),
            importType: null,
            dropzoneOptions: {
                url: `${CONSTANTS.API_URL}/importexcelcotacao`,
                timeout: 600000,
                paramName: "file",
                withCredentials: false,
                forceFallback:false,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                    'Cache-Control': null
                }
            },
            dropzoneOptionsFile: {
                url: `${CONSTANTS.API_URL}/importfilecotacao`,
                timeout: 600000,
                paramName: "file",
                withCredentials: false,
                forceFallback:false,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                    'Cache-Control': null
                }
            }
        };
    },
    methods: {
      setStoreSelect(value){
        this.storeSelect = value
      },
        importVarejoFacil() {
            if(this.quotation) {
                this.loading = true;
                var stores = this.all_stores ? this.stores.map(s => s.value.code) : [ this.storeSelect.code ]
                var counter = 0
                stores.forEach(store_code => {
                    this.service.importQuotation(this.quotation, store_code)
                    .then(result => result.data.data)
                    .then(result => {
                        counter++

                        if(counter == stores.length) {
                            this.loading = false;
                            this.close();
                            this.$emit("finish");
                        }

                    }).catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store);
                        const notification = {
                            type: 'error',
                            message: 'Ocorreu um erro ao importar cotação! Verifique o código da cotação ou tente novamente mais tarde.'
                        }
                        this.$store.dispatch('notification/add', notification)
                    })
                })
            }
        },
        checkOrders(type) {
            if(type && type.code=='VAREJOFACIL') {
                this.loading = true;
                console.log(1);
                this.service.getOpenQuotations()
                .then(result => result.data.data)
                .then(result => {
                  console.log(2);
                    this.loading = false;
                    this.quotations = result.items;
                }).catch(() => this.loading = false)
            }
        },
        close() {
            this.$emit("close");
        },
        successResponse(file, response) {
            this.close();
            this.$refs.myVueDropzone.removeAllFiles();
            this.loading=false;
            this.$emit("finish", response.new_products);
        },
        successResponseFile(file, response) {
            this.close();
            this.$refs.myVueDropzoneFile.removeAllFiles();
            this.loading=false;
            this.$emit("finish", response.new_products);
        },
        sendingEvent(file, xhr, formData) {
            this.loading=true;
            let separador = (this.importType.code == 'VR')? ";" : "|";
            formData.append("separador", separador)
            if (this.storeSelect && this.importType.code != 'TXT') formData.append("codLoja", this.storeSelect.code);
            if (this.storeSelect && this.importType.code == '15') formData.append("status", '15');
        },
        getStores() {
            var quotationService = new QuotationService();
            quotationService.getStoresClient()
            .then(response => response.data)
            .then(data => {
                this.stores = data.requisitantes.map(store => {
                    return {
                      text: store.loj_descricao,
                      code: store.loj_id,
                      value: {
                        code: store.loj_id,
                        label: store.loj_descricao
                      }
                    }
                })
                this.setup_store(this.stores)
            })
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        setup_store(stores) {
            if(stores.length == 1) {
                this.storeSelect = { label: stores[0].value.label, code: stores[0].value.code  }
            }
        }
        },
        mounted() {
            this.getStores()
        }
    };
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
  width: 70vw;
  min-height: 80vh;
}
.import-resupply__main-container {
}
.manage-categories__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.manage-categories__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.manage-categories__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.import-resupply__inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 3em;
}
.import-resupply__quotation-code-container {
  display: flex;
  margin: 0em 3em;
}
.import-resupply__quotation-code-wrapper {
  width: 100%;
}
.import-resupply__quotation-code-input {
  padding: 1em;
  border: 1px solid #c2c2c3;
  border-radius: 8px;
  width: 100%;
}
.import-resupply__inputs-wrapper {
  display: flex;
  flex-direction: column;
}
.import-resupply__inputs-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: #898989;
  margin-bottom: 10px;
}
.import-resupply__import-type-input {
  width: 30vw !important;
}
.import-resupply__buttons-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 3em;
}
.custom-checkbox {
  margin-top: 25px;
}
.disabled-input {
  padding: 15px;
  width: 30vw;
  border-radius: 8px;
}

@media only screen and (max-width: 560px) {
  .modal-content-container{
    width: 80%;
  }
  .manage-categories__header-title {
    font-size: 20px;
  }
  .import-resupply__inputs-title {
    font-size: 14px;
  }
  .import-resupply__inputs-container {
    margin: 1em;
  }
  .import-resupply__quotation-code-container {
    margin: 0em;
  }
  .import-resupply__quotation-code-wrapper {
    margin: 1em;
  }
  .import-resupply__inputs-container {
    flex-direction: column;
    gap: 3vh;
  }
  .import-resupply__import-type-input {
    width: 60vw !important;
  }
}

@media only screen and (max-width: 470px) {


  .disabled-input {
    width: 60vw;
  }
  .multiselect__single {
    font-size: 12px !important;
  }
  .import-resupply__inputs-wrapper {
    margin-top: 1em;
  }
  .import-resupply__quotation-code-container {
    margin-top: 2em;
  }
}

/deep/ {

    .dropzone {
        border-style: dashed;
        border-width: 3px;
        border-color: $primary-color;
        width: 64vw !important;
        .dropzone-custom-title {
            color: $primary-color;
        }
        i {
            font-size: 4rem;
            color: $primary-color;
        }
    }

    .multiselect__tags {
      padding: 25px 10px !important;
    }
    .multiselect__single {
      position: relative !important;
      top: -12px !important;
      font-size: 18px !important;
    }
    .multiselect__select {
        top: 5px !important;
    }
    .multiselect__select::before {
        top: 30px !important;
        z-index: 10;
    }
    .multiselect__single {
        color: black !important;
    }

    .multiselect__option.multiselect__option--highlight {
        background-color: gray !important;
    }

    .modal-body {
        overflow: visible !important;
        border: 4px solid #eaeaea;
        border-radius: 5px;
        padding: 2rem;
    }

    .vdatetime-popup__header {
		background-color: $primary-color !important;
	}
	.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
		background-color: $primary-color !important;
	}
	.vdatetime-popup__actions__button {
		color: $primary-color !important;
	}
	.vdatetime-time-picker__item--selected {
		color: $primary-color !important;
	}
}
</style>
