<template>
  <div id="app">
    <cc-header v-if="logged" :setMobileMenu="setMobileMenu"></cc-header>
    <cc-sidebar v-if="logged"></cc-sidebar>
    <MobileMenu v-if="mobileMenuIsExpanded" :setMobileMenu="setMobileMenu" />
    <transition name="fade" mode="out-in">
      <div class="page-body-y">
        <ContractAlertVue />
        <div class="page-body-x">
          <router-view />
        </div>
      </div>
    </transition>
    <chatIconVue v-if="logged && isCliVindi()" />
    <NotificationContainer />
  </div>
</template>

<script>
import Header from "@/components/cliente/base-components/Header";
import Sidebar from "@/components/shared/Sidebar";
import NotificationContainer from "@/components/notifications/NotificationContainer";
import MobileMenu from "@/components/shared/MobileMenu.vue";
import ContractAlertVue from "./components/cliente/base-components/ContractAlert.vue";
import StorageService from "@/services/TokenService";
import chatIconVue from "./components/shared/chatIcon.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { setFaviconAndTitle } from "./helpers/docInfo";
export default {
  components: {
    NotificationContainer,
    ccHeader: Header,
    ccSidebar: Sidebar,
    MobileMenu,
    ContractAlertVue,
    chatIconVue,
  },
  computed: {
    ...mapGetters({ logged: "user/logged" }),
    ...mapState(["whitelabel"]),
    primaryColor() {
      return this.whitelabel.data.colors.primary || "var(--primary-color)";
    },
  },
  data() {
    return {
      mobileMenuIsExpanded: false,
      storage_svc: new StorageService(),
    };
  },
  created() {
    this.checkAndSetFaviconAndTitle();
    this.attColorWhitelabel();
    // this.fetchDados();
  },
  mounted() {
    this.fetchDados().then(this.checkAndSetFaviconAndTitle());
  },
  watch: {
    whitelabel: {
      handler() {
        this.attColorWhitelabel();
      },
      deep: true,
    },
  },
  methods: {
    //  ...mapActions(['fetchDados']),
    ...mapActions(["fetchDados"]),
    checkAndSetFaviconAndTitle() {
      if (
        this.whitelabel.data.imgs.img_window != null &&
        this.whitelabel.data.providers.text_window != null
      ) {
        setFaviconAndTitle(
          this.whitelabel.data.imgs.img_window,
          this.whitelabel.data.providers.text_window
        );
      }
    },

    attColorWhitelabel() {
      const primaryColor = this.whitelabel.data.colors.primary || "#FF7110";
      const secondaryColor = this.whitelabel.data.colors.secondary || "#fb6000";
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor
      );
      document.documentElement.style.setProperty(
        "--primary-color-opacity",
        primaryColor + 25
      );
      document.documentElement.style.setProperty(
        "--secondary-color-opacity",
        secondaryColor + 26
      );
    },

    setMobileMenu(value) {
      this.mobileMenuIsExpanded = value;
    },
    isCliVindi() {
      return this.storage_svc.get("cli_vindi") == 1;
    },
  },
};
</script>

<style lang="scss">
@import "./sass/app.scss";
// body{
//   overflow: hidden;
// }
:root {
  --primary-color: var(--primary-color);
  --primary-color-opacity: var(--primary-color);
  --secondary-color: var(--secondary-color);
  --secondary-color-opacity: var(--secondary-color);
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.page-body-y {
  margin-top: 23vh;
  min-height: 100vh;
}
.page-body-x {
  margin-left: 9vw;
}
@media only screen and (max-width: 630px) {
  .page-body-x {
    margin-left: 1vw;
  }
    .page-body-y {
    margin-top: 5vh;
  }
}
</style>
