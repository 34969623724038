<style lang="scss" scoped>
	@import "./negotiate-offer.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
			<div slot="body" class="container-fluid">
				<div class="cc-loader" :class="{ hidden : !loading }">
					<img src="/images/loader.svg"/>
					<span>Carregando</span>
				</div>
				<div class="row" :class="{ hidden : loading }">
					<div class="col-12">
						<label class="label-control">Produto</label>
						<input type="text" class="form-control" v-model="po.product.name" disabled="disabled">
					</div>
					<div class="col-6" v-if="po.offer.brand">
						<label class="label-control">Marca </label>
						<input type="text" class="form-control" v-model="po.offer.brand.name" disabled="disabled">
					</div>
					<div class="col-6">
						<label class="label-control">Obs</label>
						<input type="text" class="form-control" v-model="po.offer.obs" :disabled="!modal.permitted">
					</div>
					<div class="col-3">
						<label class="label-control">Ultima compra </label>
						<input type="text" class="form-control" v-model="po.product.ultimaCompra" disabled="disabled">
					</div>
					<div class="col-3">
						<label class="label-control">Preço digitado</label>
						<input type="text" class="form-control" v-model="po.offer.price" disabled="disabled">
					</div>
					<div class="col-6 new-price">
						<label></label>
						<div class="row">
							<div class="col-6">
								<label>Informe o novo preço</label>
							</div>
							<div class="col-4">
								<money v-model="new_price" v-bind="money"></money>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3 align-items-center d-flex consent">
						<toggle-button
							v-model="modal.permitted"
							:color="'var(--primary-color)'"
							:height="30"/>
						<label>Confirmo que o novo preço foi acordado com o vendedor: {{ seller.name }}</label>
					</div>
				</div>
			</div>
	</cc-modal>
</template>
<script>

	import OfferService from "@/services/v3/requests/offer.service";
	import ErrorHandlerService from '@/services/ErrorHandlerService';
	import StorageService from "@/services/local-storage.service.js";

	export default {
		props: [
            "po",
            "seller"
        ],
		data() {
			return {
				new_price: 0,
				loading: false,
				service: new OfferService(),
				modal: {
					title: "negociação",
					submit: this.update_offer_price,
					subtitle: 'Consiga melhores preços e condições negociando',
					icon_title: 'fa fa-handshake',
					unpermitted_message: "Aceite o termo de consentimento para continuar.",
					permitted: false
				},
				money: {
					decimal: ',',
					thousands: '.',
					prefix: 'R$ ',
					precision: 2,
					masked: false
				}
			}
		},
		methods: {
			close(with_reload = false) {
				this.loading = false;
				this.$emit('close', with_reload);
			},
			update_offer_price() {
				this.loading = true;
				this.service.update({ ...this.po.offer, price: this.new_price })
				.then(() => {
					this.close(true);
				})
				.catch(data => {
                    ErrorHandlerService.handle(data, this.$store)
					this.close(true);
				})
			}
		},
		watch: {
			"modal.permitted" : function(consent) {
				let key = "permitted_sellers";
				let val = this.seller.id;
				if(this.modal.permitted) {
					StorageService.add_to_array(key, val);
				} else {
					StorageService.remove_from_array(key, val);
				}
			}
		},
		created() {
			let key = "permitted_sellers";
			let ids = StorageService.get(key)
			this.modal.permitted = ids && ids.includes(this.seller.id);
		}
	};
</script>
