<template>
    <div :class="getDiffColor(getDiff)" class="makeorder-page-table-totalprice-width">
        <div class="makeorder-page-table-mobile-header">Total</div>
        {{ getVar }}
    </div>
</template>

<script>
export default {
    name: 'varLine',
    props: ['product', 'quantity'],
    computed:{
        getDiff() {
            if (!this.product.ultimaCompra) return 0
            return Number(this.product.menorPreco.toFixed(2)) - this.product.ultimaCompra
        },
        getVar(){
            if (localStorage.getItem('cli_utilizaPrecoBase') != 1) return '--'
            if(!this.product.pro_principal && this.product.cli_tipoPedido == 0) return this.$options.filters.currency((this.getDiff * this.product.emb_quantidade) * this.quantity)
            return this.$options.filters.currency(this.getDiff * this.quantity)
        },
    },
    methods:{
        getDiffColor(diff) {
            if (diff > 0) return 'diff-up'
            else if (diff < 0) return 'diff-down'
        },
    }
}
</script>

<style scoped>
.makeorder-page-table-totalprice-width{width: 7%;}


.makeorder-page-table-mobile-header {
    display: none;
}
.diff-up {
    color: lightcoral;
    font-weight: bold;
}

.diff-down {
    font-weight: bold;
    color: lightgreen;
}



@media screen and (max-width: 850px) {
    .makeorder-page-table-totalprice-width{
        width: fit-content !important; 
        display: flex; 
        gap: 2vw;
        align-items: baseline;
    }


    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>