<template>
    <div class="modal-mask">
        <div class="modal-container">
            <div class="provider-details__header-container">
                <span class="provider-details__header-title">
                    Editar detalhes do pedido
                </span>
                <span @click="close" class="material-icons provider-details__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="edit-details-form">
                <div class="edit-datails-input-container">
                    <div class="page-filters__right-title-wrapper">
                        <div class="page-filters__right-title">
                            Data Entrega
                        </div>
                    </div>
                    <div class="page-filters__right-select-wrapper">
                        <datetime style="border-radius: 8px !important" v-model="newDate" class="form-control" ref="datepicker"
                            type="date"></datetime>
                    </div>
                </div>
                <div class="edit-datails-input-container">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Forma de Pagamento</span>
                    </div>
                    <cc-select-v2 class="select-width" v-model="selectedType" :tracker="'code'"
                        :text="'label'" :options="payment_types">
                    </cc-select-v2>
                </div>
                <div class="edit-datails-input-container">
                    <div class="new-page__actions-title-wrapper">
                        <span class="new-page__actions-title">Faturamento minimo</span>
                    </div>
                    <money type="text" class="edit-order-price-input" v-bind="money" v-model="newPrice" />
                </div>
                <StandardButton :iconName="'save'" :action="saveOrder" class="edit-details-save-button" :text="'Salvar'" />
            </div>
            <cc-loader-full v-if="isLoading"/>
        </div>
    </div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import StoreService from "../../../services/v1/store.service";


export default {
    name: 'EditOrderDetails',
    props: {
        close: {
            type: Function
        },
        order: {
            type: Object
        }
    },
    components: {
        StandardButton,
    },
    data() {
        return {
            payment_types: [],
            service: new QuotationService(),
            requestService: new StoreService(),
            selectedType: undefined,
            isLoading: true,
            newPrice: 0,
            newDate: moment(),
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            },
        }
    },
    methods: {
        load_payment_types() {
            this.service.list_payment_types()
                .then(response => response.data)
                .then(data => {
                    this.payment_types = data.data.map(p => {
                        if (p.ttp_nome == this.order.forma) this.selectedType = { label: p.ttp_nome, code: p.ttp_id }
                        return { label: p.ttp_nome, code: p.ttp_id } 
                    });
                    this.isLoading = false
                })
        },
        saveOrder(){
            this.isLoading = true
            const payload = {
                numerocotacao: this.$route.params.id,
                seller_id: this.$route.params.sellerid,
                data_entrega: moment(this.newDate).format("YYYY-MM-DD HH:mm"),
                forma_pagamento: this.selectedType.label,
                faturamento_minimo: this.newPrice,
                old: false,
                observation: ' '
            }
            this.requestService.editOrderDetails(payload).then(resp=>{
                this.order.faturamento_minimo = this.newPrice
                this.order.data_entrega = this.newDate
                this.order.forma = this.selectedType.label
                this.close()
            })
        }
    },
    created() {
        this.load_payment_types()
        this.newPrice = this.order.faturamento_minimo
        this.newDate = this.order.data_entrega
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.provider-details__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.provider-details__header-title {
    color: #FFFFFF;
    letter-spacing: -2%;
    font-weight: 500;
    font-size: 32px;
}

.provider-details__closebtn-icon {
    color: #FFFFFF;
    font-size: 30px;
    cursor: pointer;
}

.page-filters__right-wrapper {
    margin-right: 10px;
}

.page-filters__right-title-wrapper {
    display: flex;
    align-items: center;
}

.page-filters__right-title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}

.new-page__search-input {
    width: 30vw;
    height: 4vw;
    border: 1px solid #c2c2c3;
    border-radius: 5px;
    padding-left: 0.5em;
    color: #898989;
}

.edit-details-save-button {
    width: fit-content;
    margin: 0 auto;
    margin-top: 5vh;
}

.new-page__actions-title {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #898989;
}

.new-page__left-actions {}

.new-page__right-actions {
    display: flex;
}

.edit-details-form {
    padding: 3vw;
}

.edit-order-price-input {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    height: 40px;
    color: #a09c9c;
}
.edit-order-price-input:focus{
    outline: none;
}

.edit-datails-input-container {
    margin-bottom: 2vh;
}
</style>