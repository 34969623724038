<template>
    <div class="makeorder-page-table-digi-width makeorder-page-table-digi-text">
        <div class="makeorder-page-table-mobile-header">Digitação</div>
        <div style="display: flex; align-items: center;">
            <i v-if="product.possuiPedido < 1" class="fa fa-handshake-o makeorder-page-table-digi-icon" title="Negociar"
                @click="open_negotiation_modal(product)"></i>
            <div :style="getDigiTextColor(product)">{{ product.menorPreco | currency }}</div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DigiLine',
    props: ['product','open_negotiation_modal', 'getDigiTextColor']
}
</script>

<style scoped>
.makeorder-page-table-digi-width{
    width: 10%;
    display: flex;
    align-items: center;
}

.makeorder-page-table-mobile-header {
    display: none;
}
.makeorder-page-table-digi-icon{
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.makeorder-page-table-digi-text{font-weight: 600;}


@media screen and (max-width: 850px) {
    .makeorder-page-table-digi-width{
        width: fit-content !important;
        display: flex;
        gap: 2vw;
        align-items: baseline;
    }

    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>
