<template>
    <div class="mobile-modal-container">
        <div class="mobile-modal-body">
            <div class="mobile-modal-header-container">
                <h1 class="mobile-modal-header-text">{{ mobileItem.nomeLoja }} - {{ mobileItem.dataCotacao.split(' ')[0] }}</h1>
                <span v-on:click="close" class="material-icons-outlined mobile-modal-close-icon">cancel</span>
            </div>
            <div class="mobile-modal-body-container">
                <h1 class="mobile-modal-body-title">Lista Mobile</h1>
                <div class="mobile-modal-table-header">
                    <div class="mobile-modal-cod">COD</div>
                    <div class="mobile-modal-date">Data</div>
                    <div class="mobile-modal-desc">Descrição</div>
                    <div class="mobile-modal-company">Empresa</div>
                    <div class="mobile-modal-user">Usuário</div>
                    <div class="mobile-modal-action"></div>
                </div>
                <div v-for="(item, idx) in storesList" :key="idx" class="mobile-modal-table-row" :class="getSpecialBackground(idx)">
                    <div class="mobile-modal-cod">{{ item.lista_id }}</div>
                    <div class="mobile-modal-date">{{ item.data_envio }}</div>
                    <div class="mobile-modal-desc">{{ item.lista_descricao }}</div>
                    <div class="mobile-modal-company">{{ item.loj_descricao }}</div>
                    <div class="mobile-modal-user">{{ item.usu_nome }}</div>
                    <div class="mobile-modal-action">
                        <span class="material-icons-outlined mobile-modal-products-icon" v-on:click="mobileList = [item.lista_id]">receipt</span>
                    </div>
                </div>
            </div>
        </div>

        <ModalProductsListMobile
                :listMobile="mobileList"
                v-if="mobileList"
                @close="() => mobileList = undefined"/>
    </div>
</template>

<script>
import QuotationService from '@/services/QuotationService';
import ModalProductsListMobile from "@/components/cliente/monitors/ModalProductsListMobile";

export default {
    name: 'MobileModal',
    props: ['close', 'mobileItem'],
    components:{
        ModalProductsListMobile
    },
    data () {
        return{
            service: new QuotationService(),
            mobileList: undefined,
            storesList: []
        }
    },
    methods:{
        getSpecialBackground (idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
    },
    created () {
        this.service.getListMobile(null, this.mobileItem.list_ids).then(resp => {
            this.storesList = resp.data.lista
        })
    }
}
</script>

<style scoped>
.mobile-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 125vh;
    width: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-modal-body{
    background-color: white;
    height: 80%;
    width: 80%;
    border-radius: 10px;
}
.mobile-modal-header-container{
    background-color: var(--primary-color);
    height: 10%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 5%;
}
.page-table-line-special{ background: #F7F7F7; }
.mobile-modal-header-text{
    font-size: 2em;
    color: white;
}
.mobile-modal-close-icon{
    color: white;
    font-size: 3em;
    cursor: pointer;
}
.mobile-modal-body-container{
    height: 83%;
    margin-top: 2%;
    padding: 0 5%;
}
.mobile-modal-body-title{
    font-size: 1.5em;
    color: #605F5F;
    font-weight: bold;
}
.mobile-modal-table-header{
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    color: #605F5F;
    font-weight: 400;
    display: flex;
    padding: 1vh 5%;
    margin-top: 2vh;
}
.mobile-modal-cod{
    flex: 1;
}
.mobile-modal-date{
    width: 20%;
}
.mobile-modal-desc{
    width: 10%;
}
.mobile-modal-company{
    width: 20%;
}
.mobile-modal-user{
    width: 20%;
}
.mobile-modal-action{
    width: 5%;
}
.mobile-modal-table-row{
    display: flex;
    padding: 1vh 5%;
    color: #605F5F;
}
.mobile-modal-products-icon{
    font-size: 2em;
    color: var(--primary-color);
    cursor: pointer;
}
</style>
