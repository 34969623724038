<template>
    <div class="winners-page-containear" v-on:click="closeOptionsSelect()">
        <cc-loader-full v-if="is_loading" />
        <div class="winners-page-products-container">
            <div
                class="winners-page-product"
                v-for="(product, index) in products"
                :key="index"
            >
                <div class="winners-page-product-title-container">
                    <div class="winners-page-product-title-upper">
                        <div
                            class="winners-page-product-title"
                            v-on:click="toogleProduct(product)"
                        >
                            <span
                                class="material-icons-outlined winners-page-product-title-expand"
                                v-if="!product.isColapse"
                                >expand_more</span
                            >
                            <span
                                class="material-icons-outlined winners-page-product-title-expand"
                                style="rotate: 270deg"
                                v-else
                                >expand_more</span
                            >
                            <span
                                v-if="irregular_amount(product)"
                                class="material-icons-outlined"
                                style="color: red"
                                title="Verificar quantidades"
                                >error</span
                            >
                            {{ product.descricaoProduto }}
                        </div>
                    </div>
                    <div
                        v-if="product.codEan > 500000"
                        class="winners-page-product-title-lower"
                    >
                        Código: {{ product.codEan }}
                    </div>
                </div>
                <div v-if="!product.isColapse">
                    <div class="winners-page-product-info-table-header">
                        <div class="info-table-checkbox-width"></div>
                        <div class="info-table-suplier-width">Fornecedor</div>
                        <div class="info-table-brand-width">Marca</div>
                        <div class="info-table-payment-width">
                            Forma De Pgto
                        </div>
                        <div class="info-table-quantity-width">Qtd. Emb.</div>
                        <div class="info-table-value-width">Valor unitário</div>
                        <div class="info-table-obs-width">Obervação</div>
                    </div>
                    <div
                        class="winners-page-product-info-table-row"
                        :class="getSpecialBackground(idx)"
                        v-for="(winner, idx) in product.vencedores"
                        :key="idx"
                    >
                        <div
                            class="info-table-checkbox-width info-table-suplier-width-colum"
                        >
                            <div>
                                <span
                                    :title="getDisableTitle(winner)"
                                    v-if="is_winner(winner)"
                                    class="material-icons-outlined"
                                    style="
                                        color: var(--primary-color);
                                        cursor: pointer;
                                        margin-right: 10px;
                                        margin-left: 1vw;
                                    "
                                    >check_box</span
                                >
                                <span
                                    :title="getDisableTitle(winner)"
                                    v-else
                                    class="material-icons-outlined"
                                    style="
                                        color: var(--primary-color);
                                        cursor: pointer;
                                        margin-left: 1vw;
                                    "
                                    >check_box_outline_blank</span
                                >
                                <img
                                    class="winners-loader"
                                    :class="{ hidden: !winner.is_loading }"
                                    src="/images/loader.svg"
                                />
                                <span
                                    v-if="
                                        is_winner(winner) &&
                                        product.codEan > 500000
                                    "
                                    v-on:click="
                                        showProductInfo = product.codEan;
                                        buyPrice = winner.valor;
                                    "
                                    class="material-icons-outlined"
                                    style="
                                        color: var(--primary-color);
                                        cursor: pointer;
                                        margin-left: 0.5vw;
                                    "
                                    >local_offer</span
                                >
                            </div>
                            <!-- mobile -->
                            <div class="mobile">
                                <div class="mobile-suplier-name">
                                    {{ winner.nomeFornecedor }}
                                </div>
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="info-table-suplier-width desktop">
                            <div class="mobile-suplier-name">
                                {{ winner.nomeFornecedor }}
                                <br />
                                <i class="preorder-seller-seller-name"
                                    >({{ winner.nomeVendedor }})</i
                                >
                            </div>
                        </div>

                        <!-- desktop -->
                        <div
                            class="info-table-brand-width info-table-suplier-width-colum desktop"
                        >
                            <div class="info-table-mobile-header">Marca</div>
                            <div style="display: flex; align-items: center">
                                <span
                                    v-if="
                                        winner.pro_preferencial == 1 &&
                                        product.flag_generico == 1
                                    "
                                    class="material-icons-outlined suplier-fav-icon"
                                    >star</span
                                >
                                <span
                                    v-if="
                                        winner.pro_preferencial == 0 &&
                                        product.flag_generico == 1
                                    "
                                    class="material-icons-outlined suplier-fav-icon"
                                    >star_outline</span
                                >
                                {{ winner.pro_observacao }}
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="info-table-payment-width desktop">
                            <div class="info-table-mobile-header">
                                Forma De Pgto
                            </div>
                            {{ winner.forma ? winner.forma : "-" }}
                        </div>
                        <!-- desktop -->
                        <div class="info-table-quantity-width desktop">
                            <div class="info-table-mobile-header">
                                Qtd. Emb.
                            </div>
                            {{
                                product.flag_generico == 1
                                    ? winner.embalagem
                                    : product.qtd_embalagem
                            }}
                        </div>
                        <!-- desktop -->
                        <div class="info-table-value-width desktop">
                            <div class="info-table-mobile-header">
                                Valor unitário
                            </div>
                            {{ winner.valor | currency }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-info-row">
                            <div style="display: flex; align-items: center">
                                <span
                                    v-on:click="setPreferencial(winner)"
                                    v-if="
                                        winner.pro_preferencial == 1 &&
                                        product.flag_generico == 1
                                    "
                                    class="material-icons-outlined suplier-fav-icon"
                                    >star</span
                                >
                                <span
                                    v-on:click="setPreferencial(winner)"
                                    v-if="
                                        winner.pro_preferencial == 0 &&
                                        product.flag_generico == 1
                                    "
                                    class="material-icons-outlined suplier-fav-icon"
                                    >star_outline</span
                                >
                                {{ winner.pro_observacao }}
                            </div>
                            <div>
                                Emb:
                                {{
                                    product.flag_generico == 1
                                        ? winner.embalagem
                                        : product.qtd_embalagem
                                }}
                            </div>
                            <div style="text-align: right">
                                {{ winner.valor | currency }}
                            </div>
                        </div>

                        <!-- desktop -->
                        <div
                            class="info-table-obs-width desktop"
                            :title="winner.observacao"
                        >
                            <div class="info-table-mobile-header">
                                Obervação
                            </div>
                            <div
                                v-if="winner.observacao"
                                style="color: red; font-weight: bold"
                            >
                                {{ formatObs(winner.observacao) }}
                            </div>
                            <div v-else>--</div>
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-info-row">
                            <div :title="winner.observacao">
                                <div class="info-table-mobile-header">
                                    Obervação
                                </div>
                                <div
                                    v-if="winner.observacao"
                                    style="color: red; font-weight: bold"
                                >
                                    {{ formatObs(winner.observacao) }}
                                </div>
                                <div v-else>--</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "@/components/cliente/base-components/Header";
import WinnerProducts from "@/components/partials/_products";
import WinnerPreOrders from "@/components/partials/PreOrder.vue";
import WinnerPostOrders from "@/components/partials/PostOrder.vue";
import WinnerPreOrdersBySeller from "@/components/partials/_pre_orders_by_seller";
import Breadcrumb from "@/components/shared/Breadcrumb";
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component";
import RequestsService from "@/services/v2/requests.service";
import V3RequestsService from "@/services/v3/request.service";
import StorageService from "@/services/local-storage.service";
import GeneralRequestReport from "@/components/partials/_general-report-matrix.component";
import GeneralRequestReportWithLastOffer from "@/components/partials/_general-report-with-last-offers.component";
import { mapState, mapGetters } from "vuex";
import { perm_mixin } from "@/mixins/permission.mixin";
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import StandardInputAuto from "@/components/ui/inputs/StandardInputAuto.vue";
import MobileNote from "@/components/notes/mobile-promotion.noteV2";
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import ProductOrdersModal from "@/modals/products/orders";
import { loaderMixin } from "@/mixins/sweet-loader.mixin";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
// import InfoPriceProductModal from "@/components/shared/InfoPriceProductModal.vue";
import ChangeQuotationDeadlineModal from "@/modals/requests/deadline/recreateRequest";
// import InfoPriceService from "../../../services/InfoPrice";

export default {
    mixins: [loaderMixin, perm_mixin, typingRemoteRequestControlMixin],
    props: ["request_id", "description"],
    components: {
        GeneralRequestReportWithLastOffer,
        GeneralRequestReport,
        ccHeader: Header,
        ccBreadcrumb: Breadcrumb,
        ccPagination: Pagination,
        ccProducts: WinnerProducts,
        ccPreOrdersBySellers: WinnerPreOrdersBySeller,
        ccPreOrders: WinnerPreOrders,
        ccButton: Button,
        MobileNote,
        StandardButton,
        StandardInputAuto,
        ProductOrdersModal,
        ChangeQuotationDeadlineModal,
        "cc-product-sub-requests": ProductSubRequestsModal,
        WinnerPostOrders,
        buyPrice: undefined,
    },
    data() {
        return {
            // infoPriceService: new InfoPriceService(),
            report_data: null,
            report_data_with_last_offer: null,
            is_loading: true,
            is_loading_sellers: true,
            others_offers: null,
            VIEW: true,
            CONSTANTS: CONSTANTS,
            items_by_page_value: 30,
            freezed: false,
            page: 1,
            total_items: 1000,
            notTypedFilter: "T",
            not_typed: [],
            wraps: [],
            sellers: [],
            svc: new RequestsService(),
            v3_svc: new V3RequestsService(),
            service_v1: new QuotationService(),
            prod_svc: new ProductService(),
            myStore: undefined,
            print: false,
            values_filter: [],
            active_menu: "PRODUCTS",
            filters: {
                provider: null,
                category: null,
                store: null,
                has_observation: false,
                others_only: false,
                variation_price: false,
                withoutWinner: undefined,
            },
            page_size: 30,
            filter_by_providers_options: [{ value: "", name: "Fornecedor" }],
            filter_by_sellers_options: [{ value: "", text: "Empresa" }],
            filter_by_category_options: [
                { value: "", name: "Todas as categorias" },
            ],
            filter_by_values_options: [
                { value: "P", name: "Todos" },
                { value: "O", name: "Observação" },
            ],
            datePrint: new Date(),
            quotationId: [],
            products: [],
            total_pre_orders: 0,
            total_orders: 0,
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações",
                },
                {
                    name: "Cotação " + this.request_id,
                },
            ],
            showOptionSelect: false,
            current_product: null,
            is_product_orders_modal_open: false,
            current_winner: null,
            current_product: null,
            show_current_winner: false,
            showProductInfo: undefined,
            isOrderCreated: undefined,
            showRecreateRequestModal: false,
            savedTimeStamp: undefined,
            showMobileFilter: false,
        };
    },
    watch: {
        filters: {
            handler: function (val) {
                this.$store.dispatch("filters/update_request_filters", {
                    ...val,
                    id: this.request_id,
                });
            },
            deep: true,
        },
        VIEW: function (new_val) {
            this.$store.dispatch("set_view", new_val);
        },
    },
    computed: {
        has_observation() {
            return this.filters.has_observation ? "O" : "P";
        },
        ...mapState(["user", "priceOrder"]),
        ...mapGetters("filters", ["request_filters"]),
        ...mapGetters({ CURRENT_VIEW: "view" }),
    },
    methods: {
        setPriceOrder(value) {
            this.$store.commit("setPriceOrder", value);
        },
        excludeRequest() {
            this.$swal
                .fire({
                    title: "Tem certeza que deseja excluir a cotação?",
                    text: "Sua cotação será cancelado!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, excluir cotação.",
                })
                .then((response) => {
                    if (!response.isConfirmed) return;
                    this.is_loading = true;
                    this.service_v1
                        .excludeQuotation(this.quotationId)
                        .then(() => {
                            this.is_loading = false;
                            this.goBack();
                        })
                        .catch((error) => {
                            this.is_loading = false;
                            this.snack(
                                "Desculpe, não foi possivel excluir a cotação.",
                                "error"
                            );
                        });
                });
        },
        toogleProduct(product) {
            product.isColapse = !product.isColapse;
            this.$forceUpdate();
        },
        irregular_amount(product) {
            return (
                product.flag_generico &&
                product.soma_quantidades != product.quantidade_selecionada
            );
        },
        DisableCheckBox(winner, product) {
            if (winner.qtdPedido < 1)
                this.toggle_winner(
                    winner,
                    winner.idVendedor,
                    this.is_winner(winner),
                    product
                );
        },
        getDisableTitle(winner) {
            if (winner.qtdPedido > 0) return "Fornecedor com pedido gerado";
        },
        getDisableCheckBox(winner) {
            if (winner.qtdPedido > 0) return "disabled-check-box";
        },
        setSeller(value) {
            this.filters.store = { value: value };
            this.update();
        },
        setPreOrders() {
            this.open("PRE-ORDERS");
            this.loadPreOrders();
        },
        formatObs(text) {
            if (text.length > 25) return text.slice(0, 24) + "...";
            return text;
        },
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return "page-table-line-special";
        },
        toggle_winner(winner, seller_id, is_winner, product = null) {
            this.$set(winner, "is_loading", true);
            this.is_loading_sellers = true;
            if (this.loj_id && is_winner) {
                this.update_items_quantity(
                    winner,
                    this.loj_id,
                    seller_id,
                    () => {
                        this.$set(winner, "is_loading", false);
                        this.$set(winner, "vencedor_quantidade", 0);
                        // this.loadPreOrders();
                    }
                );
            } else {
                var quotationService = new QuotationService();
                quotationService
                    .toggleQuotationWinner(
                        this.request_id,
                        this.loj_id,
                        winner.pro_id,
                        seller_id,
                        !is_winner
                    )
                    .then((response) => response.data)
                    .then(() => {
                        this.$set(winner, "is_loading", false);
                        this.$set(
                            winner,
                            "vencedor_quantidade",
                            !is_winner ? 1 : 0
                        );
                        if (this.loj_id) {
                            if (winner.vencedor_quantidade) {
                                this.current_winner = winner;
                                this.current_product = product;
                                this.show_current_winner = true;
                            }
                        }
                    });
            }
        },
        is_winner(winner) {
            if (winner.quantidade == 0 && winner.vencedor_quantidade == 0)
                return false;
            return winner.vencedor_quantidade > 0;
        },
        setPreferencial(produto) {
            produto.pro_preferencial = produto.pro_preferencial == 1 ? 0 : 1;
            this.prod_svc
                .pin_brand({
                    idProduto: produto.pro_id,
                    preferencial: produto.pro_preferencial,
                })
                .then(() => {
                    this.products.forEach((p) => {
                        if (produto.produtoprincipal == p.prod_id) {
                            p.vencedores.forEach((w) => {
                                if (produto.pro_id == w.pro_id) {
                                    w.pro_preferencial =
                                        produto.pro_preferencial;
                                }
                            });
                        }
                    });
                })
                .catch(function () {});
        },
        show_orders(product) {
            if (product.last_purchase) {
                this.current_product = product;
                this.is_product_orders_modal_open = true;
            }
        },
        setProducts() {
            this.open("PRODUCTS");
        },
        setOrders() {
            this.open("ORDERS");
        },
        isActiveTab(tab) {
            if (this.active_menu == tab && tab == "PRODUCTS")
                return "button-containear-active";
            else if (this.active_menu == tab && tab == "PRE-ORDERS")
                return "button-green-active";
            else if (this.active_menu == tab && tab == "ORDERS")
                return "button-blue-active";
        },
        setCategory(value) {
            if (value) this.filters.category = { value: value };
            else this.filters.category = undefined;
            this.page = 1;
            this.update();
        },
        setSuplier(value) {
            this.filters.provider = value;
            if (value) this.filters.provider.value = value.idFornecedor;
            this.page = 1;
            this.update();
        },
        delayUpdate() {
            this.is_loading = true;
            const localTime = Date.now();
            this.savedTimeStamp = localTime;
            setTimeout(() => {
                if (localTime == this.savedTimeStamp) {
                    this.page = 1;
                    this.update();
                }
            }, 1500);
        },
        setDescription(value) {
            this.description = value;
            this.delayUpdate();
        },
        prices() {
            this.is_loading = true;
            this.download_general_report_with_last_offer();
            this.showOptionSelect = false;
        },
        generalReport() {
            this.is_loading = true;
            this.download_general_report();
            this.showOptionSelect = false;
        },
        dowloadReport() {
            this.download_report();
            this.showOptionSelect = false;
        },
        openOptionsSelect() {
            this.showOptionSelect = true;
        },
        closeOptionsSelect() {
            this.showOptionSelect = false;
        },
        sendNotListed() {
            this.$router.push({
                name: "nao-digitados-cotacao",
                params: { id: this.request_id },
            });
        },
        goBack() {
            this.$router.push("/cliente/monitores/cotacoes");
        },
        download_report() {
            this.svc.request_report(this.request_id).then((response) => {
                var file = response.data;
                var fileURL = window.URL.createObjectURL(file);
                var el = document.getElementById("download-excel");
                el.download = `Mapa de Digitações ${this.request_id}.xls`;
                el.href = fileURL;
                el.click();
                setTimeout(function () {
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            });
        },
        download_general_report() {
            this.open("FULL_REPORT");
            this.v3_svc
                .general_request_report(this.request_id)
                .then((response) => {
                    this.is_loading = false;
                    this.report_data = response.data;
                    Vue.nextTick(() => {
                        window.document.title = `cotacao-${this.request_id}.pdf`;
                        setTimeout(function () {
                            window.print();
                            window.document.title = "Club da Cotação";
                        }, 500);
                    });
                });
        },
        download_general_report_with_last_offer() {
            this.open("FULL_REPORT_WITH_LAST_OFFER");
            this.v3_svc
                .general_request_report(this.request_id)
                .then((response) => {
                    this.is_loading = false;
                    this.report_data_with_last_offer = response.data;
                });
        },
        to_currency(value) {
            return this.$options.filters.currency(value);
        },
        is_open(menu) {
            return this.active_menu == menu;
        },
        open(menu) {
            if (menu == "PRE-ORDERS") {
                if (
                    !this.$store.state.current_user.usu_idLoja ||
                    this.$store.state.current_user.usu_acesso_comp_loja == 1
                ) {
                    this.active_menu = menu;
                }
            } else if (menu == "PRODUCTS") {
                this.active_menu = menu;
            } else {
                this.active_menu = menu;
            }
        },
        toggle_collapse(index) {
            this.$set(this.collapsed, index, !this.collapsed[index]);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getAllWraps() {
            this.prod_svc
                .getAllWrapTypes()
                .then((response) => response.data)
                .then((data) => {
                    let wraps = [];
                    if (data.embalagens) {
                        data.embalagens.map((embalagem) => {
                            wraps.push({
                                value: embalagem.emb_sigla,
                                name: embalagem.emb_sigla,
                            });
                        });
                    }
                    this.wraps = wraps;
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getSeller(sellerId, providerId) {
            return this.sellers.filter(
                (s) => s.idVendedor == sellerId && s.idFornecedor == providerId
            )[0];
        },
        loadPreOrders(close_loader = true, callback) {
            if (close_loader) this.is_loading_sellers = true;

            return this.service_v1
                .getLojasFornecedores(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    if (close_loader) this.is_loading_sellers = false;

                    this.filter_by_sellers_options = [
                        {
                            value: "",
                            text: "Empresa",
                        },
                    ];
                    this.sellers = data.vendedores;
                    this.total_pre_orders = 0;
                    this.total_orders = 0;
                    this.sellers.forEach((s) => {
                        this.total_pre_orders += s.fornecedores
                            .filter((f) => !f.possuiPedido)
                            .reduce((x, f) => f.valorTotal + x, 0);
                        this.total_orders += s.fornecedores
                            .filter((f) => f.possuiPedido)
                            .reduce((x, f) => f.valorTotal + x, 0);
                        if (
                            this.filters.store == null ||
                            s.loj_id == this.filters.store.value ||
                            this.filters.store.value == "" ||
                            this.filters.store.value == undefined ||
                            this.filters.store.value == null
                        ) {
                            this.$set(s, "visible", true);
                        } else {
                            this.$set(s, "visible", false);
                        }

                        if (
                            !this.myStore &&
                            (this.can("REQUEST", "READ_ALL_SUBREQUESTS") ||
                                s.loj_id == this.user.store_id)
                        ) {
                            this.filter_by_sellers_options.push({
                                value: s.loj_id,
                                text: s.loj_descricao,
                            });
                            if (!this.can("REQUEST", "READ_ALL_SUBREQUESTS")) {
                                this.filter_by_sellers_options = [
                                    {
                                        value: s.loj_id,
                                        name: s.loj_descricao,
                                    },
                                ];
                                this.filters.store = {
                                    value: s.loj_id,
                                    name: s.loj_descricao,
                                };
                            }
                            this.$forceUpdate();
                        }
                        if (this.myStore && this.myStore == s.loj_id) {
                            this.filter_by_sellers_options = [
                                {
                                    value: s.loj_id,
                                    text: s.loj_descricao,
                                },
                            ];
                            this.filters.store = {
                                value: s.loj_id,
                                text: s.loj_descricao,
                            };
                        }
                        if (callback) callback();
                    });
                })
                .catch((error) => {
                    if (close_loader) this.is_loading_sellers = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        loadTotalOrders() {
            this.service_v1
                .getVendedoresPedido(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    // this.total_orders = data.totalPedido;
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getProducts(quotationId, reload = true, callback = null) {
            this.quotationId = quotationId;
            this.is_loading = reload;

            if (reload) this.products = [];

            this.cancelSearch();
            this.svc
                .request_products_with_offers(
                    this.request_id,
                    {
                        product_filter: this.description,
                        page: this.page,
                        page_size: this.page_size,
                        fornecedor: this.filters.provider
                            ? this.filters.provider.value
                            : "",
                        imprimir: this.print,
                        obs_only: this.filters.has_observation,
                        others_only: this.filters.others_only,
                        variation_only: this.filters.variation_price,
                        without_winners: this.filters.withoutWinner,
                        category_id: this.filters.category
                            ? this.filters.category.value
                            : "",
                    },
                    this.cancelSource ? this.cancelSource.token : null
                )
                .then((response) => response.data)
                .then((data) => {
                    for (var i = 0; i < data.produtos.length; i++) {
                        var embalagem_split =
                            data.produtos[i].embalagem.split(" ");
                        data.produtos[i].embalagem_units = embalagem_split[0];
                        data.produtos[i].embalagem_quantity =
                            embalagem_split[1];
                    }
                    this.total_items = data.total;
                    this.is_loading = false;
                    this.products = data.produtos;
                    callback && callback();
                })
                .catch((error) => {
                    this.is_loading = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getNotTyped() {
            var quotationService = new QuotationService();
            quotationService
                .getLojasNaoDigitados(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    let tableInfo = [];
                    data.lojas.map((loja) => {
                        tableInfo.push({
                            type: "store",
                            name: loja.loj_descricao,
                        });
                        loja.produtos.map((product) => {
                            tableInfo.push(
                                Object.assign({ type: "product" }, product)
                            );
                        });
                    });
                    this.not_typed = tableInfo.filter((i) => {
                        this.notTypedFilter === "T" ||
                            item.type === "store" ||
                            (this.notTypedFilter === "NC" &&
                                item.type == "product" &&
                                item.pro_status === "Não Comprado") ||
                            (this.notTypedFilter === "ND" &&
                                item.type == "product" &&
                                item.pro_status === "Não Digitado");
                    });
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getAllProviders() {
            var providerService = new ProviderService();
            return providerService
                .list(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    this.filter_by_providers_options = [
                        { text: "TODOS", value: undefined },
                    ];
                    data.fornecedores.map((fornecedor) => {
                        this.filter_by_providers_options.push({
                            value: fornecedor,
                            text: fornecedor.nomeFornecedor,
                        });
                    });
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        has_others() {
            return this.svc
                .others_offers(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    if (data.length > 0) {
                        this.confirm_action({
                            message:
                                "Existem novos fornecedores nesta cotação!",
                            subtitle: `Deseja visualizar suas novas ofertas?`,
                            cancel_text: "Continuar",
                            confirm_text: "Exibir novos!",
                            callback: () => {
                                this.filters.others_only = true;
                                this.getProducts(this.request_id);
                                this.$forceUpdate();
                            },
                        });
                    }
                });
        },
        update() {
            this.getAllWraps();
            if (this.can("REQUEST", "READ_ALL_SUBREQUESTS")) {
                this.getProducts(this.request_id);
            } else {
                this.is_loading = false;
                this.is_loading_sellers = false;
                this.open("ORDERS");
            }
            this.getNotTyped(this.request_id);
            this.loadPreOrders();
            this.loadTotalOrders();
            this.updateQuotationsStatusMap();
        },
        printComparativo() {
            let productsOld = this.products;
            this.page_size = 10000;
            this.getProducts(this.request_id, true, () => {
                setTimeout(() => {
                    let titleOld = document.title;
                    document.title = `Clube da Cotação - ${moment().format(
                        "DD/MM/YYYY HH:mm:ss"
                    )}`;
                    window.print();
                    document.title = titleOld;
                    this.products = productsOld;
                    this.page_size = 30;
                }, 1000);
            });
        },
        updatePage(new_page) {
            this.page = new_page;
            this.update();
        },
        updateLimit(new_limit) {
            this.changeToFirstPage();
            this.items_by_page_value = new_limit;
            this.update();
        },
        changeToFirstPage() {
            this.page = 1;
        },
        filter() {
            this.changeToFirstPage();
            this.update();
        },
        can_freeze() {
            return (
                this.service_v1.getQuotationStatus(this.request_id) !=
                "PEDIDO GERADO"
            );
        },
        clearWinners() {
            if (this.freezed) {
                this.$swal
                    .fire({
                        title: "Esta operacao removerá toda a seleção de compra , Deseja prosseguir?",
                        text: "Sua seleção será removida.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sim, remover todos!",
                    })
                    .then((result) => {
                        if (result.value) {
                            this.is_loading = true;
                            this.service_v1
                                .removeWinners(this.request_id)
                                .then((response) => response.data)
                                .then((data) => {
                                    if (
                                        data.status ==
                                        CONSTANTS.REQUEST_CODE_SUCCESS
                                    ) {
                                        this.update();
                                        this.$swal.fire(
                                            "Sucesso!",
                                            "Operação realizada com sucesso.",
                                            "success"
                                        );
                                    }
                                    this.is_loading = false;
                                })
                                .catch((error) => {
                                    this.is_loading = false;
                                    ErrorHandlerService.handle(
                                        error,
                                        this.$store
                                    );
                                });
                        }
                    });
            }
        },
        freeze() {
            var quotationService = new QuotationService();
            if (this.freezed) {
                this.$swal
                    .fire({
                        title: "Deseja descongelar essa cotação??",
                        text: "Sua contação será descongelada.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sim, descongelar!",
                    })
                    .then((result) => {
                        if (result.value) {
                            quotationService
                                .unfreeze(this.request_id)
                                .then((response) => response.data)
                                .then((data) => {
                                    if (
                                        data.status ==
                                        CONSTANTS.REQUEST_CODE_SUCCESS
                                    ) {
                                        this.updateQuotationsStatusMap();
                                        this.$swal.fire(
                                            "Descongelada!",
                                            "Cotação descongelada com sucesso.",
                                            "success"
                                        );
                                    }
                                })
                                .catch((error) => {
                                    ErrorHandlerService.handle(
                                        error,
                                        this.$store
                                    );
                                });
                        }
                    });
            } else {
                this.$swal
                    .fire({
                        title: "Deseja congelar essa cotação??",
                        text: "Sua contação será congelada.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sim, congelar!",
                    })
                    .then((result) => {
                        if (result.value) {
                            quotationService
                                .freeze(this.request_id)
                                .then((response) => response.data)
                                .then((data) => {
                                    if (
                                        data.status ==
                                        CONSTANTS.REQUEST_CODE_SUCCESS
                                    ) {
                                        this.updateQuotationsStatusMap();
                                        this.$swal.fire(
                                            "Congelada!",
                                            "Cotação congelada com sucesso.",
                                            "success"
                                        );
                                    }
                                })
                                .catch((error) => {
                                    ErrorHandlerService.handle(
                                        error,
                                        this.$store
                                    );
                                });
                        }
                    });
            }
        },
        loadCategories() {
            this.service_v1
                .getCategoriasCotacao(this.request_id)
                .then((response) => response.data)
                .then((data) => {
                    this.filter_by_category_options = [
                        { text: "TODOS", value: undefined },
                    ];
                    data.categorias.forEach((c) =>
                        this.filter_by_category_options.push({
                            text: c.cat_descricao,
                            value: c.cat_id,
                        })
                    );
                });
        },
        updateQuotationsStatusMap() {
            var quotationService = new QuotationService();
            quotationService
                .list(this.page, null, null, null, this.request_id, 10)
                .then((response) => response.data)
                .then((data) => {
                    var statusMap = {};
                    for (var i = 0; i < data.cotacoes.data.length; i++) {
                        statusMap[data.cotacoes.data[i].numeroCotacao] =
                            data.cotacoes.data[i].status_nome;
                    }
                    quotationService.persisteQuotationStatuses(statusMap);
                    let status = this.service_v1.getQuotationStatus(
                        this.request_id
                    );
                    this.freezed = status.toLowerCase() == "congelada";
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        update_breadcrumbs() {
            this.$store.dispatch("set_breadcrumbs", [
                { name: "Painel de Operações", route: "monitors-page" },
                { name: "Cotações", route: "monitors-page" },
                {
                    name: `#${this.request_id}`,
                    route: "cliente-monitores-vencedores",
                },
            ]);
        },
    },
    created() {
        this.quotationId = this.request_id;
        window.scrollTo(0, 0);
        this.perms.forEach((element) => {
            if (element.resource == "MY_COMPANY" && element.action == "READ") {
                this.myStore = this.user.store_id;
            }
        });

        let status = this.service_v1.getQuotationStatus(this.request_id);
        this.isOrderCreated = status == "PEDIDO GERADO";
        this.freezed =
            status != null ? status.toLowerCase() == "congelada" : false;
        if (this.CURRENT_VIEW == undefined) {
            this.VIEW = this.user.method == "ANALISE_POR_LOJA";
            this.$store.dispatch("set_view", this.VIEW);
        } else {
            this.VIEW = this.CURRENT_VIEW;
        }
        this.getAllProviders();
        this.loadCategories();
        this.update();
        let seen_warnings =
            StorageService.get("seen_warnings_request_ids") || [];
        if (
            !this.$route.query.po &&
            !this.$route.query.o &&
            !seen_warnings.includes(`${this.quotationId}`)
        ) {
            this.has_others();
            seen_warnings.push(`${this.quotationId}`);
            StorageService.set("seen_warnings_request_ids", seen_warnings);
        }
        if (this.$route.query.po) {
            this.open("PRE-ORDERS");
        }
        if (this.$route.query.o) {
            this.open("ORDERS");
        }
        if (!this.can("REQUEST", "READ_ALL_SUBREQUESTS")) {
            this.open("PRE-ORDERS");
        }
        this.update_breadcrumbs();
        if (this.request_filters.id == this.request_id) {
            this.filters = this.request_filters;
        }
    },
};
</script>
<style lang="scss" scoped>
@import "./typings";
.checkboxes {
    /deep/ {
        > div {
            margin-right: 10px !important;
        }
    }
}
.winners-page-containear {
    padding: 0 15px 60px 0;
    font-size: 1vw;
}
.print-only {
    display: none;
}
.winners-page-route-select {
    display: flex;
    align-items: center;
}
.winners-page-route-select-text {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 17px;
    color: #ababab;
    margin-right: 20px;
    cursor: pointer;
}
.winners-page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}
.winners-page-title {
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605f5f;
    margin-top: 35px;
}
.winners-page-mobile-info {
    margin-top: 3vh;
    width: 36%;
}
.mobile-filter-button {
    display: none;
}
.winners-page-buttons-container {
    display: flex;
    margin-top: 6vh;
    justify-content: space-between;
}
.winners-page-buttons-1 {
    display: flex;
    width: 40%;
}
.winners-page-buttons-2 {
    display: flex;
    width: 57%;
    justify-content: space-between;
}
.info-table-mobile-header {
    display: none;
}
.winners-page-options-select {
    position: absolute;
    z-index: 2;
    margin-top: 1vh;
    right: 0;
    padding: 0.5vw;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    background-color: white;
}
.winners-page-button-1 {
    margin-left: 1.5vw;
}
.winners-page-options-select-option {
    font-size: 1.25em;
    padding: 0.5vh;
    min-width: 15vw;
    text-align: end;
    cursor: pointer;
}
.winners-page-options-select-option:hover {
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
}
.winners-page-filters-container {
    display: flex;
    margin-top: 5vh;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.winners-page-filters-preorders-icon-container {
    margin-left: 2vw;
}
.winners-page-filters-preorders-container {
    align-items: flex-end;
    justify-content: flex-start;
}
.winners-page-selects-container {
    display: flex;
    justify-content: space-between;
    width: 51%;
    align-items: center;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.15px;
    color: #898989;
}
.preorder-seller-seller-name {
    font-size: 0.8em;
    font-weight: 500;
}
.winners-page-products-container {
    margin-top: 4vh;
}
.winners-page-product {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5vw 1vw;
    margin-bottom: 2vh;
}
.winners-page-product-title {
    font-weight: 400;
    font-size: 1.4em;
    color: #101010;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.winners-page-product-title-container {
    background-color: var(--secondary-color-opacity);
    border-radius: 7px;
    padding: 0.1vw 0.5vw;
}
.winners-page-product-title-lower {
    font-weight: 400;
    font-size: 1.4em;
    color: var(--primary-color);
}
.winners-page-product-title-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.winners-page-product-quantity {
    font-weight: 500;
    font-size: 1.25em;
    color: #101010;
}
.winners-page-product-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.25em;
    color: #606060;
}
.winners-page-product-info-lastbuy {
    display: flex;
    align-items: center;
}
.winners-page-product-info-lastbuy-icon {
    color: #17a2b8;
    margin-left: 5px;
    cursor: pointer;
}
.winners-page-product-info-table-header {
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    display: flex;
    padding: 1vh 0;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.1em;
    color: #605f5f;
}
.pre-order-value-check {
    margin-left: 2vw;
    color: #898989;
    font-size: 19px;
    display: flex;
    margin-bottom: 0.5vh;
    gap: 0.5vw;
    cursor: pointer;
}
.pre-order-value-active {
    color: var(--primary-color);
}
.PRINT-title {
    display: none;
}
.info-table-checkbox-width {
    width: 10%;
}
.info-table-suplier-width {
    flex: 1;
}
.info-table-brand-width {
    width: 14%;
}
.info-table-payment-width {
    width: 14%;
}
.info-table-quantity-width {
    width: 10%;
}
.info-table-value-width {
    width: 14%;
}
.info-table-obs-width {
    width: 18%;
}
.winners-page-product-info-table-row {
    display: flex;
    padding: 1vh 0;
    font-weight: 300;
    font-size: 1.1em;
    color: #605f5f;
    align-items: center;
}
.checkbox-icon {
    cursor: pointer;
}
.info-table-suplier-width-colum {
    display: flex;
    align-items: center;
}
.suplier-fav-icon {
    color: #ffca2b;
    margin-right: 5px;
    margin-bottom: 3%;
    cursor: pointer;
    font-size: 1.4em;
}
.page-table-line-special {
    background: #f7f7f7;
}
.loader-containear {
    padding-top: 10vh;
}
.toggle-label {
    color: #898989;
    margin-left: 1vw;
}
.mobile {
    display: none;
}
.disabled-check-box {
    cursor: default !important;
    color: lightgrey !important;
}
.button-containear {
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    border-color: var(--primary-color);
    border-width: 1px;
    border-style: solid;
}

.winners-page-product-title-expand {
    color: var(--primary-color);
    font-size: 2em;
}
.button-text {
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
    margin-left: 1vw;
    text-align: center;
}
.winners-page-buttons-1-icon {
    color: var(--primary-color);
}
.button-text-blue {
    color: #5281ff;
}
.button-border-blue {
    border-color: #5281ff;
    border-width: 1px;
    border-style: solid;
}
.button-text-green {
    color: #30aa4c;
}
.button-border-green {
    border-color: #30aa4c;
    border-width: 1px;
    border-style: solid;
}
.button-blue-active {
    background-color: rgba(23, 162, 184, 0.1);
    border-color: rgba(23, 162, 184, 0.1);
}
.button-green-active {
    border-color: rgb(212, 247, 212);
    background-color: rgb(212, 247, 212);
}
.button-containear-active {
    border-color: var(--secondary-color-opacity);
    background-color: var(--secondary-color-opacity);
}
.winners-loader {
    height: 3vh;
    margin-left: 1vw;
}
@media only screen and (max-width: 1200px) {
    .winners-page-containear {
        font-size: 1.2vw;
    }
}
@media only screen and (max-width: 1200px) {
    .winners-page-buttons-1 {
        width: 48%;
    }
    .winners-page-buttons-container {
        flex-direction: column;
    }
    .winners-page-buttons-2 {
        width: 70%;
        margin-top: 1vh;
    }
}
@media only screen and (max-width: 1000px) {
    .winners-page-containear {
        font-size: 1.5vw;
    }
    .winners-page-mobile-info {
        width: 55%;
    }
    .winners-page-buttons-1 {
        width: 50%;
        margin-bottom: 3vh;
    }
    .winners-page-selects-container {
        width: 100%;
    }
}
@media only screen and (max-width: 870px) {
    .winners-page-containear {
        font-size: 1.7vw;
    }
    .winners-page-buttons-1 {
        width: 60%;
    }
    .winners-page-buttons-2 {
        width: 80%;
    }
}
@media only screen and (max-width: 800px) {
    .mobile {
        display: block;
    }

    .mobile-info-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .mobile-filter-show {
        display: none;
    }
    .winners-page-product-info-container {
        flex-direction: column;
        text-align: center;
        font-size: 2em;
    }
    .winners-page-product-info-lastbuy {
        justify-content: space-between;
        font-size: 0.85em;
    }
    .mobile-filter-button {
        display: flex;
        width: 45%;
    }
    .mobile-back-button {
        width: 45%;
    }
    .winners-page-filters-container {
        flex-direction: column;
    }
    .winners-page-mobile-info {
        width: 70%;
    }
    .winners-page-buttons-1 {
        width: 70%;
    }
    .winners-page-containear {
        font-size: 2vw;
    }
    .winners-page-product-info-table-header {
        display: none;
    }
    .info-table-mobile-header {
        display: flex;
        font-weight: 500;
        font-size: 1.1em;
    }

    .winners-page-product-info-table-row {
        flex-direction: column;
        font-size: 1.5em;
        gap: 0.7vh;
    }
    .info-table-checkbox-width {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3vw;
    }
    .mobile-suplier-name {
        font-weight: 700;
    }
    .info-table-suplier-width {
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .info-table-brand-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .info-table-payment-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .info-table-quantity-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .info-table-value-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .info-table-obs-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .winners-page-options-select-option {
        font-size: 1.5em;
        padding: 1vh;
        text-align: center;
    }
    .desktop {
        display: none;
    }
}
@media only screen and (max-width: 680px) {
    .winners-page-buttons-2 {
        width: 100%;
    }
    .winners-page-buttons-1 {
        width: 100%;
        gap: 5%;
    }
    .mobile-hide-last {
        display: none;
    }
}
@media only screen and (max-width: 580px) {
    .winners-page-buttons-2 {
        flex-direction: column;
    }
    .mobile-button {
        margin-bottom: 1.5vh;
    }
    .winners-page-containear {
        font-size: 2.4vw;
        padding: 0 15px 15px 0;
    }
    .winners-page-product-title-upper {
        font-size: 1.2em;
    }
}
@media only screen and (max-width: 440px) {
    .winners-page-mobile-info {
        width: 100%;
    }
    .winners-page-buttons-1-mobile {
        width: 30%;
    }
}
@media only screen and (max-width: 370px) {
    .winners-page-buttons-1 {
        width: 100%;
    }
}
@media print {
    .PRINT-title {
        display: block;
        margin-top: -20vh;
    }
    .page-body {
        margin-top: 0;
    }
    .winners-page-containear {
        margin-left: -8vw;
    }
    .print-only {
        display: unset;
    }
    .winners-page-product-title-upper {
        break-inside: avoid;
    }
    @page {
        margin: 3%;
    }
    .print-title-container {
        display: flex;
        align-items: center;
        gap: 3vw;
        margin-top: -20vh;
    }
}
</style>
