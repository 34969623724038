<template>
  <div class="modal-mask">
	<div class="modal-content-container">
	  <div class="seller-groups__main-container">
	    <div class="seller-groups__header-container">
          <span class="seller-groups__header-title">
            Gerenciar grupo(s) de vendedores
          </span>
          <span @click="closeDialog" class="material-icons seller-groups__closebtn-icon">
            cancel
          </span>
        </div>
		<cc-loader style="margin-top: 25vh;" v-show="loading" />
		<div v-show="!loading" class="seller-groups__containers-wrapper">
	      <div class="seller-groups__content-wrapper">
		    <div class="seller-groups__title-wrapper">
		      <span class="seller-groups__title">Meus Grupos</span>
			  <cc-loader v-show="is_loading_request_sellers"/>
		    </div>
			<div class="seller-groups__input-wrapper">
			  <input placeholder="Digite o nome do Grupo"
			         class="seller-groups__input"
					 type="text"
					 v-model="new_group.nome"
					 @keyup.enter="create">
			  <span @click="create" style="color:#fff" class="material-icons-outlined seller-groups__input-icon plus-icon">
			    add
			  </span>
			</div>
			<div class="seller-groups__table-container first-table">
			  <table class="seller-groups__table-wrapper">
                <thead class="seller-groups__table-thead">
                  <tr class="seller-groups__table-thead-tr">
                    <th class="seller-groups__table-th th-center">#ID</th>
                    <th class="seller-groups__table-th th-center">Nome</th>
                    <th class="seller-groups__table-th th-center">Ação</th>
                  </tr>
                </thead>
                <tbody class="seller-groups__table-tbody">
                  <tr v-show="group.visible"
				      v-for="group in groups"
					  :key="group.id"
					  :class="{ selected: group.selected}">
                    <td class="seller-groups__table-td select-icon"><i v-show="group.selected" class="fas fa-hand-point-right mr-2"></i><b>#{{ group.id }}</b></td>
                    <td v-if="group.selected" class="seller-groups__table-td table-input"><cc-input v-model="group.nome"/></td>
                    <td v-else class="seller-groups__table-td table-input"><cc-input disabled v-model="group.nome"/></td>
					<td class="seller-groups__table-td">
					  <div class="seller-groups__table-td__icons-wrapper">
						<span v-if="!group.selected" @click.stop="select_group(group)" class="material-icons-outlined edit-icon">edit</span>
					    <span v-if="group.selected" @click="update(group)" class="material-icons-outlined save-icon">save</span>
					    <span class="material-icons-outlined trash-icon" v-show="!group.loading" @click.stop="remove_group(group)">delete</span>
					    <cc-loader v-show="group.loading" :show_txt="false" class="loader float-right" />
					  </div>
					</td>
                  </tr>
                </tbody>
              </table>
			</div>
		  </div>
	      <div class="seller-groups__content-wrapper">
		    <div class="seller-groups__title-wrapper">
			  <span v-if="group.selected" class="seller-groups__title">{{ group.nome }}</span>
			  <span v-if="!group.selected" class="seller-groups__title">Selecione um grupo</span>
			</div>
			<div class="seller-groups__table-container middle-table">
			  <table class="seller-groups__table-wrapper">
                <thead class="seller-groups__table-thead">
                  <tr class="seller-groups__table-thead-tr">
                    <th class="seller-groups__table-th">Fornecedor</th>
                    <th class="seller-groups__table-th">Vendedor</th>
					<th class="seller-groups__table-th"></th>
                  </tr>
                </thead>
                <tbody class="seller-groups__table-tbody">
                  <tr v-for="(seller, idx) in group_sellers"
				      :key="seller.usu_id" :class="{'page-table-line-special': idx % 2 != 0}">
                    <td class="seller-groups__table-td"><b style="color:var(--primary-color);">{{ seller.provider.for_nome }}</b></td>
                    <td class="seller-groups__table-td">{{ seller.usu_nome }}</td>
					<td class="seller-groups__table-td">
					  <span class="material-icons-outlined trash-icon" v-show="!seller.loading" @click.stop="remove_seller(seller)">delete</span>
					  <cc-loader style="width: 30px;" v-show="seller.loading" :show_txt="false" class="loader float-right" />
					</td>
                  </tr>
                </tbody>
              </table>
			</div>
		  </div>
	      <div class="seller-groups__content-wrapper">
		    <div class="seller-groups__title-wrapper">
			  <span class="seller-groups__title">Fornecedores disponíveis</span>
			  <cc-loader v-show="is_loading_available_sellers"/>
			</div>
			<div class="seller-groups__table-container">
			  <table class="seller-groups__table-wrapper">
                <thead class="seller-groups__table-thead">
                  <tr class="seller-groups__table-thead-tr">
                    <th class="seller-groups__table-th"></th>
                    <th class="seller-groups__table-th">Fornecedor</th>
					<th class="seller-groups__table-th">Vendedor</th>
                  </tr>
                </thead>
                <tbody class="seller-groups__table-tbody">
                  <tr v-for="(seller, idx) in sellers"
				  	  :key="seller.usu_id"
					  v-show="!seller.disabled"
					  :class="{ disabled: seller.disabled || !group.id, 'page-table-line-special': idx % 2 != 0 }">
                    <td class="seller-groups__table-td">
					  <cc-loader style="width: 30px;" v-show="seller.loading" :show_txt="false" class="loader float-right" />
					  <span v-show="!seller.loading && seller.disabled != true && group.id && addBtnSelected" :class="{ disabled: seller.disabled }" :disabled="seller.disabled" @click.stop="add(seller)" class="material-icons-outlined plus-icon">add</span>
					</td>
                    <td class="seller-groups__table-td"><b style="color:var(--primary-color);">{{ seller.for_nome }}</b></td>
					<td class="seller-groups__table-td">{{ seller.usu_nome }}</td>
                  </tr>
				  <tr v-if="filters.per_page <= filters.total_items">
				    <td colspan="3">
					  <div class="box-footer clearfix not-print">
						<cc-pagination :key="'pag'"
									   classes="orange"
									   :total="filters.total_items"
									   :page="page"
									   :page_limit="11"
									   :items_by_page_value="filters.per_page"
									   v-on:change="load_sellers($event)">
						</cc-pagination>
					  </div>
					</td>
				   </tr>
                </tbody>
              </table>
			</div>
		  </div>
	  </div>
	  </div>
	</div>
  </div>
	<!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4">
				<div class="col-4">
					<label for="" class="label-control">Meus Grupos</label>
					<cc-loader v-show="is_loading_request_sellers"/>
					<div class="col-12 mb-3">
						<div class="row">
							<div class="col p-0">
								<cc-search :placeholder="'Novo Grupo'" :icon="'fa success fa-plus'" :theme="'theme-green'" class="mt-1" v-model="new_group.nome" @search="create" />
							</div>
						</div>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">#ID</th>
									<th class="text-left px-0">Nome</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-show="group.visible" v-for="group in groups" :key="group.id" @click.stop="select_group(group)" :class="{ selected: group.selected }">
									<td class="text-left px-3"><i v-show="group.selected" class="fas fa-hand-point-right mr-2 theme-blue"></i><b>#{{ group.id }}</b></td>
									<td class="text-left px-0">
										<cc-input v-model="group.nome" />
									</td>
									<td class="text-center">
										<i class="fa fa-floppy-o remove-btn theme-green" @click="update(group)" />
										<i v-show="!group.loading" class="fa fa-trash remove-btn" @click.stop="remove_group(group)" />
										<cc-loader v-show="group.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-4">
					<label for="" class="label-control">{{ group.nome || 'Selecione um grupo'}}</label>
					<cc-loader v-show="is_loading_available_sellers"/>
					<div class="sellers-list list-group">
						<cc-loader v-show="loading"></cc-loader>
						<table class="table" v-show="!loading">
							<thead>
								<tr>
									<th class="text-left px-3">Fornecedor</th>
									<th class="text-left px-0">Vendedor</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="seller in group_sellers" :key="seller.usu_id">
									<td class="text-left px-3"><b>{{ seller.provider.for_nome }}</b></td>
									<td class="text-left px-0">{{ seller.usu_nome }}</td>
									<td class="text-center">
										<i v-show="!seller.loading" class="fa fa-trash remove-btn" @click.stop="remove_seller(seller)"/>
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-left" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-4">
					<label for="" class="label-control">Fornecedores disponíveis</label>
					<cc-loader v-show="is_loading_available_sellers"/>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th></th>
									<th class="text-left px-0">Fornecedor</th>
									<th class="text-left px-0">Vendedor</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="seller in sellers" :key="seller.usu_id" v-show="!seller.disabled" :class="{ disabled: seller.disabled || !group.id }">
									<td>
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-left" />
										<i v-show="!seller.loading && seller.disabled != true && group.id" class="fa fa-plus add-btn" :class="{ disabled: seller.disabled }" :disabled="seller.disabled" @click.stop="add(seller)"/>
									</td>
									<td class="text-left px-0"><b>{{ seller.for_nome }}</b></td>
									<td class="text-left px-0">{{ seller.usu_nome }}</td>
								</tr>
								<tr v-if="filters.per_page <= filters.total_items">
									<td colspan="3">
										<div class="box-footer clearfix not-print">
											<cc-pagination :key="'pag'"
												classes="orange"
												:total="filters.total_items"
												:page="page"
												:page_limit="11"
												:items_by_page_value="filters.per_page"
												v-on:change="load_sellers($event)"></cc-pagination>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal> -->
</template>

<script>

import SellersService from "@/services/v2/sellers.service";
import RequestsService from "@/services/v2/requests.service";
import SellerGroupService from "@/services/v2/seller-group.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
	mixins: [ loaderMixin ],
	props: {
	  closeDialog: {
	    type: Function,
	  },
	},
	components: {
		ccPagination : Pagination
   },
  	data() {
		return {
			addBtnSelected: false,
			sellers: [],
			request_sellers: [],
			groups: [],
			group_sellers: [],
			group: {},
			new_group: {},
			page: 1,
			filters: {
				name: '',
				total_items : 0,
				per_page: 0
			},
			loading: false,
			is_loading_available_sellers: false,
			is_loading_request_sellers: false,
			service: new SellersService(),
			group_svc: new SellerGroupService(),
			req_svc: new RequestsService(),
			modal: {
				title: `gerenciar grupos de vendedores`,
				subtitle: `Gerencie seus vendedores em grupos de acordo com suas necessidades`,
				icon_title: 'fas fa-users',
				cancel_text: 'Voltar',
				style: {
					width: "90%"
				}
			},
		};
	},
  	methods: {
		update(group) {
			this.loading = true
			this.group_svc.update(group).then((response) => response.data)
			.then((data) =>  {
				this.load_groups().then(() => this.deactivate(data, this.groups))
				setTimeout(() => {
				  this.load_sellers()
				}, 400);
			}).then(this.loading = false)
		},
		create() {
			this.addBtnSelected = true
			this.loading = true
			this.group_svc.save(this.new_group)
			.then((response) => response.data)
			.then((data) =>  this.load_groups()
			.then(() => this.toggle_activation(data, this.groups)))
			.then(this.loading = false).then(() => {
				this.new_group = {}
				this.group_sellers = []
				this.process_sellers()
				setTimeout(() => {
				  this.load_sellers()
				}, 400);
			})
		},
		deactivate(group, collection) {
		  this.addBtnSelected = false
		  collection.filter(s => s.id !== group.id).forEach(s => s.selected = false)
		  let g = collection.find(s => s.id == group.id)
		  g.selected = false
		  this.group_sellers = []
		  this.sellers = []
		  this.group = g
		  this.$forceUpdate()
		},
		toggle_activation(group, collection) {
			collection.filter(s => s.id !== group.id).forEach(s => s.selected = false)
			let g = collection.find(s => s.id == group.id)
			g.selected = true
			this.group = g
			this.$forceUpdate()
		},
		process_sellers() {
			this.sellers.forEach(gs => gs.disabled = false)
			this.sellers.forEach(s => {
				this.group_sellers.forEach(gs => {
					if(gs.usu_id == s.usu_id) {
						s.disabled = true
					}
				})
			})
			this.$forceUpdate()
		},
		add(seller) {
			this.$set(seller, 'loading', true)
            seller.provider = { for_nome: seller.for_nome }
			return this.group_svc.add_to_group(this.group.id, { seller_id: seller.usu_id })
			.then(() => this.group_sellers.push(seller))
            .then(() => this.sellers = this.sellers.filter(s => s.usu_id != seller.usu_id))
            .then(() => this.group_sellers = this.order_group_sellers(this.group_sellers))
			.then(() => this.$set(seller, 'loading', false))
		},
		select_group(group) {
			this.addBtnSelected = true
			this.loading = true
			return this.group_svc.load(group.id)
			.then(response => response.data)
			.then(data => {
				this.toggle_activation(data, this.groups);
				this.group_sellers = this.order_group_sellers(data.sellers);
				setTimeout(() => {
				  this.load_sellers()
				}, 400);
				this.process_sellers()
				this.loading = false
			})
			.catch(error => {
				this.loading = false
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		remove_seller(seller) {
			this.$set(seller, 'loading', true)
            seller.for_nome = seller.provider.for_nome
			return this.group_svc.remove_from_group(this.group.id, seller.usu_id)
            .then(() => this.sellers.push(seller))
            .then(() => this.group_sellers = this.group_sellers.filter(s => s.usu_id != seller.usu_id))
            .then(() => this.sellers.forEach(s => s.provider = { for_nome: s.for_nome }))
            .then(() => this.sellers = this.order_group_sellers(this.sellers))
            .then(() => this.$set(seller, 'loading', false))
            .then(() => this.$forceUpdate())
		},
		remove_group(group) {
			this.$set(group, 'loading', true)
			return this.group_svc.remove(group.id)
			.then(() => this.load_groups()).then(() => this.$set(group, 'loading', false))
		},
		close() {
			this.$emit("close");
		},
		load_sellers(page = this.page) {
			this.loading = true
			return this.service.all({ page: page })
			.then(response => response.data)
			.then(data => {
				this.sellers = data.data;
				this.sellers.forEach(s => s.visible = true)
				this.filters.total_items = data.total
				this.filters.per_page = data.per_page
				this.process_sellers()
				this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_groups() {
			this.loading = true
			return this.group_svc.all()
			.then(response => response.data)
			.then(data => {
				this.groups = data;
				this.groups.forEach(s => s.visible = true)
				this.filters.total_group_items = data.total;
				this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_request_sellers() {
			this.loading = true
			return this.req_svc.sellers_in(this.request.numeroCotacao)
			.then(response => response.data)
			.then(data => {
				this.loader_msg("Alocando vendedores...")
				this.request_sellers = data.data;
				this.request_sellers.forEach(s => s.visible = true)
				this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		filter_sellers(collection, filter = '') {
			collection.forEach(s => s.visible = false)
			collection
			.filter(s => s.usu_nome.toLowerCase().includes(filter.toLowerCase()) || s.for_nome.toLowerCase().includes(filter.toLowerCase()))
			.forEach(s => s.visible = true)
			this.$forceUpdate()
		},
        order_group_sellers(sellers){
            let result = sellers.sort(function (a, b) {
                if (a.provider.for_nome > b.provider.for_nome) {
                    return 1;
                }
                if (a.provider.for_nome < b.provider.for_nome) {
                    return -1;
                }
                return 0;
            });
            return result;
        },
	},
	mounted() {
		this.load_groups()
		this.load_sellers()
	}
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 80vw;
  border-radius: 12px;
}
.seller-groups__main-container {
  width: 115vw;
  height: 95vh;
}
.seller-groups__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.seller-groups__header-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}
.seller-groups__closebtn-icon {
  color: #FFFFFF;
  font-size: 30px;
  cursor: pointer;
}
.seller-groups__containers-wrapper {
  display: flex;
  margin: 20px 0px;
  justify-content: space-evenly;
  align-items: center;
}
.seller-groups__content-wrapper {
  width: 36vw;
  height: 73vh;
  border-radius: 8px;
  //-webkit-box-shadow: 1px 2px 6px 3px rgba(0,0,0,0.10);
  //box-shadow: 1px 2px 6px 3px rgba(0,0,0,0.10);
}
.seller-groups__title-wrapper {
  margin: 10px;
}
.seller-groups__title {
  color: #707070;
  font-weight: 400;
  font-size: 26px;
}
.seller-groups__input-wrapper {
  margin: 20px 0px 10px 10px;
  display: flex;
  align-items: center;
  width: 34.8vw;
}
.seller-groups__input {
  width: 35vw;
  height: 5vh;
  padding: 0px 0px 0px 8px;
  border-top: 0.65px solid #E5E5E5;
  border-left: 0.65px solid #E5E5E5;
  border-bottom: 0.65px solid #E5E5E5;
  border-right: none;
  border-radius: 5.23px 0px 0px 5.23px;
  font-weight: 400;
  font-size: 14px;
  color: #A1A1A1;
}
.seller-groups__input:focus {
  outline: none !important;
  border: 0.65px solid var(--primary-color);
}
.seller-groups__input-icon {
  position: relative;
  left: -1.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  background-color: var(--primary-color);
  width: 4vw;
  height: 5vh;
  border-radius: 0px 5px 5px 0px;
  border-top: 0.65px solid #E5E5E5;
  border-bottom: 0.65px solid #E5E5E5;
  border-right: 0.65px solid #E5E5E5;
}
.seller-groups__table-container {
  margin: 20px 10px;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 60vh;
}
.first-table {
  max-height: 55vh;
}
.seller-groups__table-wrapper {
}
.seller-groups__table-thead {}
.seller-groups__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 14px;
  font-weight: 400;
}
.seller-groups__table-th {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
}
.seller-groups__table-tbody {
  border: 0.33px solid #e5e5e5;
}
.seller-groups__table-td {
  font-family: Rubik;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}
.seller-groups__table-td__icons-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.save-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.edit-icon {
  color: #605F5F;
  cursor: pointer;
  margin-right: 5px;
}
.trash-icon {
  color: #cd2a2a;
  cursor: pointer;
}
.plus-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.select-icon {
  color: var(--primary-color);
}
.selected {
  background-color: #f0f0f1;
}
.disabled {
  cursor: not-allowed !important;
}
.page-table-line-special {
  background: #f7f7f7;
}
.middle-table::-webkit-scrollbar {
  display: none;  // Safari and Chrome
}

@media only screen and (min-width: 1440px) {
  .seller-groups__table-th  {
    font-size: 20px;
  }
  .seller-groups__table-td {
    font-size: 20px;
  }
  .seller-groups__actions-title {
    font-size: 22px;
  }
  .seller-groups__title {
	font-size: 32px;
  }
  .seller-groups__input {
	font-size: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .seller-groups__containers-wrapper {
    flex-direction: column;
  }
  .seller-groups__main-container {
    overflow-y: auto;
  }
  .seller-groups__content-wrapper {
    width: 100vw;
    height: 70vh;
	margin: 10px;
  }
  .seller-groups__input-wrapper {
	width: 95vw;
  }
  .seller-groups__input-wrapper {}
  .seller-groups__input-icon {
    width: 15vw;
  }
  .seller-groups__input {
    width: 100vw;
  }
  .select-icon {
    display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 10px;
  }
  .table-input {
    width: 30vw;
  }
  .th-center {
	text-align: center;
  }
}

	@import "./manage-seller-groups.modal";
</style>
