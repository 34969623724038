<template>
    <div id="print-matrix">
        <div class="p-3 matrix-wrapper" id="matrix-wrapper">
            <div class="container-fluid">
                <table id="header-table">
                    <tbody>
                        <template v-if="buyer && provider">
                            <tr class="print-header text-center">
                                <td rowspan="7">
                                    <img class="logo" src="/images/logotipo.png"/>
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">Comprador</td>
                                <td colspan="3">Fornecedor</td>
                                <td colspan="3" v-if="provider.data_entrega">Detalhes do pedido</td>
                                <td v-if="provider.observacao">Observação:</td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">
                                    {{ buyer.trading_name.toUpperCase() }}
                                </td>
                                <td colspan="3">{{ provider.for_nome }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    {{ provider.data_entrega | formatDateOnly }}
                                </td>
                                <td v-if="provider.observacao">{{ provider.observacao }}</td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">{{ buyer.street }}, {{ buyer.district }}</td>
                                <td colspan="3">{{ provider.usu_nome }}</td>
                                <td colspan="3" v-if="provider.data_entrega && provider.tpc_nome">
                                    {{ provider.tpc_nome }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">{{ provider.cid_nome }}/{{ provider.est_sigla }}</td>
                                <td colspan="3">{{ provider.usu_email }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    Faturamento: {{ provider.faturamento_minimo | currency }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">CNPJ: {{ buyer.cnpj | cnpj }}</td>
                                <td colspan="3">Fone: {{ provider.usu_telefone }}</td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    Cond. de Pgto: {{ provider.forma }}
                                </td>
                            </tr>
                            <tr class="print-header">
                                <td colspan="3">Fone: {{ buyer.phone }}</td>
                                <td colspan="3"></td>
                                <td colspan="3" v-if="provider.data_entrega">
                                    <span>Status:
                                        <span v-if="!provider.id_pedido" class="status pending">
                                            pendente
                                        </span>
                                        <span v-else class="status ordered">
                                            gerado
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <table v-if="products" :style="{ width: `${20 + (stores.length * field_size)}rem` }" id="">
                    <thead>
                        <tr>
                            <td class="col pro-col text-center">Produto</td>
                            <td v-for="store in stores" :key="store.id" class="col text-center" @click="goto(store, total_store(store))">
                                <span class="description" style="color:color: #4D4F5C">
                                    <i class="fas fa-store-alt" style="color: #6081e8;font-size: 1rem;"></i>
                                    {{ store.name }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="col text-center pro-col">
                                <div class="d-flex p-0">
                                    <p class="mb-0 info">
                                        <span class="description">
                                            Vlr:
                                        </span>
                                    </p>
                                    <p class="mb-0 info" title="ultimo preço">
                                        <span class="description">Ult Cmp:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description">Qtd:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description" style="color: #4D4F5C">Emb:</span>
                                    </p>
                                    <p class="mb-0 info">
                                        <span class="description">Total:</span>
                                    </p>
                                </div>
                            </td>
                            <td class="col text-center" v-for="store in stores" :key="'tot-' + store.id">
                                <span class="description store-total"
                                    :class="{ cursor: total_store(store) > 0, higher: total_store(store) > 0 && total_store(store) < min_fat }">
                                        {{ total_store(store).toFixed(2) | currency }}
                                </span>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="body" v-if="stores && stores.length > 0" :style="{ width: `${20 + (stores.length * field_size)}rem` }">
                        <template v-for="(item, index) in products">
                            <tr :key="'name-' + item.pro_id" class="prod-row">
                                <td class="text-left border-bottom-0 pl-0 prod-info">
                                    <div>
                                        <p class="pro-name" style="position:relative">
                                            {{ item.pro_principal_nome || item.pro_descricao }}
                                            <span class="brand">{{ item.pro_observacao }}</span>
                                            <i v-if="item.observacao" class="fas fa-info-circle" :title="item.observacao.toUpperCase()"></i>
                                        </p>
                                        <div class="text-center border-top-0 pt-0">
                                            <div class="d-flex p-0">
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">
                                                        <span :style="item.valor < item.preco_ultima_compra ? 'color: green' : 'color: red'">
                                                            <svg v-if="item.valor < item.preco_ultima_compra" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
                                                            </svg>
                                                            <svg v-if="item.valor > item.preco_ultima_compra" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                                            </svg>
                                                            {{ item.valor | currency }}
                                                        </span>
                                                        <i v-if="!has_orders()" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(item)"></i>
                                                    </span>
                                                </p>
                                                <p class="mb-0 info" title="ultimo preço">
                                                    <span class="d-block text-center">{{ item.preco_ultima_compra | currency }}</span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">{{ quantity_sum(item.stores) }}</span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center">
                                                        {{ item.pro_unidade_compra ? item.pro_unidade_compra : `${item.pro_embalagem.split(" ")[0]} ${item.pro_embalagem.split(" ")[1]}` }}
                                                        <i class="far fa-edit" v-if="can('PRODUCT', 'UPDATE') && !has_orders() && item.pro_generico == 0 && !item.pro_principal"/>
                                                    </span>
                                                </p>
                                                <p class="mb-0 info">
                                                    <span class="d-block text-center total-val">{{ total(item.stores) | currency }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td v-for="(store, idx) in stores" :key="store.id" class="quantity-wrapper">
                                    <div class="column-quantity">
                                        <p class="rounded form-control text-center m-auto"
                                            :class="'store-quantity'"
                                            :id="'ipt-' + index + '-' + idx"
                                            :ref="'y-' + index + '-' + idx">
                                            {{ get_quantity(item.stores, store) }}
                                            <span class="column-stock">{{ get_stock_label(item.stores, store) }}</span>
                                        </p>
                                        <div style="position: relative">
                                            <cc-loader v-show="filter_product(item.stores, store.id).loading == true" class="quantity-loader" :show_txt="false"></cc-loader>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin, perm_mixin ],
    props: {
        min_fat: [ Number ],
        products: [ Array ],
        buyer: [ Object ],
        provider: [ Object ]
    },
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false,
            field_size: 8
        }
    },
    methods: {
        filter_product(stores, store_id) {
            let result = stores.find(s => s.loj_id == store_id)
            if(!result) {
                result = {
                    quantidade: null,
                    loj_id: store_id,
                    pro_id: stores[0].pro_id,
                    valor: stores[0].valor,
                    pro_generico: stores[0].pro_generico,
                    pro_principal: stores[0].pro_principal,
                    pro_embalagem: stores[0].pro_embalagem
                }
                stores.push(result)
            }
            return result;
        },
        get_quantity(stores, store) {
            let result = this.filter_product(stores, store.id)
            return result ? result.quantidade : null
        },
        get_stock(stores, store) {
            let result = this.filter_product(stores, store.id)
            return result ? result.estoque : null
        },
        get_stock_label(stores, store) {
            let result = this.filter_product(stores, store.id);
            return result.estoque ? " - ("+result.estoque+")" : null
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.quantidade || 0) }, 0)
        },
        total_store(store) {
            let result = 0
            this.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.loj_id == store.id) {
                        let value = pp.pro_generico == 0 && pp.pro_principal == null && this.user.request_type != 1 ? parseInt(pp.pro_embalagem.split(' ')[1])*pp.valor : pp.valor
                        result += value*pp.quantidade
                    }
                })
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => { return sum + ((store.quantidade || 0)*(store.pro_generico == 0 && store.pro_principal == null && this.user.request_type != 1 ? parseInt(store.pro_embalagem.split(' ')[1])*store.valor : store.valor)) }, 0)
        },
        has_orders() {
            let result = false
            this.products.forEach(p =>
                p.stores.forEach(pp => {
                    if(p.id_pedido || pp.id_pedido) {
                        result = true
                    }
                })
            )
            return result
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        get_has_stock(products,store){
            let result = false;
            products.forEach( product =>{
                for (let j = 0; j < product.stores.length; j++) {
                    if((product.stores[j].loj_id == store.id) && product.stores[j].estoque){
                        result = true;
                        break;
                    }
                }
            })

            return result;
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
    }
}
</script>

<style lang="scss" scoped>
    @import './_print-products-matrix.component'
</style>
