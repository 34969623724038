export default class OfferCollection {

    status = 'ALL'
    request
    stores = []

    current_store = null
    current_product = null
    current_offer = null

    constructor(request, stores = null, status = 'ALL') {
        this.request = request
        this.stores = stores
        this.status = status
        this.process()
    }

    process() {
        this.stores = this.stores.map(s => { return this.map_store(s) })
        this.request.requests_products.forEach(rp => {
            rp.subrequests.forEach(sub => {
                this.mount_store(sub, () => {
                    this.mount_product(rp.product, () => {
                        rp.offers.forEach(o => {
                            if(o.request_product_id == sub.request_product_id) {
                                this.mount_offers(rp.product, o, (offer) => {
                                    let po = o.pre_orders.find(po => po.request_product_store_id == sub.id)
                                    offer.preorder = this.init_pre_order(po, offer)
                                    offer.preorder.subrequest = sub
                                    offer.preorder.suggestion = this.calculate_suggestion(sub, offer)
                                })
                            }
                        })
                    })
                })
            })
        })
        this.clear_stores()
    }

    init_pre_order(po, offer) {
        return po ? {
            ...po,
            _quantity: po.quantity,
            winner: true
        }
        : {
            quantity: 0,
            _quantity: 0,
            offer_id: offer.id,
            winner: false
        }
    }

    store(id) {
        this.current_store = this.stores.find(s => s.id == id)
        return this
    }

    product(id) {
        this.current_product = this.current_store.products.find(p => p.id == id)
        return this
    }

    map_store(s) {
        return {
            id: s.loj_id,
            cnpj: s.loj_cnpj,
            name: s.loj_nomeFantasia,
            expanded: true,
            phone: s.loj_telefone,
            street: s.loj_endereco,
            city: s.cid_nome,
            state: s.est_nome
        }
    }

    calculate_suggestion(sub, offer) {
        return Math.round(sub.quantity / offer.quantity) * offer.quantity
    }

    mount_store(subrequest, callback) {
        this.current_store = this.stores.find(s => s.id == subrequest.store_id)
        if(!this.current_store) {
            this.current_store = { store_id: store_id, products: [ ] }
            this.stores.push(this.current_store)
        }
        if(!this.current_store.products) {
            this.current_store.products = []
        }
        callback()
    }

    mount_product(product, callback) {
        this.current_product = this.current_store.products.find(p => p.id == product.id)
        if(!this.current_product) {
            this.current_product = { ...product, offers: [ ] }
            this.current_store.products.push(this.current_product)
        }
        callback(this.current_product)
    }

    mount_offers(product, offer, callback) {
        this.current_offer = this.current_product.offers.find(o => o.id == offer.id)
        if(!this.current_offer) {
            this.current_offer = { ...offer, product, provider: offer.seller.provider }
            this.current_product.offers.push(this.current_offer)
        }
        callback(this.current_offer)
    }

    clear_stores() {
        this.stores = this.stores.filter(s => {
            return s.products// && s.products.some(s => s.preorders.length > 0)
        })
    }

    total_for_seller(seller_id) {
        return this.stores.reduce((i, store) => {
            return i + store.products
                .reduce((x, { offers }) => {
                    return x + offers.filter(o => o.seller_id == seller_id).reduce((y, { price, pre_orders }) => {
                        return y + pre_orders.reduce((z, { quantity }) => z + (quantity * price), 0)
                    }, 0)
                }, 0)
        }, 0)
    }

    total_for_seller_on_store(seller, store) {
        let s = this.stores.find(s => s.id == store.id).sellers.find(s => s.id == seller.id)
        return s.preorders
            .reduce((x, { offer, quantity }) => {
                return x + (quantity * offer.price)
            }, 0)
    }

    get total() {
        return this.stores.reduce((x, store) => {
            return x + this.total_for_store(store)
        }, 0)
    }

}
