<template>
	<!-- <cc-modal :modal="modal" @close="close">
			<div slot="body" class="container-fluid">
				<div class="cc-loader" :class="{ hidden : !loading }">
					<img src="/images/loader.svg"/>
					<span>Carregando</span>
				</div>
				<div class="row" :class="{ hidden : loading }">
					<div class="col-12">
						<label class="label-control">Produto</label>
						<input type="text" class="form-control" v-model="product.produtoDescricao" disabled="disabled">
					</div>
					<div class="col-6">
						<label class="label-control">Marca </label>
						<input type="text" class="form-control" v-model="product.pro_observacao" disabled="disabled">
					</div>
					<div class="col-6">
						<label class="label-control">Obs</label>
						<input type="text" class="form-control" v-model="product.observacao" :disabled="!modal.permitted">
					</div>
					<div class="col-3">
						<label class="label-control">Ultima compra </label>
						<input type="text" class="form-control" v-model="product.ultimaCompra" disabled="disabled">
					</div>
					<div class="col-3">
						<label class="label-control">Preço digitado</label>
						<input type="text" class="form-control" v-model="typing.valor" disabled="disabled">
					</div>
					<div class="col-6 new-price">
						<label></label>
						<div class="row">
							<div class="col-6">
								<label>Informe o novo preço</label>
							</div>
							<div class="col-4">
								<money v-model="new_price" v-bind="money"></money>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3 align-items-center d-flex consent">
						<toggle-button
							v-model="modal.permitted"
							:color="'var(--primary-color)'"
							:height="30"/>
						<label>Confirmo que o novo preço foi acordado com o vendedor: {{ typing.nomeVendedor }}</label>
					</div>
				</div>
			</div>
	</cc-modal> -->
	<div class="modal-mask">
		<div class="modal-content-container">
			<div class="negotiate-modal-title">
				Negociação
				<span v-on:click="close" class="material-icons negotiate-modal-close-icon">cancel</span>
			</div>
			<cc-loader style="margin: 6vh 0" v-show="loading"/>
			<div v-if="!loading" class="negotiate-modal-body">
				<div>
					<div class="negotiate-modal-input-title">Produto</div>
					<div class="negotiate-modal-input-value">{{product.produtoDescricao}}</div>
				</div>
				<div class="negotiate-modal-input-row">
					<div class="negotiate-modal-input-smal">
						<div class="negotiate-modal-input-title">Marca</div>
						<div class="negotiate-modal-input-value">{{product.pro_observacao || '--'}}</div>
					</div>
					<div class="negotiate-modal-input-smal">
						<div class="negotiate-modal-input-title">OBS</div>
						<div v-if="!modal.permitted" class="negotiate-modal-input-value">{{product.observacao || '--'}}</div>
						<div v-else class="negotiate-modal-input-value"><input v-model="product.observacao" class="negotiate-modal-input-value-edit" type="text"></div>
					</div>
				</div>
				<div class="negotiate-modal-input-row">
					<div class="negotiate-modal-input-smal">
						<div class="negotiate-modal-input-title">Ultima Compra</div>
						<div class="negotiate-modal-input-value">R$: {{product.ultimaCompra ? product.ultimaCompra.toFixed(2) : '--'}}</div>
					</div>
					<div class="negotiate-modal-input-smal">
						<div class="negotiate-modal-input-title">Preço Digitado</div>
						<div class="negotiate-modal-input-value">R$: {{typing.valor? parseFloat(typing.valor).toFixed(2) : '--'}}</div>
					</div>
				</div>
				<div class="negotiate-modal-input-row">
					<div class="negotiate-modal-input-smal">
						<div class="negotiate-modal-input-title">Informe o novo Preço</div>
						<div v-if="!modal.permitted" class="negotiate-modal-input-value">R$: --</div>
						<div v-else class="negotiate-modal-input-value"><money min="0" v-bind="money" v-model="new_price" class="negotiate-modal-input-value-edit" /></div>
					</div>
				</div>
				<div class="negotiate-modal-togle">
					<toggle-button v-model="modal.permitted" :color="'var(--primary-color)'"	:height="30"/>
					<div class="negotiate-modal-togle-text">Confirmo que o novo preço foi acordado com o vendedor: {{ typing.nomeVendedor }}</div>
				</div>
				<div class="product-modal-body-buttons">
						<Button class="mobile-button-size" :text="'Cancelar'" :action="close"/>
						<Button :disabled="!modal.permitted" class="second-button mobile-button-size" :text="'Atualizar'" :action="update_quotation_typing"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import QuotationService from "@/services/QuotationService";
	import StorageService from "@/services/local-storage.service.js";
	import Button from "@/components/ui/buttons/Standard-Button.vue"


	export default {
		props: ["product", "typing", "quotation_id"],
		components:{
			Button
		},
		data() {
			return {
				new_price: 0,
				loading: false,
				service: new QuotationService(),
				modal: {
					title: "negociação",
					submit: this.update_quotation_typing,
					subtitle: 'Consiga melhores preços e condições negociando',
					icon_title: 'fa fa-handshake',
					unpermitted_message: "Aceite o termo de consentimento para continuar.",
					permitted: false
				},
				money: {
					decimal: ',',
					thousands: '.',
					prefix: 'R$ ',
					precision: 2,
					masked: false
				}
			}
		},
		methods: {
			close() {
				this.loading = false;
				this.$emit('close', false, this.new_price, this.product.observacao);
			},
			update_quotation_typing() {
				this.loading = true;
				this.service = new QuotationService();
				this.service.update_quotation_typing({ seller_id: this.typing.idVendedor,
													quotation_id: this.quotation_id,
													provider_id: this.typing.idFornecedor,
													product_id: this.typing.pro_id,
													unit_price: this.new_price,
                                                    observation: this.product.observacao })
				.then(response => {
					this.$set(this.typing, 'valor', this.new_price)
					this.close(true);
				})
				.catch(data => {
					this.close(true);
				})
			}
		},
		watch: {
			"modal.permitted" : function(consent) {
				let key = "permitted_sellers";
				let val = this.typing.idVendedor;
				if(this.modal.permitted) {
					StorageService.add_to_array(key, val);
				} else {
					StorageService.remove_from_array(key, val);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "./negotiate-offer.modal";
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}
.modal-content-container{
  background-color: white;
  min-width: 60vw;
  border-radius: 12px;
  margin: 0 auto;
  margin-top: 15vh;
  font-size: 1vw;
}
.negotiate-modal-title{
	background-color: var(--primary-color);
	color: white;
	display: flex;
	justify-content: space-between;
	border-radius: 10px 10px 0px 0px;
	padding: 1vh 1vw;
	font-weight: 500;
	font-size: 2.2em;
	letter-spacing: -0.02em;
	align-items: center;
}
.negotiate-modal-close-icon{
	font-size: 1.5em;
	cursor: pointer;
}
.negotiate-modal-body{ padding: 2vh 2vw; }
.negotiate-modal-input-title{
	font-weight: 400;
	font-size: 1.45em;
	letter-spacing: 0.15px;
	color: #898989;
}
.negotiate-modal-input-value{
	border: 1px solid #E5E5E5;
	border-radius: 8px;
	padding: 1vh 1vw;
	font-weight: 400;
	font-size: 1.05em;
	letter-spacing: 0.15px;
	color: #A1A1A1;
	display: flex;
	align-items: center;
	min-height: 6vh;
}
.negotiate-modal-input-row{
	display: flex;
	justify-content: space-between;
	margin-top: 2vh;
}
.negotiate-modal-input-smal{width: 45%;}
.negotiate-modal-togle{
	margin-top: 2vh;
	display: flex;
	align-content: center;
}
.negotiate-modal-togle-text{
	font-weight: 300;
	font-size: 1.1em;
	color: #605F5F;
	margin-left: 1vw;
}
.product-modal-body-buttons{
	display: flex;
	justify-content: flex-end;
	margin-top: 4vh;
}
.second-button{ margin-left: 1.5vw; }
.negotiate-modal-input-value-edit{
	border: none;
	font-weight: 400;
	font-size: 1.05em;
	letter-spacing: 0.15px;
	color: #A1A1A1;
	width: 100%;
}
.negotiate-modal-input-value-edit:focus{
	outline: none;
}
@media only screen and (max-width: 1000px) {
	.modal-content-container{font-size: 1.5vw;}
}
@media only screen and (max-width: 750px) {
	.modal-content-container{font-size: 2vw;}
}
@media only screen and (max-width: 500px) {
	.modal-content-container{
		font-size: 2.5vw;
		width: 110vw;
	}
	.product-modal-body-buttons{
		justify-content: center;
	}
	.button-containear{
		padding: 0 5vw;
	}
	.mobile-button-size{
		width: 45%;
	}
}
</style>
