import http from "../http";
import Service from "../../Service";

/**
 * @type OrderService
 */
export default class OrderService extends Service {

    cancel_legacy(id) {
		return http.delete(`/clients/orders/${id}`, {
            headers: this.getHeaders()
        });
	}

	send_by_store_seller(id, store_id, seller_id) {
		return http.post(`/v2/clients/requests/${id}/stores/${store_id}/sellers/${seller_id}/orders/send`, {'vendedor_id': seller_id}, {
            headers: this.getHeaders()
        });
	}

    cancel_by_store_seller(id, store_id, seller_id) {
		return http.delete(`/v2/clients/requests/${id}/stores/${store_id}/sellers/${seller_id}/orders/cancel`, {
            headers: this.getHeaders()
        });
	}

    order_by_store_seller(id, store_id, seller_id) {
		return http.get(`/v2/clients/requests/${id}/stores/${store_id}/sellers/${seller_id}/orders/last`, {
            headers: this.getHeaders()
        });
	}

    sync_legacy(id) {
		return http.get(`/v2/clients/orders/${id}/sync`, {
            headers: this.getHeaders()
        });
	}

    download_pdf(id) {
		return http.get(`/v2/clients/orders/${id}/pdf`, {
            headers: this.getHeaders(),
            responseType: 'arraybuffer'
        });
	}

    download_pdf_by_store(request_id, store_id) {
		return http.get(`/v2/clients/orders/stores/${store_id}/pdf`, {
            params: { request_id },
            headers: this.getHeaders(),
            responseType: 'arraybuffer'
        });
	}
}
