<template>
    <div class="repurchase-table-line-mobile" :class="getSpecialBackground(idx)">
        <div class="repurchase-mobile-title-line" @click="updateItem(item)">
            <span v-if="item.check" class="material-icons-outlined repurchase-check-icon">check_box</span>
            <span v-else class="material-icons-outlined repurchase-check-icon">check_box_outline_blank</span>
            {{ item.produto && item.produto.produto_principal ? item.produto.produto_principal.pro_descricao : item.produto.pro_descricao  }}
        </div>
        <div class="repurchase-mobile-colum-container">
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Pedido</p>
                <p>{{ item.id_pedido }}</p>
            </div>
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Data</p>
                <p>{{ item.create_at | formatDateOnly }}</p>
            </div>
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Usuário</p>
                <p>{{ item.user ? item.user.usu_login : '--' }}</p>
            </div>
        </div>
        <div class="repurchase-mobile-colum-container">
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Empresa</p>
                <p>{{ item.pedido ?
        item.pedido.store.loj_descricao : '--' }}</p>
            </div>
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Embalagem</p>
                <p>{{ getProductPack(item) }} </p>
            </div>
            <div class="repurchase-mobile-colum">
                <p class="repurchase-mobile-colum-title">Quantidade</p>
                <p>{{ item.observacao ? item.observacao : '--' }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RepurchaseLineMobile',
    props: ['getSpecialBackground', 'idx', 'item', 'getProductPack' ,'updateItem']
}
</script>

<style scoped lang="scss">

.repurchase-table-line-mobile{
    display: none;
    margin-top: 5vh;
    padding-top: 2vh;
    padding-left: 2vw;
    font-size: 1.1em;
}
.repurchase-mobile-title-line{
    display: flex;
    align-items: center;
    gap: 3vw;
    font-weight: 600;
    font-size: 1.3em;
    color: #605F5F;
    margin-bottom: 2vh;
}
.repurchase-check-icon {
    font-size: 1.5em;
    cursor: pointer;
    color: var(--primary-color);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.repurchase-mobile-colum-container{
    display: flex;
    justify-content: space-evenly;
}
.repurchase-mobile-colum{
    text-align: center;
    width: 30%;
    font-size: 1.2em;
    color: #605F5F;
    margin-bottom: 1vh;
    p{
        margin-bottom: 0;
    }
}
.repurchase-mobile-colum-title{
    font-weight: bold;
    font-size: 1.2em;
}


@media only screen and (max-width: 850px) {
    .repurchase-table-line-mobile{
    display: block;
}
}
</style>