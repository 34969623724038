<template>
    <div class="repurchase-table-line" :class="getSpecialBackground(idx)">
        <div class="table-header-text check-width" @click="updateItem(item)">
                <span v-if="item.check" class="material-icons-outlined repurchase-check-icon">check_box</span>
                <span v-else class="material-icons-outlined repurchase-check-icon">check_box_outline_blank</span>
            </div>
            <div class="repurchase-table-line-text product-width">{{ item.produto && item.produto.produto_principal ? item.produto.produto_principal.pro_descricao : item.produto.pro_descricao  }}
                <br>{{ item.id_pedido }}
            </div>
            <div class="repurchase-table-line-text date-width"> {{ item.create_at | formatDateOnly }}
            </div>
            <div class="repurchase-table-line-text user-width">{{ item.user ? item.user.usu_login : '--' }} <br> {{ item.pedido ?
                item.pedido.store.loj_descricao : '--' }}</div>

            <div class="repurchase-table-line-text request-width">{{ item.observacao ? item.observacao : '--' }} - {{ getProductPack(item) }} </div>
    </div>
</template>

<script>
export default {
    name: 'RepurchaseLineDesktop',
    props: ['getSpecialBackground', 'idx', 'item', 'getProductPack','updateItem']
}
</script>

<style scoped lang="scss">
.repurchase-table-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.table-header-text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.19em;
    line-height: 17px;
    color: #605F5F;
}
.check-width {
    width: 5%;
    text-align: center;
}
.repurchase-check-icon {
    font-size: 1.5em;
    cursor: pointer;
    color: var(--primary-color);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.repurchase-table-line-text {
    font-weight: 300;
    font-size: 1.19em;
    color: #605F5F;
    padding: 1vh 0;
}
.date-width {
    flex: 1;
}

.request-width {
    width: 18%;
}

.product-width {
    width: 40%;
}

.user-width {
    width: 20%;
}



.suplier-width {
    width: 10%;
}



.repurchase-store-width {
    width: 18vw;
}



.page-table-line-special {
    background: #F7F7F7;
}
@media only screen and (max-width: 850px){
    .repurchase-table-line{
        display: none;
    }
}
</style>