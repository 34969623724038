<template>
    <div class="management-modal-mask">
        <div class="management-modal-content-container">
            <div class="management-modal-header-container">
                <h1 class="management-modal-header-title">Editar Grupo de Produtos</h1>
                <span @click="close" class="material-icons management-modal-closebtn-icon">cancel</span>
            </div>
            <div class="management-modal-body">
                <h1 class="management-modal-body-title">Novo Nome</h1>
                <div class="management-modal-title-row">
                    <input type="text" @keyup.enter="updateGroup(newGroupText)" v-model="newGroupText" class="manage-categories__input">
                    <StandardButton text="Atualizar" iconName="save" :action="() => this.updateGroup(newGroupText)"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";

export default {
    name: 'EditGroupModal',
    props: ['group', 'close', 'updateGroup'],
    components: {
        StandardButton,
    },
    data(){
        return{
            newGroupText: ''
        }
    }
}
</script>

<style scoped lang="scss">
.management-modal-mask {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.management-modal-content-container {
    background-color: white;
    width: 35vw;
    border-radius: 12px;
}

.management-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.management-modal-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2em;
}

.management-modal-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.management-modal-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.management-modal-body {
    padding: 2vh 2vw;
}
.manage-categories__input {
    width: 16vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}
.management-modal-body-title{
    color: #898989;
    font-weight: 500;
    font-size: 1.5em;
}
@media only screen and (max-width: 800px) {
    .management-modal-content-container{
        width: 80vw;
    }
    .manage-categories__input{
        width: 50%;
    }
}
</style>