<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="mobile-list__header-container">
                <span class="mobile-list__header-title">
                    Listar pedidos
                </span>
                <span @click="close" class="material-icons mobile-list__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="body-modal">
                <StandardInput :title="'Buscar por loja'" width="50%" :action="store => selectStore = store.loj_id" type="select" :list="stores"/>

                <div class="order-page-table-header">
                    <div class="id-width">ID / Cotação</div>
                    <div class="store-width">Loja / Data</div>
                    <div class="seller-width">Fornecedor / Vendedor</div>
                    <div class="value-width">Valor(R$)</div>
                    <div class="confirmationdate-width confirmationdate-header-text">Status / Data Confirmação</div>
                    <div class="review-width">Avaliação</div>
                    <div class="actions-width">Ações</div>
                </div>
                <div class="table-size">
                    <div class="order-page-table" v-for="(data, index) in orders" :key="index"
                        :class="getSpecialBackground(index)">
                        <div class="checkbox-width order-page-table-separator mobile">
                            <!-- mobile -->
                            <div class="" style="font-weight: bold;">
                                {{ data.fornecedor ? data.fornecedor.for_nome : '--' }}
                            </div>
                        </div>

                        <!-- mobile -->
                        <div class="mobile" style=" width: 100%; gap: 2vw; flex-direction: row; ">
                            {{ data.loj_descricao }}
                        </div>

                        <!-- desktop -->
                        <div class="desktop id-width order-page-table-separator">
                            <div>
                                {{ data.id_pedido }} <br> {{ data.id_cotacao }}
                            </div>
                        </div>
                        <!-- desktop -->
                        <div class="desktop store-width order-page-table-separator order-table-store-content">
                            {{ data.loj_descricao }} <br> {{ getDate(data.data_geracao) }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row">
                            <div style="width: 30%;">
                                <div class="order-table-mobile-header">ID</div>
                                {{ data.id_pedido }}
                            </div>
                            <div>
                                <div class="order-table-mobile-header">Cotação</div>
                                {{ data.id_cotacao }}
                            </div>

                            <div style="width: 30%; display: flex; flex-direction: column; align-items: flex-end;">
                                <div class="order-table-mobile-header">Data</div>
                                {{ getDate(data.data_geracao) }}
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="desktop seller-width order-page-table-separator">
                            <div class="order-table-seller-content">
                                <div style="font-weight: bold;">{{ data.fornecedor ? data.fornecedor.for_nome : '--' }}
                                </div>
                                <div>{{ data.vendedor }}</div>
                            </div>
                        </div>
                        <!-- desktop -->
                        <div class="desktop value-width order-page-table-separator">
                            <div
                                style="color: white; background-color: green; opacity: 0.5; margin: 0 0.5vw; padding: 0 1vw; border-radius: 10px;">
                                {{ data.valor_pedido | currency }}
                            </div>

                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row">
                            <div style="width: 30%;">
                                <div class="order-table-mobile-header"> Vendedor</div>
                                {{ data.vendedor }}
                            </div>

                            <div style="width: 30%; text-align: center;">
                                <div style="justify-content: center; width: 100%; " class="order-table-mobile-header">
                                    Status</div>
                                <span v-if="data.status === 'C'" title="Confirmado pelo fornecedor"
                                    style="color: #30AA4C; cursor: default"
                                    class="material-icons-round">check_circle</span>
                                <span v-else title="Aguardando confirmação do fornecedor" class="material-icons"
                                    style="color: #CD2A2A; cursor: default">cancel</span>
                                <span v-if="data.status === 'C'">
                                    <span v-if="data.status_recebido" title="Pedido recebido!"
                                        style="color: #30AA4C; cursor: default"
                                        class="material-icons">local_shipping</span>
                                    <span v-else title="Pedido ainda não foi recebido!"
                                        style="color: gray; cursor: default"
                                        class="material-icons">local_shipping</span>
                                </span>
                            </div>

                            <div style="width: 30%; display: flex; flex-direction: column; align-items: flex-end;">
                                <div class="order-table-mobile-header">Valor(R$)</div>
                                <div
                                    style=" color: white; background-color: green; opacity: 0.5; margin: 0 0.5vw; padding: 0 1vw; border-radius: 10px;">
                                    {{ data.valor_pedido | currency }}
                                </div>

                            </div>

                        </div>

                        <!-- desktop -->
                        <div class="desktop confirmationdate-width order-page-table-separator">
                            <span v-if="data.status === 'C'" title="Confirmado pelo fornecedor"
                                style="color: #30AA4C; cursor: default" class="material-icons-round">check_circle</span>
                            <span v-else title="Aguardando confirmação do fornecedor" class="material-icons"
                                style="color: #CD2A2A; cursor: default">cancel</span>
                                <br>
                            {{ getDate(data.data_confirmacao) }}
                        </div>
                        <!-- desktop -->
                        <div class="desktop review-width order-page-table-separator">
                            {{ data.nota ? data.nota : '--' }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row" v-if="data.isExpand">
                            <div>
                                <div class="order-table-mobile-header">Data de Confirmação</div>
                                {{ getDate(data.data_confirmacao) }}
                            </div>
                            <div style="text-align: center;">
                                <div class="order-table-mobile-header">Avaliação</div>
                                {{ data.nota ? data.nota : '--' }}
                            </div>

                        </div>

                        <div class="actions-width">
                            <span class="material-icons" @click="print(data)" style="font-size: 1.5em;
                            color: #CD2A2A; cursor: pointer">picture_as_pdf</span>
                            <span v-if="data.order_id" class="material-icons" @click="sendPage('subrequest',data)" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                            <span v-else class="material-icons" @click="sendPage('order-info',data)" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                        </div>
                    </div>
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="50"
                        :total="total"
                        :page="1"
                        :page_limit="11"
                        v-on:change="loadPage($event)"></cc-paginacao>
                </div>

            </div>
        </div>
    
    <cc-loader-full v-if="isLoading" />
    <OrderPartial v-if="order_data" :order_data="order_data" />
    </div>
</template>

<script>
import OrderService from '@/services/OrderService.js'
import Paginacao from "@/components/cliente/base-components/Pagination";
import OrderPartial from "@/components/partials/_print_order.partial";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    name: 'ordersModal',
    props: {
        close: {
            type: Function
        },
        suplier: {
            type: Object
        }
    },
    components: {
        ccPaginacao: Paginacao,
        OrderPartial,
        StandardInput
    },
    data() {
        return {
            svc: new OrderService(),
            isLoading: true,
            orders: [],
            total: 0,
            current_order: null,
            order_data: null,
            selectStore: undefined
        }
    },
    mounted() {
        this.svc.getStores().then(resp => this.stores = resp.data.requisitantes.map(v => { return {text: v.loj_descricao, value: v}}))
        this.loadPage(1)
    },
    watch: {
        selectStore() {
           this.loadPage(1)
        }
    },
    methods: {
        loadPage(page){
            this.isLoading = true
            this.svc.findOrdersByQuotationId(undefined, this.suplier.id, this.selectStore, page).then(resp => {
                this.total = resp.data.pedidos.total;
                this.orders = resp.data.pedidos.data;
                this.isLoading = false
            })
        },
        sendPage(name, data){
            if (name=='subrequest'){
                const routeData = this.$router.resolve({name, params: {
                    return_to: 'monitors-page',
                    id: data.id_cotacao,
                    seller_id: data.usu_id,
                    store_id: data.id_loja,
                    tab: 'pedidos',
                    provider_id: data.for_id },
                });
                window.open(routeData.href, '_blank');
            } else {
                const routeData = this.$router.resolve({name, params: {
                    backRouteName: 'monitors-page',
                    id: data.id_cotacao,
                    sellerid: data.usu_id,
                    sid: data.id_loja,
                    providerid: data.for_id },
                    query: { backRoute: 2 } ,
                });
                window.open(routeData.href, '_blank');
            }
            
        },
        print(order) {
            this.current_order = order
            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                [ order.id_pedido ], [ order.id_cotacao ], [ order.id_loja ], [ order.usu_id ]
            ]
            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };
            this.svc.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                console.log('print order', order)
                Vue.nextTick(() => {
                    window.document.title = `pedido-${order.id_pedido}.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        getSpecialBackground(index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        getDate(date) {
            if (date) { return moment(String(date)).format("DD/MM/YYYY") }
            else { return '--' }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    width: 100vw;
    border-radius: 12px;
}

.mobile-list__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.mobile-list__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.mobile-list__closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.page-table-line-special {
    background: #F7F7F7;
}

.order-page-table-header {
    margin-top: 4vh;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    padding: 0.7vh 0;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}

.checkbox-width {
    width: 3.5%;
    text-align: center;
    color: var(--primary-color);
    cursor: pointer;
}

.id-width {
    width: 10%;
    text-align: center;
}

.store-width {
    width: 22%;
    text-align: center;
    padding: 0 0.5%;
}

.date-width {
    width: 9%;
    text-align: center;
}

.price-width {
    width: 7%;
    text-align: center;
}

.seller-width {
    flex: 1;
    text-align: center;
}

.value-width {
    width: 11%;
    text-align: center;
}

.status-width {
    width: 7%;
    text-align: center;
}

.confirmationdate-width {
    width: 14%;
    text-align: center;
}

.confirmationdate-header-text {
    font-size: 0.8em;
}

.suplier-width {
    width: 14%;
    text-align: center;
}

.review-width {
    width: 8%;
    text-align: center;
}

.actions-width {
    width: 7%;
}

.page-filters__right-wrapper {
    width: 25vw;
}

.page-filters__right-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 1.2em;
}

.body-modal {
    height: 90vh;
    padding: 2vw;
}

.order-page-table {
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    padding: 2vh 0;
}

.order-page-table-separator {
    border-right: 0.5px solid #DDE5E9;
}

.order-page-filter-section1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.selected-options-containear {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1.5vw;
    min-height: 7vh;
}
.table-size{
    height: 55vh;
    overflow: auto;
}
.tablet{ display: none; }
.mobile{ display: none; }
@media only screen and (max-width: 800px) {
    .order-page-filter-container{
        flex-direction: column;
    }
    .modal-mask{font-size: 2vw;}
    .modal-content-container{
        width: 115vw;
    }
    .body-modal{
        height: 100vh;
    }
    .table-size{
        height: 80vh;
        margin-top: 2vh;
    }
    .desktop{ display: none !important; }
    .tablet{ display: block; }
    .order-page-filter-section1{
        margin-bottom: 2vh;
    }
    .order-page-container{
        font-size: 1.25vw;
    }
    .order-page-filter-section1{
        margin-bottom: 0;
    }

    .tablet{ display: none; }
    .mobile{
        display: block;
    }
    .order-page-filter-section2{
        width: 100%;
        display: flex;
    }
    .page-filters__right-title-wrapper{
        margin-top: 1vh;
    }
    .order-page-filter-section1{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
     }
     .mobile-table-row{
        display: flex;
        width: 100%;
        justify-content: space-between;
     }
     .mobile-expand-icon{
        font-size: 1.5em;
        color: var(--primary-color);
     }
    .order-page-info-containear{
        flex-direction: column;
        align-items: flex-start;
    }
    .order-page-print-button{
        width: 40%;
        margin-top: 2vh;
    }
    .order-page-container{
        font-size: 2vw;
    }
    .order-page-table-header{display: none;}
    .order-page-table{
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 3vh;
        font-size: 1.5em;
    }
    .order-page-table-separator{
        border-right: none;
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
        padding-right: 3vw;
    }
    .order-table-mobile-header{
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
        text-align: inherit;
    }
    .hide-mobile-filters{
        display: none;
    }
    .order-table-seller-content{
        text-align: end;
    }
    .actions-width{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        padding-right: 3vw;
    }
    .checkbox-width{
        color: unset;
        justify-content: flex-start;
        gap: 3vw;
        font-size: 1.1em;
        align-items: center;
    }
    .mobile-id-row{
        display: flex;
        align-items: center;
        gap: 1.5vw;
    }
    .page-filters__right-wrapper {
        width: 100%;
    }
    .mobile-filter-button{
        display:flex;
    }
    .mobile-buttons-row{
        display: flex;
        gap: 10%;
        margin-top: 2vh;
        width: 100%;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 500px) {.order-page-container{ font-size: 3vw; }}
@media only screen and (max-width: 200px) {.order-page-container{ font-size: 3.25vw; }}
</style>