<template>
    <div class="product-brands-tabs-container">
        <div class="product-brands-tab" :class="getActiveTab('brand')" v-on:click="setTab('brand')">
            <span class="material-icons-outlined product-brands-tab-icon">sell</span>
            <div class="product-brands-tab-text">Marcas</div>
        </div>
        <div class="product-brands-tab" :class="getActiveTab('supliers')" v-on:click="setTab('supliers')">
            <span class="material-icons-outlined product-brands-tab-icon">groups</span>
            <div class="product-brands-tab-text">Fornecedor</div>
        </div>
        <div class="product-brands-tab" :class="getActiveTab('price')" v-on:click="setTab('price')">
            <span class="material-icons-outlined product-brands-tab-icon">currency_exchange</span>
            <div class="product-brands-tab-text">Preços</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FoodServiceTabSelect',
    props: ['tab', 'setTab'],
    methods:{
        getActiveTab(tab) {
            if (tab == this.tab) return 'product-brands-tab-active'
        },
    },
}
</script>

<style scoped>
.product-brands-tabs-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.product-brands-tab {
    padding: 1vh 0;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.product-brands-tab-icon {
    font-size: 2em;
    color: var(--primary-color);
}

.product-brands-tab-text {
    font-size: 1.5em;
}

.product-brands-tab:hover {
    background: rgba(255, 113, 16, 0.15);
    border-radius: 8px;
    cursor: pointer;
    color: var(--primary-color);
}
.product-brands-tab-active {
    background: rgba(255, 113, 16, 0.15);
    border-radius: 8px;
    cursor: pointer;
    color: var(--primary-color);
}

@media only screen and (max-width: 850px){
    .product-brands-tabs-container{
        width: 100%;
    }
}
</style>
