<template>
  <div class="modal-mask">
    <div class="modal-content-container">

      <div class="new-product__header-container">
        <span class="new-product__header-title">
          Adicionar Produto
        </span>
        <span @click="closeDialog" class="material-icons new-product__closebtn-icon">
          cancel
        </span>
      </div>

      <!-- <StandardButton class="standard-btn" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'" />
        <form @submit="load_products()">
          <div class="standard-buttons__wrapper">
            <div class="new-product__input-wrapper">
              <span class="new-product__input-title">Buscar produto</span>
              <input :placeholder="'Pesquisar produto'" v-model="product_name" @keyup="load_products()"
                class="new-product__input">
            </div>
            <div class="new-product__input-wrapper">
              <span class="new-product__input-title">Categorias</span>
              <cc-select-v2 class="select-input" :tracker="'cat_id'" :text="'cat_descricao'" :options="categories"
                v-model="cat"></cc-select-v2>
            </div>
          </div>
        </form> -->

      <cc-loader-full v-if="loading" />
      <div class="new-product-modal-body">

        <div class="new-product-inputs-container">
          <StandardInputAuto :action="setProductName" :title="'Buscar produto'" class="new-product-input"
            :type="'text'" />
          <StandardInputAuto :action="setCat" :title="'Buscar produto'" class="new-product-input" :type="'select'"
            :list="categories" />
          <StandardButton :action="closeDialog" class="new-product-inputs-button" :iconName="'arrow_back'"
            :text="'Voltar'" />
        </div>

        <div class="new-product-table-container">
          <div class="new-product-table-header">
            <div class="expand-width"></div>
            <div style="font-weight: bold; color: #605F5F;" class="product-width">Produto</div>
            <div class="package-width">Embalagem</div>
            <div class="code-width">Código</div>
          </div>
          <div v-for="(product, idx) in products" :key="idx">
            <div v-on:click="() => { product.isExpanded = !product.isExpanded, $forceUpdate() }"
              class="new-product-line-header" :class="{ 'new-product-line-header-expanded': product.isExpanded }">
              <div class="expand-width new-product-mobile-line-container">
                <span class="material-icons-outlined new-product-table-colapse-icon"
                  :class="{ 'new-product-table-colapse-icon-expanded': product.isExpanded }">expand_more</span>
              </div>
              <div class="product-width new-product-mobile-line-container">
                <div class="new-product-mobile-header">Produto</div>
                {{ product.pro_descricao }}
              </div>
              <div class="package-width new-product-mobile-line-container">
                <div class="new-product-mobile-header">Embalagem</div>
                {{ product.pro_generico ? product.pro_unidade_compra : product.pro_embalagem }}
              </div>
              <div class="code-width new-product-mobile-line-container">
                <div class="new-product-mobile-header">Código</div>
                {{ product.pro_ean }}
              </div>
            </div>
            <div v-if="product.isExpanded">
              <div class="new-product-sub-line-header">
                <div class="qty-width">Quantidade</div>
                <div class="brand-width">Marca</div>
                <div class="price-width">Preço</div>
                <!-- <div class="package-width">Embalagem</div>
                <div class="code-width">Código</div> -->
                <div class="action-width">Observação</div>
              </div>

              <div v-if="product.brands.length > 0">

                <div :class="{ 'page-table-line-special': idy % 2 != 0 }" class="new-product-sub-line"
                  v-for="(brand, idy) in product.brands" v-bind:key="idx + idy">
                  <div class="qty-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Quantidade</div>
                    <input min="0" type="number" :id="`input-${idx}-${idy}-1`"
                      class="new-quotation__new-product__input "
                      :class="{ active: brand.new_amount && brand.new_amount > 0 && brand.is_saved == true, productError: product.error, productAdded: brand.added }"
                      v-model.number="brand.new_amount" @focus="is_focused(idx, brand)"
                      @keydown="process_event($event, idx, idy, 1)" :key="'qtd' + brand.prod_id" />
                  </div>
                  <div class="brand-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Marca</div>
                    {{ brand.pro_observacao }}
                  </div>
                  <form v-on:submit.prevent="add_product({ ...brand, checked: brand.new_amount > 0 }, brand)" class="price-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Preço</div>
                    <money  min="0" :data-idx="idx" :id="`input-${idx}-${idy}-2`"
                    @keydown.native="process_event($event, idx, idy, 2)"
                      class="new-quotation__new-product__input new-quotation__new-product__input-price"
                      :class="{ active: brand.new_price && brand.new_price > 0 && brand.is_saved == true, productError: product.error, productAdded: brand.added }"
                      v-model.number="brand.new_price" :key="'qtd' + brand.prod_id" />
                  </form>
                  <form v-on:submit.prevent="add_product({ ...brand, checked: brand.new_amount > 0 }, brand)" class="action-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Ação</div>
                    <input @keydown="process_event($event, idx, idy, 3)" :id="`input-${idx}-${idy}-3`" type="text" v-model="brand.obs"
                    :class="{ active: brand.new_price && brand.new_price > 0 && brand.is_saved == true, productError: product.error, productAdded: brand.added }" class="new-quotation__new-product__input">
                  </form>
                </div>

              </div>

              <div v-else>
                <div  class="new-product-sub-line">
                  <div class="qty-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Quantidade</div>
                    <input min="0" type="number"  :id="`input-${idx}-1-1`"
                      class="new-quotation__new-product__input "
                      :class="{ active: product.new_amount && product.new_amount > 0 && product.is_saved == true, productError: product.error, productAdded: product.added }"
                      v-model.number="product.new_amount" @focus="is_focused(idx, product)"
                      @keydown="process_event($event, idx, 1, 1)" :key="'qtd' + product.prod_id" />
                  </div>
                  <div class="brand-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Marca</div>
                    --
                  </div>
                  <form v-on:submit.prevent="add_product({ ...product, checked: product.new_amount > 0 }, product)" class="price-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Preço</div>
                    <money min="0" :data-idx="idx" :id="`input-${idx}-1-2`"
                      class="new-quotation__new-product__input new-quotation__new-product__input-price"
                      :class="{ active: product.new_price && product.new_price > 0 && product.is_saved == true, productError: product.error, productAdded: product.added }"
                      v-model.number="product.new_price" @focus="is_focused(idx, product)"
                      @keydown.native="process_event($event, idx, 1, 2)" :key="'qtd' + product.prod_id" />
                  </form>
                  <form v-on:submit.prevent="add_product({ ...product, checked: product.new_amount > 0 }, product)" class="action-width new-product-mobile-line-container">
                    <div class="new-product-mobile-header">Ação</div>
                    <input @keydown="process_event($event, idx, 1, 3)" :id="`input-${idx}-1-3`" type="text" v-model="product.obs" class="new-quotation__new-product__input">
                  </form>
                </div>
              </div>

            </div>
          </div>
          <Paginacao classes="orange justify-end" :page_limit="4" :items_by_page_value="items_by_page_value"
            :total="total_items" :page="page" v-on:change="update_page($event)">
          </Paginacao>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import QuotationService from "@/services/QuotationService";
import OfferService from "@/services/v3/requests/offer.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
//import Pagination from "@/components/cliente/base-components/Pagination";
import Paginacao from "@/components/cliente/base-components/Pagination";
import NewProductModal from "@/modals/new-product/new-product.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";

export default {
  mixins: [perm_mixin, loaderMixin],
  props: ["quotation", "closeDialog", "seller_id", "store_id", "request_id"],
  components: {
    Paginacao,
    StandardButton,
    'new-product-modal': NewProductModal,
    StandardInputAuto
  },
  data() {
    return {
      showNewProductModal: true,
      products: [],
      cancelSource: null,
      page: 1,
      loading: true,
      total_items: 0,
      items_by_page_value: 50,
      adding: false,
      categories: [],
      product_name: "",
      cat: "",
      new_product: null,
      service: new QuotationService(),
      offer_svc: new OfferService(),
      modal: {
        title: "adicionar produto",
        subtitle: 'Escolha um item da base padrao do Club ou inclua um novo produto',
        icon_title: 'fas fa-cart-plus',
        cancel_text: 'Voltar',
        style: {
          width: "90%",
          height: "700px"
        }
      },
    };
  },
  watch: {
    'cat': function () {
      this.load_products()
    }
  },
  methods: {
    setProductName(value) {
      this.product_name = value
      this.load_products()
    },
    setCat(value) {
      this.cat = value
    },
    closeNewProductModal: function () {
      this.showNewProductModal = false;
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');

      }
    },
    process_event(e, idx, idy, input) {
      if (e.key == 'ArrowDown'){
        e.preventDefault();
        document.getElementById(`input-${idx}-${idy+1}-${input}`)?.focus()
      } else if (e.key == 'ArrowUp') {
        e.preventDefault();
        document.getElementById(`input-${idx}-${idy-1}-${input}`)?.focus()
      } else if (e.key == 'ArrowRight') {
        e.preventDefault();
        document.getElementById(`input-${idx}-${idy}-${input+1}`)?.focus()
      } else if (e.key == 'ArrowLeft') {
        e.preventDefault();
        document.getElementById(`input-${idx}-${idy}-${input-1}`)?.focus()
      }
    },
    close() {
      this.$emit("close");
    },
    is_focused(idx, product) {
      let inputs = this.$refs["product-amount"];
      this.products.forEach(p => p.focused = false)
      // if(inputs && inputs[idx].is(":focus")) {
      this.$set(product, "focused", true);
      this.$forceUpdate();
      // }
    },
    load_provider_default_categories() {
      this.service.load_provider_default_categories()
        .then(response => response.data)
        .then(data => {
          data.categorias.forEach(element => this.categories.push({ text: element.cat_descricao, value: element }));
        })
    },
    load_products() {
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      this.loading = true
      this.service.getProdutosClientePorPagina({
        page: this.page,
        with_brands: 1,
        nomeproduto: this.product_name,
        deleted: 0,
        categoria: this.cat ? this.cat.cat_id : null
      }, (this.cancelSource ? this.cancelSource.token : null))
        .then(response => response.data)
        .then(data => {
          this.products = data.produtos.data;
          this.products.forEach(p => {
            p.brands.sort((a,b) => {
              if (a.pro_observacao < b.pro_observacao) return -1
              if (a.pro_observacao > b.pro_observacao) return 1
              return 0
            })
          })
          this.total_items = data.produtos.total;
          this.items_by_page_value = data.produtos.per_page;
          this.loading = false;

        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    build_product(prod) {
      return {
        request_id: this.request_id,
        product_id: prod.pro_id,
        quantity: prod.new_amount,
        price: prod.new_price,
        seller_id: this.seller_id,
        store_id: this.store_id,
        obs: prod.obs
      };
    },
    add_product(prod, product_ref = {}) {
      var payload = this.build_product(prod)
      this.adding = true;
      if (prod.checked && !prod.is_saved) {
        this.loading = true
        this.offer_svc.create(payload)
          .then(response => response.data)
          .then(data => {
            prod.pro_id = data.produto
            prod.added = true
            prod.is_saved = true
            product_ref.pro_id = data.produto
            product_ref.added = true
            product_ref.is_saved = true
            this.adding = false
            this.loading = false
            this.$emit("add_product", prod)
            this.$set(prod, 'checked', true)
            this.$set(prod, 'saved', true)
            const notification = {
              type: 'success',
              message: 'Produto incluido com sucesso!.'
            }
            this.$store.dispatch('notification/add', notification)

          }).catch(error => {
            this.adding = false
            this.loading = false
            console.log(error.response)
            if (error.response.data.mensagem == "Código já existe") {
              prod.pro_id = error.response.data.pro_id
              prod.is_saved = true
              product_ref.error = true
              product_ref.is_saved = true
              this.snack('Produto já requisitado', 'error')
              this.$emit("add_product", prod);
              this.$set(prod, 'saved', true)
            } else if (error.response.data.mensagem == "Erro com categoria") {
              const notification = {
                type: 'error',
                message: 'Cliente não trabalha com essa categoria de produto.'
              }
              this.$store.dispatch('notification/add', notification)
              product_ref.new_amount = 0
              product_ref.error = true
            } else {
              const notification = {
                type: 'error',
                message: error.response.data.errors[0]
              }
              this.$store.dispatch('notification/add', notification)
            }
            this.$forceUpdate()
            ErrorHandlerService.handle(error, this.$store);
          });
      }

    },
    emit_product(prod) {
      this.$emit("add_product", prod)
    },
    update_page(page) {
      this.page = page;
      this.load_products();
    }
  },
  mounted() {
    this.load_provider_default_categories();
    this.load_products();
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}

.modal-content-container {
  background-color: white;
  width: 90vw;
  border-radius: 12px;
}

.new-product-modal-body {
  padding: 4vh 3vw;
}

.new-product-table-container {
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  height: 65vh;
  margin-top: 3vh;
  overflow-y: auto;
}

.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vw;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}

.add-product-mobile-header {
  display: none;
}

.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}

.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
}

.standard-btn {
  width: 10vw;
  margin: 1em 2em;
}

.new-product-btn {
  margin-top: 1.4em;
  margin-left: 1em;
}

.standard-buttons__wrapper {
  display: flex;
  align-items: flex-end;
  margin: 2em 1.2em;
}

.new-product__input-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.new-product__input-title {
  color: #898989;
  font-weight: 400;
  font-size: 1.8em;
  letter-spacing: 0.15px;
  margin-bottom: 0.3em;
}

.new-product__input {
  width: 25vw;
  padding: 8px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.2em;
  color: #A1A1A1;
}

.new-quotation__new-product__input {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 5.23px;
  font-size: 1em;
  padding: 0.5vh 1vw;
  color: #A1A1A1;
}

.new-quotation__new-product__input-price {
  width: 40%;
}

.new-quotation__new-product__input:focus {
  outline: none;
}

.select-input {
  width: 25vw !important;
}

.modal-table-container {
  min-height: 40vh;
  margin: 2em;
}

.modal-table-header {
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}

.modal-table-colum {
  padding-left: 0.5vw;
}

.modal-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  margin-bottom: 2vh;
}

.overflow-container {
  overflow-x: auto;
  max-height: 60vh;
}

.seemore-button-containear {
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
  max-width: 50vw;
}

.page-table-line-special {
  background: #F7F7F7;
}

.product-width {
  width: 60%;
}

.package-width {
  width: 20%;
}

.code-width {
  width: 20%;
}

.productAdded {
  background-color: rgb(200, 240, 200);
}

.productError {
  background-color: lightcoral;
}

.new-product-inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.new-product-table-header {
  display: flex;
  padding: 1vh 0;
  font-weight: 500;
  font-size: 1.2em;
  color: #909090;
}

.expand-width {
  width: 5%;
  display: flex;
  justify-content: center;
}

.product-width {
  width: 50%;
}

.package-width {
  width: 20%;
}


.code-width {
  width: 10%;
}

.qty-width {
  width: 11%;
  padding-left: 1%;
}

.brand-width {
  flex: 1;
}

.price-width {
  width: 40%;
}

.action-width {
  width: 30%;
}

.new-product-line-header {
  display: flex;
  padding: 1vh 0;
  background-color: #E5E5E5;
  font-weight: 400;
  font-size: 1.2em;
  color: #605F5F;
  margin-bottom: 2vh;
  cursor: pointer;
  align-items: center;
}

.new-product-line-header-expanded {
  background-color: #FFEADB;
}

.new-product-table-colapse-icon {
  color: var(--primary-color);
  font-size: 2em;
  cursor: pointer;
}

.new-product-table-colapse-icon-expanded {
  transform: rotate(180deg)
}

.new-product-sub-line-header {
  display: flex;
  padding: 0.5vh 0;
  font-weight: bold;
  color: #605F5F;
  border-bottom: lightgray 1px solid;
  margin-bottom: 1vh;
}

.new-product-sub-line {
  display: flex;
  padding: 1vh 0;
  color: #605F5F;
  align-items: center;
  margin-bottom: 1vh;
}

.new-product-save-icon {
  font-size: 2em;
  cursor: pointer;
  color: gray;
}

.new-product-save-icon:hover {
  color: lightgreen;
}

.new-product-input {
  width: 38%;
}

.new-product-inputs-button {
  width: 15%;
}

.new-product-mobile-header {
  display: none;
}

@media only screen and (max-width: 850px) {
  .modal-mask {
    font-size: 2vw;
  }

  .modal-content-container {
    width: 110vw;
  }

  .qty-width {
    width: 15%;
  }
}

@media only screen and (max-width: 500px) {
  .modal-mask {
    font-size: 3vw;
  }

  .modal-content-container {
    width: 115vw;
  }

  .new-product-inputs-container {
    flex-direction: column
  }

  .new-product-input {
    width: 100%;
  }

  .new-product-inputs-button {
    width: 100%;
    margin-top: 2vh
  }

  .new-product-table-header {
    display: none
  }

  .new-product-line-header {
    flex-direction: column
  }

  .new-product-mobile-line-container {
    display: flex;
    width: 100%;
    flex: unset;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vw;
  }

  .new-product-mobile-header {
    display: flex;
    font-size: 1.2em;
    font-weight: bold
  }

  .new-product-sub-line-header {
    display: none
  }

  .new-product-sub-line {
    flex-direction: column;
    gap: 1vh;
  }

  .new-quotation__new-product__input {
    width: 50%
  }

  .new-product-table-container {
    font-size: 1.3em;
  }
}

@import "./add-new-offer-to-request";
</style>
