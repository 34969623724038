import { render, staticRenderFns } from "./button.component.vue?vue&type=template&id=179b8b30&scoped=true&"
import script from "./button.component.vue?vue&type=script&lang=js&"
export * from "./button.component.vue?vue&type=script&lang=js&"
import style0 from "./button.component.vue?vue&type=style&index=0&id=179b8b30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179b8b30",
  null
  
)

export default component.exports