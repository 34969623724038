<template>
    <header class="add-provider-header">
        <h1 class="add-provider-title">Novo Fornecedor</h1>
        <span v-on:click="cancel" class="material-icons add-provider-cancel-icon">cancel</span>
    </header>
</template>

<script>
export default {
    name:'addProviderHeader',
    props:['cancel']
}
</script>

<style scoped>
.add-provider-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px 10px 0 0;
    height: 8vh;
    padding: 0 2vw;
}
.add-provider-title{
    font-weight: 500;
    font-size: 2em;
    color: #FFFFFF;
}
.add-provider-cancel-icon{
    color: white;
    cursor: pointer;
    font-size: 3em;
}
</style>