<template>
    <div :class="getDiffColor(getDiff(product))" class="makeorder-page-table-totalprice-width">
        <div class="makeorder-page-table-mobile-header">Total</div>
        {{ getVar(product)  }}
    </div>
</template>

<script>
export default {
    name: 'varLine',
    props: ['product'],
    methods:{
        getDiff(produto) {
            if (!produto.ultimaCompra) return 0
            return produto.menorPreco - produto.ultimaCompra
        },
        getVar(produto){
            if (localStorage.getItem('cli_utilizaPrecoBase') != 1) return '--'
            if(!produto.pro_principal && produto.cli_tipoPedido == 0) return this.$options.filters.currency((this.getDiff(produto) * produto.emb_quantidade) * produto.quantidade_sugestao)
            return this.$options.filters.currency(this.getDiff(produto) * produto.quantidade)
        },
        getDiffColor(diff) {
            if (diff > 0) return 'diff-up'
            else if (diff < 0) return 'diff-down'
        },
    }
}
</script>

<style scoped>
.makeorder-page-table-totalprice-width{width: 7%;}


.makeorder-page-table-mobile-header {
    display: none;
}
.diff-up {
    color: lightcoral;
    font-weight: bold;
}

.diff-down {
    font-weight: bold;
    color: lightgreen;
}



@media screen and (max-width: 850px) {
    .makeorder-page-table-totalprice-width{
        width: fit-content !important; 
        display: flex; 
        gap: 2vw;
        align-items: baseline;
    }


    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>