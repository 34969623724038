<template>
    <div class="makeorder-page-table-orders-width">
        <!-- <div :class="getInputStyle(product)" class="makeorder-page-table-input-container">
            <input type="number" min="0" :id="'product-amount'+ idx" class="makeorder-page-table-input" v-on:keydown="process_event2($event, product, idx)" v-on:blur="process_event($event, product, idx)" v-model="product.new_amount">
            <span v-if="product.new_amount > product.quantidade" style="color: green; font-size: 1.3em" class="material-icons-outlined">arrow_upward</span>
            <span v-else-if="product.new_amount < product.quantidade" style="color: red; font-size: 1.3em" class="material-icons-outlined">arrow_downward</span>
        </div> -->
        <div class="makeorder-page-table-mobile-header">Pedido</div>
        <td class="unity">
            <div class="unity-wrapper">
                <input min="0" type="number" :data-idx="idx" ref="product-amount"
                    :class="{ disabled: !!subrequest.id_pedido, lower: product.firstValue > product.quantidade_sugestao, higher: product.firstValue < product.quantidade_sugestao }"
                    :readonly="subrequest && !!subrequest.id_pedido" v-model="product.new_amount"
                    v-on:keydown="process_event($event, product)" v-on:blur="process_event($event, product)"
                    :key="'qtd-' + idx" />
                <button class="save" :disabled="!!subrequest.id_pedido"
                    v-on:click="!product.is_loading && update_quantity(product)">
                    <img :class="{ hidden: !product.is_loading }" src="/images/loader.svg" />
                    <i v-if="!product.is_loading && product.quantidade > product.new_amount" class="fa fa-arrow-down"></i>
                    <i v-if="!product.is_loading && product.quantidade < product.new_amount" class="fa fa-arrow-up"></i>
                    <i v-if="!product.is_loading && product.quantidade == product.new_amount" class="fa fa-check"></i>
                </button>
            </div>
        </td>
    </div>
</template>

<script>
export default {
    name: 'OrderLine',
    props: ['product', 'process_event', 'update_quantity', 'idx', 'subrequest']
}
</script>

<style lang="scss" scoped>
@import "./typings.component";
.makeorder-page-table-orders-width {
    width: 10%;
}

.makeorder-page-table-mobile-header {
    display: none;
}

.unity {
    box-sizing: unset;

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .unity-wrapper {
        display: inline-block;
        width: 86px;

        img {
            height: 18px;
        }

        input[type=number] {
            border-radius: 10px 0 0 10px;
            border-width: 0;
            width: 50px;
            border: 4px solid #0606064d;
            display: inline-block;

            &:focus {
                border: 3px solid $primary-color;
                ;
                outline: none;
                box-shadow: 0px 0px 0px 3px $primary-color inset;
            }

            &.higher {
                background-color: #13c7213d;
            }

            &.lower {
                background-color: #13c7213d;
            }
        }

        button {
            .fa-arrow-up {
                color: #0033ef9e;
            }

            .fa-arrow-down {
                color: #fb7272;
            }

            &.save {
                float: right;
                color: green;
                height: 32px;
                padding: 4px;
                position: relative;
                left: -12px;
                border-left: none;
                width: 1.5rem;
                border-radius: 0px 10px 10px 0px !important;

                i {
                    border-color: #0606064d;
                    left: 0.4rem;
                    position: absolute;
                    font-size: 0.9rem;
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .makeorder-page-table-orders-width {
        display: flex;
        gap: 2vw;
        align-items: center;
        width: fit-content !important;
    }


    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}
</style>