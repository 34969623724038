import http from "./http";
import Service from "../Service";

/**
 * @type EventService
 */
export default class EventService extends Service {

	list(params) {
		return http.get(`clients/events`, {
			headers: this.getHeaders(),
            params
		});
	}

}
