<template>
  <div class="modal-mask" v-on:click="closeSelectBody()">
    <div class="modal-content-container">
      <div class="modal-content-header">
        <div>Detalhe preço de venda</div>
        <span class="material-icons modal-close-icon" v-on:click.stop="close()">close</span>
      </div>
      <div class="modal-content-body">
        <div class="modal-title">
          {{product.descricao}}
        </div>
        <div class="modal-product-info-container">
          <div class="product-img-container">
            <img class="product-img" :src="product.img" alt="">
          </div>
          <div class="modal-product-info">
            <div class="modal-product-info-row">
              <div class="modal-product-info-title">GTIN</div>
              <div class="modal-product-info-value">{{product.gtin}}</div>
            </div>
            <div class="modal-product-info-row">
              <div class="modal-product-info-title">Seção</div>
              <div class="modal-product-info-value">{{product.section}}</div>
            </div>
            <div class="modal-product-info-row">
              <div class="modal-product-info-title">Categorias</div>
              <div class="modal-product-info-value">{{product.category}}</div>
            </div>
          </div>
        </div>
        <div class="filters-row">
          <!-- <div class="Select-containear">
            <div class="input-title">Estado</div>
            <div class="input-containear" v-on:click.stop="openSelectState()">
              <input type="text" v-model="stateSearch" class="input-value" placeholder="Digite o nome">
              <span v-if="!showSelectBodyState" class="material-icons input-icon">search</span>
              <span v-else class="material-icons input-icon" v-on:click.stop="closeSelectState()">close</span>
            </div>
            <div v-if="showSelectBodyState">
              <div class="select-body city-width">
                <div v-if="isAddressLoading" style="margin-top: 20px">
                  <cc-loader />
                </div>
                <div v-on:click.stop="toogleCheckState(state)" v-for="(state, index) in statesList" :key="index" class="select-body-line">
                  <span v-if="state.isCheck" class="material-icons checkbox-icon">check_box</span>
                  <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
                  <div class="select-body-line-text">{{state.text}}</div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="Select-containear-product" style="margin-top: 2vh;">
            <div class="input-title">Buscar por cidades</div>
            <div class="input-containear" v-on:click.stop="openSelectCity()">
              <input type="text" v-model="citySearch" class="input-value" placeholder="Digite o nome">
              <span v-if="!showSelectcities" class="material-icons input-icon">search</span>
              <span v-else class="material-icons input-icon" v-on:click.stop="showSelectcities = false">close</span>
            </div>
            <div v-if="showSelectcities" style="position: relative;">
              <div  class="select-body product-width">
                <cc-loader v-if="isLoading" style="margin-top: 3vh;"/>
                <div v-else v-on:click.stop="toogleCheckCity(city)" v-for="(city, idx) in citiesList" :key="idx" class="select-body-line">
                  <span v-if="city.isCheck" class="material-icons checkbox-icon">check_box</span>
                  <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
                  <div class="select-body-line-text">{{city.text.key}}</div>
                </div>
              </div>
            </div>
          </div>

          <span class="material-icons-outlined modal-search-icon" v-on:click.stop="closeSelectCity()">search</span>

          <div class="Select-containear">
            <div class="input-title">Orderna por</div>
            <div class="input-containear" v-on:click.stop="showSelectBody = true">
              <div class="input-value">{{selectedOrder.text}}</div>
              <span v-if="!showSelectBody" class="material-icons input-icon">expand_more</span>
              <span v-else class="material-icons input-icon" v-on:click.stop="showSelectBody = false">close</span>
            </div>

            <div v-if="showSelectBody" style="position: relative;">
              <div class="select-body">
                <div v-for="(item, index) in orderList" v-on:click.stop="selectOrder(item)" :key="index" class="select-body-line">
                  <div class="select-body-line-text">{{item.text}}</div>
                </div>
              </div>
            </div>
          </div>

          <StandardButton :action="cleanFilter" :iconName="'delete'" :text="'Limpar Filtro'"/>
        </div>
        <!-- <div class="filters-row-address">
          <div class="mobile-remove-bottom Select-containear">
            <div class="input-title">Endereço</div>
            <div class="input-containear" v-on:click.stop="showAddressBody = true">
              <input type="text" v-model="addressSearch" class="input-value" placeholder="Insira um endereço ou cep">
              <span v-if="!showAddressBody" class="material-icons input-icon">search</span>
              <span v-else class="material-icons input-icon" v-on:click.stop="showAddressBody = false">close</span>
            </div>
            <div v-if="showAddressBody">
              <div v-if="isAddressLoading" style="margin-top: 20px">
                <cc-loader />
              </div>
              <div v-else class="select-body">
                <div v-for="item in addressOptions" v-on:click.stop="selectAddress(item)" :key="item.place_id" class="select-body-line">
                  <div class="select-body-line-text">{{item.description}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Select-containear-address" :class="disableRadius()">
            <div class="input-title">Raio</div>
            <div class="input-containear" v-on:click.stop="() => {if (selectedAddress) showRadiusBody = true}">
              <div v-if="selectedRadius" class="input-value">{{selectedRadius.text}}</div>
              <div v-else class="input-value" >Distancia</div>
              <span v-if="!showRadiusBody" class="material-icons input-icon">expand_more</span>
              <span v-else class="material-icons input-icon" v-on:click.stop="showRadiusBody = false">close</span>
            </div>
            <div v-if="showRadiusBody">
              <div class="select-body-radius">
                <div v-for="item in radiusList" v-on:click.stop="selectRadius(item)" :key="item.value" class="select-body-line">
                  <div class="select-body-line-text">{{item.text}}</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="selected-options-containear">
          <div class="selected-option" v-for="(option, index) in selectedStates" :key="index">
            <div>{{option}}</div>
            <span class="material-icons" v-on:click.stop="cancelSelectedState(option)" style="cursor: pointer">close</span>
          </div>
          <div class="selected-option" v-for="(option, index2) in selectedCities" >
            <div>{{option}}</div>
            <span class="material-icons" v-on:click.stop="cancelSelectedCity(option)" style="cursor: pointer">close</span>
          </div>
        </div>

        <div class="overflow-container">
          <cc-loader-full v-if="isLoading"/>
          <div v-else class="modal-table-container">
            <div class="modal-table-header">
              <div class="modal-table-colum">Redes</div>
              <div class="modal-table-colum">Preço mínimo</div>
              <div class="modal-table-colum">Preço médio</div>
              <div class="modal-table-colum">Preço Máximo</div>
              <div class="modal-table-colum">Número de Loja</div>
            </div>
            <div  v-for="(seller, idx) in sellersList" :key="idx">
              <div v-if="filterSeller(seller)" :class="getSpecialBackground(idx)" class="modal-table-row">
                <div class="modal-table-colum modal-table-separator">{{seller.rede}}</div>
                <div class="modal-table-colum modal-table-separator">R$ {{seller.preco_minimo.toFixed(2)}}</div>
                <div class="modal-table-colum modal-table-separator">R$ {{seller.preco_medio.toFixed(2)}}</div>
                <div class="modal-table-colum modal-table-separator">R$ {{seller.preco_maximo.toFixed(2)}}</div>
                <div class="modal-table-colum">{{seller.cnpjs_distintos}}</div>
              </div>
            </div>
          </div>
        </div>
        <tr class="seemore-button-containear" >
            <td class="text-center" colspan="6">
                <Paginacao
                    classes="orange justify-end"
                    :items_by_page_value="30"
                    :total="totalItems"
                    :page_limit = '30'
                    :page="1"
                    v-on:change="updatePage($event)"
                ></Paginacao>
            </td>
        </tr>
      </div>
    </div>
  </div>
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'

export default {
  name: 'SellersPriceModal',
  props: ['close', 'product', 'service', 'dateBeguin', 'dateEnd', 'initSelectedStates', 'cities', 'setCities'],
  components: {
    Paginacao,
    StandardButton
  },
  data () {
    return {
      sellersList: undefined,
      isLoading: true,
      searchfilter: '',
      totalItems: undefined,
      page: 0,
      selectedOrder: { text: 'Preço mínimo', value: 'PRECO_MINIMO' },
      orderList: [{
        text: 'Rede',
        value: 'REDE',
      },
      {
        text: 'Preço mínimo',
        value: 'PRECO_MINIMO',
      },
      {
        text: 'Preço médio',
        value: 'PRECO_MEDIO',
      },
      {
        text: 'Preço Máximo',
        value: 'PRECO_MAXIMO',
      },
      {
        text: 'Número de Loja',
        value: 'CNPJS_DISTINTOS',
      },],
      showSelectBody: false,
      showSelectBodyState: false,
      stateSearch: '',
      statesList: [],
      selectedStates: [],
      addressSearch: '',
      showAddressBody: false,
      addressOptions: [],
      isAddressLoading: false,
      sessionToken: undefined,
      selectedAddress: undefined,
      showRadiusBody: false,
      radiusList: [
        {
          text: '2 KM',
          value: 2
        },
        {
          text: '3 KM',
          value: 3
        },
        {
          text: '4 KM',
          value: 4
        },
        {
          text: '5 KM',
          value: 5
        },
        {
          text: '6 KM',
          value: 6
        },
        {
          text: '7 KM',
          value: 7
        },
        {
          text: '8 KM',
          value: 8
        },
        {
          text: '9 KM',
          value: 9
        },
        {
          text: '10 KM',
          value: 10
        },
      ],
      selectedRadius: { text: '5 KM', value: 5 },
      addressLat: undefined,
      addressLng: undefined,
      citiesList: [],
      showSelectcities: false,
      citySearch: '',
      selectedCities: []
    }
  },
  methods: {
    toogleCheckCity (city) {
      city.isCheck = !city.isCheck
      if (city.isCheck) {
        this.selectedCities.push(city.text.key)
        this.setCities(this.selectedCities)
      }
      else {
        for (let i = 0; i < this.selectedCities.length; i++) {
          if (this.selectedCities == city.text) {
            this.selectedCities.splice(i, 1)
            this.setCities(this.selectedCities)
          }
        }
      }
    },
    closeSelectCity () {this.showSelectcities = false; this.loadSellers()},
    openSelectCity () {this.showSelectcities = true},
    closeSelectBody () {
      this.showRadiusBody = false
      this.showAddressBody = false
      this.showSelectBody = false
      this.showSelectBodyState = false
    },
    disableRadius () {
      if (!this.selectedAddress) return 'Select-containear-address-disable'
    },
    selectRadius (radius) {
      if (!this.selectedAddress) return false
      this.selectedRadius = radius
      this.showRadiusBody = false
      this.isLoading = true
      this.service.requestLatLong({
        placeId: this.selectedAddress.place_id,
        sessionToken: this.sessionToken
      }).then(response => {
        this.addressLat = response.result.geometry.location.lat
        this.addressLng = response.result.geometry.location.lng
        this.loadSellers()
      })
    },
    selectAddress (address) {
      this.selectedAddress = address
      this.addressSearch = address.description
      this.showAddressBody = false
      this.isLoading = true
      this.service.requestLatLong({
        placeId: this.selectedAddress.place_id,
        sessionToken: this.sessionToken
      }).then(response => {
        this.addressLat = response.result.geometry.location.lat
        this.addressLng = response.result.geometry.location.lng
        this.loadSellers()
      })
    },
    cleanFilter () {
      this.stateSearch = ''
      this.selectedStates = []
      this.statesList.forEach(state => { state.isCheck = false })
      this.addressSearch = ''
      this.loadSellers()
      this.loadStates()
    },
    toogleCheckState (state) {
      state.isCheck = !state.isCheck
      if (state.isCheck) this.selectedStates.push(state.text)
      else {
        for (let i = 0; i < this.selectedStates.length; i++) {
          if (this.selectedStates[i] == state.text) { this.selectedStates.splice(i, 1) }
        }
      }
      this.loadSellers()
    },
    closeSelectState () {this.showSelectBodyState = false},
    openSelectState () {
      this.showSelectBodyState = true
      this.loadStates()
    },
    selectOrder (order) {
      this.selectedOrder = order
      this.showSelectBody = false
      this.loadSellers()
    },
    updatePage (event) {
      this.page = event - 1
      this.loadSellers()
    },
    getSpecialBackground (idx) {
      if (idx % 2 != 0) return 'page-table-line-special'
    },
    filterSeller (seller) {
      if (this.searchfilter == '') return true
      if (seller.rede.toLowerCase().includes(this.searchfilter.toLowerCase())) return true
      return false
    },
    loadSellers () {
      this.isLoading = true
      this.service.requestSellers({
        dateBeguin: this.dateBeguin,
        dateEnd: this.dateEnd,
        gtin: [this.product.gtin],
        page: this.page,
        order: this.selectedOrder.value,
        states: this.selectedStates,
        lat: this.addressLat,
        lng: this.addressLng,
        cities: this.selectedCities,
        radius: this.addressLat ? this.selectedRadius.value : null
      }).then(response => {
        this.sellersList = response.content
        this.isLoading = false
        setTimeout(() => {
          this.page = response.number
          this.totalItems = response.totalElements
        }, 100)
      })
    },
    cancelSelectedState (state) {
      for (let i = 0; i < this.selectedStates.length; i++){
        if (this.selectedStates[i] == state) {
          this.selectedStates.splice(i, 1)
          this.loadSellers()
        }
      }
      for (let i = 0; i < this.statesList.length; i++){
        if (this.statesList[i].text == state) this.statesList[i].isCheck = false
      }
    },
    cancelSelectedCity (city) {
      for (let i = 0; i < this.selectedCities.length; i++){
        if (this.selectedCities[i] == city) {
          this.selectedCities.splice(i, 1)
          this.setCities(this.selectedCities)
          this.loadSellers()
        }
      }
      for (let i = 0; i < this.citiesList.length; i++){
        if (this.citiesList[i].text == city) this.citiesList[i].isCheck = false
      }
    },
    loadStates () {
      this.statesList = []
      this.isAddressLoading = true
      this.service.loadStates('', this.addressLat, this.addressLng, this.addressLat ? this.selectedRadius.value : null).then(resp => {
        resp.forEach(state => {
          this.statesList.push({
            text: state,
            isCheck: false
          })
        })
        this.isAddressLoading = false
      })
    }
  },
  watch: {
    stateSearch () {
      if (!this.isLoading){
        this.isLoading = true
        setTimeout(() => {
          this.service.loadStates(this.stateSearch, this.addressLat, this.addressLng, this.addressLat ? this.selectedRadius.value : null).then(response => {
            this.statesList = []
            response.forEach(state => {
              this.statesList.push({
                text: state,
                isCheck: false
              })
            })
            this.isLoading = false
          })
        },1000)
      }
    },
    addressSearch (value) {
      if (value == '') {
        this.selectedRadius = undefined
        this.addressLat = undefined
        this.addressLng = undefined
        this.loadSellers()
        return
      }
      if (!this.isAddressLoading) {
        this.isAddressLoading = true
        setTimeout(() => {
          const payload = {
            query: this.addressSearch,
            sessionToken: this.sessionToken
          }
          this.service.requestAddressOptions(payload).then(response => {
            this.sessionToken = response.session_token
            this.addressOptions = response.predictions
            this.isAddressLoading = false
          })
        },1500)
      }
    },
    citySearch () {
      if (!this.isLoading){
        this.isLoading = true
        setTimeout(() => {
          this.service.requestCities({search: this.citySearch, states: this.selectedStates}).then(response => {
            this.citiesList = []
            response.forEach(city => {
              this.citiesList.push({
                text: city,
                isCheck: false
              })
            })
            this.isLoading = false
          })
        },1000)
      }
    }
  },
  mounted () {
    this.selectedStates = this.initSelectedStates
    this.selectedCities = this.cities
    this.loadSellers()
    this.service.requestCities({search: '', states: this.selectedStates}).then(resp3 => {
      resp3.forEach(city => {
        this.citiesList.push({
          text: city,
          isCheck: false
        })
      })
    })
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  max-width: 110vw;
  border-radius: 12px;
  margin: 5vh auto;
  font-size: 1vw;
}
.modal-content-header{
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 2.72em;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}
.Select-containear{
  width: 40vw;
  margin-right: 5vw;
}
.Select-containear-address{
  width: 15vw;
}
.input-title{
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.input-value{
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 1.19em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #B3B3B3;
  cursor: pointer;
}
.select-body{
  position: absolute;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
  width: 40vw;
}
.select-body-radius{
  position: absolute;
  background-color: white;
  max-height: 400px;
  overflow-y: auto;
  width: 15vw;
}
.select-body-line{
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}
.Select-containear-address-disable{
  cursor: not-allowed;
}
.select-body-line:hover{
  background-color: rgba(255, 247, 247, 0.75);;
}
.select-body-line-text{
  font-weight: 300;
  font-size: 1.27em;
  color: #505050;
  margin-left: 5px;
}
.modal-close-icon{
  background-color: white;
  color: var(--primary-color);
  font-size: 1.5em;
  border-radius: 50%;
  cursor: pointer;
}
.page-table-line-special{  background: #F7F7F7; }
.modal-title{
  font-weight: 400;
  font-size: 2.72em;
  color: #303030;
}
.modal-content-body{
  padding: 2vw;
  max-height: 100vh;
  overflow-y: auto;
}
.modal-product-info-container{
  margin-top: 1vh;
  display: flex;
  margin-bottom: 3vh;
}
.product-img-container{
  height: 15vh;
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-row{
  display: flex;
  align-items: flex-end;
}
.filters-row-address{
  display: flex;
  margin-top: 2vh;
  align-items: flex-start;
  margin-bottom: 2vh;
}
.product-img{
  max-height: 15vh;
  max-width: 15vw;
}
.modal-product-info{
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.modal-product-info-row{
  display: flex;
  width: 20vw;
  justify-content: space-between;
}
.modal-product-info-title{
  font-weight: 400;
  font-size: 1.19em;
  color: #606060;
}
.modal-product-info-value{
  font-weight: 300;
  font-size: 1.19em;
  color: #606060;
  max-width: 60%;
  text-align: right;
}
.modal-filter-title{
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  margin-bottom: 1vh;
}
.modal-filter-container{
  display: flex;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 1vw;
  width: 50%;
}
.modal-filter-input{
  border: none;
  flex: 1;
}
.modal-filter-input:focus{
  outline: none;
}
.modal-filter-icon{
  color: #898989;
}
.modal-table-container{
  min-height: 40vh;
  min-width: 1100px;
  margin-top: 2vh;
}
.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.19em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-colum{
  flex: 1;
  padding-left: 0.5vw;
}
.Select-containear-product{
  width: 40vw;
  margin-right: auto;
}
.product-width{
  width: 40vw;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 1.10em;
  color: #605F5F;
  margin-bottom: 2vh;
}
.modal-table-separator{
  border-right: 1px solid #DDE5E9;
  margin-right: 50px;
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
  overflow-x: auto;
}
.toggle-selected-options-icon{ color: var(--primary-color); }
.toggle-selected-options{
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  padding: 1.5vh;
  width: fit-content;
  margin-top: 2vh;
  font-weight: 400;
  font-size: 1.36em;
  line-height: 19px;
  color: #605F5F;
  align-items: center;
  cursor: pointer;
}
.selected-options-containear{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5vw;
}
.selected-option{
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 5%;
  font-weight: 300;
  font-size: 0.93em;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-top: 1vh;
}
.modal-search-icon{
  font-size: 2.5em;
  background-color: var(--primary-color);
  color: white;
  margin-right: 3vw;
  cursor: pointer;
  padding: 0.5vh 0.5vw;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}
.modal-search-icon:hover{
  background-color: white;
  color: var(--primary-color);
}
.page-filter-Button-save{
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 14px;
  font-weight: 400;
  font-size: 1.27em;
  letter-spacing: 0.02em;
  color: #505050;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 150px;
  margin-right: 15px;
  background: #FAFAFC;
  height: 90%;
}
.page-filter-Button-save-icon{
  color: #212529;
}
.checkbox-icon{ color: var(--primary-color); }
@media only screen and (max-width: 1300px) {
  .Select-containear-address{
    width: 25vw;
  }
  .select-body-radius{
    width: 25vw;
  }
}
@media only screen and (max-width: 1100px) {
  .overflow-container{
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 1000px) {
    .modal-content-container{ font-size: 1.2vw; }
}
@media only screen and (max-width: 800px) {
  .modal-filter-container{
    width: 100%;
  }
  .product-img-container{
    width: 40vw;
  }
  .product-img{
    max-width: 40vw;
  }
  .modal-product-info-row{
    width: 100%;
  }
  .modal-mask{
    align-items: flex-start;
    overflow-y: scroll;
  }
  .modal-content-container{
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .modal-title{
    text-align: center;
  }
  .modal-product-info{
    flex: 1;
  }
  .modal-content-body{
    padding: 5vw;
  }
  .Select-containear{
    width: 100%;
    margin-bottom: 2vh;
  }
  .mobile-remove-bottom{
    margin-bottom: 0;
  }
  .select-body{
    width: 80%;
  }
  .filters-row{
    flex-direction: column;
    align-items: flex-start;
  }
  .Select-containear-address{
    width: 55vw;
  }
  .select-body-radius{
    width: 34vw;
  }
}
@media only screen and (max-width: 780px) {
    .modal-content-container{ font-size: 1.4vw; }
    .Select-containear-product{width: 100%;}
    .modal-search-icon{
      margin: 2vh 0;
    }
}
@media only screen and (max-width: 650px) {
    .modal-content-container{ font-size: 1.6vw; }
}
@media only screen and (max-width: 550px) {
    .modal-content-container{ font-size: 15px; }
}
</style>
