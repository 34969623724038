<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="pendencies-modal-header">
                Grupo de Produtos - {{ user.usu_nome }}
                <span class="material-icons pendencies-modal-close-icon" v-on:click="closeModal()">cancel</span>
            </div>
            <div class="management-modal-body">
                <h1 class="add-product-body-title">Lista de grupos</h1>
                <div class="add-product-table-container">
                    <div :class="{ 'add-product-selected': value.savedId }" class="add-product-select-button"
                        @click="toggleSelected(value)" v-for="(value, idx) in groups" :key="value.id">
                        {{ value.descricao }}
                        <span v-if="value.isLoading" class="material-icons-outlined loading-icon">refresh</span>
                        <span v-else-if="value.savedId" class="material-icons-outlined">radio_button_checked</span>
                        <span v-else class="material-icons-outlined">radio_button_unchecked</span>
                    </div>
                </div>
            </div>
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import ProductGroupService from '@/services/v3/management/ProductGroupService.js'

export default {
    name: 'EditProductGroupModal',
    props: ['closeModal', 'user'],
    data() {
        return {
            isLoading: true,
            service: new ProductGroupService(),
            groups: [],
        }
    },
    methods: {
        loadPage() {
            this.isLoading = true
            this.service.loadProductGroups().then(resp => {
                this.groups = resp.data
                this.groups.forEach(element => {
                    element.link.forEach(value => {
                        if (value.usu_id == this.user.usu_id) element.savedId = value.id
                    })
                })
                this.isLoading = false
            })
        },
        toggleSelected(group) {
            const notification = { type: 'success' }
            group.isLoading = true
            this.$forceUpdate()
            if (!group.savedId) {
                this.service.saveUserGroup({
                    "id_grupo": group.id,
                    "usu_id": this.user.usu_id
                }).then(resp => {
                    group.savedId = resp.data.id
                    group.isLoading = false
                    this.$forceUpdate()
                    notification.message = 'Grupo adicionado'
                    this.$store.dispatch('notification/add', notification)
                })
            } else {
                this.service.deleteUserGroup(group.savedId).then(() => {
                    group.savedId = undefined
                    group.isLoading = false
                    this.$forceUpdate()
                    notification.message = 'Grupo removido'
                    this.$store.dispatch('notification/add', notification)
                })
            }
        },
    },
    created() {
        this.loadPage()
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    transition: opacity 0.3s ease;
    overflow-y: auto;
}

.modal-content-container {
    background-color: white;
    width: 100vw;
    border-radius: 12px;
    margin: 0 auto;
    margin-top: 15vh;
    font-size: 1vw;
}

.pendencies-modal-header {
    background-color: var(--primary-color);
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 3vw;
    font-weight: 500;
    font-size: 2.5em;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.pendencies-modal-close-icon {
    font-size: 1.5em;
    cursor: pointer;
}

.management-modal-body {
    padding: 2vh 2vw;
    padding-bottom: 7vh
}

.manage-categories__input {
    width: 16vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}

.management-modal-body-title {
    color: #898989;
    font-weight: 500;
    font-size: 1.5em;
}

.add-product-body-title {
    font-size: 2em;
    color: #898989;
    margin-bottom: 4vh;
}

.add-product-table-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2vh 1vw;
}

.add-product-select-button {
    border: 1px solid #898989;
    color: #898989;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 1.5vh 1vw;
    cursor: pointer;
    font-size: 1.2em;
}

.add-product-selected {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.loading-icon {
    -webkit-animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.add-product-button {
    width: 45%;
    margin-left: auto;
    margin-top: 4vh;
}
</style>