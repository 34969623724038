<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: #fff;
  min-width: 50vw;
  border-radius: 12px;
}
.new-request-modal__main-container {
  width: 70vw;
}
.manage-categories__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.manage-categories__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.manage-categories__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.new-page__actions-container {
  display: flex;
  align-items: flex-end;
  margin: 1.5em 2em;
  justify-content: space-between;
}
.new-page__actions-wrapper {}
.new-page__search-input {
  width: 30vw;
  height: 4vw;
  border: 1px solid #c2c2c3;
  border-radius: 5px;
  padding-left: 0.5em;
  color: #898989;
}
.new-page__actions-title {
  font-size: 2em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}
.new-page__left-actions {}
.new-page__right-actions {
  display: flex;
}
.edit-providers-btn {
  margin-right: 1em;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.observation-input {
  width: 100%;
  height: 20vh;
  border: 1px solid #c2c2c3;
  border-radius: 5px;
  padding: 0 0 15vh 1vw;
  /* word-wrap: break-word; */
}
.checkbox-title {}
.overflow-container{
  overflow-x: auto;
  max-height: 50vh;
}
.modal-table-container{
  min-height: 40vh;
}
.new-page__table-container {
  margin: 2em;
}

.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 2vh 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-colum{
  padding-left: 1vw;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 13px;
  color: #605F5F;
  margin-bottom: 2vh;
}
.checkbox-width {
  width: 8%;
}
.provider-width {
  width: 46%;
}
.seller-width {
  width: 46%;
}
.new-page__actions-title-wrapper{
  margin-bottom: 0.75vh;
}
.group-width{
  width: 90% ;
}
.select-width {
  width: 22vw !important;
}
.datetime-width {
  width: 13vw !important;
  border-radius: 8px !important;
}

@media only screen and (max-width: 890px) {
  .mobile-container {
    flex-wrap: wrap;
  }
  .select-width {
    width: 49% !important;
    font-size: 0.8em;
  }
  .obs-title-mobile {
    display: flex;
    justify-content: center;
  }
  .observation-input {
    width: 100%;
    height: 20vh;
    padding: 0 0 5vh 1vw;
  }
  .group-width{
    width: 100% ;
  }
  .new-page__search-input {
    width: 50vw;
    height: 6vw;
  }

  .new-request-modal__main-container {
    height: 100vh;
    width: 115vw;
  }
  .datetime-width {
    width: 100% !important;
  }
  .manage-categories__header-title {
    font-size: 20px;
  }
  .new-page__actions-title {
    font-size: 14px;
  }
  .new-page__right-actions {
    gap: 10%
  }
  .edit-providers-btn {
    margin-right: 0;
    width: 55vw
  }
  .mobile-date-input{
    width: 100%
  }
}

@import '@/sass/commons/_variables';

/deep/ {

	.sub-title {
		padding: 0;
		text-transform: uppercase;
		color: $orange;
		font-weight: 600;
	}

	.multiselect__single {
		color: black !important;
	}

	.multiselect__option.multiselect__option--highlight {
		background-color: gray !important;
	}

	.modal-body {
		border-radius: 5px;
		padding: 2rem;
		overflow: auto !important;
	}
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="new-request-modal__main-container">
        <div class="manage-categories__header-container">
          <span class="manage-categories__header-title">
            Enviar Cotação
          </span>
          <span @click="closeDialog" class="material-icons manage-categories__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="new-page__actions-container">
          <div class="new-page__left-actions">
            <div class="new-page__actions-wrapper">
              <Button v-if="show_sellers" title="Voltar" :text="'Voltar'" :iconName="'arrow_back'" :action="() => { show_sellers = false}" />
	        </div>
            <div class="new-page__actions-wrapper">
              <Button v-if="!show_sellers" title="Voltar" :text="'Voltar'" :iconName="'arrow_back'" :action="closeDialog" />
	        </div>
          </div>
          <div class="new-page__right-actions">
            <div class="new-page__actions-wrapper edit-providers-btn">
              <Button :disabled="!ressuply.expiration_date" v-if="!show_sellers" title="Editar fornecedores" :text="'Editar Fornecedores'" :iconName="'people'" :action="select_sellers" />
	          </div>
            <div class="new-page__actions-wrapper">
              <Button title="Enviar" :text="'Enviar'" :iconName="'email'" :action="sendResuply" />
	          </div>
          </div>
        </div>

        <template v-if="!show_sellers">
          <div class="modal-mask" v-if="loading">
            <cc-loader style="margin-top: 10vh"/>
          </div>
          <div v-else>

            <div class="new-page__actions-container mobile-container" style="margin-bottom: 0;">
              <div class="new-page__actions-wrapper mobile-date-input">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Data Final</span>
                </div>
                <div class="page-filters__right-select-wrapper">
                  <datetime :min-datetime="min_date" ref="datepicker" v-model="ressuply.expiration_date" auto :minute-step=10 type="datetime" class="form-control datetime-width"></datetime>
                  <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                    today
                  </span>
                </div>
              </div>

              <!-- <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Tipo de Entrega</span>
                </div>
                <cc-select-v2 class="select-width"
                              :tracker="'code'"
                              :text="'label'"
                              :options="deliveryTypes"
                              v-model="ressuply.delivery_type">
                </cc-select-v2>
              </div> -->

              <StandardInput :placeholder="ressuply.delivery_type ? ressuply.delivery_type.text : '--'" :action="value => ressuply.delivery_type = value"
                     :list="deliveryTypes" class="select-width" :type="'select'"  title="Tipo de Entrega"/>

              <StandardInput :placeholder="'Selecione um pagamento'" :action="value => ressuply.payment_type = value"
                     :list="payment_types" class="select-width" :type="'select'"  title="Forma de Pagamento"/>
            </div>

            <div class="new-page__actions-container mobile-container" style="margin-top: 0;">
              <StandardInput :placeholder="'Selecione um grupo'" :action="value => ressuply.groups = value"
                     :list="groups" :type="'select'" class="group-width" title="Selecionar Grupos"/>
            </div>

            <!-- <div class="new-page__actions-container mobile-container">
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Forma de Pagamento</span>
                </div>
                <cc-select-v2 class="select-width"
                              :placeholder="'Selecione uma empresa'"
                              :tracker="'code'"
                              :text="'label'"
                              :options="payment_types"
                            v-model="ressuply.payment_type">
                </cc-select-v2>
              </div>
              <div class="new-page__actions-wrapper">
                <div class="new-page__actions-title-wrapper">
                  <span class="new-page__actions-title">Selecionar Grupos</span>
                </div>
                <cc-select-v2 class="select-width"
                              :placeholder="'Selecione um grupo'"
                              :tracker="'id'"
                              :text="'nome'"
                              :options="groups"
                              v-model="ressuply.groups">
                </cc-select-v2>
              </div>
            </div> -->

            <div class="new-page__actions-container">
              <div class="new-page__actions-wrapper" style="width: 100%;">
                <div class="new-page__actions-title-wrapper obs-title-mobile">
                  <span class="new-page__actions-title">Observação</span>
                </div>
                <input class="observation-input" type="text" v-model="ressuply.observation">
              </div>
            </div>
            <div class="new-page__actions-container">
              <cc-checkbox class="checkbox-title" @change="(evt) => ressuply.is_public = evt.target.checked" :checked="ressuply.is_public" :label="'Cotação Pública?'" />
            </div>

          </div>
        </template>

        <template v-if="show_sellers">
          <cc-loader-full v-show="loading" />
          <div class="new-page__actions-container">
            <div class="new-page__actions-wrapper">
		          <div class="new-page__actions-title-wrapper">
		            <span class="new-page__actions-title">Buscar</span>
		          </div>
              <input class="new-page__search-input" @input="filter" :placeholder="'Digite o vendedor ou fornecedor'" type="text" v-model="filters.name">
	          </div>
          </div>
          <div class="new-page__table-container">
            <div class="overflow-container">
              <div class="modal-table-container">
                <div class="modal-table-header">
                  <div class="modal-table-colum checkbox-width">
                    <cc-checkbox :checked="filters.check_all"
                                 @change="(evt) => toggle_check(evt.target.checked)" />
                  </div>
                  <div class="modal-table-colum provider-width">Fornecedor</div>
                  <div class="modal-table-colum seller-width">Vendedor</div>
                </div>
                <div v-for="seller in sellers"
                     :key="seller.usu_id"
                     v-show="seller.visible">
                  <div class="modal-table-row">
                    <div class="modal-table-colum checkbox-width">
                      <cc-checkbox v-model="seller.checked" :checked="seller.checked" />
                    </div>
                    <div class="modal-table-colum provider-width">{{ seller.for_nome }}</div>
                    <div class="modal-table-colum seller-width">{{ seller.usu_nome }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
	<!-- <cc-modal :modal="modal" @close="close">
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <cc-button :content="'Editar Fornecedores'" :icon="'fa fa-users'" @click="select_sellers" v-if="!show_sellers" :classes="'fill success'" :disabled="!ressuply.expiration_date"/>
                    <cc-button :content="'Voltar'" :icon="'fas fa-chevron-left'" @click="show_sellers = false" :classes="'fill default'" v-else />
                </div>
            </div>
        </div>
		<div slot="body" class="row">
			<cc-loader-full v-show="loading" />
            <template v-if="show_sellers">
                <div class="col-12 mb-3">
                    <cc-search :placeholder="'Vendedor ou Fornecedor'" :theme="'theme-green'" class="mt-1" v-model="filters.name" @input="filter" />
                </div>
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-3">
                                    <cc-checkbox :checked="filters.check_all"
                                        @change="(evt) => toggle_check(evt.target.checked)" />
                                </th>
                                <th>Fornecedor</th>
                                <th>Vendedor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="seller in sellers" v-bind:key="seller.usu_id" v-show="seller.visible">
                                <td class="pl-3">
                                    <cc-checkbox class="mt-1" v-model="seller.checked" :checked="seller.checked" />
                                </td>
                                <td>{{ seller.for_nome }}</td>
                                <td>{{ seller.usu_nome }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-if="!show_sellers">
                <div class="col-12">
                    <div>
                        <p class="col-sm-12 sub-title">INFORMAÇÕES DA COTAÇÃO</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group" id="datetime-wrp">
                        <label for="inputCodigo" class="col-sm-12 control-label">Data de expiração</label>
                        <datetime :min-datetime="min_date" ref="datepicker" v-model="ressuply.expiration_date" auto :minute-step=10 type="datetime" class="form-control"></datetime>
                        <span class="picker rounded-right" @click="open_datepicker">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Tipo de entrega</label>
                        <multiselect v-model="ressuply.delivery_type" track-by="code" label="label" placeholder="" open-direction="below" selected-label="Selecionado" :options="deliveryTypes"  :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Forma de Pagamento</label>
                        <multiselect v-model="ressuply.payment_type" track-by="code" open-direction="below" placeholder="" label="label" selected-label="Selecionado" :options="payment_types" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group pl-2">
                        <label for="inputCodigo" class="col-sm-12 control-label"></label>
                        <cc-checkbox @change="(evt) => ressuply.is_public = evt.target.checked" :checked="ressuply.is_public" :label="'Cotação Publica?'" />
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Selecionar Grupos</label>
                        <cc-select-v2 :multiple="true" :tracker="'id'" :text="'nome'" :options="groups" v-model="ressuply.groups"></cc-select-v2>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="inputCodigo" class="col-sm-12 control-label">Observação</label>
                        <input placeholder="" type="text" class="form-control" v-model="ressuply.observation" />
                    </div>
                </div>
            </template>
		</div>
	</cc-modal> -->
</template>

<script>
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/v2/products.service";
import { Datetime } from 'vue-datetime';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import StorageService from '@/services/local-storage.service';
import SellerGroupService from "@/services/v2/seller-group.service";
import AuthService from '@/services/AuthService';
import { mapGetters } from 'vuex'
import Button from "@/components/ui/buttons/Standard-Button.vue";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'


export default {
  props: ["products", "quotation", "closeDialog"],
  components: { datetime: Datetime, Button, StandardInput },
  data() {
    return {
		min_date: moment().toDate().toISOString(),
        groups: [],
        filters: {
            check_all: true
        },
		modal: {
			title: "enviar cotação",
			unpermitted_message: "Aceite o termo de consentimento para continuar.",
			permitted: true,
			submit: this.sendResuply,
			submit_text: 'Enviar',
			subtitle: 'Envie sua cotação e receba as melhores ofertas no seu prazo',
			style: {
				width: "70%"
			}
		},
        show_sellers: false,
        sellers: [],
        client: {},
        prod_svc: new ProductService(),
		service : new QuotationService(),
        group_svc: new SellerGroupService(),
        auth_svc: new AuthService(),
		payment_types: [],
		deliveryTypes: [
        {
            value: {code: 1,text: "CIF"},
            text: "CIF"
        },
        {
            value: {code: 2,text: "CIF/FOB"},
            text: "CIF/FOB"
        }
    ],
		loading: false,
		ressuply: {
            is_public: false,
			dateExpiration: moment()
			.add("2", "days")
			.format("DD/MM/YYYY")
		}
    };
  },
  methods: {
    toggle_check(val) {
        this.sellers.forEach(s => s.checked = val)
        this.filters.check_all = val
        this.$forceUpdate()
        return val
    },
    filter() {
        this.sellers.forEach(s => s.visible = false)
        let rx = new RegExp(this.filters.name, 'gi')
        this.sellers.filter(s => {
          if (!this.filter.name) return true
          if (s.usu_nome && s.usu_nome.match(rx)) return true
          if (s.for_nome && s.for_nome.match(rx)) return true
        })
        .forEach(s => s.visible = true)
    },
	open_datepicker() {
		document.getElementsByClassName('vdatetime-input')[0].click()
	},
    close() {
      this.$emit("close");
	},
	load_payment_types() {
		this.service.list_payment_types()
		.then(response => response.data)
		.then(data => {
			this.payment_types = data.data.map(p => { return { text: p.ttp_nome, value: p } });
		})
	},
    select_sellers() {
        this.loading = true
        this.show_sellers = true
        this.prod_svc.sellers_by_products(this.products.map(p => p.pro_id)).then(response => response.data).then(data => {
            this.sellers = data.data
            this.loading = false
            this.sellers.forEach(s => s.checked = true)
            this.sellers.forEach(s => s.visible = true)
        }).catch(() => {
            this.loading = false
        })
    },
    getStores() {
      var quotationService = new QuotationService();
      quotationService
        .getStoresClient()
        .then(response => response.data)
        .then(data => {
          this.stores = data.requisitantes.map(store => {
            return {
              value: store.loj_id,
              name: store.loj_descricao
            };
          });
          this.storeSelect = this.stores[0];
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    load_groups() {
        this.group_svc.all()
        .then(response => response.data)
        .then(data => {
          this.groups = []
          data.forEach(element => {
            this.groups.push({text: element.nome, value: element})
          });
          this.groups.sort((a, b) => a.text.localeCompare(b.text))
        })
        .catch(error => ErrorHandlerService.handle(error, this.$store))
	},
	build_products() {
		return this.products.map(p => Object.assign({}, { quantidade: p.quantidade, idProduto: p.pro_id, estoque: 0, observacao: '' }))
	},
    sendResuply() {
		this.loading = true;
		var quotationService = new QuotationService();

		var payload = {
			produtos: this.build_products(),
			idLoja: this.quotation.store.loj_id,
			dataExpiracao: moment(this.ressuply.expiration_date).format("DD/MM/YYYY HH:mm"),
			entrega: this.ressuply.delivery_type ? this.ressuply.delivery_type.code : null,
			pagamento: this.ressuply.payment_type ? this.ressuply.payment_type.ttp_nome : null,
            observacao: this.ressuply.observation,
            is_public: this.ressuply.is_public,
            group_ids: this.ressuply.groups ? [this.ressuply.groups.id] : null
		}

        if(this.sellers.length > 0) {
            payload.fornecedores = this.sellers.filter(s => s.checked).map(s => s.usu_id).join(",")
        }

		if(this.quotation.type == 2) {
			quotationService.inserircotacaocentral(payload)
			.then(response => response.data)
			.then(data => {
				this.close();
				this.$emit("finish");
			})
			.catch(error => {
				this.loading = false;
				ErrorHandlerService.handle(error, this.$store);
			});
		} else {
			quotationService.inserirCotacao(payload)
			.then(response => response.data)
			.then(data => {
				this.close();
				this.$emit("finish");
			})
			.catch(error => {
				this.loading = false;
				ErrorHandlerService.handle(error, this.$store);
			});
		}
    },
	load_tpc() {
		this.ressuply.delivery_type = this.deliveryTypes.filter(d => d.value.code == StorageService.tpc())[0].value;
	},
    load_settings() {
        let DEFAULT_PRIVATE_REQUESTS_SETTING = this.setting('DEFAULT_PRIVATE_REQUESTS')
        this.$set(this.ressuply, 'is_public', DEFAULT_PRIVATE_REQUESTS_SETTING && DEFAULT_PRIVATE_REQUESTS_SETTING.value == '1')
    },
    verify_client_deployed(){
        this.auth_svc.get_user().then(response => response.data).then(({ usuario }) => {
            this.client = usuario.cliente;
            if(this.client.cli_implantado == 2){
                this.load_settings();
            }

        }, err => {
            ErrorHandlerService.handle(err, this.$store, this.$store);
        })
    }
  },
  computed: {
    ...mapGetters({ setting: 'user/setting' })
  },
  mounted() {
	this.getStores()
	this.load_payment_types()
    this.load_tpc()
    this.load_groups()
    this.verify_client_deployed()
  }
};
</script>
