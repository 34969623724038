import http from "../http";
import Service from "../../Service";

/**
 * @type OrderService
 */
export default class TemporaryProductService extends Service {

	send(data) {
		return http.patch(`clients/requests/products/temp`, data, {
            headers: this.getHeaders()
        });
	}
    remove(data) {
        return http.put('clients/requests/products/temp/remove', data, {headers: this.getHeaders()});
    }

    count(data) {
		return http.get(`clients/requests/products/temp/count`, {
            headers: this.getHeaders(),
            params: data
        });
	}

    load(type = 'DEFAULT') {
		return http.get(`clients/requests/products/temp`, {
            headers: this.getHeaders(),
            params: { type }
        });
	}

}
