<style lang="scss">
@import '@/sass/commons/_variables';

.pagination {
    .desc {
        color: #878787;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    ul {
        list-style: none;
        display: flex;
        /* align-items: center; */
        justify-content: center;
        padding: unset;
        li {
            cursor: pointer;
            border: 1px solid #E8E9EC;
            border-radius: 8px;
            color: rgba(77,79,92,0.5);
            padding: 10px 17px;
            margin: 0 4px;
            &.active {
                background-color: #14ABBD;
                border: 1px solid #14ABBD;
                color: white;
            }
            &.prev {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    content: '';
                    border-top: 1px solid #808495;
                    border-left: 1px solid #808495;
                    transform: rotate(-45deg);
                    width: 10px;
                    height: 10px;
                }
            }
            &.next {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    content: '';
                    border-top: 1px solid #808495;
                    border-left: 1px solid #808495;
                    transform: rotate( 135deg );
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    &.orange {
        ul {
            li {
                &.active {
                    background-color: $primary-color !important;
                    border: 1px solid $primary-color;
                }
            }
        }
    }
    // @media (max-width: 767px) {
    //     ul {
    //         li {
    //             display: none;

    //             &:first-child,
    //             &:nth-child(2),
    //             &:nth-child(3),
    //             &:nth-last-child(1),
    //             &:nth-last-child(2),
    //             &:last-child {
    //                 display: flex;
    //             }
    //         }
    //     }
    // }




}
</style>
<template>
    <div  class="pagination" v-bind:class="classes" >
        <ul >
            <li v-on:click="goToPage(1)" class="prev"></li>
            <li v-on:click="goToPage(i + begin)"
                v-for="i in show_pages"
                v-show="i+begin <= total_pages"
                v-bind:key="i"
                v-bind:class="{active:(i+begin)==actual_page}">
                    {{i+begin}}
            </li>
            <li v-on:click="goToPage(total_pages)" class="next"></li>
        </ul>
        <div class="desc">
            Exibindo: {{ !(actual_page >= 2) ? 1 : (page-1)*items_by_page_value }}..{{ page*items_by_page_value }} de {{ total }} registros
        </div>
    </div>
</template>
<script>
import * as CONSTANTS from "../../../constants/constants";
export default {
    props: {
		'page_window_size' : {},
        'classes' : {
            default: ''
        },
        'items_by_page_value' : {
            default: '10'
		},
		'page_limit': { default : 20 },
        'page' : {
            default: 1
        },
        'total' : {
            default: 0
        },
        'skipScroll': {
            default: false
        }
    },
    data() {
        return {
            CONSTANTS: CONSTANTS,
            total_pages: 0,
			actual_page: 1,
			show_pages: 20,
			begin: 0,
			end: 20
        };
    },
    methods: {
        goToPage( new_page ) {
			this.actual_page = new_page;
			this.show_pages = this.page_limit;
			this.show_pages = this.show_pages > this.total_pages ? this.total_pages : this.show_pages;
			this.begin = this.actual_page
			var count = 0;
			for(var i = this.actual_page; i > 0 && this.begin > 0 && count < (this.show_pages/2);i--) {
				this.begin--;
				count++;
			}
			if(this.actual_page+(this.show_pages/2) > this.total_pages) {
				this.show_pages = this.total_pages - this.begin;
			}
            if (!this.skipScroll) window.scrollTo({ top: 0, behavior: 'smooth' });
        }
	},
    watch: {
		total: function() {
			this.show_pages = this.page_limit;
			this.total_pages = Math.ceil(this.total/this.items_by_page_value);
			this.actual_page = this.page;
			this.show_pages = this.show_pages > this.total_pages ? this.total_pages : this.show_pages;
		},
        items_by_page_value : function() {
            this.total_pages = Math.ceil(this.total/this.items_by_page_value);
			this.actual_page = this.page;
			this.begin = 0;
			this.show_pages = this.show_pages > this.total_pages ? this.total_pages : this.show_pages;
            this.goToPage(this.actual_page)
        },
        page: function(page) {
            this.actual_page = page
        },
        actual_page:function(new_page) {
            this.$emit('change', new_page);
        }
    }
}
</script>

