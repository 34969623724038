import CartItem from "@/models/cart-item.model";

const default_state = () => {
    return {
        store: null,
        obs: null,
        providers: []
    };
};

export default {
    namespaced: true,
    state: default_state(),
    mutations: {
        ADD_PROVIDER(state, provider) {
            let prov = state.providers.find(p => p.id == provider.id)
            if (!prov) {
                state.providers.push({ ...provider, items: [] });
            }
        },
        SET_STORE(state, store) {
            state.store = store;
        },
        SET_OBS(state, obs) {
            state.obs = obs;
        },
        ADD_ITEM(state, item) {
            let provider = state.providers.find(p => p.id == item.campaign.provider.id)
            let current = provider.items.find(it => it.item.id == item.id);
            if (current) {
                current.quantity++;
            } else {
                provider.items = [...provider.items, new CartItem(item)];
            }
        },
        UPDATE_QUANTITY(state, { item, quantity }) {
            let provider = state.providers.find(p => p.id == item.campaign.provider.id)
            let current = provider.items.find(it => it.item.id == item.id);
            if (isNaN(quantity) || quantity < 0) {
                return;
            }
            if (current) {

                current.quantity = quantity;

                if (current.quantity == 0)provider.items = provider.items.filter(i => i.id != item.id);

                if (provider.items.length == 0)state.providers = state.providers.filter(p => p.id != provider.id);

            } else {
                let new_item = new CartItem(item);
                new_item.quantity = quantity;
                provider.items = [...provider.items, new_item];
            }
        },
        REMOVE_ITEM(state, item) {
            let provider = state.providers.find(p => p.id == item.campaign.provider.id)
            if(provider) {
                let current = provider.items.find(it => it.item.id == item.id);
                if (current) {

                    if (current.quantity > 0)current.quantity--;

                    if (current.quantity == 0)provider.items = provider.items.filter(i => i.id != item.id);

                    if (provider.items.length == 0)state.providers = state.providers.filter(p => p.id != provider.id);
                }
            }
        },
        RESET(state) {
            Object.assign(state, default_state());
        }
    },
    actions: {
        set_campaign({ commit }, campaign) {
            commit("SET_CAMPAIGN", campaign);
        },
        set_obs({ commit }, obs) {
            commit("SET_OBS", obs);
        },
        reset({ commit }) {
            commit("RESET");
        },
        set_store({ commit }, store) {
            commit("SET_STORE", store);
        },
        add_item({ commit }, item) {
            item.campaign.provider.campaign = item.campaign
            commit("ADD_PROVIDER", item.campaign.provider);
            commit("ADD_ITEM", item);
        },
        update_quantity({ commit }, { item, quantity }) {
            commit("ADD_PROVIDER", item.campaign.provider);
            commit("UPDATE_QUANTITY", { item, quantity });
        },
        remove_item({ commit }, item) {
            commit("REMOVE_ITEM", item);
        }
    },
    getters: {
        items: state => state.providers.map(p => p.items).flat(),
        cart_item_for: state => item => {
            let prov = state.providers.find(p => p.id == item.campaign.provider.id) || { items: [] }
            return prov.items.find(i => i.item.id == item.id) || { quantity: 0 }
        },
        total_for: state => provider =>
            provider.items
                .map(item => Object.assign(new CartItem(), item))
                .reduce(
                    (total, item) => (total += item.price * item.quantity),
                    0
                ),
        total: state =>
            state.providers
                .map(p => p.items)
                .flat()
                .map(item => Object.assign(new CartItem(), item))
                .reduce(
                    (total, item) => (total += item.price * item.quantity),
                    0
                ),
        valid_amounts: (state, getters) => !state.providers.some(p => getters.total_for(p) < p.minimum_billing),
        total_amount: state =>
            state.items
                .map(item => Object.assign(new CartItem(), item))
                .reduce((total, item) => (total += item.quantity), 0),
        cart_request_for: state => provider => {
            return {
                provider: { id: provider.id },
                store: state.store,
                obs: state.obs,
                campaign: { id: provider.campaign.id },
                items: provider.items.map(i => {
                    return {
                        campaign_item_id: i.item.id, quantity: i.quantity,
                        id: i.item.product.id,
                        unit_price: i.item.unit_price,
                        wrap_price: i.item.wrap_price
                    }
                })
            };
        }
    }
};
