<template>
    <div class="save-order-divergent-container">
        <div class="save-order-divergent-modal">
            <header class="save-order-divergent-header">
                <h1 class="save-order-divergent-header-text">Inclusão de Divergências de Entrega</h1>
                <span @click="back" class="material-icons-outlined save-order-divergent-close">cancel</span>
            </header>

            <body class="save-order-divergent-body">
                <p class="save-order-divergent-alert">Divergência: Data de entrega e/ou condição de pagamento</p>
                <div class="save-order-divergent-buttons-container">
                    <div>
                        <p class="save-order-divergent-text">Data marcada: {{ current_subrequest.data_entrega |
                    formatDateOnly }}</p>
                        <div class="page-filters__right-select-wrapper">
                            <datetime style="border-radius: 8px !important" v-model="selectedDate" class="form-control"
                                ref="datepicker" type="date"></datetime>
                            <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                                today
                            </span>
                        </div>
                    </div>
                    <div>
                        <p class="save-order-divergent-text">Condição de pagamento: {{ current_subrequest.forma }}</p>
                        <select class="save-order-select" v-model="paymentOp" name="" id="">
                            <option selected disabled>Condição que foi cobrada</option>
                            <option v-for="op in payment_types" :value="op.code" :key="op.label">{{ op.label }}</option>
                        </select>

                    </div>

                </div>
                <div class="save-order-divergent-buttons-container">
                    <button @click="back" class="save-order-divergent-back">Voltar</button>
                    <button @click="save" class="save-order-divergent-save">Prosseguir</button>
                </div>
            </body>
        </div>

        <cc-loader-full v-if="isLoding" />
    </div>
</template>

<script>
import divergentService from '@/services/v3/divergentService.js'

export default {
    name: 'SaveOrderDivergentModal',
    props: ['back', 'current_subrequest', 'update', 'payment_types'],
    data() {
        return {
            service: new divergentService(),
            isLoding: false,
            paymentOp: undefined,
            selectedDate: undefined
        }
    },
    methods: {
        save() {
            let payload = {
                'pedido_id': this.current_subrequest.id_pedido,
                'condicao_pagamento': this.paymentOp,
                'data_entrega_realizada': this.selectedDate,
                "obs": '  '
            }
            console.log(payload);
            this.isLoding = true
            this.service.saveOrderDivergent(payload).then(() => {
                this.isLoding = false
                this.back()
                this.update()
            })
        },
        // load_payment_types() {
        //     this.quotationService.list_payment_types()
        //     .then(response => response.data)
        //     .then(data => {
        //         this.payment_types = data.data.map(p => { return { label: p.ttp_nome, code: p.ttp_id } });
        //     })
        // },
        open_datepicker(idx) {
            document.getElementsByClassName("vdatetime-input")[idx].click();
        },
    },
    watch: {
        selectedDate(value) {
            console.log(value);
        }
    },
    computed: {
        placeholder() {
            if (this.selected == 4) return 'Digite a marca do produto que foi entregue'
            if (this.selected == 1) return 'Digite a quantidade entregue'
            if (this.selected == 2) return 'Digite o valor cobrado no momento da entrega'
        }
    }
}
</script>

<style scoped lang="scss">
.save-order-divergent-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.save-order-divergent-modal {
    width: 50vw;
}

.save-order-divergent-header {
    background-color: var(--primary-color);
    box-sizing: border-box;
    padding: 1vh 2vw;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save-order-divergent-header-text {
    color: white;
    font-size: 2em;
}

.save-order-divergent-close {
    color: white;
    font-size: 2.5em;
    cursor: pointer;
}

.save-order-divergent-body {
    box-sizing: border-box;
    padding: 5vh 7vw;
    border-radius: 0 0 10px 10px;
}



.save-order-divergent-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
}

.save-order-divergent-back {
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    width: 45%;
    padding: 1vh 0;
    color: var(--primary-color);
    font-size: 1.8em;
}

.save-order-divergent-save {
    border-radius: 5px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    width: 45%;
    padding: 1vh 0;
    color: white;
    font-size: 1.8em;
}

.save-order-divergent-alert {
    font-size: 1.8em;
    color: #E53535;
}

.save-order-divergent-text {
    color: #808080;
    font-size: 1.4em;
}

.save-order-divergent-input {
    width: 70%;
    border-radius: 4px;
    border: 1px solid #DADADA;
    background: #FFF;
    box-sizing: border-box;
    padding: 1vh 1vw;
    color: #808080;
    font-size: 1.3em;
}

.save-order-divergent-input:focus {
    outline: none;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;

    .form-control {
        width: 21vw;
    }
}

.save-order-select {
    border-radius: 8px;
    border: 1px solid #ced4da;
    background-color: white;
    padding: 0.375rem 0.75rem;
    width: 23vw;
    color: gray;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.date-icon {
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -4vw;
    color: gray;
}

@media only screen and (max-width: 850px) {
    .save-order-divergent-modal {
        width: 100vw;
    }

    .save-order-divergent-alert {
        font-size: 1.4em;
    }

    .save-order-divergent-text {
        font-size: 1.1em;
    }

    .save-order-select {
        width: 100%;
    }

    .page-filters__right-select-wrapper {
        .form-control {
            width: 100%;
        }
        .date-icon{
            left: -25%;
        }
    }
    .save-order-divergent-save{
        font-size: 1.5em;
    }
    .save-order-divergent-back{
        font-size: 1.5em;
    }
}
</style>