<template></template>
<script>
    export default {
        name: 'ChatIcon',
        created () {
            (function(i,s,g,r,j,y,b,p,t,z,a) {
                a=s.createElement(r),
                a.async=1,
                a.src=g.concat(b,j,b,y,p,j),
                s.head.appendChild(a),
                a.onload = function() {
                    opa.init(g,t,z)
                }
            } )(window,document,'https://clubdacotacao.oxesuite.com.br','script','js','opa','/','.','63349cb7e1db733adb1b2739','{"google_credential":"AIzaSyDdfka9ZLwjvn1FkLHvrC-w6DwVXCeLoEY","google_oauth":"944501860376-4i56qo79o9havqvd8g39c5j2vt6n93cq.apps.googleusercontent.com","facebook_appid":"5949934968351388","permitir_login_anonimo":"on"}');
        }
    }
</script>