<style lang="scss" scoped>
    @import "./_products.scss";
.winners-page-containear{
    padding:  0 15px 60px 0;
    font-size: 1vw;
}
.winners-page-route-select{
    display: flex;
    align-items: center;
}
.winners-page-route-select-text{
    font-weight: 400;
    font-size: 1.25em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.winners-page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.winners-page-title{
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 35px;
}
.winners-page-mobile-info{
    margin-top: 3vh;
    width: 36%;
}
.winners-page-buttons-container{
    display: flex;
    margin-top: 6vh;
    justify-content: space-between;
}
.winners-page-buttons-1{
    display: flex;
    width: 40%;
}
.winners-page-buttons-2{
    display: flex;
    width: 57%;
    justify-content: space-between;
}
.winners-page-options-select{
    position: absolute;
    margin-top: 1vh;
    right: 60px;
    padding: 0.5vw;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background-color: white;
}
.winners-page-button-1{
    margin-left: 1.5vw;
}
.winners-page-options-select-option{
    font-size: 1.25em;
    padding: 0.5vh;
    text-align: end;
    cursor: pointer;
}
.winners-page-options-select-option:hover{
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
}
.winners-page-filters-container{
    display: flex;
    margin-top: 5vh;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.winners-page-filters-preorders-icon-container{
    margin-left: 2vw;
}
.winners-page-filters-preorders-container{
    align-items: flex-end;
    justify-content: flex-start;
}
.winners-page-selects-container{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.winners-page-selects-option{
    display: flex;
    justify-content: space-between;
    width: 30%;
}
.winners-page-selects-option{
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.15px;
    color: #898989;
}
.winners-page-products-container{
    margin-top: 4vh;
    overflow-x: auto;
}
.winners-page-product{
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5vw 1vw;
    margin-bottom: 2vh;
}
.winners-page-product-title{
    font-weight: 400;
    font-size: 1.4em;
    color: #101010;
    display: flex;
    align-items: center;
}
.winners-page-product-title-container{
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-color-opacity);
    border-radius: 7px;
    padding: 0.1vw 0.5vw;
}
.winners-page-product-quantity{
    font-weight: 500;
    font-size: 1.25em;
    color: #101010;
}
.winners-page-product-info-container{
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.25em;
    color: #606060;
}
.winners-page-product-info-lastbuy{
    display: flex;
    align-items: center;
}
.winners-page-product-info-lastbuy-icon{
    color: #17A2B8;
    margin-left: 5px;
    cursor: pointer;
}
.winners-page-product-info-table-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    display: flex;
    padding: 1vh 0;
    margin-top: 2vh;
    font-weight: 400;
    font-size: 1.1em;
    color: #605F5F;
}
.info-table-checkbox-width{ width: 10%; }
.info-table-suplier-width{ flex: 1; }
.info-table-brand-width{ width: 14%; }
.info-table-payment-width{ width: 14%; }
.info-table-quantity-width{ width: 10%; }
.info-table-value-width{ width: 14%; }
.info-table-obs-width{ width: 18%; }
.winners-page-product-info-table-row{
    display: flex;
    padding: 1vh 0;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    align-items: center;
}
.checkbox-icon{ cursor: pointer; }
.info-table-suplier-width-colum{
    display: flex;
    align-items: center;
}
.suplier-fav-icon{
    color: #FFCA2B;
    margin-right: 5px;
    cursor: pointer;
}
.page-table-line-special{  background: #F7F7F7; }
.loader-containear{
    padding-top: 10vh;
}
.toggle-label{ color: #898989; margin-left: 1vw;}
.mobile{ display: none;}
.disabled-check-box{
    cursor: default !important;
    color: lightgrey !important;
}
.button-containear{
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
}
.button-containear-active{
    background-color: #fdf0e9;
    .button-text{color: var(--primary-color);}
}
.button-text{
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
  margin-left: 1vw;
}
.button-containear:hover{
  background-color: #fdf0e9;
}
.button-containear:hover > .button-text{
  color: var(--primary-color);
}
.winners-page-buttons-1-icon{
    color: var(--primary-color);
}
.winner-page-product-info-table-mobile-header{display: none;}
.winners-loader{
    height: 3vh;
    margin-left: 1vw;
}
.current-store-title{
    background-color: yellow;
    width: fit-content;
    border-radius: 8px;
    padding: 0 1vw;
}
@media only screen and (max-width: 850px) {
    .winners-page-product-info-table-header{
        display: none;
    }
    .winners-page-product{font-size: 1.5em;}
    .winners-page-product-info-table-row{
        flex-direction: column;
        gap: 1vh;
        padding: 2vh 2vw;
    }
    .info-table-checkbox-width{ width: 100%; display: flex; justify-content: space-between; }
    .info-table-suplier-width{ flex: 1; width: 100%; display: flex; justify-content: space-between; }
    .info-table-brand-width{ width: 100%; display: flex; justify-content: space-between; }
    .info-table-payment-width{ width: 100%; display: flex; justify-content: space-between; }
    .info-table-quantity-width{ width: 100%; display: flex; justify-content: space-between; }
    .info-table-value-width{ width: 100%; display: flex; justify-content: space-between; }
    .info-table-obs-width{ width: 100%; display: flex; justify-content: space-between; }
    .winner-page-product-info-table-mobile-header{
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
    }

}
</style>

<template>
    <div>
        <div class="cc-loader" :class="{ hidden : !is_loading }">
            <img src="/images/loader.svg" />
            <span>Carregando</span>
        </div>
        <div >
            <div class="winners-page-product" v-for="(product, index) in products" :key="index" v-show="!loj_id || product.visible">
                <div class="winners-page-product-title-container">
                    <div class="winners-page-product-title">
                        {{product.descricaoProduto}}
                    </div>
                    <div class="winners-page-product-quantity">Quantidade:{{ product.quantidadeSolicitacao ? product.quantidadeSolicitacao : 0 }} {{ product.unidade_compra }}</div>
                </div>
                <div v-if="product.last_purchase" class="winners-page-product-info-container">
                    <div class="winners-page-product-info-lastbuy">
                        <div>Dados da ultima compra:</div>
                        <span @click="show_orders(product)" class="material-icons-outlined winners-page-product-info-lastbuy-icon">assignment</span>
                    </div>
                    <div> Fornecedor: {{ product.last_purchase.for_nome }} </div>
                    <div v-if="product.last_purchase.pro_observacao"> Marca : {{ product.last_purchase.pro_observacao }} </div>
                    <div> Data: {{ product.last_purchase.data_geracao | formatDateOnly }} </div>
                    <div> Quantidade: {{ product.last_purchase.quantidade }} </div>
                    <div> Valor: {{formatPrice( product.last_purchase.valor )}} </div>
                </div>
                <div class="winners-page-product-info-table-header">
                    <div class="info-table-checkbox-width"></div>
                    <div class="info-table-suplier-width">Fornecedor</div>
                    <div class="info-table-brand-width">Marca</div>
                    <div class="info-table-payment-width">Forma De Pgto	</div>
                    <div class="info-table-quantity-width">QTD. EMB.</div>
                    <div class="info-table-value-width">Valor unitário</div>
                    <div class="info-table-obs-width">Obervação</div>
                </div>
                <div class="winners-page-product-info-table-row" :class="getSpecialBackground(idx)" v-for="(winner, idx) in product.vencedores" :key="idx">
                    <div class="info-table-checkbox-width info-table-suplier-width-colum">

                        <span :title="getDisableTitle(winner)" v-on:click.self="DisableCheckBox(winner, product)" v-if="is_winner(winner)" class="material-icons-outlined" :class="getDisableCheckBox(winner)" style="color: var(--primary-color); cursor: pointer; margin-right: 10px; margin-left: 1vw">check_box</span>
                        <span :title="getDisableTitle(winner)" v-on:click.self="DisableCheckBox(winner, product)" v-else class="material-icons-outlined" :class="getDisableCheckBox(winner)" style="color: var(--primary-color); cursor: pointer; margin-left: 1vw">check_box_outline_blank</span>
                        <img class="winners-loader" :class="{ 'hidden' : !winner.is_loading }"  src="/images/loader.svg"/>
                        <span v-if="is_winner(winner)" :class="{ 'hidden' : winner.is_loading }" @click="current_winner = winner;current_product=product;show_current_winner=true" class="material-icons-outlined" style="color: var(--primary-color); cursor: pointer;">shopping_cart</span>
                    </div>
                    <div class="info-table-suplier-width">
                        <div class="winner-page-product-info-table-mobile-header">Fornecedor</div>
                        <div :class="{ 'current-store-title' : seller_id === winner.idVendedor }">
                            {{winner.nomeFornecedor}}
                        </div>
                    </div>
                    <div class="info-table-brand-width info-table-suplier-width-colum">
                        <div class="winner-page-product-info-table-mobile-header">Marca</div>
                        <div>
                            <span v-on:click="setPreferencial(winner)" v-if="winner.pro_preferencial == 1 && product.flag_generico == 1" class="material-icons-outlined suplier-fav-icon">star</span>
                            <span v-on:click="setPreferencial(winner)" v-if="winner.pro_preferencial == 0 && product.flag_generico == 1" class="material-icons-outlined suplier-fav-icon">star_outline</span>
                            {{ winner.pro_observacao }}
                        </div>

                    </div>
                    <div class="info-table-payment-width">
                        <div class="winner-page-product-info-table-mobile-header">Forma De Pgto</div>
                        {{(winner.forma)? winner.forma: '-'}}
                    </div>
                    <div class="info-table-quantity-width">
                        <div class="winner-page-product-info-table-mobile-header">QTD. EMB.</div>
                        {{product.flag_generico == 1 ? winner.embalagem : product.qtd_embalagem}}
                    </div>
                    <div class="info-table-value-width">
                        <div class="winner-page-product-info-table-mobile-header">Valor unitário</div>
                        {{winner.valor  | customCurrency}}
                    </div>
                    <div class="info-table-obs-width" :title="winner.observacao">
                        <div class="winner-page-product-info-table-mobile-header">Obervação</div>
                        {{winner.observacao ? formatObs(winner.observacao) : '-'}}
                    </div>
                </div>
            </div>
            <cc-pagination
					 classes="orange"
					 :items_by_page_value="20"
					 :total="total_items"
					 :page="page"
					 :page_limit="5"
					 v-on:change="updatePage($event)">
            </cc-pagination>
        </div>

        <cc-add-outside-request-seller :seller_id="current_winner.idVendedor"
            v-if="show_outside_seller"
            :request_id="$route.params.id"
            @close="current_winner = null;show_outside_seller=false" />
        <cc-product-sub-requests v-if="current_winner && show_current_winner"
            :salesman="current_winner"
            :loj_id="loj_id"
            :quotationId="$route.params.id"
            :product="current_product"
            @close="current_winner = null;show_current_winner=false"
            @load_products="reload_products"
            @loadProviders="loadProviders" :sellers="sellers" />
        <ProductOrdersModal
            :product_id="current_product.prod_id"
            :product_name="current_product.descricaoProduto"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
    </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverStores from "@/components/popovers/stores.popover";
import ProductOrdersModal from '@/modals/products/orders';
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import ProductService from '@/services/ProductService';
import AddOutsideRequestSeller from '@/modals/add-outside-request-seller/add-outside-request-seller.modal'
import currencyPrecision from '../../mixins/currencyPrecision';
export default {
    components: {
        'cc-product-sub-requests': ProductSubRequestsModal,
        popoverStores,
        ProductOrdersModal,
        'cc-add-outside-request-seller': AddOutsideRequestSeller,
        ccPagination: Pagination,
    },
    props: ["products", "wraps", "is_loading", "sellers", "loj_id", "seller_id", 'updatePage', 'total_items', 'page'],
    data() {
        return {
            show_current_winner: false,
            show_outside_seller: false,
            current_winner: null,
            current_product: null,
            openedPopovers: [],
            filters: {},
            collapsed: [],
            quotationService: new QuotationService(),
            prod_svc: new ProductService(),
            delivery : {},
            is_product_orders_modal_open: false
        };
    },
    mixins: [currencyPrecision],
    methods: {
        setSeller (value) {
            this.filters.store = {value: value}
            this.update()
        },
        setPreOrders () { this.open('PRE-ORDERS') },
        formatObs (text) {
            if (text.length > 25) return text.slice(0,24) + '...'
            return text
        },
        irregular_amount(product) { return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada },
        DisableCheckBox(winner, product) {
            if (winner.qtdPedido < 1) this.toggle_winner(winner, winner.idVendedor, this.is_winner(winner), product)
        },
        getDisableTitle(winner) {
            if (winner.qtdPedido > 0) return 'Fornecedor com pedido gerado'
        },
        getDisableCheckBox(winner){ if (winner.qtdPedido > 0) return 'disabled-check-box' },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        show_orders(product) {
            if(product.last_purchase) {
                this.current_product = product
                this.is_product_orders_modal_open = true
            }
        },
        irregular_amount(product) {
            return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada
        },
        is_winner(winner) {
            console.log(winner)
            if(this.loj_id != null && winner.quantidade == 0 && winner.vencedor_quantidade == 0)return false;
            return winner.vencedor_quantidade > 0;
        },
        loadProviders(){
            this.$emit("loadProviders", false);
        },
        reload_products() {
            this.$emit("reload_products", this.$route.params.id, false);
        },
        showStores(winner){
            this.current_winner = null;
            this.current_product = null;
        },
        setPreferencial(produto) {
            produto.pro_preferencial = produto.pro_preferencial == 1 ? 0 : 1;
            this.prod_svc.pin_brand({ idProduto: produto.pro_id, preferencial: produto.pro_preferencial })
            .then(({ data }) => {
                this.$props.products.forEach(p => {
                    if (produto.produtoprincipal == p.prod_id) {
                        p.vencedores.forEach(w => {
                            if (produto.pro_id == w.pro_id) {
                                w.pro_preferencial = produto.pro_preferencial;
                            }
                        });
                    }
                });
            }).catch(function() {})
        },
        toggle_winner(winner, seller_id, is_winner, product = null) {
            this.$set(winner, "is_loading", true);
            if(this.loj_id && is_winner) {
                this.update_items_quantity(winner, this.loj_id, seller_id, () => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", 0);
                    this.$emit("loadProviders")
                });
            } else {
                this.quotationService.toggleQuotationWinner(
                    this.$route.params.id,
                    this.loj_id,
                    winner.pro_id,
                    seller_id,
                    !is_winner
                ).then(response => response.data).then(() => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", !is_winner ? 1 : 0);
                    if(this.loj_id) {
                        if(winner.vencedor_quantidade) {
                            this.current_winner = winner
                            this.current_product= product
                            this.show_current_winner = true
                        }
                        this.$emit("loadProviders")
                    }
                    this.reload_products()
                });
            }
        },
        update_items_quantity(winner, loj_id, seller_id, callback) {
            this.quotationService.sendQuantidade({
                numerocotacao: "" + this.$route.params.id,
                quantidade: "0",
                vendedor: seller_id,
                produto: winner.pro_id,
                loja: loj_id
            })
            .then(response => response.data)
            .then(callback);
        },
        getStore(winner, loj_id, seller_id, callback) {
            var quotationService = new QuotationService();
            quotationService.getLojasQuantidade(
                winner.pro_id,
                this.$route.params.id,
                seller_id,
                loj_id
            ).then(response => response.data)
            .then(callback).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        toggle_collapse(index) {
            this.$set(this.collapsed, index, !this.collapsed[index]);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getFormaPagamento(sellers,winner){
            let delivery = [];
            if(sellers.fornecedores.length > 0){
                delivery = sellers.fornecedores
                .filter(f => {

                    return f.idVendedor == winner.idVendedor
                })
            }
            return delivery && delivery[0] ? delivery[0].entrega.forma : '-';
        }
  }
};
</script>
