
import Service from "@/services/Service";
import http from "./Http";

export default class ExternalUserService extends Service{
  getAudit (id) {
    const url = 'audit?pro_id=' + id
    const config = {
      headers: this.getHeaders()
    };
    return http.get(url, config)
  }
}