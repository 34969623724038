export default class CartItem {

    quantity;
    item;

    constructor(item) {
        this.item = item
        this.quantity = 1
    }

    get price() {
        return this.item.wrap_price;
    }

    get total() {
        return this.item.wrap_price * this.quantity;
    }

    get wrap() {
        return this.item.wrap
    }

    get wrap_quantity() {
        return this.item.wrap_quantity;
    }

    get id() {
        return this.item.id
    }
}
