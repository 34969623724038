<template>
    <div class="add-divergent-container">
        <div class="add-divergent-modal" v-if="!isLoading">
            <header class="add-divergent-header">
                <h1 class="add-divergent-header-text"> {{ product.produtoDescricao }}</h1>
                <span @click="back" class="material-icons-outlined add-divergent-close">cancel</span>
            </header>

            <body class="add-divergent-body">
                <p class="add-divergent-select-title">Selecione as opções que se aplicam:</p>
                <div v-for="op in opList" :key="op.id" class="add-divergent-line">
                    <span v-if="op.lock == true" class="material-icons add-divergent-check" style="color: lightcoral;"
                        @click="toggleOp(op)">delete</span>
                    <span v-else-if="op.update == true" @click="updateOp(op)"
                        class="material-icons-outlined add-divergent-edit">save</span>
                    <span v-else-if="op.selected == true" class="material-icons add-divergent-check"
                        @click="toggleOp(op)">check_box</span>
                    <span v-else class="material-icons add-divergent-check"
                        @click="toggleOp(op)">check_box_outline_blank</span>
                    <span @click="disableLock(op)" v-if="op.lock == true && op.descricao != 'Produto nao veio'"
                        class="material-icons-outlined add-divergent-edit">edit</span>
                    <p class="add-divergent-text">{{ op.descricao }}</p>

                    <money :disabled="op.lock" v-if="op.tipo == 'MONEY' && op.selected == true"
                        v-model="op.observacao" class="add-divergent-input" />
                    <input :disabled="op.lock" v-else-if="op.tipo == 'NUMERIC' && op.selected == true"
                        v-model="op.observacao" class="add-divergent-input" type="number">
                </div>
                <div class="add-divergent-line" style="margin-top: 5vh;" @click="reBuy = !reBuy">
                    <span v-if="reBuyIsSaved" class="material-icons add-divergent-check" style="color: lightcoral;"
                        @click="toggleOp({lock: true, id:reBuyIsSaved})">delete</span>

                    <span v-if="!reBuy && !reBuyIsSaved"
                        class="material-icons-outlined add-divergent-rebuy-icon">radio_button_unchecked</span>
                    <span v-else-if="!reBuyIsSaved" class="material-icons-outlined add-divergent-check">check</span>

                    <span @click="reBuyUpdate = true" v-if="reBuyIsSaved && !reBuyUpdate"
                        class="material-icons-outlined add-divergent-edit">edit</span>
                    <span v-if="reBuyUpdate"
                        @click="updateOp({ id: reBuyIsSaved, id_opcao_divergencia: 999, observacao: reBuyValue })"
                        class="material-icons-outlined add-divergent-edit">save</span>
                    <p class="add-divergent-text">Gostaria de recomprar esse produto</p>
                    <input v-on:click.stop v-if="reBuy || reBuyUpdate" v-model="reBuyValue" class="add-divergent-input"
                        type="number">
                </div>
                <div class="add-divergent-buttons-container">
                    <button @click="back" class="add-divergent-back">Voltar</button>
                    <button @click="save" class="add-divergent-save">Salvar</button>
                </div>
            </body>
        </div>
        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import divergentService from '@/services/v3/divergentService.js'
export default {
    name: 'AddDivergentModal',
    props: ['back', 'product', 'cotId', 'update', 'id_pedido'],
    data() {
        return {
            selected: 1,
            reBuyValue: 0,
            isLoading: true,
            service: new divergentService(),
            opList: [],
            reBuy: false,
            reBuyIsSaved: false,
            reBuyUpdate: false
        }
    },
    methods: {
        toggleOp(op) {
            if (op.lock) {
                this.$swal.fire({
                    title: "Deseja excluir essa divergência?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, excluir divergência!"
                }).then(resp => {
                    if (resp.isConfirmed) {
                        this.isLoading = true
                        this.service.deleteDivergent(op.id).then(() => {
                            this.isLoading = false
                            this.update()
                            this.back()
                        })
                    }
                })
                return
            }
            op.selected = !op.selected;
            this.$forceUpdate()
        },
        disableLock(op) {
            op.lock = false
            op.update = true
            this.$forceUpdate()
        },
        savedDivergentsList() {
            return this.opList.filter(element => element.lock)
        },
        updateOp(element) {
            this.isLoading = true
            let payload = {
                "id": element.id,
                "id_cotacao": this.cotId,
                "id_pedido": this.id_pedido,
                "id_produto": this.product.idProduto,
                status: element.id_opcao_divergencia == 999 ? 'RECOMPRA' : null,
                "id_opcao_divergencia": element.id_opcao_divergencia,
                "observacao": element.descricao == 'Produto cobrado mais caro' ? element.observacao.toFixed(2) : element.observacao
            }
            this.service.updateDivergent(payload).then(() => {
                element.lock = true
                this.update()
                this.isLoading = false
            })
        },
        save() {
            let requestList = this.opList.filter(element => element.selected && !element.lock && !element.update)
            // if (requestList.length == 0 && this.opList.filter(element => element.lock).length > 0 && this.reBuy) {
            //     requestList.push(this.opList.filter(element => element.lock)[0])
            //     requestList[0].update = true
            // } else 
            if (this.reBuy && this.reBuyValue < 1) {
                this.$swal.fire({
                    title: "Insira um valor para a recompra.",
                    type: "warning",
                })
                return
            } else if (requestList.length == 0 && (!this.reBuy || this.reBuy && this.reBuyUpdate)) {
                this.$swal.fire({
                    title: "Selecione uma divergência",
                    type: "warning",
                })
                return
            }
            let requestsComplete = 0
            this.isLoading = true
            if (this.reBuy && !this.reBuyIsSaved) requestList.push({ id: 999, observacao: this.reBuyValue })
            requestList.forEach(element => {
                let payload = {
                    "id_cotacao": this.cotId,
                    "id_pedido": this.id_pedido,
                    status: element.id == 999 ? 'RECOMPRA' : null,
                    "id_produto": this.product.idProduto,
                    "id_opcao_divergencia": element.id,
                    "observacao": element.descricao == 'Produto cobrado mais caro' ? element.observacao.toFixed(2) : element.observacao
                }
                this.service.saveDivergent(payload).then(resp => {
                    requestsComplete++
                    if (resp.data.id_opcao_divergencia == 999) {
                        this.reBuyIsSaved = resp.data.id
                    } else {
                        element.lock = true
                        element.id = resp.data.id
                    }
                    if (requestsComplete == requestList.length) {
                        this.update()
                        this.isLoading = false
                    }
                })
            });
        }
    },
    created() {
        this.service.getOptions().then(resp => {
            if (this.product.divergencias.length > 0) {
                // resp.data.forEach(element => { if (element.id != this.productDivergent[0].id && element.id !=3) this.opList.push(element)});
                this.opList = resp.data.map(element => {
                    let resp = element
                    this.product.divergencias.forEach(element2 => {
                        if (element2.id_opcao_divergencia == 999) {
                            this.reBuyIsSaved = element2.id
                            this.reBuyValue = element2.observacao
                        }
                        else if (element.id == element2.id_opcao_divergencia) resp = { ...element2, tipo: element.tipo, descricao: element.descricao, selected: true, lock: true }
                    });
                    return resp
                })
            }
            else this.opList = resp.data
            this.opList = this.opList.filter(value => value.id != 999)
            this.isLoading = false
        })
    }
}
</script>

<style scoped lang="scss">
.add-divergent-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-divergent-modal {
    width: 55vw;
}

.add-divergent-header {
    background-color: var(--primary-color);
    box-sizing: border-box;
    padding: 1vh 2vw;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-divergent-header-text {
    color: white;
    font-size: 1.8em;
}

.add-divergent-close {
    color: white;
    font-size: 2.5em;
    cursor: pointer;
}

.add-divergent-body {
    box-sizing: border-box;
    padding: 5vh 6vw;
    border-radius: 0 0 10px 10px;
}

.add-divergent-select-title {
    color: #605F5F;
    font-size: 2em;
}

.add-divergent-line {
    display: flex;
    align-items: center;
    height: 6.5vh;
    gap: 2vw;
    position: relative;
    padding-top: 1vh;
    padding-bottom: 1vh;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.add-divergent-text {
    color: #605F5F;
    font-size: 1.7em;
    margin: 0;
}

.add-divergent-check {
    font-size: 2em;
    color: var(--primary-color);
    cursor: pointer;
}

.add-divergent-rebuy-icon {
    font-size: 2em;
    color: gray;
    cursor: pointer;
}

.add-divergent-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
}

.add-divergent-back {
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    width: 45%;
    padding: 1vh 0;
    color: var(--primary-color);
    font-size: 1.8em;
}

.add-divergent-save {
    border-radius: 5px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    width: 45%;
    padding: 1vh 0;
    color: white;
    font-size: 1.8em;
}

.add-divergent-input {
    margin-left: auto;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: #605F5F;
    padding: 0.9vh 0.5vw;
    width: 18%;
    font-size: 1.2em;
}

.add-divergent-edit {
    font-size: 2em;
    cursor: pointer;
    color: var(--primary-color);
}

.add-divergent-input:focus {
    outline: none;
}
@media only screen and (max-width: 850px) {
    .add-divergent-modal{
        width: 115vw;
    }
    .add-divergent-select-title{
        font-size: 1.5em;
    }
    .add-divergent-text{
        font-size: 1.2em;
    }
    .add-divergent-line{
        padding-bottom: 7vh;
    }
}
</style>