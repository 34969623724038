<template>
    <div id="seller" class="col">
        <div class="list-group">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="user" @submit.prevent="handleSubmit( save )">
                    <div class="row py-3  d-flex justify-content-center">
                        <div class="col">
                            <label>Usuário Externo:</label>
                            <cc-input type="text" v-model="user.varejo_facil_user"></cc-input>
                        </div>
                        <div class="col">
                            <label>Senha:</label>
                            <cc-input type="password" v-model="user.varejo_facil_pass"></cc-input>
                        </div>

                    </div>
                    <div class="row py-3 d-flex justify-content-center">
                        <div class="col">
                            <label>Subdominio:</label>
                            <cc-input type="text" v-model="user.varejo_facil_subdomain"></cc-input>
                        </div>
                    </div>

                </form>

            </ValidationObserver>

        </div>
    </div>
</template>

<script>
import UserService from "@/services/v1/user.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';


export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
		ValidationObserver
    },
    props: [ "user"],
    data() {
        return {

        }
    },
    methods: {

    }
}

</script>

