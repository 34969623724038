<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="products-brands__header-container">
        <span
          @click="this.cancelChanges"
          class="material-icons products-brands__closebtn-icon"
        >
          cancel
        </span>
      </div>
      <div class="modalContent">
        <div class="titleContainer">
          <p>
            {{
              product.pro_descricao ? product.pro_descricao.toUpperCase() : "--"
            }}
          </p>
        </div>
        <div class="infoContainer">
          <div>
            <p>
              <strong>Código: </strong
              >{{ product.pro_ean ? product.pro_ean : "--" }}
            </p>
          </div>
          <div>
            <p>
              <strong>Embalagem: </strong>
              {{
                product.pro_generico
                  ? product.pro_unidade_compra
                  : product.pro_embalagem
              }}
            </p>
          </div>
        </div>
        <div class="inputsContainer">
          <div>
            <label for="">Quantidade</label>
            <input
              min="0"
              type="number"
              ref="quantityInput"
              class="product-amount"
              :class="{
                active: this.product.new_amount && this.product.new_amount > 0,
              }"
              v-model.number="newProduct.new_amount"
              :disabled="onlyStock"
            />
          </div>
          <div>
            <label>Estoque</label>
            <input
              min="0"
              type="number"
              ref="stockInput"
              class="product-amount"
              :class="{
                active: this.product.estoque && this.product.estoque > 0,
              }"
              v-model.number="newProduct.estoque"
            />
          </div>
        </div>
        <div class="switchContainer">
          <CustomCheckboxV2 v-if="quotation.type.id != 'DEFAULT'"
            class="custom-checkbox"
            :label="'Apenas estoque'"
            :checked="allOnlyStock"
            v-model="onlyStock"
            @change="handleSwitchChange"
          />
        </div>
        <div class="buttonsContainer">
          <div class="buttonCancel" @click="cancelChanges">Cancelar</div>
          <div class="buttonConfirm" @click="confirmChanges">Confirmar</div>
          <!-- <div class="buttonCancel">Cancelar</div>
          <div class="buttonConfirm" @click="add_temp_product(product)">
            Confirmar
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as CONSTANTS from "@/constants/constants";
// import ProductService from "@/services/ProductService";
// import ErrorHandlerService from "@/services/ErrorHandlerService";
// import { perm_mixin } from "@/mixins/permission.mixin";
import StandardInputV2 from "@/components/ui/inputs/StandardInputV2.vue";
import CustomCheckboxV2 from "@/components/ui/inputs/CustomCheckboxV2.vue";
export default {
  // mixins: [perm_mixin],

  components: {
    StandardInputV2,
    CustomCheckboxV2,
  },
  props: {
    quotation:{
      type: Object
    },
    allOnlyStock: Boolean,
    product: {
      type: Object,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: false,
    },
    add_temp_product: {
      type: Function,
      required: false,
    },
    allOnlyStockChange: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      newProduct: {
        new_amount: this.product.new_amount? this.product.new_amount : null,
        estoque: this.product.estoque ? this.product.estoque : null,
      },
      originalProduct: {},
      onlyStock: false,
    };
  },
  methods: {
    teste() {
    //   console.log(this.product);
    //   console.log(this.originalProduct);
      // Faça algo com o evento e o produto aqui
    },
    handleSwitchChange() {
      this.allOnlyStockChange(this.onlyStock);
      if (this.onlyStock) {
        this.$refs.stockInput.focus();
      }
    },
    processEvent(event, product) {
      // Faça algo com o evento e o produto aqui
    },
    confirmChanges() {
      if (this.quotation.type.id == 'DEFAULT' && !this.newProduct.new_amount) return
      this.originalProduct = { ...this.product };
      if (this.allOnlyStock) {
        this.originalProduct.new_amount = 0;
        this.originalProduct.estoque = this.newProduct.estoque;
        // this.product.new_amount = 0;
      } else {
        this.originalProduct.new_amount = this.newProduct.new_amount;
        this.originalProduct.estoque = this.newProduct.estoque;
      }

      this.add_temp_product(this.originalProduct);
    },
    cancelChanges() {
      // Reverter para os valores originais
      this.product.new_amount = this.originalProduct.new_amount;
      this.product.estoque = this.originalProduct.estoque;
      //   this.product.estoque =  this.originalProduct.estoque


      this.closeDialog();
    },
    checkOnlyStock() {

      this.onlyStock = this.allOnlyStock;
    },
  },
  mounted() {
    this.originalProduct = { ...this.product };
    this.$refs.quantityInput.focus();
    this.checkOnlyStock();
  },
  // data() {
  //     return {
  //         prod_svc: new ProductService(),
  //         modal: {
  //             title: `Marcas #${this.product.pro_descricao}`,
  //             icon_title: "fa fa-list",
  //             cancel_text: "Voltar",
  //             subtitle: "Acompanhe as marcas disponíveis para seus produtos",
  //             style: {
  //                 width: "65%",
  //             },
  //         },
  //         filters: {
  //             name: "",
  //         },
  //         actionSearch: true,
  //         new_brand: null,
  //         sucess_brand: false,
  //         erro_brand: false,
  //         brands_modal: [],
  //         loading: true,
  //         isLoadingFull: false
  //     };
  // },
  // methods: {
  //     updateProductStatus (data) {
  //       this.isLoadingFull = true
  //       const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
  //         let payload = { pro_id: data.pro_id }
  //         if (data.pro_status == '1') payload.pro_status = '0'
  //         else payload.pro_status = '1'
  //         let url = `${CONSTANTS.API_URL}/atualizarprodutonormalcliente/ativar`
  //         axios.put(url,payload,config)
  //         .then((res)=>{
  //             data.pro_status = res.data.pro_status
  //             this.isLoadingFull = false
  //         })
  //         .catch(()=>{ this.isLoadingFull = false })
  //     },
  //     close() {
  //         if (this.actionSearch) {
  //             this.$emit("close");
  //         } else {
  //             this.actionSearch = !this.actionSearch;
  //         }
  //     },
  //     tooglePreferencialMarcas(brand) {
  //         brand.pro_preferencial = brand.pro_preferencial === 0 ? 1 : 0;
  //         const data = {
  //             idProduto: brand.pro_id,
  //             preferencial: brand.pro_preferencial,
  //         };
  //         this.prod_svc.pin_brand(data);
  //     },
  //     toogleAtivoMarcas(index) {
  //         this.brands[index].pro_status = +!this.brands[index].pro_status;

  //         const config = {
  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //             params: {
  //                 produto_id: this.brands[index].pro_id,
  //                 status: this.brands[index].pro_status,
  //             },
  //         };
  //         let url = `${CONSTANTS.API_URL}/inativarativarprodutocliente`;
  //         axios.post(url, {}, config).then(() => {});
  //     },
  //     save() {
  //         const data = {
  //             produto: this.product.pro_id,
  //             marca: this.new_brand,
  //         };

  //         this.prod_svc
  //             .save_brand(data)
  //             .then((data) => {
  //                 this.new_brand = null;
  //                 this.loadBrands();
  //                 this.actionSearch = true
  //                 //this.showMessageSucess();
  //                 this.$swal.fire(
  //                   'Sucesso!',
  //                   'Nova marca adicionada com sucesso.',
  //                   'success'
  //                 );
  //             })
  //             .catch((error) => {
  //                 this.showMessageErro();
  //             });
  //     },
  //     loadBrands() {
  //         this.prod_svc
  //             .get_product_brands(this.product.pro_id)
  //             .then((data) => {
  //                 this.brands_modal = data.data.produtos;
  //             })
  //             .catch((error) => {
  //                 ErrorHandlerService.handle(error, this.$store);
  //             });
  //     },
  //     showMessageSucess() {
  //         const notification = {
  //             type: "sucess",
  //             message: "Marca Cadastrada com sucesso!",
  //         };
  //         this.$store.dispatch("notification/add", notification);
  //     },
  //     showMessageErro() {
  //         const notification = {
  //             type: "error",
  //             message: "Não foi possível salvar a marca!",
  //         };
  //         this.$store.dispatch("notification/add", notification);
  //     },
  //     getSpecialBackground(idx) {
  //       if (idx % 2 != 0) return 'page-table-line-special'
  //     },
  // },
  // mounted() {
  //   document.documentElement.style.overflow = 'hidden'
  //   this.brands_modal = this.brands;
  //   this.loading = false
  // },
  // destroyed() { document.documentElement.style.overflow = 'auto' },
  // computed: {
  //     filtred_brands() {
  //         return this.brands_modal.filter((marca) => {
  //             return marca.pro_observacao
  //                 .toLowerCase()
  //                 .includes(this.filters.name.toLowerCase());
  //         });
  //     },
  // },
};
</script>

<style lang="scss" scoped>
/* @import "./product-brands.modal"; */
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.products-brands__header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.products-brands__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.modal-content-container {
  background-color: white;
  width: 90%;
  border-radius: 12px;
  padding-bottom: 3vh;
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 20px 10px;
}
.titleContainer {
  font-size: 1.3rem;
  color: var(--primary-color);
}
.infoContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  p {
    font-size: 1rem;
    color: #605f5f;
    strong {
      font-size: 1.1rem;
      color: black;
    }
  }
}
.inputsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  label {
    font-size: 1rem;
    color: #605f5f;
  }
  input {
    border: none;
    outline: none;

    border-radius: 6px;
    padding: 5px 10px;
    text-align: center;
    border: 1px solid;
    border-color: #c7c7c7;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    height: 54px;
    min-width: 130px;
    &:focus {
      border-color: var(--primary-color);
    }
  }
}
.product-amount {
  width: 15vw !important;
}
.switchContainer {
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 85%;
  div {
    cursor: pointer;
    font-size: 1.1rem;
    &:active {
      transform: translateY(1px);
      transition: transform 150ms;
    }
  }
}
.buttonCancel {
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  color: #605f5f;
}
.buttonConfirm {
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #ffffff;

  width: 80%;
}

@media only screen and (max-width: 750px) {
}
</style>
