import http from "../http";
import Service from "../../Service";

/**
 * @type SysConfService
 */
export default class SysConfService extends Service {

	load_campaign_state_banners() {
		return http.get(`clients/management/system/campaigns`, {
            headers: this.getHeaders()
        });
	}

}
