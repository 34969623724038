<style lang="scss">
@import "@/sass/commons/_variables";
.hml-warning {
  background-color: red;
  color: white;
  font-size: 1.5rem;
  word-wrap: break-word;
  font-family: monospace;
  text-align: center;
  margin-top: -5vh;
}

#app > .header {
  font-family: $font-default;
  & > div {
    display: flex;
    align-items: center;
  }
  z-index: 3;
}
.primary-header {
  background-color: white;
  height: 4rem;
  font-size: 0.9rem;
  color: $primary-color;
  font-weight: 500;
}

.secondary-header {
  background-color: $ternary-color;
  height: 2.3rem;

  .menus {
    display: flex;
    justify-content: space-evenly;
    width: 60%;

    .hvr-underline-from-center:before {
      background: white;
    }

    .menu {
      display: inline-block;
      &.active {
        box-shadow: inset 0px -6px 0px 0px white;
      }
      a {
        display: inline-block;
        padding: 0.5rem;
        color: white;
        font-size: 0.9rem;
        height: 2.3rem;
        font-weight: 500;
      }
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}
.menuDropDown {
  z-index: 1;
  position: absolute !important;
  margin-top: -8px;
  background-color: $ternary-color;
  margin-left: 56px;
  width: 50%;
  span:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.menuLink {
  border: 1px solid white !important;
}
.header-upper-containear {
  background: var(--primary-color);
  height: 11vh;
  display: flex;
  align-items: center;
}
.header-upper-logo {
  flex: 1;
  margin-left: 50px;
}
.header-upper-icon {
  color: white;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon:hover {
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta:hover {
  color: var(--primary-color) !important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta {
  background: white;
  color: var(--primary-color);
  border-radius: 50%;
  padding: 4px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-suport-containear {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 22px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon-whats {
  color: white;
  background: orangered;
  padding: 5px;
  border-radius: 50%;
}
.header-upper-icon-whats-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-color);
  margin-left: 7px;
  margin-right: 10px;
}
.header-upper-icon-divider {
  border-right: 1px solid white;
  margin-right: 15px;
}
.header-upper-user-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-right: 20px;
}
.header-lower-containear {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  color: #605f5f;
  padding-top: 7px;
  padding-bottom: 9px;
  margin-bottom: 11px;
  box-shadow: 0px 5px 8px 0px lightgrey;
  min-height: 50px;
  background-color: white;
}
.header-lower-texts {
  margin-right: 5vw;
  cursor: pointer;
  color: #605f5f;
  font-size: 16px;
}
.header-lower-texts:hover {
  color: #605f5f !important;
}
.header-lower-texts-activate {
  border-bottom: 3px solid var(--secondary-color);
  padding-bottom: 5.5px;
}
.header-contact-list {
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 10;
}
.header-contact-list-row {
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 2px 4px;
  margin-bottom: 10px;
}
.header-contact-list-row-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: white;
}
.mobile-header {
  display: none;
}
.mobile-header-logo-containear {
  flex: 1;
  display: flex;
  justify-content: center;
}
.mobile-side-menu-containear {
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: white;
  min-width: 100%;
  min-height: 100vh;
}
.account-icon {
  font-size: 35px;
  color: white;
  margin-right: 15px;
}
.header-upper-icons {
  margin-right: 12px;
}
.mobile-header-img-logo {
  width: 130px;
}
.tagnew-icon {
  position: absolute;
  background-color: #30aa4c;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 0.5vh;
  border-radius: 5px;
  margin-top: -20px;
  margin-left: 16vw;
}
.desktop-header {
  position: fixed;
  width: 100%;
  z-index: 4;
  top: 0;
}
@media only screen and (max-width: 630px) {
  .desktop-header {
    display: none;
  }
  .env-row {
    margin-top: 4vh;
  }
  .mobile-header {
    background: var(--primary-color);
    height: 8vh;
    display: flex;
    align-items: center;
    z-index: 10;
    padding: 35px 0;
    position: fixed;
    width: 100%;
    z-index: 4;
    top: 0;
  }
}
.mobile-header-menu-icon {
  color: white;
  margin-left: 15px;
  cursor: pointer;
}

.contact {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  a {
    display: inline-block;
  }
  i {
    font-size: 1.7rem;
    margin-left: 14px;
    cursor: pointer;
  }
  .support {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border: 1px solid #4ac959;
    color: #4ac959;
    border-radius: 5px;
    i {
      margin-right: 4px;
    }
    .support-contacts {
      transform: translateX(160%);
      position: fixed;
      top: 40px;
      right: 142px;
      list-style: none;
      li {
        padding: 6px;
        padding-right: 14px;
        border: 1px solid #4ac959;
        border-radius: 6px;
        background-color: white;
        margin-bottom: 6px;
        &:hover {
          color: white;
          border: 1px solid $primary-color;
          background-color: $primary-color;
          i,
          a {
            color: white;
          }
        }
        i {
          font-size: 1rem;
        }
        a {
          color: #4ac959;
        }
      }
    }
  }
  .support-remote {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    i {
      margin-right: 4px;
    }
  }
}
</style>
<template>
  <div>
    <div class="desktop-header">
      <div class="header-upper-containear not-print">
        <div class="header-upper-logo">
          <img src="@/assets/Logo-BRANCA.svg" />
        </div>
        <a
          href="https://www.facebook.com/clubdacotacao"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Facebook_black.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/clubdacotacao/"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Instagram_black.svg" alt="" />
        </a>
        <a
          href="mailto:contato@clubdacotacao.com.br"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Mail_black.svg" alt="" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Youtube_black.svg" alt="" />
        </a>
      </div>
    </div>
    <div class="mobile-header">
      <div class="mobile-header-logo-containear">
        <div>
          <img src="@/assets/Logo-BRANCA.svg" class="mobile-header-img-logo" />
        </div>
      </div>
    </div>
    <div v-if="ENV != 'prod'" class="hml-warning">
      Você está em ambiente [ {{ ENV }} ] @ [ {{ API_URL }} ]
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import Breadcrumb from "@/components/shared/Breadcrumb";
import SessionService from "@/services/TokenService";
import { mapState, mapGetters } from "vuex";
import { gsap } from "gsap";

export default {
  data() {
    return {
      user_name: null,
      ENV: CONSTANTS.PROJECT_ENV,
      API_URL: CONSTANTS.API_URL,
      session: new SessionService(),
      isHidden: true,
      tab: "",
      present_numbers: false,
    };
  },
  methods: {
    openPage(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    present_numbers(current) {
      if (current) {
        gsap.fromTo(
          "#contacts",
          { x: "100%" },
          { x: "50%", duration: 1, ease: "back.out(1.7)" }
        );
      } else {
        gsap.fromTo(
          "#contacts",
          { x: "0%" },
          { x: "170%", duration: 1, ease: "back.out(1.7)" }
        );
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["version"]),
  },
};
</script>

