import http from "./http";
import Service from "../Service";

/**
 * @type OrderService
 */
export default class OrderService extends Service {

	notify_legacy(id) {
		return http.get(`orders/${id}/notify`, {
			headers: this.getHeaders()
		});
	}

}
