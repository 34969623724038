<template>
  <div style="padding-right: 5vw;">
    <div class="products-page__container">
      <div>
        <p class="food-service-input-text">Produtos mais requisitados</p>
        <div class="food-service-op-container">
          <img v-on:click="requestSize = op.value" :class="getOpClass(op)" class="food-service-op-icon" :src="op.img" v-for="op in requestList" :key="op.value">
        </div>
      </div>
      <div class="products-page__right-wrapper">
        <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'"
          :action="clearFilters" />
      </div>
    </div>

    <div class="products-page__container">
      <!-- <div class="page-filters__right-wrapper">
        <div class="page-filters__right-title-wrapper">
          <div class="products-page__datetime-title">Período da requisição</div>
        </div>
        <div class="page-filters__right-select-wrapper">
           <datetime class="datetime-input form-control" v-model="selectDate" ref="datepicker" auto type="date">
          </datetime>
          <span class="material-icons date-icon">
            event
          </span>
        </div>
      </div> -->
      <StandardInput title="Período da requisição" :action="value => selectDate = value" :list="dates"
        class="products-page__selects" :type="'select'" />
      <StandardInput :placeholder="'Digite o produto'" :title="'Produto'" :action="setProductValue"
        class="products-page__selects" :type="'text'" />
      <StandardInput :title="'Estado'" :action="setStateValue" :placeholder="selectState ? selectState.est_nome : ''"
        class="products-page__selects" :list="states" :type="'select'" />
    </div>

    <div class="products-page-categories-title">Categorias</div>

    <div class="mobile-only-arrows">
      <span role="button" v-on:click="moveCategoryDow()"
        class="material-icons-outlined product-page-categories-arrows">arrow_back_ios</span>
      <span role="button" v-on:click="moveCategoryUp()"
        class="material-icons-outlined product-page-categories-arrows">arrow_forward_ios</span>
    </div>

    <div class="product-page-categorie-list">
      <span role="button" v-on:click="moveCategoryDow()"
        class="material-icons-outlined desktop-only product-page-categories-arrows">arrow_back_ios</span>
      <img class="product-page-categorie-card" :class="getActiveCategorie(categorie.id)"
        @click="setCategoryValue(categorie.id)" v-for="(categorie, idx) in categoriesIcons" :key="idx"
        :src="categorie.image">
      <span role="button" v-on:click="moveCategoryUp()"
        class="material-icons-outlined desktop-only product-page-categories-arrows">arrow_forward_ios</span>
    </div>

    <div v-show="!isLoading" class="product-page-products-card-container">
      <div class="product-page-products-card" v-for="(product, idx) in products" :key="idx">
        <div class="product-page-products-card-img-container">
          <img v-if="product.pro_imagem" class="product-page-products-card-img" :src="product.pro_imagem" alt="">
          <img v-else class="product-page-products-card-img" src="@/assets/newLogo.jpeg" alt="">
        </div>
        <div class="product-page-products-card-title" :title="product.name">{{ product.name }}</div>
        <div class="product-page-products-card-info" style="display: flex;">
          <div> Cod: {{ product.ean }}</div>
          <div style="margin-left: 20%;"> Id: {{ product.id }}</div>
        </div>
        <div class="product-page-products-card-info">
          <img src="@/assets/package.svg" style="margin-right: 5%; width: 1.2em;" alt="">
          {{ product.is_generic ? product.purchase_unit : product.packing }}
        </div>
        <div class="product-page-quantity-container">
          Qtd. Requisição:
          <div style="background: rgba(255, 113, 16, 0.15); border-radius: 8px; padding: 3px 1vw;">{{
            product.emUso >= 1 ? product.emUso : '--' }}</div>
        </div>
        <div class="product-page-buttons-container">
          <div class="product-page-buttons-brand product-page-button" @click="open_brands(product, 'brand')">
            Marcas</div>
          <div class="product-page-buttons-price product-page-button" @click="open_brands(product, 'price')">
            Preços</div>
        </div>
        <div class="product-page-buttons-supliers" @click="open_brands(product, 'supliers')">Fornecedores</div>
      </div>
    </div>

    <tr style="display: flex !important; padding-bottom: 5vh;" v-show="total_items > items_by_page_value">
      <td style="margin: 0 auto;" colspan="5">
        <Paginacao classes="orange justify-end" :items_by_page_value="items_by_page_value" :total="total_items"
          :page="page" v-on:change="list($event)">
        </Paginacao>
      </td>
    </tr>


    <ProductsDetails v-if="is_brands_modal_open && current_product && showProductBrandsModal"
      :closeDialog="closeProductBrandsModal" :product="current_product" :product_pattern="true" :initTab="brandModalTab"
      @close="is_brands_modal_open = false; current_product = null" :states="states" :cities="cities"/>
    <cc-loader-full v-show="isLoading" />
  </div>
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import ManageCategoriesModal from "@/modals/client/categories/manage-categories.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import Button from '@/components/ui/buttons/Standard-Button.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import ProductsDetails from '@/modals/product-brands/ProductsDetails'
import foodService from "../../services/v3/foodService";
import UserService from "@/services/v1/user.service";
import { mapState } from 'vuex'



export default {
  name: 'foodServiceTab',
  mixins: [loaderMixin, typingRemoteRequestControlMixin],
  components: {
    Paginacao,
    ProductsDetails,
    'cc-client-categories': ManageCategoriesModal,
    Button,
    StandardInput,
  },
  data() {
    return {
      user_svc: new UserService(),
      brandModalTab: undefined,
      productFilteredInUse: null,
      showProductBrandsModal: false,
      states: [{ value: {}, text: 'Todos' }],
      cities: [],
      selectState: undefined,
      selectDate: 0,
      loading: false,
      isLoading: false,
      service: new foodService(),
      page: 1,
      products: [],
      filters: {
        category: { id: null }
      },
      items_by_page_value: 30,
      total_items: 0,
      brands: null,
      current_product: null,
      is_brands_modal_open: false,
      productIsMostRequested: false,
      productFilteredQuantity: null,
      productFilteredMostRequested: null,
      categoriesIcons: [],
      categoriesIconsList: [
        { image: require('@/assets/alimenticioImage.svg'), id: 1 },
        { image: require('@/assets/friosImage.svg'), id: 2 },
        { image: require('@/assets/proteinasImage.svg'), id: 3 },
        { image: require('@/assets/bebidasImage.svg'), id: 4 },
        { image: require('@/assets/perfumesImage.svg'), id: 5 },
        { image: require('@/assets/limpezaImage.svg'), id: 6 },
        { image: require('@/assets/bazarImage.svg'), id: 7 },
        { image: require('@/assets/descartaveisImage.svg'), id: 8 },
        { image: require('@/assets/epiImage.svg'), id: 9 },
        { image: require('@/assets/matImage.svg'), id: 10 },
        { image: require('@/assets/hortiImage.svg'), id: 11 },
        { image: require('@/assets/petImage.svg'), id: 12 },
        { image: require('@/assets/cotImage.svg'), id: 13 },
      ],
      categoriesIconsNumbers: [0, 1, 2, 3, 4, 5],
      requestSize: undefined,
      requestList: [
        {value: undefined, img: require('@/assets/Icone__todas_.svg'), class: 'food-service-op-all', activeClass: 'food-service-op-all-active'},
        {value: 100, img: require('@/assets/Icone_100.svg'), class: 'food-service-op-100', activeClass: 'food-service-op-100-active' },
        {value: 200, img: require('@/assets/Icone_200.svg'), class: 'food-service-op-200', activeClass: 'food-service-op-200-active' },
        {value: 300, img: require('@/assets/Icone_300.svg'), class: 'food-service-op-300', activeClass: 'food-service-op-300-active' },
      ],
      dates: [
        {text: 'Ultimos 30 Dias', value: 30},
        {text: 'Ultimos 60 Dias', value: 60},
        {text: 'Ultimos 90 Dias', value: 90},
        {text: 'Ultimos 180 Dias', value: 180},
      ]
    }
  },
  created() {
    this.isLoading = true
    this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    this.list_address()
  },
  watch: {
    selectDate() {
      if (this.selectDate) this.list(this.page)
    },
    requestSize(){
      this.list(this.page)
    }
  },
  computed:{
    ...mapState([ 'user' ]),
  },
  methods: {
    getOpClass(op) {
      if (op.value == this.requestSize) return op.activeClass
      return op.class
    },
    moveCategoryUp() {
      if (this.categoriesIconsNumbers.at(-1) == this.categoriesIconsList.length - 1) return
      for (let index = 0; index < this.categoriesIconsNumbers.length; index++) this.categoriesIconsNumbers[index]++
      this.categoriesIcons = []
      this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    },
    moveCategoryDow() {
      if (this.categoriesIconsNumbers[0] == 0) return
      for (let index = 0; index < this.categoriesIconsNumbers.length; index++) this.categoriesIconsNumbers[index]--
      this.categoriesIcons = []
      this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    },
    getActiveCategorie(category) {
      if (this.filters && this.filters.category && category == this.filters.category.id) return 'product-page-categorie-card-active'
    },
    setStateValue(value) {
      this.selectState = value
      this.list(this.page)
    },
    setProductValue(value) {
      this.filters.name = value
      this.list(this.page)
    },
    setCategoryValue(value) {
      if (this.filters.category.id == value) value = null
      this.filters.category = { id: value }
      this.list(this.page)
    },
    closeProductBrandsModal: function () {
      this.showProductBrandsModal = false;
    },
    clearFilters() {
      this.filters = {}
      this.list(this.page)
    },
    getMostRequestedProductsList() {
      this.productIsMostRequested = true
      this.productFilteredQuantity = 'emUso'

      setTimeout(() => {
        this.list()
      }, 400);
    },
    removeMostRequestedProductsList() {
      this.productIsMostRequested = false
      this.productFilteredQuantity = null

      setTimeout(() => {
        this.list()
      }, 400);
    },
    list(page = this.page) {
      this.isLoading = true
      this.cancelSearch(() => { this.loading = true });
      if (this.selectDate) {
        var day = new Date();
        day.setDate(day.getDate()-this.selectDate);
      } else var day = undefined

      let params = {
        fu: this.selectState ? this.selectState.est_sigla : '',
        start_at: day,
        page: page,
        filter: this.productFilteredInUse,
        sort_by: this.productFilteredQuantity,
      };
      if (this.requestSize) {
        params.sort_order = 'desc'
        params.limit = this.requestSize
      }
      if (this.filters && this.filters.category) params.category_id = this.filters.category.id
      if (this.filters && this.filters.name) params.name = this.filters.name
      this.service.list(params, this.cancelSource.token).then(response => response.data).then(data => {
        this.isLoading = false
        if (data.data) {
          this.products = data.data
          this.total_items = data.meta.total
        }
        else {
          this.products = data
          this.total_items = 0
        }

      }).catch(error => {
        ErrorHandlerService.handle(error, this.$store);
      });
    },
    list_address() {
      this.user_svc.region_data(true).then(response => response.data).then(data => {
        data.estados.forEach(state => {
          this.states.push({ value: state, text: state.est_sigla })
          if (state.est_id == this.user.stores[0].state.id) this.selectState = state
        })
        this.list(1)
      })
    },
    open_brands(product, tab) {
      // this.is_brands_modal_open = true
      // this.current_product = product
      // this.brandModalTab = tab
      // this.showProductBrandsModal = true;
      this.$router.push('/produtos/marcas')
      this.$store.commit('setProductBrands', {
        product: product,
        tab: tab,
        states: this.states,
        cities: this.cities
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './products.page';


.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}

.products-page__datetime-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}

.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 4px 0;
}

.date-icon {
  color: var(--primary-color);
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}

.product-page-categorie-card-active {
  outline: 0.3vw solid lightsalmon;
}

.products-page__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em 0em;
}


.products-page-categories-title {
  color: #808080;
  font-size: 1.7em;
}

.product-page-categorie-card {
  border-radius: 12px;
  width: 13vw;
  cursor: pointer;
}

.page-filters__right-wrapper {
  width: 28%;
}

.product-page-categorie-card:hover {
  outline: 0.3vw solid lightsalmon;
}


.product-page-categorie-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.product-page-quantity-container {
  display: flex;
  gap: 1vw;
  margin-top: 0.5vh;
  align-items: flex-end;
  color: var(--primary-color);
  font-size: 1.1em;
}
.food-service-input-text{
  font-weight: 400;
  font-size: 2em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
}

.product-page-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}

.product-page-button {
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 1.2em;
  text-align: center;
  width: 45%;
  cursor: pointer;
}

.product-page-buttons-brand {
  background: var(--primary-color);
}

.product-page-buttons-brand:hover {
  color: var(--primary-color);
  background: #FFEADB;
}

.product-page-buttons-price {
  background: #278E3F;
}

.product-page-buttons-price:hover {
  background: rgba(42, 205, 114, 0.1);
  color: #30AA4C;
}

.product-page-buttons-supliers {
  background: #17A2B8;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 1.2em;
  text-align: center;
  margin-top: 1.5vh;
  cursor: pointer;
}

.product-page-buttons-supliers:hover {
  color: #17A2B8;
  background: rgba(23, 162, 184, 0.1);
}

.product-page-categories-arrows {
  font-size: 2em;
  color: var(--primary-color);
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product-page-products-card-no-img{
  color: lightgray;
  font-size: 5em;
}

.mobile-only-arrows {
  display: none;
}

.products-page__right-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 2vw;
}

.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.products-page__checkbox-wrapper {
  display: flex;
}

/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}
.food-service-op-container{
  display: flex;
  gap: 9vw;
}
.food-service-op-icon{
  width: 8vw;
  border-radius: 50%;
  cursor: pointer;
}
.food-service-op-all:hover{
  outline: var(--primary-color) 3px solid;
}
.food-service-op-100:hover{
  outline: lightblue 3px solid;
}
.food-service-op-200:hover{
  outline: lightgreen 3px solid;
}
.food-service-op-300:hover{
  outline: rgb(97, 97, 250) 3px solid;
}
.food-service-op-all-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-100-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-200-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-300-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid var(--primary-color);
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked~.checkmark {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.products-page__selects {
  width: 28%;
}



.product-page-products-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 5vh;
}

.product-page-products-card {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  margin-bottom: 3vh;
  padding: 2vh 2vw;
}

.product-page-products-card-img-container {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page-products-card-img {
  max-height: 100%;
  max-width: 100%;
}

.product-page-products-card-title {
  display: -webkit-box;
  width: 16vw;
  margin-top: 2vh;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  color: #605F5F;
}

.product-page-products-card-info {
  display: flex;
  color: #6A6D71;
  font-size: 1.1em;
}


@media only screen and (max-width: 850px) {
  .mobile-only-arrows {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 5vh 0;
  }

  .desktop-only {
    display: none;
  }


  .products-page__container {
    flex-direction: column;
    gap: 3vh;
  }

  .products-page__right-wrapper {
    justify-content: space-between;
    gap: 0;
  }

  .action-buttons {
    width: 100%;
  }

  .products-page__selects {
    width: 100%;
  }

  .page-filters__right-wrapper {
    width: 100%;
  }

  .product-page-products-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-page-categorie-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .product-page-categorie-card {
    width: 90%;
    margin-bottom: 2vh;
  }

  .product-page-products-card-title {
    width: 25vw;
  }
  .food-service-op-icon{
    width: 15vw;
  }
}

@media only screen and (max-width: 450px) {

  .product-page-products-card-container {
    grid-template-columns: 1fr 1fr;
  }

  .product-page-categorie-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-page-products-card-title {
    width: 45vw;
  }
  .food-service-op-icon{
    width: 20vw;
  }
}</style>
