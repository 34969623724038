import http from "../http";
import Service from "../../Service";

/**
 * @type ProductService
 */
export default class ProductService extends Service {

	products(id, params) {
		return http.get(`/v2/clients/requests/${id}/products`, {
            headers: this.getHeaders(),
            params
        });
	}

}
