import StorageService from "@/services/local-storage.service";
import AuthService from '@/services/AuthService'
import ProfileService from '@/services/v2/profile.service'
import StoreService from "@/services/v3/stores/store.service"

export default {
    namespaced: true,
    state: {
        user_name: null,
        token: null,
        request_type: null,
        settings: null,
        stores: null,
        attendance: null,
        perms: []
    },
    actions: {
        reload_user({ commit }) {
            return new AuthService()
                .get_user()
                .then(response => response.data)
                .then(data => {
                    commit("SET_ATTENDANCE", data.usuario.attendance);
                    commit("SET_SETTINGS", data.usuario.settings);
                });
        },
        load_perms({ commit }) {
            let perms = StorageService.get("perms");
            if (perms) {
                commit("SET_PERMISSIONS", perms);
            } else {
                return new ProfileService().my().then(({ data }) => {
                    commit("SET_PERMISSIONS", data);
                    StorageService.set("perms", data);
                });
            }
        },
        load_user({ commit, dispatch }) {
            commit("SET_USER_NAME", StorageService.get_string("user_name"));
            commit("SET_TOKEN", StorageService.get_string("token"));
            commit("SET_SETTINGS", StorageService.get("user_settings"));
            commit("SET_GROUP_NAME", StorageService.get_string("grupo"));
            commit("SET_CLI_ID", StorageService.get("cli_id"));
            commit("SET_USER_ID", StorageService.get("usu_id"));
            commit("SET_CLI_REQUEST_TYPE", StorageService.get("request_type"));
            commit("SET_PERMISSIONS", StorageService.get("perms"));
            commit("SET_ATTENDANCE", StorageService.get("attendance"));
            commit("SET_STORE_ID", StorageService.get("store_id"));
            commit("SET_CLOSING_METHOD", StorageService.get_string("closing"));
            dispatch('fetch_stores')
        },
        logout({ commit }) {
            commit("SET_USER_NAME", null);
            commit("SET_TOKEN", null);
            commit("SET_PERMISSIONS", null);
            commit("SET_ATTENDANCE", null);
            commit("SET_STORE_ID", null);
            commit("SET_USER_ID", null);
            localStorage.removeItem("attendance");
            localStorage.removeItem("tcp_id");
            localStorage.removeItem("cli_id");
            localStorage.removeItem("store_id");
            localStorage.removeItem("seen_warnings_request_ids");
            localStorage.removeItem("stores");
            localStorage.removeItem("has_vf_integration");
            localStorage.removeItem("user_name");
            localStorage.removeItem("user_settings");
            localStorage.removeItem("token");
            localStorage.removeItem("perms");
        },
        async fetch_stores({ commit, state }) {
            if (!state.stores) {
                let response = await new StoreService().load()
                let stores = response.data;
                commit("SET_STORES", stores);
                return stores;
            }
            return state.stores;
        }
    },
    mutations: {
        SET_USER_NAME(state, user_name) {
            state.user_name = user_name;
        },
        SET_GROUP_NAME(state, group) {
            state.group = group;
        },
        SET_ATTENDANCE(state, attendance) {
            state.attendance = attendance;
        },
        SET_CLOSING_METHOD(state, method) {
            state.method = method;
        },
        SET_STORES(state, stores) {
            state.stores = stores;
        },
        SET_STORE_ID(state, id) {
            state.store_id = id;
        },
        SET_SETTINGS(state, settings) {
            state.settings = settings;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_CLI_REQUEST_TYPE(state, type) {
            state.request_type = type;
        },
        SET_CLI_ID(state, cli_id) {
            state.cli_id = cli_id;
        },
        SET_USER_ID(state, usu_id) {
            state.user_id = usu_id;
        },
        SET_PERMISSIONS(state, perms) {
            state.perms = perms;
        }
    },
    getters: {
        logged: state => {
            return !!state.token;
        },
        setting: state => key => {
            if (!state.settings) return null;
            return state.settings.find(s => s.key == key);
        },
        stores: state => {
            return state.stores;
        },
        perms: state => {
            return state.perms;
        },
        attendance: state => {
            return state.attendance;
        }
    }
};
