<template>
  <div>
    <div class="input-title"> {{title}} </div>
    <div v-if="type == 'text'" class="input-containear">
      <input :type="getPassword()" :style="getWarning()" v-mask="mask" v-on:keyup.enter="returnValue()" v-model="inputValue" class="input-value" :placeholder="placeholder">
      <span v-if="iconName" v-on:click="returnValue()" class="material-icons input-icon">{{iconName}}</span>
    </div>
    <div v-if="type == 'select'" :style="getWarning()" class="input-containear">
      <select  v-model="selectValue" class="input-select-value">
        <option v-if="placeholder" value="" disabled selected> {{placeholder}} </option>
        <option v-for="(x, idx) in list" :value="x.value" :key="idx"> {{x.text}} </option>
      </select>
    </div>
    <div v-if="type == 'disabled'" class="input-containear">
      <select style="cursor: not-allowed;" disabled v-model="selectValue" class="input-select-value">
        <option v-if="placeholder" value="" disabled selected> {{placeholder}} </option>
        <option v-for="(x, idx) in list" :value="x.value" :key="idx"> {{x.text}} </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardInput',
  props: ['title', 'type', 'list', 'value', 'action', 'placeholder', 'iconName', 'auto', 'password', 'mask', 'warning', ],
  data () {
    return {
      inputValue: '',
      selectValue: ''
    }
  },
  methods: {
    returnValue(){ this.action(this.inputValue) },
    getWarning() { if (this.warning) return 'border: 1px solid lightcoral'},
    getPassword() { if (this.password) return 'password'}
  },
  watch: {
    selectValue(newValue){ this.action(newValue) },
    inputValue(newValue){ if (this.auto) this.action(newValue) },
    value(newValue) { 
      if (!newValue) {
        this.inputValue = undefined
        this.selectValue = undefined
      }
      if (this.type == 'text' && newValue) this.inputValue = newValue
    }
  },
  mounted(){
    if (this.type == 'text' && this.value) this.inputValue = this.value
  }
}
</script>

<style scoped>
.input-title{
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.5vh 0.7vw;
  margin-top: 5px;
  display: flex;
  height: 7vh;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  width: 100%;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #6e6e6e;
  cursor: pointer;
  font-size: 1.8em;
}
.input-select-value{
  border: none;
  font-weight: 400;
  font-size: 1.3em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  flex: 1;
  width: 5%;
}
.input-select-value:focus{
  border: none;
  outline: none;
}
</style>