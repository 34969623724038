<template>
    <div :class="getClassContainer()" v-on:click="callAction">
        <span :class="getClassIcon()" class="material-icons">{{
            iconName
        }}</span>
        <div :class="getClasstext()" class="">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: "StandardButton",
    props: ["text", "iconName", "action", "disabled", "active"],
    methods: {
        callAction() {
            if (this.disabled) return;
            this.action();
        },
        getClassContainer() {
            if (this.disabled) return "button-containear-disabled";
            else return `button-containear-comp ${this.active ? "active" : ""}`;
        },
        getClassIcon() {
            if (this.disabled) return "button-icon-disabled";
            else return "button-icon";
        },
        getClasstext() {
            if (this.disabled) return "button-text-disabled";
            else return "button-text";
        },
    },
};
</script>

<style scoped>
.button-containear-comp {
    background-color: white;
    border: 0.5px solid #e5e5e5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
}
.button-containear-disabled {
    background-color: white;
    border: 0.5px solid #e5e5e5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
}
.button-text {
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
}
.button-text-disabled {
    font-weight: 600;
    font-size: 1em;
    color: #e5e5e5;
}
.button-icon {
    color: var(--primary-color);
    margin-right: 10px;
}
.button-icon-disabled {
    color: #e5e5e5;
    margin-right: 10px;
}
@media only screen and (min-width: 800px) {
    .button-containear-comp:hover {
        background-color: var(--primary-color);
    }
    .button-containear-comp:hover > .button-text {
        color: white;
    }
    .button-containear-comp:hover > .button-icon {
        color: white;
    }
    .active {
        &.button-containear-comp {
            background-color: var(--primary-color);
        }
        &.button-containear-comp > .button-text {
            color: white;
        }
        &.button-containear-comp > .button-icon {
            color: white;
        }
    }
}
</style>
