import http from "./http";
import Service from "../Service";
/**
 * @type ProviderService
 */
export default class RequestService extends Service {
    load_obs(request_id, store_id) {
        return http.get(`clients/requests/obs`, {
            headers: this.getHeaders(),
            params: {
                store_id,
                request_id,
            },
        });
    }

    send_temps(data) {
		return http.post("clients/requests/temporaries", data, {
			headers: this.getHeaders(),
            timeout: 90000
		});
	}

    save_obs(data) {
        return http.post(`clients/requests/obs`, data, {
            headers: this.getHeaders(),
        });
    }

    update_resupply_title(id, title) {
        return http.patch(`clients/requests/${id}/title`, { title }, {
            headers: this.getHeaders(),
        });
    }

    extract_resupply(id, data) {
        return http.post(`clients/requests/${id}/extract`, data, {
            headers: this.getHeaders(),
        });
    }

    request_offers(id, seller_id) {
        return http.get(`/clients/requests/${id}/sellers/${seller_id}/offers`, {
            headers: this.getHeaders(),
        });
    }

    request_offers_by_product(id, product_id) {
        return http.get(
            `/clients/requests/${id}/products/${product_id}/offers`,
            {
                headers: this.getHeaders(),
            }
        );
    }

    general_request_report(id) {
        return http.get(`/clients/reports/requests/${id}/products`, {
            headers: this.getHeaders(),
        });
    }

    full_general_request_report(id) {
        return http.get(`/clients/reports/requests/${id}/products/all`, {
            headers: this.getHeaders(),
        });
    }

    export_store_report(id, store_id, params) {
        return http.get(
            `/clients/reports/excel/requests/${id}/store/${store_id}`,
            {
                params,
                headers: this.getHeaders(),
                responseType: "arraybuffer",
            }
        );
    }
    uploadExcel (id, store_id, file) {
        var formData = new FormData();
        formData.append("file", file);
        return http.post( `clients/read/file/response/cot/${id}/loja/${store_id}`, formData, {headers: this.getHeaders(), 'Content-Type': 'multipart/form-data;', responseType: 'blob'})
    }

    sync(id) {
        return http.get(`/v2/clients/requests/${id}/sync`, {
            headers: this.getHeaders(),
            timeout: 40000
        });
    }

    load(id) {
        return http.get(`/v2/clients/requests/${id}`, {
            headers: this.getHeaders(),
        });
    }

    reset(id) {
        return http.put(
            `/v2/clients/requests/${id}/reset`,
            {},
            {
                headers: this.getHeaders(),
            }
        );
    }

    toggle(data) {
        return http.post(`/v2/clients/requests/pre-orders/toggle`, data, {
            headers: this.getHeaders(),
        });
    }
    resyncOrder(id){
        return http.delete(`clients/external/data/${id}/integration`, {
            headers: this.getHeaders(),
        });
    }
    ExportOrder(id){
        return http.get(`clients/export/csv/cotacao/${id}`, {
            headers: this.getHeaders(),
        });
    }
}
