<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="page-route-select">
                <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                    Home
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <div class="page-route-select-text">
                    Produtos
                </div>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <div class="page-route-select-text">
                    {{ getPageName() }}
                </div>
            </div>
            <div class="page-title">
                {{ getPageName() }}
            </div>
            <div class="new-product__header-container">
                <div style="display: flex; align-items: center; gap: 3vw;">
                    <img v-if="product.pro_imagem" class="product-details-img" :src="product.pro_imagem">
                    <img v-else class="product-details-img product-details-no-product" src="@/assets/newLogo.jpeg">
                    <span class="new-product__header-title">
                        {{ product.name }}
                    </span>
                </div>

                <Button :iconName="'arrow_back'" text="Voltar"
                :action="() => $router.push('/produtos/foodService')" />
            </div>

            <ProductBrandTab v-if="tab == 'brand'" :tab="tab" :setTab="value => tab = value" :product="product" :product_pattern="true" />
            <supliersBrandTab v-else-if="tab == 'supliers'" :tab="tab" :setTab="value => tab = value" :states="states" :cities="cities" :productId="product.id" />
            <PriceBrandTab v-else :productId="product.id" :tab="tab" :setTab="value => tab = value" :states="states" :cities="cities" :is100Percent="is100Percent" />
        </div>
    </div>
</template>

<script>
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ProductBrandTab from '@/modals/product-brands/product-brand-tab.vue'
import supliersBrandTab from '@/modals/product-brands/product-supliers-tab.vue'
import PriceBrandTab from '@/modals/product-brands/PriceBrandTab.vue';
import Button from '@/components/ui/buttons/Standard-Button.vue'

export default {
    name : 'FoodServiceBrands',
    mixins: [loaderMixin],
    components: {
        ProductBrandTab,
        supliersBrandTab,
        PriceBrandTab,
        Button
    },
    data() {
        return {
            tab: undefined,
            is100Percent: false,
            product: undefined,
            states: undefined,
            cities: undefined
        }
    },
    // props: {
    //     product: {
    //         type: Object,
    //         required: true
    //     },
    //     product_pattern: {
    //         type: Boolean,
    //         required: true
    //     },
    //     initTab: {
    //         type: String,
    //         required: true
    //     },
    //     states: { required: false },
    //     cities: { required: false }
    // },
    created() {
        const info = this.$store.state.productBrandsInfo
        if (!info) this.$router.push('/produtos/')
        this.tab = info.tab
        this.product = info.product
        this.states = info.states
        this.cities = info.cities
        window.scrollTo(0, 0);
    },
    watch: {
        tab() {
            window.scrollTo(0, 0);
            // if (value == 'price') {
            //     this.is100Percent = true
            //     document.body.style.zoom = '100%'
            // } else {
            //     this.is100Percent = false
            //     document.body.style.zoom = '80%'
            // }
        }
    },
    methods: {
        // close() {
        //     document.body.style.zoom = '80%'
        //     this.$emit('close')
        // },
        getActiveTab(tab) {
            if (tab == this.tab) return 'product-brands-tab-active'
        },
        getPageName () {
            if (this.tab == 'brand') return 'Marcas'
            if (this.tab == 'supliers') return 'Fornecedores'
            if (this.tab == 'price') return 'Preços'
        }
    },
}
</script>

<style lang="scss" scoped>

.modal-mask {
    font-size: 1vw;
}

.modal-content-container {
    display: flex;
    flex-direction: column;
    margin-right: 5vw;
    padding-bottom: 10vh;
}
.product-details-img{
    max-width: 10vw;
    max-height: 10vh;
    border-radius: 40%;
}
.product-details-no-product{
    background-color: white;
    border-radius: 40%;
    padding: 0.5vw 1vw;
}


.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw 0;
}

.new-product__header-title {
    font-weight: 500;
    font-size: 2.6em;
    color: #605F5F;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 4em;
    cursor: pointer;
}

.product-brands-tabs-container {
    margin: 2.5vh 0;
    display: flex;
    justify-content: space-between;
}

.product-brands-tab {
    padding: 1vh 0;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.product-brands-tab-icon {
    font-size: 2em;
    color: var(--primary-color);
}

.product-brands-tab-text {
    font-size: 1.5em;
}

.product-brands-tab:hover {
    background: rgba(255, 113, 16, 0.15);
    border-radius: 8px;
    cursor: pointer;
    color: var(--primary-color);
}

.product-brands-tab-active {
    background: rgba(255, 113, 16, 0.15);
    border-radius: 8px;
    cursor: pointer;
    color: var(--primary-color);
}

.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.page-title {
    font-weight: 600;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 45px;
    margin-bottom: 3vh;
}

@media only screen and (max-width: 800px) {
    .modal-content-container {
        width: 100vw;
    }
    .modal-content-container-100{
        width: 90vw;
    }

    .modal-mask {
        font-size: 2vw;
    }
    .modal-mask-100 {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 450px) {
    .modal-content-container {
        width: 115vw;
    }
    .modal-content-container-100{
        width: 95vw;
    }

    .modal-mask {
        font-size: 3vw;
    }
    .modal-mask-100 {
        font-size: 3vw;
    }
}
</style>
