<template>
    <div class="step-create-container">
        <addProviderHeader :cancel="cancel"/>
        <div class="step-create-body">
            
            <div v-if="message" class="new-provider-error-message">{{ message }}</div>
            <ProviderField :provider="provider" :getCnpjInfo="()=>{}" />
            <!-- <UserField :provider="provider" /> -->
            <StoreFields :highlightAdress="false" :provider="provider" :states="states" :allCities="allCities" />
            <div class="new-provider-body-buttons">
                <StandardButton class="new-provider-buttons-size" :action="cancel" :iconName="'arrow_back'"
                    :text="'Voltar'" />
                <StandardButton class="new-provider-buttons-size" :action="save" :iconName="'save'" :text="'Salvar'" />
            </div>
        </div>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/Standard-Button.vue';
import StoreFields from '../new/newStore.vue'
import ProviderField from '../new/NewProvider.vue'
import UserField from '../new/NewUser.vue'
import UserService from "@/services/v1/user.service";
import ProviderService from "@/services/v3/provider.service";
import BrasilApiService from "@/services/BrasilApi";
import addProviderHeader from './addProviderHeader.vue';

export default {
    name: 'StepCreate',
    props:['cancel', 'next', 'cnpj'],
    components:{
        addProviderHeader,
        StoreFields,
        ProviderField,
        UserField,
        StandardButton
    },
    data () {
        return {
            message:'',
            provider: {
                manager: {
                    email: '--'
                },
                head_office: {
                   cnpj: this.cnpj,
                   email: '--'
                },
                email: '--',
                delivery_type_id: 2,
                phone: '99999999999',
                settings: [
                    { key: 'CLIENT_RELEASE_STATUS' }
                ]
            },
            states: [],
            allCities: [],
            svc: new ProviderService(),
            user_svc: new UserService(),
            brasilApi: new BrasilApiService(),
            isLoading: false
        }
    },
    created(){
        this.isLoading = true
        let resp = ''
        for (let index = 0; index < 6; index++) resp += Math.floor(Math.random() * 10);
        this.provider.manager.password = resp
        this.load_region_data()
    },
    methods:{
        load_region_data() {
            return this.user_svc.region_data().then(response => response.data).then(data => {
                this.states = data.estados.map(c => ({ value: c, text: c.est_nome }))
                this.allCities = data.cidades
                this.loadCnpj()
            })
        },
        loadCnpj(){
            this.brasilApi.getCnpjInfo(this.cnpj).then(resp => {
                this.$set(this.provider, 'contact', resp.data.qsa[0] ? resp.data.qsa[0].nome_socio : '')
                this.$set(this.provider, 'phone', resp.data.ddd_telefone_1 ? resp.data.ddd_telefone_1 : '99999999999')
                this.$set(this.provider.head_office, 'social_reazon', resp.data.razao_social)
                this.$set(this.provider.head_office, 'trading_name', resp.data.razao_social.replace('LTDA', ''))
                // this.$set(this.provider.head_office, 'state', resp.data.uf)
                // this.$set(this.provider.head_office, 'city', resp.data.municipio)
                this.$set(this.provider.head_office, 'zip_code', resp.data.cep)
                this.$set(this.provider.head_office, 'street', resp.data.logradouro + ' ' + resp.data.numero)
                this.$set(this.provider.head_office, 'district', resp.data.bairro)
                this.$set(this.provider.manager, 'name', resp.data.razao_social)
                this.$set(this.provider.manager, 'login', resp.data.razao_social)
                this.states.forEach(element => {
                    if( element.value.est_sigla == resp.data.uf){
                        this.$set(this.provider.head_office, 'state', {...element.value, id:element.value.est_id})
                        this.allCities.forEach(element2 => {
                            if( element2.est_id == element.value.est_id && element2.cid_nome.toLowerCase() == resp.data.municipio.toLowerCase()){
                                this.$set(this.provider.head_office, 'city', {...element2, id: element2.cid_id})
                            }
                        });
                    }
                });
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
            })
        },
        save() {
            this.message = null
            this.isLoading = true
            let callback = data => {
                data.data.municipio = this.provider.head_office.city.cid_nome
                data.data.uf = this.provider.head_office.state.est_sigla
                this.isLoading = false
                this.next(data.data)
            }
            let callback_error = err => {
                this.isLoading = false
                this.message = 'Desculpe, não foi possível cadastrar o fornecedor.'
            }
            this.svc.create(this.provider).then(callback, callback_error)
        },
    }
}
</script>

<style scoped>
.step-create-container{
    height: 90vh;
    width: 80vw;
    background-color: white;
    border-radius: 10px;
}
.step-create-body{
    height: calc(100% - 8vh);
    padding: 10% 5%;
    overflow: auto;
}
.new-provider-body-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 2vw;
    padding-right: 3vw;
}

@media only screen and (max-width: 850px){
    .step-create-container{
        width: 110vw;
    }
    .new-provider-buttons-size{
        width: 50%;
    }
}
</style>