import http from "../Http";
import Service from "../Service";

export default class UserService extends Service {

	all(client_id) {
		return http.get("usuario", {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
    }

    update(client_id, id, data) {
		return http.put(`usuario/${id}`, this.parse_params(data, client_id), {
			headers: this.getHeaders()
		});
	}

	create(client_id, data) {
		return http.post(`usuario`, this.parse_params(data), {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
	}

	remove(client_id, data) {
		return http.delete(`usuario/${data.usu_id}`, {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
	}

    region_data(notCity) {
		let url = `usuario/region`
		if (notCity) url += '?not_city=1'
		return http.get(url, {
			headers: this.getHeaders()
		});
	}

	getCityIfo(city, state) {
		return http.get(`https://nominatim.openstreetmap.org/search?q=${city}%20${state}&format=json&addressdetails=1&limit=1`)
	}

	load_profiles(cli_id) {
		return http.get(`perfil`, {
			headers: this.getHeaders(),
			params: {
				cliente_id: cli_id,
				tipo: "C"
			}
		});
	}

	parse_params(user, cli_id) {
		let type = ''
		if (user.usu_integracao == 'V') type = 'VAREJO' 
		else if (user.usu_integracao == 'P') type = 'PRIME' 
		else  type = null 
		return {
			'cliente_id': cli_id,
			'cpf': user.usu_cpf,
			'loja': user.store ? user.store.loj_id : null,
			'login': user.usu_login,
			'perfil_id' : user.profile ? user.profile.pfl_id : null,
            'usu_comparativo': user.secondary_profile ? user.secondary_profile.value : null,
			'nome': user.usu_nome,
			'senha' : user.usu_senha,
			'email': user.usu_email,
			'telefone': user.usu_telefone,
			'usuario_externo_user': user.varejo_facil_user != '' ? user.varejo_facil_user : null,
			'usuario_externo_pass': user.varejo_facil_pass != '' ? user.varejo_facil_pass : null,
			'usuario_externo_subdomain': user.varejo_facil_subdomain != '' ? user.varejo_facil_subdomain : null,
			'token': user.varejo_token,
			'tipo': type
		}
	}

}
