<template>
  <div :style="getWidth()">
    <div v-if="title" class="input-title">{{title}}</div>
    <div v-if="type == 'text'" class="input-containear">
      <input type="text" v-model="inputValue" :disabled="disabled" class="input-value" :placeholder="placeholder ? placeholder : 'Digite o nome'">
    </div>
    <div v-if="type == 'select'" class="input-containear" >
      <select v-model="selectValue" class="input-select-value" :disabled="getDisabledList()" style="cursor: pointer;">
        <option disabled :value="''">{{placeholder}}</option>
        <option v-for="(x, idx) in list" :value="x.value" :key="idx">{{x.text}}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardInputAuto',
  props: ['title', 'width', 'type', 'list', 'value', 'action', 'placeholder', 'disabled'],
  data () {
    return {
      inputValue: '',
      selectValue: ''
    }
  },
  methods: {
    getDisabledList(){
      if (this.list.length < 1 || this.disabled) return true
      return false
    },
    getWidth(){return 'width: ' + this.width},
    returnValue(){ this.action(this.inputValue) }
  },
  watch: {
    selectValue(newValue){ this.action(newValue) },
    inputValue(newValue){ this.action(newValue) }
  }
}
</script>

<style scoped>
.input-title{
  font-weight: 400;
  font-size: 2em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5vw;
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 6vh;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  margin-top: 0 !important;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #6e6e6e;
  cursor: pointer;
  font-size: 1.5em;
}
.input-select-value{
  border: none;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  flex: 1;
  height: 24px;
}
.input-select-value:focus{
  border: none;
  outline: none;
}
</style>