import "./bootstrap";
import App from './App.vue';
import router from "./routes";
import { Icon } from 'leaflet';
import { store } from "./store/store";
import Vue from "vue";
import VueCarousel from "vue-carousel";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'leaflet/dist/leaflet.css';
import 'hover.css/css/hover.css';
import { ValidationProvider, localize } from 'vee-validate';
import Notifications from "vue-notification";
import VueVirtualScroller from 'vue-virtual-scroller'
import VueHtmlToPaper from 'vue-html-to-paper';
import Datetime from 'vue-datetime'
import VTooltip from 'v-tooltip'
import 'vue-datetime/dist/vue-datetime.css'
import numeral from "numeral";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import BootstrapVue from 'bootstrap-vue'
import VModal from 'vue-js-modal'
import StarRating from 'vue-star-rating'
import VueCurrencyFilter from 'vue-currency-filter'
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'
import Whatsapp from '@/components/ui/labels/whatsapp.component'
import Select from "@/components/cliente/base-components/Select";
import PageHeader from "@/components/utils/page-header.utils";
import Filter from "@/components/ui/forms/filter.component";

import Button from '@/components/ui/buttons/button.component'
import ButtonSeach from '@/components/ui/buttons/seach-button.component'
import ButtonClean from '@/components/ui/buttons/clean-button.component'
import ButtonGroup from '@/components/ui/buttons/group-button.component'
import ButtonInvitation from '@/components/ui/buttons/invitation-button.component'
import ButtonNew from '@/components/ui/buttons/new-button.component'
import ButtonResend from '@/components/ui/buttons/resend-button.component'
import ButtonClose from '@/components/ui/buttons/close-button.component'
import ButtonBack from '@/components/ui/buttons/back-button.component'
import ButtonSend from '@/components/ui/buttons/send-button.component'
import ButtonCategories from '@/components/ui/buttons/categories-button.component'
import ButtonPDF from '@/components/ui/buttons/pdf-button.component'
import ButtonExcel from '@/components/ui/buttons/excel-button.component'

import Modal from '@/components/ui/modals/modal.component'
import Loader from '@/components/ui/loaders/loader.component'
import FullScreenLoader from '@/components/ui/loaders/loader-full-screen.component'
import Status from "@/components/shared/Status";
import SelectV2 from '@/components/ui/selects/select.component'
import TextInput from '@/components/ui/inputs/text.component'
import SortableLable from '@/components/ui/labels/sortable-label.component'
import Checkbox from '@/components/ui/inputs/checkbox.component'
import SearchInput from '@/components/ui/inputs/search.component'
import MobileNote from '@/components/notes/mobile-promotion.note';
import NoDataAvailable from "@/components/utils/no-data.utils"
import DropDownButton from "@/components/ui/buttons/dropdown-button.component"
import Popover from "@/components/ui/popovers/base.popover"
import { extend } from 'vee-validate';
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueEllipseProgress from 'vue-ellipse-progress';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import JsonExcel from "vue-json-excel";


extend('phone', {
    message: 'Telefone inválido.',
    validate: value => {
        value = value.replace("(", "")
        value = value.replace(")", "")
        value = value.replace("-", "")
        value = value.replace(" ", "").trim()
        if (value == '0000000000')
            return false
        else if (value == '00000000000')
            return false

        if (["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10"]
        .indexOf(value.substring(0, 2)) != -1)
            return false

        if (value.length < 10 || value.length > 11)
            return false

        if (["3", "6", "7", "8", "9"].indexOf(value.substring(2, 3)) == -1)
            return false

        return true
    }
})

extend('required', {
    validate (value) {
        return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Senha e confirmação de senha diferem!'
});

extend('profile', {
  params: ['target'],
  validate(value, { target }) {
      return 'LOJA' === target.pfl_descricao && value !== null || (!target || target.pfl_descricao != 'LOJA');
  },
  message: 'Selecione uma empresa!'
});

// register globally
Vue.use(VueCarousel);
Vue.use(VueEllipseProgress);
Vue.component('RecycleScroller', RecycleScroller)
Vue.component('multiselect', Multiselect)

Vue.component('cc-button', Button);
Vue.component('cc-button-seach', ButtonSeach);
Vue.component('cc-button-clean', ButtonClean);
Vue.component('cc-button-group', ButtonGroup);
Vue.component('cc-button-invitation', ButtonInvitation);
Vue.component('cc-button-new', ButtonNew);
Vue.component('cc-button-resend', ButtonResend);
Vue.component('cc-button-close', ButtonClose);
Vue.component('cc-button-back', ButtonBack);
Vue.component('cc-button-send', ButtonSend);
Vue.component('cc-button-categories', ButtonCategories);
Vue.component('cc-button-pdf', ButtonPDF);
Vue.component('cc-button-excel', ButtonExcel);




Vue.component('cc-page-header', PageHeader);
Vue.component('cc-modal', Modal);
Vue.component('cc-whatsapp', Whatsapp);
Vue.component('cc-select', Select);
Vue.component('cc-checkbox', Checkbox);
Vue.component('cc-select-v2', SelectV2);
Vue.component('cc-status', Status);
Vue.component('cc-loader', Loader);
Vue.component('cc-loader-full', FullScreenLoader);
Vue.component('cc-input', TextInput);
Vue.component('cc-filter', Filter);
Vue.component('cc-label-sort', SortableLable);
Vue.component('cc-search', SearchInput);
Vue.component('cc-mobile-note', MobileNote);
Vue.component('no-data-available', NoDataAvailable);
Vue.component('cc-dropdown-button', DropDownButton);
Vue.component('cc-popover', Popover);

import { Settings } from "luxon";
moment.locale("pt-br");
// window.$ = window.jQuery = require("jquery");
Settings.defaultLocale = "pt";

numeral.register("locale", "pt", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "R$"
  }
});
numeral.locale("pt");

const dictionary = {
  en: {
    messages: {
      email: function() {
        return "Inserir email válido";
      },
      required: function() {
        return "Campo obrigatório";
      }
    }
  }
};

localize(dictionary);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(ToggleButton)
Vue.use(VTooltip)
Vue.component("downloadExcel", JsonExcel);

Vue.use(VueCurrencyFilter, {
    symbol : 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})

Vue.use(Notifications);

Vue.use(VueVirtualScroller)

Vue.use(VueSweetalert2);

Vue.use(Datetime);

Vue.use(Donut);

Vue.use(VueHtmlToPaper, {
	styles: [
		"./sass/app.scss",
        "./sass/reports/general-report-matrix.scss",
        "/css/_print-products-matrix.component.css",
        "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
	]
});

// Vue.filter('VMask', VueMaskFilter)
Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  // var formatter = new Intl.NumberFormat("pt-BR");
  return numeral(value).format("0,0[.]00");
});

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('cnpj', function (value) {
	if (!value) return ''
	value = value.toString()
	value = value.slice(0, 2) + "." + value.slice(2);
	value = value.slice(0, 6) + "." + value.slice(6);
	value = value.slice(0, 10) + "/" + value.slice(10);
	value = value.slice(0, 15) + "-" + value.slice(15);
	return value;
  })

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatDateOnly", function(value) {
	if (value) {
	  return moment(String(value)).format("DD/MM/YYYY");
	}
});

const url = window.location.href;
const name = "token";
const themeName = "theme";
const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
let results = regex.exec(url);

const themeRegex = new RegExp(`[?&]${themeName}=([^&#]*)`);
const themeMatch = url.match(themeRegex);
const themeEncoded = themeMatch && themeMatch[1];


if (themeEncoded) {

    const themeObject = decodeURIComponent(themeEncoded);

    const theme = JSON.parse(themeObject);
    store.dispatch('setTheme', theme);
    if(theme.providers.url){
        localStorage.setItem("loginURL", theme.providers.url);
    }else{
        localStorage.setItem("loginURL", 'https://admin.clubdacotacao.com.br/login');
    }
  }

if (results) {
  const token = decodeURIComponent(results[2].replace(/\+/g, " "));
  localStorage.setItem("token", token);
}
Vue.use(VueCurrencyFilter,
  {
    symbol : 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.use(BootstrapVue)
Vue.use(VModal)
Vue.use(require('vue-moment'));
Vue.component('star-rating', StarRating);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
