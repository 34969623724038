<template>
    <div class="log-modal-container">
        <div class="log-modal-body">
            <header class="log-header">
                <h1 class="log-modal-title">Histórico de alterações: {{ product.pro_descricao }}</h1>
                <span @click="close" class="material-icons log-close-icon">
                    cancel
                </span>
            </header>

            <body class="log-body">
                <div class="modal-table-header">
                    <div class="log-table-header date-width">data</div>
                    <div class="log-table-header name-width">Usuário</div>
                    <div class="log-table-header type-width">tipo</div>
                    <div class="log-table-header old-width">Campo</div>
                    <div class="log-table-header old-width">Dado antigo</div>
                    <div class="log-table-header new-width">Dado novo</div>
                </div>
                <h1 class="empty-alert" v-if="!isLoading && logs.length < 1">Sem registro</h1>
                <div v-for="(log, idx) in logs" :key="idx" >
                    <!-- desktop -->
                    <div class="log-table-row" :class="getSpecialBackground(idx)">
                        <div class="log-table-header date-width">{{ new Date(log.updated_at).toLocaleDateString() }}</div>
                        <div class="log-table-header name-width">{{ log.usu_login }}</div>
                        <div class="log-table-header type-width action-text" :style="getActionColor(log.action)">{{ log.action }}</div>
                        <div class="log-table-header old-width">
                            <p >
                                {{ log.field || '--' }} 
                            </p>
                        </div>
                        <div class="log-table-header old-width">
                            <p >
                                {{ log.old || '--' }}
                            </p>
                        </div>
                        <div class="log-table-header new-width">
                            <p >
                                {{ log.newValue || '--' }}
                            </p>
                        </div>
                    </div>
                    <!-- mobile -->
                    <ProductLogMobileLine :log="log" :getActionColor="getActionColor"/>
                </div>
            </body>
        </div>

        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import AuditService from '@/services/AuditService.js'
import ProductLogMobileLine from './ProductLogMobileLine.vue'
export default {
    name: 'ProductsLogModal',
    props: ['close', 'product'],
    components:{ProductLogMobileLine},
    data() {
        return {
            service: new AuditService(),
            logs: [],
            isLoading: true
        }
    },
    methods: {
        getSpecialBackground(index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        getActionColor(value) {
            if (value == 'create') return 'color: lightgreen'
            return 'color: lightblue'
        }
    },
    created() {
        this.service.getAudit(this.product.pro_id).then(resp => {
            // this.logs = resp.data.data
            resp.data.data.forEach(element => {
                element.updates = []
                if (element.action == 'create') {
                    element.updates.push({})
                    this.logs.push(element)
                    return
                }
                const list = element.details.split('Field:')
                console.log(list);
                for (let index = 1; index < list.length; index++) {
                    const element2 = list[index];
                    const name = element2.split(' -')[0]
                    const old = element2.split('Old Value: ')[1].split(',')[0]
                    const newValue = element2.split('New Value: ')[1].split('\n')[0]
                    if (old != newValue) {
                        // element.updates.push({ name: name, old: old, newValue: newValue })

                        this.logs.push({...element, field: name, old: old, newValue: newValue})
                    }
                }
            });
            this.isLoading = false
            console.log(this.product);
        })
    }
}
</script>

<style scoped lang="scss">
@import "@/sass/commons/_variables";

.log-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 125vh;
    width: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

.log-modal-body {
    background-color: white;
    height: 70%;
    width: 80%;
    border-radius: 10px;
}

.log-header {
    height: 10%;
    background: var(--primary-color);
    display: flex;
    border-radius: 10px 10px 0 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 4%;
}

.log-modal-title {
    font-size: 2.5em;
    color: white;
}
.action-text{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1em;
}

.log-close-icon {
    font-size: 3em;
    cursor: pointer;
    color: white;
}

.log-body {
    height: 90%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3% 4%;
    background-color: white;
}

.modal-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 2vh 0;
    padding-left: 2%;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 17px;
    color: #605F5F;
    margin-bottom: 1vh;
}

.log-table-header {
    font-weight: bold;
    font-size: 1.2em;
}

.log-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    padding: 2vh 0 2vh 2%;
}

.date-width {
    width: 15%;
}

.name-width {
    flex: 1;
}

.type-width {
    width: 20%;
}

.old-width {
    width: 15%;
}

.new-width {
    width: 15%;
}
.empty-alert{
    text-align: center;
    color: lightgray;
    font-size: 5em;
    margin-top: 15%;
}
.page-table-line-special {
  background: #F7F7F7;
}
@media only screen and (max-width: 800px) {
    .log-modal-container{
        font-size: 2vw;
    }
    .log-modal-body{
        width: 110vw;
        height: 100vh;
    }
    .modal-table-header{
        display: none;
    }
    .log-table-row{
        display: none;
    }
}
</style>