<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="pendencies-modal-header">
        <div>Pendência Financeira: {{store.loj_razao}}</div>
        <span class="material-icons pendencies-modal-close-icon" v-on:click="closeModal()">cancel</span>
      </div>
      <cc-loader v-if="isLoading" class="pendencies-modal-loader"/>
      <div v-else class="pendencies-modal-body-container">
        <div v-on:click="pendecieOnly = !pendecieOnly" class="pendencies-modal-filter">
          <span v-if="pendecieOnly" style="color: var(--primary-color);" class="material-icons-outlined">check_box</span>
          <span v-else style="color: var(--primary-color);" class="material-icons-outlined">check_box_outline_blank</span>
          <div>Mostrar apenas boletos em atraso</div>
        </div>
        <div class="pendencies-modal-table-overflow" >
          <div class="pendencies-modal-table-width">
            <div class="pendencies-modal-body-header">
              <div class="pendencies-modal-history-width">Histórico</div>
              <div class="pendencies-modal-document-width">Nº do Documento</div>
              <div class="pendencies-modal-value-width">Valor</div>
              <div class="pendencies-modal-date-width">Vencimento</div>
              <div class="pendencies-modal-cnpj-width">Status</div>
              <div class="pendencies-modal-client-width">Cliente</div>
              <div class="pendencies-modal-status-width">ações</div>
            </div>
            <div v-if="storage_svc.get('cli_vindi') == 0">
              <!-- desktop -->
              <div class="desktop" v-for="invoice in pendenciesList" :key="invoice.id">
                <div v-if="showInvoice(invoice)" class="pendencies-modal-table-row">
                  <div class="pendencies-modal-history-width">--</div>
                  <div class="pendencies-modal-document-width">{{ invoice.chargeId }}</div>
                  <div class="pendencies-modal-value-width">{{invoice.currentAmount}}</div>
                  <div class="pendencies-modal-date-width">{{formatDate(invoice.dueDate)}}</div>
                  <div class="pendencies-modal-cnpj-width">
                    <div v-if="isInvoiceLate(invoice)" class="pendencies-modal-cnpj-icon-red">Em atraso</div>
                    <div v-else class="pendencies-modal-cnpj-icon">Em aberto</div>
                  </div>
                  <div class="pendencies-modal-client-width">{{invoice.customer.name}}</div>
                  <a :href="invoice.chargeUrl" target="_blank" class="pendencies-modal-status-width pendencies-modal-invoice-button">
                    <span class="material-icons-outlined">print</span>
                    <div>Emitir Boleto</div>
                  </a>
                </div>
              </div>
              <!-- mobile -->
              <div class="mobile" style="margin-bottom: 2vh; color: #605F5F;" v-for="invoice in pendenciesList" :key="invoice.id + pendenciesList.length">
                <p class="mobile-table-title">{{invoice.customer.name}}</p>
                <div class="mobile-table-row">
                  <div>
                    <p class="mobile-table-title">Nº DO DOCUMENTO</p>
                    {{ invoice.chargeId }}
                  </div>
                  <div>
                    <p class="mobile-table-title">VALOR</p>
                    {{invoice.currentAmount}}
                  </div>
                  <div>
                    <p class="mobile-table-title">Vencimento</p>
                    {{formatDate(invoice.dueDate)}}
                  </div>
                </div>
                <div class="mobile-table-row">
                  <div style="width: 45%; text-align: center;" v-if="isInvoiceLate(invoice)" class="pendencies-modal-cnpj-icon-red">Em atraso</div>
                  <div style="width: 45%; text-align: center;" v-else class="pendencies-modal-cnpj-icon">Em aberto</div>
                  <a style="width: 45%;" :href="invoice.chargeUrl" target="_blank" class="pendencies-modal-status-width pendencies-modal-invoice-button">
                    <span class="material-icons-outlined">print</span>
                    <div>Emitir Boleto</div>
                  </a>
                </div>
              </div>
            </div>
            <div v-else-if="storage_svc.get('cli_vindi') == 1">
              <div v-for="(invoice, idx) in pendenciesList" :key="idx">
                <div v-if="showInvoice(invoice)" class="pendencies-modal-table-row">
                  <div class="pendencies-modal-history-width">
                    {{ formatDateVindi(invoice.period.start_at) + ' até ' + formatDateVindi(invoice.period.end_at)}}
                  </div>
                  <div class="pendencies-modal-document-width">{{ invoice.id }}</div>
                  <div class="pendencies-modal-value-width">R$ {{ parseFloat(invoice.amount).toFixed(2) }}</div>
                  <div class="pendencies-modal-date-width">{{ formatDateVindi(invoice.due_at) }}</div>
                  <div class="pendencies-modal-cnpj-width">
                    <div v-if="invoice.charges[0].status == 'pending'" class="pendencies-modal-cnpj-icon-red">Em aberto</div>
                    <div v-else-if="invoice.charges[0].status == 'paid'" class="pendencies-modal-cnpj-icon-green">Pago</div>
                  </div>
                  <div class="pendencies-modal-client-width">{{ invoice.customer.name }}</div>
                  <a :href="invoice.url" target="_blank" class="pendencies-modal-status-width pendencies-modal-invoice-button">
                    <span class="material-icons-outlined">print</span>
                    <div>Emitir Boleto</div>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientServiceV3 from "@/services/v3/ClientService.js"
import StorageService from '@/services/TokenService';

export default {
  name: 'pendenciesModal',
  props: ['closeModal', 'store'],
  data() {
    return {
      clientServiceV3: new ClientServiceV3(),
      storage_svc: new StorageService(),
      isLoading: true,
      pendenciesList: [],
      pendecieOnly: false
    }
  },
  methods: {
    formatDate (date) { return date.split('-')[2]+'-'+date.split('-')[1] +'-'+ date.split('-')[0] },
    formatDateVindi (date) {
      date = date.split('T')[0]
      return date.split('-')[2]+'-'+date.split('-')[1] +'-'+ date.split('-')[0]
    },
    showInvoice (invoice) {
      const cliVindi = this.storage_svc.get('cli_vindi')
      if (cliVindi == 0) {
        if (this.pendecieOnly) return this.isInvoiceLate(invoice)
        else return true
      } else if (cliVindi == 1) {
        if (this.pendecieOnly) return invoice.charges[0].status == 'pending'
        else return true
      }

    },
    isInvoiceLate(invoice){
      const invoiceDate = new Date(invoice.dueDate + " GMT-3:00")
      const dateNow = new Date()
      dateNow.setHours(0)
      dateNow.setMilliseconds(0)
      dateNow.setMinutes(0)
      dateNow.setSeconds(0)
      return invoiceDate < dateNow
    }
  },
  created () {
    const cliVindi = this.storage_svc.get('cli_vindi')
    console.log('init');
    if (cliVindi == 0) {
      // this.clientServiceV3.getPendencies(this.store.contract.loj_cnpj).then(response => {
      //  this.pendenciesList = response.data.invoices
      //  this.isLoading = false
      //})
      this.clientServiceV3.getBills(this.store.loj_id).then(resp => {
        this.pendenciesList = resp.data
        this.isLoading = false
      })
    } else if (cliVindi == 1) {
      this.pendenciesList = this.store.bills
      this.pendecieOnly = true
      this.isLoading = false
    }

  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}
.modal-content-container{
  background-color: white;
  width: 100vw;
  border-radius: 12px;
  margin: 0 auto;
  margin-top: 15vh;
  font-size: 1vw;
}
.pendencies-modal-header{
  background-color: var(--primary-color);
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 3vw;
  font-weight: 500;
  font-size: 2.5em;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}
.pendencies-modal-close-icon{
  font-size: 1em;
  cursor: pointer;
}
.pendencies-modal-body-container{
  padding: 1vh 5vw;
  min-height: 40vh;
}
.pendencies-modal-body-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 1vw;
  font-weight: 400;
  font-size: 1em;
  color: #605F5F;
}
.pendencies-modal-history-width{ width: 23%;}
.pendencies-modal-document-width{ width: 13%;}
.pendencies-modal-value-width{ width: 8%;}
.pendencies-modal-date-width{ width: 10%;}
.pendencies-modal-cnpj-width{ width: 14%;}
.pendencies-modal-client-width{ flex: 1;}
.pendencies-modal-status-width{ width: 11%; margin-left: 1vw;}
.pendencies-modal-loader{ margin-top: 10vh; margin-bottom: 5vh;}
.pendencies-modal-table-row{
  padding: 2vh 1vw;
  display: flex;
  font-size: 1em;
  align-items: center;
}
.pendencies-modal-invoice-button{
  display: flex;
  background: rgba(23, 162, 184, 0.05);
  border-radius: 8px;
  color: #17A2B8;
  padding: 0.3vw 0.2vw;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
}
.pendencies-modal-table-width{ min-width: 800px;}
.pendencies-modal-table-overflow{ overflow-x: auto;}
.pendencies-modal-cnpj-icon{
  width: fit-content;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 0.5vh 1vw;
  color: var(--primary-color);
}
.pendencies-modal-cnpj-icon-red{
  width: fit-content;
  border: 1px solid lightcoral;
  border-radius: 8px;
  padding: 0.5vh 1vw;
  color: lightcoral;
}
.pendencies-modal-cnpj-icon-green{
  width: fit-content;
  border: 1px solid lightgreen;
  border-radius: 8px;
  padding: 0.5vh 1vw;
  color: lightgreen;
}
.pendencies-modal-filter{
  display: flex;
  align-items: center;
  margin: 2vh 0;
  cursor: pointer;
  gap: 1.5vw;
}
.mobile{display: none;}

@media only screen and (max-width: 850px) {
  .mobile{display: block;}
  .modal-content-container{
    font-size: 2vw;
    width: 115vw;
   }
  .pendencies-modal-body-header{display: none;}
  .pendencies-modal-table-width{min-width: 0;}
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
  }
  .mobile-table-title{
    font-size: 1.1em;
    font-weight: bold;
  }
  p{margin: 0;}
  .desktop{display: none;}
}
@media only screen and (max-width: 550px) {
  .modal-content-container{ font-size: 3vw; }
}
</style>
