import http from "./http";
import Service from "../Service";

/**
 * @type QuotationService
 */
export default class ProductService extends Service {

    load(id) {
		return http.get(`products/${id}`, {
			headers: this.getHeaders()
		});
	}

	update_wrap(id, data) {
		return http.put(`products/${id}/wrap`, data, {
			headers: this.getHeaders(),
			params: data
		});
	}

    reset_product_last_offer(id, seller_id) {
        return http.delete(`clients/products/${id}`, {
			headers: this.getHeaders(),
			params: {
                seller_id
            }
		});
    }

    continue_product(id, seller_id) {
        return http.put(`clients/products/${id}/continue`, { seller_id }, {
			headers: this.getHeaders()
		});
    }

    sellers_by_products(prod_ids) {
        return http.post(`products/sellers`, { prod_ids }, {
			headers: this.getHeaders(),
		});
    }

}
