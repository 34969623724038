<template>
    <div v-if="tab == 'table'" style="overflow: auto;">
        <cc-loader-full v-if="isLoading"/>
        <div v-else class="vindi-plans-component">
            <div class="vindi-plans-header">
                <div class="vindi-plans-legend vindi-plans-title">Planos e suas <br/> Vantagens</div>
                <div class="vindi-plans-colum ">
                    <div class="vindi-plans-colum-plan-name">
                        {{ plans[0].name }}
                        <img src="@/assets/bronzeIcon.svg" />
                    </div>
                    <div class="vindi-plans-colum-price">{{ plans[0].product.price_short_format }}</div>
                    <div class="vindi-plans-colum-obs">Mensalidade por CNPJ</div>
                </div>
                <div class="vindi-plans-colum ">
                    <div class="vindi-plans-colum-plan-name">
                        {{ plans[1].name }}
                        <img src="@/assets/silverIcon.svg" />
                    </div>
                    <div class="vindi-plans-colum-price">{{ plans[1].product.price_short_format }}</div>
                    <div class="vindi-plans-colum-obs">Mensalidade por CNPJ</div>
                </div>
                <div class="vindi-plans-colum ">
                    <div class="vindi-plans-colum-plan-name">
                        {{ plans[2].name }}
                        <img src="@/assets/goldIcon.svg" />
                    </div>
                    <div class="vindi-plans-colum-price">{{ plans[2].product.price_short_format }}</div>
                    <div class="vindi-plans-colum-obs">Mensalidade por CNPJ</div>
                </div>
                <div class="vindi-plans-colum ">
                    <div class="vindi-plans-colum-plan-name">
                        {{ plans[3].name }}
                        <img src="@/assets/diamondIcon.svg" />
                    </div>
                    <div class="vindi-plans-colum-price">{{ plans[3].product.price_short_format }}</div>
                    <div class="vindi-plans-colum-obs">Mensalidade por CNPJ</div>
                </div>
            </div>
            <div class="vindi-plans-row">
                <div class="vindi-plans-legend ">Sistema de Cotação</div>
                <div class="vindi-plans-colum vindi-plans-products">100 Produtos</div>
                <div class="vindi-plans-colum vindi-plans-products">300 Produtos</div>
                <div class="vindi-plans-colum vindi-plans-products">600 Produtos</div>
                <div class="vindi-plans-colum vindi-plans-products">Sem limite de Produtos</div>
            </div>
            <div class="vindi-plans-row" :class="getSpecialBackground(idx)" v-for="(row, idx) in rows" :key="row">
                <div class="vindi-plans-legend">{{row}}</div>
                <div class="vindi-plans-colum "><span class="material-icons-outlined vindi-plans-check-icon">check_circle</span></div>
                <div class="vindi-plans-colum "><span class="material-icons-outlined vindi-plans-check-icon">check_circle</span></div>
                <div class="vindi-plans-colum "><span class="material-icons-outlined vindi-plans-check-icon">check_circle</span></div>
                <div class="vindi-plans-colum "><span class="material-icons-outlined vindi-plans-check-icon">check_circle</span></div>
            </div>
            <div class="vindi-plans-header">
                <div class="vindi-plans-legend"></div>
                <div class="vindi-plans-colum "><button class="vindi-plans-order-button" v-on:click="() => choosePlan(this.plans[0])">Ativar</button></div>
                <div class="vindi-plans-colum "><button class="vindi-plans-order-button" v-on:click="() => choosePlan(this.plans[1])">Ativar</button></div>
                <div class="vindi-plans-colum "><button class="vindi-plans-order-button" v-on:click="() => choosePlan(this.plans[2])">Ativar</button></div>
                <div class="vindi-plans-colum "><button class="vindi-plans-order-button" v-on:click="() => choosePlan(this.plans[3])">Ativar</button></div>
            </div>
        </div>
    </div>
    <div v-else class="vindi-form-component">
        <cc-loader-full v-if="isLoading"/>
        <div class="vindi-form-section section-divider">
            <div class="vindi-form-section-title">Revise seus dados</div>
            <div class="vindi-form-section-subtitle">Dados pessoais</div>
            <VindiPlansInputVue style="width: 80%;" :propValue="account.name" title="Nome"/>
            <VindiPlansInputVue style="width: 80%;" :propValue="account.registry_code" :isDocument="true" title="CPF ou CNPJ"/>
            <VindiPlansInputVue style="width: 80%;" :propValue="account.email" title="Email"/>
            <div class="vindi-form-inputs-line">
                <VindiPlansSelectVue style="width: 30%;" :propValue="numberType" :list="typeList" title="Tipo"/>
                <VindiPlansInputVue style="width: 10%;" :propValue="numberState" :isNumber="true" title="DDD"/>
                <VindiPlansInputVue style="width: 30%;" :propValue="number" :isNumber="true" title="Telefone"/>
            </div>
            <div class="vindi-form-section-subtitle">Endereço</div>
            <VindiPlansInputVue style="width: 80%;" :propValue="account.address.neighborhood" title="Rua/Avenida/Praça..."/>
            <div class="vindi-form-inputs-line2">
                <VindiPlansInputVue style="width: 35%;" :propValue="account.address.number" :isNumber="true" title="Número"/>
                <VindiPlansInputVue style="width: 35%;" :propValue="account.address.additional_details" title="Complemento"/>
            </div>
            <div class="vindi-form-inputs-line2">
                <VindiPlansInputVue style="width: 35%;" :isCep="true" :propValue="account.address.zipcode" title="CEP"/>
                <VindiPlansInputVue style="width: 35%;" :propValue="account.address.state" title="Estado"/>
                <!-- <VindiPlansSelectVue :list="statesList" style="width: 35%;" title="Estado"/> -->
            </div>
            <VindiPlansInputVue style="width: 80%;" :propValue="account.address.city" title="Cidade"/>
        </div>
        <div class="vindi-form-section section-divider section-padding">
            <div class="vindi-form-section-title">Escolha o método de pagamento</div>
            <div class="vindi-form-section-subtitle">Todas as trasações são seguras e criptografadas.</div>
            <div class="vindi-form-check-container" v-on:click="selectedPayment = 'card'">
                <span v-if="selectedPayment == 'card'" class="material-icons-outlined vindi-form-check-checked">check_box</span>
                <span v-else class="material-icons-outlined vindi-form-check-unchecked">check_box_outline_blank</span>
                <div class="vindi-form-check-text">Cartão de crédito</div>
            </div>
            <div class="vindi-form-check-container" v-on:click="selectedPayment = 'bill'">
                <span v-if="selectedPayment == 'bill'" class="material-icons-outlined vindi-form-check-checked">check_box</span>
                <span v-else class="material-icons-outlined vindi-form-check-unchecked">check_box_outline_blank</span>
                <div class="vindi-form-check-text">Boleto bancário</div>
            </div>
            <div v-show="selectedPayment == 'card'">
                <div  class="vindi-form-section-subtitle">Dados do cartão</div>
                <VindiPlansSelectVue :active="true" :setValue="setCardBrand" :list="cardsBrands" style="width: 80%;" title="Bandeira"/>
                <VindiPlansInputVue style="width: 80%;" :setValue="setCardNumber" :active="true" :isCardNumber="true" title="Número"/>
                <div class="vindi-form-inputs-line2">
                    <VindiPlansInputVue style="width: 35%;" :active="true" :setValue="setCardDate" :isDate="true" title="Vencimento"/>
                    <VindiPlansInputVue style="width: 35%;" :active="true" :setValue="setCardCode" :isNumber="true" title="CVV"/>
                </div>
                <VindiPlansInputVue style="width: 80%;" :setValue="setCardName" :active="true" title="Nome (como impresso)"/>
            </div>

        </div>
        <div class="vindi-form-section section-padding">
            <div class="vindi-form-section-title">Revise seu plano</div>
            <div class="vindi-form-order-product-container">
                <div class="vindi-form-order-product-text">I - {{chosenPlan.name}}</div>
                <img v-if="(chosenPlan.code == 1)" src="@/assets/bronzeIcon.svg" class="vindi-form-order-product-icon"/>
                <img v-else-if="(chosenPlan.code == 2)" src="@/assets/silverIcon.svg" class="vindi-form-order-product-icon"/>
                <img v-else-if="(chosenPlan.code == 3)" src="@/assets/goldIcon.svg" class="vindi-form-order-product-icon"/>
                <img v-else-if="(chosenPlan.code == 4)" src="@/assets/diamondIcon.svg" class="vindi-form-order-product-icon"/>
            </div>
            <div class="vindi-form-order-product-desc">
                <div class="vindi-form-order-product-desc-text vindi-form-order-text-align" v-if="(chosenPlan.code == 1)">Com até 100 produtos</div>
                <div class="vindi-form-order-product-desc-text vindi-form-order-text-align" v-else-if="(chosenPlan.code == 2)">Com até 300 produtos</div>
                <div class="vindi-form-order-product-desc-text vindi-form-order-text-align" v-else-if="(chosenPlan.code == 3)">Com até 600 produtos</div>
                <div class="vindi-form-order-product-desc-text vindi-form-order-text-align" v-else-if="(chosenPlan.code == 4)">Sem limite de Produtos</div>
                <div class="vindi-form-order-product-price-text">{{chosenPlan.product.price_short_format}} / mês</div>
            </div>
            <div class="vindi-form-order-product-divider"></div>
            <div class="vindi-form-order-product-desc">
                <div class="vindi-form-order-resume vindi-form-order-text-align">Total recorrente</div>
                <div class="vindi-form-order-resume-price">{{chosenPlan.product.price_short_format}} / mês</div>
            </div>
            <div class="vindi-form-order-product-desc">
                <div class="vindi-form-order-total vindi-form-order-text-align">Total</div>
                <div class="vindi-form-order-total-price">{{chosenPlan.product.price_short_format}}</div>
            </div>
            <button class="vindi-form-order-button" type="button" v-on:click="signContract" :style="getButtonState()">
                Concluir ativação
            </button>
        </div>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import VindiPlansInputVue from './VindiPlansInput.vue'
import VindiPlansSelectVue from './VindiPlansSelect.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StorageService from '@/services/TokenService';
import SubscriptionsService from '@/services/v3/subscriptionsService.js'

export default {
    name: "VindiPlans",
    mixins: [ loaderMixin ],
    data () {
        return {
            tab: 'table',
            plans: [],
            rows: [
                'Sem Fidelidade',
                'Club de Ofertas',
                'Consulta fornecedores por região',
                'Consulta preço de venda base nacional',
                'Avaliação de fornecedores',
                'Painel de relatório',
                'Acesso ao App Android / iOS',
                'Integração a API'
            ],
            creditCardInfo: {},
            chosenPlan: undefined,
            number: '',
            numberState: '',
            numberType: '',
            typeList: ['Celular', 'Telefone'],
            statesList: ['ac', 'al', 'ap', 'am', 'ba', 'ce', 'es', 'go', 'ma', 'mt', 'ms', 'mg', 'pa', 'pb', 'pr', 'pe', 'pi', 'rj', 'rn', 'rs', 'ro', 'rr', 'sc', 'sp', 'se', 'to', 'df'],
            selectedPayment: undefined,
            cardsBrands: ['Mastercard', 'Visa', 'Elo', 'American Express', 'Hipercard'],
            account: {},
            storage_svc: new StorageService(),
            isLoading: true,
            vindiService: new SubscriptionsService()
        }
    },
    components: {
        StandardButton,
        VindiPlansInputVue,
        VindiPlansSelectVue
    },
    created () {
        this.vindiService.getPlans().then(response => {
            this.plans = response.data.data
            this.isLoading = false
        })
        this.account = JSON.parse(this.storage_svc.get('vindiAccount'))
        if (this.account.phones.length > 0 ) this.getNumber (this.account.phones[0])
    },
    methods: {
        setCardBrand (value) { this.creditCardInfo.brand = value; this.$forceUpdate() },
        setCardNumber (value) { this.creditCardInfo.number = value; this.$forceUpdate() },
        setCardDate (value) { this.creditCardInfo.date = value; this.$forceUpdate() },
        setCardCode (value) { this.creditCardInfo.code = value; this.$forceUpdate() },
        setCardName (value) { this.creditCardInfo.name = value; this.$forceUpdate() },
        signContract () {
            this.isLoading = true
            let payload
            if (this.selectedPayment == 'card') {
                payload = {
                    cardInfo: {
                        "holder_name": this.creditCardInfo.name,
                        "card_expiration": this.creditCardInfo.date,
                        "card_number": this.creditCardInfo.number,
                        "card_cvv": this.creditCardInfo.code,
                        "payment_method_code": "credit_card",
                        "payment_company_code": this.creditCardInfo.brand,
                        "customer_id": this.account.id
                    },
                    subsInfo: {
                        "plan_id": this.chosenPlan.vindiId,
                        "customer_id": this.account.id,
                        "payment_method_code": "credit_card",
                        "billing_trigger_day": 15,
                        "product_items": [
                            { "product_id": this.chosenPlan.product.id }
                        ]
                    }
                }
            } else {
                payload = {
                    "plan_id": this.chosenPlan.vindiId,
                    "customer_id": this.account.id,
                    "payment_method_code": "bank_slip_yapay",
                    "billing_trigger_day": 1,
                    "product_items": [
                        { "product_id": this.chosenPlan.product.id }
                    ]
                }
            }
            this.vindiService.signContract(payload).then( response => {
                this.snack('Novo plano assinado.')
                this.$router.push('/cliente/monitores/cotacoes')
                this.storage_svc.put('contracts', JSON.stringify([response.data]))
            }).catch(error => {
                this.isLoading = false
                this.snack('Desculpe, não foi possível assinar o plano', 'error')
                console.log(error);
            })
        },
        getButtonState () {
            if (this.selectedPayment == 'bill' || this.creditCardInfo.name && this.creditCardInfo.name.length > 4 && this.creditCardInfo.date && this.creditCardInfo.number && this.creditCardInfo.code) return 'background-color: var(--primary-color)'
        },
        getNumber (phone) {
            if(phone.number[0] + phone.number[1] == '55') {
                this.numberState = phone.number.slice(2,4)
                this.number = phone.number.slice(4)
                this.numberType = phone.phone_type == 'mobile' ? 'CELULAR' : 'TELEFONE'
            }
        },
        getSpecialBackground (idx) {
            if (idx % 2 == 0) return 'page-table-line-special'
        },
        choosePlan (plan) {
            this.tab = 'form'
            this.chosenPlan = plan
        }
    }
}
</script>

<style>
.section-divider{
    border-right: 1px solid lightgray;
}
.vindi-plans-component{
    font-size: 1vw;
    margin-bottom: 10vh;
    min-width: 900px;
    overflow: auto;
}
.vindi-plans-header{
    display: flex;
    align-items: flex-start;
    margin-top: 5vh;
    margin-bottom: 3vh;
}
.vindi-plans-legend{ flex: 1; margin-left: 2vw;}
.vindi-plans-colum{
    width: 17%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1em;
}
.vindi-plans-title{
    font-weight: 600;
    font-size: 2em;
    color: var(--primary-color);
}
.vindi-plans-colum-plan-name{
    font-weight: 400;
    font-size: 1.5em;
    color: #605F5F;
    display: flex;
    align-items: center;
    gap: 1vw;
}
.vindi-plans-colum-price{
    font-weight: 600;
    font-size: 1.5em;
    color: var(--primary-color);
    padding: 0.5vh 0;
}
.vindi-plans-colum-obs{
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
}
.vindi-plans-row{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2em;
    color: #605F5F;
    padding: 1vh 0;
}
.vindi-plans-check-icon{
    font-size: 2.5em;
}
.page-table-line-special{ background: #F7F7F7; }
.vindi-form-component{
    display: flex;
    margin-top: 5vh;
    margin-bottom: 10vh;
}
.vindi-form-section{
    width: 33.3%;
}
.vindi-form-section-title{
    font-weight: 600;
    font-size: 1.5em;
    color: var(--primary-color);
}
.vindi-form-section-subtitle{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
    margin-top: 1.5vh;
}
.vindi-form-inputs-line{
    display: flex;
    gap: 5%;
}
.vindi-form-inputs-line2{
    display: flex;
    gap: 10%;
}
.section-padding{ padding-left: 4%;}
.vindi-form-check-container{
    display: flex;
    margin: 2vh 0;
    gap: 1vw;
    align-items: center;
    cursor: pointer;
}
.vindi-form-check-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #303030;
}
.vindi-form-check-checked{
    font-size: 2em;
    color: var(--primary-color);
}
.vindi-form-check-unchecked{
    font-size: 2em;
    color: gray;
}
.vindi-form-order-product-container{
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-top: 4vh;
}
.vindi-form-order-product-text{
    font-weight: 600;
    font-size: 1.5em;
    color: #605F5F;
}
.vindi-form-order-product-icon{  width: 2.5vw; }
.vindi-form-order-product-desc{
    display: flex;
    align-items: center;
    gap: 3vw;
    margin-top: 2vh;
}
.vindi-form-order-product-desc-text{
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
}
.vindi-form-order-product-price-text{
    font-weight: 400;
    font-size: 1.5em;
    color: #606060;
}
.vindi-form-order-product-divider{
    border-bottom: 1px solid lightgray;
    width: 80%;
    margin-top: 6vh;
}
.vindi-form-order-resume{
    font-weight: 400;
    font-size: 1.5em;
    color: #909090;
}
.vindi-form-order-resume-price{
    font-weight: 400;
    font-size: 1.6em;
    color: #505050;
}
.vindi-form-order-total{
    font-weight: 700;
    font-size: 1.6em;
    color: #909090;
}
.vindi-form-order-total-price{
    font-weight: 600;
    font-size: 1.8em;
    color: #0677C9;
}
.vindi-form-order-text-align{  width: 40%;}
.vindi-form-order-button{
    background: rgba(255, 113, 16, 0.3);
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.75em;
    color: #FFFFFF;
    border: none;
    padding: 0.5vh 5vw;
    margin-top: 4vh;
    margin-left: 1vw;
}
.vindi-plans-order-button{
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    height: 7.5vh;
    min-width: 12vw;
    font-weight: 600;
    letter-spacing: 0.3vw;
    font-size: 1.1em;
    color: white;
}
.vindi-plans-order-button:hover{
    background-color: white;
    color: var(--primary-color);
}


@media only screen and (max-width: 850px) {
    .vindi-plans-legend{
        font-size: 20px
    }
    .vindi-plans-colum {font-size: 10px;}
    .vindi-plans-colum{width: 20%;}
    .vindi-plans-products{font-size: 20px;}
    .vindi-plans-order-button{min-width: 30vw;}
    .vindi-form-component{
        flex-direction: column;
        font-size: 15px;
    }
    .section-divider{
        border-right: none;
        border-bottom: 1px solid lightgray;
        margin-top: 2vh;
        padding-bottom: 2vh;
    }
    .vindi-form-section{width: 100%;}
}
</style>
