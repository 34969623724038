import http from "./http";
import Service from "../Service";

/**
 * @type ClientService
 */
export default class ClientService extends Service {

	update_client(client) {
		return http.put(`clients/${client.cli_id}`, client, {
			headers: this.getHeaders()
		});
	}

}
