import http from './Http';
import Service from './Service';

/**
 * @type QuotationService
 */
export default class AuthService extends Service {

    hasPermission($for = null) {
        return http.get('haspermission', {
            headers: this.getHeaders(),
            timeout: 1200000,
            params: { for: $for }
        })
    }

    get_user() {
        return http.get('getUser', {
            headers: this.getHeaders(),
            timeout: 200000
        })
    }

}
