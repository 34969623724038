import http from "../http";
import Service from "../../Service";

/**
 * @type StoreService
 */
export default class StoreService extends Service {

    load() {
		return http.get(`clients/stores`, {
            headers: this.getHeaders()
        });
	}

}
