<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="subrequest-modal-header">
                {{ product.pro_descricao }}
                <span
                    v-on:click="close"
                    class="material-icons subrequest-modal-close-icon"
                    >cancel</span
                >
            </div>
            <div class="subrequest-modal-body">
                <div class="subrequest-modal-body-title">
                    <div class="subrequest-modal-body-title-buttons">
                        <div>
                            <StandardButton
                                class="subrequest-modal-body-mobile-width"
                                :class="{ 'button-active': tab == 'STORES' }"
                                :action="() => (tab = 'STORES')"
                                :iconName="'group'"
                                :text="'Requisitantes'"
                            />
                            <StandardButton
                                class="subrequest-modal-body-mobile-width mr-2"
                                :action="() => (tab = 'TYPINGS')"
                                :class="{ 'button-active': tab == 'TYPINGS' }"
                                :iconName="'payments'"
                                :text="'Digitacões'"
                            />
                            <StandardButton
                                class="subrequest-modal-body-mobile-width"
                                :action="() => (tab = 'ORDERS')"
                                :class="{ 'button-active': tab == 'ORDERS' }"
                                :iconName="'shopping_cart_checkout'"
                                :text="'Pedidos'"
                            />
                        </div>
                        <StandardButton
                            class="subrequest-modal-body-mobile-width"
                            :action="close"
                            :iconName="'arrow_back'"
                            :text="'Voltar'"
                        />
                    </div>
                </div>
                <cc-loader v-if="is_loading" style="margin-top: 5vh" />
                <div
                    v-if="!is_loading && tab == 'TYPINGS'"
                    class="subrequest-modal-table-overflow"
                >
                    <TypingsPage
                        :description="product.pro_descricao"
                        :request_id="request_id"
                        :product_id="product.pro_id"
                    />
                </div>
                <div
                    v-if="!is_loading && tab == 'ORDERS'"
                    class="subrequest-modal-table-overflow"
                >
                    <OrdersComponent
                        :description="product.pro_descricao"
                        :product_id="product_id"
                        :request_id="request_id"
                    />
                </div>
                <div
                    v-if="!is_loading && tab == 'STORES'"
                    class="subrequest-modal-table-overflow"
                >
                    <div class="subrequest-modal-table-width">
                        <div class="subrequest-modal-table">
                            <div class="subrequest-modal-table-id-width">
                                ID
                            </div>
                            <div class="subrequest-modal-table-company-width">
                                Empresa
                            </div>
                            <div class="subrequest-modal-table-req-width">
                                Req.
                            </div>
                            <div class="subrequest-modal-table-est-width">
                                Est.
                            </div>
                            <div class="subrequest-modal-table-order-width">
                                Pedido
                            </div>
                            <div
                                class="subrequest-modal-table-total-item-width"
                            >
                                Total Item
                            </div>
                        </div>
                        <div
                            class="subrequest-modal-table-row"
                            v-for="(store, idx) in visibleStores()"
                            v-bind:key="idx"
                            :class="getSpecialBackground(idx)"
                        >
                            <!-- desktop -->
                            <div
                                class="subrequest-modal-table-id-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    ID
                                </div>
                                {{ store.codSolicitacao }}
                            </div>
                            <div
                                class="subrequest-modal-table-company-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    Empresa
                                </div>
                                {{ store.descricaoLoja }}
                            </div>
                            <div
                                class="subrequest-modal-table-req-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    Req.
                                </div>
                                {{ store.quantidade }}
                            </div>
                            <div
                                class="subrequest-modal-table-est-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    Est.
                                </div>
                                {{ store.estoque ? store.estoque : "--" }}
                            </div>
                            <div
                                class="subrequest-modal-table-order-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    Pedido
                                </div>
                                <input
                                    class="subrequest-modal-table-order-input"
                                    :class="{
                                        'subrequest-modal-table-order-input-disabled': true,
                                        'subrequest-modal-table-order-input-altered':
                                            store.isAltered,
                                    }"
                                    type="number"
                                    min="0"
                                    :readonly="true"
                                    v-model="store.quantidadeSugestao"
                                    :id="'product-amount' + idx"
                                />
                            </div>
                            <div
                                class="subrequest-modal-table-total-item-width desktop"
                            >
                                <div class="subrequest-modal-mobile-header">
                                    Total Item
                                </div>
                                {{ formatPrice(calculateAmount(store)) }}
                            </div>

                            <!-- mobile -->
                            <div
                                class="subrequest-modal-mobile-header mobile-table-title"
                            >
                                {{ store.descricaoLoja }}
                            </div>
                            <div class="mobile mobile-table-row">
                                <div class="mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">
                                        ID
                                    </div>
                                    {{ store.codSolicitacao }}
                                </div>
                                <div class="mobile-center mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">
                                        Req.
                                    </div>
                                    {{ store.quantidade }}
                                </div>
                                <div class="mobile-end mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">
                                        Est.
                                    </div>
                                    {{ store.estoque ? store.estoque : "--" }}
                                </div>
                            </div>
                            <div class="mobile mobile-table-row">
                                <div class="mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">
                                        Pedido
                                    </div>
                                    <input
                                        class="subrequest-modal-table-order-input"
                                        :class="{
                                            'subrequest-modal-table-order-input-disabled':
                                                store.vendedorPossuiPedido > 0,
                                            'subrequest-modal-table-order-input-altered':
                                                store.isAltered,
                                        }"
                                        type="number"
                                        min="0"
                                        :readonly="
                                            store.vendedorPossuiPedido > 0
                                        "
                                        v-model="store.quantidadeSugestao"
                                        :id="'product-amount' + idx"
                                    />
                                </div>
                                <div class="mobile-center mobile-colum-size">
                                    <div class="subrequest-modal-mobile-header">
                                        Total Item
                                    </div>
                                    {{ formatPrice(calculateAmount(store)) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import { perm_mixin } from "@/mixins/permission.mixin";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import QuotationService from "@/services/QuotationService";
import ProviderService from "@/services/v3/provider.service";
import { mapState } from "vuex";
import OrdersComponent from "./orders/_orders.component.vue";
import TypingsPage from "./typings/typings.page.vue";

export default {
    components: {
        StandardButton,
        TypingsPage,
        OrdersComponent
    },
    props: [
        "request_id",
        "product_id",
        "seller_id",
        "provider_id",
        "sellers",
        "loj_id",
        "price",
        "stores_only",
        "product",
    ],
    mixins: [perm_mixin],
    data() {
        return {
            tab: "STORES",
            open_negotiation_modal: false,
            open_edit_wrap: false,
            prov_svc: new ProviderService(),
            stores: [],
            salesman: {},
            is_loading: true,
            service: new QuotationService(),
            delivery: {},
            rate: 5,
            myStore: undefined,
        };
    },
    methods: {
        load_provider() {
            console.log(this.provider_id, "KSHAGDKHASGJKGHS");
            this.prov_svc.show(this.seller_id).then((response) => {
                this.salesman = response.data;
                console.log(this.salesman);
            });
        },
        phone(text = "") {
            if (!text) {
                text = "";
            }
            if (text.length < 6) {
                return text;
            }

            text = text.toString().replace("+55", "").replace(/\D/g, "");

            let mask = "($1) $2-$3";
            let regex = /^(\d{2})(\d{5})?(\d{4})?/;

            if (text.length > 13) {
                text = text.substring(text.length - 11);
            } else if (text.length == 13) {
                text = text.substring(text.length - 10);
            } else if (text.length == 12) {
                regex = /^(\d{3})(\d{5})?(\d{4})?/;
            } else if (text.length == 11) {
                regex = /^(\d{2})(\d{5})?(\d{4})?/;
            } else if (text.length == 10) {
                regex = /^(\d{2})(\d{4})?(\d{4})?/;
            } else if (text.length == 9) {
                regex = /^(\d{5})?(\d{4})?/;
                mask = "$1-$2";
            } else if (text.length == 8) {
                regex = /^(\d{4})?(\d{4})?/;
                mask = "$1-$2";
            } else {
                regex = /^(\d{2})?(\d{1,})?/;
                mask = "($1) $2";
            }

            return text.replace(regex, mask);
        },
        formatNumber(number) {
            if (!number) return null;
            number = number
                .replace("+", "")
                .replace("(", "")
                .replace(")", "")
                .replace("-", "");
            if (number[0] + "" + number[1] != "55") number = "55" + number;
            return number;
        },
        is_focused(idx, product) {
            let inputs = this.$refs["product-amount"];
            this.stores.forEach((p) => (p.focused = false));
            if (inputs && document.activeElement == inputs[idx]) {
                this.$set(product, "focused", true);
                this.$forceUpdate();
            }
        },
        visibleStores() {
            let resp = [];
            this.stores.filter((s) => {
                if (this.myStore && this.myStore == s.codLoja) resp = [s];
                if (
                    !this.myStore &&
                    s.loj_excluido != "1" &&
                    (this.loj_id == undefined || s.codLoja == this.loj_id)
                ) {
                    resp.push(s);
                }
            });
            return resp;
        },
        getDelivery() {
            let delivery = this.sellers
                .filter((s) => s.fornecedores && s.fornecedores.length > 0)
                .map((s) => {
                    let providers = s.fornecedores.filter(
                        (f) => f.idFornecedor == this.salesman.idFornecedor
                    );
                    return providers[0];
                })
                .filter((s) => s != undefined);
            return delivery && delivery[0] ? delivery[0].entrega : {};
        },
        getProvider(store) {
            let storeData = this.sellers.filter(
                (s) => s.loj_id == store.codLoja
            )[0];
            let provider = storeData.fornecedores.filter(
                (p) => p.idFornecedor == store.idFornecedor
            )[0];
            return provider;
        },
        isLower(store) {
            return (
                this.calculateTotalPedidoAmount(store) > 0 &&
                this.calculateTotalPedidoAmount(store) <
                    this.salesman.usu_fatura_min
            );
        },
        calculateAmount(store) {
            let result = this.price * store.quantidadeSugestao;
            return this.product.flag_generico == 0 &&
                !this.product.pro_principal &&
                this.user.request_type != 1
                ? parseFloat(this.product.qtd_embalagem) * result
                : result;
        },
        calculateTotalPedidoAmount(store) {
            let total = store.total || 0;
            let result = this.price * store.old_value;
            console.log(result, "AHSGDKGSAH");
            result =
                this.product.flag_generico == 0 &&
                !this.product.pro_principal &&
                this.user.request_type != 1
                    ? parseFloat(this.product.qtd_embalagem) * result
                    : result;
            return total - result + this.calculateAmount(store);
        },
        close() {
            this.$emit("close");
        },
        save(store, callback) {
            this.service
                .sendQuantidade({
                    numerocotacao: this.$route.params.id,
                    quantidade: store.quantidadeSugestao || "0",
                    vendedor: this.salesman.idVendedor,
                    produto: store.codProduto,
                    loja: store.codLoja,
                })
                .then((response) => response.data)
                .then(callback);
        },
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return "page-table-line-special";
        },
        load_reviews() {
            this.svc
                .get_evaluation(this.seller.usu_id)
                .then((response) => response.data)
                .then((data) => {
                    if (data.todas_avaliacoes) {
                        let nota = 0;
                        data.todas_avaliacoes.forEach((data) => {
                            nota +=
                                parseInt(data.nota) /
                                data.todas_avaliacoes.length;
                        });
                        this.rate = nota;
                    }
                });
        },
        getStores() {
            this.is_loading = this.stores.length === 0;
            var quotationService = new QuotationService();
            quotationService
                .getLojasQuantidade(
                    this.product_id,
                    this.request_id,
                    this.seller_id
                )
                .then((response) => response.data)
                .then((data) => {
                    this.stores = data.produtos;
                    this.is_loading = false;
                    this.stores.forEach((s) => {
                        // this.sellers.forEach((element) => {
                        //     if (s.descricaoLoja == element.loj_descricao)
                        //         s.loj_excluido = element.loj_excluido;
                        // });
                        if (s.quantidadeSugestao == null) {
                            s.quantidadeSugestao = s.quantidade;
                        }
                        s.old_value = s.quantidadeSugestao;
                    });
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },
    computed: {
        ...mapState(["user"]),
        mapped_product: function () {
            return {
                produtoDescricao: this.product.descricaoProduto,
                ultimaCompra: this.product.last_purchase?.valor,
                pro_observacao: this.salesman.pro_observacao,
                observacao: this.salesman.observacao,
            };
        },
    },
    mounted() {
        this.getStores();
        this.product.idProduto = this.product.pro_id;
        this.perms.forEach((element) => {
            if (element.resource == "MY_COMPANY" && element.action == "READ") {
                this.myStore = this.user.store_id;
            }
        });
        this.load_provider();
    },
};
</script>

<style lang="scss" scoped>
@import "./request-details.modal";
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}
.modal-content-container {
    background-color: white;
    width: 95vw;
    border-radius: 12px;
}
.subrequest-modal-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-radius: 10px 10px 0px 0px;
    padding: 2vh 1vw;
    font-weight: 500;
    font-size: 2.2em;
    letter-spacing: -0.02em;
    color: #ffffff;
    align-items: center;
}
.subrequest-modal-close-icon {
    font-size: 1.4em;
    cursor: pointer;
}
.subrequest-modal-body {
    padding: 5vh 5vw;
    max-height: 95vh;
    overflow-y: auto;
}
.subrequest-modal-mobile-header {
    display: none;
}
.subrequest-modal-body-title {
    display: flex;
    align-items: flex-start;
    font-size: 1.65em;
    margin-bottom: 2vh;
}
.rate {
    margin-left: 2vw;
}
.subrequest-modal-body-title-buttons {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 1vw;
    div {
        display: flex;
        div:first-child {
            margin-right: 10px;
        }
    }
}
.subrequest-modal-body-title-last-button {
    margin-left: 1vw;
}
.subrequest-modal-body-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}
.subrequest-modal-body-input-product {
    width: 45%;
}
.subrequest-modal-body-input-smal {
    width: 30%;
}

.subrequest-modal-body-input-title {
    font-weight: 400;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #898989;
    display: flex;
    justify-content: space-between;
}
.subrequest-modal-body-input-value {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 1vw;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.15px;
    color: #515151;
    background-color: #e9ecef;
    min-height: 3.5vh;
}
.subrequest-modal-table {
    display: flex;
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    padding: 1vh 0.5vw;
    font-size: 1.2em;
    font-weight: 600;
    color: #605f5f;
}
.subrequest-modal-table-id-width {
    width: 10%;
}
.subrequest-modal-table-company-width {
    flex: 1;
}
.subrequest-modal-table-req-width {
    width: 11%;
}
.subrequest-modal-table-est-width {
    width: 11%;
}
.subrequest-modal-table-order-width {
    width: 11%;
}
.subrequest-modal-table-total-item-width {
    width: 11%;
}
.subrequest-modal-table-total-order-width {
    width: 11%;
}
.subrequest-modal-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    color: #605f5f;
    padding: 1vh 0.5vw;
    align-items: center;
}
.subrequest-modal-table-order-input {
    width: 80%;
    background: rgba(23, 162, 184, 0.05);
    border-radius: 8px;
    border: none;
    font-weight: 400;
    font-size: 1.1em;
    color: var(--primary-color);
    padding-left: 1vw;
    outline: 1px solid var(--primary-color);
}
.subrequest-modal-table-order-input:focus {
    background-color: #f7dea8;
}
.subrequest-modal-table-order-input-disabled {
    cursor: not-allowed;
}
.subrequest-modal-table-order-input-altered {
    background-color: lightgreen !important;
}
.subrequest-modal-table-order-input-disabled:focus {
    outline: none;
}
.page-table-line-special {
    background: #f7f7f7;
}
.mobile {
    display: none;
}
.button-active{
    background-color: #fdf0e9;
}

@media only screen and (max-width: 1100px) {
    .modal-mask {
        font-size: 1.5vw;
    }
}
@media only screen and (max-width: 850px) {
    .mobile {
        display: flex;
    }
    .subrequest-modal-body-title {
        flex-direction: column;
    }
    .modal-mask {
        font-size: 2vw;
    }
    .modal-content-container {
        width: 110vw;
    }
    .subrequest-modal-table {
        display: none;
    }
    .subrequest-modal-table-row {
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 2vh;
        font-size: 1.5em;
    }
    .subrequest-modal-table-id-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-company-width {
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-req-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-est-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-order-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-total-item-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-table-total-order-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .subrequest-modal-mobile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }
    .subrequest-modal-body-mobile-width {
        width: 48%;
        font-size: 3em;
    }
    .subrequest-modal-body-title-buttons {
        width: 100%;
        justify-content: space-between;
    }
    .modal-mask {
        font-size: 3vw;
    }
    .subrequest-modal-body-input-product {
        margin-bottom: 1vh;
    }
    .subrequest-modal-body-input-smal {
        margin-bottom: 1vh;
    }
    .subrequest-modal-table {
        margin-top: 3vh;
    }
    .modal-content-container {
        width: 115vw;
    }
    .mobile-product-name {
        width: 65%;
    }
    .mobile-product-brand {
        width: 30%;
    }
    .mobile-table-row {
        width: 100%;
        justify-content: space-between;
    }
    .mobile-colum-size {
        width: 30%;
    }
    .mobile-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mobile-end {
        display: flex;
        flex-direction: column;
        align-items: end;
    }
    .mobile-table-title {
        text-align: left;
        width: 100%;
    }
    .desktop {
        display: none;
    }
}
</style>
