<template>
    <div class="sellprice-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/ofertas" class="page-route-select-text">
                Ofertas
            </router-link>
        </div>
        <div class="sellprice-page-title">
            Olá, {{ user.user_name }}
        </div>
        <div class="sellprice-page-subtitle">
            Seja bem-vindo ao Club da Cotação e saiba o que podemos fazer por você e sua empresa:
        </div>
        <div class="home-main-video-container">
            <iframe class="home-main-video" src="https://www.youtube.com/embed/XxsY-fH3E0s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="sellprice-page-subtitle">
            Siga os passos a seguir para começar a economizar:
        </div>

        <div class="sellprice-card">
            <span class="material-icons-outlined sellprice-task-icon">task_alt</span>
            <div class="sellprice-card-text-container">
                <div class="sellprice-card-text-title">1. Cadastre seus Produtos</div>
                <div class="sellprice-card-text-subtitle">Veja o vídeo ao lado e aprenda como cadastrar seus produtos e começar a aproveitar os benefícios do Club.</div>
                <StandardButton :action="() => { }" :iconName="'email'" class="home-card-button" :text="'Cadastrar Produto'" />
            </div>
            <iframe class="home-guide-video" src="https://www.youtube.com/embed/LvBx7g6P2Xo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="sellprice-card">
            <span class="material-icons-outlined sellprice-task-icon">task_alt</span>
            <div class="sellprice-card-text-container">
                <div class="sellprice-card-text-title">2. Convide seus Fornecedores</div>
                <div class="sellprice-card-text-subtitle">Veja o vídeo ao lado e descubra como convidar seus fornecedores para fazer parte do Club.</div>
                <StandardButton :action="() => { }" :iconName="'email'" class="home-card-button" :text="'Convidar Fornecedor'" />
            </div>
            <iframe class="home-guide-video" src="https://www.youtube.com/embed/ieljcEAJNJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="sellprice-card">
            <span class="material-icons-outlined sellprice-task-icon">task_alt</span>
            <div class="sellprice-card-text-container">
                <div class="sellprice-card-text-title">3. Lance sua Cotação</div>
                <div class="sellprice-card-text-subtitle">Veja o vídeo ao lado e aprenda como lançar sua cotação e começar a economizar.</div>
                <StandardButton :action="() => { }" :iconName="'shopping_cart'" class="home-card-button" :text="'Lancar Cotação'" />
            </div>
            <iframe class="home-guide-video" src="https://www.youtube.com/embed/LvBx7g6P2Xo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import { mapState } from 'vuex'

export default {
    name: 'HomePage',
    components: {
        StandardButton
    },
    computed: {
        ...mapState(['user'])
    }
}
</script>

<style scoped>
.sellprice-page {
    font-size: 1vw;
    padding-bottom: 10vh;
}

.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.sellprice-page-title {
    font-weight: 500;
    font-size: 2.2em;
    letter-spacing: 0.15px;
    color: #606060;
    margin-top: 2vh;
}

.sellprice-page-subtitle {
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.15px;
    color: #606060;
    margin-top: 1vh;
}

.sellprice-card {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;
    padding: 2vh 4vw;
    display: flex;
    margin-right: 10vw;
    align-items: center;
    margin-top: 5vh;
}

.sellprice-task-icon {
    font-size: 4em;
    color: #C1C1C1;
    margin-right: 4vw;
}

.sellprice-card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    margin-right: 10vw;
}

.sellprice-card-text-title {
    font-weight: 400;
    font-size: 1.5em;
    color: #505050;
    margin-bottom: 2vh;
}

.sellprice-card-text-subtitle {
    font-weight: 400;
    font-size: 1.3em;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 2vh;
}

.home-main-video-container {
    display: flex;
    justify-content: center;
    padding: 2vh 0;
}
.home-main-video{
    width: 40vw;
    height: 40vh;
}
.home-guide-video{
    width: 35vw;
    height: 35vh;
}
.home-card-button{width: fit-content;}

@media only screen and (max-width: 850px) {
    .sellprice-page { font-size: 2vw; }
    .home-main-video{
        width: 60vw;
        height: 30vh;
    }
    .home-guide-video{
        width: 40vw;
        height: 20vh;
    }
}

@media only screen and (max-width: 450px) {
    .sellprice-page {
        font-size: 3vw;
        padding-bottom: 10vh;
    }

    .sellprice-card {
        flex-direction: column;
        gap: 2vh;
        margin-right: 0;
    }
    .home-main-video{
        width: 90vw;
        height: 30vh;
    }
    .home-guide-video{
        width: 75vw;
        height: 25vh;
    }
    .sellprice-card-text-container{
        align-items: center;
        margin-right: 0;
    }
    .home-card-button{
        width: 70vw;
    }
    .sellprice-card-text-subtitle{text-align: center;}
}
</style>
