import http from "./http";
import Service from "../Service";


export default class SubscriptionsService extends Service {

	getPlans() {
        return http.get('clients/plans', { headers: this.getHeaders() })
    }
    signContract (payload) {
        if (payload.cardInfo) {
            return http.post('clients/payment_profiles', payload.cardInfo, { headers: this.getHeaders() }).then(response => {
                payload.subsInfo.payment_profile = { id: response.data.payment_profile.id }
                return http.post('clients/subscriptions', payload.subsInfo, { headers: this.getHeaders() })
            })
        } else return http.post('clients/subscriptions', payload, { headers: this.getHeaders() })
    }

}