<template>
    <div class="quantity-modal-container">
        <div class="quantity-modal">
            <div class="quantity-modal-header">
                {{ product.pro_descricao }}
                <span class="material-icons quantity-modal-close-icon" v-on:click="close">cancel</span>
            </div>
            <div class="quantity-modal-body">
                <div class="quantity-modal-inputs">
                    <StandardInput class="quantity-modal-products" :title="'Buscar loja'" placeholder="Digite o nome da loja" :action="() => { }" :type="'text'"/>
                    <StandardInput :title="'Preço'" :action="setPrice" :type="'money'" :value="current_price"/>
                    <StandardInput v-if="product.pro_generico == 1" class="quantity-modal-products" :title="'Marca'" :action="selectBrand" :type="'select'" :list="product.brands.map(value=>{return{text: value.pro_observacao, value}})"/>
                    <div v-else class="quantity-modal-products"></div>
                </div>
                <div class="quantity-modal-info-row">
                    <div class="quantity-modal-info-text" style="color: #17A2B8;">Volume total comprado:</div>
                    <div class="quantity-modal-info-value" style="color: #17A2B8; background-color: rgba(23, 162, 184, 0.1); font-weight: bold;">{{ unitTotal() }}</div>
                    <div class="quantity-modal-info-text" style="color: #30AA4C;">Valor total:</div>
                    <div class="quantity-modal-info-value" style="color: #30AA4C; background: rgba(42, 205, 114, 0.1); font-weight: bold;">{{ currency.format( valueTotal()) }}</div>
                    <Button text="Salvar" :iconName="'save'" :action="() => save(temp_list)" :value="current_price"/>
                    <Button text="Voltar" :iconName="'arrow_back'" :action="() => close()" />
                </div>
                <div class="quantity-modal-table-header">
                    <div class="quantity-modal-name-width">Loja</div>
                    <div class="quantity-modal-quantity-width">Quantidade</div>
                    <div class="quantity-modal-price-width">Total por loja</div>
                </div>
                <div class="quantity-modal-table-body">
                    <div class="quantity-modal-table-line" v-for="(tmp, idx) in temp_list" :key="idx"
                        :class="{ 'quantity-modal-special-background': idx % 2 == 0 }">
                        <div class="quantity-modal-name-width">{{ tmp.name }}</div>
                        <div class="quantity-modal-quantity-width">
                            <input :id="'input-' + idx"
                                @keydown="event => moveFocus(event, idx)"
                                class="quantity-modal-input" min="0"
                                :class="{'quantity-modal-input-disabled': price < 0.01}"
                                :disabled="price < 0.01" type="number" v-model="tmp.quantity">
                        </div>
                        <div class="quantity-modal-price-width">{{ tmp.quantity && tmp.unit_price ? currency.format(tmp.quantity * tmp.unit_price) : 0 }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import Button from "@/components/ui/buttons/Standard-Button.vue";
import { mapState } from 'vuex'

export default {
    name: 'QuantityModal',
    props: ['close', 'product', 'save', 'temps'],
    components: {
        StandardInput,
        Button
    },
    data() {
        return {
            storeList: [],
            currency: new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }),
            price: 0,
            temp_list: [],
            current_temps: []
        }
    },
    computed:{
        current_price() {
            return this.current_temps.find(tmp => tmp.unit_price)?.unit_price
        },
        ...mapState('user', ['stores']),
    },
    created () {
        if (this.product.pro_generico == 0){
            this.selectBrand(this.product)
        }
    },
    methods: {
        selectBrand(value){
            this.build_tmps(value)
            this.price = this.current_temps.find(tmp => tmp.unit_price)?.unit_price
            this.setPrice(this.price)
        },
        unitTotal() {
            let resp = 0
            this.temp_list.forEach(element => { if (element.quantity) resp += Number(element.quantity) });
            return isNaN(resp) ? 0 : resp
        },
        valueTotal() {
            let resp = 0
            this.temp_list.forEach(element => { if (element.quantity && element.unit_price) {
                resp += element.unit_price * Number(element.quantity)
                }
            });
            return isNaN(resp) ? 0 : resp
        },
        build_tmps(brand) {
            this.current_temps = this.temps.filter(tmp => tmp.product_id == brand.pro_id)
            this.temp_list = this.stores?.map(s => {
                let curr_tmp = this.current_temps.find(tmp => tmp.store_id == s.id) || {}
                return {
                    quantity: curr_tmp.quantity,
                    name: s.name,
                    ean: this.product.pro_ean,
                    product_id: brand.pro_id,
                    store_id: s.id,
                    type: 'REQUEST_WITH_PRE_ORDERS',
                    unit_price: curr_tmp.unit_price
                }
            }) || []
        },
        setPrice (value) {
            this.temp_list.forEach(tmp => tmp.unit_price = value)
            this.$forceUpdate()
        },
        moveFocus (e, idx) {
            if (e.key != "ArrowDown" && e.key != "ArrowUp") return
            e.preventDefault()
            let newIdx
            if (e.key == "ArrowDown") newIdx = idx + 1
            else if (e.key == "ArrowUp") newIdx = idx - 1
            let input = document.getElementById('input-' + newIdx)
            if (!input) return
            input.focus()
        }
    }
}
</script>

<style scoped>
.quantity-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 125vh;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 1vw;
}

.quantity-modal {
    background-color: white;
    height: 95vh;
    width: 90vw;
    border-radius: 10px;
}

.quantity-modal-header {
    background-color: var(--primary-color);
    height: 12%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    font-size: 1.7em;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.quantity-modal-close-icon {
    font-size: 1.7em;
    cursor: pointer;
}

.quantity-modal-body {
    height: 88%;
    box-sizing: border-box;
    padding: 3% 4%;
}

.quantity-modal-inputs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.quantity-modal-products {
    width: 30%;
}

.quantity-modal-table-header {
    display: flex;
    padding: 1vh 0;
    margin-top: 3vh;
    margin-bottom: 1vh;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-size: 1.2em;
    color: #605F5F;
    padding-right: 2.5%;
}

.quantity-modal-check-width {
    width: 10%;
    box-sizing: border-box;
    padding-left: 2%;
}

.quantity-modal-name-width {
    flex: 1;
    padding-left: 2%;
}

.quantity-modal-quantity-width {
    width: 20%;
}

.quantity-modal-price-width {
    width: 15%;
}

.quantity-modal-table-body {
    height: 60%;
    overflow: auto;
}

.quantity-modal-table-line {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
    margin-top: 1vh;
    color: #605F5F;
    font-size: 1em;
    padding: 1vh 0;
}

.quantity-modal-special-background {
    background: rgba(247, 247, 247, 0.5);
}

.quantity-modal-check-icon {
    font-size: 2em;
    cursor: pointer;
    color: var(--primary-color);
}

.quantity-modal-input {
    background: rgba(247, 247, 247, 0.5);
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    width: 60%;
    padding: 1% 5%;
    color: #605F5F;
    font-size: 1.1em;
}
.quantity-modal-input-disabled{
    background-color: lightgray;
}
.quantity-modal-input:focus{
    outline: none;
}

.quantity-modal-info-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vh;
}

.quantity-modal-info-text{
    font-size: 1.5em;
    margin-right: 3%;
}
.quantity-modal-info-value{
    font-size: 1.5em;
    margin-right: 7%;
    padding: 1vh 1.5vw;
    border-radius: 10px;
}
</style>
