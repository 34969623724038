<template>
    <div class="request-table-container">
        <h1 v-if="!isLoading && list.length==0" class="empty-alert">Sem requisições disponíveis</h1>
        <table v-else class="request-table-body">
            <thead >
                <tr class="request-table-header">
                    <th class="request-table-cot-width request-table-header-text">N. da Requisição / Cotação</th>
                    <th class="request-table-date-width request-table-header-text">Data Cotação</th>
                    <th class="request-table-number-width request-table-header-text desktop">Numero de Produtos</th>
                    <th class="request-table-company-width request-table-header-text">Empresa</th>
                    <th class="request-table-actions-width request-table-header-text" >Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(value, idx) in list" :key="value.id" class="request-table-row" :class="{ 'page-table-line-special': idx % 2 != 0 }">
                    <th class="request-table-cell request-table-cot-width">{{ value.cot_ressuprimento_id }} <br> {{ value.cot_solicitacao_id }}</th>
                    <th class="request-table-cell">{{ value.cot_ressuprimento.cot_dataCadastro | formatDate }}</th>
                    <th class="request-table-cell desktop">{{  value.cot_ressuprimento.cot_qtd_produtos}}</th>
                    <th class="request-table-cell" >{{ value.store.loj_nomeFantasia }}</th>
                    <th class="request-table-cell">
                        <span class="material-icons-outlined request-table-consult-icon" title="Consulta" @click="consult(value)">manage_search</span>
                        <span v-if="value.lista_mobile" @click="()=>mobileList=[value.lista_mobile.list_id]" class="material-icons-outlined request-table-mobile-icon" title="Mobile">phone_iphone</span>
                        <!-- <StandardButton class="request-table-button" :action="consult" :iconName="'manage_search'"
                            :text="'Consulta'" /> -->
                    </th>
                </tr>
            </tbody>
        </table>

        <ModalProductsListMobile
                                 :listMobile="mobileList"
                                 v-if="mobileList"
                                 @close="()=>mobileList=undefined"/>

        <SelectedProductModal :list="selectedProductList" :title="modalTitle" :closeDialog="()=>selectedProductList=undefined" v-if="selectedProductList"/>
        

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import QuotationService from "@/services/QuotationService";
import ModalProductsListMobile from "@/components/cliente/monitors/ModalProductsListMobile";
import SelectedProductModal from './selectedProductModal.vue'
import V3OrderService from "@/services/v3/products/order.service"

export default {
    name: 'RequestedProductsTable',
    props: ['quotation'],
    components: {
        StandardButton,
        ModalProductsListMobile,
        SelectedProductModal
    },
    data() {
        return {
            service: new QuotationService(),
            orderService: new V3OrderService(),
            selectedProductList: undefined,
            isLoading: true,
            mobileList: undefined,
            modalTitle: '',
            list: []
        }
    },
    methods: {
        consult(value) {
            this.isLoading = true
            this.service.getProductsResupply(value.cot_ressuprimento_id).then(resp => {
                this.isLoading = false
                this.selectedProductList = resp.data.produtos
                this.modalTitle = value.cot_ressuprimento_id +' -- '+ value.store.loj_nomeFantasia
            })
        }
    }, 
    created() {
        this.orderService.getResuplyList(this.quotation).then(resp => {
            this.list = resp.data.sort((a, b) => a.store.loj_nomeFantasia.localeCompare(b.store.loj_nomeFantasia))
            this.isLoading = false
        })
    }
}
</script>

<style scoped lang="scss">
.request-table-container {
    margin: 20px;
    overflow: auto;
    max-height: 70vh;
    scrollbar-width: unset;
}

.request-table-body {
    outline: red;
}

.request-table-header {
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    
}
.request-table-header-text{
    color: #605F5F;
    font-size: 1.2em;
    font-weight: 600;
}

.request-table-date-width {
    width: 18%;
}

.request-table-number-width {
    width: 17%;
}

.request-table-company-width {
    width: 30%;
}

.request-table-actions-width {
    width: 10%;
}

.request-table-row {
    color: #898888;
    font-size: 1em;
}

.request-table-cell {
    padding: 1vh 0 !important;
    font-size: 1.1em;
    color: #605F5F;
    font-weight: 300;
    border-bottom: none;
}

.request-table-cot-width {
    flex: 1;
    padding-left: 2% !important;
}

.request-table-button {
    height: 5vh;
}
.request-table-consult-icon{
    color: var(--primary-color);
    font-size: 2em;
    cursor: pointer;
}
.request-table-mobile-icon{
    color: lightgreen;
    font-size: 1.8em;
    margin-left: 10%;
    cursor: pointer;
}
.empty-alert{color: lightgray; text-align: center;}
.page-table-line-special {
  background: #F7F7F7;
}
@media only screen and (max-width: 850px) {
    .desktop{display: none;}
    .request-table-date-width{
        width: 30%;
    }
    .request-table-actions-width{
        width: 20%;
    }
}
</style>