import { mapGetters } from 'vuex'

export const perm_mixin = {
    data() {
        return {
        }
    },
    methods: {
        can(resource, action) {
            return (!this.perms || this.perms.length == 0) || this.perms.some(perm => perm.resource == resource && perm.action == action)
        }
    },
    computed: {
        ...mapGetters({ perms: 'user/perms' })
    },
    mounted() {
        this.$store.dispatch('user/load_perms')
    }
}