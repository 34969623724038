<template>
    <div class="invitation-button-container">
        <span class="material-icons-outlined rotate-loading-icon">refresh</span>
    </div>
</template>

<script>
export default {
    name: 'InvitationButtonLoading',
}
</script>

<style scoped lang="scss">
.invitation-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    border: 1px solid #E5E5E5;
    background-color: #FAFAFC;
    color: #505050;
    padding: 1.3vh 2vw;
    border-radius: 10px;
    cursor: pointer;
    span{
        color: var(--primary-color);
        animation: rotate 1s infinite;
    }
}
@keyframes rotate {
  0% {
    transform: rotate(-180deg)
  }
  100% {
    transform: rotate(180deg)
  }
}
</style>