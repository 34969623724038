export default {
    namespaced: true,
    state: {
        modules: [
            {
                name: "Relatórios",
                resource: "REPORT",
                action: "READ",
                description: "acessar menu de relatórios",
                permissions: [
                    {
                        name: "Acesso amplo",
                        resource: "REPORT",
                        action: "READ_ALL",
                        description: "acessar relatório de todos os vendedores"
                    }
                ]
            },
            {
                name: "Configurações",
                resource: "PROVIDER",
                action: "UPDATE",
                description: "editar configurações do fornecedor"
            },
            {
                name: "Perfis",
                resource: "PROFILE",
                action: "MANAGE",
                description: "gerenciar perfis do fornecedor"
            },
            {
                name: "Empresas Compradoras",
                action: "READ",
                resource: "CLIENT_COMPANY",
                description: "acessar menu de empresas compradoras",
                permissions: [
                    {
                        name: "Acesso amplo",
                        resource: "CLIENT_COMPANY",
                        action: "READ_ALL",
                        description:
                            "consultar todas as empresas compradoras do fornecedor"
                    },
                    {
                        name: "Acesso completo",
                        resource: "CLIENT_COMPANY",
                        action: "READ_OTHERS",
                        description:
                            "consultar todas as empresas compradoras do sistema"
                    }
                ]
            },
            {
                name: "Produtos",
                action: "READ",
                resource: "PRODUCT",
                description: "acessar menu de produtos da venda direta",
                permissions: [
                    {
                        name: "Leitura",
                        action: "READ",
                        resource: "PRODUCT",
                        description: "acessar menu de produtos da venda direta",
                    },
                    {
                        name: "Atualizar",
                        action: "UPDATE",
                        resource: "PRODUCT",
                        description: "atualizar produtos da venda direta",
                    },
                    {
                        name: "Criar",
                        action: "CREATE",
                        resource: "PRODUCT",
                        description: "criar produtos da venda direta",
                    },
                    {
                        name: "Níveis de preço",
                        action: "PRICING",
                        resource: "PRODUCT",
                        description: "editar niveis de preço de produtos da venda direta",
                    },
                    {
                        name: "Adicionar preço",
                        action: "ADD_PRICE",
                        resource: "PRODUCT",
                        description: "adicionar nivel de preço em produtos da venda direta",
                    },
                    {
                        name: "Gerenciar Grupos",
                        action: "MANAGE_GROUPS",
                        resource: "PRODUCT",
                        description: "gerenciar grupos de produtos da venda direta",
                    },
                    {
                        name: "Remover",
                        action: "DELETE",
                        resource: "PRODUCT",
                        description: "remover produtos da venda direta",
                    },
                    {
                        name: "Ativar/Desativar",
                        action: "ACTIVATE_DEACTIVATE",
                        resource: "PRODUCT",
                        description: "ativar/desativar produtos da venda direta",
                    }
                ]
            },
            {
                name: "Pedidos",
                action: "READ",
                resource: "REQUEST_ORDER",
                description: "acessar menu de produtos",
                permissions: [
                    {
                        name: "Acesso amplo",
                        resource: "REQUEST_ORDER",
                        action: "READ_ALL",
                        description: "acessar pedidos de todos os vendedores"
                    }
                ]
            },
            {
                name: "Venda direta",
                action: "READ",
                resource: "DIRECT_PURCHASE",
                description: "acessar menu de venda direta",
                permissions: [
                    {
                        name: "Acesso amplo",
                        resource: "DIRECT_PURCHASE",
                        action: "READ_ALL",
                        description: "acessar pedidos de todos os vendedores"
                    },
                    {
                        name: "Atualizar",
                        resource: "DIRECT_PURCHASE",
                        action: "UPDATE",
                        description: "atualizar dados da compra direta"
                    }
                ]
            },
            {
                name: "Cotações",
                action: "READ",
                resource: "REQUEST",
                description: "acessar menu de cotações",
                permissions: [
                    {
                        name: "Acesso amplo",
                        resource: "REQUEST",
                        action: "READ_ALL",
                        description:
                            "acessar cotações de todos os vendedores do fornecedor"
                    },
                    {
                        name: "Acesso completo",
                        resource: "REQUEST",
                        action: "READ_OTHERS",
                        description:
                            "acessar cotações de todos os clientes do sistema"
                    },
                    {
                        name: "Adicionar vendedor",
                        resource: "REQUEST",
                        action: "ADD_SELLER",
                        description: "adicionar vendedores a cotação"
                    },
                    {
                        name: "Histórico de Ofertas",
                        resource: "REQUEST_PRODUCT",
                        action: "READ_HISTORY",
                        description:
                            "visualizar histórico de offertas de itens da cotação"
                    }
                ]
            },
            {
                name: "Campanhas",
                action: "READ",
                resource: "CAMPAIGN",
                description: "acessar menu de campanhas"
            },
            {
                name: "Usuário",
                action: "READ",
                resource: "USER",
                permissions: [
                    {
                        name: "Atualizar perfil",
                        action: "UPDATE_PROFILE",
                        resource: "USER",
                        description: "atualizar perfil",
                    },
                    {
                        name: "Atualizar grupo",
                        resource: "USER",
                        action: "UPDATE_GROUP",
                        description: "atualizar grupo"
                    }
                ]
            },
        ],
        client_modules: [
            {
                name: "Produtos",
                action: "READ",
                resource: "PRODUCT",
                description: "acessar menu de produtos",
                permissions: [
                    {
                        name: "Incluir / Atualizar",
                        resource: "PRODUCT",
                        action: "UPDATE",
                        description: "Inclusão e manutenção"
                    },
                    {
                        name: "Remover",
                        resource: "PRODUCT",
                        action: "DELETE",
                        description: "remover produtos"
                    }
                ]
            },
            {
                name: "Meus Fornecedores",
                resource: "SELLER",
                action: "READ",
                description: "editar configurações do fornecedor",
                permissions: [
                    {
                        name: "Grupos e Convites",
                        resource: "CLIENT_SELLER",
                        action: "UPDATE",
                        description: "editar grupos e convites"
                    }
                ]
            },
            
            
            
            {
                name: "Mapa de Fornecedores",
                action: "READ_PROVIDERS_MAP",
                resource: "MAP",
                description: "acessar menu explorar",
            },
            {
                name: 'Nova Compra/Requisição',
                description: "acessar menu de compra/requisição",
                resource: undefined,
                action: undefined,
                permissions: [
                    {
                        name: "Criar cotação",
                        action: "CREATE",
                        resource: "REQUEST",
                        description: "acessar menu de compra/requisição"
                    },
                    {
                        name: "Criar Ressuprimento",
                        resource: "REQUEST",
                        action: "CREATE_RESUPPLY",
                        description: "criar cotação do tipo ressuprimento"
                    },
                    {
                        name: "Editar ressuprimento",
                        action: "UPDATE_RESUPPLY",
                        resource: "REQUEST",
                        description: "Acessar tela de ressuprimento de produtos"
                    }
                ]
            },
            
            {
                name: "Minhas Cotações",
                action: "READ",
                resource: "REQUEST",
                description: "acessar menu de cotações",
                permissions: [
                    {
                        name: "Remover ressuprimento",
                        resource: "REQUEST",
                        action: "DELETE_RESUPPLY",
                        description: "apagar cotações do tipo ressuprimento"
                    },
                    {
                        name: "Atualizar cotacao",
                        resource: "REQUEST",
                        action: "UPDATE",
                        description:
                            "alterar dados da cotacao, ex: prazo, congelamento..."
                    },
                    {
                        name: "Visualizar Vendedores",
                        resource: "REQUEST",
                        action: "READ_SELLERS",
                        description: "acessar vendedores da cotação"
                    },
                    {
                        name: "Reenviar notificação",
                        resource: "REQUEST",
                        action: "SEND_NOTIFICATION",
                        description:
                            "reenviar notificação da cotacao para os vendedores"
                    },
                    {
                        name: "Editar preços",
                        resource: "REQUEST",
                        action: "MANAGE_OFFER",
                        description: "alterar valor das ofertas da cotação"
                    },
                    {
                        name: "Relatório da cotação",
                        resource: "REQUEST",
                        action: "SEE_REPORT",
                        description: "acessar relatório da cotação"
                    },
                    {
                        name: "Visualizar pré-pedidos",
                        resource: "REQUEST",
                        action: "READ_ALL_SUBREQUESTS",
                        description: "acessar todos os pré-pedidos da cotação"
                    },
                    {
                        name: "Visualizar apenas cotações enviadas",
                        resource: "MY_QUOTE",
                        action: "READ",
                        description: "Visualizar apenas cotações enviadas"
                    },
                    {
                        name: "Produtos da Cotação",
                        action: "READ",
                        resource: "REQUEST_PRODUCT",
                        description: "acessar produtos da cotação"
                    }
                ]
            },
            
            
            {
                name: "Pedidos",
                action: "READ",
                resource: "REQUEST_ORDER",
                description: "acessar menu de cotações",
                permissions: [
                    {
                        name: "Criar Pedidos",
                        resource: "REQUEST_ORDER",
                        action: "CREATE",
                        description: "criar pedidos"
                    },
                    {
                        name: "Acesso amplo",
                        resource: "REQUEST_ORDER",
                        action: "READ_ALL",
                        description: "acessar pedidos de todos os vendedores"
                    },
                    {
                        name: "Visualizar Apenas minha Empresa",
                        resource: "MY_COMPANY",
                        action: "READ",
                        description: "Pre-Pedidos / Pedidos / CheckBox Item"
                    }
                ]
            },
            {
                name: "Relatórios",
                resource: "REPORT",
                action: "READ",
                description: "acessar menu de relatórios"
            },
            {
                name: "Ofertas",
                action: "READ",
                resource: "CAMPAIGN",
                description: "acessar menu de campanhas"
            },
            {
                name: "Minhas Configurações",
                action: "READ",
                resource: "ACCOUNT_SETTINGS",
                description: "acessar configurações da conta"
            },
        ]
    }
};
