<template>
    <div class="orders-tab-container">
        <ProductsTable v-if="products.length > 0" :date="date" :total="totalPedido" :categories="categories" :products="products"/>
        <Pagination classes="orange" :items_by_page_value="20" :total="total_items" :page="page" :page_limit="5"
            v-on:change="updatePage($event)" :skipScroll="true">
        </Pagination>
    </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import ProductsTable from "./ProductsTablev2.vue";
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'ordersTab',
    props: {
        svc: {
            type: Object
        },
        date: {
            type: Object
        },
        setLoading: {
            type: Function
        },
        categories:{
            type: Array
        }
    },
    components: {
        Pagination,
        ProductsTable
    },
    data() {
        return {
            products: [],
            total_items: undefined,
            totalPedido: 0,
            page: 1
        }
    },
    mounted() {
        this.loadPage()
    },
    methods: {
        loadPage() {
            this.setLoading(true)
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=p.pro_id&ordenar=valorTotal&tipoorder=desc&page=1` +
                "&dateIni=" + this.date.primeiro_dia
                +
                "&dateFim=" + this.date.ultimo_dia
            axios.get(requestUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(resp => {
                this.products = resp.data.produtos.data
                this.total_items = resp.data.produtos.total
                this.totalPedido = resp.data.totalProdutos.valorTotal
                            ? resp.data.totalProdutos.valorTotal
                            : 0;
                this.setLoading(false)
            });
        },
        updatePage(page){
            this.page = page
            this.loadPage()
        }
    },
}
</script>

<style lang="scss" scoped>
.orders-tab-container {
    height: 70vh;
    padding: 2vh 3vw;
}
</style>