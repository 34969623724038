<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="modal-content-header">
        <div>Editar Filtro</div>
        <span class="material-icons modal-close-icon" v-on:click="close()">close</span>
      </div>
      <div class="modal-content-body">
        <div class="modal-input-title">Nome do filtro</div>
        <div class="input-row">
          <input v-if="editName" class="modal-input" v-model="filter.filterName" type="text" placeholder="Nome do filtro">
          <div v-else class="modal-input-text">{{filter.filterName}}</div>
          <span v-if="editName" v-on:click="editName = false" class="material-icons modal-input-edit-icon">close</span>
          <span v-else v-on:click="editName = true" class="material-icons modal-input-edit-icon">edit</span>
        </div>
        <div class="modal-input-title">Período</div>
        <div class="input-row">
          <div class="modal-input-text">{{filter.selectedPeriod.text}}</div>
          <span v-if="showSelectBody" v-on:click="showSelectBody = false" class="material-icons modal-input-edit-icon">close</span>
          <span v-else v-on:click="showSelectBody = true" class="material-icons modal-input-edit-icon">edit</span>
        </div>
        <div v-if="showSelectBody">
          <div class="select-body">
            <div v-for="period in periodList" v-on:click="selectPeriod(period)" :key="period.text" class="select-body-line">
              <div class="select-body-line-text">{{period.text}}</div>
            </div>
          </div>
        </div>
        <div class="modal-input-title">Produtos</div>
        <div class="modal-product-row" v-for="product in filter.selectedProducts" :key="product.id">
          <span v-if="product.isCheck" v-on:click="product.isCheck = !product.isCheck" class="material-icons modal-product-row-icon">check_box</span>
          <span v-else v-on:click="product.isCheck = !product.isCheck" class="material-icons modal-product-row-icon">check_box_outline_blank</span>
          <div>{{product.descricao}}</div>
        </div>

        <div class="modal-body-buttons-container">
          <div class="modal-body-button-cancel" v-on:click="close()">Cancelar</div>
          <div class="modal-body-button-save" v-on:click="initSaveFilter()">Salvar </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFilterModal',
  props: ['close', 'filter'],
  data () {
    return {
      editName: false,
      periodList: [{
        text: 'Ultima semana',
        value: 7
      }, {
        text: 'Ultima quinzena',
        value: 15
      }, {
        text: 'Ultimo mês',
        value: 30
      }],
      showSelectBody: false,
      originalName: undefined
    }
  },
  mounted () {
    this.originalName = this.filter.filterName
  },
  methods: {
    selectPeriod (period) {
      this.filter.selectedPeriod = period
      this.showSelectBody = false
    },
    initSaveFilter () {
      let newProductList = []
      this.filter.selectedProducts.forEach(element => {
        if (element.isCheck){
          newProductList.push(element)
        }
      });
      this.filter.selectedProducts = newProductList
      let filters = JSON.parse(localStorage.getItem('filters'))
      for  (let i = 0; i < filters.length; i++) {
        if (filters[i].filterName == this.originalName) {
          filters[i] = this.filter
        }
      }
      setTimeout(() => {
        this.close()
        localStorage.setItem('filters', JSON.stringify(filters))
      }, 500)
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.modal-content-header{
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}
.modal-close-icon{
  background-color: white;
  color: var(--primary-color);
  font-size: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.modal-input-title{
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #898989;
}
.modal-content-body{
  border-radius: 0px 0px 10px 10px;
  padding: 2vh 2vw;
}
.input-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
  margin-top: 0.7vh;
}
.modal-input{
  width: 60%;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 1vh;
}
.modal-input:focus{
  outline: none;
}
.modal-body-buttons-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
}
.modal-body-button-cancel{
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  width: 30%;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-color);
  text-align: center;
  padding-top: 2px;
  margin-right: 5%;
  cursor: pointer;
}
.modal-body-button-save{
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 30%;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.modal-product-row{
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  font-weight: 300;
  font-size: 15px;
  color: #505050;
}
.modal-product-row-icon{
  color: var(--primary-color);
  margin-right: 1vw;
  cursor: pointer;
}
.modal-input-text{
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #404040;
}
.modal-input-edit-icon{
  color: #404040;
  cursor: pointer;
}
.select-body{
  position: absolute;
  background-color: white;
  width: 31%;
  overflow-y: scroll;
  max-height: 300px;
  border: 0.5px solid var(--primary-color);
  border-radius: 5px;
}
.select-body::-webkit-scrollbar {
  width: 7px;
}
.select-body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  height: 10px;
}
.select-body-line{
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}
.select-body-line:hover{
  background-color: rgba(255, 247, 247, 0.75);;
}
.select-body-line-text{
  font-weight: 300;
  font-size: 15px;
  color: #505050;
  margin-left: 5px;
}
@media only screen and (max-width: 800px) {
  .modal-content-container{
    max-width: 110vw;
  }
}
</style>
