<template>
  <div class="checkbox-containear" v-on:click="action()">
    <span v-if="value" class="material-icons checkbox-icon">check_box</span>
    <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
    <div class="checkbox-text">{{ text ? text :'Apenas comprados'}}</div>
  </div>
</template>

<script>
export default {
  name: 'StandardCheckBox',
  props: ['value', 'action', 'text']
}
</script>

<style lang="scss" scoped>
.checkbox-containear{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-text{
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: #202020;
  margin-left: 10px;
}
.checkbox-icon{
  color: var(--primary-color);
  font-size: 2em;

}
</style>
