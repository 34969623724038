<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .edit-amount {
        margin: auto;
        width: 200px;
        text-align: center;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .header-title {
        padding-right: 23px;
        text-align: left;
        color: gray;
        font-size: 22px;
    }

    .actions {
        i {
            color: $brand-danger !important;
            margin: 0 1rem;
            font-size: 1rem;
            cursor: pointer;
        }
    }
    .import-products-modal{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
    }
    .import-products-modal-content{
        background-color: white;
        border-radius: 10px;
        height: 90%;
        width: 80%;
        display: flex;
        flex-direction: column;
    }
    .import-product-header{
        height: 8%;
        background-color: var(--primary-color);
        border-radius: 9px 9px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .import-product-header-text{
        margin-left: 1.5vw;
        font-weight: 500;
        font-size: 2.1em;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
    .import-product-header-close{
        color: white;
        font-size: 2.7em;
        cursor: pointer;
        margin-right: 1.5vw;
    }
    .import-product-inputs-container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 2vh 2vw;
    }
    .import-product-table-header{
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        margin-top: 2vh;
        font-weight: 600;
        font-size: 1.19em;
        color: #605F5F;
        display: flex;
        padding: 1vh 0;
        margin: 0 2vw;
    }
    .import-product-table-ean{margin-left: 1vw; width: 10%;}
    .import-product-table-name{flex: 1;}
    .import-product-table-wrap{width: 15%;}
    .import-product-table-category{width: 12%;}
    .import-product-table-quantity{width: 10%;}
    .import-product-table-stock{width: 10%;}
    .import-product-table-obs{flex: 1;}
    .import-product-table-action{width: 10%;}
    .import-product-table-row{
        display: flex;
        align-items: center;
        margin: 1.5vh 2vw;
        color: #605F5F;
    }
    .import-product-table-name-input{
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 1vh 1vw;
        width: 80%;
        &:focus{
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            outline: none;
        }
    }
    .import-product-table-wrap-input{
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 1vh 1vw;
        width: 30%;
        margin-left: 0.5vw;
        &:focus{
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            outline: none;
        }
    }
    .import-product-table{overflow: auto; padding-bottom: 15vh;}
    .import-product-table-select{
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 60%;
        padding: 0.8vh 0;
    }
    .import-product-table-select-category{
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        padding: 0.8vh 0;
    }
    .import-product-table-select-body{
        position: absolute;
        z-index: 2;
        background-color: white;
        border: 1px solid var(--primary-color);
        border-radius: 8px;
        padding: 1vh 0;
        color: var(--primary-color);
        font-size: 1.2em;
        cursor: pointer;
    }
    .import-product-table-select-body-text{
        padding: 0 2vw;
        &:hover{
            background-color: var(--primary-color);
            color: white;
        }
    }
</style>
<template>
    <!-- <cc-modal :modal="modal" @close="close" id="products-modal">

        <div slot="body" class="row" style="min-height: 25rem;">
            <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                <h4 style="padding-right: 23px;text-align: left;color: gray;">Produtos não encontrados</h4>
            </div>
            <div class="col-12" style="padding-bottom: 15rem;">
                <div class="col-3 pb-3">
                    <cc-select-v2 :placeholder="'Selecionar categoria para todos'" :tracker="'cat_codInterno'" :text="'cat_descricao'" v-model="category" :options="categories" @select="set_category"></cc-select-v2>
                </div>
                <ValidationObserver ref="form" slim>
                <table class="table">
                    <thead>
                        <tr>
                            <th class="pl-4 text-left" style="width:10%">EAN</th>
                            <th class="pl-4" style="width:25%">Nome</th>
                            <th class="text-left">Embalagem</th>
                            <th class="text-center">Categoria</th>
                            <th class="text-center" style="width:6%">Qtd.</th>
                            <th class="text-center" style="width:6%">Estoque</th>
                            <th class="text-center">Observação</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="categories && processed" style="padding-bottom: 15rem;">
                        <ProductComponent
                        v-for="item in products" v-bind:key="item.produto_id"
                        :wraps="wraps" :categories="categories" @remove="remove"
                        :item="item" />
                    </tbody>
                </table>
                </ValidationObserver>
            </div>
        </div>
    </cc-modal> -->
    <div class="import-products-modal">
        <div class="import-products-modal-content">
            <div class="import-product-header">
                <div class="import-product-header-text">Importar Novos Produtos</div>
                <span class="material-icons import-product-header-close" v-on:click="close()">cancel</span>
            </div>
            <div class="import-product-inputs-container">
                <StandardInputListFiltered :action="set_category" :title="'Categoria'" :width="'40vw'" :selectWidth="'39.6vw'" :placeholder="'Selecione Categoria para todos'" :list="categories"/>
                <StandardButton :action="save" :iconName="'save'" :text="'Salvar Alterações'"/>
            </div>
            <div class="import-product-table">
                <div class="import-product-table-header">
                    <div class="import-product-table-ean">EAN</div>
                    <div class="import-product-table-name">Nome</div>
                    <div class="import-product-table-wrap">Embalagem</div>
                    <div class="import-product-table-category">Categoria</div>
                    <div class="import-product-table-quantity">QTD.</div>
                    <div class="import-product-table-stock">Estoque</div>
                    <div class="import-product-table-obs">Observação</div>
                    <div class="import-product-table-action">Ação</div>
                </div>
                <div v-if="categories && processed" v-for="item in products" v-bind:key="item.produto_id" class="import-product-table-row">
                    <div class="import-product-table-ean">{{ item.pro_ean}}</div>

                    <div class="import-product-table-name">
                        <input class="import-product-table-name-input" v-model="item.pro_descricao">
                    </div>
                    <div class="import-product-table-wrap">
                        <div style="display: flex;">
                            <div class="import-product-table-select" v-on:click="toogleWrapExpand(item)" style="cursor: pointer">
                                <div>{{item.wrap.name}}</div>
                                <span class="material-icons-outlined">expand_more</span>
                            </div>
                            <input type="number" v-model="item.qtd_emb" class="import-product-table-wrap-input">
                        </div>
                        <div style="position: relative;">
                            <div v-if="item.isWrapExpanded" class="import-product-table-select-body">
                                <div class="import-product-table-select-body-text" v-on:click="selectWrap(item, wrap)" v-for="wrap in wraps" :key="wrap.name">{{wrap.name}}</div>
                            </div>
                        </div>

                    </div>
                    <div class="import-product-table-category">
                        <div class="import-product-table-select-category" v-on:click="toogleCategoryExpand(item)" style="cursor: pointer">
                            <div>{{item.category ? item.category.text : ''}}</div>
                            <span class="material-icons-outlined">expand_more</span>
                        </div>
                        <div style="position: relative;">
                            <div v-if="item.isCategoryExpanded" class="import-product-table-select-body">
                                <div class="import-product-table-select-body-text" v-on:click="selectCategory(item, cat)" v-for="cat in categories" :key="cat.text">{{cat.text}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="import-product-table-quantity">
                        <input class="import-product-table-name-input" type="number" v-model="item.quantidade">
                    </div>
                    <div class="import-product-table-stock">
                        <input class="import-product-table-name-input" type="number" v-model="item.estoque">
                    </div>
                    <div class="import-product-table-obs">
                        <input class="import-product-table-name-input" v-model="item.pro_observacao">
                    </div>
                    <div class="import-product-table-action">
                        <span class="material-icons-outlined" style="color: #CB4243; cursor: pointer;" @click="remove(item)">delete</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import RequestsService from "@/services/v2/requests.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import CategoryService from "@/services/v1/category.service";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import ProductComponent from './_product.component'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'


export default {
    mixins: [ loaderMixin ],
    props: { products: [ Array ] },
    components: { ValidationProvider, ValidationObserver, ProductComponent, StandardInputListFiltered, StandardButton },
    data() {
        return {
            loading: false,
            svc: new RequestsService(),
            cat_svc: new CategoryService(),
            categories: [],
            category: null,
            processed: false,
            wraps: [
                { name: 'Atado', value: 'AT' },
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Display', value: 'DP' },
                { name: 'Duzia', value: 'DZ' }
            ],
            modal: {
                title: `Importar novos produtos`,
                subtitle: 'Adicione os produtos de sua planilha que não estão na sua base',
                icon_title: 'fas fa-boxes',
                permitted: true,
                cancel_text: "Fechar",
                submit: this.save,
                style: {
                    width: "90%",
                    "min-height": "80%"
                }
            }
        };
    },
    methods: {
        selectCategory(item, cat){
            item.category = cat
            item.isCategoryExpanded = false
            this.$forceUpdate()
        },
        selectWrap(item, wrap){
            item.wrap = wrap
            item.isWrapExpanded = false
            this.$forceUpdate()
        },
        toogleWrapExpand(item){
            item.isWrapExpanded = !item.isWrapExpanded
            this.$forceUpdate()
        },
        toogleCategoryExpand(item){
            item.isCategoryExpanded = !item.isCategoryExpanded
            this.$forceUpdate()
        },
        set_category(category) {
            this.products.forEach(i => this.$set(i, 'category', {
                        text: category.cat_descricao,
                        value: category
                    }))
            this.$forceUpdate()
        },
        close() {
            this.$emit("close");
            this.$emit("reload");
        },
        save() {
            this.present_loader("Importando novos produtos...")
            this.products.forEach(prod => prod.cat_id = prod.category ? prod.category.value.cat_id : null)
            this.products.forEach(prod => prod.pro_embalagem = prod.wrap ?  `${prod.wrap.value} ${prod.qtd_emb}` : null)
            this.svc.import_products(this.products)
            .then(response => {
                this.dismiss_loader()
                this.close();
                this.$swal.fire( 'Sucesso!', 'Produtos importados com sucesso.', 'success');
            })
        },
        remove(item) {
            this.confirm_action({
				message: "Remover produto?",
				callback: () => this.products = this.products.filter(i => i.pro_ean != item.pro_ean)
			})
        },
        load_categories() {
            this.present_loader("Carregando suas categorias...")
			return this.cat_svc.all()
			.then(response => response.data)
			.then(data => {
				// this.categories = data.categorias;
                data.categorias.forEach(cat => {
                    this.categories.push({
                        text: cat.cat_descricao,
                        value: cat
                    })
                })
				this.dismiss_loader()
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
        },
        process_categories() {
          	this.products.forEach(i => i.category = this.categories.find(cat => cat.cat_codInterno == i.cat_id))
        },
        process_wraps() {

              this.products.filter(i => i.pro_embalagem.split(" ").length > 1)
              .forEach(i => i.wrap = this.wraps.find(w => w.value == i.pro_embalagem.split(" ")[0]))

              this.products.filter(i => i.pro_embalagem.split(" ").length > 1 && !isNaN(i.pro_embalagem.split(" ")[1]))
              .forEach(i => i.qtd_emb = i.pro_embalagem.split(" ")[1])
              this.processed = true
        }
    },
    beforeMount() {
        this.load_categories().then(this.process_categories).then(this.process_wraps)
    }
};
</script>
