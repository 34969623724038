import http from './Http';
import Service from './Service';

/**
 * @type QuotationService
 */
export default class ProviderService extends Service {

    list( quotationId ) {
        return http.get(
            'getfornecedoresprodutofiltro', {
                headers: this.getHeaders(),
                timeout: 1200000,
                params: {
                numeroCotacao: quotationId
                }
            }
        );
    }

    get_evaluation(seller_id) {
        return http.get(
            'getavaliacaovendedor', {
                headers: this.getHeaders(),
                timeout: 1200000,
                params: {
                    id_vendedor: seller_id
                }
            }
        );
    }

    get_sellers(provider_id) {
        return http.get(
            'getclientesvendedor', {
                headers: this.getHeaders(),
                timeout: 1200000,
                params: {
                    vendedor_id: provider_id
                }
            }
        );
    }


    remove_seller(seller_id) {
        return http.delete(
            `providers/${seller_id}`, {
                headers: this.getHeaders()
            }
        );
    }

    syncProvider (id, type) {
        return http.put(
            `providers/${id}/alter_stype`, {
                stype: type
            }, {
                headers: this.getHeaders()
            }
        );
    }

}
