<template>
  <div class="modal-mask">
    <cc-loader style="margin-right: -35vw" v-show="loading" />
    <div class="modal-content-container">
      <div v-show="!loading && providerInfo" class="provider-details__main-container">
        <div class="provider-details__header-container">
          <span class="provider-details__header-title">
            Detalhes do Fornecedor
          </span>
          <span @click="closeDialog" class="material-icons provider-details__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="provider-details__info-container">
          <div class="provider-details__image-wrapper" >
            <!-- <div class="provider-details__image" :style="{ backgroundImage: `url(${providerInfo.cover_url})` }"> -->
            <img class="provider-details__image" :src="providerInfo.cover_url">
            <!-- </div> -->
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">Nome: </span>
            <span class="provider-details__product-info">{{ providerInfo.name }}</span>
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">CNPJ: </span>
            <span class="provider-details__product-info">{{ providerInfo.cnpj | cnpj }}</span>
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">Contato: </span>
            <span class="provider-details__product-info">{{ providerInfo.contact[0].toUpperCase() + providerInfo.contact.substr(1) }}</span>
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">Telefone: </span>
            <span class="provider-details__product-info">{{ providerInfo.phone || "" | VMask('(##) #####-####') }}</span>
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">Faturamento minimo: </span>
            <span class="provider-details__product-info">{{ providerInfo.minimum_billing | currency }}</span>
          </div>
          <div class="provider-details__product-info-wrapper">
            <span class="provider-details__product-title">E-mail: </span>
            <span class="provider-details__product-info">{{ providerInfo.email }}</span>
          </div>
          <div class="provider-details__back-btn-wrapper">
            <div @click="closeDialog" class="provider-details__back-btn">
              <span class="provider-details__back-btn-text">Voltar</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'providerDetailsModal',
    props: {
      closeDialog: {
        type: Function,
      },
      providerInfo: {
        type: Object,
      }
    },
    data() {
      return {
        loading: false,
      };
    }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 60vw;
  border-radius: 12px;
}
.provider-details__main-container {
  width: 60vw;
  min-height: 90vh;
}
.provider-details__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.provider-details__header-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}
.provider-details__closebtn-icon {
  color: #FFFFFF;
  font-size: 30px;
  cursor: pointer;
}
.provider-details__info-container {
  margin: 20px 25px;
}
.provider-details__image-wrapper {
  display: flex;
  justify-content: center;
}
.provider-details__image {
  height: 28vh;
}
.provider-details__product-info-wrapper {
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0px;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.provider-details__product-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #707070;
}
.provider-details__product-info {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.15px;
  color: #a1a1a1;
}
.provider-details__back-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0px;
}
.provider-details__back-btn {
  background-color: var(--primary-color);
  padding: 10px 40px;
  border-radius: 4.19px;
  cursor: pointer;
}
.provider-details__back-btn-text {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .provider-details__main-container {
    width: 110vw;
  }
  .provider-details__header-title {
    font-size: 20px;
  }
}
</style>
