import Router from 'vue-router'
import SigninPage from '@/pages/signin/signin.page'

import HomePage from '@/pages/home/home.page'

import NewRequestPage from '@/pages/monitors/new/new.page'
import RequestWinners from '@/pages/monitors/winners/winners.page'
import Campaigns from "@/pages/campaigns/index.page";
import CampaignOrders from "./pages/campaigns/orders/index.page";
import MonitorsPage from '@/pages/monitors/monitors.page'
import RequestDetailsPage from '@/pages/monitors/details/details.page'
import OrderInfo from '@/pages/order-info/details.page'
import RequestPage from '@/pages/request/index.page'
import SubRequestPage from '@/pages/subrequest/index.page'
import RequestMatrixDetailsPage from '@/pages/monitors/details/details-matrix.page'
import priceManager from '@/pages/monitors/priceManager.vue'
import RequestProvidersWithoutOffer from '@/pages/monitors/providers/without-offer.page'
import DirectRequest from './pages/orders/show.page'
import Divegent from './pages/orders/DivergentPage.vue'
import NotFoundPage from '@/pages/errors/not-found.page'
import SellersPage from '@/pages/sellers/sellers.page'
import ProductsPage from '@/pages/products/products.page'
import ReportsPage from '@/pages/reports/reports.page'
import SettingsPage from '@/pages/user/settings.page'
import ResumeOrder from '@/pages/orderResume/OrderResumeView.vue'
import ExplorePage from '@/pages/maps/explore.page'
import DashboardPage from '@/pages/DashboardPage/DashboardPage.vue'
import HighlightsPage from '@/pages/products/highlights/index.page'
import CompraDireta from './components/cliente/relatorios/CompraDireta'
import HomePageV2 from '@/pages/HomePage/HomePage.vue'
import inicio from '@/pages/inicio/inicio.vue'
import PublicOffer from '@/pages/PublicPage/PublicOffer.vue'
import ClientSuplier from '@/pages/monitors/ClientSuplier.vue'
import FoodServiceBrands from '@/pages/products/FoodServiceBrands.vue'
import Repurchase from '@/pages/repurchase/repurchase.vue'

let router = new Router({
    mode: "history",
    hash: false,
    routes: [
        { path: "/", name: "home", component: HomePage },
        { path: "/inicio", name: "Inicio", component: inicio },
        { path: "/Login/", name: "login", component: SigninPage },
        { path: "/ofertas", name: "campaigns-index", component: Campaigns },
        { path: "/explorar", name: "explore", component: ExplorePage },
        { path: "/destaques", name: "highlights", component: HighlightsPage },
        { path: "/ofertas/pedidos", name: "campaign-orders-index", component: CampaignOrders },
        { path: "/cliente/", name: "cliente", component: HomePage },
        { path: "/cotacoes/:id", name: "request", component: RequestPage, props: true },
        { path: "/cotacoes/:id/loja/:store_id/vendedor/:seller_id", name: "subrequest", component: SubRequestPage, props: true },
        {
            path: "/cliente/cotacao/nova",
            name: "cliente-new-request",
            component: NewRequestPage
        },
        {
            path: "/cliente/monitores/:tab",
            name: "monitors-page",
            component: MonitorsPage,
            props: true
        },
        {
            path: "/cliente/monitores/ressuprimento/edit",
            name: "EditSpecialResuplyPage",
            component: () => import('@/pages/monitors/EditSpecialResuply'),
            props: true
        },
        {
            path: "/cliente/recompra",
            name: "repurchase-page",
            component: Repurchase,
        },
        {
            path: "/cliente/fornecedor",
            name: "monitors-client-suplier",
            component: ClientSuplier
        },
        {
            path: "/cliente/monitores/vencedores/:id",
            name: "cliente-monitores-vencedores",
            component: RequestWinners
        },
        {
            path: "/cliente/monitores/pedidos/:id",
            name: "cliente-monitores-pedido",
            component: DirectRequest
        },
        {
            path: "/cliente/vencedores/:id/vendedor/:sellerid/loja/:sid/fornecedor/:providerid/divergent",
            name: "cliente-monitores-divergent",
            component: Divegent
        },
        {
            path:
                "/cliente/vencedores/:id/vendedor/:sellerid/loja/:sid/fornecedor/:providerid/:cnpj?",
            name: "lancamento-cliente-monitores-vencedores",
            component: RequestDetailsPage,
            props: true
        },
        {
            path: "/order-info/:id/vendedor/:sellerid/loja/:sid/fornecedor/:providerid/:cnpj?",
            name: "order-info",
            component: OrderInfo,
            props: true
        },
        {
            path:
                "/cliente/vencedores/:id/vendedor/:sellerid/fornecedor/:providerid",
            name: "request-matrix-overview",
            component: RequestMatrixDetailsPage,
            props: true
        },
        {
            path:
                "/cliente/vencedores/:id/vendedor/:sellerid/fornecedor/:providerid/price-manager",
            name: "requestPriceManager",
            component: priceManager,
            props: true
        },
        {
            path: "/cliente/monitores/:id/naodigitados",
            name: "nao-digitados-cotacao",
            component: RequestProvidersWithoutOffer
        },
        {
            path: "/relatorios",
            name: "relatorios",
            component: ReportsPage,
            props: true
        },
        {
            path: "/fornecedores/",
            name: "sellers",
            component: SellersPage
        },
        {
            path: "/produtos/marcas",
            name: "productsBrands",
            component: FoodServiceBrands
        },
        {
            path: "/produtos/:tab?",
            name: "products",
            component: ProductsPage
        },
        {
            path: "/compradireta/",
            name: "Compra-Direta",
            component: CompraDireta
        },
        {
            path: "/configuracoes/:tab?",
            name: "user-settings",
            component: SettingsPage
        },
        {
            path: "/resume-order/",
            name: 'resumeOrder',
            component: ResumeOrder
        },
        {
            path: "/dashboard/",
            name: 'Dashboard',
            component: DashboardPage
        },
        {
            path: "/home-page/",
            name: 'HomePage',
            component: HomePageV2
        },
        {
            path: "/club-de-ofertas/:filter?",
            name: 'PublicOffers',
            component: PublicOffer
        },
        {
            path: "404",
            name: "404",
            component: NotFoundPage
        },
        {
            path: "*",
            redirect: { name: "404" }
        }
    ]
});

import { store } from '@/store/store'
router.beforeEach((to, from, next) => {
    window.scrollTo(0,0)
    if(to.name == 'login' || to.name == 'PublicOffers') {
        next()
    } else {
        store.dispatch('user/load_user')
        next()
    }
})

export default router;
