export const loaderMixin = {
    methods: {
        snack(text, klass = 'success', icon = null) {
            this.$store.dispatch('notification/add', {
                type: klass,
                message: text,
                icon
            })
        },
        present_info(text) {
            this.dismiss_loader()
            this.$swal.fire({
                html: `<span>${text}</span>`,
                showConfirmButton: true,
                icon: 'success'
            })
        },
        confirmOrder(text) {
            this.dismiss_loader()
            this.$swal.fire({
                html: `<span>${text}</span>`,
                showConfirmButton: true,
                icon: 'success'
            }).then(result => {
                if (result.isConfirmed) this.$router.push('/ofertas/')
            })
        },
        present_error(text) {
            this.dismiss_loader()
            this.$swal.fire({
                html: `<span>${text}</span>`,
                showConfirmButton: true,
                icon: 'error'
            })
        },
        present_loader(text) {
            this.dismiss_loader()
            this.$swal.fire({
                html: `<img style="margin-right:10px;" src="/images/loader.svg"/><span>${text}</span>`,
                showCancelButton: false,
                showConfirmButton: false
            })
        },
        dismiss_loader() {
            this.$swal.close()
        },
        loader_msg(msg) {
            this.$swal.update({ html: `<img style="margin-right:10px;" src="/images/loader.svg"/><span>${msg}</span>` })
        },
        confirm_action(params) {
            this.$swal.fire({
                title: params.message,
                text: params.subtitle,
                html: params.html,
                icon: params.icon || 'question',
                showCancelButton: !params.hide_cancel_button && true,
                denyButtonText: params.deny_text,
                showDenyButton: params.deny_text,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: params.cancel_buttom_color || '#d33',
                confirmButtonText: params.confirm_text || 'Sim, continuar!',
                cancelButtonText: params.cancel_text || 'Cancelar!'
            }).then((result) => {
                if(result.value) {
                    params.callback(result);
                }
                if (result.isDenied) {
                    params.deny_callback(result)
                }
            })
        }

    }
}
