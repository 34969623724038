<template>
    <div class="container-fluid p-0">
        <form @submit.prevent="getListaPedidos">
        <div class="row align-items-center pb-4 pt-0 px-3">
            <div class="col-sm-6 col-md-3 col-lg">
                <label for="" class="label-control">Número do Pedido</label>
                <cc-search v-model="buscaPedido" :noicon="true" />
            </div>
            <div class="col-sm-6 col-md-3 col-lg">
                <label for="" class="label-control">Número da Cotação</label>
                <cc-search v-model="buscaCotacao" :noicon="true" />
            </div>
            <div class="col-md-2 col-lg">
                <label for="" class="label-control">Empresa</label>
                <cc-select-v2 :tracker="'loj_id'" :text="'loj_nomeFantasia'"
                    :disabled="!can('REQUEST_ORDER', 'READ_ALL')"
                    :options="arraySelectLoja" v-model="selectedLoja"></cc-select-v2>
            </div>
            <div class="col-md-3 col-lg">
                <label for="" class="label-control">Fornecedor</label>
                <cc-select-v2 :tracker="'for_id'" :text="'for_nome'"
                    :searchable="true"
                    :options="arraySelectFornecedor" v-model="selectedFornecedor"></cc-select-v2>
            </div>
            <div class="col-md-3 col-lg">
                <label for="" class="label-control">Status</label>
                <cc-select-v2 :tracker="'id'" :text="'name'"
                    :options="statuses" v-model="filters.status"></cc-select-v2>
            </div>
            <div class="col-md-3 col-lg-3 d-flex justify-content-around pt-1">
                <cc-button class="mt-4 w-100" @click="getListaPedidos()" :classes="'fixed secondary'" :icon="'fa fa-search fa-lg'"/>
                <cc-button class="mt-4 ml-2" :content="'Limpar Filtros'" @click="resetePedido()" :icon="'fa fa-trash'" :classes="'fixed danger-outline'" />
                <cc-button class="mt-4 ml-2" @click="print_many" :classes="'fixed'" :icon="'fa fa-print'"/>
                <a id="download-pdf" style="display:none;"></a>
            </div>
        </div>
        </form>
        <div class="table-responsive m-0">
            <cc-loader v-show="loading" />

            <no-data-available v-if="arrayPedido.length == 0 && !loading" :text="'Sua lista de pedidos enviados está vazia, pedidos enviados aos fornecedores serão listados aqui'" />

            <table class="table" v-show="!loading && arrayPedido.length > 0">
                <thead>
                    <tr>
                        <th class="px-3">
                            <cc-checkbox
                                v-model="check_all"
                            />
                        </th>
                        <th class="text-center">ID</th>
                        <th>Loja</th>
                        <th>Data</th>
                        <th>Cotação</th>
                        <th>Vendedor</th>
                        <th>Valor(R$)</th>
                        <th>Status</th>
                        <th>Data de Confirmação</th>
                        <th>Fornecedor</th>
                        <th class="text-center"><i class="fa fa-star-o fa-2x px-2" aria-hidden="true"></i></th>
                        <th class="text-center">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data,index) in arrayPedido" :key="data.id_pedido">
                        <td class="text-center px-3">
                            <cc-checkbox
                                :id="'checkbox-'+data.id_pedido"
                                @change="(evt) => data.selected = evt.target.checked"
                                :checked="data.selected"
                                :value="{id_pedido: data.id_pedido, id_cotacao: data.id_cotacao, id_loja: data.id_loja, usu_id: data.usu_id}"
                                type="checkbox"
                            />
                        </td>
                        <td class="text-center">{{ data.id_pedido }}</td>
                        <td>{{ data.loj_descricao }}</td>
                        <td>{{ data.data_geracao | moment("DD/MM/YYYY") }}</td>
                        <td>{{ data.id_cotacao }}</td>
                        <td>{{ data.vendedor }}</td>
                        <td>{{ data.valor_pedido | currency }}</td>
                        <td class="statuses">
                            <i v-if="data.status === 'C'" class="fa fa-check-circle-o okCheck" aria-hidden="true" title="Confirmado pelo fornecedor"></i>
                            <i v-else class="fa fa-times-circle-o notCheck" aria-hidden="true" title="Aguardando confirmação do fornecedor"></i>
                            <i v-if="data.status === 'C'" class="fas fa-truck" :class="{ delivered: data.status_recebido, pending: !data.status_recebido }"
                            :title="data.status_recebido ? 'Pedido recebido!' : 'Pedido ainda não foi recebido!'"
                            ></i>
                        </td>
                        <td>{{ data.data_confirmacao | moment("DD/MM/YYYY HH:mm") }}</td>
                        <td>{{ data.fornecedor ? data.fornecedor.for_nome : '' }}</td>
                        <td class="text-center">{{ data.nota }}</td>
                        <td class="text-center action-controls">
                            <router-link
                                v-if="data.order_id"
                                :to="{
                                    name: 'subrequest',
                                    params: {
                                        return_to: 'monitors-page',
                                        id: data.id_cotacao,
                                        seller_id: data.usu_id,
                                        store_id: data.id_loja,
                                        tab: 'pedidos',
                                        provider_id: data.for_id },
                                    }" tag="span">
                                <i style="cursor: pointer;padding-left: 4px;" class="fa fa-info-circle" aria-hidden="true" title="Consultar"></i>
                            </router-link>
                            <router-link v-else
                                :to="{
                                    name: 'lancamento-cliente-monitores-vencedores',
                                    params: {
                                        backRouteName: 'monitors-page',
                                        id: data.id_cotacao,
                                        sellerid: data.usu_id,
                                        sid: data.id_loja,
                                        providerid: data.for_id },
                                    query: { backRoute: 2 } }" tag="span">
                                <i style="cursor: pointer;padding-left: 4px;" class="fa fa-info-circle" aria-hidden="true" title="Consultar"></i>
                            </router-link>
                            <i class="fa fa-star" aria-hidden="true" title="Avaliar" @click="show(index)"></i>
                            <i style="cursor: pointer;padding-left: 4px;" class="fa fa-print" aria-hidden="true" title="Exportar Pdf" @click="print(data)"></i>
                            <i  v-show="data.status === 'C' && !data.status_recebido" class="fas fa-check-double" aria-hidden="true" title="Confirmar Recebimento" @click="confirm_delivery(data, index)"></i>
                            <i class="fas fa-sync-alt"
                                aria-hidden="true"
                                title="Enviar VF"
                                v-show="
                                    has_vf_integration &&
                                    (!data.vf_integration_status ||
                                    (data.vf_integration_status && data.vf_integration_status != 'INTEGRATED'))
                                "
                                @click="sync_order(data)"></i>
                            <i class="fas fa-check-square"
                                aria-hidden="true"
                                :title="'Sincronizado #' + data.external_reference"
                                v-show="
                                    has_vf_integration &&
                                    data.vf_integration_status &&
                                    data.vf_integration_status == 'INTEGRATED'
                                "></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row not-print">
                <cc-pagination
                    classes="orange justify-end"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="paginaAtual"
                    v-on:change="updatePage($event)"
                ></cc-pagination>
            </div>
        </div>
        <OrderPartial v-if="order_data" :order_data="order_data" />
        <modal name="hello-world" classes="v--modal modal-avaliar " styles="margin: 30px auto;left: 0px !important;position: inherit !important;" width="850px" height="600px" :clickToClose="false">
            <div class="container modalBorder">
                <div class="d-flex justify-content-end">
                    <h1 class="closeModal p-1" @click="hide">X</h1>
                </div>
                <h4 class="modalTitlulo pb-3 pt-2 text-center">
                    Como foi sua experiência com
                    <span class="modalTituloNome">{{indexArray}}</span> ?
                </h4>
                <div class="d-flex justify-content-center">
                <star-rating
                    @rating-selected="modalClick"
                    v-model="rating"
                    :star-size="50"
                    :show-rating="false"
                ></star-rating>
                </div>
                <div>
                <div class="d-flex justify-content-center py-3">
                    <h1 class="notaVendedor">{{notaVendedor}}</h1>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <h5 class="subTitulo">Escolha um ou mais problemas que você encontrou:</h5>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <form class="formModal">
                    <div class="form-group" v-for="(data,key) in arrayCheckbox" :key="data.id">
                        <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            :name="data.descricao"
                            :id="data.id"
                            :checked="checkbox[key] == data.id"
                            v-model="checkbox[key]"
                        />
                        <label class="custom-control-label" :for="data.id">{{data.descricao}}</label>
                        </div>
                    </div>
                    </form>
                </div>

                <div class="d-flex justify-content-center pb-2">
                    <h5 class="subTitulo">Sua avaliação é feita de forma anônima.</h5>
                </div>
                <div class="d-flex justify-content-center pt-2">
                    <button class="btnFeedback" @click="postAvaliacaoPedido">Enviar Feedback</button>
                </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>

import axios from "axios";
import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/OrderService";
import SellersService from "@/services/v2/sellers.service";
import OrderPartial from "@/components/partials/_print_order.partial";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import * as CONSTANTS from "@/constants/constants";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { perm_mixin } from '@/mixins/permission.mixin'
import VarejoFacilService from '@/services/VarejoFacilService';
import StorageService from '@/services/local-storage.service';

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: { ccPagination: Paginacao, OrderPartial },
    props: ["buscaCotacao"],
    data() {
        return {
            image: "/images/search.png",
            loading: false,
            pagina: null,
            total_items: 1000,
            paginaAtual: 1,
            totalPaginas: 1,
            selectPedidos: [],
            indexArray: "",
            totalPaginas: 1,
            items_by_page_value: 50,
            indexIdPedido: null,
            rating: 0,
            notaVendedor: null,
            arrayPedido: [],
            arrayPedidoFiltrada: null,
            checkbox: [],
            arrayCheckbox: null,
            arraySelectLoja: [],
            arraySelectFornecedor: [],
            spinning: false,
            selectedLoja: "",
            selectedFornecedor: "",
            has_vf_integration: false,
            buscaPedido: null,
            orderService: new QuotationService(),
            svc: new SellersService(),
            vf_svc: new VarejoFacilService(),
            storage_svc : new StorageService(),
            is_loading_pdf: false,
            check_all: false,
            current_order: null,
            order_data: null,
            filters: {},
            statuses: [
                { id: 'P', name: 'Pendente' },
                { id: 'C', name: 'Confirmado' },
                { id: 'D', name: 'Entregue' },
                { id: 'ND', name: 'Não Entregue' }
            ]
        };
    },
    watch: {
        check_all: function(new_val) {
            this.arrayPedido.forEach(ord => this.$set(ord, 'selected', new_val));
        }
    },
    methods: {
        confirm_delivery(order, index) {
            this.confirm_action({
                message: "Confirma recebimento do pedido?",
                deny_text: "Com divergência!",
                cancel_buttom_color: '#4D4F5C',
				callback: (result) => {
                    this.svc.confirm_delivery(order.id_pedido).then(res => {
                        order.status_recebido = true
                    })
                },
                deny_callback: () => {
                    this.svc.confirm_delivery(order.id_pedido).then(res => {
                        order.status_recebido = true
                        this.show(index)
                    })
                }
			})
        },
        sync_order(item) {
            this.present_loader("Enviando pedido...")
            this.vf_svc.export(item.id_pedido).then(response => response.data).then(data => {
                this.dismiss_loader()
                const notification = {
                    type: 'success',
                    message: "Pedido enviado com sucesso!"
                }
                this.$store.dispatch('notification/add', notification)
            }).catch(e => {
                console.log(e.response.data.message);
                this.dismiss_loader()
                if(e.response.data.message) {
                    const notification = {
                        type: 'error',
                        message: e.response.data.message
                    }
                    this.$store.dispatch('notification/add', notification)
                }
            })
        },
        show(index) {
            this.$modal.show("hello-world");
            this.indexArray = this.arrayPedido[index].vendedor;
            this.indexIdPedido = this.arrayPedido[index].id_pedido;
            this.rating = this.arrayPedido[index].nota;
            this.checkbox = this.arrayCheckbox.map(opts => {
                let opt = this.arrayPedido[index].avaliacao.find(item => item.id == opts.id)
                return opt ? true : false
            })
        },
        hide() {
            this.$modal.hide("hello-world");
            this.rating = 0;
            this.select = "";
            this.checkbox = [];
        },
        modalClick() {
            if (this.rating === 1) {
                this.notaVendedor = "Vendedor péssimo poderia melhorar.";
            }
            if (this.rating === 2) {
                this.notaVendedor = "Vendedor ruim poderia melhorar.";
            }
            if (this.rating === 3) {
                this.notaVendedor = "Vendendo regular poderia melhorar.";
            }
            if (this.rating === 4) {
                this.notaVendedor = "Vendedor bom mas poderia melhorar.";
            }
            if (this.rating === 5) {
                this.notaVendedor = "Vendedor Excelente!";
            }
        },
        getListaPedidos(page) {
            this.loading = true;
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    idPedido: this.buscaPedido,
                    numerocotacao: this.buscaCotacao,
                    fornecedor: this.selectedFornecedor? this.selectedFornecedor.for_id : null,
                    loja: this.selectedLoja ? this.selectedLoja.loj_id : null,
                    singleorder: false,
                    status: this.filters.status ? this.filters.status.id : null
                }
            };
            let url = `${CONSTANTS.API_URL}/listarpedidos?page=${page}`;
            axios.get(url, config).then(data => {
                this.arrayPedido = data.data.pedidos.data;
                this.loading = false;
                this.paginaAtual = data.data.pedidos.current_page;
                this.totalPaginas = data.data.pedidos.last_page;
                this.total_items = data.data.pedidos.total;
            }).catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        updatePage(new_page) {
            this.getListaPedidos(new_page);
        },
        postAvaliacaoPedido() {
            let arrayCheck = [];
            this.checkbox.forEach((data, index) => {
                if (data) {
                arrayCheck.push(this.arrayCheckbox[index].id);
                }
            });
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.post(`${CONSTANTS.API_URL}/atualizaravaliacaopedido`, {
                id_pedido: this.indexIdPedido,
                nota: this.rating,
                comentarios: arrayCheck,
                observacao: " "
            }, config).then(() => {
            this.hide();
            this.getListaPedidos(this.paginaAtual);
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getArrayPedido() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }
            axios.get(`${CONSTANTS.API_URL}/listarcomentariospedido`, config)
            .then(data => {
                this.arrayCheckbox = data.data.comentarios;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },

        resetePedido() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.get(`${CONSTANTS.API_URL}/api/listarpedidos`, config)
            .then(data => {
                this.arrayPedido = data.data.pedidos.data;
                this.spinning = false;
                this.paginaAtual = data.data.pedidos.current_page;
                this.totalPaginas = data.data.pedidos.last_page;
                this.buscaPedido = "";
                this.selectedLoja = "";
                this.selectedFornecedor = "";
            })
        },
        getLojas() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            return axios.get(`${CONSTANTS.API_URL}/getrequisitantescliente`, config)
            .then(data => {
                this.arraySelectLoja = data.data.requisitantes;
                if(!this.can('REQUEST_ORDER', 'READ_ALL')) {
                    this.selectedLoja = this.arraySelectLoja[0]
                }
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getFornecedor() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        axios
            .get(`${CONSTANTS.API_URL}/getfornecedorescliente`, config)
            .then(data => {
            this.arraySelectFornecedor = data.data.fornecedores;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getFornecedor() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        axios
            .get(`${CONSTANTS.API_URL}/getfornecedorescliente`, config)
            .then(data => {
            this.arraySelectFornecedor = data.data.fornecedores;
            });
        },
        print(order) {

            this.current_order = order

            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                [ order.id_pedido ], [ order.id_cotacao ], [ order.id_loja ], [ order.usu_id ]
            ]

            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };

            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                Vue.nextTick(() => {
                    window.document.title = `pedido-${order.id_pedido}.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        print_many() {
            this.is_loading_pdf = true;

            this.selectPedidos = this.arrayPedido.filter(order => order.selected)
            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                this.selectPedidos.map(item => item.id_pedido),
                this.selectPedidos.map(item => item.id_cotacao),
                this.selectPedidos.map(item => item.id_loja),
                this.selectPedidos.map(item => item.usu_id)
            ]
            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };

            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                Vue.nextTick(() => {
                    window.document.title = `pedidos.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        check_vf_integration() {
            let has_vf_integration = StorageService.get_string('has_vf_integration')
            if(has_vf_integration) {
                this.has_vf_integration = !!has_vf_integration;
            }
        }
    },
    mounted() {
        this.getArrayPedido();
        this.getLojas().then(() => this.getListaPedidos());
        this.getFornecedor();
        this.check_vf_integration()
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/commons/_variables';
@import '@/sass/commons/_tables';

.okCheck {
  font-size: 1.3em;
  color: $brand-success !important;
}

    /deep/ .buttons {
        a {
            margin-left: 10px;
        }
    }
    .checkBoxTableHeader{
        margin-left: 0px !important;
    }
    .button-alter{
        cursor: pointer;
        font-size: 20px;
    }
.notCheck {
  font-size: 1.3em;
  color: $brand-danger !important;
}
.nota {
  font-weight: bold !important;
  font-size: 13px;
}

.menuSegundo {
  color: #a3a6b4;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f38505;
  border: none !important;
}
.custom-control-label {
  padding-top: 2px !important;
}
.custom-checkbox {
  transform: scale(1.2) !important;
  border-radius: 5px !important;
}

.borderCheck {
  border: solid 1px #d2d2d2;
  padding-right: 50px;
}

.custom-checkbox2 {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0px;
  }
  & + .check-title {
    margin-left: 6px;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
     border: 1px solid #B8BFC5;
     border-radius: 5px !important;
     background: white;
  }

  // Box hover
  label:before {
    background: #f35429;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
   background: #f38505;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.spinning {
  font-size: 50px;
  color: #f38505;
}
.btnPag {
  color: #ec7f3b;
  border: 1px solid #ec7f3b;
  font-weight: bold;
}
.justify-end {
  justify-content: flex-end;
}
.pagNum {
  color: #ec7f3b;
  font-weight: bold;
  padding-top: 10px;
}
.imgLoaderPdf {
  width: 20px;
}
.dropdown-item.btnMenu:hover {
  background: #ececec !important;
}
.modal-avaliar {
  margin: 30px auto;
  left: 0px !important;
  position: inherit !important;
}
.cell-table-header{
  color:#858586 !important
}
.statuses {
    i {
        &:nth-child(2) {
            &.delivered {
              color: darkgreen;
            }
            color: gold;
            &.pending {
                color: $brand-danger !important;
            }
        }
        color: $primary-color;
        padding: 0 6px;
    }
}

.action-controls {
    width: 12%;
    i {
        cursor: pointer;
        &:first-child {
            color: $secondary-color;
        }
        &:nth-child(2) {
            color: $yellow1;
        }
        &:nth-child(4) {
            color: darkgreen;
        }
        &:nth-child(5) {
            color: #11cdef !important;
        }
        color: $primary-color;
        font-size: 1.2rem;
        padding: 0 6px;
        &:hover {
            color: $secondary-color;
        }
    }
    a i {
        color: $secondary-color;
    }
}
</style>
