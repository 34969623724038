<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <cc-loader-full v-show="loading" :text="'Aguarde... Essa operação pode levar alguns minutos.'" />
            <div class="col-sm-4">
                <label for="inputCodigo" class="col-sm-12 control-label">Empresa de Origem</label>
				<multiselect :disabled="true" v-model="storeOrigin" track-by="value" placeholder="" label="name" open-direction="below" selected-label="Selecionado" :options="listStores" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-sm-4 change">
                <i class="fas fa-long-arrow-alt-right change-icon"></i>
            </div>
            <div class="col-sm-4">
                <label for="inputCodigo" class="col-sm-12 control-label">Empresa de Destino</label>
				<multiselect v-model="storeDestiny" track-by="value" placeholder="" label="name" open-direction="below" selected-label="Selecionado" :options="listStores" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
        </div>
    </cc-modal> -->
    <div class="modal-mask" v-on:click="close()">
        <div v-on:click.stop="">
            <cc-loader-full v-if="loading"/>
            <div class="modal-change-company-header">
                Trocar empresa
                <span class="material-icons modal-change-company-header-close-icon" v-on:click="close()">cancel</span>
            </div>
            <div class="modal-change-company-body">
                <div class="modal-change-company-inputs">
                    <StandardInputAuto :action="setOrigin" class="desktop" :title="'Empresa de origem:'" :width="'40%'"  :placeholder="this.ressuply.nomeLoja" :list="[]" :type="'select'"/>
                    <StandardInputAuto :action="setOrigin" class="mobile" :title="'Empresa de origem:'" :width="'90%'"  :placeholder="this.ressuply.nomeLoja" :list="[]" :type="'select'"/>
                    <span class="material-icons-outlined modal-change-company-inputs-separator">east</span>
                    <StandardInputAuto :action="setDestiny" class="desktop" :title="'Empresa de destino:'" :width="'40%'"  :placeholder="''" :list="formatList(listStores)" :type="'select'"/>
                    <StandardInputAuto :action="setDestiny" class="mobile" :title="'Empresa de destino:'" :width="'90%'"  :placeholder="''" :list="formatList(listStores)" :type="'select'"/>
                </div>
                <div class="modal-date-butons">
                    <div class="modal-date-cancel-button" v-on:click="close()">Voltar</div>
                    <div class="modal-date-update-button" v-on:click="changeStore()">Atualizar</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import RequestsService from "@/services/v2/requests.service";
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default {
    props: ["ressuply"],
    components:{
        StandardInputAuto
    },
    data() {

        return {
            modal: {
                title: "Trocar Empresa",
                permitted: true,
                submit: this.changeStore,
                submit_text: 'Enviar',
                subtitle: 'Aqui você pode trocar a empresa da sua requisição',
                icon_title: 'fas fa-exchange-alt',
                style: {
                    width: "70%"
                }
            },
            loading: false,
            listStores: [],
            storeOrigin: {name: this.ressuply.nomeLoja, value: this.ressuply.loj_id},
            storeDestiny: null,
            service: new RequestsService(),
        };
    },
    methods: {
        setOrigin(value){ this.storeOrigin = value},
        setDestiny(value){ this.storeDestiny = value},
            formatList (wraps){
                const resp = []
                wraps.forEach(element => {
                    resp.push({
                        value: element,
                        text: element.name
                    })
                })
                return resp
            },
            close() {
                this.$emit("close");
            },
            getStores() {
                var quotationService = new QuotationService();
                quotationService
                    .getStoresClient()
                    .then(response => response.data)
                    .then(data => {
                        this.listStores = data.requisitantes.map(store => {
                            return {
                                value: store.loj_id,
                                name: store.loj_descricao
                            };
                        });
                    })
                    .catch(error => {
                        ErrorHandlerService.handle(error, this.$store);
                    });
            },
            changeStore(){
                if (!this.storeDestiny) return
                this.loading = true;
                let data = {
                    store_id : this.storeDestiny.value
                }
                this.service
                    .store_request(this.ressuply.numeroCotacao,data)
                    .then(response => response.data)
                    .then(data => {
                        this.loading = false;
                        this.close();
                        this.$emit("finish");
                    })
                    .catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store);
                    });

            }
        },
        mounted() {
            this.getStores()
        }
    };
</script>

<style lang="scss" scoped>
	@import './change-store.modal';
    .modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
    }
    .modal-change-company-header{
        background: var(--primary-color);
        border-radius: 10px 10px 0px 0px;
        padding: 1vh 2vw;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 2.1em;
        color: #FFFFFF;
        align-items: center;
        width: 75vw;
    }
    .modal-change-company-header-close-icon{
        font-size: 1.4em;
        cursor: pointer;
    }
    .mobile{display: none;}
    .modal-change-company-body{
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 2vh 1vw;
        width: 75vw;
    }
    .modal-change-company-inputs{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20vh;
    }
    .modal-change-company-inputs-separator{
        color: var(--primary-color);
        font-size: 3em;
        margin-top: 4vh;
    }
    .modal-date-butons{
    display: flex;
    justify-content: flex-end;
}
.modal-date-cancel-button{
    padding: 1.5vh 0;
    width: 10vw;
    text-align: center;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
    color: var(--primary-color);
    cursor: pointer;
}
.modal-date-update-button{
    padding: 1.5vh 0;
    width: 10vw;
    text-align: center;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
    color: white;
    margin-left: 2vw;
    cursor: pointer;
}
@media only screen and (max-width: 1200px) {
    .modal-mask{font-size: 1.5em;}
    .modal-change-company-inputs{
        flex-direction: column;
        margin-bottom: 5vh;
    }
    .modal-change-company-inputs-separator{
        rotate: 90deg;
        margin: 1vh 0;
    }
    .desktop{display: none;}
    .mobile{display: block;}
}
@media only screen and (max-width: 800px) {
    .modal-change-company-header{width: 90vw;}
    .modal-change-company-body{width: 90vw;}
    .modal-date-update-button{width: 30vw;}
    .modal-date-cancel-button{width: 30vw;}
}
@media only screen and (max-width: 550px){
    .modal-change-company-header{font-size: 5.5vw;}
}
@media only screen and (max-width: 400px){
    .modal-date-update-button{width: 35vw;}
    .modal-date-cancel-button{width: 35vw;}
}
</style>


