<template>
    <div
      class="rounded-circle shadow btn"
      v-on:click="$router.push('/resume-order/')"
    >
      <span class="quantity rounded-circle">{{ this.items.length }}</span>
      <i class="fas fa-shopping-cart"></i>
      <span class="total-price rounded">{{ this.total | currency }}</span>
    </div>
  </template>
  <script>
  import { mapState, mapActions, mapGetters } from "vuex";

  export default {
    inheritAttrs: false,
    data() {
      return {};
    },
    computed: {
      ...mapState(["cart"]),
      ...mapGetters("cart", ["cart_item_for", "total", "items", "cart_request"]),
    },
    methods: {
      ...mapActions("cart", [
        "set_campaign",
        "add_item",
        "remove_item",
        "update_quantity",
        "set_store",
        "reset",
      ]),
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "@/sass/commons/_variables";
  @import "@/sass/commons/_tables";

  .btn {
    // padding: 10px 15px;
    position: fixed;
    right: 2rem;
    bottom: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #20ce88;
    width: 4rem;
    height: 4rem;
    @media only screen and (max-width: 630px) {
      bottom: unset;
      top: 17px;
      right: 25px;
      z-index: 10;
      width:2.2rem;
      height: 2.2rem;
    }
    &:hover {
      background-color: white;
      color: $primary-color;
      box-shadow: 0 0.5rem 1rem $primary-color !important;
      i {
        color: $primary-color;
      }
    }
    i {
      font-size: 1.5rem;
      color: white;
      @media only screen and (max-width: 630px) {
          font-size: 1.2rem;
    }
    }
    .quantity {
      position: absolute;
      bottom: 50px;
      left: 45px;
      background-color: $primary-color;
      border: 1px solid white;
      padding: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      width: 30px;
      height: 30px;
      @media only screen and (max-width: 630px) {
          width: 25px;
          height: 25px;
          padding: 2px;
          font-size: 0.9rem;
          bottom: 20px;
          left: 25px;
      }
    }
    .total-price {
      position: absolute;
      top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 35px;
      width: fit-content;
      font-size: 1.2rem;
      height: fit-content;
      padding: 0 5px;
      white-space: nowrap;
      background-color: #2098d1;
      border: 1px solid white;
      color: white;
      @media only screen and (max-width: 630px) {
          font-size: 0.9rem;
          top: 20px;
          right: 27px;
      }
    }
  }
  </style>
