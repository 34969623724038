
<template>
    <div>

        <PrintMatrixComponent
            :products="products"
            v-if="is_printing"
            :min_fat="min_fat"
            :provider="provider"
            :buyer="buyer" />
        <div class="p-3 matrix-wrapper">
            <cc-loader v-if="loading || is_loading" />
            <div v-if="!loading && !is_loading && products" class="container-fluid">
                <div class="header" :style="{ width: `${33 + (stores.length * field_size)}rem` }">
                    <div>
                        <div class="col text-center pro-col">
                            <div class="products-header">
                                    <span >
                                        Vlr:
                                    </span>
                                    <span >Ult Cmp:</span>
                                    <span >Qtd:</span>
                                    <span >Emb:</span>
                                    <span >Total:</span>
                            </div>
                        </div>
                        <div v-for="store in stores" :key="store.id" class="col text-center quantity-header" @click="goto(store, store.total)">
                            <span class="store-header-item" :title="store.name">
                                <span class="material-icons-outlined store-header-icon">store</span>
                                {{ store.name }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="col text-center pro-col"></div>

                        <div class="col text-center quantity-header" v-for="store in stores" :key="'tot-' + store.id">
                            <span class="description store-total"
                                :class="{ cursor: store.total > 0, higher: store.total > 0 && store.total < min_fat }">
                                    {{ store.total.toFixed(2) | currency }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="body" v-if="stores && stores.length > 0" :style="{ width: `${33 + (stores.length * field_size)}rem` }">
                    <RecycleScroller
                        class="scroller"
                        :items="products"
                        :item-size="84"
                        :prerender="6"
                        key-field="prod_id"
                        v-slot="{ item, index }"
                    >
                        <div :key="'name-' + item.pro_id" :class="getSpecialBackground(index)" class="prod-row">
                            <div class="text-left border-bottom-0 pl-0 prod-info">
                                <div>
                                        <div class="pro-name">
                                            {{ item.pro_principal_nome || item.pro_descricao }}
                                            <i v-if="item.observacao" class="fas fa-info-circle" style="color: var(--primary-color); margin-left: 1vw" :title="item.observacao.toUpperCase()"></i>
                                        </div>
                                        <div class="brand">{{ item.pro_observacao }}</div>
                                    <div class="text-center border-top-0 pt-0">
                                        <div class="d-flex p-0">
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">
                                                    <span :class="{ lower: item.valor < item.preco_ultima_compra, higher2: item.valor > item.preco_ultima_compra, 'total-val': true }">
                                                        {{ item.valor | currency }}
                                                    </span>
                                                    <i v-if="!has_orders" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(item)"></i>
                                                </span>
                                            </p>
                                            <p class="mb-0 info" title="ultimo preço">
                                                <span class="d-block text-center">{{ item.preco_ultima_compra | currency }}</span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">{{ quantity_sum(item.stores) }}</span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">
                                                    {{ item.pro_unidade_compra ? item.pro_unidade_compra : `${item.pro_embalagem.split(" ")[0]} ${item.pro_embalagem.split(" ")[1]}` }}
                                                    <i class="far fa-edit" @click="edit_wrap(item)" v-if="can('PRODUCT', 'UPDATE') && !has_orders && item.pro_generico == 0 && !item.pro_principal && provider"/>
                                                </span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center total-val">{{ item.total | currency }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(store, idx) in item.stores" :key="store.id" class="quantity-wrapper">
                                <div class="column-quantity">
                                    <input type="text" style="border-radius: 8px;" class=" form-control text-center m-auto"
                                        :class="{
                                            'store-quantity-reduce' : store.has_stock,
                                            'store-quantity' : store.has_stock,
                                            'saved' : store.saved
                                        }"
                                        v-model.number="store.quantidade"
                                        @blur="handle_navigation($event, store, index, idx, item)"
                                        @keyup="handle_navigation($event, store, index, idx, item)"
                                        :id="'ipt-' + index + '-' + idx"
                                        :data-idy="index"
                                        :readonly="store.disabled"
                                        :data-idx="idx"
                                        :ref="'y-' + index + '-' + idx"><span class="column-stock">{{get_stock_label(store)}}</span>
                                    <div style="position: relative">
                                        <cc-loader v-show="store.loading" class="quantity-loader" :show_txt="false"></cc-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RecycleScroller>
                </div>
            </div>
            <NegotiationModal
                @close="reset_negotitation"
                :typing="{
                    idVendedor: seller_id, idFornecedor: provider.idFornecedor,
                    valor: current_product.valor, nomeVendedor: provider.nomeVendedor,
                    pro_id: current_product.pro_id
                }"
                :product="{
                    produtoDescricao: current_product.pro_descricao, pro_observacao: current_product.pro_observacao,
                    observacao: current_product.observacao, ultimaCompra: current_product.preco_ultima_compra
                }"
                v-if="current_product && !open_edit_wrap"
                :quotation_id="id" />
            <ProductWrapModal
                v-if="open_edit_wrap && current_product"
                :product="{ ...current_product, descricaoProduto: current_product.pro_descricao, embalagem: current_product.pro_embalagem, idProduto: current_product.pro_id }"
                @close="close_wrap"
                @reload="load_matrix"
            />
        </div>
    </div>
</template>
<script>

let counter = 0

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
import PrintMatrixComponent from './_print-products-matrix.component.vue'
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    components: { NegotiationModal, ProductWrapModal, PrintMatrixComponent },
    mixins: [ elementNavigationmMixin, perm_mixin ],
    props: {
        buyer: {
            type: Object
        },
        id: {
            type: [Number, String],
            required: true
        },
        seller_id: {
            type: [Number, String],
            required: true
        },
        provider: {
            type: [ Object ],
            required: true
        },
        min_fat: [ Number ],
        reload: [ Boolean ],
        is_loading: [ Boolean ],
        is_printing: [ Boolean ]
    },
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            products: null,
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false,
            field_size: 8,
            call_qtd: 0,
            has_orders: false
        }
    },
    methods: {
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        close_wrap({ wrap }) {
            if(wrap) {
                this.current_product.pro_embalagem = wrap
            }
            this.current_product = null;
            this.open_edit_wrap = false
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        filter_product(stores, store) {
            let result = stores.find(s => s.loj_id == store.id)
            if(!result) {
                result = {
                    quantidade: null,
                    loading: false,
                    loj_id: store.id,
                    pro_id: stores[0].pro_id,
                    saved: false,
                    valor: stores[0].valor,
                    pro_generico: stores[0].pro_generico,
                    pro_principal: stores[0].pro_principal,
                    pro_embalagem: stores[0].pro_embalagem
                }
            }
            return { ...result, loading: false, saved: false, disabled: store.disabled };
        },
        get_quantity(store) {
            return store ? store.quantidade : null
        },
        get_stock(store) {
            return store ? store.estoque : null
        },
        get_stock_label(store) {
            return store.estoque ? "("+store.estoque+")" : null
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.quantidade || 0) }, 0)
        },
        total_store(store) {
            let result = 0
            this.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.loj_id == store.id) {
                        let value = pp.pro_generico == 0 && pp.pro_principal == null && this.user.request_type != 1 ? parseInt(pp.pro_embalagem.split(' ')[1])*pp.valor : pp.valor
                        result += value*pp.quantidade
                    }
                })
            })
            store.total = result
        },
        total(item) {
            item.total = item.stores.reduce((sum, store) => { return sum + ((store.quantidade || 0)*(store.pro_generico == 0 && store.pro_principal == null && this.user.request_type != 1 ? parseInt(store.pro_embalagem.split(' ')[1])*store.valor : store.valor)) }, 0)
        },
        load_matrix(show_loader = true) {
            if(show_loader)this.loading = true
            return this.svc.request_products_matrix(this.id, { seller_id: this.seller_id }).then(response => response.data)
            .then(data => {
                data.forEach(p => {
                    let prod_stores = p.stores
                    p.stores.forEach(pp => pp.initial_quantity = pp.quantidade)
                    p.stores = []
                    this.stores.forEach(s => {
                        let curr = this.filter_product(prod_stores, s)
                        prod_stores.forEach(ps => ps.initial_quantity = ps.quantidade)
                        curr.disabled = s.disabled
                        p.stores.push(curr)
                    })
                });
                this.products = data;
                this.products.forEach(p => this.total(p))
                this.stores.forEach(s => this.total_store(s))
                this.load_has_orders()
                this.products.forEach(p => this.load_has_stock(p))
                this.loading = false
            }).catch(e => {
                this.loading = false
            })
        },
        handle_navigation($event, product, x, y, item) {
            this.navigatrix($event, {
				callback: {
					function: product.quantidade != product.initial_quantity ? this.save : null,
                    params: { product, item }
				},
                x: x,
                y: y
			})
        },
        save({ product, item }, callback) {
            let store = product
            store.loading = true
            this.$set(store, 'loading', true);
            return this.service.sendQuantidade({
                numerocotacao: this.$route.params.id,
                quantidade: store.quantidade || "0",
                vendedor: this.seller_id,
                produto: store.pro_id,
                loja: store.loj_id
            })
            .then(response => response.data)
            .then(() => {
                store.initial_quantity = store.quantidade
                this.$set(store, 'loading', false)
                this.$set(store, 'saved', true);
                this.total_store(this.stores.find(s => s.id == product.loj_id))
                this.total(item)
            })
            .catch(e => {
                const notification = {
                    type: 'error',
                    message: 'Ocorreu um erro ao atualizar a quantidade.'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        process_available_stores() {
            this.stores.forEach(store => store.disabled = false)
            this.svc.request_orders_by_seller(this.id, this.seller_id).then(response => response.data).then(data => {
                data.forEach(order => {
                    this.products.forEach(p => {
                        p.stores.forEach(store => {
                            if(store.loj_id == order.id_loja) {
                                store.disabled = true
                            }
                        })
                    })
                })
                this.$forceUpdate();
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
                a.total = 0
				if (a.position > b.position) return 1
				if (b.position > a.position) return -1
				return 0
			})
        },
        goto(store, fat) {
            if(fat > 0) {
                this.$router.push({ name: 'lancamento-cliente-monitores-vencedores', params: { id: this.id, sellerid: this.seller_id, sid: store.id, providerid: this.$route.params.providerid } })
                this.$emit('update', 'TYPINGS')
            }
        },
        open_negotiation_modal(product) {
            this.current_product = product;
        },
        load_has_orders() {
            let result = false
            this.products.forEach(p =>
                p.stores.forEach(pp => {
                    if(p.id_pedido || pp.id_pedido) {
                        result = true
                    }
                })
            )
            this.has_orders = result
        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.valor = new_value
            }
            this.current_product = null;
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        load_has_stock(products, store){
            let result = false;
            products.forEach( product =>{
                for (let j = 0; j < product.stores.length; j++) {
                    if((product.stores[j].loj_id == store.id) && product.stores[j].estoque){
                        result = true;
                        break;
                    }
                }
            })
            store.has_stock = result
            return result;
        }
    },
    watch: {
        reload: async function(newVal, oldVal) {
            this.load_matrix().then(this.process_available_stores)
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
        this.sort_stores()
        await this.load_matrix().then(this.process_available_stores)
        this.$forceUpdate()
    }
}
</script>

<style lang="scss" scoped>
@import './_products-matrix.component';
.products-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    font-size: 16px;
    color: #605F5F;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vw;
}
.store-header-icon{color: var(--primary-color);}
.store-header-item{
    font-size: 0.9rem;
    font-weight: bolder;
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 2px;
    display: inline-block;
    text-align: center;
    color: $gray3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7rem;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding: 0.5vh 1vw;
    cursor: pointer;
}
.page-table-line-special{
    background: #F7F7F7;
}
::-webkit-scrollbar{
        height: 1vh;
        width: 0;
        background: gray;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: var(--primary-color);
        border-radius: 10px;
    }
</style>

