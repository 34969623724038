<template>
    <div class="request-table-container">
        <table class="request-table-body">
            <thead class="request-table-header">
                <tr>
                    <th class="request-table-cot-width">Código</th>
                    <th class="request-table-product-width">Produto</th>
                    <th class="request-table-number-width">Embalagem</th>
                    <th class="request-table-quantity-width">Qtd.</th>
                    <th class="request-table-quantity-width">Estq.</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in list" :key="idx" class="request-table-row" :class="{ 'page-table-line-special': idx % 2 != 0 }">
                    <th class="request-table-cell request-table-cot-width">{{ item.pro_ean }}</th>
                    <th class="request-table-cell">{{ item.pro_descricao }}</th>
                    <th class="request-table-cell">{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</th>
                    <th class="request-table-cell">{{ item.quantidade ? item.quantidade : '--' }}</th>
                    <th class="request-table-cell">{{ item.estoque ? item.estoque : '--' }}</th>
                </tr>
            </tbody>
        </table>
        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import QuotationService from "@/services/QuotationService";

export default {
    name: 'SelectProductTable',
    props: ['list'],
    components: {
        StandardButton,
    },
    data() {
        return {
            service: new QuotationService(),
            isLoading: false
        }
    },
    methods: {
        consult() {
            this.isLoading = true
            this.service.getProductsResupply(636395).then(resp => {
                this.isLoading = false
                this.setList(resp.data)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.request-table-container {
    margin: 20px;
    overflow: auto;
    max-height: 70vh;
    scrollbar-width: unset;
}

.request-table-body {
    outline: red;
}

.request-table-header {
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    color: #605F5F;
    font-size: 1.1em;
    font-weight: 400;
}

.request-table-product-width {
    flex: 1;
}

.request-table-number-width {
    width: 25%;
}

.request-table-row {
    color: #898888;
    font-size: 1em;
}

.request-table-cell {
    padding: 1vh 0 !important;
    border-bottom: none;
}

.request-table-cot-width {
    width: 10%;
    padding-left: 2% !important;
}

.request-table-button {
    height: 5vh;
}
.request-table-quantity-width{
    width: 12%;
}
.page-table-line-special {
  background: #F7F7F7;
}
@media only screen and (max-width: 850px){
    .request-table-container{display: none;}
}
</style>