import Vuex from 'vuex'
import QuotationService from "@/services/QuotationService";

export default {
    namespaced: true,
    state: {
        statuses: [],
        request: null
    },
    actions: {
        set_request({ commit }, request) {
            commit('SET_REQUEST', request)
        },
        set_statuses({ commit, state }) {
            if(state.statuses && state.statuses.length > 0) {
                return state.statuses
            } else {
                var quotationService = new QuotationService();
                quotationService
                .getAllStatus()
                .then(response => response.data)
                .then(data => {
                    var status = [{ value: "", name: "Todas" }];
                    for (var i = 0; i < data.status.length; i++) {
                        if (
                            data.status[i].status_nome !== "CANCELADA" &&
                            data.status[i].status_nome !== "SEM PEDIDOS PARA VOCE"
                        ) {
                            var nome = data.status[i].status_nome;
                            if (nome === "DIGITADA") {
                                nome = "EM DIGITAÇÃO";
                            }
                            status.push({
                                value: data.status[i].status_id,
                                name: nome.charAt(0).toUpperCase() + nome.slice(1).toLowerCase()
                            });
                        }
                    }
                    commit('SET_STATUSES', status)
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
            }
        }
    },
    mutations: {
        SET_STATUSES(state, statuses) {
            state.statuses = statuses
        },
        SET_REQUEST(state, request) {
            state.request = request
        }
    },
    getters: {
        status_formatado: state => state.status_formatado,
        request: state => state.request
    }
}
