export default class PreOrderCollection {

    status = 'ALL'
    pre_orders = []
    stores = []

    current_store = null
    current_seller = null
    current_preorder = null

    constructor(pre_orders, stores = null, status = 'ALL') {
        this.pre_orders = pre_orders
        this.stores = stores.map(s => { return { ...s, expanded: true } })
        this.status = status
        this.process_by_store()
    }

    process_by_store() {
        // this.stores = this.stores.map(s => { return this.map_store(s) })
        this.pre_orders.filter(po => po.status == this.status || this.status == 'ALL')
        .forEach(po => {
            this.mount_store(po.subrequest, () => {
                this.mount_seller(po.offer, () => {
                    this.mount_preorder(po, po.offer, (preorder) => {
                        preorder._quantity = preorder.quantity
                        preorder.suggestion = this.calculate_suggestion(preorder, po.offer)
                    })
                })
            })
        })
        this.clear_stores()
        this.link_sellers()
    }

    calculate_suggestion(po, offer) {
        return Math.round(po.subrequest.quantity / offer.quantity) * offer.quantity
    }

    pre_orders(store_id, seller_id) {
        return this.stores
            .find(s => s.id == store_id)
            .sellers
            .find(s => s.id == seller_id)
            .preorders
    }

    total_by_store_and_seller(store_id, seller_id) {
        let seller = this.stores
            .find(s => s.id == store_id)
            .sellers
            .find(s => s.id == seller_id)
        return seller ? seller.preorders.reduce((x, { offer, quantity }) => x + (quantity * offer.price) , 0) : 0
    }

    minimum_billing_reached(store_id, seller_id) {
        let mb = this.seller(seller_id).minimum_billing || 0
        return this.stores
            .find(s => s.id == store_id)
            .sellers
            .find(s => s.id == seller_id)
            .preorders.reduce((x, { offer, quantity }) => quantity * offer.price , 0) > mb
    }

    process_last_offers(last_offers) {
        this.stores.forEach(s => {
            s.sellers.forEach(sell => {
                sell.preorders.forEach(po => {
                    last_offers.forEach(lo => {
                        if(po.offer.brand_id) {
                            if(po.offer.brand_id == lo.product_id) {
                                po.last_offer = lo
                            }
                        } else {
                            if(po.product.id == lo.product_id) {
                                po.last_offer = lo
                            }
                        }
                    })
                })
            })
        })
    }

    has_orders() {
        return this.current_store &&
            this.current_store.sellers
            .some(sell => sell.preorders.some(po => po.status == 'ORDERED'))
    }

    store(id) {
        this.current_store = this.stores.find(s => s.id == id)
        return this.current_store
    }

    seller(id) {
        this.current_seller = this.current_store.sellers.find(s => s.id == id)
        return this.current_seller
    }

    map_store(s) {
        return {
            id: s.loj_id,
            cnpj: s.loj_cnpj,
            name: s.loj_nomeFantasia,
            expanded: true,
            phone: s.loj_telefone,
            street: s.loj_endereco,
            city: s.cid_nome,
            state: s.est_nome
        }
    }

    mount_store(subrequest, callback) {
        this.current_store = this.stores.find(s => s.id == subrequest.store_id)
        if(!this.current_store) {
            this.current_store = { store_id: store_id, sellers: [ ] }
            this.stores.push(this.current_store)
        }
        if(!this.current_store.sellers) {
            this.current_store.sellers = []
        }
        callback()
    }

    mount_seller(offer, callback) {
        this.current_seller = this.current_store.sellers.find(s => s.id == offer.seller_id)
        if(!this.current_seller) {
            this.current_seller = { ...offer.seller, preorders: [ ] }
            this.current_store.sellers.push(this.current_seller)
        }
        callback(this.current_seller)
    }

    link_sellers() {
        this.stores.forEach(s => {
            s.sellers.forEach((sell, idx) => {
                sell.next = s.sellers.length == idx+1 ? s.sellers[0] : s.sellers[idx+1]
                sell.prev = idx == 0 ? s.sellers[s.sellers.length-1] : s.sellers[idx-1]
            })
        })
    }

    mount_preorder(preorder, offer, callback) {
        this.current_preorder = this.current_seller.preorders.find(po => po.id == preorder.id)
        if(!this.current_preorder) {
            this.current_preorder = { ...preorder, offer }
            this.current_seller.preorders.push(this.current_preorder)
        }
        callback(this.current_preorder)
    }

    clear_stores() {
        this.stores = this.stores.filter(s => {
            return s.sellers && s.sellers.some(s => s.preorders.length > 0)
        })
    }

    total_for_store(store) {
        return this.stores.find(s => s.id == store.id)
            .sellers
            .reduce((x, { preorders }) => {
                return x + preorders.reduce((y, { quantity, offer }) => y + (quantity * offer.price), 0)
            }, 0)
    }

    total_for_seller_on_store(seller, store) {
        let s = this.stores.find(s => s.id == store.id).sellers.find(s => s.id == seller.id)
        return s.preorders
            .reduce((x, { offer, quantity }) => {
                return x + (quantity * offer.price)
            }, 0)
    }

    get total() {
        return this.stores.reduce((x, store) => {
            return x + this.total_for_store(store)
        }, 0)
    }

}
