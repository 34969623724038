<template>
    <div>
        <div class="input-title">{{title}}</div>
        <select :disabled="!active" class="input-value-select" v-model="value">
            <option v-if="propValue" :value="propValue" disabled selected>{{propValue}}</option>
            <option class="input-value-option" v-for="item in list" :keys="item" :value="item">{{item}}</option>
        </select>
    </div>
</template>

<script>

export default {
    name: 'VindiPlansSelect',
    props: ['title', 'propValue', 'list', 'active', 'setValue'],
    data () {
        return {
            value: undefined
        }
    },
    created () {
        this.value = this.propValue
    },
    watch: {
        value (value) { if (this.setValue) this.setValue(value) }
    }
}
</script>

<style>
.input-title{
    font-weight: 300;
    font-size: 1.2em;
    color: #909090;
}
.input-value-select{
    font-weight: 300;
    font-size: 1.4em;
    color: #6e6e6e;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5vh 1vw;
    margin-top: 0.5vh;
    width: 100%;
    text-transform: uppercase;
}
.input-value-select:focus {
    outline: none;
}
.input-value-optiom{
    text-transform: uppercase;
}
</style>