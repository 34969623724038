import http from "./http";
import Service from "../Service";

/**
 * @type ProviderService
 */
export default class ProviderService extends Service {

	find_by_phone(phone) {
		return http.get(`clients/providers/sellers/byphone`, {
			headers: this.getHeaders(),
            params: {
                phone
            }
		});
	}

    show(id, params) {
		return http.get(`clients/providers/sellers/${id}`, {
			headers: this.getHeaders(),
            params
		});
	}

    load(params) {
		console.log(params);
		return http.get(`clients/providers/sellers`, {
            headers: this.getHeaders(),
            params
        });
	}

    load_providers(params) {
		return http.get(`clients/providers`, {
            headers: this.getHeaders(),
            params
        });
	}

	States() {
		return http.get(`clients/providers/sellers/states`, {
			headers: this.getHeaders(),
			

        });
	}

    blacklist(id, obs) {
		return http.post(`clients/providers/${id}/blacklist`, { obs }, {
			headers: this.getHeaders()
		});
	}

    remove_from_blacklist(id) {
		return http.delete(`clients/providers/${id}/blacklist`, {
			headers: this.getHeaders()
		});
	}

	create(provider) {
        return http.post(`clients/providers`, provider, {
                headers: this.getHeaders()
            }
        );
    }

	getReview(id){
		return http.get(`clients/providers/sellers/rating/`+id, {
			headers: this.getHeaders(),
        });
	}

}
