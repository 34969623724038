<template>
    <div class="makeorder-page-table-stock-width">
        <div class="makeorder-page-table-mobile-header">Estoque</div>
        {{ product.estoque ? product.estoque : '-' }}
    </div>
</template>

<script>
export default {
    name: 'EstoqLine',
    props: ['product']
}
</script>

<style scoped>
.makeorder-page-table-stock-width{width: 7%;}

.makeorder-page-table-mobile-header {
    display: none;
}

@media screen and (max-width: 850px) {
    .makeorder-page-table-stock-width{
        width: fit-content !important; 
        display: flex; 
        gap: 2vw;
        align-items: baseline;
    }

    .makeorder-page-table-mobile-header {
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
        text-wrap: nowrap;
    }
}</style>