<style lang="scss">
$fast-options-header-background-color: #ec7f3b;
@import "@/sass/commons/_variables";
.home {
  background: white;
  display: flex;
  padding: 0;
  min-height: 100vh;
  .body {
    width: calc(100% - 290px);
    .fast-options {
      .card {
        border-radius: 0;
        min-width: 23rem;
        height: 15rem;
        padding: 0;
        box-shadow: 5px 8px #ededed;
        margin-left: 0.7rem;
        margin-top: 1rem;
        margin-bottom: 36px;
        .header {
          background-color: var(--primary-color);
          height: 15rem;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 5rem;
            &::before {
              color: rgba(255, 255, 255, 0.61);
            }
          }
        }
        .info {
          background-color: white;
          min-height: 100px;
          padding: 16px 14px 13px 20px;
          position: relative;
          h2 {
            margin: 0;
            padding: 0 0 10px;
            font-size: 16px;
            font-weight: 600;
          }
          a {
            border: 1px solid var(--primary-color);
            padding: 6px 13px;
            border-radius: 4px;
            font-size: 12px;
            color: #4d4f5c;
            position: absolute;
            bottom: 13px;
            right: 13px;
            &:hover {
              background-color: var(--primary-color);
              color: white;
            }
          }
        }
      }
    }
    .history {
      margin-bottom: 30px;
      #history-graph {
        min-width: 310px;
        height: 400px;
        margin: 0 auto;
      }
    }
    .rankings {
      margin-bottom: 40px;

      & > div:first-child {
        display: flex;
      }
      .providers {
        background-color: transparent;
        padding: 0 12px 0 0;
        .data {
          background-color: white;
          padding: 0 20px 20px;
          h2 {
            font-family: "Rubik", sans-serif !important;
            color: #9b9b9b;
            font-size: 18px;
            font-weight: 400;
            padding-top: 23px;
            margin: 0 0 20px;
          }
        }
      }
      .categories {
        background-color: transparent;
        padding: 0;
        .data {
          background-color: white;
          padding: 20px;
          h2 {
            font-family: "Rubik", sans-serif !important;
            color: #9b9b9b;
            font-size: 18px;
            font-weight: 400;
            margin: 0;
            position: absolute;
            z-index: 1;
            top: 20px;
          }
          .chart {
            height: 400px;
            .title {
              text-align: center;
              z-index: 1;
              position: absolute;
              top: 180px;
              left: 50%;
              transform: translate(-50%);
              margin-left: 3px;
            }
            #categories-chart {
              width: 200px;
              margin: 0 auto;
            }
          }
          .subtitles {
            margin-top: -60px;
            z-index: 1;
            position: relative;
            ul {
              list-style: none;
              margin: 0 auto;
              max-width: 240px;
              padding: 0;
              li {
                display: flex;
                justify-content: space-between;
                .ball {
                  width: 16px;
                  height: 16px;
                  background-color: white;
                  border: 3px solid #4d4f5c;
                  border-radius: 50%;
                  &.purple {
                    border-color: #a3a0fb;
                  }
                  &.yellow {
                    border-color: #ffda83;
                  }
                  &.red {
                    border-color: #ff8373;
                  }
                  &.blue {
                    border-color: #55d8fe;
                  }
                }
                p {
                  color: #f38505;
                  &.total-sold {
                    color: rgba(77, 79, 92, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.icon-card {
  color: white !important;
}
.btn-padding {
  margin-top: 70px !important;
}
.text-card {
  font-size: 25px !important ;
  color: #8b8b8b !important;
}
.text-header {
  font-size: 50px;
  color: #9b9b9b;
}
.text-subheader {
  font-size: 30px;
  color: #9b9b9b;
}
.first-Letter:first-letter {
  text-transform: uppercase;
}
.cardContainerInicio {
  display: grid;
  padding: 5px 5px;
  grid-template-columns: 1fr 1fr;
  /* flex-wrap: wrap; */

  gap: 10px;
  /* align-items: center; */
  justify-content: center;
  .cardMenu {

    display: flex;
    flex-direction: column;

    padding: 10px 10px;

    > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* flex: 1; */
      /* justify-content: space-between; */
      /* align-items: center; */

      gap: 5px;
    }
    /* width: 47vw; */
  }

  @media only screen and (max-width: 630px) {
  }
}
.nameContainer {
  /* margin-left: 5px; */
  font-size: 17px;
  color: #4d4f5c;
  font-weight: 500 ;
  text-align: center;
  /* text-wrap: wrap; */
  /* max-width: 100px; */
  white-space: pre;
}
.cardMenu {
  text-wrap: wrap;



  @media only screen and (max-width: 630px) {
    /* width: 100%; */
    /* justify-self: stretch; */
    /* padding: 10px 15px; */
    /* background-color: red; */
  }
}
.iconContainer {
  border: 1px solid transparent;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  border-radius: 25px;
  width: 90px;
  background-color:var(--primary-color-opacity);
    box-shadow: 0 0 2px 2px var(--primary-color-opacity);
    transition: 100ms ease;
    &:active{
        translate: 1px 2px;
    }
  .material-icons {
    color: var(--primary-color);
    /* opacity: 0.2; */
    font-size: 30px;
    /* border: 1px solid black; */
    /* padding: 40px; */
    @media only screen and (max-width: 630px) {
      font-size: 30px;
    }
  }
}
</style>

<template>
  <div class="cardContainerInicio">
    <div class="cardMenu" v-for="menu of permitted_menus()" :key="menu.name">
      <router-link :to="menu.link" v-on:click="redirect(menu)" class="">
        <!-- <span
          :class="{
            'active-class': menu.active,
            active: menu.active,
            'material-icons': menu.icon == 'local_offer',
            'green-icon': menu.icon == 'local_offer',
            'material-icons-outlined': menu.icon != 'local_offer',
          }"
          class="new-sidebar__icon-color"
        > -->
        <div class="iconContainer">
          <span class="material-icons">
            {{ menu.icon }}
          </span>
        </div>
        <div v-if="true" class="nameContainer">{{ menu.name }}</div>
        <!-- <div class="sidebar-options" v-if="menu.showOptions && isExpanded">
          <router-link
            :to="option.link"
            class=""
            v-for="option in menu.options"
            :key="option.name"
          >
            {{ option.name }}s
          </router-link>
        </div> -->
      </router-link>
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import StorageService from "@/services/TokenService";
import { perm_mixin } from "@/mixins/permission.mixin";
// highchartsVariablePie(Highcharts);

export default {
  mixins: [perm_mixin],
  data() {
    return {
      storage_svc: new StorageService(),
      isExpanded: false,
      CONSTANTS: CONSTANTS,
      menus: [
        {
          name: "Home",
          resource: "HOME",
          icon: "home",
          link: "/home-page/",
          active: false,
          route: "home",
        },
        {
          name: "Dashboard",
          resource: "REPORT",
          icon: "donut_large",
          link: "/dashboard/",
          active: false,
          route: "relatorios",
        },
        {
          name: "Produtos",
          resource: "PRODUCT",
          icon: "inventory_2",
          link: "/produtos/",
          route: "products",
          active: false,
        },
        {
          name: "Meus\nFornecedores",
          resource: "SELLER",
          icon: "groups",
          link: "/fornecedores/",
          route: "sellers",
          active: false,
        },
        {
          name: `Mapa de\nFornecedores`,
          resource: "MAP",
          icon: "map",
          link: "/explorar/",
          active: false,
          action: "READ_PROVIDERS_MAP",
          route: "explore",
        },
        {},
        {
          name: "painel de\noperações",
          resource: "REQUEST",
          icon: "desktop_windows",
          link: CONSTANTS.ROUTE_MONITORS,
          params: { tab: "cotacoes" },
          route: "monitors-page",
          active: false,
          showOptions: false,
          options: [
            {
              name: "Monitor de Cotação",
              link: "/cliente/monitores/cotacoes",
            },
            {
              name: "Monitor de Ressuprimento",
              link: "/cliente/monitores/ressuprimento",
            },
            {
              name: "Lista Mobile",
              link: "/cliente/monitores/mobile",
            },
            {
              name: "Pedidos",
              link: "/cliente/monitores/pedidos",
            },
            // {
            //     name: "Monitor Preço de Venda Varejo",
            //     link: "/cliente/monitores/price"
            // },
          ],
        },
        {
          name: "Relatórios",
          resource: "REPORT",
          icon: "trending_up",
          link: "/relatorios/",
          active: false,
          route: "relatorios",
        },
        {},
        // {
        //     name: "Produtos em Destaque",
        //     resource: 'PRODUCT',
        //     action: 'SEE_HIGHLIGHTS',
        //     icon: "fas fa-medal",
        //     link: '/destaques',
        //     route: "highlights-index",
        //     active: false,
        // },
        {
          name: "Minhas\nConfigurações",
          icon: "settings",
          resource: "ACCOUNT_SETTINGS",
          action: "READ",
          link: CONSTANTS.ROUTE_SETTINGS,
          route: "user-settings",
          active: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    perms: function () {
      setTimeout(() => {
        this.process_request_permission_menu();
      }, 400);
    },
    $route(to, from) {
      this.menus.forEach((m) => {
        if (to.name == m.route) {
          this.activate(m);
        }
      });
    },
  },
  methods: {
    permitted_menus: function () {
      return this.menus
        .filter((menu) => menu.link)
        .filter(
          (menu) =>
            menu.resource == null ||
            this.can(menu.resource, menu.action || "READ")
        );
    },
    hideMenuOptions(menu) {
      if (menu.options) menu.showOptions = false;
    },
    showMenuOptions(menu) {
      if (menu.options) {
        menu.showOptions = true;
      }
    },
    getExpandstyles() {
      if (this.isExpanded)
        return "border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);";
    },
    showExpand() {
      this.isExpanded = true;
    },
    hideExpand() {
      this.isExpanded = false;
      this.menus.forEach((menu) => (menu.showOptions = false));
    },
    redirect(menu) {
      if (!menu.options) {
        this.menus.forEach((m) => (m.active = false));
        menu.active = true;
        this.$forceUpdate();
        this.isExpanded = false;
        // this.$router.push(menu.link)
      }
    },
    logout() {
      console.log("logout");
      this.$store.dispatch("user/logout");
      window.location.assign(CONSTANTS.ROUTE_LOGIN);
    },
    activate(menu) {
      this.menus.forEach((m) => (m.active = false));
      menu.active = true;
      this.$forceUpdate();
    },
    defineMenuItemActive() {
      this.menus.map((menu, index) => {
        this.menus[index].active = false;
        if (menu.link === this.$route.path) {
          this.menus[index].active = true;
        }
      });
    },
    process_request_permission_menu() {
      if (JSON.parse(this.storage_svc.get("showOffers"))) {
        this.menus[8] = {
          name: "Ofertas",
          resource: "CAMPAIGN",
          icon: "local_offer",
          link: "/ofertas",
          route: "campaigns-index",
          active: false,
        };
        this.$forceUpdate();
      }

      if (
        this.can("REQUEST", "CREATE") ||
        this.can("REQUEST", "CREATE_RESUPPLY")
      ) {
        let action = null;
        if (this.can("REQUEST", "CREATE")) {
          action = "CREATE";
        } else if (this.can("REQUEST", "CREATE_RESUPPLY")) {
          action = "CREATE_RESUPPLY";
        }
        this.menus[5] = {
          name: "Nova Compra\nRequisição",
          resource: "REQUEST",
          action: action,
          icon: "add_shopping_cart",
          link: CONSTANTS.ROUTE_NEW_QUOTATION_REQUEST,
          active: false,
        };
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    this.process_request_permission_menu();
    this.defineMenuItemActive();
  },
  beforeRouteUpdate(to, from, next) {
    this.defineMenuItemActive();
  },
};
</script>
