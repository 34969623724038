let next_id = 1

export default {
    namespaced: true,
    state: {
        requests: {
            provider: null,
            category: null,
            store: null,
            has_observation: false,
            others_only: false,
            variation_price: false
        }
    },
    mutations: {
        UPDATE_REQUEST_FILTERS(state, filters) {
            state.requests = filters;
        }
    },
    actions: {
        update_request_filters({ commit }, filters) {
            commit("UPDATE_REQUEST_FILTERS", filters);
        }
    },
    getters: {
        request_filters: state => state.requests
    }
};
