<template>
    <div class="log-mobile-container">
        <div class="log-mobile-row">
            <h1 class="log-mobile-row-title">{{ new Date(log.updated_at).toLocaleDateString() }}</h1>
            <h1 class="log-mobile-row-title">{{ log.usu_login }}</h1>
        </div>
        <div class="log-mobile-row">
            <div style="width: 45%;">
                <h1 class="log-mobile-collum-title">tipo</h1>
                <p :style="getActionColor(log.action)">{{ log.action }}</p>
            </div>
            <div>
                <h1 class="log-mobile-collum-title" style="text-align: right;">Campo</h1>
                <p style="text-align: right;">{{ log.field || '--' }}</p>
            </div>
        </div>
        <div class="log-mobile-row">
            <div>
                <h1 class="log-mobile-collum-title">Dado antigo</h1>
                <p >{{ log.old || '--' }}</p>
            </div>
            <div>
                <h1 class="log-mobile-collum-title" style="text-align: right;">Dado novo</h1>
                <p style="text-align: right;">{{ log.newValue || '--' }}</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ProductLogMobile',
    props:['log', 'getActionColor']
}
</script>
<style scoped lang="scss">
.log-mobile-container{
    display: none;
    margin-bottom: 5vh;
}
p{
    margin: 0;
    color: #605F5F;
    font-size: 1em;
}
.log-mobile-row{
    display: flex;
    justify-content: space-between;
    
}
.log-mobile-row-title{
    color: #605F5F;
    font-weight: bold;
    font-size: 1.2em;
}
.log-mobile-collum-title{
    color: #605F5F;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0;
}
@media only screen and (max-width: 800px) {
    .log-mobile-container{
        display: block;
    }
}
</style>