<template>
    <button :class="{'divergent-table-button-red': haveDivergent}" @click="action" class="divergent-table-button">{{ !haveDivergent ? 'Incluir Divergência' : 'Editar Divergência' }}</button>
</template>

<script>
export default {
    name: 'Divergentbutton',
    props: ['action', 'haveDivergent']
}
</script>

<style scoped>
.divergent-table-button {
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    background: #FFF;
    font-weight: bold;
    height: fit-content;
}
.divergent-table-button-red{
    color: lightcoral;
    border: 1px solid lightcoral;
}

</style>