<template>
        <div class="products-modal-content-container">
            <div class="management-modal-inputs-container-wrapper">
                <span class="management-modal-inputs-container-title">Filtrar produtos</span>
                <input :placeholder="'Digite o nome do produto'" @keyup.enter="inputEnter()" v-model="inputSearch" class="manage-categories__input">
            </div>
            <div class="management-modal-body">
                <div class="modal-table-header">
                    <div class="modal-table-colum-id">Cod.</div>
                    <div class="modal-table-colum-name">Nome</div>
                    <div class="modal-table-colum-action">Nome</div>
                </div>
                <div v-for="(value, idx) in showList" :key="value.id" :class="{ 'page-table-line-special': idx % 2 != 0 }"
                    class="modal-table-row">
                    <div class="modal-table-colum-id">{{ value.produto.pro_ean }}</div>
                    <div class="modal-table-colum-name">{{ value.produto.pro_descricao }}</div>
                    <div class="modal-table-colum-action">
                        <span @click="deleteProduct(value.id)" class="material-icons-outlined modal-table-delete-icon">delete</span>
                    </div>
                </div>
                <h1 class="modal-empty-list" v-if="showList && showList.length==0">Lista vazia</h1>
                <pagination classes="orange" :total="totalItems" :page="1" :page_limit="5" :items_by_page_value="30"
                    v-on:change="loadPage($event)" style="margin-top: 5vh;"/>
            </div>
            <cc-loader-full v-if="isLoading" />
        </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import ProductGroupService from '@/services/v3/management/ProductGroupService.js'
import pagination from "@/components/cliente/base-components/Pagination";

export default {
    name: 'ListProductsModal',
    props: ['listId'],
    components: {
        StandardButton,
        pagination
    },
    data() {
        return {
            newGroupText: '',
            service: new ProductGroupService(),
            isLoading: false,
            showList: undefined,
            totalItems: undefined,
            inputSearch: '',
            searchTimeSpan: undefined,
        }
    },
    created(){
        this.loadPage(1)
    },
    watch:{
        inputSearch(){
            const localTimeStamp = Date.now()
            this.searchTimeSpan = localTimeStamp
            setTimeout(() => {
                if (this.searchTimeSpan == localTimeStamp) this.loadPage(1);
            }, 2000);
        }
    },
    methods:{
        inputEnter(){
            this.searchTimeSpan = undefined;
            this.loadPage(1);
        },
        loadPage(page){
            this.isLoading = true
            this.service.getGroupProducts(this.listId, page, this.inputSearch).then(resp => {
                this.showList = resp.data.data
                this.isLoading = false
                this.totalItems = resp.data.total
            })
        },
        deleteProduct(id){
            this.isLoading = true
            this.service.deleteProduct(id).then(()=>{
                this.isLoading = false
                this.showList = this.showList.filter(element => element.id != id)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.management-modal-mask {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.products-modal-content-container {
    background-color: white;
    border-radius: 12px;
    height: 100%;
    overflow-x: auto;
    padding: 0 2vw;
}

.management-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.management-modal-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2em;
}

.management-modal-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.management-modal-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.manage-categories__input {
    width: 16vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}

.management-modal-body-title {
    color: #898989;
    font-weight: 500;
    font-size: 1.5em;
}

.modal-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 17px;
    color: #605F5F;
    margin-bottom: 1vh;
}

.modal-table-colum-id {
    width: 20%;
    padding-left: 0.5vw;
}

.modal-table-colum-name {
    flex: 1;
    padding-left: 0.5vw;
}
.modal-table-colum-action{
    width: 15%;
    padding-left: 0.5vw;
}
.modal-table-delete-icon{
    color: lightcoral;
    cursor: pointer;
}

.modal-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    padding: 0.5vh 0;
    margin-bottom: 1vh;
    align-items: center;
}
.modal-empty-list{
    text-align: center;
    color: #898989;
}
.management-modal-inputs-container-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
}
.management-modal-inputs-container-title {
    color: #898989;
    font-weight: 400;
    font-size: 1.8em;
    letter-spacing: 0.15px;
}
.manage-categories__input {
    width: 27vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}
@media only screen and (max-width: 800px) {
    .manage-categories__input{
        width: 60vw;
    }
}
</style>