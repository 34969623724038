<template>
    <div>
        <div class="filters">
            <StandardInputAuto class="desktop" :action="setDescription" :title="'Descrição do Produto'" :width="'50%'" :type="'text'"/>
            <StandardInputAuto class="mobile" :action="setDescription" :title="'Descrição do Produto'" :width="'100%'" :type="'text'"/>
            <!-- <StandardInputAuto class="desktop" :action="(val) => filters.name = val" :width="'30%'" :type="'text'"/>
            <StandardButton :action="load" :text="'Filtrar'"/> -->
        </div>
        <div v-if="!is_loading">
            <div id='products' class="winners-page-product" v-for="rp in collection" v-bind:key="rp.id">
                <div class="winners-page-product-title-container">
                    <div class="winners-page-product-title">
                        {{rp.product.name}}
                    </div>
                    <div class="winners-page-product-quantity">Quantidade:{{ requested_quantity(rp.subrequests) }} {{ rp.product.packing }}</div>
                </div>
                <div class="winners-page-product-info-table-header">
                    <div class="info-table-checkbox-width"></div>
                    <div class="info-table-suplier-width">Fornecedor</div>
                    <div class="info-table-brand-width">Marca</div>
                    <div class="info-table-payment-width">Forma De Pgto	</div>
                    <div class="info-table-quantity-width">QTD. EMB.</div>
                    <div class="info-table-value-width">Valor unitário</div>
                    <div class="info-table-obs-width">Obervação</div>
                </div>
                <div class="winners-page-product-info-table-row" :class="getSpecialBackground(idx)" v-for="(offer, idx) in rp.offers" v-bind:key="offer.id">
                    <div class="info-table-checkbox-width info-table-suplier-width-colum">
                        <span :title="has_orders(offer) ? 'Vendedor já possui pedidos' : ''" v-on:click.self="toggle(offer)" v-if="is_winner(offer)" class="material-icons-outlined" style="color: var(--primary-color); cursor: pointer; margin-right: 10px; margin-left: 1vw">check_box</span>
                        <span :title="has_orders(offer) ? 'Vendedor já possui pedidos' : ''" v-on:click.self="toggle(offer)"  v-else class="material-icons-outlined" style="color: var(--primary-color); cursor: pointer; margin-left: 1vw">check_box_outline_blank</span>
                        <img class="brands-loader" :class="{ 'hidden' : !offer.is_loading }" style="height: 3vh;" src="/images/loader.svg"/>
                        <span :class="{ 'not-visible' : !has_orders(offer) && !is_winner(offer) || offer.is_loading}" @click="show_pre_orders(rp, offer)" class="material-icons-outlined" style="color: var(--primary-color); cursor: pointer;">shopping_cart</span>
                    </div>
                    <div class="info-table-suplier-width">{{ offer.seller.provider.name }}</div>
                    <div class="info-table-brand-width info-table-suplier-width-colum">
                        <span v-on:click="setPreferencial(offer.brand)" v-if="offer.brand && offer.brand.starred" class="material-icons-outlined suplier-fav-icon">star</span>
                        <span v-on:click="setPreferencial(offer.brand)" v-if="offer.brand && !offer.brand.starred" class="material-icons-outlined suplier-fav-icon">star_outline</span>
                        {{ offer.brand ? offer.brand.name : '-' }}
                    </div>
                    <div class="info-table-payment-width">{{ offer.seller.delivery ? offer.seller.delivery.form : '-' }}</div>
                    <div class="info-table-quantity-width">{{!rp.product.is_generic ? rp.product.packing_quantity : offer.quantity }}</div>
                    <div class="info-table-value-width">{{offer.price | currency}}</div>
                    <div class="info-table-obs-width" :title="offer.obs">{{offer.obs ? formatObs(offer.obs) : '-'}}</div>
                </div>
            </div>
        </div>
        <div v-else>
            <cc-loader-full />
        </div>
        <div>
            <Pagination
                classes="orange"
                :per_page="per_page"
                :total="collection_size"
                v-on:change="set_page($event)"
            />
        </div>
        <cc-add-outside-request-seller :seller_id="current_brand.idVendedor"
            v-if="show_outside_seller"
            :request_id="$route.params.id"
            @close="current_brand = null;show_outside_seller=false" />

        <PreOrderModal v-if="current_offer && show_pre_orders_modal"
            :loj_id="loj_id"
            :offer="current_offer"
            :product="current_product"
            :request="request"
            @close="show_pre_orders_modal=false"
            @reload="reload" />

        <!-- <cc-product-sub-requests v-if="current_offer && show_pre_orders_modal"
            :salesman="current_offer"
            :quotationId="$route.params.id"
            :product="current_product"
            @close="show_pre_orders_modal=false"
            :sellers="sellers" /> -->

        <ProductOrdersModal
            :product_id="current_product.id"
            :product_name="current_product.name"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
    </div>
</template>
<script>
import Pagination from "@/components/ui/paginations/pagination.component";
import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverStores from "@/components/popovers/stores.popover";
import ProductOrdersModal from '@/modals/products/orders';
import PreOrderModal from "../pre-orders/_pre-orders.modal";
import ProductService from '@/services/ProductService';
import RequestProductService from "@/services/v3/requests/product.service"
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import AddOutsideRequestSeller from '@/modals/add-outside-request-seller/add-outside-request-seller.modal'
import V3RequestsService from "@/services/v3/request.service"
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import { pag_mixin } from '@/mixins/pagination.mixin'
import { mapState } from 'vuex'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'

export default {
    mixins: [ pag_mixin ],
    components: {
        PreOrderModal,
        popoverStores,
        ProductOrdersModal,
        StandardButton,
        StandardInputAuto,
        Pagination,
        'cc-add-outside-request-seller': AddOutsideRequestSeller,
        'cc-product-sub-requests': ProductSubRequestsModal,
    },
    props: [
        "wraps", "sellers", "loj_id",
        "seller_id", "request",
        "filters"
    ],
    data() {
        return {
            show_outside_seller: false,
            show_pre_orders_modal: false,
            current_offer: null,
            current_brand: null,
            current_product: null,
            quotationService: new QuotationService(),
            filters: {},
            svc: new V3RequestsService(),
            prod_svc: new ProductService(),
            rq_svc: new RequestProductService(),
            is_product_orders_modal_open: false
        };
    },
    watch: {
        request: function(request) {
            if(request.requests_products) {
                this.paginate(request.requests_products.filter(rp => rp.offers && rp.offers.length > 0))
            }
        },
        page: function() {
            this.load()
        }
    },
    computed: {
        ...mapState('user', [ 'stores' ]),
    },
    methods: {
        setDescription (value) {
            this.filters.name = value
            if (!this.is_loading){
                this.is_loading = true
                setTimeout(()=>{
                    this.load()
                }, 1500)
            }
        },
        formatObs (text) {
            if (text.length > 25) return text.slice(0,24) + '...'
            return text
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        irregular_amount(product) { return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada },
        set_page(page) {
            this.page = page
            var element = document.getElementById("products");
            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        },
        has_orders(offer) {
            return offer.seller.orders_count > 0
        },
        requested_quantity(subrequests) {
            return subrequests.reduce((x, { quantity }) => x + quantity, 0)
        },
        show_pre_orders(rp, offer) {
            this.current_offer = offer
            this.current_product = rp.product
            this.show_pre_orders_modal = true
            this.current_subrequests = rp.subrequests
        },
        show_orders(product) {
            if(product.last_purchase) {
                this.current_product = product
                this.is_product_orders_modal_open = true
            }
        },
        irregular_amount(product) {
            return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada
        },
        is_winner(offer) {
            return offer.pre_orders && offer.pre_orders.length > 0
        },
        reload(params) {
            this.$emit("reload", params)
        },
        setPreferencial(produto) {
            produto.starred = produto.starred == 1 ? 0 : 1;
            this.prod_svc.pin_brand({ idProduto: produto.id, preferencial: produto.starred })
                .catch(function() {
                    ErrorHandlerService.handle(err, this.$store)
                })
        },
        toggle(offer) {
            this.$set(offer, "is_loading", true);
            let params = { offer_id: offer.id, is_winner: !this.is_winner(offer) }
            this.$set(offer, "pre_orders", this.is_winner(offer) ? [] : [ {} ]);
            this.svc.toggle(params).then(response => response.data).then(() => {
                this.$set(offer, "is_loading", false);
                this.reload()
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store)
            })
        },
        update_items_quantity(brand, loj_id, seller_id, callback) {
            this.quotationService.sendQuantidade({
                numerocotacao: "" + this.$route.params.id,
                quantidade: "0",
                vendedor: seller_id,
                produto: brand.pro_id,
                loja: loj_id
            })
            .then(response => response.data)
            .then(callback);
        },
        load() {
            this.is_loading = true
            let params = {
                per_page: this.per_page,
                page: this.page,
                name: this.filters?.name
            }
            return this.rq_svc.products(this.request.id, params).then(response => response.data)
            .then(response => {
                this.paginate(response.data)
                this.collection_size = response.meta.total
                this.is_loading = false
            })
        }
    },
    mounted() {
        this.per_page = 30
        this.load();
    }
};
</script>

<style lang="scss" scoped>
    @import "./_products.scss";

    .winners-page-product-title-container{
        display: flex;
        justify-content: space-between;
        background-color: var(--secondary-color-opacity);
        border-radius: 7px;
        padding: 0.1vw 0.5vw;
    }
    .winners-page-product-title{
        font-weight: 400;
        font-size: 1.4em;
        color: #101010;
        display: flex;
        align-items: center;
    }
    .winners-page-product{
        border: 1px solid lightgray;
        border-radius: 8px;
        padding: 0.5vw 1vw;
        margin-bottom: 2vh;
        min-width: 1000px;
    }
    .winners-page-product-info-table-header{
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        display: flex;
        padding: 1vh 0;
        margin-top: 2vh;
        font-weight: 400;
        font-size: 1.1em;
        color: #605F5F;
    }
    .info-table-checkbox-width{ width: 10%; }
    .info-table-suplier-width{ flex: 1; }
    .info-table-brand-width{ width: 14%; }
    .info-table-payment-width{ width: 14%; }
    .info-table-quantity-width{ width: 10%; }
    .info-table-value-width{ width: 14%; }
    .info-table-obs-width{ width: 18%; }
    .winners-page-product-info-table-row{
        display: flex;
        padding: 1vh 0;
        font-weight: 300;
        font-size: 1.1em;
        color: #605F5F;
        align-items: center;
    }
    .page-table-line-special{  background: #F7F7F7; }
    .info-table-suplier-width-colum{
        display: flex;
        align-items: center;
    }
    .suplier-fav-icon{
        color: #FFCA2B;
        margin-right: 5px;
        cursor: pointer;
    }
    .winners-page-product-quantity{
    font-weight: 500;
    font-size: 1.25em;
    color: #101010;
}
.desktop{margin-bottom: 3vh;}
.mobile{ display: none;}
@media only screen and (max-width: 800px){
    .desktop{display: none;}
    .mobile{display: block; margin-bottom: 2vh;}
}
</style>
