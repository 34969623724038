<template>
  <div>
    <label class="switch">
      <input
        :checked="checked"
        :value="value"
        type="checkbox"
        @change="$emit('input', $event.target.checked)"
        v-on="$listeners"
        v-bind="$attrs"
      />
      <span class="slider round"></span>
    </label>
    <span class="label" v-if="label">{{ label }}</span>
  </div>
</template>

<script>
import { uiMixin } from "@/mixins/base.mixing";
export default {
  mixins: [uiMixin],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  align-items: center;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label {
  position: relative;
  top: -20px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 300;
  color: #605f5f;
}
</style>
