import http from "../http";
import Service from "../../Service";

/**
 * @type ProfileService
 */
export default class ProfileService extends Service {

    save(profile) {
        return profile.id ? this.update(profile) : this.create(profile);
    }

    create(profile) {
        return http.post(`clients/providers/${profile.provider_id}/profiles`, profile, {
			headers: this.getHeaders()
		});
    }

    update(profile) {
        return http.put(`clients/providers/${profile.provider_id}/profiles/${profile.id}`, profile, {
            headers: this.getHeaders()
        });
    }

    save_permission(permission) {
        return permission.id ? this.update_permission(permission) : this.create_permission(permission);
    }

    create_permission(permission) {
        return http.post(
            `clients/providers/${permission.provider_id}/profiles/${permission.profile_id}/permissions`,
            permission,
            { headers: this.getHeaders() }
        );
    }

    update_permission(permission) {
        return http.put(
            `clients/providers/${permission.provider_id}/profiles/${permission.id}/permissions/${permission.id}`,
            permission,
            { headers: this.getHeaders() }
        );
    }

    load_profiles(id) {
        return http.get(`clients/profiles/${id}`, {
            headers: this.getHeaders()
        });
    }

    remove(profile) {
		return http.delete(
            `clients/providers/${profile.provider_id}/profiles/${profile.id}`,
            {
                headers: this.getHeaders()
            }
        );
	}

    remove_permission(permission) {
		return http.delete(
            `clients/providers/${permission.provider_id}/profiles/${permission.profile_id}/permissions/${permission.id}`,
            { headers: this.getHeaders() }
        );
	}
    load(params) {
		return http.get(`admin/providers`, {
			headers: this.getHeaders(),
            params
		});
	}
    exportItem(cotId, forId) {
		return http.get(`clients/export/csv/cotacao/${cotId}/fornecedor/${forId}`,{
			headers: this.getHeaders()
		})
	}
}
