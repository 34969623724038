<template>
    <div class="product-brand-tab-container">


        <div class="brand-tab-main-header">
            <FoodServiceTabSelect :tab="tab" :setTab="setTab"/>
            <StandardInput class="brands-modal__actions-search" placeholder="Buscar marcas" title="Marcas" :action="(value) => { filters.name = value }"
                :type="'text'" />
        </div>

        <div class="brands-modal__actions-wrapper">
            <div class="brands-modal__generic-request">Requisições Genéricas: <b class="brands-modal__value-request">{{ generic }}</b> </div>
            <div class="brands-modal__objective-request">Requisições Objetivas: <b class="brands-modal__value-request">{{ objective }}</b> </div>
            <div class="brands-modal__total-request">Total de Requisições: <b class="brands-modal__value-request">{{ total }}</b> </div>
        </div>
        <div class="page-table-container">
            <div class="page-table-content">
                <div class="page-table-header">
                    <div class="page-table-header-text id-width">ID</div>
                    <div class="page-table-header-text brand-width">Marca</div>
                    <div class="page-table-header-text request-width" style="padding-left: 0;">Requisições</div>
                </div>
                <div v-for="(product, idx) in filtred_brands()" :key="idx" :class="getSpecialBackground(idx)">
                    <div class="page-table-row">
                        <div class="page-table-desc-column id-width">
                            <div class="page-table-mobile-header">ID</div>
                            {{ product.pro_id }}
                        </div>
                        <div class="page-table-desc-column brand-width">
                            <div class="page-table-mobile-header">Marca</div>
                            {{ product.pro_observacao }}
                        </div>
                        <div class="request-width">
                            <div class="page-table-mobile-header">Requisições</div>
                            {{ product.resq ? product.resq : '--' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import foodService from '../../services/v3/foodService';
import FoodServiceTabSelect from '../../pages/products/FoodServiceTabSelect.vue';

export default {
    name: "ProductBrandTab",
    components: {
        StandardInput,
        FoodServiceTabSelect
    },
    props: [
        'product',
        'product_pattern',
        'tab',
        'setTab'
        // closeDialog: {
        //     type: Function,
        // },
        ],
    data() {
        return {
            svc: new foodService(),
            filters: {
                name: ""
            },
            total: 0,
            objective: 0,
            generic: 0,
            isLoading: false,
            brands: [],
        }
    },
    methods: {
        getProductImage(product) {
            return `background-image: url(${product.pro_imagem})`
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        close() {
            this.$emit('close')
        },
        filtred_brands() {
            if (this.filters.name) {
                let resp = []
                this.product.derivatives.forEach(element => {
                    if (element.brand?.toLowerCase().includes(this.filters.name?.toLowerCase())) resp.push(element)
                })
                return resp
            } else return this.product.derivatives
        }
    },
    mounted() {
        this.isLoading = true
        this.svc.getBrandsInfo(this.product.id).then(resp => {
            this.total = resp.data.total
            this.objective = resp.data.totalObjetivo
            this.generic = resp.data.totalGenerico
            this.svc.getBrands(this.product.id).then(resp2 => {
                this.product.derivatives = resp2.data.produtos
                resp.data.data.forEach(brand => {
                    this.product.derivatives.forEach(brand2 => {
                        if (brand.pro_id == brand2.pro_id) {
                            brand2.resq = brand.qtd
                        }
                    })
                })
                this.isLoading = false
            })
        })

    }
}

</script>

<style lang="scss" scoped>




.brands-modal__actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2em;
}

.brand-tab-main-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 13vh;
    margin-bottom: 5vh;
}



.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-mobile-header {
    display: none;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}
.brands-modal__value-request{
    font-weight: bolder;
    font-size: 1.2em;
}

.page-table-line-special {
    background: #F7F7F7;
}

.brand-width {
    flex: 1;
}

.request-width {
    width: 20%;
}



.id-width {
    width: 10%;
}







.brands-modal__actions-search {
    width: 45%;
}


.brands-modal__generic-request {
    width: 30%;
    color: #E53535;
    background-color: rgba(230, 53, 53, 0.1);
    font-size: 1.2em;
    padding: 1vh 1vw;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.brands-modal__objective-request {
    width: 30%;
    color: #17A2B8;
    background-color: rgba(23, 162, 184, 0.1);
    font-size: 1.2em;
    padding: 1vh 1vw;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.brands-modal__total-request {
    width: 30%;
    color: var(--primary-color);
    background: rgba(255, 113, 16, 0.15);
    font-size: 1.2em;
    padding: 1vh 1vw;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.upload-img-icon {
    width: 7em;
    height: 7em;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-left: 5vw;
}


/deep/ .products-page__input {
    padding: 25px 10px !important;
    color: #707070;
    font-size: 1.2em;
    width: 90%;
}



@media only screen and (max-width: 800px) {
    .page-table-content {
        font-size: 1.5em;
    }

    .brand-tab-main-header{
        flex-direction: column;
        height: unset;
    }

    .page-table-header {
        display: none;
    }

    .page-table-row {
        flex-direction: column;
        gap: 1vh;
    }

    .page-table-desc-column {
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
    }

    .page-table-mobile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }

    .request-width {
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
        padding: 0.5em 0.7em;
    }

    .upload-img-icon {
        margin-right: 4vw;
    }
}

@media only screen and (max-width: 450px) {

    .brands-modal__actions-wrapper {
        flex-direction: column;
        gap: 1vh;
    }

    .brands-modal__actions-search {
        width: 100%;
    }

    .brand-button {
        width: 100%;
    }

    .brands-modal__generic-request {
        width: 100%;
        font-size: 1.5em;
        text-align: center;
    }

    .brands-modal__objective-request {
        width: 100%;
        font-size: 1.5em;
        text-align: center;
    }

    .brands-modal__total-request {
        width: 100%;
        font-size: 1.5em;
        text-align: center;
    }
}</style>
